import React, { FC, useMemo } from 'react'
import { useTranslate } from 'ra-core'
import { Typography } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { QUOTE_STATUS_OPTIONS } from '../QuoteStatusDropDown'

type Props = {
  record?: Record<string, any>
  source?: string
  label?: string
}

const QuoteStatusField: FC<Props> = ({ record = {}, source = 'status' }) => {
  const translate = useTranslate()

  const color = useMemo((): string => {
    const statusFound = QUOTE_STATUS_OPTIONS.find((status: any) => record[source] === status.name)
    return statusFound ? statusFound.color : 'white'
  }, [record, source])

  if (!record[source]) return null

  return (
    <Typography variant="body2" style={{ display: 'flex' }}>
      <FiberManualRecordIcon fontSize="small" style={{ color, marginRight: 6 }} />
      {translate(`statuses.${record[source]}`)}
    </Typography>
  )
}

export default QuoteStatusField
