import React, { FC } from 'react'
import { ReferenceField, ReferenceManyField, TextField } from 'react-admin'
import { useShowController, useTranslate, useGetOne } from 'ra-core'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
// internal components
import ShowView from '../../components/details/ShowView'
import CreateButton from '../../components/button/CreateButton'
import { InfoCard, InfoCardItem, InfoCardWrapper, InfoCardTypography, InfoCardAvatar } from '../../components/InfoCard'
import Empty from '../../components/list/Empty'
import Datagrid from '../../components/Datagrid'
import GenericMoreMenu from '../../components/GenericMoreMenu'
import { Pagination } from '../../components/list/pagination'
import BankAccountDetailsField from '../../components/field/BankAccountDetailsField'
// icons
import { BiReceipt, BiError } from 'react-icons/bi'
import { AiFillPhone, AiOutlineMail } from 'react-icons/ai'
import { RiRoadMapLine } from 'react-icons/ri'

type Props = {
  [x: string]: any
}

const BankAccountsTable: FC<Props> = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.bankAccountsTable}>
      <ReferenceManyField
        pagination={<Pagination />}
        record={props.record}
        reference="BankAccount"
        target="organizationId"
        basePath={props.basePath}
      >
        <Datagrid emptyComponent={<Empty buttonComponent={<></>} />}>
          <ReferenceField source="currencyId" reference="Currency" link={false} basePath={props.basePath}>
            <TextField source="sign" />
          </ReferenceField>
          <TextField source="name" />
          <TextField source="address" />
          <BankAccountDetailsField source="details" />
          <GenericMoreMenu disableEdit />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  )
}

const ShowContent: FC<Props> = (props) => {
  const translate = useTranslate()
  const { loading, error, data: address } = useGetOne('Address', props.record.addressId)

  return (
    <Box display="flex" position="relative">
      <Box pr={3}>
        <InfoCardWrapper>
          <InfoCard>
            <InfoCardItem direction="column" alignItems="flex-start">
              <Box display="flex">
                <InfoCardAvatar>
                  <BiReceipt size={20} />
                </InfoCardAvatar>
                <Box flexDirection="column">
                  <InfoCardTypography variant="label">
                    {translate('resources.Organization.fields.vat')}
                  </InfoCardTypography>
                  <InfoCardTypography variant="value">{props.record.vat || 'N.D.'}</InfoCardTypography>
                </Box>
              </Box>
            </InfoCardItem>
            <InfoCardItem direction="column" alignItems="flex-start">
              <Box display="flex">
                <InfoCardAvatar>
                  <AiFillPhone size={20} />
                </InfoCardAvatar>
                <Box flexDirection="column">
                  <InfoCardTypography variant="label">
                    {translate('resources.Organization.fields.phone')}
                  </InfoCardTypography>
                  <InfoCardTypography variant="value">{props.record.phone}</InfoCardTypography>
                </Box>
              </Box>
            </InfoCardItem>
            <InfoCardItem direction="column" alignItems="flex-start">
              <Box display="flex">
                <InfoCardAvatar>
                  <AiOutlineMail size={20} />
                </InfoCardAvatar>
                <Box flexDirection="column">
                  <InfoCardTypography variant="label">
                    {translate('resources.Organization.fields.email')}
                  </InfoCardTypography>
                  <InfoCardTypography variant="value">{props.record.email}</InfoCardTypography>
                </Box>
              </Box>
            </InfoCardItem>
            <InfoCardItem direction="column" alignItems="flex-start">
              <Box display="flex">
                <InfoCardAvatar>
                  <RiRoadMapLine size={20} />
                </InfoCardAvatar>
                <Box flexDirection="column">
                  <InfoCardTypography variant="label">
                    {translate('resources.Organization.fields.address')}
                  </InfoCardTypography>
                  {loading ? (
                    <LinearProgress />
                  ) : error ? (
                    <BiError />
                  ) : (
                    <InfoCardTypography variant="value">
                      {`${address?.street || ''}, ${address?.city || ''}`}
                    </InfoCardTypography>
                  )}
                </Box>
              </Box>
            </InfoCardItem>
          </InfoCard>
        </InfoCardWrapper>
      </Box>
      <BankAccountsTable {...props} />
    </Box>
  )
}

const OrganizationShow: FC<Props> = (props) => {
  const showProps = useShowController(props)

  return !showProps.loading ? (
    <ShowView
      {...showProps}
      {...props}
      title={showProps.record ? showProps.record.name : undefined}
      additionalActions={
        <Box component="span" mr={4}>
          <CreateButton
            basePath="/BankAccount"
            label="resources.Organization.actions.addAccount"
            queryParams={showProps.record ? { organizationId: showProps.record.id } : undefined}
          />
        </Box>
      }
    >
      <ShowContent {...props} />
    </ShowView>
  ) : null
}

const useStyles = makeStyles((theme) => ({
  bankAccountsTable: {
    width: '100%',
  },
}))

export default OrganizationShow
