import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

type Props = {
  record?: any
}
export const ActivityTypeItem: FC<Props> = ({ record }) => {
  const classes = useStyles()

  return (
    <span className={classes.root}>
      <span className={clsx('material-icons', classes.icon)}>{record.icon}</span>
      <span className={classes.label}>{record.label}</span>
    </span>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  icon: {
    fontSize: '16px',
  },
  label: {
    marginLeft: theme.spacing(2),
  },
}))
