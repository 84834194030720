import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { ApolloClient, useApolloClient, useQuery } from '@apollo/client'
import { useForm } from 'react-final-form'
import {
  QUERY_GET_CIELO_QUOTE_REPLACEMENTS,
  QUERY_GET_EMAIL_TEMPLATES_AND_CUSTOMER_LANGUAGE,
  QUERY_GET_CIELO_ORDER_REPLACEMENTS,
  QUERY_GET_CIELO_FLIGHT_BRIEF_REPLACEMENTS,
} from '../../queries'
import { GmailQuoteVariables, renderTemplate } from '../../utils/gmail-template-variables'
import { Alert } from '@material-ui/lab'
import getValue from 'get-value'
import { nextUntil } from '../../utils/nextUntil'

type Props = {
  type: 'quote' | 'contract' | 'brief'
  customerId: string
  resourceId: string
}

export const SelectTemplateModalButton: FC<Props> = ({ type, customerId, resourceId }) => {
  const [showModal, setShowModale] = useState<boolean>(false)
  const radioGroupRef = useRef<HTMLElement>(null)
  const [value, setValue] = useState<string>()
  const form = useForm()
  const client = useApolloClient()

  const { loading, data } = useQuery(QUERY_GET_EMAIL_TEMPLATES_AND_CUSTOMER_LANGUAGE, {
    variables: {
      filters: {
        category: type,
      },
      customerId,
    },
    skip: !type || !customerId,
  })

  const selectedValue = useMemo(() => {
    if (!value) {
      return undefined
    }

    const foundedTemplate = data?.gmailTemplates?.data?.find((item: any) => {
      return item.id === value
    })

    if (!foundedTemplate) {
      return undefined
    }

    const foundedLanguageValue = foundedTemplate.values.find((item: any) => {
      return item.languageId === data?.customer?.languageId
    })

    return foundedLanguageValue
  }, [data, value])

  const handleShowModal = useCallback(() => {
    setShowModale(true)
  }, [])

  const handleHideModal = useCallback(() => {
    setShowModale(false)
  }, [])

  const handleEntering = useCallback(() => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
    setValue(undefined)
  }, [])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value
    setValue(newValue)
  }, [])

  const handleTemplateConfirmed = useCallback(async () => {
    const parsedDom = new DOMParser().parseFromString(selectedValue.value._html, 'text/html')
    const foundedReplacements = parsedDom.getElementsByClassName('mention')

    if (foundedReplacements.length > 0) {
      const { data } = await client.query({
        query:
          type === 'quote'
            ? QUERY_GET_CIELO_QUOTE_REPLACEMENTS
            : type === 'contract'
            ? QUERY_GET_CIELO_ORDER_REPLACEMENTS
            : QUERY_GET_CIELO_FLIGHT_BRIEF_REPLACEMENTS,
        variables: {
          id: resourceId,
        },
      })

      form.change('subject', selectedValue.subject.replace('$$quoteReference', data?.replacements?.quoteReference))
      renderTemplate(parsedDom, data?.replacements, type)
    }

    form.change('html', parsedDom.body.innerHTML)
    handleHideModal()
  }, [selectedValue, form, type, resourceId])

  return (
    <>
      <Button disabled={loading} variant="outlined" size="small" onClick={handleShowModal}>
        Fill from template
      </Button>

      <Dialog
        maxWidth="xs"
        fullWidth
        onEntering={handleEntering}
        aria-labelledby="select-mail-template"
        open={showModal}
      >
        <DialogTitle id="confirmation-dialog-title">Choose a template to use:</DialogTitle>
        <DialogContent dividers>
          <RadioGroup ref={radioGroupRef} aria-label="ringtone" name="ringtone" value={value} onChange={handleChange}>
            {(data?.gmailTemplates?.data as any[])?.map((option) => (
              <FormControlLabel
                value={option.id}
                key={`gmail-template-option-${option.id}`}
                control={<Radio />}
                label={option.name}
              />
            ))}
          </RadioGroup>

          {data?.customer && !data.customer.languageId && (
            <Alert severity="warning">Error! Client does not have a selected language</Alert>
          )}

          {value && !selectedValue && (
            <Alert severity="error">Error! Template Value not found for the client language</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleHideModal} color="primary">
            Cancel
          </Button>
          <Button disabled={!!(value && !selectedValue)} onClick={handleTemplateConfirmed} color="primary">
            Use template
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
