import React from 'react'
import { Center, Heading, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { Paper } from '@material-ui/core'
import { TableContent } from './components/TableContent'

export type MostUsedVehicleSafetyTypes = {
  id: number
  safetyType: {
    id: number
    name: string
  }
  count: number
  SUM_flightDuration: number
}

interface MostUsedVehicleSafetyTypesCardProps {
  data?: MostUsedVehicleSafetyTypes[]
  loading?: boolean
  error?: boolean
}

export const MostUsedVehicleSafetyTypesCard = ({ data, loading, error }: MostUsedVehicleSafetyTypesCardProps) => {
  return (
    <Paper>
      <Heading as="h4" size="sm" m="2">
        Most used safety types
      </Heading>
      <TableContainer overflowY="auto" maxHeight="10rem">
        <Table size="sm" variant="simple">
          <Thead position="sticky" top={0} bgColor="gray.50">
            <Tr>
              <Th>Safety Type</Th>
              <Th isNumeric> Flights</Th>
              <Th isNumeric>Hours</Th>
            </Tr>
          </Thead>
          <TableContent loading={loading} error={error}>
            <Tbody overflow="auto">
              {data?.map(({ safetyType, count, SUM_flightDuration }) => (
                <Tr key={safetyType.id}>
                  <Td>{safetyType.name}</Td>
                  <Td isNumeric>{count}</Td>
                  <Td isNumeric>{Math.round(SUM_flightDuration / 6) / 10}</Td>
                </Tr>
              ))}
              {data === undefined && (
                <Center>
                  <Spinner size="lg" m="4" />
                </Center>
              )}
            </Tbody>
          </TableContent>
        </Table>
      </TableContainer>
    </Paper>
  )
}
