import list from './AddressList'
import create from './AddressCreate'
import edit from './AddressEdit'

export default {
  name: 'Address',
  // list,
  // create,
  edit,
}
