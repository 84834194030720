// import list from './CustomerList'
import list from './CustomerList'
// import show from './CustomerShow'
// import create from './CustomerCreate'
import create from './CustomerCreate'
// import edit from './CustomerEdit'
import show from './CustomerShow'
// import edit from './ClientEdit'

export default { name: 'Customer', list, create, show }
