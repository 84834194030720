import React, { FC } from 'react'
import { useGetMany } from 'ra-core'
import LinearProgress from '@material-ui/core/LinearProgress'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'

type Props = {
  record?: Record<string, any>
  departureSource?: string
  arrivalSource?: string
  label?: string
}

const OrderRouteAirportsField: FC<Props> = ({
  record = {},
  departureSource = 'departureAirportId',
  arrivalSource = 'arrivalAirportId',
}) => {
  const { loading, error, data } = useGetMany('Airport', [record[departureSource], record[arrivalSource]])

  if (error || !record || !record[departureSource] || !record[arrivalSource] || data.length !== 2) {
    return <ErrorIcon htmlColor="darkred" />
  }

  if (loading) {
    return <LinearProgress />
  }

  if (data && Array.isArray(data) && data.length === 2) {
    return (
      <Chip
        color="primary"
        size="small"
        label={`${data[0].iataCode || data[0].icaoCode} - ${data[1].iataCode || data[1].icaoCode}`}
      />
    )
  }
  return null
}

export default OrderRouteAirportsField
