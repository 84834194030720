import React, { FC } from 'react'
import { Edit } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import FormSelectInput from '../../components/input/FormSelectInput'
import { GMAILTEMPLATESELECTCATEGORIES } from './GmailTemplateCreate'

type Props = {}

const GmailTemplateEdit: FC<Props> = (props) => {
  return (
    <Edit {...props} component="div">
      <SectionedForm variant="outlined" redirect="show" title="Edit template">
        <FormSection title="Template info">
          <FormTextInput source="name" required />
          <FormSelectInput source="category" choices={GMAILTEMPLATESELECTCATEGORIES} />
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default GmailTemplateEdit
