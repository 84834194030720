import React, { FC, useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'
import useCurrencySettings from '../hooks/useCurrencySettings'

type Props = {
  variant?: string
  label?: string
  name?: string
  value: string | number
  onChange: (event: any, rawValue: number, maskedValue: string) => void
  currencyId?: number
  classes?: Record<string, any>
  [x: string]: any
}

const MuiCurrencyInput: FC<Props> = ({ variant = 'outlined', onChange, classes, currencyId = 1, value, ...rest }) => {
  const { loading, error, data, mask: maskObject } = useCurrencySettings(currencyId)

  const TextMaskCustom: FC<any> = useCallback(
    (props: any) => {
      const { inputRef, ...other } = props

      return !data ? null : (
        <MaskedInput
          {...other}
          ref={(ref: any): any => inputRef(ref ? ref.inputElement : null)}
          mask={createNumberMask(maskObject)}
        />
      )
    },
    [maskObject]
  )

  const rawValue = (value: string): number => {
    if (!data || !value) return 0
    const noSignValue = value.replaceAll(data.sign, '')
    const formatted = noSignValue
      .replaceAll(maskObject.thousandsSeparatorSymbol, '')
      .replace(maskObject.decimalSymbol, '.')

    const parsed = parseFloat(formatted)
    return parsed
  }

  if (data) {
    return (
      <TextField
        {...rest}
        variant="outlined"
        value={value}
        onChange={(e): void => onChange(e, rawValue(e.target.value), e.target.value)}
        InputProps={{
          classes: { ...classes },
          inputComponent: TextMaskCustom as any,
        }}
      />
    )
  }
  return null
}

export default MuiCurrencyInput

// export const formatCurrencyInput = (value: string): number | string =>
//   value ? parseFloat(value.replace('€ ', '').replaceAll('.', '').replace(',', '.').trim()) : value
