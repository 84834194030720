import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import {
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Button,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  DeleteButton,
} from 'react-admin'

import List from './list/List'
import HiddenOrganizationField from './forms/HiddenOrganizationField'

const show: FC<any> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />

        <ReferenceManyField
          addLabel={false}
          perPage={100}
          reference="ProductFeatureValue"
          target="productFeatureId"
          isRowSelectable={(record: any): boolean => true}
        >
          <Datagrid rowClick="edit">
            <TextField source="value" />
            <DeleteButton undoable={false} redirect={false} />
          </Datagrid>
        </ReferenceManyField>
        <Button
          component={Link}
          to={`/ProductFeatureValue/create?productFeatureId=${props.id}`}
          color="primary"
          label="Create value"
        />
      </SimpleShowLayout>
    </Show>
  )
}

const list = (props: any): any => (
  <List {...props} perPage={40}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
  </List>
)

const edit = (props: any): any => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
)

const create = (props: any): any => (
  <Create {...props}>
    <SimpleForm>
      <HiddenOrganizationField />
      <TextInput source="name" />
    </SimpleForm>
  </Create>
)

export default { name: 'ProductFeature', show, list, create, edit }
