import React, { FC, useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import Button from '@material-ui/core/Button'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import { MUTATION_GOOGLE_LOGOUT } from '../queries'
import { useRefresh } from 'ra-core'
import { Confirm } from 'ra-ui-materialui'
import { makeStyles } from '@material-ui/styles'
import red from '@material-ui/core/colors/red'

const GoogleLogoutButton: FC = () => {
  const client = useApolloClient()
  const refresh = useRefresh()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()

  const onClick = (): void => setIsDialogOpen(true)
  const onClose = (): void => setIsDialogOpen(false)

  const onConfirmClick = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const res = await client.mutate({ mutation: MUTATION_GOOGLE_LOGOUT })
      if (!res.data.googleLogout) {
        throw new Error('Error logging out')
      }
      setLoading(false)
      console.log('ma sto refresh?????? ', res)
      setIsDialogOpen(false)
      refresh()
    } catch (error) {
      console.error('Error logging out: ', error)
    }
  }, [refresh])

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        onClick={onClick}
        endIcon={<LinkOffIcon />}
        className={classes.button}
      >
        {loading ? 'Loading...' : 'Logout from Gmail'}
      </Button>
      <Confirm
        isOpen={isDialogOpen}
        loading={loading}
        title="Confirm logout"
        content="Are you sure you want to logout?"
        onConfirm={onConfirmClick}
        onClose={onClose}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: red['900'],
    color: 'white',
  },
}))

export default GoogleLogoutButton
