import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Datagrid as RaDatagrid } from 'ra-ui-materialui'
import Empty from './list/Empty'
import { Box, Card } from '@material-ui/core'
import Loading from './Loading'

const useStyles = makeStyles((theme) => {
  return {
    // root: {
    //   '&&$active': {
    //     color: 'red !important',
    //   },
    // },
    card: {
      overflow: 'scroll',
    },
    headerCell: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      fontWeight: 'bold',
      color: 'rgba(51,51,51,0.5)',
    },
    rowCell: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    rowEven: {
      backgroundColor: '#eeeeee',
    },
  }
})

type Props = {
  emptyComponent?: any
  [x: string]: any
}

const Datagrid: FC<Props> = (props) => {
  const classes = useStyles()
  // return props.loaded && Object.keys(props.data).length > 0 ? <RaDatagrid classes={classes} {...props} /> : <Empty />
  const CustomEmpty = props.emptyComponent || <Empty />
  const shouldRenderEmptyPage =
    props.loaded && !props.loading && !props.total && !Object.keys(props.filterValues || props.ids).length
  const shouldRenderLoading = !props.loaded && props.loading

  if (shouldRenderLoading) return <Loading width="100%" height="100%" component={Card} />
  if (shouldRenderEmptyPage) return <Card>{CustomEmpty}</Card>

  return (
    <Card className={classes.card}>
      <RaDatagrid classes={classes} {...props} />
    </Card>
  )
}

export default Datagrid
