import React, { FC } from 'react'
import { useTranslate } from 'ra-core'
import {
  TextField,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
  TextInput,
} from 'react-admin'
import Datagrid from './../../components/Datagrid'
import RAList from '../../components/list/List'
import OrderStatusField from '../../components/field/OrderStatusField'
import Filter from '../../components/list/filter/Filter'
import SearchAutoCompleteInput from '../../components/SearchAutoCompleteInput'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import { ORDER_STATUS_OPTIONS } from '../../components/OrderStatusDropDown'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Typography from '@material-ui/core/Typography'
import { GoTasklist } from 'react-icons/go'
import FirstOrderRouteField from '../../components/field/FirstOrderRouteField'
import OrderMoreMenu from './OrderMoreMenu'
import { DateField } from 'ra-ui-materialui'

type Props = {
  //
}

const sort = { field: 'createdAt', order: 'DESC' }

const filterToQuery = (q: string): any => ({ q })

const OrderFilters: FC<any> = (props) => {
  const translate = useTranslate()
  return (
    <Filter {...props} variant="outlined">
      <ReferenceInput filterToQuery={filterToQuery} source="customerId" reference="Customer" label="Client" alwaysOn>
        <SearchAutoCompleteInput optionText="fullName" />
      </ReferenceInput>
      <TextInput source="reference" />
      <ReferenceInput source="teamMemberId" reference="TeamMember" filterToQuery={filterToQuery}>
        <SelectInput optionText="fullName" />
      </ReferenceInput>
      <ReferenceInput source="personId" reference="Person" filterToQuery={filterToQuery}>
        <AutocompleteInputInDrawer optionText="fullName" />
      </ReferenceInput>
      {/* <SelectInput
        fullWidth
        resettable
        source="statuses"
        choices={ORDER_STATUS_OPTIONS}
        optionValue="name"
        translateChoice={false}
        optionText={(record: any): any => (
          <Typography variant="body2" style={{ display: 'flex' }}>
            <FiberManualRecordIcon fontSize="small" style={{ color: record.color, marginRight: 6 }} />
            {translate(`customFields.orderStatus.${record.name}`)}
          </Typography>
        )}
      /> */}
      <SelectArrayInput
        fullWidth
        resettable
        source="statuses"
        choices={ORDER_STATUS_OPTIONS}
        optionValue="name"
        translateChoice={false}
        optionText={(record: any): any => (
          <Typography variant="body2" style={{ display: 'flex' }}>
            <FiberManualRecordIcon fontSize="small" style={{ color: record.color, marginRight: 6 }} />
            {translate(`customFields.orderStatus.${record.name}`)}
          </Typography>
        )}
      />
    </Filter>
  )
}

const OrderList: FC<Props> = (props) => {
  const translate = useTranslate()

  return (
    <RAList {...props} filters={<OrderFilters />} sort={sort} titleIcon={<GoTasklist />}>
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <TextField source="reference" />
        <ReferenceField source="customerId" reference="Customer" link={false}>
          <TextField source="fullName" />
        </ReferenceField>
        <FirstOrderRouteField label={translate('resources.Quote.fields.routes')} />
        <DateField source="flightDate" showTime options={{ dateStyle: 'short', timeStyle: 'short' }} />
        <ReferenceField source="teamMemberId" reference="TeamMember" link={false}>
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField source="personId" reference="Person" link={false}>
          <TextField source="email" />
        </ReferenceField>
        <OrderStatusField label="resources.Quote.fields.status" />
        {/* <GenericMoreMenu /> */}
        <OrderMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default OrderList
