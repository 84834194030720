import React, { FC } from 'react'
import { useGetList } from 'ra-core'
import ErrorIcon from '@material-ui/icons/Error'
import LinearProgress from '@material-ui/core/LinearProgress'
import { NumberField } from 'react-admin'

type Props = {
  record?: Record<string, any>
  label?: string
  source?: string
}

const PassengerTotalFlightsField: FC<Props> = ({ record = {}, ...props }: Props) => {
  const result = useGetList(
    'PassengerRoute',
    { page: 1, perPage: 1000 },
    { field: 'id', order: 'DESC' },
    { passengerId: record?.id }
  )

  if (result.loading) return <LinearProgress />
  if (result.error) return <ErrorIcon color="error" />
  if (result.total) return <NumberField {...props} record={result} source="total" />
  return null
}

export default PassengerTotalFlightsField
