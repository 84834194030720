import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslate, useNotify, useRefresh } from 'ra-core'
import { useMutation } from '@apollo/client'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { makeStyles } from '@material-ui/core'
import { MUTATION_UPDATE_QUOTE_STATUS } from '../queries'

// type Status = 'pending' | 'sent' | 'confirmed' | 'abandoned' | 'lost'
export enum QUOTE_STATUS {
  pending = 'pending',
  sent = 'sent',
  confirmed = 'confirmed',
  abandoned = 'abandoned',
  lost = 'lost',
}

type Statuses = {
  name: QUOTE_STATUS
  color: string
}[]

export const QUOTE_STATUS_OPTIONS: Statuses = [
  { name: QUOTE_STATUS.pending, color: '#edd838' },
  { name: QUOTE_STATUS.sent, color: '#1e76ea' },
  { name: QUOTE_STATUS.confirmed, color: '#62ed38' },
  { name: QUOTE_STATUS.abandoned, color: '#ed3b38' },
  { name: QUOTE_STATUS.lost, color: '#a3a09e' },
]

type Props = {
  currentStatus: QUOTE_STATUS
  id: number
}

const QuoteStatusDropDown: FC<Props> = ({ currentStatus, id }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [updateQuoteStatus, { loading, error, data }] = useMutation(MUTATION_UPDATE_QUOTE_STATUS)

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const onClose = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const onStatusClick = useCallback(
    (status: QUOTE_STATUS) => (event: React.MouseEvent<HTMLLIElement>): void => {
      event.preventDefault()
      event.stopPropagation()
      setAnchorEl(null)
      updateQuoteStatus({ variables: { status, id } })
    },
    []
  )

  useEffect(() => {
    if (!loading && !error && data && data.updateQuoteStatus) {
      notify(translate('ra.notification.updated', { smart_count: 1 }))
      refresh()
    }
  }, [data, error, loading])

  useEffect(() => {
    if (error) {
      notify(error.message, 'error')
    }
  })

  return (
    <>
      <IconButton className={classes.iconMore} edge="start" onClick={onClick}>
        <FiberManualRecordIcon
          fontSize="small"
          style={{
            marginLeft: -4,
            marginRight: 6,
            color: QUOTE_STATUS_OPTIONS.find((status: any) => status.name === currentStatus)?.color,
          }}
        />
        <Typography variant="h5">{translate(`statuses.${currentStatus}`)}</Typography>
        <ExpandMoreIcon />
      </IconButton>
      <Menu id={'statuses-drop-down'} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
        {loading ? (
          <MenuItem key="status-drop-down-loading" disabled className={classes.itemLoading}>
            <CircularProgress />
          </MenuItem>
        ) : (
          QUOTE_STATUS_OPTIONS.map((status) => {
            return (
              <MenuItem
                key={`status-drop-down-${status.name}`}
                onClick={onStatusClick(status.name)}
                disabled={status.name === currentStatus}
              >
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" style={{ color: status.color }} />
                </ListItemIcon>
                {translate(`statuses.${status.name}`)}
              </MenuItem>
            )
          })
        )}
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  iconMore: {
    margin: 0,
    padding: 0,
  },
  itemLoading: {
    minWidth: 150,
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default QuoteStatusDropDown
