import React, { FC, useEffect } from 'react'
import TextInput, { TextInputProps } from 'ra-ui-materialui/lib/input/TextInput'
import { useFormState, useForm } from 'react-final-form'

type Props = {
  //
} & Partial<TextInputProps>

export const ActivityTextValueInput: FC<Props> = ({ source = 'textValue', ...props }) => {
  const { values } = useFormState()
  const { change } = useForm()

  useEffect(() => {
    if ((values || {})[source]) {
      if (!(values || {}).activityDate && !(values || {}).activityTime) {
        change('activityDate', new Date())
        change('activityTime', new Date())
      }
    }

    if (!(values || {})[source]) {
      change('activityDate', null)
      change('activityTime', null)
      change('activityDateTime', null)
    }
  }, [values])

  return (
    <TextInput
      variant="standard"
      source={source}
      multiline
      label=""
      fullWidth
      placeholder="Write here to add a note..."
      {...props}
    />
  )
}
