import InputAdornment from '@material-ui/core/InputAdornment'
import React, { FC } from 'react'
import { TextInput, NumberInput, BooleanInput } from 'react-admin'
import FormSection from './../../components/forms/FormSection'

type Props = {}

const TaxForm: FC<Props> = (props) => (
  <FormSection title="Tax Info" {...props}>
    <TextInput source="name" />
    <NumberInput source="rate" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
    {/* <BooleanInput source="active" /> */}
  </FormSection>
)

export default TaxForm
