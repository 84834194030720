import { makeStyles, Typography, useTheme } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import { Color } from '@material-ui/lab'
import React, { FC, useMemo } from 'react'
import { FaCircle } from 'react-icons/fa'
import classNames from 'classnames'
import { useTranslate } from 'ra-core'

type Props = {
  label?: string
  source?: string
  record?: Record<string, any>
  trueValue?: string
  falseValue?: string
  trueColor?: Color | 'grey'
  falseColor?: Color | 'grey'
  variant?: Variant
  value?: boolean
  className?: string
}

const BooleanStatusField: FC<Props> = ({
  source,
  record = {},
  trueValue = 'status.enabled',
  falseValue = 'status.disabled',
  variant = 'body2',
  value,
  className,
  ...rest
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const translate = useTranslate()

  const trueColor = useMemo(() => {
    if (rest.trueColor == 'grey') return theme.palette.grey[400]
    return theme.palette[rest.trueColor || 'success'].main
  }, [rest.trueColor])

  const falseColor = useMemo(() => {
    if (rest.falseColor == 'grey' || !rest.falseColor) return theme.palette.grey[500]
    return theme.palette[rest.falseColor].main
  }, [rest.falseColor])

  if (source && record[source] === undefined)
    return (
      <Typography variant={variant} color="textSecondary" className={classNames(className)}>
        {translate('status.null')}
      </Typography>
    )
  else
    return (
      <span className={classes.wrapper}>
        <FaCircle size="0.6rem" color={(source && record[source]) || value ? trueColor : falseColor} />
        <Typography variant={variant} className={classNames(classes.text, className)}>
          {(source && record[source]) || value ? translate(trueValue) : translate(falseValue)}
        </Typography>
      </span>
    )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    marginLeft: theme.spacing(2),
  },
}))

export default BooleanStatusField
