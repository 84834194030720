import React, { FC } from 'react'
import { TextField, NumberField } from 'react-admin'
import RAList from '../../components/list/List'
import Datagrid from '../../components/Datagrid'
import Filter from '../../components/Filter'
import GenericMoreMenu from '../../components/GenericMoreMenu'
import { FaBuilding } from 'react-icons/fa'
import FilterTextInput from '../../components/FilterTextInput'
import ReferenceAddressCountryField from '../../components/field/ReferenceAddressCountryField'

const Filters: FC = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput source="name" alwaysOn />
    </Filter>
  )
}

type Props = {
  [x: string]: any
}

const List: FC<Props> = (props) => {
  return (
    <RAList {...props} filters={<Filters />} titleIcon={<FaBuilding />} bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="vat" />
        <TextField source="email" />
        <ReferenceAddressCountryField basePath={props.basePath} source="addressId" />
        <TextField source="bankAccounts.length" />
        <GenericMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default List
