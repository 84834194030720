import React from 'react'
import { Center, Heading, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { Paper } from '@material-ui/core'
import { TableContent } from './components/TableContent'

export type MostUsedVehicleModels = {
  id: number
  model: {
    id: number
    name: string
  }
  count: number
  SUM_flightDuration: number
}

interface MostUsedVehicleModelsCardProps {
  data?: MostUsedVehicleModels[]
  loading?: boolean
  error?: boolean
}

export const MostUsedVehicleModelsCard = ({ data, loading, error }: MostUsedVehicleModelsCardProps) => {
  return (
    <Paper>
      <Heading as="h4" size="sm" m="2">
        Most used models
      </Heading>
      <TableContainer overflowY="auto" maxHeight="10rem">
        <Table size="sm" variant="simple">
          <Thead position="sticky" top={0} bgColor="gray.50">
            <Tr>
              <Th>Model</Th>
              <Th isNumeric> Flights</Th>
              <Th isNumeric>Hours</Th>
            </Tr>
          </Thead>
          <TableContent loading={loading} error={error}>
            <Tbody overflow="auto">
              {data?.map(({ model, count, SUM_flightDuration }) => (
                <Tr key={model.id}>
                  <Td>{model.name}</Td>
                  <Td isNumeric>{count}</Td>
                  <Td isNumeric>{Math.round(SUM_flightDuration / 6) / 10}</Td>
                </Tr>
              ))}
              {data === undefined && (
                <Center>
                  <Spinner size="lg" m="4" />
                </Center>
              )}
            </Tbody>
          </TableContent>
        </Table>
      </TableContainer>
    </Paper>
  )
}
