import React, { FC, useCallback } from 'react'
import { TextField, NumberField, ChipField, ReferenceField } from 'react-admin'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useTranslate } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
// internal components
import VehicleEdit from './VehicleEdit'
import VehicleShow from './VehicleShow'
import RAList from '../../components/list/List'
import VehicleFilters from './VehicleFilters'
import Datagrid from '../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'
import ExportButton from '../../components/button/ExportButtont'
import GenericMoreMenu from '../../components/GenericMoreMenu'
import Drawer from '../../components/Drawer'
import DrawerHeader from '../../components/DrawerHeader'
// icons
import { VscListSelection } from 'react-icons/vsc'
import { FunctionField } from 'react-admin'
import GalleryImagesField from '../../components/field/GalleryImagesField'

type Props = {
  [x: string]: any
}

const List: FC<Props> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const history = useHistory()

  const handleDrawerClose = useCallback(() => history.push('/Vehicle'), [])

  return (
    <>
      <RAList
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<VehicleFilters variant="outlined" />}
        titleIcon={<VscListSelection />}
        bulkActionButtons={false}
        actions={
          <div>
            <CreateButton basePath={props.basePath} label="resources.Vehicle.actions.create" />
            <ExportButton
              {...props}
              className={classes.export}
              loading={false}
              onClick={(): void => console.log('TODO: implement me!!')}
            />
          </div>
        }
      >
        <Datagrid rowClick="show">
          <NumberField source="id" />
          <GalleryImagesField source="galleryId" />
          <ReferenceField source="vehicleManufacturerId" reference="VehicleManufacturer" link={false}>
            <ChipField color="primary" source="name" />
          </ReferenceField>
          <ReferenceField source="vehicleModelId" reference="VehicleModel" sortable={false} link={false}>
            <TextField source="name" />
          </ReferenceField>
          <ChipField color="primary" source="licensePlate" sortable={false} />
          <TextField source="homeBaseIcao" />
          <NumberField source="numberOfSeats" sortable={false} />
          <TextField source="yearOfManufacture" sortable={false} />
          <ReferenceField source="vehicleSafetyTypeId" reference="VehicleSafetyType" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <GenericMoreMenu />
        </Datagrid>
      </RAList>
      <Switch>
        <Route exact path="/Vehicle/:id/show">
          {({ match }): React.ReactNode => (
            <Drawer open={true} onClose={handleDrawerClose}>
              <VehicleShow
                id={match && match.params ? match.params.id : undefined}
                onCancel={handleDrawerClose}
                {...props}
              />
            </Drawer>
          )}
        </Route>
        <Route path="/Vehicle/:id">
          {({ match }): React.ReactNode => {
            const isMatch = match && match.params && match.params.id !== 'create'

            return (
              <Drawer open={isMatch} onClose={handleDrawerClose}>
                {isMatch ? (
                  <>
                    <DrawerHeader
                      buttonOnClick={handleDrawerClose}
                      title={`${translate('ra.action.edit')} #${match?.params?.id}`}
                    />
                    <VehicleEdit
                      id={isMatch && match && match.params ? match.params.id : undefined}
                      onCancel={handleDrawerClose}
                      {...props}
                    />
                  </>
                ) : (
                  <div className={classes.drawerContent} />
                )}
              </Drawer>
            )
          }}
        </Route>
      </Switch>
    </>
  )
}

const useStyles = makeStyles((theme: any) => ({
  export: {
    marginLeft: theme.spacing(3),
  },
  drawerContent: {
    width: '26vw',
    minWidth: 260,
    // eslint-disable-next-line quotes
    content: "''",
  },
}))

export default List
