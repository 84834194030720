import React, { FC, useState, useCallback, useMemo, useEffect } from 'react'
import { useFormState, useForm } from 'react-final-form'
import { useGetOne, useTranslate } from 'ra-core'
import { ReferenceInput } from 'react-admin'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useCurrencySettings from '../../hooks/useCurrencySettings'
import Drawer from './../../components/Drawer'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import BaseButton from '../../components/button/BaseButton'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import MuiCurrencyInput from '../../components/MuiCurrencyInput'
import Button from '@material-ui/core/Button'
import MuiPercentageInput from '../../components/MuiPercentageInput'
import EditIcon from '@material-ui/icons/Edit'
import IOSSwitch from '../../components/IOSSwitch'
import TaxReferenceInput from '../../components/input/TaxReferenceInput'
import RichTextInput from 'ra-input-rich-text'

const filterToQueryLicensePlate = (licensePlate: string): Record<string, string> => ({ licensePlate })

const VATCalculator: FC<any> = ({ sellingPriceWithoutVat, setVATValue, mask, fullWidth }) => {
  const classes = useStyles()
  const { values } = useFormState()
  const { loading, error, data } = useGetOne('Tax', values.taxId, { enabled: values && !!values.taxId })

  const VATValue = useMemo((): number => {
    if (!values || typeof values.taxId === 'undefined') {
      return 0
    } else if (!loading && data && sellingPriceWithoutVat) {
      const { rate } = data
      const rawValue = parseFloat(sellingPriceWithoutVat.replace(mask.decimalSymbol, '.'))
      return (rawValue / 100) * rate
    } else {
      return 0
    }
  }, [values?.taxId, sellingPriceWithoutVat, loading, data])

  useEffect(() => {
    if (typeof VATValue !== 'undefined') {
      setVATValue(VATValue)
    }
  }, [VATValue])

  return (
    <MuiCurrencyInput
      fullWidth={fullWidth}
      label="Tax Value"
      name="taxValue"
      value={VATValue.toString().replace('.', mask.decimalSymbol)}
      disabled
      onChange={() => {}}
      classes={{ input: classes.input }}
      currencyId={values?.currencyId}
    />
  )
}

const VehicleWithCarbonOffsetInput: FC<any> = ({ formValues, ...props }) => {
  const carbonOffsetId = useMemo((): string | null | undefined => {
    if (props.choices && props.input && props.input.value) {
      return props.choices.filter((vehicle: any) => vehicle.id === props.input.value)[0].vehicleCarbonOffsetId || null
    } else return undefined
  }, [props.choices, props.input.value])

  const { data } = useGetOne('VehicleCarbonOffset', carbonOffsetId as string, { enabled: !!carbonOffsetId })

  const totalDuration = useMemo((): number | null => {
    if (formValues.routes && Array.isArray(formValues.routes)) {
      const value = formValues.routes.reduce(
        (accumulator: number, current: any) =>
          !isNaN(current.flightDuration) ? accumulator + current.flightDuration : accumulator,
        0
      )
      return value
    } else return null
  }, [props.formValues])

  const helperText = useMemo((): string => {
    if (carbonOffsetId === undefined) return ''
    else if (carbonOffsetId === null) return 'No carbon offset category for this aircraft.'
    else if (!totalDuration || totalDuration === 0) return 'Please add flight durations to calculate carbon offset.'
    else if (!data) return 'Error getting the carbon offset category.'
    else return `Carbon offset: ${((totalDuration * data.value) / 60).toFixed(2)}`
  }, [data, totalDuration, carbonOffsetId])

  return <AutocompleteInputInDrawer {...props} optionText="licensePlate" fullWidth helperText={helperText} />
}

const AddOptionButtonDrawer: FC<any> = (props) => {
  const theme = useTheme()
  const mediaquery = useMediaQuery(theme.breakpoints.up('lg'))
  const mediaquerySm = useMediaQuery(theme.breakpoints.down('sm'))
  const translate = useTranslate()
  const classes = useStyles()
  const form = useForm()
  const { values } = useFormState()

  const { loading: loadingCurrency, mask } = useCurrencySettings(values.currencyId)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(props.handleSubmit ? true : false)

  const [optionsRecord, setOptionsRecord] = useState<Record<string, any>>({
    purchasePrice: '0',
    passengerTax: '0',
    commissionsPercentage: '0',
    commissions: '0',
    showYomYor: false,
    excludeVatFromFinalPrice: false,
  })
  const [rawValues, setRawValues] = useState<Record<string, number>>({
    purchasePrice: 0,
    passengerTax: 0,
    commissions: 0,
  })
  const [sellingPrice, setSellingPrice] = useState<string>('0')
  const [sellingPriceWithoutTaxes, setSellingPriceWithoutTaxes] = useState<string>('0')
  const [VATValue, setVATValue] = useState<number>(0)

  const onButtonClick = useCallback((): void => setIsDrawerOpen(true), [])

  const onClose = useCallback((): void => {
    form.change('vehicleId', undefined)
    form.change('taxId', undefined)
    form.change('optionDescription', undefined)
    setOptionsRecord({
      purchasePrice: '0',
      passengerTax: '0',
      commissions: '0',
      commissionsPercentage: '0',
      showYomYor: false,
      excludeVatFromFinalPrice: false,
    })
    setRawValues({
      purchasePrice: 0,
      passengerTax: 0,
      commissions: 0,
    })
    setSellingPrice('0')
    setSellingPriceWithoutTaxes('0')
    setVATValue(0)
    setIsDrawerOpen(false)
  }, [])

  const onInputChange = useCallback(
    (event: any, rawValue: number): void => {
      const name = event.target.name
      const value = event.target.value

      if (name === 'commissions') {
        const tmpCommissionsPercentage = (rawValue * 100) / rawValues.purchasePrice
        setOptionsRecord({
          ...optionsRecord,
          commissions: value,
          commissionsPercentage: tmpCommissionsPercentage.toString().replace('.', ','),
        })
        setRawValues({
          ...rawValues,
          commissions: rawValue,
        })
      } else if (name === 'purchasePrice' && optionsRecord.commissionsPercentage !== '0') {
        // need to recalculate commissions
        const percentage = parseFloat(optionsRecord.commissionsPercentage.replace(',', '.'))
        let commissions = 0
        if (typeof percentage === 'number') {
          commissions = (rawValue / 100) * percentage
        }
        setOptionsRecord({
          ...optionsRecord,
          [event.target.name]: event.target.value,
          commissions: commissions.toString().replace('.', mask.decimalSymbol),
        })
        setRawValues({
          ...rawValues,
          [event.target.name]: rawValue,
          commissions,
        })
      } else {
        setOptionsRecord({
          ...optionsRecord,
          [event.target.name]: event.target.value,
        })
        setRawValues({
          ...rawValues,
          [event.target.name]: rawValue,
        })
      }
    },
    [optionsRecord, rawValues, mask]
  )

  const onPercentageInputChange = useCallback(
    (event: any): void => {
      const name = event.target.name
      const value = event.target.value
      const formattedValue = parseFloat(value.replace(',', '.'))

      if (name === 'commissionsPercentage') {
        let commissions = 0
        if (typeof formattedValue === 'number') {
          commissions = (rawValues.purchasePrice / 100) * formattedValue
        }
        setOptionsRecord({
          ...optionsRecord,
          commissions: commissions.toString().replace('.', mask.decimalSymbol),
          commissionsPercentage: value,
        })
        setRawValues({ ...rawValues, commissions })
      }
    },
    [optionsRecord, rawValues.purchasePrice, mask]
  )

  const onShowYomYorChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setOptionsRecord({ ...optionsRecord, showYomYor: checked })
  }

  const onExcludeVatChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setOptionsRecord({ ...optionsRecord, excludeVatFromFinalPrice: checked })
  }

  const onInputFocus = useCallback((event) => {
    event.target.select()
  }, [])

  const onAddClick = useCallback((): void => {
    if (props.savedRecord) {
      const optionsArray = values.options.map((option: any, index: any) => {
        if (option.id === rawValues.id) {
          return {
            id: option.id,
            vehicleId: values.vehicleId,
            // description: optionsRecord.description,
            description: values.optionDescription || null,
            sellingPriceWithoutTaxes: rawValues.purchasePrice + rawValues.commissions,
            purchasePrice: rawValues.purchasePrice,
            commissions: rawValues.commissions,
            // transferCost: rawValues.transferCost,
            passengerTax: rawValues.passengerTax,
            sellingPrice: rawValues.purchasePrice + rawValues.commissions + rawValues.passengerTax + VATValue,
            showYomYor: optionsRecord.showYomYor,
            taxId: values.taxId,
            excludeVatFromFinalPrice: optionsRecord.excludeVatFromFinalPrice,
          }
        } else return option
      })
      form.change('options', optionsArray)
    } else {
      const recordToPush = {
        ...rawValues,
        id: new Date().getTime(),
        vehicleId: values.vehicleId,
        // description: optionsRecord.description,
        description: values.optionDescription || null,
        sellingPriceWithoutTaxes: rawValues.purchasePrice + rawValues.commissions,
        sellingPrice: rawValues.purchasePrice + rawValues.commissions + rawValues.passengerTax + VATValue,
        showYomYor: optionsRecord.showYomYor,
        taxId: values.taxId,
        excludeVatFromFinalPrice: optionsRecord.excludeVatFromFinalPrice,
      }
      form.mutators.push('options', recordToPush)
    }
    onClose()
  }, [
    optionsRecord,
    values?.vehicleId,
    rawValues,
    props?.savedRecord,
    VATValue,
    values?.taxId,
    values?.optionDescription,
  ])

  const disableAddButton = useMemo(
    (): boolean =>
      Object.keys(optionsRecord).length === 0 ||
      !optionsRecord.purchasePrice ||
      optionsRecord.purchasePrice === '' ||
      !optionsRecord.commissions ||
      optionsRecord.commissions === '' ||
      !values.vehicleId,
    [optionsRecord, values?.vehicleId]
  )

  useEffect(() => {
    if (isDrawerOpen && props.savedRecord) {
      form.change('vehicleId', props.savedRecord.vehicleId)
      form.change('taxId', props.savedRecord.taxId)
      form.change('optionDescription', props.savedRecord.description)

      setOptionsRecord({
        ...props.savedRecord,
        commissionsPercentage: ((props.savedRecord.commissions * 100) / props.savedRecord.purchasePrice)
          .toString()
          .replace('.', ','),
      })

      setRawValues({ ...props.savedRecord })
      setSellingPrice(props.savedRecord.sellingPrice.toString())
      setSellingPriceWithoutTaxes(props.savedRecord.sellingPriceWithoutTaxes.toString())
    }
  }, [isDrawerOpen, props?.savedRecord])

  useEffect(() => {
    const sumWithoutTaxes = rawValues.purchasePrice + rawValues.commissions

    if (!loadingCurrency && sumWithoutTaxes > 0) {
      setSellingPriceWithoutTaxes(sumWithoutTaxes.toString().replace('.', mask.decimalSymbol))
      const sumWithTaxes = sumWithoutTaxes + rawValues.passengerTax + VATValue
      setSellingPrice(sumWithTaxes.toString().replace('.', mask.decimalSymbol))
    }
  }, [optionsRecord, mask, VATValue])

  return (
    <Box pt={props.savedRecord ? 0 : mediaquerySm ? 0 : 3} ml={props.savedRecord ? 2 : 0}>
      {!props.handleSubmit && (
        <Button
          fullWidth={props.savedRecord ? false : mediaquerySm ? false : true}
          onClick={onButtonClick}
          variant="contained"
          size={mediaquerySm ? undefined : 'large'}
          className={props.savedRecord ? undefined : classes.addButton}
          // {...props}
        >
          {props.savedRecord ? <EditIcon /> : 'Add option'}
        </Button>
      )}
      <Drawer open={isDrawerOpen} anchor="right" onClose={onClose}>
        <div>
          <div className={classes.row}>
            <Typography variant="h2">{`${props.savedRecord ? 'Edit' : 'Add'} option`}</Typography>
            <BaseButton onClick={onClose}>{translate('ra.action.cancel')}</BaseButton>
          </div>
          <div className={classes.row}>
            <ReferenceInput
              source="vehicleId"
              reference="Vehicle"
              // record={optionsRecord}
              filterToQuery={filterToQueryLicensePlate}
              variant="outlined"
            >
              <VehicleWithCarbonOffsetInput formValues={values} />
            </ReferenceInput>
          </div>
          <div className={classes.row}>
            <MuiCurrencyInput
              fullWidth
              label="Purchase price"
              name="purchasePrice"
              value={optionsRecord.purchasePrice}
              onChange={onInputChange}
              onFocus={onInputFocus}
              classes={{ input: classes.input, root: classes.rootInput }}
              currencyId={values?.currencyId}
              // required
            />
          </div>
          <div className={classes.commissionsRow}>
            <MuiCurrencyInput
              label="Commissions"
              name="commissions"
              value={optionsRecord.commissions}
              onChange={onInputChange}
              classes={{ input: classes.input, root: classes.rootInput }}
              currencyId={values?.currencyId}
              fullWidth={mediaquery ? false : true}
            />
            {!mediaquery && <Box mt={4} />}
            <MuiPercentageInput
              label="Commissions %"
              name="commissionsPercentage"
              value={optionsRecord.commissionsPercentage}
              onChange={onPercentageInputChange}
              classes={{ input: classes.input }}
              fullWidth={mediaquery ? false : true}
            />
          </div>
          <div className={classes.commissionsRow}>
            <div className={mediaquery ? classes.halfRow : undefined}>
              <TaxReferenceInput
                fullWidth={mediaquery ? false : true}
                classes={{ input: classes.input, root: classes.rootInput }}
              />
            </div>
            {!mediaquery ? <Box mt={4} /> : <Box mr={3} />}
            <div className={mediaquery ? classes.halfRow : undefined}>
              <VATCalculator
                sellingPriceWithoutVat={sellingPriceWithoutTaxes}
                setVATValue={setVATValue}
                mask={mask}
                fullWidth={mediaquery ? false : true}
              />
            </div>
          </div>
          <div className={classes.row}>
            <MuiCurrencyInput
              fullWidth
              label="Passenger Tax"
              name="passengerTax"
              value={optionsRecord.passengerTax}
              onChange={onInputChange}
              onFocus={onInputFocus}
              classes={{ input: classes.input, root: classes.rootInput }}
              currencyId={values?.currencyId}
              // required
            />
          </div>
          <div className={classes.commissionsRow}>
            <MuiCurrencyInput
              fullWidth={mediaquery ? false : true}
              label="Selling price (no taxes)"
              name="sellingPriceWithoutTaxes"
              value={sellingPriceWithoutTaxes}
              disabled
              onChange={() => {}}
              classes={{ input: classes.input, root: classes.rootInput }}
              currencyId={values?.currencyId}
            />
            {!mediaquery && <Box mt={4} />}
            <MuiCurrencyInput
              fullWidth={mediaquery ? false : true}
              label="Selling price"
              name="sellingPrice"
              value={sellingPrice}
              disabled
              onChange={() => {}}
              classes={{ input: classes.input }}
              currencyId={values?.currencyId}
            />
          </div>
          <div className={classes.row}>
            {/* <MuiTextInput
              fullWidth
              multiline
              variant="outlined"
              name="description"
              label="Description"
              onChange={onDescriptionChange}
              value={optionsRecord.description}
              // classes={{ input: classes.input, root: classes.rootInput }}
            /> */}
            <RichTextInput source="optionDescription" label="Description" />
          </div>
          <div className={classes.row}>
            <Typography>Show YOM/YOR</Typography>
            <IOSSwitch {...props} onChange={onShowYomYorChange} checked={optionsRecord.showYomYor} />
          </div>
          <div className={classes.row}>
            <Typography>Exclude VAT in print</Typography>
            <IOSSwitch {...props} onChange={onExcludeVatChange} checked={optionsRecord.excludeVatFromFinalPrice} />
          </div>
          <Button
            color="primary"
            variant="contained"
            className={classes.createButton}
            onClick={props.handleSubmit ? props.handleSubmit({ rawValues, optionsRecord, sellingPrice }) : onAddClick}
            disabled={disableAddButton}
          >
            {props.savedRecord ? 'Update' : translate('ra.action.add')}
          </Button>
        </div>
      </Drawer>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    boxShadow: theme.shadows[4],
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  commissionsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(4),
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  input: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  rootInput: {
    marginRight: theme.spacing(3),
  },
  createButton: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '100%',
  },
  halfRow: {
    width: '50%',
  },
}))

export default AddOptionButtonDrawer
