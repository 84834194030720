import React, { FC } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField } from 'react-admin'

import Datagrid from './../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'
import { FaStore } from 'react-icons/fa'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import FilterTextInput from '../../components/FilterTextInput'
import BooleanStatusField from '../../components/BooleanStatusField'
import Filter from '../../components/list/filter/Filter'
import OfficeAreaField from '../../components/CustomFields/OfficeAreaField'
import { Pagination, PaginationLimit } from '../../components/list/pagination'
import OfficeAreaInput from '../../components/OfficeAreaInput'
import { RadioButtonGroupInput } from 'react-admin'

type Props = {}

const OfficeFilters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput label="Cerca" source="name" alwaysOn />
      <OfficeAreaInput label="Seleziona l'area" />

      <RadioButtonGroupInput
        label={'Pubblico - Privato'}
        source="active"
        choices={[
          { id: true, name: 'Pubblico' },
          { id: false, name: 'Privato' },
        ]}
      />
    </Filter>
  )
}

const OfficeList: FC<Props> = (props) => {
  return (
    <RAList
      {...props}
      title="Punti Vendita"
      titleIcon={<FaStore />}
      actions={<CreateButton label="Nuovo P.V." />}
      filters={<OfficeFilters variant="outlined" />}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <TextField source="name" />
        <OfficeAreaField source="areaId" />
        <BooleanStatusField source="active" trueValue="Pubblico" falseValue="Privato" label="Status" />
        <GenericMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default OfficeList
