import React, { FC } from 'react'
import { TextField, NumberField, ReferenceField, ReferenceInput, DateField, DateInput } from 'react-admin'
import Datagrid from './../../components/Datagrid'
import RAList from '../../components/list/List'
import OrderStatusField from '../../components/field/OrderStatusField'
import Filter from '../../components/list/filter/Filter'
import SearchAutoCompleteInput from '../../components/SearchAutoCompleteInput'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import Typography from '@material-ui/core/Typography'
import { GoTasklist } from 'react-icons/go'
import OrderRouteAirportsField from '../../components/field/OrderRouteAirportsField'
import FlightDurationField from '../../components/field/FlightDurationField'
import OrderRouteStatusField from '../../components/field/OrderRouteStatusField'
import { useMemo } from 'react'

type Props = {
  [x: string]: any
}
const sort = { field: 'flightDate', order: 'ASC' }

const onRowClick = (id: string, basePath: string, record: Record<string, any>): string | undefined => {
  return record && record.orderId ? `/Order/${record.orderId}/show?selectedRoute=${record.id}` : undefined
}

const filterToQuery = (name: string): any => ({ name })
const filterToQueryOrder = (reference: string): any => ({ reference })
const filterToQueryClient = (q: string): any => ({ q })
const airportOptionText = (record: any): string =>
  `${record.iataCode ? `${record.iataCode} (${record.icaoCode})` : record.icaoCode} - ${record.name}`

const OrderRouteFilters: FC<any> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <ReferenceInput
        filterToQuery={filterToQuery}
        source="departureAirportId"
        reference="Airport"
        label="Departure airport"
        alwaysOn
      >
        <SearchAutoCompleteInput optionText={airportOptionText} />
      </ReferenceInput>
      <ReferenceInput
        filterToQuery={filterToQuery}
        source="arrivalAirportId"
        reference="Airport"
        label="Arrival airport"
      >
        <AutocompleteInputInDrawer optionText={airportOptionText} />
      </ReferenceInput>
      <ReferenceInput filterToQuery={filterToQueryOrder} source="orderId" reference="Order" label="Order reference">
        <AutocompleteInputInDrawer optionText="reference" />
      </ReferenceInput>
      <DateInput source="flightDate" />
      <Typography variant="h4">Date range</Typography>
      <DateInput source="startDate" />
      <DateInput source="endDate" />
      {/* <ReferenceInput source="orderId" reference="Order">
        <ReferenceInput filterToQuery={filterToQueryClient} source="customerId" reference="Customer" label="ciao">
          <AutocompleteInputInDrawer optionText="fullName" />
        </ReferenceInput>
      </ReferenceInput> */}
      {/* <ReferenceInput source="personId" reference="Person" filterToQuery={filterToQuery}>
        <AutocompleteInputInDrawer optionText="fullName" />
      </ReferenceInput> */}
    </Filter>
  )
}

const OrderRouteList: FC<Props> = (props) => {
  const defaultFilters = useMemo(() => {
    const endDate = new Date()
    endDate.setDate(endDate.getDate() + 7)
    return { startDate: new Date().toISOString(), endDate: endDate.toISOString() }
  }, [])

  return (
    <RAList
      {...props}
      filterDefaultValues={defaultFilters}
      sort={sort}
      titleIcon={<GoTasklist />}
      filters={<OrderRouteFilters />}
    >
      <Datagrid rowClick={onRowClick}>
        <ReferenceField label="Order reference" source="orderId" reference="Order" link="show" sortable={false}>
          <TextField source="reference" />
        </ReferenceField>
        <OrderRouteAirportsField label="Route" />
        <DateField source="flightDate" showTime options={{ timeZone: 'UTC', dateStyle: 'short', timeStyle: 'short' }} />
        {/* <ArrivalDateField label="Arrival date" /> */}
        <DateField
          source="arrivalDate"
          showTime
          options={{ timeZone: 'UTC', dateStyle: 'short', timeStyle: 'short' }}
        />
        <FlightDurationField label="Flight duration" />
        <NumberField source="passengersQuantity" label="Pax" />
        <ReferenceField label="Client" source="orderId" reference="Order" link={false}>
          <ReferenceField source="customerId" reference="Customer" link={false}>
            <TextField source="fullName" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField label="Account manager" source="orderId" reference="Order" link={false}>
          <ReferenceField source="teamMemberId" reference="TeamMember" link={false}>
            <TextField source="fullName" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField label="Order status" source="orderId" reference="Order" link={false}>
          <OrderStatusField />
        </ReferenceField>
        <OrderRouteStatusField label="Route status" />
      </Datagrid>
    </RAList>
  )
}

export default OrderRouteList
