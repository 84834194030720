/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback } from 'react'
import { Link } from 'react-router-dom'
import {
  Datagrid,
  NumberInput,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleList,
  Pagination,
  Show,
  SimpleShowLayout,
  ReferenceField,
  ReferenceManyField,
  CreateButton,
  CloneButton,
  SelectInput,
  EditButton,
  DeleteButton,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import { useRedirect } from 'ra-core'

import SortableDatagrid from './SortableDatagrid'
import { makeStyles } from '@material-ui/styles'
import List from './list/List'
import HiddenOrganizationField from './forms/HiddenOrganizationField'

const useListStyles = makeStyles({
  datagrid: {
    marginTop: '200px',
  },
})

export const ProductAttributeShow: FC<any> = (props) => {
  const classes = useListStyles()

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />

        <ReferenceManyField
          addLabel={false}
          pagination={<Pagination rowsPerPageOptions={[100, 200]} />}
          perPage={100}
          reference="ProductAttributeValue"
          target="productAttributeId"
          sort={{ field: 'order', order: 'ASC' }}
          isRowSelectable={(record: any): boolean => true}
        >
          <SortableDatagrid className={classes.datagrid} sortableSource="order" rowClick="edit">
            <TextField sortable={false} source="value" />
            <TextField sortable={false} source="label" />
            <TextField sortable={false} source="order" />
            {/* <EditButton /> */}
            <DeleteButton undoable={false} redirect={false} />
          </SortableDatagrid>
        </ReferenceManyField>
        <Button
          component={Link}
          to={{
            pathname: '/ProductAttributeValue/create',
            state: { record: { productAttribute: props.id } },
          }}
          color="primary"
        >
          Create Value
        </Button>
      </SimpleShowLayout>
    </Show>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AttributesPagination: FC = (props) => <Pagination rowsPerPageOptions={[20, 40, 50, 100]} {...props} />

export const ProductAttributeList = (props: any): any => (
  <List {...props} sort={{ field: 'order', order: 'ASC' }} perPage={40} pagination={<AttributesPagination />}>
    <SortableDatagrid sortableSource="order" rowClick="show">
      <TextField sortable={false} source="id" />
      <TextField sortable={false} source="name" />
      <TextField sortable={false} source="label" />
      <TextField sortable={false} source="order" />
    </SortableDatagrid>
  </List>
)

export const ProductAttributeEdit = (props: any): any => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <TextInput source="label" />
      <SelectInput
        source="type"
        choices={[
          { id: 'COLOR_OR_TEXTURE', name: 'Color/Texture' },
          { id: 'DROPDOWN', name: 'Dropdown list' },
          { id: 'RADIO_BUTTONS', name: 'Radio buttons' },
        ]}
      />
    </SimpleForm>
  </Edit>
)

export const ProductAttributeCreate = (props: any): any => (
  <Create {...props}>
    <SimpleForm>
      <HiddenOrganizationField />
      <TextInput source="name" />
      <TextInput source="label" />
      <NumberInput source="order" />
      <SelectInput
        source="type"
        choices={[
          { id: 'COLOR_OR_TEXTURE', name: 'Color/Texture' },
          { id: 'DROPDOWN', name: 'Dropdown list' },
          { id: 'RADIO_BUTTONS', name: 'Radio buttons' },
        ]}
      />
    </SimpleForm>
  </Create>
)

export default {
  name: 'ProductAttribute',
  show: ProductAttributeShow,
  list: ProductAttributeList,
  create: ProductAttributeCreate,
  edit: ProductAttributeEdit,
}
