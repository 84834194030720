import React, { FC } from 'react'
import { HiTemplate } from 'react-icons/hi'
import RAList from '../../components/list/List'
import { TextField, NumberField, DateField } from 'react-admin'
import Datagrid from './../../components/Datagrid'
import GenericMoreMenu from '../../components/GenericMoreMenu'

type Props = {
  [x: string]: any
}

const sort = { field: 'createdAt', order: 'DESC' }

const GmailTemplateList: FC<Props> = (props) => {
  return (
    <RAList {...props} titleIcon={<HiTemplate />} sort={sort}>
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="category" />
        <DateField source="createdAt" />
        <GenericMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default GmailTemplateList
