import React, { FC } from 'react'
import {
  TextField,
  SimpleShowLayout,
  ReferenceField,
  DateField,
  ReferenceManyField,
  SingleFieldList,
} from 'react-admin'
import { useTranslate } from 'ra-core'
import { makeStyles } from '@material-ui/core'
// internal components
import Show from '../../components/details/Show'
import SimpleOrderRouteWithClientField from '../../components/field/SimpleOrderRouteWithClientField'

type Props = {
  [x: string]: any
}

const InDrawerPassengerShow: FC<Props> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <Show {...props} inDrawer title={`${translate('resources.Passenger.name', { smart_count: 1 })} #${props.id}`}>
      <SimpleShowLayout className={classes.root}>
        <TextField source="lastName" />
        <TextField source="firstName" />
        <ReferenceField reference="Country" source="nationality" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <DateField source="birthDate" />
        <TextField source="documentType" />
        <TextField source="documentNumber" />
        <DateField source="releaseDate" />
        <DateField source="expirationDate" />
        {/* <ReferenceManyField reference="PassengerRoute" target="passengerId" source="id" label="Flight routes">
          <SingleFieldList>
            <ReferenceField reference="OrderRoute" source="orderRouteId">
              <OrderRouteAirportsField label="Route" />
            </ReferenceField>
          </SingleFieldList>
        </ReferenceManyField> */}
        <ReferenceManyField
          reference="PassengerRoute"
          target="passengerId"
          source="id"
          label="Flight routes"
          link={false}
        >
          <SingleFieldList linkType={false}>
            <ReferenceField reference="OrderRoute" source="orderRouteId" link={false}>
              <SimpleOrderRouteWithClientField />
              {/* <ReferenceField reference="Order" source="orderId">
                <ReferenceField reference="Customer" source="customerId">
                  <ClientNameField />
                </ReferenceField>
              </ReferenceField> */}
            </ReferenceField>
          </SingleFieldList>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  )
}

const useStyles = makeStyles((theme) => ({
  root: { padding: 0, display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0px, 1fr));', fontSize: '24px' },
}))

export default InDrawerPassengerShow
