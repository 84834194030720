import * as React from 'react'
import { Children, cloneElement, FC } from 'react'
import Card from '@material-ui/core/Card'
import Container from '@material-ui/core/Container'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { defaultExporter, ListControllerProps, useListContext, getListControllerProps, useVersion } from 'ra-core'
import DefaultActions from './ListActions'

import {
  // ListToolbar,
  BulkActionsToolbar,
} from 'ra-ui-materialui'
import DefaultPagination from './pagination/Pagination'
import { BulkDeleteButton } from 'react-admin'
import { ListProps } from '../../types'
import Empty from './Empty'
import ListToolbar from './ListToolbar'
import Title from '../layout/Title'
import { Box, Fade } from '@material-ui/core'

type CustomProps = {
  titleIcon?: React.ReactNode
}

// TODO: FIXME: BULK ACTIONS NOT WORKING WITH EVERY RESOURCE
// Uncaught TypeError: action.payload.ids is undefined
export const ListView: FC<ListViewProps & CustomProps> = (props) => {
  const {
    actions,
    aside,
    filters,
    bulkActionButtons,
    pagination,
    children,
    className,
    classes: classesOverride,
    component: Content,
    exporter = defaultExporter,
    title,
    titleIcon,
    empty = <Empty bgcolor="backround.paper" />,
    ...rest
  } = props
  const controllerProps = getListControllerProps(props) // deprecated, to be removed in v4
  const listContext = useListContext(props)
  const classes = useStyles(props)
  const { defaultTitle, total, loaded, loading, hasCreate, filterValues, selectedIds } = listContext
  const version = useVersion()

  const FinalContent = Content ? Content : Card

  const paginationComponent = pagination ? cloneElement(pagination, listContext) : <></>

  const shouldRenderEmptyPage = hasCreate && loaded && !loading && !total && !Object.keys(filterValues).length
  const shouldRenderNoResultPage = hasCreate && loaded && !loading && !total && Object.keys(filterValues).length

  const renderList = (): React.ReactNode => (
    <>
      {(filters || actions) && (
        <ListToolbar
          filters={filters}
          {...controllerProps} // deprecated, use ListContext instead, to be removed in v4
          exporter={exporter} // deprecated, use ListContext instead, to be removed in v4
          pagination={paginationComponent}
        />
      )}
      <div className={classes.main}>
        <Box display="flex" flexDirection="column" width="100%">
          {!shouldRenderNoResultPage && (
            <>
              <FinalContent
                className={classnames(classes.content, {
                  [classes.bulkActionsDisplayed]: selectedIds.length > 0,
                })}
                key={version}
              >
                {bulkActionButtons !== false && bulkActionButtons && (
                  <BulkActionsToolbar
                    classes={{ toolbar: classes.toolbar, collapsed: classes.collapsed, buttons: 'testino' }}
                    {...controllerProps}
                  >
                    <Box className={classes.actionButton}>{bulkActionButtons}</Box>
                  </BulkActionsToolbar>
                )}
                {children &&
                  cloneElement(Children.only(children), {
                    ...controllerProps, // deprecated, use ListContext instead, to be removed in v4
                    hasBulkActions: bulkActionButtons !== false,
                  })}
                {/* {pagination && cloneElement(pagination, listContext)} */}
              </FinalContent>
              <Box mt={2}>{paginationComponent}</Box>{' '}
            </>
          )}
        </Box>

        {aside && cloneElement(aside, listContext)}
      </div>
    </>
  )

  return (
    <Fade in={loaded}>
      <Container
        maxWidth={false}
        className={classnames('list-page', classes.root, className)}
        {...sanitizeRestProps(rest)}
      >
        <div className={classes.firstRowWrapper}>
          <Title icon={titleIcon} title={title} defaultTitle={defaultTitle} disableGoBack />
          {actions &&
            React.cloneElement(actions, {
              ...controllerProps,
              className: classes.actions,
              exporter,
              filters,
              ...actions.props,
            })}
        </div>
        {shouldRenderEmptyPage && empty ? cloneElement(empty, listContext) : undefined}
        {!shouldRenderEmptyPage ? renderList() : undefined}
        {shouldRenderNoResultPage && empty
          ? cloneElement(empty, {
              ...listContext,
              title: 'Nessun risultato disponibile',
              message: 'I filtri o la ricerca fatta non ha portato a dei risultati',
              component: Card,
              margin: 0,
              buttonComponent: <></>,
            })
          : undefined}
      </Container>
    </Fade>
  )
}

const DefaultBulkActionButtons: FC = (props) => {
  const classes = useStyles(props)

  return <BulkDeleteButton className={classes.actionButton} />
}

ListView.defaultProps = {
  actions: <DefaultActions />,
  classes: {},
  component: Card,
  bulkActionButtons: <DefaultBulkActionButtons />,
  pagination: <DefaultPagination />,
  empty: <Empty />,
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: '100%',
      paddingTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      display: 'flex',
    },
    content: {
      marginTop: 0,
      transition: theme.transitions.create('margin-top'),
      position: 'relative',
      flex: '1 1 auto',
      [theme.breakpoints.down('xs')]: {
        boxShadow: 'none',
      },
      overflow: 'inherit',
    },
    bulkActionsDisplayed: {
      margin: 0,
    },
    actions: {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        // backgroundColor: theme.palette.background.paper,
      },
    },
    noResults: { padding: 20 },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      fontWeight: 'bold',
      marginLeft: theme.spacing(2),
    },
    firstRowWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    iconTitleWrapper: {
      backgroundColor: 'white',
      color: 'black',
      width: '45px',
      height: '45px',
      boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    },
    toolbar: {
      color: 'rgb(237 29 35)',
      minHeight: '50px',
      zIndex: 3,
      transition:
        ' height, margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, min-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      justifyContent: 'space-between',
      backgroundColor: 'rgb(252, 227, 228)',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[2],
      marginBottom: theme.spacing(2),
    },
    collapsed: {
      minHeight: 0,
      marginBottom: theme.spacing(0),
    },
    actionButton: {
      marginTop: 0,
      marginBottom: 0,
      top: '-6px', //Fix it! temporary
    },
    '@media screen and (min-width: 1024px)': {
      root: {
        paddingTop: theme.spacing(10),
      },
    },
  }),
  { name: 'RaList' }
)

export interface ListViewProps
  extends Omit<ListProps, 'basePath' | 'hasCreate' | 'perPage' | 'resource'>,
    ListControllerProps {}

const sanitizeRestProps: (props: any) => any = ({
  basePath,
  currentSort,
  data,
  defaultTitle,
  displayedFilters,
  filterDefaultValues,
  filterValues,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  hideFilter,
  history,
  ids,
  loading,
  loaded,
  location,
  match,
  onSelect,
  onToggleItem,
  onUnselectItems,
  options,
  page,
  permissions,
  perPage,
  resource,
  selectedIds,
  setFilters,
  setPage,
  setPerPage,
  setSort,
  showFilter,
  sort,
  total,
  ...rest
}) => rest

export default ListView
