import { gql, useApolloClient } from '@apollo/client'
import { useDataProvider, useNotify, useRedirect } from 'ra-core'
import React, { FC, useCallback } from 'react'
import { useFormState } from 'react-final-form'
import SaveButton from '../../components/button/SaveButton'

type Props = {
  [x: string]: any
}

const SaveUserButton: FC<Props> = (props) => {
  const dataProvider = useDataProvider()
  const client = useApolloClient()
  const redirect = useRedirect()
  const notify = useNotify()
  const handleSave = useCallback(async (data?: any) => {
    let userResult: any = {}
    try {
      if (data.roleId) {
        data.rolesIds = [data.roleId]
      }

      const userMutationResult = data.id
        ? await dataProvider.update('User', {
            data,
            id: data.id,
            previousData: data,
          })
        : await dataProvider.create('User', {
            data,
          })

      if (!userMutationResult || !userMutationResult.data || !userMutationResult.data.id) {
        throw new Error('Unexpected Error')
      }
      userResult = userMutationResult.data
    } catch (e) {
      console.error(e)
      notify(e.message)
      return
    }

    try {
      const teamMemberData = {
        firstName: userResult.firstName,
        lastName: userResult.lastName,
        email: userResult.email,
        organizationId: data.organizationId,
        officeId: data.officeId,
        accountId: userResult.id,
        approved: data.approved,
      }
      let teamMemberResult

      if (data.id) {
        const foundedTeamMembers = await dataProvider.getList('TeamMember', {
          filter: {
            accountId: userResult.id,
          },
          sort: {
            field: 'lastName',
            order: 'ASC',
          },
          pagination: {
            perPage: 1,
            page: 1,
          },
        })

        teamMemberResult =
          foundedTeamMembers.data.length === 0
            ? await dataProvider.create('TeamMember', {
                data: teamMemberData,
              })
            : await dataProvider.update('TeamMember', {
                data: teamMemberData,
                id: foundedTeamMembers.data[0].id,
                previousData: foundedTeamMembers.data[0],
              })
      } else {
        teamMemberResult = await dataProvider.create('TeamMember', {
          data: teamMemberData,
        })
      }
      if (!teamMemberResult || !teamMemberResult.data || !teamMemberResult.data.id) {
        throw new Error('unexpected error on managing TeamMember')
      }

      await client.mutate({
        mutation: gql`
          mutation SaveMemberOnHNS($userId: ID!) {
            saveMemberOnHNS(userId: $userId)
          }
        `,
        variables: {
          userId: userResult.id,
        }
      })

      notify('ra.message.success')
      redirect(`${props.basePath}/${userResult.id}/show`)
    } catch (e) {
      console.log('error managing teammember', e)
      notify(e.message)
      return
    }
  }, [])

  return <SaveButton {...props} onSave={handleSave} />
}

export default SaveUserButton
