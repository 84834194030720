import React, { FC, useMemo } from 'react'
import { useTranslate } from 'ra-core'
import Typography from '@material-ui/core/Typography'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

export enum ROUTE_STATUS {
  waitingForFlightBrief = 'waitingForFlightBrief',
  flightBriefIsReady = 'flightBriefIsReady',
  flightBriefSent = 'flightBriefSent',
}

export const ROUTE_STATUS_OPTIONS = [
  { name: ROUTE_STATUS.waitingForFlightBrief, color: '#ed3b38' },
  { name: ROUTE_STATUS.flightBriefIsReady, color: '#ffb516' },
  { name: ROUTE_STATUS.flightBriefSent, color: '#62ed38' },
]

type Props = {
  record?: Record<string, any>
  source?: string
  label?: string
}

const OrderRouteStatusField: FC<Props> = ({ record = {}, source = 'status' }) => {
  const translate = useTranslate()

  const color = useMemo((): string => {
    const statusFound = ROUTE_STATUS_OPTIONS.find((status: any) => record[source] === status.name)
    return statusFound ? statusFound.color : 'white'
  }, [record, source])

  if (!record[source]) return null

  return (
    <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
      <FiberManualRecordIcon fontSize="small" style={{ color, marginRight: 6 }} />
      {translate(`customFields.routeStatus.${record[source]}`)}
    </Typography>
  )
}

export default OrderRouteStatusField
