import React, { FC } from 'react'
import { TextInput } from 'react-admin'
import FilterTextInput from '../../components/FilterTextInput'
import Filter from '../../components/list/filter/Filter'

type Props = {
  [x: string]: any
}

const AirportFilters: FC<Props> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <FilterTextInput source="name" alwaysOn />
      <TextInput source="icao" />
      <TextInput source="iata" />
      <TextInput source="city" />
      <TextInput source="country" />
    </Filter>
  )
}

export default AirportFilters
