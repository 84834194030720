import React, { FC } from 'react'
import { NumberInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    width: '50%',
    alignSelf: 'stretch',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  helper: {
    width: '50%',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(8),
  },
  input: {
    width: '100%',
  },
}))

type Props = {
  helperText?: string
  source?: string
  [x: string]: any
}

const FormNumberInput: FC<Props> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputWrapper}>
        <NumberInput {...props} className={classes.input} helperText={undefined} />
      </div>
      <Typography className={classes.helper} variant="body2">
        {props.helperText}
      </Typography>
    </div>
  )
}

export default FormNumberInput
