import React, { FC, useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress, Container, Fade, useMediaQuery } from '@material-ui/core'
import { ReferenceInput, ReferenceField, TextField, SelectInput, DateField, DateInput, NumberField } from 'react-admin'
import { useListController, ListContextProvider, useTranslate, usePermissions, useRedirect } from 'ra-core'
import Typography from '@material-ui/core/Typography'
import StatCard from '../StatCard'
import Title from '../layout/Title'
import { FaChartLine, FaFileAlt } from 'react-icons/fa'
import Filter from '../../components/list/filter/Filter'
import RAList from '../../components/list/List'
import Datagrid from '../Datagrid'
import { parse } from 'query-string'
import SearchAutoCompleteInput from '../SearchAutoCompleteInput'
import { ORDER_STATUS } from '../OrderStatusDropDown'
import FirstOrderRouteField from '../field/FirstOrderRouteField'
import FirstOrderRouteDateField from '../field/FirstOrderRouteDateField'
import CurrencyField from '../field/CurrencyField'
import OrderIncomeField from '../field/OrderIncomeField'
import { canSeeDashboard } from './../../utils/hasPermission'
import { useTheme } from '@material-ui/core/styles'
import { gql, useQuery } from '@apollo/client'
import { FlightTotalByOrderStatus, groupByStatus, OrderTotalByStatus, QuoteTotalByStatus } from './helpers'
import { SimpleGrid, Button, Stack, Grid } from '@chakra-ui/react'
import { addMonths, addYears, endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns'
import { MostFrequentRoutes, MostFrequentRoutesCard } from './MostFrequentRoutesCard'
import { MostFrequentPassengers, MostFrequentPassengersCard } from './MostFrequentPassengers'
import { MostUsedManufacturers, MostUsedManufacturersCard } from './MostUsedManufacturers'
import { MostUsedVehicleModels, MostUsedVehicleModelsCard } from './MostUsedVehicleModels'
import { MostUsedVehicleCarbonOffsets, MostUsedVehicleCarbonOffsetsCard } from './MostUsedVehicleCarbonOffsets'
import { MostUsedVehicleSafetyTypes, MostUsedVehicleSafetyTypesCard } from './MostUsedVehicleSafetyTypes'

type Props = {
  [x: string]: any
}

const CustomFilters: FC<any> = (props) => {
  const setDateFilters = (type: 'year' | 'month' = 'year', offset = 0) => {
    const workingDate = type === 'year' ? addYears(new Date(), offset) : addMonths(new Date(), offset)
    const orderCreationStartDate = type === 'year' ? startOfYear(workingDate) : startOfMonth(workingDate)
    const orderCreationEndDate = type === 'year' ? endOfYear(workingDate) : endOfMonth(workingDate)

    props.setFilters({
      ...(props.filterValues || {}),
      orderCreationStartDate,
      orderCreationEndDate,
    })
  }

  return (
    <Filter {...props} variant="outlined">
      <ReferenceInput source="customerId" reference="Customer" label="Client" alwaysOn>
        <SearchAutoCompleteInput optionText="fullName" />
      </ReferenceInput>
      <ReferenceInput source="teamMemberId" reference="TeamMember">
        <SelectInput optionText="fullName" />
      </ReferenceInput>
      <Typography variant="h3">Date range</Typography>
      <DateInput source="orderCreationStartDate" />
      <DateInput source="orderCreationEndDate" />
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        paddingLeft="4"
        gridGap="5px"
        marginLeft="10px"
        {...{ alwaysOn: true }}
      >
        <Button
          size="xs"
          backgroundColor="#eeeeee"
          color="#333"
          borderRadius="sm"
          onClick={() => setDateFilters('year', 0)}
          style={{ maxHeight: '1.2rem', fontSize: '0.75rem', fontWeight: 'normal' }}
        >
          This year
        </Button>
        <Button
          size="xs"
          backgroundColor="#eeeeee"
          color="#333"
          borderRadius="sm"
          onClick={() => setDateFilters('year', -1)}
          style={{ maxHeight: '1.2rem', fontSize: '0.75rem', fontWeight: 'normal' }}
        >
          Last year
        </Button>
        <Button
          size="xs"
          backgroundColor="#eeeeee"
          color="#333"
          borderRadius="sm"
          onClick={() => setDateFilters('month', 0)}
          style={{ maxHeight: '1.2rem', fontSize: '0.75rem', fontWeight: 'normal' }}
        >
          This month
        </Button>
        <Button
          size="xs"
          backgroundColor="#eeeeee"
          color="#333"
          borderRadius="sm"
          onClick={() => setDateFilters('month', -1)}
          style={{ maxHeight: '1.2rem', fontSize: '0.75rem', fontWeight: 'normal' }}
        >
          Last month
        </Button>
      </Box>
    </Filter>
  )
}

const DEFAULTSORT = { field: 'createdAt', order: 'DESC' }
const PERMANENTFILTERS = { statuses: [ORDER_STATUS.confirmed, ORDER_STATUS.confirmedAndPaid, ORDER_STATUS.sent] }

const rowClick = (id: any, basePath: string, record: any): string => `/Order/${record.id}/show`

const ConfirmedContractsList: FC<any> = (props) => {
  const t = useTranslate()
  return (
    <RAList
      {...props}
      title="Contracts"
      resource="Order"
      basePath="/"
      filters={<CustomFilters />}
      filter={PERMANENTFILTERS}
      sort={DEFAULTSORT}
      bulkActionButtons={false}
      titleIcon={<FaFileAlt />}
      exporter={false}
    >
      <Datagrid rowClick={rowClick}>
        <FirstOrderRouteDateField label="First flight date" />
        <DateField source="createdAt" />
        <TextField source="reference" />
        <ReferenceField source="teamMemberId" reference="TeamMember" link={false}>
          <TextField source="fullName" />
        </ReferenceField>
        <FirstOrderRouteField label={t('resources.Quote.fields.routes')} />
        <ReferenceField source="customerId" reference="Customer" link={false}>
          <TextField source="fullName" />
        </ReferenceField>
        <CurrencyField source="purchasePrice" textAlign="right" />
        <CurrencyField source="sellingPrice" textAlign="right" />
        <OrderIncomeField label="Income" textAlign="center" />
      </Datagrid>
    </RAList>
  )
}

enum CustomerStatus {
  'LEAD' = 'LEAD',
  'PROSPECT' = 'PROSPECT',
  'CLIENT' = 'CLIENT',
  'NOT_DEFINED' = 'NOT_DEFINED',
}

type Metrics = {
  orderTotalByStatus: OrderTotalByStatus[]
  quoteTotalByStatus: QuoteTotalByStatus[]
  customerCountByStatus: {
    [CustomerStatus.CLIENT]: number
    [CustomerStatus.LEAD]: number
    [CustomerStatus.PROSPECT]: number
  }
  flightTotalByOrderStatus: FlightTotalByOrderStatus[]
  mostFrequentRoutes: MostFrequentRoutes[]
  mostFrequentPassengers: MostFrequentPassengers[]
  mostUsedManufacturers: MostUsedManufacturers[]
  mostUsedVehicleModels: MostUsedVehicleModels[]
  mostUsedVehicleCarbonOffsets: MostUsedVehicleCarbonOffsets[]
  mostUsedVehicleSafetyTypes: MostUsedVehicleSafetyTypes[]
}

type MetricsFilters = {
  teamMemberId?: number
  creationStartDate?: Date
  creationEndDate?: Date
  orderStatuses?: ORDER_STATUS[]
}

const Dashboard: FC<Props> = (props) => {
  const { loading: loadingPermissions, permissions } = usePermissions()
  const theme = useTheme()
  const mediaquery = useMediaQuery(theme.breakpoints.down('xs'))
  const redirect = useRedirect()
  const { filter } = parse(props?.location?.search)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>({})

  const filters: MetricsFilters = useMemo(
    () => ({
      customerId: currentFilters?.customerId,
      teamMemberId: currentFilters?.teamMemberId,
      creationStartDate: currentFilters?.orderCreationStartDate || startOfYear(new Date()),
      creationEndDate: currentFilters?.orderCreationEndDate || endOfYear(new Date()),
    }),
    [currentFilters]
  )

  const { data: metrics, ...metricsQuery } = useQuery<
    Metrics,
    { filters: MetricsFilters; customerFilters: MetricsFilters }
  >(
    gql`
      query metrics($filters: DashboardFilterInput, $customerFilters: CustomerDashboardFilterInput) {
        orderTotalByStatus(filters: $filters) {
          status
          count
          SUM_purchasePrice
          SUM_commissions
          SUM_passengerTax
        }
        quoteTotalByStatus(filters: $filters) {
          status
          count
        }
        flightTotalByOrderStatus(filters: $filters) {
          status
          count
          SUM_flightDuration
        }
        customerCountByStatus(filters: $customerFilters) {
          CLIENT
          LEAD
          PROSPECT
        }
        mostFrequentRoutes(filters: $filters) {
          departureAirport {
            id
            name
            city
          }
          arrivalAirport {
            id
            name
            city
          }
          count
        }
        mostFrequentPassengers(filters: $filters) {
          id
          passenger {
            id
            fullName
          }
          count
          SUM_flightDuration
        }
        mostUsedManufacturers(filters: $filters) {
          id
          manufacturer {
            id
            name
          }
          count
          SUM_flightDuration
        }
        mostUsedVehicleModels(filters: $filters) {
          id
          model {
            id
            name
          }
          count
          SUM_flightDuration
        }
        mostUsedVehicleCarbonOffsets(filters: $filters) {
          id
          carbonOffset {
            id
            name
          }
          count
          SUM_flightDuration
        }
        mostUsedVehicleSafetyTypes(filters: $filters) {
          id
          safetyType {
            id
            name
          }
          count
          SUM_flightDuration
        }
      }
    `,
    {
      variables: {
        filters: {
          ...filters,
          orderStatuses: PERMANENTFILTERS.statuses,
        },
        customerFilters: {
          ...filters,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const stats = useMemo(() => {
    const confirmedOrders = groupByStatus(
      [ORDER_STATUS.confirmed, ORDER_STATUS.confirmedAndPaid, ORDER_STATUS.sent],
      metrics?.orderTotalByStatus
    )
    const confirmedFlights = groupByStatus(
      [ORDER_STATUS.confirmed, ORDER_STATUS.confirmedAndPaid, ORDER_STATUS.sent],
      metrics?.flightTotalByOrderStatus
    )
    const allOrders = groupByStatus('all', metrics?.orderTotalByStatus)
    const allQuotes = groupByStatus('all', metrics?.quoteTotalByStatus)
    const lostQuotes = groupByStatus(['lost'], metrics?.quoteTotalByStatus)
    const abandonedQuotes = groupByStatus(['abandoned'], metrics?.quoteTotalByStatus)
    const confirmedQuotes = groupByStatus(['confirmed'], metrics?.quoteTotalByStatus)

    const totalPurchasePrice = confirmedOrders.SUM_purchasePrice ?? 0
    const totalCommissions = confirmedOrders.SUM_commissions ?? 0
    const totalProfit = totalCommissions + (confirmedOrders.SUM_passengerTax ?? 0)
    const orderCount = allOrders.count ?? 0
    const confirmedOrderCount = confirmedOrders.count ?? 0
    const quoteCount = allQuotes.count ?? 0
    const lostQuoteCount = lostQuotes.count ?? 0
    const abandonedQuoteCount = abandonedQuotes.count ?? 0
    const confirmedQuoteCount = confirmedQuotes.count ?? 0

    return {
      leadCount: metrics?.customerCountByStatus?.[CustomerStatus.LEAD] || 0,
      prospectCount: metrics?.customerCountByStatus?.[CustomerStatus.PROSPECT] || 0,
      clientCount: metrics?.customerCountByStatus?.[CustomerStatus.CLIENT] || 0,
      flightCount: confirmedFlights.count,
      flightTime: (confirmedFlights.SUM_flightDuration || 0) / 60,
      quoteCount,
      lostQuoteCount,
      abandonedQuoteCount,
      generatedVersusConfirmed: Math.round((confirmedQuoteCount / quoteCount) * 100) || 0,
      orderCount,
      confirmedOrderCount,
      totalPurchasePrice,
      totalCommissions,
      totalProfit,
      totalIncome: totalPurchasePrice + totalProfit,
      percentage: !totalPurchasePrice || !totalProfit ? 0 : ((totalProfit * 100) / totalPurchasePrice).toFixed(2),
    }
  }, [metrics?.orderTotalByStatus])

  const listContextValue = useListController({
    resource: 'Order',
    basePath: '/',
    sort: { field: 'createdAt', order: 'DESC' },
    filters: <CustomFilters />,
  })

  useEffect(() => {
    if (!loadingPermissions && permissions && !canSeeDashboard(permissions)) {
      redirect('/Order')
    }
  }, [loadingPermissions, permissions])

  useEffect(() => {
    if (filter && typeof filter === 'string') {
      const x = JSON.parse(filter)
      setCurrentFilters(x)
    }
  }, [filter])

  return !loadingPermissions && permissions && canSeeDashboard(permissions) ? (
    <Fade in={true}>
      <Container maxWidth={false} className={'dashboard-page'}>
        <Stack pt={11} pl={3} spacing="4">
          <Title title="Dashboard" icon={<FaChartLine />} />
          <SimpleGrid minChildWidth="15rem" spacing={4}>
            <StatCard
              title="Number of Flights"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              count={stats.flightCount}
            />
            <StatCard
              title="Flight Time"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              customChild={
                <span>
                  <NumberField variant="h2" record={stats} source="flightTime" /> hours
                </span>
              }
            />
          </SimpleGrid>
          <Grid gridTemplateColumns={{ base: '1fr', md: '1fr 1fr 1fr' }} gap="4">
            <MostFrequentRoutesCard
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              data={metrics?.mostFrequentRoutes}
            />
            <MostUsedManufacturersCard
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              data={metrics?.mostUsedManufacturers}
            />
            <MostUsedVehicleModelsCard
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              data={metrics?.mostUsedVehicleModels}
            />
            <MostUsedVehicleCarbonOffsetsCard
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              data={metrics?.mostUsedVehicleCarbonOffsets}
            />
            <MostUsedVehicleSafetyTypesCard
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              data={metrics?.mostUsedVehicleSafetyTypes}
            />
            <MostFrequentPassengersCard
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              data={metrics?.mostFrequentPassengers}
            />
          </Grid>
          <SimpleGrid minChildWidth="15rem" spacing={4}>
            <StatCard
              title="Number of Leads"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              count={stats.leadCount}
            />
            <StatCard
              title="Number of Propsects"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              count={stats.prospectCount}
            />
            <StatCard
              title="Number of Clients"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              count={stats.clientCount}
            />
          </SimpleGrid>
          <SimpleGrid minChildWidth="12rem" spacing={4}>
            <StatCard
              title="Number of quotes"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              count={stats.quoteCount}
            />
            <StatCard
              title="Lost quotes"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              count={stats.lostQuoteCount}
            />
            <StatCard
              title="Abandoned Quotes"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              count={stats.abandonedQuoteCount}
            />

            <StatCard
              title="Generated / Confirmed"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              customChild={
                <span>
                  <NumberField variant="h2" record={stats} source="generatedVersusConfirmed" />%
                </span>
              }
            />
            <StatCard
              title="Number of  Contracts"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              count={stats.orderCount}
            />
            <StatCard
              title="Confirmed Contracts"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              count={stats.confirmedOrderCount}
            />
            <StatCard
              status="negative"
              title="Purchase prices"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              customChild={<CurrencyField variant="h2" record={stats} source="totalPurchasePrice" />}
            />
            <StatCard
              title="Income"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              customChild={<CurrencyField variant="h2" record={stats} source="totalIncome" />}
            />
            <StatCard
              title="Profit"
              status="positive"
              loading={metricsQuery.loading}
              error={!!metricsQuery.error}
              customChild={
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CurrencyField variant="h2" record={stats} source="totalProfit" />
                  {stats && stats.percentage && (
                    <span>
                      (<NumberField variant="h2" record={stats} source="percentage" />
                      %)
                    </span>
                  )}
                </Box>
              }
            />
          </SimpleGrid>

          <Box mt={8}>
            <ListContextProvider value={listContextValue}>
              <ConfirmedContractsList />
            </ListContextProvider>
          </Box>
        </Stack>
      </Container>
    </Fade>
  ) : (
    <Container>
      <Box mt={30} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={100} />
      </Box>
    </Container>
  )
}

export default Dashboard
