import React, { FC, memo } from 'react'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { useTranslate } from 'ra-core'
import { Box, useTheme } from '@material-ui/core'
import { FaEyeSlash, FaMinusSquare } from 'react-icons/fa'

const PaginationLimit: FC = () => {
  const translate = useTranslate()
  const theme = useTheme()
  return (
    <Box
      px={5}
      py={7}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      color="secondary"
    >
      <FaEyeSlash size="5rem" color={theme.palette.secondary.light} />
      <Box my={2} />
      <Typography variant="body2" color="secondary">{translate('ra.navigation.no_results')}</Typography>
    </Box>
  )
}

export default memo(PaginationLimit)
