import React, { FC, useCallback, useEffect, useState } from 'react'
// import Button from '@material-ui/core/Button'
// import { FaCheck } from 'react-icons/fa'
// import { MdClear } from 'react-icons/md'
import { makeStyles } from '@material-ui/core/styles'
// import CircularProgress from '@material-ui/core/CircularProgress'
import { useDataProvider, useMutation, useNotify, useRefresh, useTranslate } from 'ra-core'
import { Box, Switch, Typography } from '@material-ui/core'
import IOSSwitch from '../IOSSwitch'

type Props = {
  source: string
  resource: string
  record: Record<string, any>
  disabled?: boolean
  noRefresh?: boolean
  [x: string]: any
}

const ToggleStatusButton: FC<Props> = ({ source, resource, record, disabled, noRefresh = false }) => {
  const notify = useNotify()
  const classes = useStyles()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const t = useTranslate()
  const [loading, setLoading] = useState<boolean>(false)

  const handleToggle = useCallback(async () => {
    try {
      setLoading(true)
      const result = await dataProvider.update(resource, {
        data: {
          [source]: !record[source],
        },
        previousData: { id: record.id },
        id: record.id,
      })
      notify(t('ra.notification.updated', { smart_count: 1 }))
      if (!noRefresh) {
        refresh()
      }
    } catch (e) {
      console.error('error on toggle status', e)
      notify('ra.message.error', 'error')
    } finally {
      setLoading(false)
    }
  }, [record, source])

  return (
    <Box display="flex" flexDirection="column">
      <IOSSwitch source={source} disabled={loading} checked={record[source]} onChange={handleToggle} />
      {/* <Button
        className={record[source] === true ? classes.disableButton : classes.enableButton}
        classes={{
          root: classes.button,
          label: record[source] === true ? classes.disableLabel : classes.enableLabel,
          endIcon: record[source] === true ? classes.disableLabel : classes.enableLabel,
        }}
        endIcon={loading ? undefined : record[source] === true ? <MdClear /> : <FaCheck />}
        onClick={handleToggle}
        disabled={loading}
      >
        {loading ? <CircularProgress size={22} /> : record[source] === true ? 'Disattiva' : 'Attiva'}
      </Button> */}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    minHeight: 40,
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    textTransform: 'none',
  },
  enableButton: {
    backgroundColor: '#56c85f',
  },
  disableButton: {
    // fixare colore dark // backgroundColor: theme.palette.primary.main,
  },
  enableLabel: {
    color: 'white',
  },
  disableLabel: {},
}))

export default ToggleStatusButton
