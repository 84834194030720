import { Box, BoxProps, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { useInfoCardStyles } from './InfoCard.styles'
import classNames from 'classnames'

type Props = BoxProps & {
  direction?: 'row' | 'column'
  children: any
}

const InfoCardItem: FC<Props> = ({ direction = 'column', padding = 4, className, alignItems = 'center', ...rest }) => {
  const classes = useInfoCardStyles()
  return (
    <Box
      className={classNames(classes.cardItem, direction, className)}
      flexDirection={direction}
      alignItems={alignItems}
      padding={padding}
      {...rest}
    ></Box>
  )
}

export default InfoCardItem
