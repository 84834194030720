import React, { FC } from 'react'
import RichTextInput from 'ra-input-rich-text'

import 'quill-mention'
import {
  GmailBriefLoopsList,
  GmailBriefVariablesList,
  GmailOrderLoopsList,
  GmailOrderVariablesList,
  GmailQuoteLoopsList,
  GmailQuoteVariablesList,
} from '../../utils/gmail-template-variables'

export const GmailRichTextEditor: FC<{ resourceType?: 'quote' | 'contract' | 'brief' } & Record<string, any>> = ({
  resourceType,
  ...props
}) => {
  console.log('resourceType', resourceType)

  return (
    <RichTextInput
      options={{
        modules: {
          toolbar: true,
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö\\.]*$/,
            mentionDenotationChars: ['$$', '@@'],
            source: function (searchTerm: string, renderList: any, mentionChar: string): void {
              let values

              if (!resourceType) {
                return
              }

              if (mentionChar === '$$') {
                values =
                  resourceType === 'quote'
                    ? GmailQuoteVariablesList
                    : resourceType === 'contract'
                    ? GmailOrderVariablesList
                    : GmailBriefVariablesList
              } else {
                values =
                  resourceType === 'quote'
                    ? [
                        ...GmailQuoteLoopsList,
                        ...GmailQuoteLoopsList.map((item) => ({ id: 'end-' + item.id, value: 'end ' + item.value })),
                      ]
                    : resourceType === 'contract'
                    ? [
                        ...GmailOrderLoopsList,
                        ...GmailOrderLoopsList.map((item) => ({ id: 'end-' + item.id, value: 'end ' + item.value })),
                      ]
                    : [
                        ...GmailBriefLoopsList,
                        ...GmailBriefLoopsList.map((item) => ({ id: 'end-' + item.id, value: 'end ' + item.value })),
                      ]
              }

              if (searchTerm.length === 0) {
                renderList(values, searchTerm)
              } else {
                const matches = []
                for (let i = 0; i < values.length; i++)
                  if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i])
                renderList(matches, searchTerm)
              }
            },
          },
        },
      }}
      {...props}
    />
  )
}
