import React, { FC, useCallback, useMemo, useState } from 'react'
import { useFormState, useForm } from 'react-final-form'
import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import { TextField, ArrayField } from 'react-admin'
import WizardSectionedForm, { WizardStep } from '../../components/forms/WizardSectionedForm'
import { Drawer, makeStyles, Typography, TextField as MuiTextInput, Box, Card } from '@material-ui/core'
import WizardRightControls from '../../components/forms/WizardSectionedForm/WizardRightControls'
import Button from '@material-ui/core/Button'
import { FormDataConsumer, useDataProvider, useNotify, useRedirect, useTranslate } from 'ra-core'
import Datagrid from '../../components/Datagrid'
import IconButton from '@material-ui/core/IconButton'
import { Confirm, ReferenceInput } from 'ra-ui-materialui'
import SaveButton from '../../components/button/SaveButton'
import BaseButton from '../../components/button/BaseButton'
import Empty from '../../components/list/Empty'
import { FaTrashAlt } from 'react-icons/fa'
import CustomerDynamicFormPart from './CustomerDynamicFormPart'
import IsCompanyAndLanguageFormPart from './IsCompanyAndLanguageFormPart'
import AddressFormSection from '../../components/AddressFormSection'
import FormSelectInput from '../../components/input/FormSelectInput'
import { MarketingOriginField } from './MarketingOriginField'

type Props = {
  [x: string]: any
}

type AddProductButtonProps = {
  size?: 'small' | 'medium' | 'large'
} & Props

const AddPersonButtonDrawer: FC<AddProductButtonProps> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const form = useForm()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [peopleRecord, setPeopleRecord] = useState<Record<string, any>>({})

  const onButtonClick = useCallback((): void => setIsDrawerOpen(true), [])
  const onClose = useCallback((): void => setIsDrawerOpen(false), [])

  const onUndoClick = useCallback((): void => {
    setPeopleRecord({})
    onClose()
  }, [])

  const onInputChange = useCallback(
    (event: any): void => {
      if (event?.target?.name) {
        setPeopleRecord({ ...(peopleRecord || {}), [event.target.name]: event.target.value })
      }
    },
    [peopleRecord]
  )

  // add localstorage saving logics here
  const onAddClick = useCallback((): void => {
    peopleRecord.id = new Date().getTime()
    form.mutators.push('people', peopleRecord)
    // saveProductsToLS(peopleRecord)
    setPeopleRecord({})
    onClose()
  }, [peopleRecord])

  const disableAddButton = useMemo(
    (): boolean =>
      Object.keys(peopleRecord).length === 0 ||
      !peopleRecord.firstName ||
      peopleRecord.firstName === '' ||
      !peopleRecord.lastName ||
      peopleRecord.lastName === '',
    [peopleRecord]
  )

  return (
    <Box pt={3}>
      <BaseButton
        fullWidth
        onClick={onButtonClick}
        variant="contained"
        size="xl"
        className={classes.addButton}
        {...props}
      >
        {translate('resources.Customer.titles.addPerson')}
      </BaseButton>
      <Drawer open={isDrawerOpen} anchor="right" classes={{ paper: classes.drawer }} onClose={onClose}>
        <div className={classes.drawerContainer}>
          <div className={classes.row}>
            <Typography variant="h2">{translate('resources.Customer.titles.addPerson')}</Typography>
            <BaseButton onClick={onUndoClick}>{translate('ra.action.cancel')}</BaseButton>
          </div>
          <div className={classes.row}>
            <MuiTextInput
              variant="outlined"
              label={translate('resources.Person.fields.firstName')}
              name="firstName"
              fullWidth
              value={peopleRecord?.firstName}
              onChange={onInputChange}
              required
            />
          </div>
          <div className={classes.row}>
            <MuiTextInput
              variant="outlined"
              label={translate('resources.Person.fields.lastName')}
              name="lastName"
              fullWidth
              value={peopleRecord?.lastName}
              onChange={onInputChange}
              required
            />
          </div>
          <div className={classes.row}>
            <MuiTextInput
              variant="outlined"
              label={translate('resources.Person.fields.position')}
              name="position"
              fullWidth
              value={peopleRecord?.position}
              onChange={onInputChange}
            />
          </div>
          <div className={classes.row}>
            <MuiTextInput
              variant="outlined"
              label={translate('resources.Person.fields.email')}
              name="email"
              fullWidth
              value={peopleRecord?.email}
              onChange={onInputChange}
            />
          </div>
          <div className={classes.row}>
            <MuiTextInput
              variant="outlined"
              label={translate('resources.Person.fields.phone')}
              name="phone"
              fullWidth
              value={peopleRecord?.phone}
              onChange={onInputChange}
            />
          </div>
          <Button
            color="primary"
            variant="contained"
            className={classes.createButton}
            onClick={onAddClick}
            disabled={disableAddButton}
          >
            {translate('ra.action.add')}
          </Button>
        </div>
      </Drawer>
    </Box>
  )
}

const PeopleTable: FC<any> = ({ formDataProps }) => {
  const translate = useTranslate()
  return !formDataProps.formData.people ||
    (Array.isArray(formDataProps.formData.people) && formDataProps.formData.people.length === 0) ? (
    <Box>
      <Empty
        resourceName="Person"
        component={Card}
        buttonComponent={<AddPersonButtonDrawer size="medium" />}
        messageComponent={<Typography>{translate('resources.Customer.cta.clickOnAddPerson')}</Typography>}
      />
    </Box>
  ) : (
    <Card>
      <ArrayField source="people" record={formDataProps.formData} selectedIds={[]}>
        <Datagrid fieldKey="id">
          <TextField source="firstName" sortable={false} />
          <TextField source="lastName" sortable={false} />
          <TextField source="position" sortable={false} />
          <TextField source="email" sortable={false} />
          <TextField source="phone" sortable={false} />
          <DeletePersonButton />
        </Datagrid>
      </ArrayField>
    </Card>
  )
}

const DeletePersonButton: FC<any> = (props) => {
  const { values } = useFormState()
  const form = useForm()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const classes = useStyles()
  const translate = useTranslate()

  const onClose = useCallback((): void => setIsDialogOpen(false), [])
  const onClick = useCallback((): void => setIsDialogOpen(true), [])

  // need to save updated table to localstorage here
  const handleDelete = useCallback((): void => {
    const indexToDelete = values.people.findIndex((elem: any) => elem.id === props.record.id)
    const updated = [...values.people.slice(0, indexToDelete), ...values.people.slice(indexToDelete + 1)]
    form.change('people', updated)
    setIsDialogOpen(false)
    // saveProductsToLS(updated)
  }, [values, props?.record])

  return (
    <>
      <IconButton onClick={onClick} className={classes.iconDelete}>
        <FaTrashAlt fontSize="1rem" />
      </IconButton>
      <Confirm
        isOpen={isDialogOpen}
        title={`${translate('ra.action.delete')} ${props.record.firstName} ${props.record.lastName} ?`}
        content="ra.message.delete_content"
        onConfirm={handleDelete}
        onClose={onClose}
      />
    </>
  )
}

const SaveFullClient: FC<any> = (props) => {
  const { values } = useFormState()
  const [saving, setSaving] = useState(false)
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()

  const handleSave = async (): Promise<any> => {
    console.log('customer_values: ', values)
    try {
      setSaving(true)
      const clientRes = await dataProvider.create('Customer', {
        data: values.isCompany
          ? {
              companyName: values.companyName,
              vat: values.vat,
              pec: values.pec,
              email: values.email,
              isCompany: values.isCompany,
              phone: values.phone,
              billingDigitalId: values.billingDigitalId,
              languageId: values.languageId,
              customerStatus: 'LEAD',
              mainReferenceTeamMemberId: values.teamMemberId,
              marketingOriginId: values.marketingOriginId,
            }
          : {
              firstName: values.firstName,
              lastName: values.lastName,
              fiscalCode: values.fiscalCode,
              email: values.email,
              isCompany: values.isCompany,
              languageId: values.languageId,
              phone: values.phone,
              customerStatus: 'LEAD',
              mainReferenceTeamMemberId: values.teamMemberId,
              marketingOriginId: values.marketingOriginId,
            },
      })
      // console.log('customer result: ', clientRes)
      if (!clientRes || !clientRes.data || !clientRes.data.id) throw new Error('customer create error')
      localStorage.removeItem('_wizard_Customer_create')

      if (!!values.phone || !!values.countryId || !!values.city || !!values.street || !!values.postCode) {
        const addressRes = await dataProvider.create('Address', {
          data: {
            customerId: clientRes.data.id,
            firstName: values.firstName,
            lastName: values.lastName,
            countryId: values.countryId,
            street: values.street,
            postCode: values.postCode,
            city: values.city,
            // phone: values.phone,
            // alias: `${values.firstName}-${values.lastName}`,
          },
        })
      }
      // console.log('address result: ', addressRes)
      if (values && values.people && values.people.length > 0) {
        for (let i = 0; i < values.people.length; i++) {
          const personRes = await dataProvider.create('Person', {
            data: {
              firstName: values.people[i].firstName,
              lastName: values.people[i].lastName,
              position: values.people[i].position,
              email: values.people[i].email,
              phone: values.people[i].phone,
              referencedCustomerId: clientRes.data.id,
            },
          })
          // console.log('person result: ', personRes)
        }
      }
      setSaving(false)
      notify('ra.notification.success')
      redirect('/Customer')
    } catch (err) {
      console.error('customer create error:', err)
      notify('ra.message.error')
      setSaving(false)
    }
  }

  return (
    <SaveButton
      {...props}
      variant="contained"
      saving={saving}
      disabled={saving}
      handleSubmitWithRedirect={handleSave}
    />
  )
}

const ClientCreate: FC<Props> = (props) => {
  const translate = useTranslate()

  return (
    <Create {...props} component="div">
      <WizardSectionedForm variant="outlined" redirect="list" title={translate('resources.Customer.titles.create')}>
        <WizardStep>
          <FormSection title={translate('resources.Customer.fields.mainReferenceTeamMemberId')}>
            <ReferenceInput source="mainReferenceTeamMemberId" reference="TeamMember">
              <FormSelectInput optionText="fullName" />
            </ReferenceInput>
          </FormSection>
          <FormSection title={translate('resources.Customer.titles.createSub1')}>
            <IsCompanyAndLanguageFormPart />
            <CustomerDynamicFormPart />
            <FormTextInput {...props} source="email" />
            <Box style={{ width: 'calc(50% - 18px)' }}>
              <MarketingOriginField {...props} source="marketingOriginId" />
            </Box>
          </FormSection>
          <AddressFormSection
            title={translate('resources.Customer.titles.createSub2')}
            referenceField="mainAddressId"
          />
        </WizardStep>
        <WizardStep
          formRightControls={
            <WizardRightControls
              saveButtonComponent={<SaveFullClient />}
              asideLowerContent={<AddPersonButtonDrawer {...props} />}
            />
          }
        >
          <FormDataConsumer>
            {(formDataProps: any): React.ReactNode => <PeopleTable formDataProps={formDataProps} />}
          </FormDataConsumer>
        </WizardStep>
      </WizardSectionedForm>
    </Create>
  )
}

const useStyles = makeStyles((theme) => ({
  description: {
    width: '100%',
  },
  drawer: {
    width: '26vw',
    minWidth: 260,
  },
  drawerContainer: {
    padding: theme.spacing(10),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  input: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  rootInput: {
    maxWidth: '130px',
    marginRight: theme.spacing(3),
  },
  createButton: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '100%',
  },
  addButton: {
    backgroundColor: theme.palette.common.white,
  },
  imgDropZoneLarge: {
    padding: theme.spacing(8),
    outline: 'none',
    width: '100%',
  },
  imgRoot: {
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .previews': {
      display: 'flex',
      alignSelf: 'flex-start',
      width: '100%',
      overflow: 'scroll',
      marginLeft: theme.spacing(6),
    },
  },
  imageLarge: {
    borderRadius: theme.shape.borderRadius,
    maxWidth: '150px',
    margin: 0,
  },
  imgRemoveButton: {
    position: 'relative',
    '& button': {
      position: 'absolute',
      left: '5px',
      top: '5px',
      padding: 0,
      backgroundColor: theme.palette.background.paper,
    },
    '& button svg': {
      width: '0.8em',
      height: '0.8em',
    },
  },
  iconDelete: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: 29,
    height: 33,
    borderRadius: theme.shape.borderRadius,
  },
}))

export default ClientCreate
