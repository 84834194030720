import React, { FC } from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'
import ProductCategoryInput from '../../components/ProductCategoryInput'
import SlugInput from '../../components/SlugInput'
import HiddenOrganizationField from './../../components/forms/HiddenOrganizationField'
import { parse } from 'query-string'

const ProductCategoryCreate: FC = (props: any) => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { parentId } = parse(props.location.search)
  const redirect = (basePath: string, id: string, data: any): string => {
    return data.parentId ? `${basePath}/${data.parentId}/show` : `${basePath}/${id}/show`
  }

  return (
    <Create {...props}>
      <SimpleForm initialValues={{ parentId: parentId || undefined }} redirect={redirect}>
        <HiddenOrganizationField />
        <TextInput source="name" />
        {/* <TextInput fullWidth multiline source="description" /> */}
        <TextInput source="slug" />
        <ProductCategoryInput source="parentId" label="Parent" />
      </SimpleForm>
    </Create>
  )
}

export default ProductCategoryCreate
