import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useNotify } from 'ra-core'

import { GET_CUSTOMER_MAIL_AND_PEOPLE_MAIL } from '../../queries'

type CustomerAsOption = {
  id: string
  name: string
}

export const useCustomerPeople = (
  customerId: string
): {
  initialized: boolean
  options: CustomerAsOption[]
} => {
  const notify = useNotify()

  const { data, loading } = useQuery(GET_CUSTOMER_MAIL_AND_PEOPLE_MAIL, {
    variables: {
      customerId,
    },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      notify('Error fetching client people', 'error')
    },
  })

  return useMemo(() => {
    if (loading) {
      return {
        initialized: false,
        options: [],
      }
    }

    if (!data || !data.people || !data.people.data || !data.customer) {
      return {
        initialized: true,
        options: [],
      }
    }

    const s = new Set([data.customer.email, ...data.people.data.map((item: any) => item.email)])
    const it = s.values()

    const options = Array.from(it)
      .filter((item) => !!item)
      .map((item) => ({ id: item, name: item }))

    return {
      initialized: true,
      options,
    }
  }, [data, loading])
}
