import React, { FC } from 'react'
import { TextField, SimpleShowLayout, ReferenceField, NumberField } from 'react-admin'
import { useTranslate } from 'ra-core'
import { makeStyles, Typography } from '@material-ui/core'
// internal components
import Show from '../../components/details/Show'
import { GalleryEditableField } from '../../components/field/GalleryEditableField'
import CarbonOffsetReferenceField from '../../components/field/CarbonOffsetReferenceField'

type Props = {
  [x: string]: any
}

const VehicleShow: FC<Props> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <Show {...props} inDrawer title={`${translate('resources.Vehicle.name')} #${props.id}`}>
      <SimpleShowLayout className={classes.root}>
        {/* <TextField source="id" /> */}
        <ReferenceField reference="VehicleManufacturer" source="vehicleManufacturerId">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="VehicleModel" source="vehicleModelId">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="licensePlate" />
        <TextField source="homeBaseIcao" />
        <NumberField source="numberOfSeats" />
        <TextField source="yearOfManufacture" />
        <TextField source="yearOfRestyling" />
        <ReferenceField reference="VehicleCategory" source="vehicleCategoryId" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="vehicleSafetyTypeId" reference="VehicleSafetyType" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <CarbonOffsetReferenceField />
        <GalleryEditableField source="galleryId" />
      </SimpleShowLayout>
    </Show>
  )
}

const useStyles = makeStyles((theme) => ({
  root: { padding: 0, display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0px, 1fr));', fontSize: '24px' },
}))

export default VehicleShow
