import React, { FC, useCallback, useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useApolloClient, useQuery } from '@apollo/client'
import { QUERY_CHECK_GOOGLE_TOKEN, QUERY_GET_GMAIL_SETTINGS } from '../queries'
import GmailCodeRedirectButton from '../components/GoogleCodeRedirectButton'
import GoogleLogoutButton from '../components/GoogleLogoutButton'
import { useVersion } from 'ra-core'
import { GmailSettingsCC } from './GmailSettingsCC'

type Props = {
  [x: string]: any
}

const GoogleLogin: FC<Props> = () => {
  const client = useApolloClient()
  const [loggedUser, setLoggedUser] = useState<string | null>(null)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const version = useVersion()

  const { loading: loadingSettings, data } = useQuery(QUERY_GET_GMAIL_SETTINGS)

  const checkGoogleToken = useCallback(async (): Promise<any> => {
    try {
      const check = await client.query({ query: QUERY_CHECK_GOOGLE_TOKEN, fetchPolicy: 'network-only' })
      // console.log('xxx check result:: ', check)

      if (
        check.data.checkGoogleToken?.user &&
        check.data.checkGoogleToken?.user !== '' &&
        check.data.checkGoogleToken?.found
      ) {
        setErrorMsg(null)
        setLoggedUser(check.data.checkGoogleToken.user)
      } else {
        throw new Error(check?.data?.checkGoogleToken?.error || 'Unknown error')
      }
    } catch (error) {
      const message = JSON.stringify((error as any)?.message || error)
      // console.error('xxx msg: ', message)
      console.error('XXX ', error)
      setErrorMsg(message)
      setLoggedUser(null)
    } finally {
      setLoading(false)
    }
  }, [client])

  useEffect(() => {
    setTimeout(() => {
      checkGoogleToken()
    }, 500)
  }, [version])

  // const redirect = useRedirect()
  useEffect(() => {
    checkGoogleToken()
  }, [])

  // if (loading) return <CircularProgress size={30} />
  // if (errorMsg)
  //   return (
  //     <>
  //       <Typography variant="h4">{errorMsg}</Typography>
  //       <GmailCodeRedirectButton />
  //     </>
  //   )
  // if (loggedUser)
  //   return (
  //     <>
  //       <Typography variant="h4">{loggedUser}</Typography>
  //       <GmailLogoutButton />
  //     </>
  //   )
  return (
    <>
      <Box display="flex" flexDirection="column" mt={10} alignItems="center">
        {loading && <CircularProgress size={60} />}
        {errorMsg && (
          <>
            <Typography variant="h4">{errorMsg}</Typography>
            <Box mt={4} />
            <GmailCodeRedirectButton />
          </>
        )}
        {loggedUser && (
          <>
            <Typography variant="h4">{`logged as: ${loggedUser}`}</Typography>
            <Box mt={4} />
            <GoogleLogoutButton />
          </>
        )}
      </Box>
      {!loadingSettings && (
        <Box display="flex" alignItems="flex-start" flexDirection="column" mt={20} minWidth="60%">
          <Typography variant="h4">Gmail Settings</Typography>
          <GmailSettingsCC value={data?.gmailSettings.defaultCC} />
        </Box>
      )}
    </>
  )
}

export default GoogleLogin
