import React, { FC, useCallback, useState, useEffect } from 'react'
import { useTranslate, useDataProvider, useNotify, useRefresh } from 'ra-core'
import { Form } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ReferenceInput, SelectInput } from 'react-admin'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import { FormRenderProps } from 'react-final-form'
import { CurrencyFinalFormInput } from '../../components/input/CurrencyFinalFormInput'
import { PercentageFinalFormInput } from '../../components/input/PercentageFinalFormInput'
import { useApolloClient } from '@apollo/client'
import { QUERY_GET_ORDER_ADDITIONAL_INFO } from '../../queries'
import createDecorator from 'final-form-calculate'
import { ORDER_INFO_TEMPLATE_CATEGORIES } from '../OrderAdditionalInfoTemplate/OrderAdditionalInfoTemplateCreate'
import { DateInput } from 'react-admin'
import TaxReferenceInput from '../../components/input/TaxReferenceInput'
import FormSwitchInput from '../../components/forms/FormSwitchInput'
import RichTextInput from 'ra-input-rich-text'
import FilteredPersonAutocompleteInput from '../../components/input/FilteredPersonAutocompleteInput'
import useCurrencySettings from '../../hooks/useCurrencySettings'
import { formatToMaskedString, parseFromMaskedString } from '../../utils/prices'

const OrderEditForm: FC<
  FormRenderProps<any, Record<string, any>> & { saving?: boolean; data?: any; setCurrentCurrency: any }
> = ({ handleSubmit, saving, data, values, setCurrentCurrency }) => {
  const classes = useStyles()
  const translate = useTranslate()

  // useEffect(() => {
  //   if (values?.currencyId !== data.currencyId) {
  //     setCurrentCurrency(values.currencyId)
  //   }
  // }, [values?.currencyId])

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h4">Client</Typography>
      <Box mt={2} />
      <div className={classes.row}>
        <ReferenceInput basePath="/Order" source="customerId" reference="Customer">
          <AutocompleteInputInDrawer optionText="fullName" fullWidth variant="outlined" />
        </ReferenceInput>
      </div>
      <div className={classes.row}>
        {/* <ReferenceInput basePath="/Order" source="personId" filters={personQueryFilter} reference="Person">
          <AutocompleteInputInDrawer optionText="fullName" fullWidth variant="outlined" />
        </ReferenceInput> */}
        <FilteredPersonAutocompleteInput isEdit inDrawer initialCustomerId={data?.customerId} />
      </div>
      <Typography variant="h4">Localization</Typography>
      <Box mt={2} />
      <div className={classes.row}>
        <ReferenceInput basePath="/Order" source="currencyId" reference="Currency">
          <SelectInput optionText="name" fullWidth variant="outlined" />
        </ReferenceInput>
      </div>
      <div className={classes.row}>
        <ReferenceInput basePath="/Order" source="languageId" reference="Language">
          <SelectInput optionText="name" fullWidth variant="outlined" />
        </ReferenceInput>
      </div>

      <Typography variant="h4">Pricing</Typography>
      <Box mt={2} />
      <div className={classes.row}>
        <CurrencyFinalFormInput
          fullWidth
          label="Purchase price"
          classes={{ input: classes.input, root: classes.rootInput }}
          source="purchasePrice"
        />
      </div>
      <div className={classes.row}>
        <CurrencyFinalFormInput
          classes={{ input: classes.input, root: classes.rootInput }}
          label="Commissions"
          source="commissions"
        />
        <PercentageFinalFormInput
          label="Commissions %"
          // parse={(value: string): number => {
          //   return parseFloat(value.replace('%', '').trim().replace(',', '.'))
          // }}
          // format={(value: number): string => {
          //   return `${value.toString().replace('.', ',')} %`
          // }}
          classes={{ input: classes.input }}
          source="commissionsPercentage"
        />
      </div>
      <div className={classes.taxRow}>
        <TaxReferenceInput classes={{ input: classes.input, root: classes.rootInput }} />
        <Box mr={3} />
        <CurrencyFinalFormInput
          fullWidth
          label="Tax Value"
          classes={{ input: classes.input }}
          source="taxValue"
          disabled
        />
      </div>
      <div className={classes.row}>
        <CurrencyFinalFormInput
          fullWidth
          label="Passenger Tax"
          classes={{ input: classes.input, root: classes.rootInput }}
          source="passengerTax"
        />
      </div>
      <div className={classes.row}>
        <CurrencyFinalFormInput
          fullWidth
          label="Selling price (no taxes)"
          classes={{ input: classes.input, root: classes.rootInput }}
          source="sellingPriceWithoutTaxes"
        />
        <Box mr={3} />
        <CurrencyFinalFormInput
          fullWidth
          label="Selling price"
          classes={{ input: classes.input }}
          source="sellingPrice"
        />
      </div>
      <div className={classes.row}>
        <FormSwitchInput source="excludeVatFromFinalPrice" helperText="Exclude VAT in print" />
      </div>

      <div className={classes.row}>
        <DateInput
          fullWidth
          variant="outlined"
          label="Payment Terms"
          classes={{ input: classes.input, root: classes.rootInput }}
          source="expiringDate"
        />
      </div>

      <Typography variant="h4">Additional Info</Typography>
      <Box mt={2} />
      <ReferenceInput
        source="additionalInfoCancellationFee"
        reference="OrderAdditionalInfoTemplate"
        perPage={50}
        filter={{ category: ORDER_INFO_TEMPLATE_CATEGORIES.cancellationFee }}
        allowEmpty
      >
        <SelectInput optionText="name" fullWidth variant="outlined" label="Cancellation Fee" />
      </ReferenceInput>
      <ReferenceInput
        source="additionalInfoRentalConditions"
        reference="OrderAdditionalInfoTemplate"
        perPage={50}
        filter={{ category: ORDER_INFO_TEMPLATE_CATEGORIES.rentalConditions }}
        allowEmpty
      >
        <SelectInput optionText="name" fullWidth variant="outlined" label="Rental conditions" />
      </ReferenceInput>
      <ReferenceInput
        source="bankAccountId"
        reference="BankAccount"
        perPage={50}
        filter={{ organizationId: data.organizationId }}
        allowEmpty
      >
        <SelectInput optionText="name" fullWidth variant="outlined" label="Bank account details" />
      </ReferenceInput>

      <Typography variant="h4">Printable notes</Typography>
      <Box mt={2} />
      <RichTextInput fullWidth label={false} source="printableNotes._html" />

      <Typography variant="h4">Account Manager</Typography>
      <Box mt={2} />
      <ReferenceInput basePath="/Order" source="teamMemberId" reference="TeamMember">
        <SelectInput optionText="fullName" fullWidth variant="outlined" />
      </ReferenceInput>
      <Button
        color="primary"
        variant="contained"
        className={classes.createButton}
        onClick={handleSubmit}
        disabled={saving}
      >
        {translate('ra.action.save')}
      </Button>
    </form>
  )
}

type Props = {
  data: any
  [x: string]: any
}

const InDrawerOrderEditForm: FC<Props> = ({ data, ...props }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [formValues, setFormValues] = useState<Record<string, any>>({})
  const client = useApolloClient()
  const refresh = useRefresh()

  const [currentCurrency, setCurrentCurrency] = useState('')
  const { loading, mask } = useCurrencySettings(currentCurrency)

  const calculator = useCallback(
    createDecorator(
      {
        field: 'purchasePrice',
        updates: {
          commissions: (value: any, allValues: any): any => {
            const rawValue = parseFromMaskedString(mask, value)
            const rawPercentage = parseFloat(allValues?.commissionsPercentage.replace(mask.decimalSymbol, '.'))
            return formatToMaskedString(mask, ((rawValue || 0) / 100) * rawPercentage)
            // return ((value || 0) / 100) * allValues?.commissionsPercentage
          },
          // sellingPrice: (value: any, allValues: any): any => value + allValues?.commissions + allValues?.transferCost,
          sellingPriceWithoutTaxes: (value: any, allValues: any): any => {
            const rawValue = parseFromMaskedString(mask, value)
            const rawCommissions = parseFromMaskedString(mask, value)
            return formatToMaskedString(mask, (rawValue || 0) + rawCommissions)
            // return (value || 0) + allValues?.commissions
          },
          // taxValue: (value: any, allValues: any): any => (value + allValues?.commissions) * (allValues?.taxRate / 100),
        },
      },
      {
        field: 'commissionsPercentage',
        updates: {
          commissions: (value: any, allValues: any): any => {
            const rawValue = parseFloat(value.replace(mask.decimalSymbol, '.'))
            const rawPurchasePrice = parseFromMaskedString(mask, allValues?.purchasePrice)
            return formatToMaskedString(mask, (rawPurchasePrice / 100) * (rawValue || 0))
            // return (allValues?.purchasePrice / 100) * (value || 0)
          },
          // sellingPrice: (value: any, allValues: any): any =>
          //   allValues?.purchasePrice + allValues?.commissions + allValues?.transferCost,

          // sellingPriceWithoutTaxes: (value: any, allValues: any): any => {
          //   console.log(
          //     'yyy commissionPercentage setta SellingNOTAX CaLc',
          //     allValues?.purchasePrice + allValues?.commissions + allValues?.transferCost,
          //     ' purchase: ',
          //     allValues?.purchasePrice,
          //     ' commissions: ',
          //     allValues?.commissions
          //   )
          //   return allValues?.purchasePrice + allValues?.commissions
          // },
        },
      },
      {
        field: 'commissions',
        updates: {
          commissionsPercentage: (value: any, allValues: any): any => {
            const rawValue = parseFromMaskedString(mask, value)
            const rawPurchasePrice = parseFromMaskedString(mask, allValues?.purchasePrice)
            // return formatToMaskedString(mask, ((rawValue || 0) * 100) / rawPurchasePrice)
            return (((rawValue || 0) * 100) / rawPurchasePrice).toString().replace('.', mask.decimalSymbol)
            // return ((value || 0) * 100) / allValues?.purchasePrice
          },
          // sellingPrice: (value: any, allValues: any): any => allValues?.purchasePrice + value + allValues?.transferCost,
          sellingPriceWithoutTaxes: (value: any, allValues: any): any => {
            const rawValue = parseFromMaskedString(mask, value)
            const rawPurchasePrice = parseFromMaskedString(mask, allValues?.purchasePrice)

            return formatToMaskedString(mask, rawPurchasePrice + (rawValue || 0))
            // return allValues?.purchasePrice + (value || 0) + allValues?.transferCost
          },
        },
      },
      // {
      //   field: 'transferCost',
      //   updates: {
      //     sellingPrice: (value: any, allValues: any): any => allValues?.purchasePrice + allValues?.commissions + value,
      //   },
      // },
      {
        field: 'sellingPriceWithoutTaxes',
        updates: {
          sellingPrice: (value: any, allValues: any): any => {
            const rawValue = parseFromMaskedString(mask, value)
            const rawPassengerTax = parseFromMaskedString(mask, allValues?.passengerTax)
            const rawTaxValue = parseFromMaskedString(mask, allValues?.taxValue)

            return formatToMaskedString(mask, (rawValue || 0) + rawTaxValue + rawPassengerTax)
            // return (value || 0) + allValues?.taxValue + allValues?.passengerTax
          },
          taxValue: (value: any, allValues: any): any => {
            const rawValue = parseFromMaskedString(mask, value)
            return formatToMaskedString(mask, (rawValue || 0) * (allValues?.taxRate / 100))
            // return (value || 0) * (allValues?.taxRate / 100)
          },
        },
      },
      {
        field: 'taxId',
        updates: {
          taxValue: async (value: any, allValues: any): Promise<any> => {
            if (!value) return 0
            const taxData = await dataProvider.getOne('Tax', { id: value })
            const rawSellingNoTaxes = parseFromMaskedString(mask, allValues?.sellingPriceWithoutTaxes)
            return formatToMaskedString(mask, rawSellingNoTaxes * (taxData.data.rate / 100))
            // return allValues?.sellingPriceWithoutTaxes * (taxData.data.rate / 100)
          },
          taxRate: async (value: any, allValues: any): Promise<any> => {
            if (!value) return 0
            const taxData = await dataProvider.getOne('Tax', { id: value })
            return taxData.data.rate
          },
        },
      },
      {
        field: 'taxValue',
        updates: {
          sellingPrice: (value: any, allValues: any): any => {
            const rawValue = parseFromMaskedString(mask, value)
            const rawSellingNoTaxes = parseFromMaskedString(mask, allValues?.sellingPriceWithoutTaxes)
            const rawPaxTax = parseFromMaskedString(mask, allValues?.passengerTax)

            return formatToMaskedString(mask, rawSellingNoTaxes + (rawValue || 0) + rawPaxTax)
            // return allValues?.sellingPriceWithoutTaxes + (value || 0) + allValues?.passengerTax
          },
        },
      },
      {
        field: 'passengerTax',
        updates: {
          sellingPrice: (value: any, allValues: any): any => {
            const rawValue = parseFromMaskedString(mask, value)
            const rawSellingNoTaxes = parseFromMaskedString(mask, allValues?.sellingPriceWithoutTaxes)
            const rawTaxValue = parseFromMaskedString(mask, allValues?.taxValue)

            return formatToMaskedString(mask, rawSellingNoTaxes + (rawValue || 0) + rawTaxValue)
            // return allValues?.sellingPriceWithoutTaxes + (value || 0) + allValues?.taxValue
          },
        },
      }
    ),
    [dataProvider, mask, currentCurrency, loading]
  )

  const onSubmit = useCallback(
    async (values) => {
      try {
        if (saving) {
          return
        }
        setSaving(true)

        const {
          taxValue,
          taxRate,
          commissionsPercentage,
          purchasePrice,
          commissions,
          passengerTax,
          sellingPriceWithoutTaxes,
          sellingPrice,
          ...valuesFromForm
        } = values
        const dataToSend = {
          ...valuesFromForm,
          purchasePrice: parseFromMaskedString(mask, purchasePrice),
          commissions: parseFromMaskedString(mask, commissions),
          passengerTax: parseFromMaskedString(mask, passengerTax),
          sellingPriceWithoutTaxes: parseFromMaskedString(mask, sellingPriceWithoutTaxes),
          sellingPrice: parseFromMaskedString(mask, sellingPrice),
          orderAdditionalInfoIds: [],
        }

        if (values.additionalInfoCancellationFee) {
          dataToSend.orderAdditionalInfoIds.push(values.additionalInfoCancellationFee)
        }

        if (values.additionalInfoRentalConditions) {
          dataToSend.orderAdditionalInfoIds.push(values.additionalInfoRentalConditions)
        }

        if (values.additionalInfoBankAccountDetails) {
          dataToSend.orderAdditionalInfoIds.push(values.additionalInfoBankAccountDetails)
        }

        // removing printableNotes
        if (formValues.printableNotes && !values.printableNotes) {
          dataToSend.printableNotes = { _html: null }
        }

        const result = await dataProvider.update('Order', {
          id: data.id,
          data: { ...dataToSend },
          previousData: { id: data.id },
        })

        if (values.customerId !== data.customerId) {
          // console.log('updating quote tooo: ', values.personId)
          const foundQuotes = await dataProvider.getList('Quote', {
            filter: { reference: data.reference },
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'id', order: 'DESC' },
          })
          if (foundQuotes?.data && Array.isArray(foundQuotes.data)) {
            // TODO: FIXME: null, '' or undefined are ignored by dataprovider
            const updateQuote = await dataProvider.update('Quote', {
              id: foundQuotes.data[0].id,
              data: {
                customerId: values.customerId,
                personId: values.personId ? values.personId : null,
              },
              previousData: { id: foundQuotes.data[0].id },
            })
          }
        }
        props.handleDrawerClose()
        notify('ra.notify.updated')
        refresh()
      } catch (error) {
        console.log('update error: ', error)
        notify(error.message, 'error')
      } finally {
        setSaving(false)
      }
    },
    [data, formValues, mask]
  )

  useEffect(() => {
    const init = async (): Promise<void> => {
      setInitialized(false)
      setFormValues({})
      let result
      try {
        result = await client.query({
          query: QUERY_GET_ORDER_ADDITIONAL_INFO,
          variables: {
            orderId: data.id,
          },
        })
      } catch (error) {
        console.error('error loading additional info', error)
      }

      let taxData
      try {
        taxData = await dataProvider.getOne('Tax', { id: data.taxId })
      } catch (error) {
        console.error('error loading tax info', error)
      }

      const newValues: Record<string, any> = {
        customerId: data.customerId,
        personId: data.personId,
        languageId: data.languageId,
        currencyId: currentCurrency,
        // currencyId: data.currencyId,
        purchasePrice: formatToMaskedString(mask, data.purchasePrice),
        commissions: formatToMaskedString(mask, data.commissions),
        commissionsPercentage: ((data.commissions * 100) / data.purchasePrice)
          .toString()
          .replace('.', mask.decimalSymbol),
        // transferCost: data.transferCost,
        sellingPrice: formatToMaskedString(mask, data.sellingPrice),
        penaltyPrice: data.penaltyPrice ? formatToMaskedString(mask, data.penaltyPrice) : undefined,
        bankAccountId: data.bankAccountId,
        teamMemberId: data.teamMemberId,
        expiringDate: data.expiringDate,
        taxId: data.taxId,
        passengerTax: formatToMaskedString(mask, data.passengerTax),
        sellingPriceWithoutTaxes: formatToMaskedString(mask, data.sellingPriceWithoutTaxes),
        taxValue: formatToMaskedString(mask, data.sellingPriceWithoutTaxes * (taxData?.data.rate / 100) || 0),
        taxRate: taxData?.data.rate || 0,
        excludeVatFromFinalPrice: data.excludeVatFromFinalPrice,
        printableNotes: data.printableNotes,
      }

      if (result?.data?.orderAdditionalInfos?.data) {
        // console.log(result.data.orderAdditionalInfos.data, 'iniziamo')
        newValues.additionalInfoCancellationFee = (
          result.data.orderAdditionalInfos.data.find(
            (item: any, index: number) =>
              item &&
              item.value?.orderAdditionalInfoTemplate?.category &&
              item.value.orderAdditionalInfoTemplate.category === ORDER_INFO_TEMPLATE_CATEGORIES.cancellationFee
          ) || {}
        ).orderAdditionalInfoTemplateId
        newValues.additionalInfoRentalConditions = (
          result.data.orderAdditionalInfos.data.find(
            (item: any, index: number) =>
              item &&
              item.value?.orderAdditionalInfoTemplate?.category &&
              item.value.orderAdditionalInfoTemplate.category === ORDER_INFO_TEMPLATE_CATEGORIES.rentalConditions
          ) || {}
        ).orderAdditionalInfoTemplateId
        // newValues.additionalInfoBankAccountDetails = (
        //   result.data.orderAdditionalInfos.data.find(
        //     (item: any, index: number) =>
        //       item &&
        //       item.value?.orderAdditionalInfoTemplate?.category &&
        //       item.value.orderAdditionalInfoTemplate.category === ORDER_INFO_TEMPLATE_CATEGORIES.bankAccountDetails
        //   ) || {}
        // ).orderAdditionalInfoTemplateId
      }
      setFormValues(newValues)
    }

    if (!loading && mask) init()
  }, [mask, loading, currentCurrency])

  useEffect(() => {
    if (Object.keys(formValues).length > 0 && !initialized) {
      setInitialized(true)
    }
  }, [formValues, initialized])

  useEffect(() => {
    setCurrentCurrency(data.currencyId)
  }, [])

  // if (initialized && calculator && !loading && Object.keys(formValues).length > 0) {
  //   console.log('ora renderizzo il form')

  //   return (
  //     <Form
  //       onSubmit={onSubmit}
  //       initialValues={formValues}
  //       decorators={[calculator as any]}
  //       render={(props): React.ReactNode => (
  //         <OrderEditForm {...props} data={data} saving={saving} setCurrentCurrency={setCurrentCurrency} />
  //       )}
  //     />
  //   )
  // } else return <CircularProgress />

  if (!initialized || !calculator || loading || Object.keys(formValues).length === 0) {
    return <CircularProgress />
  } else
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={formValues}
        decorators={[calculator as any]}
        render={(props): React.ReactNode => (
          <OrderEditForm {...props} data={data} saving={saving} setCurrentCurrency={setCurrentCurrency} />
        )}
      />
    )
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginTop: 0,
    marginRight: theme.spacing(5),
  },
  passengersInput: {
    maxWidth: 100,
  },
  dateTimeInput: {
    width: 'auto',
  },
  line: {
    display: 'flex',
    alignItems: 'stretch',
    alignContent: 'center',
    borderBottom: 0,
  },
  removeButton: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
    padding: 7,
    marginLeft: theme.spacing(5),
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  taxRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(4),
  },
  input: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  rootInput: {
    marginRight: theme.spacing(3),
  },
  createButton: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '100%',
  },
  addButton: {
    backgroundColor: theme.palette.common.white,
  },
}))

export default InDrawerOrderEditForm
