import React, { FC, ChangeEvent, useState, useCallback, useMemo, useEffect } from 'react'
import { FieldProps, FieldRenderProps, FieldInputProps, useForm } from 'react-final-form'
import { Validator, useDataProvider, useNotify, useTranslate, useRefresh, FieldTitle } from 'ra-core'

import {
  ReferenceManyField,
  SimpleForm,
  SaveButton,
  Button,
  TextInput,
  ImageInput,
  ImageField,
  required,
} from 'react-admin'
import clsx from 'clsx'
import { useFormState } from 'react-final-form'
import ProductGalleryList, { getColsForWidth } from './ProductGalleryList'
import IconCancel from '@material-ui/icons/Cancel'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { makeStyles } from '@material-ui/core/styles'
import { GridListTile, withWidth, GridList, InputLabel } from '@material-ui/core'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import { createForm } from 'final-form'
import ModalLightbox from './ModalLightbox'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import GalleryTileButton from './GalleryTileButton'
import GalleryTileSingleImage from './GalleryTileSingleImage'
import RemoveCircle from '@material-ui/icons/RemoveCircle'

export enum ModalType {
  ADD_TO_GALLERY = 'addToGallery',
  SET_CATALOG = 'setCatalog',
  SET_SECONDARY_CATALOG = 'setSecondaryCatalog',
  SET_FEATURED = 'setFeatured',
  SET_MOBILE_CATALOG = 'setMobileCatalog',
  SET_MOBILE_SECONDARY_CATALOG = 'setMobileSecondaryCatalog',
  SET_MOBILE_FEATURED = 'setMobileFeatured',
}

interface Props<T = any>
  extends Omit<FieldProps<any, FieldRenderProps<any, HTMLElement>, HTMLElement>, 'validate' | 'children'> {
  source?: string
  name?: string
  id?: string
  defaultValue?: any
  validate?: Validator | Validator[]
  onBlur?: (event: FocusEvent) => void
  onChange?: (event: ChangeEvent | any) => void
  onFocus?: (event: FocusEvent) => void
  options?: T
  input?: FieldInputProps<any, HTMLElement>
  meta?: any
  basePath?: string
  location?: any
  width: Breakpoint
  galleryIdSource?: string
  catalogPictureIdSource?: string
  catalogSecondaryPictureIdSource?: string
  featuredPictureIdSource?: string

  catalogMobilePictureIdSource?: string
  catalogMobileSecondaryPictureIdSource?: string
  featuredMobilePictureIdSource?: string
}

const useStyles = makeStyles((theme) => ({
  gridList: {
    maxWidth: '1000px',
    margin: 0,
  },
  productPicturesContainer: {
    paddingBottom: 40,
  },
  label: {
    marginBottom: 10,
  },
  root: {
    margin: '-2px',
    paddingBottom: '20px',
  },
  tileBar: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.6) 70%,rgba(0,0,0,0) 100%)',
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
  price: {
    display: 'inline',
    fontSize: '1em',
  },
  link: {
    color: '#fff',
  },
  removeButtonContainer: {
    display: 'inline-block',
    position: 'relative',
    float: 'left',
    '& button': {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      minWidth: theme.spacing(2),
      opacity: 0,
    },
    '&:hover button': {
      opacity: 1,
    },
  },
  removeIcon: {
    color: theme.palette.error.main,
  },
}))

const ProductGalleryInput: FC<Props> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [modalType, setModalType] = useState<ModalType | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [gallery, setGallery] = useState<any>(null)
  const [error, setError] = useState<string | undefined>(undefined)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const upperForm = useForm()
  const { values: formData } = useFormState()
  const [showLightboxPreview, setShowLightboxPreview] = useState(false)
  const [lightboxSelectedIndex, setLightboxSelectedIndex] = useState(0)
  const {
    catalogPictureIdSource = 'catalogPictureId',
    catalogSecondaryPictureIdSource = 'catalogSecondaryPictureId',
    galleryIdSource = 'galleryId',
    featuredPictureIdSource = 'featuredPictureId',
    catalogMobilePictureIdSource = 'catalogMobilePictureId',
    catalogMobileSecondaryPictureIdSource = 'catalogMobileSecondaryPictureId',
    featuredMobilePictureIdSource = 'featuredMobilePictureId',
  } = props

  const fetchGallery = useCallback(
    async (galleryId?: string): Promise<void> => {
      try {
        setLoading(true)
        const result = await dataProvider.getList('Picture', {
          filter: {
            [galleryIdSource]: galleryId || formData[galleryIdSource],
          },
          pagination: {
            page: 1,
            perPage: 20,
          },
          sort: {
            field: 'order',
            order: 'ASC',
          },
        })
        console.log(result, 'sono bello')
        setGallery(result)
      } catch (e) {
        setError(e.message)
      } finally {
        setLoading(false)
      }
    },
    [formData, galleryIdSource]
  )

  const onShowModalLightbox = useCallback((picture: any, index: number): void => {
    setShowLightboxPreview(true)
    setLightboxSelectedIndex(index)
  }, [])

  const onHideModalLightbox = useCallback(() => {
    setShowLightboxPreview(false)
  }, [])

  const handlePictureClick = useCallback(
    (picture: any, index: number) => (): void => {
      setShowLightboxPreview(true)
      setLightboxSelectedIndex(index)
    },
    []
  )

  const onShowAddPictureModal = useCallback(
    (type: ModalType) => (): void => {
      console.log('setto il tipo: ', type)
      setModalType(type)
    },
    []
  )

  const onCloseAddPictureModal = useCallback(() => {
    setModalType(undefined)
  }, [])

  const onSubmitPicture = useCallback(
    async (values: any): Promise<void> => {
      try {
        let source
        switch (modalType) {
          case ModalType.SET_CATALOG:
            source = catalogPictureIdSource
            break
          case ModalType.SET_SECONDARY_CATALOG:
            source = catalogSecondaryPictureIdSource
            break
          case ModalType.SET_FEATURED:
            source = featuredPictureIdSource
            break
          case ModalType.SET_MOBILE_CATALOG:
            source = catalogMobilePictureIdSource
            break
          case ModalType.SET_MOBILE_SECONDARY_CATALOG:
            source = catalogMobileSecondaryPictureIdSource
            break
          case ModalType.SET_MOBILE_FEATURED:
            source = featuredMobilePictureIdSource
            break
        }

        if (!source) {
          throw new Error('source is undefined')
        }

        const pictureResult = await dataProvider.create('Picture', {
          data: { ...values, galleryId: undefined },
        })

        console.log(modalType, source, 'sono sourcee')
        upperForm.change(source, pictureResult.data.id)
        notify(translate('customFields.galleryInput.modal.success'))
        setModalType(undefined)
        fetchGallery()
      } catch (e) {
        notify(translate('customFields.galleryInput.modal.error'), 'warning')
      }
    },
    [modalType]
  )

  const onSubmitGallery = useCallback(
    async (values: any): Promise<void> => {
      try {
        const originalGalleryId = formData ? formData[galleryIdSource] : undefined
        let newGalleryId = originalGalleryId
        if (!originalGalleryId) {
          const createGalleryResult = await dataProvider.create('Gallery', {
            data: {
              name: props.record.sku || props.record.name || 'Product gallery',
            },
          })
          newGalleryId = createGalleryResult.data.id
        }

        const pictureResult = await dataProvider.create('Picture', {
          data: { ...values, galleryId: newGalleryId },
        })

        if (originalGalleryId !== newGalleryId) {
          upperForm.change('galleryId', newGalleryId)
        }

        notify(translate('customFields.galleryInput.modal.success'))
        setModalType(undefined)
        fetchGallery(newGalleryId)
      } catch (e) {
        // console.error('Error uploading Picture', e)
        notify(translate('customFields.galleryInput.modal.error'), 'warning')
      }
    },
    [formData, props.record]
  )

  const onSubmit = useCallback(
    async (values: any): Promise<void> => {
      if (modalType === ModalType.ADD_TO_GALLERY) {
        return onSubmitGallery(values)
      } else {
        return onSubmitPicture(values)
      }
    },
    [modalType]
  )

  const form = createForm({ onSubmit: onSubmit, initialValues: {} })

  const handleSubmit = useCallback(() => {
    form.submit()
  }, [form])

  const handleRemoveImage = useCallback(
    (id: string) => async (): Promise<void> => {
      try {
        // previousData was missing in sneakpeek's implementation
        const pictureResult = await dataProvider.delete('Picture', { id, previousData: { id } })
        refresh()
      } catch (e) {
        console.error('error removing image')
      }
    },
    []
  )

  useEffect(() => {
    if (formData && formData[galleryIdSource]) {
      fetchGallery()
    }
  }, [])

  return (
    <>
      <InputLabel shrink className={classes.label}>
        <FieldTitle label={translate('customFields.galleryInput.title')} isRequired={false} />
      </InputLabel>
      <GridList
        cellHeight={180}
        cols={getColsForWidth(props.width)}
        className={clsx(classes.gridList, classes.productPicturesContainer)}
      >
        <GalleryTileSingleImage
          image={formData && formData[catalogPictureIdSource]}
          onClick={onShowAddPictureModal(ModalType.SET_CATALOG)}
          type="primary"
        />
        {/* <GalleryTileSingleImage
          image={formData && formData[catalogSecondaryPictureIdSource]}
          onClick={onShowAddPictureModal(ModalType.SET_SECONDARY_CATALOG)}
          type="secondary"
        /> */}

        {/* {props.resource === 'Raffle' && (
          <GalleryTileSingleImage
            image={formData && formData[featuredPictureIdSource]}
            onClick={onShowAddPictureModal(ModalType.SET_FEATURED)}
            type="featured"
          />
          // <GalleryTileButton
          //   onClick={onShowAddPictureModal(ModalType.SET_FEATURED)}
          //   label="customFields.galleryGrid.setFeaturedImage"
          // />
        )} */}
      </GridList>

      <InputLabel shrink className={classes.label}>
        <FieldTitle label={translate('customFields.galleryInput.mobileTitle')} isRequired={false} />
      </InputLabel>
      <GridList
        cellHeight={180}
        cols={getColsForWidth(props.width)}
        className={clsx(classes.gridList, classes.productPicturesContainer)}
      >
        <GalleryTileSingleImage
          image={formData && formData[catalogMobilePictureIdSource]}
          onClick={onShowAddPictureModal(ModalType.SET_MOBILE_CATALOG)}
          type="primary"
        />
        <GalleryTileSingleImage
          image={formData && formData[catalogMobileSecondaryPictureIdSource]}
          onClick={onShowAddPictureModal(ModalType.SET_MOBILE_SECONDARY_CATALOG)}
          type="secondary"
        />

        {/* {props.resource === 'Raffle' && (
          <GalleryTileSingleImage
            image={formData && formData[featuredMobilePictureIdSource]}
            onClick={onShowAddPictureModal(ModalType.SET_MOBILE_FEATURED)}
            type="featured"
          />
          // <GalleryTileButton
          //   onClick={onShowAddPictureModal(ModalType.SET_FEATURED)}
          //   label="customFields.galleryGrid.setFeaturedImage"
          // />
        )} */}
      </GridList>
      {formData && formData.galleryId ? (
        <>
          <InputLabel shrink className={classes.label}>
            <FieldTitle
              label="customFields.galleryInput.galleryTitle"
              source={'galleryId'}
              resource={'Product'}
              isRequired={false}
            />
          </InputLabel>
          <GridList cellHeight={180} cols={getColsForWidth(props.width)} className={classes.gridList}>
            <GalleryTileButton
              onClick={onShowAddPictureModal(ModalType.ADD_TO_GALLERY)}
              label="customFields.galleryGrid.addToGallery"
            />

            {!loading &&
              gallery &&
              gallery.data &&
              gallery.data.map(
                (item: any, index: number): React.ReactNode => (
                  <GridListTile
                    onClick={handlePictureClick(item, index)}
                    key={item.id}
                    classes={{ root: classes.removeButtonContainer }}
                  >
                    <img src={item.urlMedium} alt="" />
                    <GridListTileBar className={classes.tileBar} title={item.description} />
                    <IconButton
                      onClick={handleRemoveImage(item.id)}
                      aria-label={translate('ra.action.delete')}
                      title={translate('ra.action.delete')}
                    >
                      <RemoveCircle className={classes.removeIcon} />
                    </IconButton>
                  </GridListTile>
                )
              )}
          </GridList>
        </>
      ) : (
        // <ReferenceManyField
        //   source={galleryIdSource}
        //   basePath="/Picture"
        //   reference="Picture"
        //   target={galleryIdSource}
        //   record={record}
        // >
        //   <ProductGalleryList
        //     label={translate('customFields.galleryInput.galleryTitle')}
        //     showAddPictureTile
        //     onAddPictureClick={onShowAddPictureModal(ModalType.ADD_TO_GALLERY)}
        //     onRemoveClick={handleRemoveImage}
        //   />
        // </ReferenceManyField>
        <GridList cellHeight={180} cols={getColsForWidth(props.width)} className={classes.gridList}>
          <GalleryTileButton
            onClick={onShowAddPictureModal(ModalType.ADD_TO_GALLERY)}
            label="customFields.galleryGrid.addToGallery"
          />
        </GridList>
      )}

      <Dialog fullWidth open={!!modalType} onClose={onCloseAddPictureModal} aria-label="Create post">
        {modalType && <DialogTitle>{translate(`customFields.galleryInput.modal.title.${modalType}`)}</DialogTitle>}
        <DialogContent>
          <SimpleForm form={form} save={onSubmit} resource="Picture" toolbar={null}>
            <TextInput validate={required()} source="description" resource="Picture" fullWidth />
            <ImageInput validate={required()} source="file" label="Picture" accept="image/*" multiple={false}>
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <SaveButton handleSubmitWithRedirect={handleSubmit} />
          <Button label="ra.action.cancel" onClick={onCloseAddPictureModal}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>

      {/* <ModalLightbox
              onClose={onHideModalLightbox}
              open={showLightboxPreview}
              pictures={ids.map(id => ({ ...data[id] }))}
              selectedPictureIndex={lightboxSelectedIndex}
            /> */}
    </>
  )
}

export default withWidth()(ProductGalleryInput)
