import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './assets/fonts/Avenir-Book.ttf'
import './assets/fonts/Avenir-Heavy.ttf'
import './assets/fonts/Avenir-Medium.ttf'
import { ChakraProvider, theme } from '@chakra-ui/react'

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
