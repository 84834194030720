import { Box, CircularProgress, Container, Fade, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { useInfoCardStyles } from './InfoCard.styles'

type Props = {
  children: any
}

const InfoCard: FC<Props> = (props) => {
  const classes = useInfoCardStyles()
  return <Box className={classes.card} {...props}></Box>
}

export default InfoCard
