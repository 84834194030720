import { ApolloClient, useApolloClient } from '@apollo/client'
import filesize from 'filesize'
import fileDownload from 'js-file-download'
import { useNotify } from 'ra-core'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { MUTATION_SAVE_ATTACHMENT } from '../../queries'
import { MailAttachment } from './MailAttachment'
import { ResizableIFrame } from './ResizableIFrame'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const nl2br = require('react-nl2br')

const resolveAttachmentUrl = async ({
  messageId,
  attachment,
  client,
}: {
  messageId: string
  attachment: any
  client: ApolloClient<any>
}): Promise<string> => {
  const { data, errors } = await client.mutate({
    mutation: MUTATION_SAVE_ATTACHMENT,
    variables: {
      messageId,
      attachment: {
        id: attachment.attachmentId,
        name: attachment.fileName,
        mimeType: attachment.mimeType,
        size: attachment.size,
        contentId: attachment.contentId,
      },
    },
  })

  return data.saveGmailAttachmentById as string
}

export type MailPartsProps = {
  //
} & MailPartProps

/**
 *
 * Entry point for reading Mail Parts.
 * it render all the parts that are present in part project
 */
export const MailParts: FC<MailPartsProps> = ({ part, ...props }) => {
  // const isAlternative = useMemo(() => {
  //   if (!part?.mimeType) {
  //     if (part?.parts?.find((item: any) => item.mimeType === 'text/plain')) {
  //       return true
  //     }
  //   }

  //   return false
  // }, [part])

  // if (isAlternative) {
  //   return <MultiPartRelated part={part} {...props} />
  // }

  return (
    <>
      {(part?.parts as any[])?.map((subpart) => (
        <MailPart {...props} key={subpart.id} part={subpart} />
      ))}
    </>
  )
}

export type MailPartProps = {
  part: any
  messageId: string
  relatedAttachmentsMap?: Record<string, any>
}

/**
 *
 * Render a mail part based on the mime type.
 * It support:
 * - text/plain
 * - text/html
 * - multipart/related
 * - multipart/alternative
 * - multipart/mixed
 */
export const MailPart: FC<MailPartProps> = (props) => {
  const { part } = props

  const component = useMemo(() => {
    switch (part?.mimeType) {
      case 'text/plain':
        return <TextPlainPart {...props} />
      case 'text/html':
        return <TextHTMLPart {...props} />
      case 'multipart/related':
        return <MultiPartRelated {...props} />
      case 'multipart/alternative':
        return <MultiPartAlternative {...props} />
      case 'multipart/mixed':
        return <MailParts {...props} />
      default:
        if (part?.attachmentId) {
          return <AttachmentPart {...props} />
        }
    }
  }, [part])

  return <>{component}</>
}

export type MultiPartRelatedProps = {
  //
} & MailPartProps

/**
 * Render a multipart/related mail part using the html present.
 */
export const MultiPartRelated: FC<TextPlainPartProps> = ({ part, messageId, ...props }) => {
  const [relatedAttachments, setRelatedAttachments] = useState<Record<string, any>>({})
  const [toRenderParts, setToRenderParts] = useState<any[]>([])
  const client = useApolloClient()
  const notify = useNotify()

  const resolveAttachment = useCallback(
    async (attachment: any): Promise<string | undefined> => {
      try {
        return await resolveAttachmentUrl({ attachment, client, messageId })
      } catch (error) {
        console.error('Error resolving attachment', error)
        notify('Error resolving attachment', 'error')
      }

      return undefined
    },
    [messageId]
  )

  useEffect(() => {
    const resolveAttachments = async (): Promise<void> => {
      const foundedAttachments = (part?.parts || []).filter((item: any) => !!item.contentId)
      const resolvedAttachments = await Promise.all(
        foundedAttachments.map(async (item: any) => {
          const resolvedAttachmentUrl = await resolveAttachment(item)
          return { ...item, resolvedUri: resolvedAttachmentUrl }
        })
      )

      setRelatedAttachments(
        (resolvedAttachments as any[]).reduce((acc, item) => {
          const cid = item.contentId.substring(1, item.contentId.length - 1)
          return {
            ...acc,
            [cid]: item,
          }
        }, {})
      )
      const newToRenderParts = (part?.parts || []).filter(
        (item: any) => !foundedAttachments.find((att: any) => att.attachmentId === item.attachmentId)
      )
      setToRenderParts(newToRenderParts)
    }

    resolveAttachments()
  }, [])

  return (
    <>
      {toRenderParts?.map((subpart) => (
        <MailPart
          key={subpart.id}
          {...props}
          part={subpart}
          messageId={messageId}
          relatedAttachmentsMap={relatedAttachments}
        />
      ))}
    </>
  )
}

export type MultiPartAlternativeProps = {
  //
} & MailPartProps

/**
 *
 * Render a multiplart/alternative part.
 */
export const MultiPartAlternative: FC<MultiPartAlternativeProps> = ({ part, ...props }) => {
  const toRenderPart = useMemo(() => {
    const parts: any[] = part?.parts || []

    const htmlPart = parts.find((item: any) => item.mimeType === 'text/html')
    const mixedPart = parts.find((item: any) => item.mimeType === 'multipart/mixed')
    const relatedPart = parts.find((item: any) => item.mimeType === 'multipart/related')
    const textPart = parts.find((item: any) => item.mimeType === 'text/plain')

    if (htmlPart) {
      return htmlPart
    }

    if (mixedPart) {
      return mixedPart
    }

    if (relatedPart) {
      return relatedPart
    }

    if (textPart) {
      return textPart
    }

    return undefined
  }, [part?.parts])

  if (!toRenderPart) {
    return <></>
  }

  return <MailPart {...props} part={toRenderPart} />
}

export type TextPlainPartProps = {
  //
} & MailPartProps

/**
 * Render a text/plain mail part
 */
export const TextPlainPart: FC<TextPlainPartProps> = ({ part }) => {
  const content = useMemo(() => nl2br(part?.content || ''), [part?.content])

  return <>{content}</>
}

export type TextHTMLPartProps = {
  //
} & MailPartProps

/**
 *
 * Render a text/html part
 */
export const TextHTMLPart: FC<TextHTMLPartProps> = ({ part, relatedAttachmentsMap }) => {
  const content = useMemo(() => {
    if (!part?.content) {
      return ''
    }

    let modifiedHtml: string = part?.content || ''
    if (modifiedHtml.indexOf('<head>') !== -1) {
      modifiedHtml = modifiedHtml.replace(
        '<head>',
        `
        <head>
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
          <style>
            body {
              min-height: 0;
              height: auto;
              font-family: Roboto, sans-serif;
            }
          </style>
      `
      )
    } else {
      modifiedHtml = `
        <html>
        <head>
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
          <style>
            body {
              min-height: 0;
              height: auto;
              font-family: Roboto, sans-serif;
            }
          </style>
        </head>
        ${modifiedHtml}
        </html>
      `
    }

    if (relatedAttachmentsMap && Object.keys(relatedAttachmentsMap).length > 0) {
      const attachmentIds = Object.keys(relatedAttachmentsMap)
      for (const cid of attachmentIds) {
        if (modifiedHtml.indexOf(`cid:${cid}`) > -1 && relatedAttachmentsMap[cid].resolvedUri) {
          modifiedHtml = modifiedHtml.replaceAll(`cid:${cid}`, relatedAttachmentsMap[cid].resolvedUri)
        }
      }
    }

    return modifiedHtml
  }, [part?.content])

  return <ResizableIFrame content={content} />
}

export type AttachmentPartProps = {
  //
} & MailPartProps

/**
 * Render a text/plain mail part
 */
export const AttachmentPart: FC<AttachmentPartProps> = ({ part, messageId }) => {
  const notify = useNotify()
  const client = useApolloClient()
  const [loading, setLoading] = useState<boolean>(false)
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>()

  const handleDownload = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const downloadUrl = await resolveAttachmentUrl({ attachment: part, client, messageId })

      setDownloadUrl(downloadUrl)

      const downloadResult = await fetch(downloadUrl)
      const fileBlob = await downloadResult.blob()
      fileDownload(fileBlob, downloadUrl)
    } catch (error) {
      console.error('Error resolving attachment', error)
      notify('Error resolving attachment', 'error')
    } finally {
      setLoading(false)
    }
  }, [part, messageId])

  return (
    <div style={{ maxWidth: '400px', padding: '10px' }}>
      <MailAttachment
        filesize={filesize(part.size)}
        filename={part.fileName}
        attachmentUrl={downloadUrl}
        onDownload={handleDownload}
        isLoading={loading}
      />
    </div>
  )
}
