import React, { FC, useEffect } from 'react'
import { useGetOne } from 'ra-core'
import { useForm, useFormState } from 'react-final-form'
import { ReferenceInput, SelectInput, TextInput, DateInput } from 'react-admin'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'

const filterToQueryName = (name: string): Record<string, string> => ({ name })

export enum DocumentType {
  ID = 'ID',
  passport = 'passport',
  drivingLicense = 'drivingLicense',
}

export const DOCUMENTTYPE_SELECTCHOICES = [
  { id: DocumentType.ID, name: DocumentType.ID },
  { id: DocumentType.passport, name: DocumentType.passport },
  { id: DocumentType.drivingLicense, name: DocumentType.drivingLicense },
]

const PassengerForm: FC<any> = (props) => {
  const { values } = useFormState()
  const form = useForm()
  const { loading, error, data } = useGetOne('Passenger', values.passengerId, { enabled: !!values.passengerId })

  useEffect((): any => {
    if (!loading && !error && data) {
      const { createdAt, updatedAt, id, ...fields } = data
      Object.entries(fields).map(([k, val]) => {
        form.change(k, val)
      })
    }
  }, [data, loading])

  return (
    <>
      <TextInput source="firstName" fullWidth variant="outlined" />
      <TextInput source="lastName" fullWidth variant="outlined" />
      <ReferenceInput
        reference="Country"
        source="nationality"
        filterToQuery={filterToQueryName}
        label="Nationality"
        variant="outlined"
      >
        <AutocompleteInputInDrawer optionText="name" fullWidth />
      </ReferenceInput>
      <DateInput source="birthDate" fullWidth variant="outlined" />
      <SelectInput source="documentType" choices={DOCUMENTTYPE_SELECTCHOICES} variant="outlined" />
      <TextInput source="documentNumber" fullWidth variant="outlined" />
      <DateInput source="releaseDate" fullWidth variant="outlined" />
      <DateInput source="expirationDate" fullWidth variant="outlined" />
    </>
  )
}
export default PassengerForm
