import React, { FC, useState, useEffect } from 'react'
import { useInput, Loading, useDataProvider } from 'react-admin'
import { Select, InputLabel, makeStyles, Grid, FormControl, Button, Box, Radio, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  label: {
    marginBottom: theme.spacing(1)
  },
  deleteButton: {
    color: theme.palette.error.main
  }
}))

const ProductFeatureValueInput: FC<any> = props => {
  const {
    input: { name, onChange, value }
  } = useInput(props)

  const {
    input: { name: nameMain, onChange: onChangeMain, value: valueMain }
  } = useInput({ source: props.mainSource })

  const classes = useStyles()
  const [features, setFeatures] = useState<any>({})
  const [choices, setChoices] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const dataProvider = useDataProvider()

  useEffect(() => {
    Promise.all([
      dataProvider.getList('ProductFeature', {
        // @todo: find a better way to load all features
        pagination: { disabled: true },
        sort: { field: 'name', order: 'ASC' },
        filter: {}
      }),
      dataProvider.getList('ProductFeatureValue', {
        // @todo: find a better way to load all values
        pagination: { disabled: true },
        sort: { field: 'value', order: 'ASC' },
        filter: {}
      })
    ]).then((response: any[]): void => {
      const [featuresResponse, valuesResponse] = response

      setFeatures(featuresResponse.data)
      setChoices(valuesResponse.data)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Loading />
  }

  const inputs = {
    feature: { id: `${name}--featureInput` },
    value: { id: `${name}--valueInput` }
  }

  const handleChangeFeature = async (key: number, e: any): Promise<void> => {
    const { value: featureId } = e.target

    // compute the new value
    const newValue = value.map((featureValue: any, index: any): any => {
      if (index === key && featureValue.productFeatureId !== featureId) {
        return { ...featureValue, productFeatureId: featureId }
      }
      return featureValue
    })

    // change the value on the form
    onChange(newValue)
  }

  const handleChangeFeatureValue = (key: number, e: any): void => {
    const { value: valueId } = e.target

    // compute the new value
    const newValue = value.map((featureValue: any, index: any): any => {
      if (index === key && featureValue.id !== valueId) {
        return {
          ...featureValue,
          id: valueId
        }
      }
      return featureValue
    })

    // change the value on the form
    onChange(newValue)
  }

  const handleAddFeature = (): void => {
    const currentValue = value || []
    onChange([...currentValue, { id: '' }])
  }

  const handleDeleteFeature = (key: number): void => {
    onChange(value.filter((item: any, index: number) => index !== key))
  }

  const handleChangeMain = (e: any): void => {
    const { value } = e.target
    onChangeMain(value)
  }

  return (
    <div className={classes.root}>
      <InputLabel className={classes.label}>{props.label}</InputLabel>
      {value &&
        value.length > 0 &&
        value.map((v: any, index: number): any => {
          const choice = !v.id || v.id === '' ? v : choices.find((item: any): any => item.id == v.id)

          return (
            <Box display="flex" alignItems="center" key={index} mb={1}>
              <Box flex={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel htmlFor={inputs.feature.id}>Feature</InputLabel>
                      <Select
                        native
                        value={choice.productFeatureId || ''}
                        onChange={(e: any): any => handleChangeFeature(index, e)}
                        inputProps={inputs.feature}
                      >
                        <option value="" />
                        {features &&
                          features.map((feature: any): any => (
                            <option key={feature.id} value={feature.id}>
                              {feature.name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel htmlFor={inputs.value.id}>Value</InputLabel>
                      <Select
                        native
                        value={choice.id || ''}
                        onChange={(e: any): any => handleChangeFeatureValue(index, e)}
                        inputProps={inputs.value}
                      >
                        <option value="" />
                        {choices
                          .filter((item: any): any => item.productFeatureId === choice.productFeatureId)
                          .map((option: any): any => (
                            <option key={option.id} value={option.id}>
                              {option.value}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box flex="0 0 auto" px={1}>
                <Radio
                  name={nameMain}
                  onChange={handleChangeMain}
                  checked={valueMain !== '' && valueMain === choice.id}
                  value={choice.id}
                  color="primary"
                />
              </Box>
              <Box flex="0 0 auto">
                <IconButton
                  aria-label="delete feature"
                  component="span"
                  className={classes.deleteButton}
                  onClick={(): any => handleDeleteFeature(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          )
        })}
      <Button color="primary" onClick={handleAddFeature}>
        Add feature
      </Button>
    </div>
  )
}

ProductFeatureValueInput.defaultProps = {
  label: 'Features'
}

export default React.memo(ProductFeatureValueInput)
