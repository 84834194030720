export enum ROLEPARAMTYPEENUM {
  string = 'STRING',
  number = 'NUMBER',
  boolean = 'BOOLEAN',
}

export const ROLEPARAMTYPECHOICES = [
  { id: ROLEPARAMTYPEENUM.boolean, name: 'Boolean' },
  { id: ROLEPARAMTYPEENUM.number, name: 'Number' },
  { id: ROLEPARAMTYPEENUM.string, name: 'String' },
]
