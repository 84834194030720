import { Avatar, Box, CircularProgress, makeStyles, MuiThemeProvider, Theme, Typography } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import defaultTheme from '../../theme'
import { useTranslate, useListContext, useLocale, number } from 'ra-core'
import englishMessages from '../../i18n/en'
import italianMessages from '../../i18n/it'
import BaseTypography from '../BaseTypography'

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '180px',
    height: '180px',
    overflow: 'hidden',
    '& > img': {
      width: '180px',
      margin: 0,
    },
    marginBottom: theme.spacing(3),
  },
  component: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(5),
    height: 'inherit',
    width: '100%',
    textAlign: 'center',
  },
}))

type Props = {
  theme?: Theme
  logo?: string
  width?: string | number
  height?: string | number
  component?: any
}

const Loading: FC<Props> = ({ width = '100%', height = '100vh', component = Box, ...rest }) => {
  const { logo } = rest
  const theme = rest.theme || defaultTheme
  const classes = useStyles()
  const translate = useTranslate()
  const locale = useLocale()
  const Component = component

  const loadingMessage = useMemo(() => {
    if (locale === 'it') {
      return italianMessages.ra.page.loading
    }
    return englishMessages.ra.page.loading
  }, [locale])

  return (
    <MuiThemeProvider theme={theme}>
      <Box
        width={width}
        height={height}
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={theme.palette.background.default}
      >
        <Component className={classes.component}>
          {logo && (
            <Box boxShadow={3} borderRadius={30} className={classes.logo} overflow="hidden">
              <img src={logo} />
            </Box>
          )}
          <CircularProgress color="primary" />
          <Box marginTop={4}>
            <BaseTypography color="textSecondary" variant="h3" paragraph>
              {loadingMessage}
            </BaseTypography>
          </Box>
        </Component>
      </Box>
    </MuiThemeProvider>
  )
}

export default Loading
