import React, { FC, useState, useEffect } from 'react'
import { useField } from 'react-final-form'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import TextField from '@material-ui/core/TextField'
import { useDataProvider, useInput, InputProps } from 'ra-core'
import { Labeled, TextInput } from 'react-admin'

export const PRICE_FORMAT_DESCRIPTORS: any = {
  'X0,000.00': {
    decimal: '.',
    thousand: ',',
    precision: 2,
    symbolPosition: 'before'
  },
  '0 000,00X': {
    decimal: ',',
    thousand: ' ',
    precision: 2,
    symbolPosition: 'after'
  },
  'X0.000,00': {
    decimal: ',',
    thousand: '.',
    precision: 2,
    symbolPosition: 'before'
  },
  '0,000.00X': {
    decimal: '.',
    thousand: ',',
    precision: 2,
    symbolPosition: 'after'
  },
  // eslint-disable-next-line quotes
  "0'000.00X": {
    decimal: '.',
    // eslint-disable-next-line quotes
    thousand: "'",
    precision: 2,
    symbolPosition: 'after'
  }
}

// TODO: RE-ENABLE DEFAULT SETTINGS FROM SHOPSETTINGS (NOW MS-CONFIGURATION)
const PriceInput: FC<InputProps<{ label?: string; decimalScale?: number; defaultCurrency?: any }>> = props => {
  const {
    input: { name, onChange, value },
    meta: { touched, error },
    isRequired
  } = useInput(props)
  const dataProvider = useDataProvider()
  const [defaultCurrency, setDefaultCurrency] = useState<any>(props.defaultCurrency)
  const [loading, setLoading] = useState(false)

  const onNumberChange = (values: NumberFormatValues): void => {
    onChange(values.floatValue)
  }

  useEffect(() => {
    const getDefaultCurrency = async (): Promise<void> => {
      try {
        setLoading(true)
        // const shopSettingsResult = await dataProvider.getOne('ShopSettings', { id: '' })
        const defaultCurrencyResult = await dataProvider.getList('Currency', {
          filter: {
            // isoCode: shopSettingsResult.data.defaultCurrency
            isoCode: 'EUR'
          },
          pagination: {
            page: 1,
            perPage: 1
          },
          sort: {
            field: 'id',
            order: 'ASC'
          }
        })
        if (defaultCurrencyResult.data.length < 1) {
          throw new Error('default currency is set but currency does not exists')
        } else {
          const newDefaultCurrency = defaultCurrencyResult.data[0]
          setDefaultCurrency(newDefaultCurrency)
          setLoading(false)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }

    if (!defaultCurrency) {
      getDefaultCurrency()
    }
  }, [])

  if (loading || !defaultCurrency) {
    return null
  }

  // return <div>{JSON.stringify(defaultCurrency)}</div>
  return (
    <Labeled label={props.label} resource={props.resource} source={props.source}>
      <NumberFormat
        name={name}
        required={isRequired}
        error={!!(touched && error)}
        helperText={touched && error}
        onValueChange={onNumberChange}
        disabled={loading}
        value={value}
        customInput={TextInput}
        thousandSeparator={PRICE_FORMAT_DESCRIPTORS[defaultCurrency.format.toUpperCase()].thousand}
        decimalSeparator={PRICE_FORMAT_DESCRIPTORS[defaultCurrency.format.toUpperCase()].decimal}
        fixedDecimalScale={
          props.decimalScale || PRICE_FORMAT_DESCRIPTORS[defaultCurrency.format.toUpperCase()].precision
        }
        decimalScale={props.decimalScale || PRICE_FORMAT_DESCRIPTORS[defaultCurrency.format.toUpperCase()].precision}
        isNumericString
      />
    </Labeled>
  )
}

export default PriceInput
