import gql from 'graphql-tag'

export const QUERY_GET_EVENT_STATUSES_CONFIGURATION = gql`
  query GetEventStatusesConfiguration {
    getConfiguration(key: "CALENDAR_PLANNING_STATUSES") {
      id
      key
      value
    }
  }
`

export const QUERY_GET_PLANNED_EVENT = gql`
  query GetPlannedEventStatus($id: ID!) {
    event(id: $id) {
      id
      startDate
      endDate
      title
      description
      orderId
      quoteId
      customerId
      teamMemberIds
    }
  }
`

export const QUERY_GET_USER_ME = gql`
  query GetUserMe {
    userMe {
      id
      firstName
      lastName
      roles {
        id
        name
        params
      }
    }
  }
`

export const QUERY_GET_TEAMMEMBER_ME = gql`
  query GetTeamMemberMe {
    teamMemberMe {
      id
      accountId
      firstName
      lastName
      roles
      office {
        id
        areaId
      }
    }
  }
`

export const MUTATION_CREATE_CIELO_QUOTE = gql`
  mutation createCieloQuote($data: CieloQuoteCreateInput!) {
    createCieloQuote(data: $data) {
      id
    }
  }
`

export const MUTATION_UPDATE_CIELO_QUOTE = gql`
  mutation updateCieloQuote($id: ID!, $data: CieloQuoteUpdateInput!) {
    updateCieloQuote(id: $id, data: $data) {
      id
    }
  }
`

export const MUTATION_CONFIRM_QUOTE_OPTION = gql`
  mutation confirmQuoteOption($quoteOptionId: ID!) {
    confirmQuoteOption(quoteOptionId: $quoteOptionId) {
      id
    }
  }
`

export const MUTATION_UPDATE_QUOTE_STATUS = gql`
  mutation updateQuoteStatus($status: QuoteStatus!, $id: ID!) {
    updateQuoteStatus(status: $status, id: $id) {
      id
    }
  }
`

export const MUTATION_UPDATE_ORDER_STATUS = gql`
  mutation updateOrderStatus($status: OrderStatus!, $id: ID!) {
    updateOrderStatus(status: $status, id: $id) {
      id
    }
  }
`

export const QUERY_ORDER_CHECKLIST_GROUPS = gql`
  query orderChecklistGroups($filters: OrderChecklistGroupFilterInput) {
    orderChecklistGroups(filters: $filters) {
      data {
        id
        itemPosition
        name
        items {
          id
          orderChecklistGroupId
          name
          done
          documentId
          itemPosition
          hasDocument
        }
      }
    }
  }
`

export const MUTATION_UPDATE_ORDER_CHECKLIST_ITEM = gql`
  mutation updateOrderChecklistItem($data: OrderChecklistItemUpdateDataInput!, $id: ID!) {
    updateOrderChecklistItem(data: $data, id: $id) {
      id
    }
  }
`

export const DELETE_CIELO_ORDER = gql`
  mutation deleteCieloOrder($id: ID!) {
    deleteCieloOrder(id: $id)
  }
`

export const DELETE_CIELO_QUOTE = gql`
  mutation deleteCieloQuote($id: ID!) {
    deleteCieloQuote(id: $id)
  }
`

export const CREATE_PASSENGER_ROUTE = gql`
  mutation createPassengerRoute($data: PassengerRouteCreateDataInput!) {
    createPassengerRoute(data: $data) {
      passengerId
      orderRouteId
    }
  }
`

export const QUERY_GET_PICTURES = gql`
  query getPictures($filters: PictureFilterInput, $pagination: PaginationInputType, $sort: PictureSortInput) {
    pictures(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        description
        urlSmall
        urlMedium
        urlLarge
      }
    }
  }
`

export const MUTATION_GENERATE_CIELO_QUOTE_PRINT = gql`
  mutation GenerateCieloQuotePrint($id: ID!) {
    generateCieloQuotePrint(id: $id)
  }
`

export const MUTATION_GENERATE_CIELO_ORDER = gql`
  mutation GenerateCieloFlightOrderPrint($orderId: ID!) {
    generateCieloOrderPrint(orderId: $orderId)
  }
`

export const MUTATION_GENERATE_CIELO_FLIGHT_BRIEF = gql`
  mutation GenerateCieloFlightPrint($orderId: ID!, $routeIds: [ID!]) {
    generateCieloFlightBriefPrint(orderId: $orderId, routeIds: $routeIds)
  }
`

export const QUERY_GET_ORDER_ADDITIONAL_INFO = gql`
  query GetOrderAdditionalInfo($orderId: ID) {
    orderAdditionalInfos(filters: { orderId: $orderId }) {
      data {
        orderAdditionalInfoTemplateId
        value {
          id
          languageId
          value
          orderAdditionalInfoTemplate {
            id
            name
            category
          }
        }
        orderId
      }
    }
  }
`

export const MUTATION_DELETE_PAX_ROUTE = gql`
  mutation deletePaxRoute($id: ID, $orderRouteId: ID, $passengerId: ID) {
    deletePassengerRoute(id: $id, orderRouteId: $orderRouteId, passengerId: $passengerId)
  }
`

export const QUERY_GET_ORDER_ROUTES = gql`
  query GetOrderRoutes($sort: OrderRouteSortInput, $filters: OrderRouteFilterInput, $pagination: PaginationInputType) {
    orderRoutes(sort: $sort, filters: $filters, pagination: $pagination) {
      data {
        id
        departureAirportId
        arrivalAirportId
        vehicleId
        flightDate
        flightNumber
        captainName
        captainNumber
        firstOfficerName
        firstOfficerNumber
        flightAttendantName
        flightAttendantNumber
        departureHandlingName
        departureHandlingNumber
        arrivalHandlingName
        arrivalHandlingNumber
        passengersQuantity
      }
    }
  }
`

export const QUERY_CHECK_GOOGLE_TOKEN = gql`
  query CheckGoogleToken {
    checkGoogleToken {
      user
      found
      error
    }
  }
`

export const QUERY_GET_GOOGLE_AUTH_URL = gql`
  query GetGoogleAuthUrl {
    getGoogleAuthUrl {
      authUrl
      error
    }
  }
`

export const MUTATION_VERIFY_GOOGLE_CODE = gql`
  mutation VerifyGoogleCode($code: String!) {
    verifyGoogleCode(code: $code) {
      success
      email
      error
    }
  }
`

export const GET_CUSTOMER_PEOPLE = gql`
  query GetCustomerPeople($customerId: ID) {
    people(filters: { referencedCustomerId: $customerId }, pagination: { disabled: true }) {
      data {
        id
        email
        firstName
        lastName
      }
    }
  }
`

export const GET_CUSTOMER_MAIL_AND_PEOPLE_MAIL = gql`
  query GetCustomerMailAndPeopleMail($customerId: ID!) {
    customer(id: $customerId) {
      id
      email
    }

    people(filters: { referencedCustomerId: $customerId }, pagination: { disabled: true }) {
      data {
        id
        email
        firstName
        lastName
      }
    }
  }
`

export const MUTATION_SEND_MAIL = gql`
  mutation SendMailToCustomer(
    $attachmentUrl: String!
    $attachmentName: String
    $content: String!
    $subject: String
    $recipients: [String!]!
    $cc: [String!]
    $senderName: String!
  ) {
    sendGmail(
      attachmentUrl: $attachmentUrl
      attachmentName: $attachmentName
      content: $content
      subject: $subject
      recipients: $recipients
      cc: $cc
      senderName: $senderName
    )
  }
`

export const MUTATION_GOOGLE_LOGOUT = gql`
  mutation GoogleLogout {
    googleLogout
  }
`

export const QUERY_GET_EMAIL_TEMPLATES = gql`
  query GetEmailTemplates(
    $sort: GmailTemplateSortInput
    $pagination: PaginationInputType
    $filters: GmailTemplateFilterInput
  ) {
    gmailTemplates(sort: $sort, pagination: $pagination, filters: $filters) {
      total
      data {
        id
        name
        category
        values {
          id
          languageId
          subject
          value
        }
      }
    }
  }
`

export const QUERY_GET_EMAIL_TEMPLATES_AND_CUSTOMER_LANGUAGE = gql`
  query GetEmailTemplatesAndCustomerLanguage(
    $sort: GmailTemplateSortInput
    $pagination: PaginationInputType
    $filters: GmailTemplateFilterInput
    $customerId: ID!
  ) {
    gmailTemplates(sort: $sort, pagination: $pagination, filters: $filters) {
      total
      data {
        id
        name
        category
        values {
          id
          languageId
          subject
          value
        }
      }
    }

    customer(id: $customerId) {
      id
      languageId
    }
  }
`

export const QUERY_GET_CIELO_QUOTE_REPLACEMENTS = gql`
  query GetCieloQuoteReplacements($id: ID!) {
    replacements: generateCieloQuoteReplacements(id: $id)
  }
`

export const QUERY_GET_CIELO_ORDER_REPLACEMENTS = gql`
  query GetCieloOrderReplacements($id: ID!) {
    replacements: generateCieloOrderReplacements(id: $id)
  }
`

export const QUERY_GET_CIELO_FLIGHT_BRIEF_REPLACEMENTS = gql`
  query GetCieloFlightBriefReplacements($id: ID!) {
    replacements: generateCieloFlightBriefReplacements(id: $id)
  }
`

export const QUERY_GET_GMAIL_SETTINGS = gql`
  query GetGmailSettings {
    gmailSettings {
      id
      defaultCC
    }
  }
`

export const MUTATION_UPLDATE_GMAIL_SETTINGS = gql`
  mutation UpdateGmailSettings($data: GmailSettingsUpdateDataInput!) {
    updateGmailSettings(data: $data) {
      id
      defaultCC
    }
  }
`

export const MUTATION_SAVE_ATTACHMENT = gql`
  mutation SaveAttachment($messageId: String!, $attachment: SimpleAttachmentInput!) {
    saveGmailAttachmentById(messageId: $messageId, attachment: $attachment)
  }
`

export const QUERY_GET_ACTIVITY_TYPES = gql`
  query GetActivityTypes(
    $filters: ActivityTypeFilterInput
    $sort: ActivityTypeSortInput
    $pagination: PaginationInputType
  ) {
    activityTypes(filters: $filters, sort: $sort, pagination: $pagination) {
      total
      offset
      data {
        id
        icon
        label
        isNote
      }
    }
  }
`

export const QUERY_GET_NOTE_ACTIVITY_TYPE = gql`
  query GetNoteActivityType {
    activityTypeNote {
      id
      icon
      label
      isNote
    }
  }
`

export const MUTATION_CREATE_CUSTOMER_ACTIVITY = gql`
  mutation CreateCustomerActivity($data: CustomerActivityCreateInput!) {
    createCustomerActivity(data: $data) {
      id
    }
  }
`

export const QUERY_GET_CUSTOMER_ACTIVITIES = gql`
  query GetCustomerActivities(
    $filters: CustomerActivityFilterInput
    $sort: CustomerActivitySortInput
    $pagination: PaginationInputType
  ) {
    customerActivities(filters: $filters, sort: $sort, pagination: $pagination) {
      total
      data {
        id
        customerId
        typeId
        textValue
        date
        createdUserId
        type {
          id
          label
          icon
        }
      }
    }
  }
`

export const MUTATION_DELETE_CUSTOMER_ACTIVITY = gql`
  mutation DeleteCustomerActivity($id: ID!) {
    deleteCustomerActivity(id: $id)
  }
`

export const MUTATION_CREATE_QUOTE_ACTIVITY = gql`
  mutation CreateQuoteActivity($data: CieloQuoteActivityCreateInput!) {
    createCieloQuoteActivity(data: $data) {
      id
    }
  }
`

export const QUERY_GET_QUOTE_ACTIVITIES = gql`
  query GetQuoteActivities(
    $filters: CieloQuoteActivityFilterInput
    $sort: CieloQuoteActivitySortInput
    $pagination: PaginationInputType
  ) {
    cieloQuoteActivities(filters: $filters, sort: $sort, pagination: $pagination) {
      total
      data {
        id
        quoteId
        typeId
        textValue
        date
        createdUserId
        type {
          id
          label
          icon
        }
      }
    }
  }
`

export const MUTATION_DELETE_QUOTE_ACTIVITY = gql`
  mutation DeleteQuoteActivity($id: ID!) {
    deleteCieloQuoteActivity(id: $id)
  }
`

export const MUTATION_CREATE_ORDER_ACTIVITY = gql`
  mutation CreateOrderActivity($data: CieloOrderActivityCreateInput!) {
    createCieloOrderActivity(data: $data) {
      id
    }
  }
`

export const QUERY_GET_ORDER_ACTIVITIES = gql`
  query GetOrderActivities(
    $filters: CieloOrderActivityFilterInput
    $sort: CieloOrderActivitySortInput
    $pagination: PaginationInputType
  ) {
    cieloOrderActivities(filters: $filters, sort: $sort, pagination: $pagination) {
      total
      data {
        id
        orderId
        typeId
        textValue
        date
        createdUserId
        type {
          id
          label
          icon
        }
      }
    }
  }
`

export const MUTATION_DELETE_ORDER_ACTIVITY = gql`
  mutation DeleteOrderActivity($id: ID!) {
    deleteCieloOrderActivity(id: $id)
  }
`

export const QUERY_GET_SERVICE_LOGS = gql`
  query GetServiceLogs($filters: JSONObject, $sort: ServiceLogSortInput, $pagination: PaginationInputType) {
    serviceLogs(filters: $filters, sort: $sort, pagination: $pagination) {
      total
      data {
        id
        userId
        service
        domain
        action
        data
        createdAt
        date: createdAt
      }
    }
  }
`

export const GET_CUSTOMER_MAILS = gql`
  query GetCustomerMails($filters: GetEmailListFilters!, $maxResults: Float, $pageToken: String) {
    getGmailList(filters: $filters, maxResults: $maxResults, pageToken: $pageToken) {
      messages {
        id
        threadId
      }

      nextPageToken
      resultSizeEstimate
    }
  }
`

export const GET_MAIL_BY_ID = gql`
  query GetMailById($id: String!) {
    getGmailById(id: $id) {
      id
      historyId
      internalDate
      payload
      sizeEstimate
      snippet
      threadId
      labelIds
      raw
      parts
      attachments {
        id
        name
        mimeType
        size
      }
    }
  }
`

export const MUTATION_UPDATE_PLANNED_EVENT = gql`
  mutation UpdatePlannedEvent($data: EventUpdateInput!, $id: ID!) {
    updateEvent(data: $data, id: $id) {
      id
      startDate
      endDate
      title
      description
      customerId
      teamMemberIds
      quoteId
      orderId
    }
  }
`

export const QUERY_GET_VEHICLE_BY_ID = gql`
  query GetVehicleById($id: ID!) {
    vehicle(id: $id) {
      id
      licensePlate
      gallery {
        id
        name
        pictures {
          id
          urlMedium
        }
      }
      numberOfSeats
      registrationYear
      yearOfRestyling
      vehicleModel {
        id
        name
        vehicleType {
          id
          name
        }
      }
      vehicleManufacturer {
        id
        name
      }
      vehicleSafetyType {
        id
        name
      }
      vehicleCarbonOffset {
        id
        value
      }
    }
  }
`

export const QUERY_GET_ORDER_FEEDBACK = gql`
  query orderFeedback($orderId: ID!) {
    orderFeedback(orderId: $orderId) {
      status
      orderFeedback {
        id
        rating
        comment
        answeredAt
      }
    }
  }
`

export const MUTATION_SEND_FEEDBACK_REQUEST = gql`
  mutation sendFeedbackRequest($orderId: ID!, $recipients: [String!]!) {
    sendFeedbackRequest(orderId: $orderId, recipients: $recipients) {
      id
    }
  }
`

export const MUTATION_DUPLICATE_CIELO_QUOTE = gql`
  mutation duplicateCieloQuote($id: ID!) {
    duplicateCieloQuote(id: $id) {
      id
    }
  }
`
