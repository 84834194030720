import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import { Alert, Skeleton } from '@material-ui/lab'
import React from 'react'
import { Form } from 'react-final-form'
import { MultiSelectField } from '../../components/gmail/MultiSelectField'

import { useCustomerPeople } from '../../components/gmail/useCustomerPeople'

interface RecipientsFormProps {
  customerId: string
  loading: boolean
  onSubmit: (values: any) => void
}

export function RecipientsForm({ customerId, onSubmit, loading = false }: RecipientsFormProps) {
  const classes = useStyles()
  const { initialized, options } = useCustomerPeople(customerId)

  if (!initialized) {
    return (
      <div className={classes.root}>
        <Skeleton variant="rect" className={classes.inputSkeleton} />
        <Skeleton variant="rect" width={132} height={30} className={classes.selectTemplateButtonContainer} />
      </div>
    )
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ to: options.length > 0 ? [options[0]] : undefined }}
      validate={(values) => {
        const errors: any = {}
        if (!values.to || values.to.length === 0) {
          errors.to = 'At least one recipient (to) is required'
        }
        return errors
      }}
      render={({ handleSubmit, invalid, errors }): React.ReactElement => (
        <form className={classes.root} onSubmit={handleSubmit}>
          {options.length === 0 && <Alert severity="warning">Error! No e-mail available for the client</Alert>}
          <div className={classes.inputContainer}>
            <label htmlFor="to">To:</label>
            <MultiSelectField source="to" placeholder="To" options={options} />
          </div>

          {errors?.to && (
            <div className={classes.inputContainer}>
              <Alert severity="error">{errors.to}</Alert>
            </div>
          )}

          <Button type="submit" disabled={invalid || loading}>
            {loading && <CircularProgress size={20} />}
            Send
          </Button>
        </form>
      )}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '394px',
  },
  title: {
    marginBottom: '20px',
  },
  inputSkeleton: {
    width: '100%',
    height: '40px',
    marginBottom: '37px',
  },
  selectTemplateButtonContainer: {
    marginBottom: '20px',
  },
  attachmentsContainer: {
    marginBottom: '20px',
  },
  inputContainer: {
    marginBottom: '20px',
    width: '354px',
  },
}))
