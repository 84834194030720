import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'

type Props = {
  record?: Record<string, any>
  source?: string
  label?: string
}

const defaultOptions = {
  separator: ' ',
  minute: 'm',
  hour: 'h',
  day: 'd',
}

export const formatMinutesDuration = (duration: number, options: Record<string, string> = defaultOptions): string => {
  if (!duration) {
    return ''
  } else if (duration < 60) {
    return `${duration.toString()}${options.minute}`
  } else {
    let hours = Math.trunc(duration / 60)
    const minutes = duration % 60

    if (hours < 24) {
      return `${hours}${options.hour}${options.separator}${minutes}${options.minute}`
    } else {
      const days = Math.trunc(hours / 24)
      hours = hours % 24
      return `${days}${options.day}${options.separator}${hours}${options.hour}${options.separator}${minutes}${options.minute}`
    }
  }
}

const FlightDurationField: FC<Props> = ({ record = {}, source = 'flightDuration' }) => {
  if (!record[source]) {
    return null
  }

  if (typeof record[source] !== 'number') {
    return <ErrorIcon htmlColor="darkred" />
  }
  return <Typography variant="body2">{formatMinutesDuration(record[source])}</Typography>
}

export default FlightDurationField
