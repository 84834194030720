import React, { FC } from 'react'
import Show from '../../components/details/Show'
import SaveButton from '../../components/button/SaveButton'
import RoleDetails from './RoleDetails'

type Props = {}

const RoleShow: FC<Props> = (props) => {
  return (
    <Show {...props}>
      <RoleDetails />
    </Show>
  )
}

export default RoleShow
