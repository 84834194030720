import React, { FC, useMemo } from 'react'
import { useGetOne } from 'ra-core'
import { ReferenceField, TextField, FunctionField } from 'react-admin'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
// import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import { FaLeaf, FaShieldAlt, FaTrashAlt, FaUsers, FaWrench } from 'react-icons/fa'
import ErrorIcon from '@material-ui/icons/Error'
// import EditIcon from '@material-ui/icons/Edit'
import CurrencyField from '../../components/field/CurrencyField'
import clsx from 'clsx'
import { Box } from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { QUERY_GET_PICTURES } from '../../queries'
// import { Form } from 'react-final-form'
import AddOptionButtonDrawer from './AddOptionButtonDrawer'
import { RichTextField } from 'ra-ui-materialui'
// import EditOptionButtonDrawer from './EditOptionButtonDrawer'
import Tooltip from '@material-ui/core/Tooltip'
import VisibilityIcon from '@material-ui/icons/Visibility'
import RouteCarbonOffsetField from '../../components/field/RouteCarbonOffsetField'

type Props = {
  vehicleId: string
  sellingPrice: number
  purchasePrice: number
  transferCost: number
  showYomYor: boolean
  index: number
  id: number
  currencyId: number
  taxId?: number
  onRemove?: (id: string | number) => () => any
  onConfirm?: (id: number) => () => any
  // onEdit?: (id: string | number) => () => any
  confirmedOption: boolean
  isQuoteConfirmed: boolean
  description?: string
  fullQuote?: Record<string, any>
  savedRecord?: Record<string, any>
}

// const CarbonOffset: FC<any> = ({ carbonOffsetId, totalDuration }) => {
//   const { loading, data } = useGetOne('VehicleCarbonOffset', carbonOffsetId as string, { enabled: !!carbonOffsetId })

//   if (loading) return <LinearProgress />
//   if (data) return <span>{((data.value * totalDuration) / 60).toFixed(2)}</span>
//   return null
// }

const OptionField: FC<Props> = ({
  vehicleId,
  index,
  onRemove,
  onConfirm,
  confirmedOption,
  isQuoteConfirmed,
  id,
  currencyId,
  fullQuote,
  savedRecord,
  ...props
}) => {
  const classes = useStyles()
  const { loading, error, data } = useGetOne('Vehicle', vehicleId)
  const { data: picturesData, loading: picturesLoading } = useQuery(QUERY_GET_PICTURES, {
    variables: {
      filters: {
        galleryId: data?.galleryId,
      },
    },
    skip: !data || !data.galleryId,
  })
  const imageUrl = useMemo(
    () =>
      picturesData?.pictures?.data && picturesData.pictures.data.length > 0
        ? picturesData.pictures.data[0].urlMedium
        : undefined,
    [picturesData]
  )

  const totalDuration = useMemo((): number | null => {
    if (fullQuote && fullQuote.routes && Array.isArray(fullQuote.routes)) {
      const value = fullQuote.routes.reduce(
        (accumulator: number, current: any) =>
          !isNaN(current.flightDuration) ? accumulator + current.flightDuration : accumulator,
        0
      )
      return value
    } else return null
  }, [fullQuote])

  return (
    <Paper className={classes.container}>
      <div className={classes.infoWrapper}>
        <Box display="flex">
          {imageUrl ? <img src={imageUrl} className={classes.image} /> : <div />}
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="body2">{`Option #${++index}`}</Typography>
              {loading ? (
                <LinearProgress />
              ) : error || !data ? (
                <ErrorIcon color="error" />
              ) : (
                <>
                  <div className={classes.textInterspaceSm}>
                    <span>
                      <ReferenceField
                        basePath="/Quote"
                        record={data}
                        source="vehicleManufacturerId"
                        reference="VehicleManufacturer"
                        link={false}
                      >
                        <TextField source="name" variant="h3" />
                      </ReferenceField>
                      <ReferenceField
                        basePath="/Quote"
                        record={data}
                        source="vehicleModelId"
                        reference="VehicleModel"
                        link={false}
                      >
                        <TextField source="name" variant="h3" className={classes.inlineField} />
                      </ReferenceField>
                    </span>
                  </div>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <span className={clsx(classes.fieldWithIcon, classes.textInterspaceSm)}>
                      <FaShieldAlt className={classes.icon} />
                      <ReferenceField
                        basePath="/Quote"
                        record={data}
                        source="vehicleSafetyTypeId"
                        reference="VehicleSafetyType"
                        link={false}
                      >
                        <TextField source="name" variant="h5" />
                      </ReferenceField>
                    </span>
                    <Box mr={3} />
                    <Typography variant="h5" className={clsx(classes.fieldWithIcon, classes.textInterspaceSm)}>
                      <FaUsers className={classes.icon} />
                      {`${data.numberOfSeats} seats`}
                    </Typography>
                    <Box mr={3} />
                    {totalDuration && totalDuration > 0 && data.vehicleCarbonOffsetId && (
                      <>
                        <Typography variant="h5" className={clsx(classes.fieldWithIcon, classes.textInterspaceSm)}>
                          <FaLeaf className={classes.icon} />
                          <RouteCarbonOffsetField
                            totalDuration={totalDuration}
                            carbonOffsetId={data.vehicleCarbonOffsetId}
                          />
                        </Typography>
                        <Box mr={3} />
                      </>
                    )}
                    {props.showYomYor && (
                      <Typography variant="h5" className={clsx(classes.fieldWithIcon, classes.textInterspaceSm)}>
                        <FaWrench className={classes.icon} />
                        {`YOM: ${data.yearOfManufacture} / YOR: ${data.yearOfRestyling}`}
                      </Typography>
                    )}
                  </Box>
                </>
              )}
            </Box>
            <Box display="flex" justifyContent="flex-end" textAlign="right" ml={2}>
              <Typography variant="h6">{'Description'}</Typography>
              <Box mr={2} />
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={
                  props.description ? (
                    <RichTextField record={props as any} source="description" />
                  ) : (
                    <Typography variant="body2">No description found</Typography>
                  )
                }
              >
                <VisibilityIcon color={props.description && props.description !== '' ? 'primary' : 'secondary'} />
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <div className={classes.pricesWrapper}>
          <span className={classes.singlePrice}>
            <Typography variant="h6">{'Purchase Price: '}</Typography>
            <CurrencyField
              variant="h6"
              record={props}
              source="purchasePrice"
              currencyId={currencyId}
              textAlign="left"
            />
          </span>
          <span className={classes.singlePrice}>
            <Typography variant="h6">{'Commissions: '}</Typography>
            <CurrencyField
              variant="h6"
              record={props}
              source="commissions"
              currencyId={currencyId}
              inline
              textAlign="left"
            />
          </span>
          <span className={classes.singlePrice}>
            <Typography variant="h6">{'VAT: '}</Typography>
            {props.taxId ? (
              <ReferenceField
                source="taxId"
                record={props}
                reference="Tax"
                basePath="/Quote"
                link={false}
                emptyText="0%"
              >
                <FunctionField render={(record: any): string => `${record.rate || 0}%`} />
              </ReferenceField>
            ) : (
              <Typography variant="h6">{'0%'}</Typography>
            )}
          </span>
          <span className={classes.singlePrice}>
            <Typography variant="h6">{'Passenger Tax: '}</Typography>
            <CurrencyField
              variant="h6"
              record={props}
              source="passengerTax"
              currencyId={currencyId}
              inline
              textAlign="left"
            />
          </span>
          <span className={classes.singlePrice}>
            <Typography variant="h6" className={classes.bold}>
              {'Selling Price (no taxes): '}
            </Typography>
            <CurrencyField
              variant="h6"
              record={props}
              source="sellingPriceWithoutTaxes"
              currencyId={currencyId}
              inline
              textAlign="left"
            />
          </span>
          <span className={classes.singlePrice}>
            <Typography variant="h6" className={classes.bold}>
              {'Selling Price: '}
            </Typography>
            <CurrencyField
              variant="h6"
              record={props}
              source="sellingPrice"
              className={classes.bold}
              currencyId={currencyId}
              inline
              textAlign="left"
            />
          </span>
          {onRemove && (
            <Box display="flex" className={classes.removeBox}>
              <Button
                // fullWidth
                className={classes.removeButton}
                variant="contained"
                startIcon={<FaTrashAlt />}
                onClick={onRemove(id)}
              >
                Delete
              </Button>
              {savedRecord && <AddOptionButtonDrawer savedRecord={savedRecord} index={index} />}
            </Box>
          )}
          {onConfirm && (
            <Button
              // fullWidth
              className={confirmedOption ? classes.confirmedButton : classes.button}
              variant={confirmedOption ? 'contained' : 'outlined'}
              onClick={onConfirm(id)}
              disabled={isQuoteConfirmed && !confirmedOption}
            >
              {confirmedOption ? 'Confirmed' : 'Confirm'}
            </Button>
          )}
        </div>
      </div>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'auto',
    display: 'flex',
    // padding: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      display: 'grid',
    },
  },
  image: {
    width: 100,
    height: 100,
    objectFit: 'cover',
    flexGrow: 0,
    flexBasis: 150,
    marginRight: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('md')]: {
      width: 60,
      height: 100,
    },
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    // marginLeft: theme.spacing(4),
    flexGrow: 3,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      marginLeft: theme.spacing(0),
      gridTemplateColumns: 'repeat(1, minmax(0px, 1fr));',
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
  },
  inlineField: {
    display: 'inline',
    marginLeft: theme.spacing(2),
  },
  fieldWithIcon: {
    display: 'flex',
    alignItems: 'left',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  pricesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    textAlign: 'left',
    flexGrow: 1,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      marginLeft: theme.spacing(0),
      gridTemplateColumns: 'repeat(1, minmax(0px, 1fr));',
      '& $singlePrice:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  removeButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    // maxWidth: '40%',
    [theme.breakpoints.down('sm')]: {
      gridColumnStart: 1,
      gridColumnEnd: 'span col4-start',
      // gridRowStart: 2,
      gridRowEnd: 'span 2',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
  },
  confirmedButton: {
    backgroundColor: theme.palette.success.dark,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumnStart: 1,
      gridColumnEnd: 'span col4-start',
      // gridRowStart: 2,
      gridRowEnd: 'span 2',
    },
  },
  button: {
    // marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      maxWidth: '15%',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumnStart: 1,
      gridColumnEnd: 'span col4-start',
      // gridRowStart: 2,
      gridRowEnd: 'span 2',
    },
  },
  iconButton: {
    fontSize: '5px',
  },
  singlePrice: {
    display: 'flex',
    flexDirection: 'column',
    // minWidth: '20%',
    //marginLeft: theme.spacing(3),
    textAlign: 'left',
    '& h5:last-child': {
      //marginLeft: theme.spacing(1),
    },
    '& h6:last-child': {
      // marginLeft: theme.spacing(1),
    },
  },
  textInterspaceSm: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  removeBox: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    maxWidth: 600,
    borderRadius: 4,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[4],
  },
}))

export default OptionField
