import React, { FC, useCallback, useState } from 'react'
import { AvatarGroup, AvatarGroupProps } from '@chakra-ui/react'
import { AvatarFilterProps } from './AvatarFilter'

const defaultExpandedAvatarsSpacing = 1
const defaultGroupedAvatarsSpacing = -4

export type AvatarGroupFilterProps = {
  selectedIds?: (number | string)[]
  onAvatarClick?: (id: number | string) => void
  expandedAvatarsSpacing?: number
  groupedAvatarsSpacing?: number
} & AvatarGroupProps

export const AvatarGroupFilter: FC<AvatarGroupFilterProps> = ({
  selectedIds = [],
  children,
  expandedAvatarsSpacing = defaultExpandedAvatarsSpacing,
  groupedAvatarsSpacing = defaultGroupedAvatarsSpacing,
  onAvatarClick,
  ...props
}) => {
  const [spacing, setSpacing] = useState<number | undefined>(
    selectedIds.length > 0 ? expandedAvatarsSpacing : groupedAvatarsSpacing
  )

  const handleMouseEnter = useCallback(() => {
    if (selectedIds.length === 0) {
      setSpacing(expandedAvatarsSpacing)
    }
  }, [selectedIds, expandedAvatarsSpacing])

  const handleMouseLeave = useCallback(() => {
    if (selectedIds.length === 0) {
      setSpacing(groupedAvatarsSpacing)
    }
  }, [selectedIds, groupedAvatarsSpacing])

  const handleAvatarClick = useCallback(
    (id: number | string) => {
      if (onAvatarClick) {
        onAvatarClick(id)
      }
    },
    [onAvatarClick]
  )

  return (
    <AvatarGroup
      size="md"
      max={20}
      {...props}
      onMouseEnter={handleMouseEnter}
      onFocus={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onBlur={handleMouseLeave}
      spacing={spacing}
      role="group"
    >
      {React.Children.map(children, (child) => {
        if (child && React.isValidElement<AvatarFilterProps>(child)) {
          const elementChildren: React.ReactElement<AvatarFilterProps> = child
          return React.cloneElement<AvatarFilterProps>(elementChildren, {
            key: `avatar-filter-${elementChildren.props.id}`,
            onClick: () => handleAvatarClick(elementChildren.props.id),
            isSelected: selectedIds.indexOf(elementChildren.props.id) !== -1,
          })
        }
      })}
    </AvatarGroup>
  )
}
