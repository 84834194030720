import React, { FC } from 'react'
import { Skeleton } from '@material-ui/lab'

export const MailItemSkeleton: FC = () => {
  return <Skeleton variant="rect" width="100%" height="50px" />
}

export const MailListSkeleton: FC = () => {
  return (
    <div>
      <MailItemSkeleton />
      {/* <MailItemSkeleton />
      <MailItemSkeleton /> */}
    </div>
  )
}
