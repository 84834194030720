import React, { FC } from 'react'
import { TextInput, NumberInput } from 'react-admin'
import FormSection from '../../components/forms/FormSection'
import InputColor from './../../components/InputColor'

// type Props = {}

const EventTemplateForm: FC<any> = (props) => {
  // const record = {
  //   backgroundColor:
  //     props && props.record && props.record.additionalData && props.record.additionalData.backgroundColor
  //       ? props.record.additionalData.backgroundColor
  //       : '',
  // }
  // console.log('RECORD', record)

  return (
    <FormSection title="Event Template" {...props}>
      <TextInput source="title" />
      <NumberInput source="duration" />
      <TextInput source="description" />
      {/* <InputColor record={record} source="backgroundColor" label="Colore nel calendario" /> */}
      <InputColor source="color" label="Colore nel calendario" />
    </FormSection>
  )
}

export default EventTemplateForm
