import { IconButtonTypeMap } from '@material-ui/core/IconButton'
import { OverrideProps } from '@material-ui/core/OverridableComponent'
import { makeStyles } from '@material-ui/core/styles'
import { Calendar, useStaticState } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CustomDay } from './CustomDayInPreview/CustomDay'
import { isSameDay, isToday, isSameWeek, format } from 'date-fns'
import { CalendarViewModes } from './CalendarViewModeMenu'

type Props = {
  value: Date
  onChange: (date: MaterialUiPickersDate) => void
  viewMode: CalendarViewModes
  primaryEvents?: any[]
  secondaryEvents?: any[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  smallIcon: {
    width: '0.965rem',
    height: '0.965rem',
  },
}))

const MonthlyPreviewPicker: FC<Props> = ({ value, onChange, viewMode, primaryEvents, secondaryEvents }) => {
  const classes = useStyles()
  useSelector((state: any) => state.admin.ui.sidebarOpen) // need re-render on sidebar open/close

  const { pickerProps, wrapperProps } = useStaticState({
    value,
    onChange,
  })

  const leftArrowButtonProps: Partial<OverrideProps<IconButtonTypeMap, 'button'>> = useMemo(
    () => ({
      size: 'small',
      className: classes.smallIcon,
    }),
    []
  )

  const rightArrowButtonProps: Partial<OverrideProps<IconButtonTypeMap, 'button'>> = useMemo(
    () => ({
      size: 'small',
      className: classes.smallIcon,
    }),
    []
  )

  const primaryEventsStringDates = useMemo((): any[] => {
    let tmp: any[] = []
    if (primaryEvents && Array.isArray(primaryEvents)) {
      tmp = (primaryEvents as any).map((flightEvent: any) => {
        if (flightEvent.start) {
          return format(new Date(flightEvent.start), 'P')
        }
      })
      const unique = tmp.filter(function (item, pos) {
        return tmp.indexOf(item) === pos
      })
      return unique
    }
    return tmp
  }, [primaryEvents])

  const secondaryEventsStringDates = useMemo((): any[] => {
    let tmp: any[] = []
    if (secondaryEvents && Array.isArray(secondaryEvents)) {
      tmp = (secondaryEvents as any).map((genericEvent: any) => {
        if (genericEvent.start) {
          return format(new Date(genericEvent.start), 'P')
        }
      })
      const unique = tmp.filter(function (item, pos) {
        return tmp.indexOf(item) === pos
      })
      return unique
    }
    return tmp
  }, [secondaryEvents])

  return (
    <div className={classes.root}>
      <Calendar
        {...pickerProps}
        leftArrowButtonProps={leftArrowButtonProps}
        rightArrowButtonProps={rightArrowButtonProps}
        renderDay={(day: any, selectedDate: any, dayInCurrentMonth: boolean, dayComponent: any): any => {
          return (
            <CustomDay
              day={day}
              inCurrentMonth={dayInCurrentMonth}
              selected={isSameDay(day, selectedDate)}
              today={isToday(day)}
              onDaySelect={() => {}}
              sameSelectedWeek={
                viewMode === CalendarViewModes.WEEK ? isSameWeek(day, selectedDate, { weekStartsOn: 1 }) : false
              }
              hasPrimaryEvents={primaryEventsStringDates.includes(format(day, 'P'))}
              hasSecondaryEvents={secondaryEventsStringDates.includes(format(day, 'P'))}
              fullBorderRadius={viewMode === CalendarViewModes.DAY}
            />
          )
        }}
      />
    </div>
  )
}

export default MonthlyPreviewPicker
