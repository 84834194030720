import * as React from 'react'
import { Children, cloneElement, FC } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Drawer, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import lodashGet from 'lodash/get'
import { setSidebarVisibility, ReduxState, useLocale } from 'ra-core'

export const DRAWER_WIDTH = 270
export const CLOSED_DRAWER_WIDTH = 55

const useStyles = makeStyles(
  (theme) => ({
    mobileDrawer: {
      minWidth: 280,
    },
    desktopDrawer: {
      height: 'calc(100% - 55px)',
      overflowX: 'hidden',
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(6),
      borderRightWidth: '0 !important',
      marginRight: 15,
    },
    avatar: {
      cursor: 'pointer',
      width: 64,
      height: 64,
    },
    root: {
      minHeight: '100vh',
      top: '0px',
      position: 'sticky',
      paddingTop: '54px',
    },
    docked: {
      maxHeight: '100vh',
    },
    paper: {},
    desktopDrawerRoot: {
      paddingTop: theme.spacing(11),
    },
    paperAnchorLeft: {},
    paperAnchorRight: {},
    paperAnchorTop: {},
    paperAnchorBottom: {},
    paperAnchorDockedLeft: {},
    paperAnchorDockedTop: {},
    paperAnchorDockedRight: {},
    paperAnchorDockedBottom: {},
    modal: {},
    drawerPaper: {
      position: 'relative',
      // height: '100%',
      overflowX: 'hidden',
      width: (props: { open?: boolean }): string | number =>
        props.open
          ? lodashGet(theme, 'sidebar.width', DRAWER_WIDTH)
          : lodashGet(theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: 'inherit',
    },
    '@media print': {
      mobileDrawer: {
        display: 'none',
      },
      desktopDrawer: {
        display: 'none',
      },
      drawerPaper: {
        width: 0,
        height: 0,
        display: 'none',
      },
      desktopDrawerRoot: {
        display: 'none',
      },
    },
  }),
  { name: 'RaSidebar' }
)

const Sidebar: FC<any> = (props) => {
  const { children, closedSize, size, classes: classesOverride, ...rest } = props
  const dispatch = useDispatch()
  const open: any = useSelector<ReduxState>((state) => state.admin.ui.sidebarOpen)

  useLocale() // force redraw on locale change
  const handleClose = (): any => dispatch(setSidebarVisibility(false))
  const toggleSidebar = (): any => dispatch(setSidebarVisibility(!open))
  const { drawerPaper, ...classes } = useStyles({ ...props, open })

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ ...classes, paper: classes.mobileDrawer }}
          onClose={toggleSidebar}
          open={open}
          variant="temporary"
        >
          {cloneElement(Children.only(children), {
            logout: props.logout,
            onMenuClick: handleClose,
          })}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          PaperProps={{
            className: drawerPaper,
          }}
          classes={{ ...classes, paper: classes.desktopDrawer }}
          open
          variant="permanent"
        >
          {children}
        </Drawer>
      </Hidden>
    </>
  )
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Sidebar
