export const hasPermission = (permissions: any): boolean => {
  if (!permissions || !permissions.user) {
    return false
  }

  return true
}

export const canSeeDashboard = (permissions: any): boolean => {
  if (!permissions || !permissions.user) {
    return false
  } else if (permissions.params?.canSeeDashboard) {
    return true
  } else return false
}

export const canSeeClientStats = (permissions: any): boolean => {
  if (!permissions || !permissions.user) {
    return false
  } else if (permissions.params?.canSeeClientStats) {
    return true
  } else return false
}
