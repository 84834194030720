import React, { FC } from 'react'
import { TextField, NumberField, Filter, DateField } from 'react-admin'
// internal components
import RAList from '../../components/list/List'
import Datagrid from './../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'
import GenericMoreMenu from '../../components/GenericMoreMenu'
import FilterTextInput from '../../components/FilterTextInput'
import BooleanStatusField from '../../components/BooleanStatusField'
// icons
import { FaUserTie } from 'react-icons/fa'

type Props = {}

const TeamMemberFilters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput source="q" alwaysOn />
    </Filter>
  )
}

const TeamMemberList: FC<Props> = (props) => {
  return (
    <RAList
      {...props}
      titleIcon={<FaUserTie />}
      actions={<CreateButton label="resources.TeamMember.actions.create" />}
      filters={<TeamMemberFilters variant="outlined" />}
    >
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <TextField source="fullName" />
        <DateField source="createdAt" />
        <TextField source="email" />
        <BooleanStatusField source="approved" />
        <GenericMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default TeamMemberList
