import React, { FC, useEffect, useMemo } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { ORDER_INFO_TEMPLATE_CATEGORIES } from '../OrderAdditionalInfoTemplate/OrderAdditionalInfoTemplateCreate'
import { useQuery } from '@apollo/client'
import { QUERY_GET_ORDER_ADDITIONAL_INFO } from '../../queries'
import { Tooltip } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { RichTextField } from 'ra-ui-materialui'
import { useGetOne, useVersion } from 'ra-core'

type Props = {
  record: Record<string, any>
  name?: string
  id?: any
}

const OrderAdditionalInfoField: FC<Props> = ({ record }) => {
  const classes = useStyles()
  const version = useVersion()
  const { loading, error, data, refetch } = useQuery(QUERY_GET_ORDER_ADDITIONAL_INFO, {
    variables: {
      orderId: record.id,
    },
  })
  const { loading: loadingBank, data: dataBank } = useGetOne('BankAccount', record?.bankAccountId)

  const groupedResult = useMemo(() => {
    return (data?.orderAdditionalInfos?.data || []).reduce((acc: any, item: any) => {
      const newResult = { ...acc }

      if (!newResult[item.value.orderAdditionalInfoTemplate.category]) {
        newResult[item.value.orderAdditionalInfoTemplate.category] = []
      }

      newResult[item.value.orderAdditionalInfoTemplate.category].push(item)

      return newResult
    }, {})
  }, [data])

  const cancellationFee = useMemo(() => {
    if (
      groupedResult[ORDER_INFO_TEMPLATE_CATEGORIES.cancellationFee] &&
      groupedResult[ORDER_INFO_TEMPLATE_CATEGORIES.cancellationFee].length > 0
    ) {
      return groupedResult[ORDER_INFO_TEMPLATE_CATEGORIES.cancellationFee][0]
    }
    return undefined
  }, [groupedResult])

  const rentalConditions = useMemo(() => {
    if (
      groupedResult[ORDER_INFO_TEMPLATE_CATEGORIES.rentalConditions] &&
      groupedResult[ORDER_INFO_TEMPLATE_CATEGORIES.rentalConditions].length > 0
    ) {
      return groupedResult[ORDER_INFO_TEMPLATE_CATEGORIES.rentalConditions][0]
    }
    return undefined
  }, [groupedResult])

  useEffect(() => {
    refetch()
  }, [version])

  return (
    <>
      <Typography variant="h3">Additional Info</Typography>
      <Paper className={classes.card}>
        <Box display="flex" flexDirection="column">
          <Typography variant="caption">Penali di cancellazione</Typography>
          <Box display="flex" alignItems="center">
            {cancellationFee && cancellationFee.value && cancellationFee.value.orderAdditionalInfoTemplate && (
              <Typography variant="h5">{cancellationFee.value.orderAdditionalInfoTemplate.name}</Typography>
            )}
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={
                cancellationFee ? (
                  <RichTextField record={cancellationFee.value.value} source="_html" />
                ) : (
                  <Typography variant="body2">No template found</Typography>
                )
              }
            >
              <VisibilityIcon
                color={cancellationFee && cancellationFee.value ? 'primary' : 'secondary'}
                className={classes.visibilityIcon}
              />
            </Tooltip>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="caption">Condizioni di noleggio</Typography>
          <Box display="flex" alignItems="center">
            {rentalConditions && rentalConditions.value && rentalConditions.value.orderAdditionalInfoTemplate && (
              <Typography variant="h5">{rentalConditions.value.orderAdditionalInfoTemplate.name}</Typography>
            )}
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={
                rentalConditions ? (
                  <RichTextField record={rentalConditions.value.value} source="_html" />
                ) : (
                  <Typography variant="body2">No template found</Typography>
                )
              }
            >
              <VisibilityIcon
                color={rentalConditions && rentalConditions.value ? 'primary' : 'secondary'}
                className={classes.visibilityIcon}
              />
            </Tooltip>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="caption">Coordinate Bancarie</Typography>
          <Box display="flex" alignItems="center">
            {dataBank && dataBank.name && <Typography variant="h5">{dataBank.name}</Typography>}
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={
                !loadingBank && dataBank ? (
                  <span>
                    <Typography variant="body1">{dataBank.name}</Typography>
                    <Typography variant="body2">{`Bank name: ${dataBank.bankName}`}</Typography>
                    <Typography variant="body2">{`Bank address: ${dataBank.address}`}</Typography>
                    {dataBank.details &&
                      Array.isArray(dataBank.details) &&
                      dataBank.details.map((detail: any, index: number) => (
                        <Typography variant="body2" key={`bank-detail-${index}`}>
                          {`${detail.key}: ${detail.value}`}
                        </Typography>
                      ))}
                  </span>
                ) : (
                  <Typography variant="body2">No template found</Typography>
                )
              }
            >
              <VisibilityIcon color={dataBank ? 'primary' : 'secondary'} className={classes.visibilityIcon} />
            </Tooltip>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    justifyContent: 'space-between',
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    maxWidth: 600,
    borderRadius: 4,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[4],
  },
  visibilityIcon: {
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}))

export default OrderAdditionalInfoField
