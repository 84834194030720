import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateMomentUtils from '@date-io/moment'
import { useCalendarPlanningStyles } from './CalendarPlanning.styles'
import { Box, IconButton, Typography } from '@material-ui/core'
import MonthlyPreviewPicker from './MonthlyPreviewPicker'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import FullCalendar, {
  CustomContentGenerator,
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventContentArg,
  ViewApi,
} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { Draggable, EventResizeDoneArg, EventDragStopArg } from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import { gql, useApolloClient, useQuery } from '@apollo/client'
import PlanningCustomersList from './PlanningCustomersList'
import itLocale from '@fullcalendar/core/locales/it'
import CalendarToolbar from './CalendarToolbar'
import { useDispatch } from 'react-redux'
import { fetchStart, fetchEnd, useNotify } from 'react-admin'
import { useVersion } from 'ra-core'
import dateFnsEnLocale from 'date-fns/locale/it'
import { startOfISOWeek, startOfMonth } from 'date-fns'
import { FlightEvent } from './FlightEvent'
import { Event } from './Event'
import moment from 'moment'
import clsx from 'clsx'
import { MUTATION_UPDATE_PLANNED_EVENT, QUERY_GET_TEAMMEMBER_ME } from '../../queries'
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import { ORDER_STATUS_OPTIONS } from '../OrderStatusDropDown'
import { ROUTE_STATUS_OPTIONS } from '../field/OrderRouteStatusField'

const today = moment().startOf('day').toDate()

const MUTATION_CREATE_PLANNED_EVENT = gql`
  mutation CreatePlannedEvent($data: EventCreateInput!) {
    createEvent(data: $data) {
      id
      startDate
      endDate
      title
      description
      customerId
      personId
      teamMemberIds
      quoteId
      orderId
    }
  }
`

const QUERY_GET_EVENTS = gql`
  query GetEvents($filters: EventFilterInput) {
    events(pagination: { disabled: true }, filters: $filters) {
      data {
        id
        startDate
        endDate
        title
        description
        customerId
        personId
        teamMemberIds
        quoteId
        orderId
        allDay
      }
      total
      offset
    }
  }
`

const QUERY_GET_ORDER_ROUTES = gql`
  query GetCalendarOrderRoutes($filters: OrderRouteFilterInput) {
    orderRoutes(pagination: { limit: 1000, offset: 0 }, filters: $filters) {
      data {
        id
        registrationNumber
        flightNumber
        arrivalDate
        flightDate
        status
        vehicleId
        departureAirportId
        arrivalAirportId
        boardingTime
        notes
        flightDuration
        orderId
        captainName
        captainNumber
        firstOfficerName
        firstOfficerNumber
        flightAttendantName
        flightAttendantNumber
        departureHandlingName
        departureHandlingNumber
        arrivalHandlingName
        arrivalHandlingNumber
      }
      total
      offset
    }
  }
`

type Props = {
  basePath?: string
}

export const CalendarPlanning: FC<Props> = (props) => {
  const { search } = useLocation()
  const history = useHistory()
  const calendarContainerRef = useRef<any>()
  const draggableCustomersRef = useRef<Draggable>()
  const [currentDates, setCurrentDates] = useState<DatesSetArg | undefined>(undefined)
  const [calTitle, setCalTitle] = useState<string>('')
  const fullCalendarRef = useRef<FullCalendar>()
  const client = useApolloClient()
  const dispatch = useDispatch()
  const notify = useNotify()
  const viewVersion = useVersion()
  const [selectedTeamMemberIds, setSelectedTeamMemberIds] = useState<string[]>([])
  const { loading: loadingTeamMemberMe, data: teamMemberMeData } = useQuery(QUERY_GET_TEAMMEMBER_ME)

  const parsedQueryString = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const selectedDateString = useMemo<string>(() => {
    if (parsedQueryString.date) {
      return typeof parsedQueryString.date === 'string' ? parsedQueryString.date : parsedQueryString.date[0]
    }
    return new Date().toISOString()
  }, [parsedQueryString])

  const selectedDate = useMemo<Date>(() => {
    return new Date(selectedDateString)
  }, [selectedDateString])

  const initialCalendarView = useMemo<string>(() => {
    if (parsedQueryString.initialView) {
      return typeof parsedQueryString.initialView === 'string'
        ? parsedQueryString.initialView
        : parsedQueryString.initialView[0]
    }
    return 'timeGridWeek'
  }, [parsedQueryString])

  const [fastEventsOpen, setFastEventsOpen] = useState<boolean>(
    localStorage.getItem('fastEventsOpen') === 'false' ? false : true
  )

  const classes = useCalendarPlanningStyles({ ...props, fastEventsOpen })

  const onCalGoToDate = useCallback(
    (date: any) => {
      history.push({
        pathname: props.basePath,
        search: queryString.stringify({
          ...parsedQueryString,
          date: date.toISOString(),
        }),
      })
    },
    [history, parsedQueryString]
  )

  const onCalendarViewChange = useCallback(
    (arg: DatesSetArg): void => {
      setCurrentDates(arg)
      setCalTitle(arg.view.title)
      stopPolling()
      stopPollingFlights()
      // startPolling(2000)
      // startPollingFlights(5000)
    },
    [setCalTitle, setCurrentDates]
  )

  const {
    loading: loadingEvents,
    data: dataEvents,
    error: errorEvents,
    refetch: refetchEvents,
    startPolling,
    stopPolling,
  } = useQuery(QUERY_GET_EVENTS, {
    fetchPolicy: 'cache-and-network',
    skip: !currentDates || !currentDates.startStr || !currentDates.endStr,
    variables: {
      filters: {
        dateRangeStart: currentDates ? currentDates.startStr : undefined,
        dateRangeEnd: currentDates ? currentDates.endStr : undefined,
        teamMemberIds: selectedTeamMemberIds.length > 0 ? selectedTeamMemberIds : undefined,
      },
    },
  })

  const {
    loading: loadingFlights,
    data: dataFlights,
    error: errorFlights,
    refetch: refetchFlights,
    startPolling: startPollingFlights,
    stopPolling: stopPollingFlights,
  } = useQuery(QUERY_GET_ORDER_ROUTES, {
    fetchPolicy: 'cache-and-network',
    skip: !currentDates || !currentDates.startStr || !currentDates.endStr,
    variables: {
      filters: {
        startDate: currentDates ? currentDates.startStr : undefined,
        endDate: currentDates ? currentDates.endStr : undefined,
      },
      pagination: {
        offset: 0,
        limit: 1000,
      },
    },
  })

  const onCustomersFERef = useCallback(
    (ref) => {
      if (draggableCustomersRef.current) {
        return
      }

      draggableCustomersRef.current = new Draggable(ref, {
        itemSelector: '.draggable-event',
        eventData: (eventEl: HTMLElement): any => {
          const customerDetails =
            eventEl.attributes.getNamedItem('data-customer') && eventEl.attributes.getNamedItem('data-customer')?.value
              ? JSON.parse(eventEl.attributes.getNamedItem('data-customer')!.value)
              : {}

          return {
            title: customerDetails.fullName ? customerDetails.fullName : 'N.D.',
            duration: {
              hours: 1,
            },
          }
        },
      })
    },
    [draggableCustomersRef]
  )

  const onSwitchCalView = useCallback(
    (view: string) => (): void => {
      history.push({
        pathname: props.basePath,
        search: queryString.stringify({ ...parsedQueryString, initialView: view }),
      })
    },
    [fullCalendarRef.current, parsedQueryString]
  )

  const onCalPrev = useCallback(() => {
    fullCalendarRef.current!.getApi().prev()
    history.push({
      pathname: props.basePath,
      search: queryString.stringify({
        ...parsedQueryString,
        date: fullCalendarRef.current?.getApi()?.view.currentStart.toISOString(),
      }),
    })
  }, [fullCalendarRef.current, history, parsedQueryString])

  const onCalNext = useCallback(() => {
    fullCalendarRef.current!.getApi().next()
    history.push({
      pathname: props.basePath,
      search: queryString.stringify({
        ...parsedQueryString,
        date: fullCalendarRef.current!.getApi().view.currentStart.toISOString(),
      }),
    })
  }, [fullCalendarRef.current, history, parsedQueryString])

  const onCalToday = useCallback(() => {
    fullCalendarRef.current!.getApi().today()
    history.push({
      pathname: props.basePath,
      search: queryString.stringify({
        ...parsedQueryString,
        date: fullCalendarRef.current!.getApi().view.currentStart.toISOString(),
      }),
    })
  }, [fullCalendarRef.current, history, parsedQueryString])

  const createPlannedEvent = useCallback(
    async (event: {
      startDate: string
      endDate: string
      title?: string
      description?: string
      customerId?: string
      teamMemberIds?: string[]
      additionalData?: {
        backgroundColor?: string
      }
    }) => {
      try {
        dispatch(fetchStart())
        const result = await client.mutate({
          mutation: MUTATION_CREATE_PLANNED_EVENT,
          variables: { data: event },
          update: (cache, { data: { createPlannedEvent } }) => {
            cache.modify({
              fields: {
                plannedEvents: (existingPlannedEventsRefs = { data: [], total: 0, offset: 0 }, { readField }): any => {
                  console.log(existingPlannedEventsRefs, '<-- existingPlannedEventsRefs')
                  const newPlannedEventRef = cache.writeFragment({
                    data: createPlannedEvent,
                    fragment: gql`
                      fragment NewPlannedEvent on Event {
                        id
                        startDate
                        endDate
                        title
                        description
                      }
                    `,
                  })
                  return {
                    total: existingPlannedEventsRefs.data + 1,
                    offset: existingPlannedEventsRefs.offset,
                    data: [...existingPlannedEventsRefs.data, newPlannedEventRef],
                  }
                },
              },
            })
          },
        })
        // fullCalendarRef.current!.getApi().refetchEvents()
      } catch (e) {
        console.error(e)
        notify('Error creating Event', 'warning')
      } finally {
        dispatch(fetchEnd())
      }
    },
    [client, fullCalendarRef]
  )

  const handleToggleFastEvents = useCallback(() => {
    setFastEventsOpen(!fastEventsOpen)
    localStorage.setItem('fastEventsOpen', JSON.stringify(!fastEventsOpen))
  }, [fastEventsOpen])

  const handleFastEventDrop = useCallback(
    (arg: any) => {
      console.log('onFastEventDrop()', arg, arg.view.getCurrentData())

      const customerDetails =
        arg.draggedEl.attributes.getNamedItem('data-customer') &&
        arg.draggedEl.attributes.getNamedItem('data-customer')?.value
          ? JSON.parse(arg.draggedEl.attributes.getNamedItem('data-customer')!.value)
          : {}

      if (customerDetails.id) {
        createPlannedEvent({
          title: customerDetails.companyName || customerDetails.fullName ? customerDetails.fullName : 'N.D.',
          description: '',
          startDate: arg.event.startStr,
          endDate: arg.event.endStr,
          customerId: customerDetails.id ? customerDetails.id : undefined,
          teamMemberIds: teamMemberMeData?.teamMemberMe ? [teamMemberMeData?.teamMemberMe.id] : [],
        })
          .then((data) => arg.revert())
          .then(() => refetchEvents())
      }
    },
    [createPlannedEvent, teamMemberMeData]
  )

  const updatePlannedEvent = useCallback(
    async (
      id: string,
      event: {
        startDate: string
        endDate?: string
        title?: string
        description?: string
        allDay?: boolean
      }
    ) => {
      try {
        dispatch(fetchStart())
        const result = await client.mutate({
          mutation: MUTATION_UPDATE_PLANNED_EVENT,
          variables: { data: event, id: id },
        })
        fullCalendarRef.current!.getApi().refetchEvents()
      } catch (e) {
        console.error(e)
        notify('Error updating Planned Event', 'warning')
      } finally {
        dispatch(fetchEnd())
      }
    },
    [client, fullCalendarRef]
  )

  const handleEventDrop = useCallback(
    (arg: EventDragStopArg) => {
      const startDateM = moment(arg.event.startStr)
      const endDateM = moment(arg.event.startStr)

      updatePlannedEvent(arg.event.id, {
        startDate: !arg.event.allDay ? arg.event.startStr : startDateM.startOf('day').toISOString(),
        endDate: arg.event.endStr || endDateM.add(1, 'hour').toISOString(),
        allDay: arg.event.allDay,
      })
    },
    [updatePlannedEvent]
  )

  const handleEventResize = useCallback((arg: EventResizeDoneArg) => {
    updatePlannedEvent(arg.event.id, {
      startDate: arg.event.startStr,
      endDate: arg.event.endStr,
    })
  }, [])

  const handleEventClick = useCallback(
    (e: EventClickArg) => {
      if (e.event.extendedProps.isFlightEvent) {
        history.push({
          pathname: `/Event/OrderRoute/${e.event.extendedProps.orderId}/${e.event.extendedProps.id}`,
          search: search,
        })
      } else if (e.event.id) {
        history.push({
          pathname: `/Event/${e.event.id}`,
          search: search,
        })
      }
    },
    [props.basePath, search]
  )

  const handleDateSelected = useCallback(
    (arg: DateSelectArg) => {
      history.push({
        pathname: '/Event/create',
        search: search,
        state: {
          record: {
            title: '',
            description: '',
            startDate: arg.startStr,
            endDate: arg.endStr,
            allDay: arg.allDay,
            teamMemberIds: teamMemberMeData?.teamMemberMe ? [teamMemberMeData?.teamMemberMe.id] : [],
          },
        },
      })
    },
    [search, teamMemberMeData]
  )

  const onDayLabelClick = useCallback(
    (date: any) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      history.push({
        pathname: props.basePath,
        search: queryString.stringify({
          ...parsedQueryString,
          initialView: 'timeGridDay',
          date: date.toISOString(),
        }),
      })
    },
    [onCalGoToDate, parsedQueryString]
  )

  const eventContent = useCallback((arg: EventContentArg) => {
    if (arg.event.extendedProps.isFlightEvent) {
      return <FlightEvent calendarEvent={arg} locale={dateFnsEnLocale} />
    } else {
      return (
        <Event calendarEvent={arg.event} locale={dateFnsEnLocale} timeText={arg.timeText} />
        // <Popover trigger="hover">
        //   <PopoverTrigger>
        //     <div
        //       className={classes.eventContainer}
        //       // style={badgeColor ? { borderLeft: `5px solid ${badgeColor}` } : undefined}
        //     >
        //       <div className={classes.eventTopContainer}>
        //         <Typography className={classes.eventTitle} variant="body2">
        //           {arg.event.title}
        //         </Typography>
        //         <Typography noWrap variant="caption" className={classes.eventTimeText}>
        //           {arg.timeText}
        //         </Typography>
        //       </div>
        //     </div>
        //   </PopoverTrigger>

        //   <Portal>
        //     <PopoverContent>
        //       <PopoverArrow />
        //       <PopoverCloseButton />
        //       <PopoverHeader>Confirmation!</PopoverHeader>
        //       <PopoverBody>Are you sure you want to have that milkshake?</PopoverBody>
        //     </PopoverContent>
        //   </Portal>
        // </Popover>
      )
    }
  }, [])

  const events = useMemo(() => {
    return [
      ...(dataEvents?.events?.data
        ? dataEvents.events.data.map((item: any) => {
            console.log(item.startDate, '<-- event.startDate')
            const isEditable = true
            return {
              id: item.id,
              title: item.title,
              start: item.startDate ? item.startDate : undefined,
              end: item.endDate ? item.endDate : undefined,
              editable: isEditable,
              overlap: isEditable,
              allDay: item.allDay,
              extendedProps: {
                ...item,
              },
            }
          })
        : []),
    ]
  }, [dataEvents])

  const flights = useMemo(() => {
    return [
      ...(dataFlights?.orderRoutes?.data
        ? dataFlights?.orderRoutes?.data.map((item: any) => {
            return {
              ...item,
              id: item.id,
              title: `Flight from ${item.departureAirportId} to ${item.arrivalAirportId}`,
              start: item.flightDate ? item.flightDate.slice(0, -1) : undefined,
              end: item.arrivalDate ? item.arrivalDate.slice(0, -1) : undefined,
              editable: false,
              overlap: false,
              backgroundColor: 'white',
              extendedProps: {
                ...item,
                isFlightEvent: true,
                // orderId: item.orderId,
                // departureAirportId: item.departureAirportId,
                // arrivalAirportId: item.arrivalAirportId,
              },
            }
          })
        : []),
    ]
  }, [dataFlights])

  useEffect(() => {
    if (fullCalendarRef.current) {
      fullCalendarRef.current!.getApi().changeView(initialCalendarView)
    }
  }, [initialCalendarView, fullCalendarRef])

  useEffect(() => {
    if (fullCalendarRef.current && selectedDateString) {
      const date = new Date(selectedDateString)
      if (fullCalendarRef.current.getApi().view.activeStart.getTime() !== date.getTime()) {
        fullCalendarRef.current.getApi().gotoDate(date)
      }
    }
  }, [selectedDateString, fullCalendarRef])

  useEffect(() => {
    if (viewVersion > 0 && !!fullCalendarRef.current) {
      refetchEvents()
    }
  }, [viewVersion])

  useEffect(() => {
    // startPolling(2000)
    // startPollingFlights(5000)
    return (): void => {
      stopPolling()
      stopPollingFlights()
    }
  }, [])

  const dayHeaderContent: CustomContentGenerator<{
    date: Date
    view: ViewApi
    text: string
  }> = useCallback(
    ({ text, date, view }) => {
      const momentDate = moment(date)
      const isTodayActive = today.getTime() === date.getTime() ? classes.activeDayLabel : undefined
      return (
        <div className={classes.dayHeaderContent}>
          <Typography
            className={classes.dayOfWeekText}
            color={isTodayActive ? 'primary' : 'textSecondary'}
            variant="body2"
          >
            {momentDate.format('ddd')}
          </Typography>

          {view.type !== 'dayGridMonth' && (
            <IconButton
              size="small"
              // color={selectedDate.getTime() === date.getTime() ? 'primary' : undefined}
              onClick={onDayLabelClick(date)}
              className={clsx(classes.dayIconButton, { [classes.activeDayLabel]: isTodayActive })}
            >
              <Typography
                color={!isTodayActive ? 'textPrimary' : undefined}
                className={classes.dayIconText}
                variant="h4"
              >
                {momentDate.format('D')}
              </Typography>
            </IconButton>
          )}
        </div>
      )
    },
    [onDayLabelClick]
  )

  const slotLabelContent: CustomContentGenerator<{
    level: number
    time: Duration
    date: Date
    view: ViewApi
    text: string
  }> = useCallback(({ level, time, date, view, text }) => {
    return (
      <div className={classes.hourSlotLabelContent}>
        <Typography className={classes.hourSlotLabel} role="body2" color="textSecondary">
          {text}
        </Typography>
      </div>
    )
  }, [])

  return (
    <MuiPickersUtilsProvider utils={DateMomentUtils}>
      <CalendarToolbar
        onCalToday={onCalToday}
        onCalPrev={onCalPrev}
        onCalNext={onCalNext}
        onToggleCalendarSidebarOpen={handleToggleFastEvents}
        calendarSidebarOpen={fastEventsOpen}
        dateTitle={calTitle}
        initialCalendarView={initialCalendarView}
        onTwoWeeksClick={onSwitchCalView('timeGridTwoWeeks')}
        onWeekClick={onSwitchCalView('timeGridWeek')}
        onDayClick={onSwitchCalView('timeGridDay')}
        onMonthClick={onSwitchCalView('dayGridMonth')}
        calendarRef={fullCalendarRef}
        calendarContainerRef={calendarContainerRef}
        selectedTeamMemberIds={selectedTeamMemberIds}
        onSelectedTeamMemberIdsChange={setSelectedTeamMemberIds}
        // resourceMode={selectedResourceMode}
        // onResourceModeChange={handleResourceModeChange}
        // selectedResourceIds={selectedResourceIds}
        // onResourcesSelected={onResourcesSelected}
        // vehicleHashTable={vehiclesHashTable}
        // vehicles={vehicles}
        // vehiclesLoading={vehiclesLoading}
        // teamMembersHashTable={teamMembersHashTable}
        // teamMembers={teamMembers}
        // teamMembersLoading={teamMembersLoading}
        // officeHashTable={officesHashTable}
        // offices={offices}
        // officesLoading={officesLoading}
      />

      <div className={classes.root}>
        <Box display="flex" flexDirection="row" className={classes.container}>
          <Box pr={2} ml={1} py={1} className={classes.calendarFastEventTemplatesContainer} width={'300px'}>
            <MonthlyPreviewPicker value={selectedDate} onChange={onCalGoToDate} />
            <PlanningCustomersList ref={onCustomersFERef} {...props} />
            {/* <PlanningEventTemplatesList ref={onTemplateEventsFERef} {...props} /> */}
          </Box>

          <Box flex="1" className={classes.calendarSectionContainer}>
            <div className={classes.calendarContainer} ref={calendarContainerRef}>
              {initialCalendarView === 'resourceTimeGridDay' && (
                <div className={classes.oneDayDate}>
                  <Typography align="center" variant="h4">
                    {calTitle}
                  </Typography>
                </div>
              )}
              <FullCalendar
                locale={itLocale}
                ref={fullCalendarRef as any}
                headerToolbar={false}
                plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]}
                initialView={initialCalendarView}
                initialDate={selectedDateString}
                eventReceive={handleFastEventDrop}
                eventDrop={handleEventDrop}
                eventResize={handleEventResize}
                select={handleDateSelected}
                eventClick={handleEventClick}
                selectable={true}
                droppable={true}
                editable={true}
                datesSet={onCalendarViewChange}
                dayHeaderContent={dayHeaderContent}
                slotLabelContent={slotLabelContent}
                // timeZone="Europe/Rome"
                height="100%"
                nowIndicator
                eventSources={[
                  {
                    events: flights,
                    // backgroundColor: '#ffca68',
                    // borderColor: '#ffca68',
                    // textColor: 'black',
                    editable: false,
                  },
                  {
                    events,
                  },
                ]}
                views={{
                  timeGridTwoWeeks: {
                    type: 'timeGrid',
                    duration: { weeks: 2 },
                    buttonText: '14 day',
                  },
                }}
                eventContent={eventContent}
                eventClassNames={classes.fcEvent}
                slotLabelClassNames={classes.fcSlotLabel}
                slotLaneClassNames={classes.fcSlotLane}
                dayHeaderClassNames={classes.fcCalendarDayHeader}
              />
            </div>
          </Box>
        </Box>
      </div>
    </MuiPickersUtilsProvider>
  )
}
