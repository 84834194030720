import { useQuery } from '@apollo/client'
import React, { FC, forwardRef, useCallback, useEffect, useState } from 'react'
import { useTranslate, useDataProvider, useNotify } from 'ra-core'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Box from '@material-ui/core/Box'
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles'
import { useDebounce } from '../../utils/useDebounce'
import { Accordion, AccordionSummary } from './PlanningAccordions'
import clsx from 'clsx'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import CustomerAvatarField from '../CustomerAvatarField'

type Props = {
  [prop: string]: any
  className?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    },
    attributeHeading: {
      // fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
    },
    accordion: {
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    accordionExpanded: {},
    accordionSummary: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    accordionSummaryContent: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    searchField: {
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.grey[100],
        },
        '&:hover fieldset': {
          borderColor: theme.palette.grey[400],
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.grey[400],
        },
      },
    },
    customerListItemRoot: {
      // paddingTop: 0,
      // paddingBottom: 0,
    },
  })
)

const PlanningCustomersList: FC<Props> = forwardRef<any, Props>(({ className, ...rest }, ref) => {
  const classes = useStyles()
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const [initialized, setInitialized] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const notify = useNotify()
  const [data, setData] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [filter, setFilter] = useState<string>('')
  const debouncedFilter = useDebounce(filter, 150)
  const [pagination, setPagination] = useState<{ perPage: number; page: number }>({ perPage: 20, page: 1 })
  const [sort, setSort] = useState<{ field: string; order: 'ASC' | 'DESC' }>({ field: 'lastName', order: 'ASC' })

  const onFilterChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
  }, [])

  const fetchCustomers = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const result = await dataProvider.getList('Customer', {
        filter: {
          q: debouncedFilter,
        },
        pagination,
        sort,
      })
      setData(result.data)
      setTotal(result.total)
    } catch (e) {
      //
      notify('ra.error')
      console.error('error on fetching customers', e)
    } finally {
      setLoading(false)
      setInitialized(true)
    }
  }, [debouncedFilter, pagination, sort, dataProvider, notify])

  useEffect(() => {
    fetchCustomers()
  }, [])

  useEffect(
    () => {
      fetchCustomers()
    },
    [debouncedFilter] // Only call effect if debounced search term changes
  )

  if (!initialized) {
    return <CircularProgress />
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary disableRipple={false} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1" className={classes.attributeHeading}>
          Clients
        </Typography>
      </AccordionSummary>
      <Box display="flex" flexDirection="column">
        <TextField
          variant="outlined"
          size="small"
          className={classes.searchField}
          value={filter}
          onChange={onFilterChange}
          placeholder="Search clients..."
        />
      </Box>
      <List ref={ref}>
        {data.map((item, index) => {
          return (
            <ListItem
              key={`calendar-list-customer-${item.id}`}
              id={`calendar-list-customer-${item.id}`}
              button
              className="draggable-event"
              classes={{
                root: classes.customerListItemRoot,
              }}
              data-customer={JSON.stringify({
                id: item.id,
                firstName: item.firstName,
                lastName: item.lastName,
                fullName: item.fullName,
              })}
            >
              <ListItemAvatar>
                <CustomerAvatarField avatarSize={36} record={item} source="profilePictureId" />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'body2',
                }}
                primary={
                  item.companyName || `${item.lastName ? item.lastName : ''} ${item.firstName ? item.firstName : ''}`
                }
              />
            </ListItem>
          )
        })}
      </List>
    </Accordion>
  )
})

export default PlanningCustomersList
