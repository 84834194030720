import React, { forwardRef, useCallback, FC } from 'react'
import { StaticContext, useRouteMatch } from 'react-router'
import { NavLinkProps } from 'react-router-dom'
import { MenuItemProps } from '@material-ui/core/MenuItem'
import { NavLink as RouterLink } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

export const useStyles = makeStyles(
  (theme) => ({
    item: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
      margin: '2px 0',
      transition: 'all 0.8s ease',
    },
    button: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      justifyContent: 'flex-start',
      letterSpacing: 0,
      // paddingTop: theme.spacing(2),
      // paddingBottom: theme.spacing(2),
      padding: theme.spacing(2),
      textTransform: 'none',
      whiteSpace: 'nowrap',
      width: '100%',
      // '&:hover': {
      //   backgroundColor: 'none',
      // },
    },
    icon: {
      marginRight: theme.spacing(1),
      height: '20px',
      // color: '#b9a7a6',
    },
    iconSelected: {
      height: '20px',
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    title: {
      // fontWeight: 'lighter',
      // color: '#5e5555',
      marginLeft: theme.spacing(2),
      // fontSize: '14px',
    },
    titleSelected: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
      fontSize: '14px',
    },
    active: {
      color: theme.palette.primary.main,
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& $icon': {
        color: theme.palette.primary.main,
      },
    },
    selected: {
      backgroundColor: '#9cb0bb !important',
      borderRadius: '4px',
    },

    // root: {
    //   color: theme.palette.text.secondary,
    // },
    // active: {
    //   color: theme.palette.text.primary,
    // },
    // icon: { minWidth: theme.spacing(5) },
  }),
  { name: 'RaMenuItemLink' }
)

const MenuItemLink: FC<MenuItemLinkProps> = forwardRef((props, ref) => {
  const {
    classes: classesOverride,
    className,
    primaryText,
    leftIcon: Icon,
    onClick,
    sidebarIsOpen,
    isExactMatch = false,
    // selected,
    ...rest
  } = props
  const classes = useStyles(props)
  const match = useRouteMatch(props.to as string)

  const handleMenuTap = useCallback(
    (e) => {
      onClick && onClick(e)
    },
    [onClick]
  )

  const renderMenuItem = (): React.ReactElement => {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        classes={{ selected: classes.selected }}
        disableGutters
        onClick={handleMenuTap}
        selected={isExactMatch && match ? match.isExact : !!match}
      >
        <Button ref={ref as any} className={classes.button} component={RouterLink} {...(rest as any)}>
          {Icon && <Icon className={match ? classes.iconSelected : classes.icon} size="20" />}
          {/* {Icon && Icon} */}
          <span className={match ? classes.titleSelected : classes.title}>{primaryText}</span>
        </Button>
      </ListItem>
    )

    // return (
    //   <MenuItem
    //     className={classnames(classes.root, className)}
    //     activeClassName={classes.active}
    //     component={NavLinkRef}
    //     ref={ref}
    //     {...rest}
    //     onClick={handleMenuTap}
    //   >
    //     {leftIcon && (
    //       <ListItemIcon className={classes.icon}>
    //         {cloneElement(leftIcon, {
    //           titleAccess: primaryText,
    //         })}
    //       </ListItemIcon>
    //     )}
    //     {primaryText}
    //   </MenuItem>
    // )
  }

  if (sidebarIsOpen) {
    return renderMenuItem()
  }

  return (
    <Tooltip title={primaryText} placement="right">
      {renderMenuItem()}
    </Tooltip>
  )
})

interface Props {
  leftIcon?: any
  primaryText?: any
  staticContext?: StaticContext
  sidebarIsOpen?: boolean
  isExactMatch?: boolean
  // to: string
}

export type MenuItemLinkProps = Props & NavLinkProps & MenuItemProps<'li', { button?: true }> // HACK: https://github.com/mui-org/material-ui/issues/16245

export default MenuItemLink
