import * as React from 'react'
import { FC } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate, useListContext } from 'ra-core'
import inflection from 'inflection'

import { ClassesOverride } from '../../types'
import CreateButton from '../../components/button/CreateButton'
import { FaEyeSlash } from 'react-icons/fa'
import BaseTypography from '../BaseTypography'

const useStyles = makeStyles(
  (theme) => ({
    message: {
      textAlign: 'center',
      margin: '0 1em',
      color: theme.palette.text.secondary,
    },
    icon: {
      width: '4.5rem',
      height: '4.5rem',
    },
    toolbar: {
      textAlign: 'center',
      marginTop: theme.spacing(3),
    },
    wrapper: {
      width: '100%',
      height: '100%',
    },
  }),
  { name: 'RaEmpty' }
)

const Empty: FC<EmptyProps & { resourceName?: string; hasCreate?: boolean }> = (props) => {
  const { resource, basePath } = useListContext(props)
  const translate = useTranslate()
  const {
    iconComponent,
    titleComponent,
    messageComponent,
    buttonComponent,
    component = Box,
    shadow = 0,
    padding = 10,
    my = 3,
    mx = 0,
    message,
    title,
    bgcolor = 'background.paper',
    hasCreate,
  } = props
  const classes = useStyles(props)

  const Component = component
  const realResource = props.resourceName ? props.resourceName : resource
  const resourceName = realResource
    ? translate(`resources.${realResource}.forcedCaseName`, {
        smart_count: 0,
        _: inflection.humanize(
          translate(`resources.${realResource}.name`, {
            smart_count: 0,
            _: inflection.pluralize(realResource),
          }),
          true
        ),
      })
    : undefined

  const emptyMessage = title || translate('ra.page.empty', { name: resourceName })
  const inviteMessage = message || translate('ra.page.invite')

  return (
    <Component className={classes.wrapper} my={my} mx={mx}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bgcolor={bgcolor}
        borderRadius={4}
        boxShadow={shadow}
        width="100%"
        height="100%"
        p={padding}
      >
        <div className={classes.message}>
          {iconComponent ? React.cloneElement(iconComponent, {}) : <FaEyeSlash className={classes.icon} />}
          {titleComponent ? (
            React.cloneElement(titleComponent, {})
          ) : (
            <BaseTypography color="textSecondary" variant="h3" paragraph>
              {translate(`resources.${resource}.empty`, {
                _: emptyMessage,
              })}
            </BaseTypography>
          )}
          {hasCreate &&
            (messageComponent ? (
              React.cloneElement(messageComponent, {})
            ) : (
              <BaseTypography color="textSecondary" variant="body1">
                {translate(`resources.${resource}.invite`, {
                  _: inviteMessage,
                })}
              </BaseTypography>
            ))}
        </div>
        {hasCreate && (
          <div className={classes.toolbar}>
            {buttonComponent
              ? React.cloneElement(buttonComponent, {})
              : resource && (
                  <CreateButton
                    basePath={basePath}
                    label={translate('create') + ' ' + translate(`resources.${resource}.name`, { smart_count: 1 })}
                  />
                )}
          </div>
        )}
      </Box>
    </Component>
  )
}

export interface EmptyProps {
  classes?: ClassesOverride<typeof useStyles>
  iconComponent?: React.ReactElement
  titleComponent?: React.ReactElement
  messageComponent?: React.ReactElement
  buttonComponent?: React.ReactElement
  component?: any
  title?: string
  message?: string
  shadow?: number
  padding?: number
  my?: number
  mx?: number
  bgcolor?: string
}

export default Empty
