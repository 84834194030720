import React, { FC } from 'react'
import { NumberField } from 'react-admin'
import ErrorIcon from '@material-ui/icons/Error'
import Box from '@material-ui/core/Box'
import CurrencyField from './CurrencyField'

type Props = {
  record?: Record<string, any>
  // source?: string
  label?: string
  [x: string]: any
}

const OrderIncomeField: FC<Props> = ({ record = {}, ...props }) => {
  const percentageRecord = {
    percentage: record.commissions / record.purchasePrice,
  }

  if (!record.commissions || !record.purchasePrice) return <ErrorIcon color="error" />

  return (
    <div>
      <CurrencyField {...props} record={record} source="commissions" />
      <Box mr={1} />
      <NumberField {...props} record={percentageRecord} source="percentage" options={{ style: 'percent' }} />
    </div>
  )
}

export default OrderIncomeField
