import React, { FC, useCallback, useMemo } from 'react'
import { CircularProgress, IconButton, makeStyles, Typography } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import blue from '@material-ui/core/colors/lightBlue'
import GetAppIcon from '@material-ui/icons/GetApp'
import fileDownload from 'js-file-download'

type Props = {
  isLoading?: boolean
  attachmentUrl?: string
  onDownload?: () => void
  filename?: string
  filesize?: string
  resourceType?: 'quote' | 'contract' | 'brief'
  reference?: string
}

export const MailAttachment: FC<Props> = ({
  isLoading,
  attachmentUrl,
  onDownload,
  filename,
  filesize,
  resourceType,
  reference,
}) => {
  const classes = useStyles()

  const attachmentName = useMemo(() => {
    // console.log(filename, attachmentUrl, 'ciao ciao')
    // if (filename) {
    //   return filename
    // }

    if (!attachmentUrl) {
      return undefined
    }

    // const splitted = attachmentUrl.split('/')

    // return splitted[splitted.length - 1]

    if (resourceType && reference) {
      return `cielo-${resourceType}-${reference}.pdf`
    }

    const splitted = attachmentUrl.split('/')
    return splitted[splitted.length - 1]
  }, [attachmentUrl, resourceType])

  const handleDownload = useCallback(async () => {
    if (onDownload && !attachmentUrl) {
      onDownload()
      return
    }

    if (!attachmentUrl || !attachmentName) {
      return
    }

    const downloadResult = await fetch(attachmentUrl)
    const fileBlob = await downloadResult.blob()
    fileDownload(fileBlob, attachmentName)
  }, [attachmentUrl, attachmentName])

  return (
    <div className={classes.root}>
      <div className={classes.attachmentPreview}>{isLoading ? <CircularProgress size={18} /> : <AttachFileIcon />}</div>
      <div className={classes.attachmentData}>
        {isLoading ? (
          <Typography className={classes.initializingText}>Preparing the attachment...</Typography>
        ) : (
          <>
            <Typography className={classes.fileName}>{attachmentName}</Typography>
            {filesize && <Typography className={classes.fileSize}>{filesize}</Typography>}
          </>
        )}
      </div>
      <IconButton
        onClick={handleDownload}
        disabled={isLoading}
        className={classes.attachmentActions}
        color="primary"
        aria-label="upload picture"
        component="span"
      >
        <GetAppIcon />
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 5px',
    backgroundColor: blue[50],
    borderRadius: '4px',
  },
  attachmentPreview: {
    width: '50px',
    minWidth: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    backgroundColor: blue[500],
    color: 'white',
  },
  attachmentData: {
    paddingLeft: '15px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  fileName: {
    fontWeight: 'bold',
    color: blue[800],
    fontSize: '16px',
    width: '200px',
    maxHeight: '25px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  fileSize: {
    fontSize: '14px',
    opacity: 0.7,
  },
  initializingText: {
    fontSize: '14px',
    opacity: 0.7,
    alignSelf: 'flex-start',
  },
  attachmentActions: {},
}))
