import React, { FC, useCallback, useState } from 'react'
import { parse } from 'query-string'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Route, useHistory } from 'react-router-dom'
import { useTranslate, useShowController, useRedirect, useRefresh } from 'ra-core'
import { ReferenceField, TextField, FunctionField, ChipField, Confirm } from 'react-admin'
import { InfoCard, InfoCardItem, InfoCardWrapper, InfoCardTypography, InfoCardAvatar } from '../../components/InfoCard'
import ShowView from '../../components/details/ShowView'
import { FaUserTie, FaMoneyCheckAlt, FaBriefcase, FaLeaf } from 'react-icons/fa'
import { GrStatusInfo } from 'react-icons/gr'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CurrencyField from '../../components/field/CurrencyField'
import OrderStatusDropDown from '../../components/OrderStatusDropDown'
import OrderChecklistGroups from './OrderChecklistGroups'
import OrderRoute from './OrderRoute'
import Drawer from '../../components/Drawer'
import DrawerHeader from '../../components/DrawerHeader'
import InDrawerOrderRouteForm from './InDrawerOrderRouteForm'
import InDrawerOrderEditForm from './InDrawerOrderEditForm'
import OrderMessagesField from '../../components/OrderMessagesField'
import TabPanel from '../../components/TabPanel'
import OrderAdditionalInfoField from './OrderAdditionalInfoField'
import GenericFilesSection from './GenericFilesSection'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import InDrawerAddPassenger from './InDrawerAddPassenger'
import {
  DownloadServerGeneratedFileButton,
  useDownloadServerGeneratedButton,
} from '../../components/button/DownloadServerGeneratedFileButton'
import {
  MUTATION_GENERATE_CIELO_FLIGHT_BRIEF,
  MUTATION_GENERATE_CIELO_ORDER,
  MUTATION_UPDATE_ORDER_STATUS,
} from '../../queries'
import PrintIcon from '@material-ui/icons/Print'
import { Hidden } from '@material-ui/core'
import { TiPrinter } from 'react-icons/ti'
import ToggleStatusButton from '../../components/button/ToggleStatusButton'
import Tooltip from '@material-ui/core/Tooltip'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { RichTextField } from 'ra-ui-materialui'
import { CgNotes } from 'react-icons/cg'
import { CreateMailDrawerButton } from '../../components/gmail/CreateMailDrawerButton'
import { OrderActivities } from '../../components/Activities/OrderActivities'
import { useMutation } from '@apollo/client'
import { Center, Circle, Heading, HStack, Text } from '@chakra-ui/react'
import OrderFeedbackNotification from './OrderFeedbackNotification'

const LeftSide = (props: any): any => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Box className={classes.leftSide}>
      <InfoCardWrapper>
        <InfoCard>
          <InfoCardItem direction="row" alignItems="center">
            <InfoCardAvatar>
              <FaBriefcase size={20} />
            </InfoCardAvatar>
            <Box display="flex" flexDirection="column" justifySelf="flex-start">
              <InfoCardTypography variant="label">{`${translate(
                'resources.Order.fields.customerId'
              )}:`}</InfoCardTypography>
              <InfoCardTypography variant="value">
                <ReferenceField {...props} label={false} source="customerId" reference="Customer" link="show">
                  <TextField source="fullName" />
                </ReferenceField>
              </InfoCardTypography>
              <ReferenceField {...props} label={false} source="customerId" reference="Customer" link={false}>
                <ChipField size="small" color="primary" source="customerStatus" />
              </ReferenceField>
            </Box>
          </InfoCardItem>
          {props.record.personId && (
            <InfoCardItem direction="column" alignItems="flex-start">
              <Box display="flex">
                <InfoCardAvatar>
                  <FaUserTie size={20} />
                </InfoCardAvatar>
                <div>
                  <InfoCardTypography variant="title">
                    <ReferenceField {...props} label={false} source="personId" reference="Person" link={false}>
                      <TextField source="fullName" variant="h3" className={classes.bold} />
                    </ReferenceField>
                  </InfoCardTypography>
                  <InfoCardTypography variant="label">
                    <ReferenceField {...props} label={false} source="personId" reference="Person" link={false}>
                      <TextField source="position" />
                    </ReferenceField>
                  </InfoCardTypography>
                </div>
              </Box>
            </InfoCardItem>
          )}
        </InfoCard>
        <Box mt={4} />
        <InfoCard>
          <InfoCardItem direction="row" alignItems="flex-start">
            <InfoCardAvatar>
              <GrStatusInfo size={20} />
            </InfoCardAvatar>
            <Box display="flex" flexDirection="column" justifySelf="flex-start">
              <InfoCardTypography variant="value">{`${translate(
                'resources.Quote.fields.status'
              )}:`}</InfoCardTypography>
              <InfoCardTypography variant="value">
                <OrderStatusDropDown currentStatus={props.record.status} id={props.record.id} />
              </InfoCardTypography>
            </Box>
          </InfoCardItem>
          <Accordion elevation={0} classes={{ expanded: classes.infoCardAccordionSummaryContent }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="prices-panel-header"
              classes={{
                root: classes.infoCardAccordionSummaryRoot,
                content: classes.infoCardAccordionSummaryContent,
              }}
            >
              <InfoCardItem direction="row" alignItems="flex-start">
                <InfoCardAvatar>
                  <FaMoneyCheckAlt size={20} />
                </InfoCardAvatar>
                <Box display="flex" flexDirection="column" justifySelf="flex-start">
                  <InfoCardTypography variant="label">{`${translate(
                    'customFields.prices.sellingPrice'
                  )}:`}</InfoCardTypography>
                  <InfoCardTypography variant="value">
                    <CurrencyField record={props.record} source="sellingPrice" variant="h4" />
                  </InfoCardTypography>
                </Box>
              </InfoCardItem>
            </AccordionSummary>
            <AccordionDetails className={classes.infoCardAccordionDetail}>
              <InfoCardItem direction="row" justifyContent="space-between">
                <Typography>{translate('customFields.prices.purchasePrice')}</Typography>
                <CurrencyField record={props.record} source="purchasePrice" className={classes.price}></CurrencyField>
              </InfoCardItem>
              <InfoCardItem direction="row" justifyContent="space-between">
                <Typography>{translate('customFields.prices.commissions')}</Typography>
                <CurrencyField record={props.record} source="commissions" className={classes.price}></CurrencyField>
              </InfoCardItem>
              <InfoCardItem direction="row" justifyContent="space-between">
                <Typography>{translate('customFields.prices.sellingPriceWithoutTaxes')}</Typography>
                <CurrencyField
                  record={props.record}
                  source="sellingPriceWithoutTaxes"
                  className={classes.price}
                ></CurrencyField>
              </InfoCardItem>
              <InfoCardItem direction="row" justifyContent="space-between">
                <Typography>{translate('customFields.prices.vat')}</Typography>
                {props.record.taxId ? (
                  <ReferenceField source="taxId" record={props.record} reference="Tax" basePath="/Quote" link={false}>
                    <FunctionField render={(record: any): string => `${record.rate}%`} />
                  </ReferenceField>
                ) : (
                  <Typography variant="body2" className={classes.price}>
                    {'0%'}
                  </Typography>
                )}
              </InfoCardItem>
              <InfoCardItem direction="row" justifyContent="space-between">
                <Typography>{translate('customFields.prices.passengerTax')}</Typography>
                <CurrencyField record={props.record} source="passengerTax" className={classes.price}></CurrencyField>
              </InfoCardItem>
            </AccordionDetails>
          </Accordion>
          <InfoCardItem direction="row" alignItems="center">
            <InfoCardAvatar>
              <FaLeaf size={20} />
            </InfoCardAvatar>
            <Box display="flex" justifySelf="flex-start" alignItems="center">
              <InfoCardTypography variant="value">{'Carbon offset'}</InfoCardTypography>
              <Box mr={2} />
              <ToggleStatusButton source="showCarbonOffset" record={props.record} resource="Order" noRefresh />
            </Box>
          </InfoCardItem>
          <InfoCardItem direction="row" alignItems="center">
            <InfoCardAvatar>
              <CgNotes size={20} />
            </InfoCardAvatar>
            <Box display="flex" justifySelf="flex-start" alignItems="center">
              <InfoCardTypography variant="value">{'Printable notes'}</InfoCardTypography>
              <Box mr={2} />
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={
                  props.record.printableNotes?._html ? (
                    <RichTextField record={props.record.printableNotes} source="_html" />
                  ) : (
                    <Typography variant="body2">No notes found</Typography>
                  )
                }
              >
                <VisibilityIcon color={props.record.printableNotes?._html ? 'primary' : 'secondary'} />
              </Tooltip>
            </Box>
          </InfoCardItem>
        </InfoCard>
        <Box mb={4} />
        <OrderChecklistGroups orderId={props.record.id} />
      </InfoCardWrapper>
    </Box>
  )
}

const OrderShowComp = (props: any): any => {
  const classes = useStyles()
  // const translate = useTranslate()
  // const notify = useNotify()
  // const refresh = useRefresh()
  const history = useHistory()

  const theme = useTheme()
  const [currentTab, setCurrentTab] = useState<number>(0)
  // isDesktop will be true if width > 1280px - real-time updated
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const handleTabChange = useCallback((tabIndex: number) => (): void => setCurrentTab(tabIndex), [])

  const handleDrawerClose = useCallback(() => history.goBack(), [])

  const queryString = parse(props.location.search)

  const onEditOrderRouteClick = (orderRouteId: number) => (event: any): void => {
    event.preventDefault()
    event.stopPropagation()
    history.push(`/Order/${props.record.id}/show/OrderRoute/${orderRouteId}`)
  }

  /**
   * generateMutation={MUTATION_GENERATE_CIELO_ORDER}
    // getFileName={(): string => `order-${Intl.DateTimeFormat().format(new Date())}.pdf`}
    getFileName={(): string => `cielo-contract-${showProps?.record?.reference.slice(-5)}.pdf`}
    getPayload={(): any => ({ orderId: showProps?.record?.id })}
   */

  const { loading: downloadingRouteFlightBrief, download: downloadRouteFlightBrief } = useDownloadServerGeneratedButton(
    {
      generateMutation: MUTATION_GENERATE_CIELO_FLIGHT_BRIEF,
      getFileName: (params): string => `cielo-flight-brief-${props?.record?.reference.slice(-5)}.pdf`,
      getPayload: (params): any => ({ orderId: props?.record?.id, routeIds: [params.orderRouteId] }),
    }
  )
  const onPrintOrderRouteClick = (orderRouteId: number) => (event: any): void => {
    event.preventDefault()
    event.stopPropagation()
    downloadRouteFlightBrief({ orderRouteId })
  }

  return (
    <>
      {isDesktop ? (
        <Box display="flex" position="relative">
          <LeftSide {...props} />
          <Box display="flex" flexDirection="column" alignItems="stretch" flexGrow={1}>
            <OrderFeedbackNotification record={props.record} />
            {props.record.routes &&
              props.record.routes.map((route: any, index: number) => {
                return (
                  <OrderRoute
                    {...route}
                    key={`route-${route.id}`}
                    onEditClick={onEditOrderRouteClick}
                    onPrintClick={onPrintOrderRouteClick}
                    index={index}
                    defaultExpanded={queryString && queryString.selectedRoute && queryString.selectedRoute === route.id}
                  />
                )
              })}
            <Box mt={0} />
            <OrderAdditionalInfoField record={props.record} />
            <Box mt={0} />
            <OrderMessagesField record={props.record} />
            <Box mt={0} />
            <GenericFilesSection record={props.record} />
            <Box mt={0} />
            <OrderActivities record={props.record} />
          </Box>
        </Box>
      ) : (
        <>
          <Box mb={2} display="flex" position="sticky" top={10} zIndex={10}>
            <Button
              onClick={handleTabChange(0)}
              variant="contained"
              color={currentTab === 0 ? 'primary' : undefined}
              classes={{ label: classes.fontNormal }}
            >
              Info
            </Button>
            <Box ml={2} />
            <Button
              onClick={handleTabChange(1)}
              variant="contained"
              color={currentTab === 1 ? 'primary' : undefined}
              classes={{ label: classes.fontNormal }}
            >
              Routes
            </Button>
            <Button
              onClick={handleTabChange(2)}
              variant="contained"
              color={currentTab === 2 ? 'primary' : undefined}
              classes={{ label: classes.fontNormal }}
            >
              Activities
            </Button>
          </Box>
          <TabPanel value={currentTab} index={0}>
            <LeftSide {...props} />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <Box display="flex" flexDirection="column" alignItems="stretch" flexGrow={1}>
              {props.record.routes &&
                props.record.routes.map((route: any, index: number) => {
                  return (
                    <OrderRoute
                      {...route}
                      key={`route-${route.id}`}
                      onEditClick={onEditOrderRouteClick}
                      onPrintClick={onPrintOrderRouteClick}
                      index={index}
                      defaultExpanded={
                        queryString && queryString.selectedRoute && queryString.selectedRoute === route.id
                      }
                    />
                  )
                })}
              <Box mt={4} />
              <OrderAdditionalInfoField record={props.record} />
              <Box mt={4} />
              <OrderMessagesField record={props.record} />
              <Box mt={4} />
              <GenericFilesSection record={props.record} />
            </Box>
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <OrderActivities record={props.record} />
          </TabPanel>
        </>
      )}
      <Route exact path="/Order/:id/show/OrderRoute/:orderRouteId">
        {({ match }: any): React.ReactNode => {
          const isMatch = match && match.params
          const isCreate = match && match.params && match.params.orderRouteId === 'create'
          const orderRouteId = match?.params.orderRouteId

          return (
            <Drawer open={isMatch} onClose={handleDrawerClose}>
              {orderRouteId ? (
                <>
                  <DrawerHeader buttonOnClick={handleDrawerClose} title={`${isCreate ? 'Create' : 'Edit'} route`} />
                  <InDrawerOrderRouteForm id={isCreate ? undefined : orderRouteId} orderId={props.record.id} />
                </>
              ) : null}
            </Drawer>
          )
        }}
      </Route>
      <Route exact path="/Order/:id/show/OrderRoute/:orderRouteId/addpassenger">
        {({ match }: any): React.ReactNode => {
          const isMatch = match && match.params
          const orderRouteId = match?.params.orderRouteId

          return (
            <Drawer open={isMatch} onClose={handleDrawerClose}>
              {orderRouteId ? (
                <>
                  <DrawerHeader buttonOnClick={handleDrawerClose} title={'Add passenger'} />
                  <InDrawerAddPassenger orderRouteId={orderRouteId} orderId={props.record.id} />
                </>
              ) : null}
            </Drawer>
          )
        }}
      </Route>
    </>
  )
}

const OrderShow: FC<any> = (props) => {
  const classes = useStyles()
  const showProps = useShowController(props)
  const refresh = useRefresh()
  // const translate = useTranslate()
  const redirect = useRedirect()
  // const history = useHistory()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  const [isChangeOrderStatusDialogOpen, setChangeOrderStatusDialogOpen] = useState<boolean>(false)
  const handleChangeOrderStatusDialogClose = useCallback((): void => setChangeOrderStatusDialogOpen(false), [])
  const [updateOrderStatus, { loading, error, data }] = useMutation(MUTATION_UPDATE_ORDER_STATUS, {
    variables: {
      id: props.id,
      status: 'sent',
    },
    onCompleted: () => {
      setChangeOrderStatusDialogOpen(false)
      refresh()
    },
  })

  const handleContractMailSent = useCallback(() => {
    if (showProps.loading || !showProps.record) {
      return
    }

    const currentStatus = showProps.record?.status

    if (currentStatus === 'confirmed') {
      return updateOrderStatus()
    }
  }, [showProps?.loading, showProps?.record])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])
  const onMoreClose = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const goToEdit = useCallback((event: any) => {
    onMoreClose(event)
    setIsDrawerOpen(true)
  }, [])

  const goToNewRoute = useCallback(
    (event: any) => {
      if (showProps && showProps.record) {
        onMoreClose(event)
        redirect(`/Order/${showProps?.record.id}/show/OrderRoute/create`)
      }
    },
    [showProps?.record]
  )

  const handleDrawerClose = useCallback(() => setIsDrawerOpen(false), [])

  const handleContractDownloadCompleted = useCallback(() => {
    if (showProps.loading || !showProps.record) {
      return
    }

    const currentStatus = showProps.record?.status

    if (currentStatus === 'confirmed') {
      setChangeOrderStatusDialogOpen(true)
    }
  }, [showProps])

  return !showProps.loading && showProps.record ? (
    <>
      <ShowView
        {...showProps}
        {...props}
        title={
          showProps.record
            ? `Order #${showProps.record.reference.substring(showProps.record.reference.length - 5)}`
            : undefined
        }
        hideEdit
        additionalActions={
          <Box display="flex">
            <DownloadServerGeneratedFileButton
              generateMutation={MUTATION_GENERATE_CIELO_ORDER}
              // getFileName={(): string => `order-${Intl.DateTimeFormat().format(new Date())}.pdf`}
              getFileName={(): string => `cielo-contract-${showProps?.record?.reference.slice(-5)}.pdf`}
              getPayload={(): any => ({ orderId: showProps?.record?.id })}
              onDownloadComplete={handleContractDownloadCompleted}
            >
              <PrintIcon />
              <Hidden mdDown>
                {' '}
                <Box ml={2}>Print Order</Box>
              </Hidden>
            </DownloadServerGeneratedFileButton>
            <CreateMailDrawerButton
              type="contract"
              resourceId={showProps.record.id as any}
              customerId={showProps.record.customerId}
              reference={showProps.record?.reference?.substring(showProps.record.reference.length - 5)}
              onMailSent={handleContractMailSent}
            >
              <Box ml={2}>Send Contract</Box>
            </CreateMailDrawerButton>
            <DownloadServerGeneratedFileButton
              generateMutation={MUTATION_GENERATE_CIELO_FLIGHT_BRIEF}
              getFileName={(): string => `flight-brief-${showProps.record?.reference.slice(-5)}.pdf`}
              getPayload={(): any => ({ orderId: showProps?.record?.id })}
            >
              <TiPrinter size={24} />
              <Hidden mdDown>
                {' '}
                <Box ml={2}>Print Flight Brief</Box>
              </Hidden>
            </DownloadServerGeneratedFileButton>
            <CreateMailDrawerButton
              type="brief"
              resourceId={showProps.record.id as any}
              customerId={showProps.record.customerId}
              reference={showProps.record?.reference?.substring(showProps.record.reference.length - 5)}
            >
              <Box ml={2}>Send Flight Brief</Box>
            </CreateMailDrawerButton>
            <Avatar onClick={onMoreClick as any} variant="rounded" className={classes.avatar}>
              <MoreVertIcon />
            </Avatar>
            <Menu
              id={`more-order-${showProps.record.id}`}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={onMoreClose}
            >
              <MenuItem onClick={goToEdit}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                Edit order
              </MenuItem>
              <MenuItem onClick={goToNewRoute}>
                <ListItemIcon>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                Add new route
              </MenuItem>
            </Menu>
          </Box>
        }
      >
        <OrderShowComp {...props} />
      </ShowView>
      <Route exact path="/Order/:id">
        {({ match }: any): React.ReactNode => {
          return (
            <Drawer open={isDrawerOpen} onClose={handleDrawerClose}>
              <>
                <DrawerHeader buttonOnClick={handleDrawerClose} title="Edit order" />
                <InDrawerOrderEditForm data={showProps.record} handleDrawerClose={handleDrawerClose} />
              </>
            </Drawer>
          )
        }}
      </Route>

      <Confirm
        isOpen={isChangeOrderStatusDialogOpen}
        content={<ChangeOrderStatusContent />}
        onConfirm={updateOrderStatus}
        onClose={handleChangeOrderStatusDialogClose}
        loading={loading}
      />
    </>
  ) : null
}

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  infoCardAccordionSummaryRoot: {
    paddingLeft: 0,
  },
  infoCardAccordionSummaryContent: {
    margin: '0 !important',
  },
  infoCardAccordionDetail: {
    display: 'flex',
    flexDirection: 'column',
  },
  price: {
    fontWeight: 'bold',
  },
  fontNormal: {
    fontWeight: 'normal',
  },
  avatar: {
    backgroundColor: 'white',
    color: theme.palette.secondary.dark,
    width: '45px',
    height: '45px',
    marginLeft: theme.spacing(2),
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  leftSide: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(4),
    },
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    maxWidth: 600,
    borderRadius: 4,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[4],
  },
}))

const ChangeOrderStatusContent: FC<any> = (props) => {
  return (
    <Center flexDir="column">
      <Heading as="h3" size="md" mb={4}>
        Order status update
      </Heading>

      <Text mb={4}>
        The order status will be updated to{' '}
        <HStack
          cursor="pointer"
          _hover={{ bgColor: 'gray.100' }}
          px={2}
          display="inline-flex"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
        >
          <Circle bgColor="rgb(30, 118, 234)" size="10px" />
          <Text fontWeight="bold">Sent</Text>
        </HStack>{' '}
        .
      </Text>
    </Center>
  )
}

export default OrderShow
