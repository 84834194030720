import React, { FC, useMemo } from 'react'
import { Button, ButtonProps, makeStyles, PropTypes, Typography, TypographyProps } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    backgroundColor: '#eeeeee',
    color: 'rgba(51,51,51,0.5)',
    width: '60px',
    height: '60px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    marginRight: theme.spacing(3),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  light: {
    fontWeight: 'lighter',
  },
  bold: {
    fontWeight: 'bold',
  },
}))

export type BaseTypographyProps = {
  weight?: 'light' | 'bold'
} & TypographyProps

const BaseTypography: FC<BaseTypographyProps> = ({ className, ...rest }) => {
  const { weight } = rest
  const classes = useStyles()

  return (
    <Typography
      className={clsx(className, weight == 'bold' && classes.bold, weight == 'light' && classes.light)}
      {...rest}
    ></Typography>
  )
}

export default BaseTypography
