import React, { forwardRef, useCallback } from 'react'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import { useStyles } from './MenuItemLink'
import { Badge } from '@chakra-ui/react'

export const NotificationsTriggerButton = forwardRef((props: any, ref) => {
  const { classes: classesOverride, className, primaryText, leftIcon: Icon, unreadCount, ...rest } = props
  const classes = useStyles(props)

  return (
    <ListItem className={clsx(classes.item, className)} classes={{ selected: classes.selected }} disableGutters>
      <Button ref={ref as any} className={classes.button} {...rest}>
        {Icon && <Icon className={classes.icon} size="20" />}
        {/* {Icon && Icon} */}
        <span className={classes.title}>{primaryText}</span>
        {unreadCount > 0 && (
          <Badge ml={2} colorScheme="blue" px={2} borderRadius="lg" position="absolute" right={3}>
            {unreadCount}
          </Badge>
        )}
      </Button>
    </ListItem>
  )
})
