import React, { FC, useCallback, useState } from 'react'
import { Confirm } from 'react-admin'
import { useNotify, useRedirect, useRefresh, useTranslate } from 'ra-core'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useApolloClient } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { DELETE_CIELO_QUOTE, MUTATION_DUPLICATE_CIELO_QUOTE } from '../../queries'

type Props = {
  record?: any
}

const QuoteMoreMenu: FC<Props> = ({ record = { id: 0 } }) => {
  const classes = useStyles()
  const refresh = useRefresh()
  const notify = useNotify()
  const apolloClient = useApolloClient()
  const translate = useTranslate()
  const redirect = useRedirect()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleDeleteDialogClose = useCallback((): void => setIsDeleteDialogOpen(false), [])
  const handleDeleteDialogOpen = useCallback((): void => setIsDeleteDialogOpen(true), [])

  const handleDuplicateDialogClose = useCallback((): void => setIsDuplicateDialogOpen(false), [])
  const handleDuplicateDialogOpen = useCallback((): void => setIsDuplicateDialogOpen(true), [])

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const onDuplicateClick = useCallback(async (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    handleDuplicateDialogOpen()
  }, [])

  const onDeleteClick = useCallback((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    onMoreClose(event)
    handleDeleteDialogOpen()
  }, [])

  const onEditClick = useCallback((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    onMoreClose(event)
    redirect(`/Quote/${record.id}/1`)
  }, [])

  const deleteQuote = useCallback(async () => {
    try {
      setLoading(true)
      const result = await apolloClient.mutate({
        mutation: DELETE_CIELO_QUOTE,
        variables: { id: record.id },
      })

      if (result && result.data && !result.data.deleteCieloQuote) {
        throw new Error('error deleting quote ')
      }
      notify('ra.notification.deleted')
    } catch (err: any) {
      console.error('Delete quote error: ', err.message)
      notify(err.message, 'error')
    } finally {
      setLoading(false)
      refresh()
    }
  }, [record])

  const duplicateQuote = useCallback(async () => {
    try {
      setLoading(true)
      const result = await apolloClient.mutate({
        mutation: MUTATION_DUPLICATE_CIELO_QUOTE,
        variables: { id: record.id },
      })

      if (result && result.data && !result.data.duplicateCieloQuote) {
        throw new Error('error duplicating quote')
      }

      notify('ra.notification.success')
      redirect(`/Quote/${result.data.duplicateCieloQuote.id}/show`)
    } catch (err: any) {
      console.error('Duplicate quote error: ', err.message)
      notify(err.message, 'error')
    } finally {
      setLoading(false)
    }
  }, [record])

  return (
    <>
      <IconButton onClick={onMoreClick} className={classes.iconMore}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`more-order-${record.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMoreClose}
      >
        <MenuItem onClick={onDuplicateClick}>
          <ListItemIcon>
            <FileCopyIcon fontSize="small" />
          </ListItemIcon>
          {translate('ra.action.duplicate')}
        </MenuItem>
        <MenuItem onClick={onEditClick}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          {translate('ra.action.edit')}
        </MenuItem>
        <MenuItem onClick={onDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          {translate('ra.action.delete')}
        </MenuItem>
      </Menu>
      <Confirm
        isOpen={isDeleteDialogOpen}
        loading={loading}
        title="Delete Quote"
        content="Are you sure you want to delete this quote?"
        onConfirm={deleteQuote}
        onClose={handleDeleteDialogClose}
      />
      <Confirm
        isOpen={isDuplicateDialogOpen}
        // loading={loading}
        title="Duplicate Quote"
        content="Are you sure you want to duplicate this quote?"
        onConfirm={duplicateQuote}
        onClose={handleDuplicateDialogClose}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  iconMore: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: 29,
    height: 33,
    borderRadius: theme.shape.borderRadius,
  },
}))

export default QuoteMoreMenu
