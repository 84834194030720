import React, { cloneElement, FC, useCallback, useState } from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconCancel from '@material-ui/icons/Cancel'
import { useTranslate, useCreate, useNotify, useRefresh, FormDataConsumer } from 'ra-core'
import {
  FormWithRedirect,
  SaveButton,
  Button as RAButton,
  FileInput,
  TextInput,
  FileField,
  required,
} from 'react-admin'
import IconButton from '@material-ui/core/IconButton'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { makeStyles } from '@material-ui/core'

type Props = {
  label?: string
  onSuccess?: (data: any) => void
  button?: React.ReactElement
  resource?: string
  getPayload?: () => Record<string, any>
} & ButtonProps

const UploadMediaButton: FC<Props> = ({
  label = 'uploadDocumentButton.label',
  onSuccess,
  button,
  resource = 'Document',
  getPayload,
  ...rest
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [create, { loading }] = useCreate(resource)
  const notify = useNotify()
  const refresh = useRefresh()

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback(
    (values: any) => {
      // values.file.title
      // values.file.rawFile.size
      create(
        {
          payload: {
            data: {
              ...values,
              ...(getPayload ? getPayload() : {}),
              description: values.description || values.file.title,
            },
          },
        },
        {
          onSuccess: ({ data }) => {
            setIsModalOpen(false)
            if (onSuccess) {
              onSuccess(data)
            } else {
              refresh()
            }
          },
          onFailure: ({ error }) => {
            notify(error.message, 'error')
          },
        }
      )
    },
    [getPayload, onSuccess]
  )

  return (
    <>
      {button ? (
        cloneElement(button, { onClick: handleModalOpen })
      ) : (
        <IconButton onClick={handleModalOpen}>
          <FaCloudUploadAlt />
        </IconButton>
      )}
      <Dialog open={isModalOpen} onClose={handleModalClose}>
        <DialogTitle>{translate(label)}</DialogTitle>
        <FormWithRedirect
          resource={resource}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }: any): React.ReactNode => (
            <>
              <DialogContent>
                <TextInput fullWidth variant="outlined" source="description" />
                <FileInput
                  multiple={false}
                  source="file"
                  label="Related files"
                  validate={required()}
                  classes={{
                    dropZone: classes.dropZone,
                  }}
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </DialogContent>
              <DialogActions>
                <RAButton label="ra.action.cancel" onClick={handleModalClose} disabled={loading}>
                  <IconCancel />
                </RAButton>
                <FormDataConsumer>
                  {({ formData }): React.ReactNode => {
                    return (
                      <SaveButton
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        pristine={pristine}
                        saving={saving}
                        disabled={loading || !formData.file}
                      />
                    )
                  }}
                </FormDataConsumer>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  dropZone: {
    padding: 50,
    border: '1px dashed rgba(0,0,0,.3)',
    borderRadius: theme.shape.borderRadius,
  },
}))

export default UploadMediaButton
