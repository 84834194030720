import React, { FC, useCallback, useState } from 'react'
import { required, TextInput } from 'react-admin'
import { Form } from 'react-final-form'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import LangWithFlagSelectInput from '../../components/input/LangWithFlagSelectInput'
import { useDataProvider, useGetList, useNotify, useRefresh } from 'ra-core'
import { GmailRichTextEditor } from '../../components/gmail/GmailRichTextEditor'

type Props = {
  gmailTemplateId: number
  resourceType?: 'quote' | 'contract' | 'brief'
}

const TemplateValueForm: FC<Props> = ({ gmailTemplateId, resourceType }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const [saving, setSaving] = useState<boolean>(false)
  const { loading, error, data } = useGetList('Language', { page: 1, perPage: 1 }, { field: 'id', order: 'ASC' }, {})

  const onSubmit = useCallback(async (values) => {
    try {
      setSaving(true)
      const result = await dataProvider.create('GmailTemplateValue', {
        data: {
          ...values,
          gmailTemplateId,
        },
      })
      // console.log('Here i am, value saved:: ', result)
      notify('ra.notify.updated')
      refresh()
    } catch (err) {
      console.error(err)
      notify((err as any).message, 'error')
    } finally {
      setSaving(false)
    }
  }, [])

  // if (data) console.log('languageeees', data)

  return !loading && data && Object.values(data).length > 0 ? (
    <Form
      onSubmit={onSubmit}
      initialValues={{ languageId: Object.values(data)[0].id }}
      render={({ handleSubmit, invalid }): React.ReactElement => (
        <form onSubmit={handleSubmit}>
          <Box display="flex" alignSelf="stretch">
            <Box display="flex" width="70%" flexDirection="column">
              <Box flexGrow={4} alignSelf="stretch" minHeight="50px">
                <TextInput source="subject" variant="outlined" />
              </Box>
              <Box flexGrow={4}>
                <Paper>
                  <GmailRichTextEditor
                    resourceType={resourceType}
                    label={false}
                    source="value._html"
                    validate={required()}
                  />
                </Paper>
              </Box>
            </Box>
            <Box ml={4} display="flex" flexDirection="column" justifyContent="space-between">
              <LangWithFlagSelectInput source="languageId" />
              <Button color="primary" variant="contained" onClick={handleSubmit} disabled={saving || invalid}>
                Add new
              </Button>
            </Box>
          </Box>
        </form>
      )}
    />
  ) : (
    <LinearProgress />
  )
}

export default TemplateValueForm
