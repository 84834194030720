import { StatusInfoLabelDefaultColors } from './components/StatusInfoLabel'

export const NORTH = 'Nord'
export const SOUTH = 'Sud'

export const OfficeAreaEnum: Record<number, string> = {
  1: NORTH,
  2: SOUTH,
}

const WAITING = 'In attesa'
const CUSTOMER_NOTIFIED = 'SMS inviato'
const DELIVERED = 'Consegnato'
const WAITING_FOR_AVAILABLE_NOTIFICATION = 'In attesa di invio SMS'
const WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION = 'In attesa di SMS di scuse'

export const ProductReservationStatusEnum: Record<string, string> = {
  WAITING: WAITING,
  WAITING_FOR_AVAILABLE_NOTIFICATION: WAITING_FOR_AVAILABLE_NOTIFICATION,
  CUSTOMER_NOTIFIED: CUSTOMER_NOTIFIED,
  DELIVERED: DELIVERED,
  WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION: WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION,
}

export const ProductReservationStatusColors: Record<string, string> = {
  WAITING: StatusInfoLabelDefaultColors.INACTIVE,
  CUSTOMER_NOTIFIED: StatusInfoLabelDefaultColors.PROGRESS,
  DELIVERED: StatusInfoLabelDefaultColors.SUCCESS,
  WAITING_FOR_AVAILABLE_NOTIFICATION: StatusInfoLabelDefaultColors.PROGRESS,
  WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION: StatusInfoLabelDefaultColors.INACTIVE,
}
