import React, { FC } from 'react'
import { NumberInput } from 'react-admin'
import FilterTextInput from '../../../components/FilterTextInput'
import Filter from '../../../components/list/filter/Filter'

type Props = {
  [x: string]: any
}

const Filters: FC<Props> = (props: Props) => (
  <Filter {...props}>
    <FilterTextInput source="name" alwaysOn />
    <NumberInput source="value" />
  </Filter>
)
export default Filters
