import React, { FC, useCallback, useState } from 'react'
import { TextInput, NumberInput, ReferenceInput, SelectInput } from 'react-admin'
import { FormWithRedirect, useGetOne, useTranslate, useDataProvider, useNotify, useRedirect, useRefresh } from 'ra-core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
// internal components
import Loading from '../../components/Loading'
import SaveButton from '../../components/button/SaveButton'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import CarbonOffsetReferenceInput from '../../components/input/CarbonOffsetReferenceInput'

const sort = { field: 'name', order: 'ASC' }
const filterToQuery = (name: string): any => ({ name })
const filterToQueryHomeBase = (icao: string): any => ({ icao })
const sortHomeBase = { field: 'icaoCode', order: 'ASC' }

type Props = {
  [x: string]: any
}

const VehicleEdit: FC<Props> = ({ id, ...props }) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [saving, setSaving] = useState<boolean>(false)
  const { loading, error, data } = useGetOne('Vehicle', id)

  const onSubmit = useCallback(
    async (values) => {
      try {
        setSaving(true)
        const result = await dataProvider.update('Vehicle', {
          id,
          data: { ...values },
          previousData: { id },
        })
        console.log('update?? ', result)
        refresh()
        redirect('/Vehicle')
        // UNABLE TO USE ra.notification.updated (smart count not working :/)
        notify('ra.notify.updated')
      } catch (error) {
        console.log('update error: ', error)
        notify('ra.message.error', 'error')
      } finally {
        setSaving(false)
      }
    },
    [data, id]
  )

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Typography color="error">{translate('ra.page.error')}</Typography>
  }

  return (
    <FormWithRedirect
      {...props}
      record={data}
      resource="Vehicle"
      save={onSubmit}
      // "saving" boolean coming out from render not working ://
      render={({ handleSubmitWithRedirect, pristine }: any): any => (
        <Box display="flex" flexDirection="column">
          <ReferenceInput
            reference="VehicleManufacturer"
            source="vehicleManufacturerId"
            sort={sort}
            filterToQuery={filterToQuery}
          >
            <AutocompleteInputInDrawer optionText="name" variant="outlined" fullWidth />
          </ReferenceInput>
          <ReferenceInput reference="VehicleModel" source="vehicleModelId" sort={sort} filterToQuery={filterToQuery}>
            <AutocompleteInputInDrawer optionText="name" variant="outlined" fullWidth />
          </ReferenceInput>
          <TextInput source="licensePlate" variant="outlined" />
          <ReferenceInput
            reference="Airport"
            source="homeBaseIcao"
            filterToQuery={filterToQueryHomeBase}
            sort={sortHomeBase}
          >
            <AutocompleteInputInDrawer optionText="id" variant="outlined" fullWidth />
          </ReferenceInput>
          <NumberInput source="numberOfSeats" variant="outlined" />
          <NumberInput source="yearOfManufacture" variant="outlined" />
          <NumberInput source="yearOfRestyling" variant="outlined" />
          <ReferenceInput reference="VehicleCategory" source="vehicleCategoryId" sort={sort}>
            <SelectInput optionText="name" variant="outlined" />
          </ReferenceInput>
          <ReferenceInput reference="VehicleSafetyType" source="vehicleSafetyTypeId" sort={sort}>
            <SelectInput optionText="name" variant="outlined" />
          </ReferenceInput>
          <CarbonOffsetReferenceInput />
          {/* TODO: added image gallery here */}
          <Box mt={3}>
            <SaveButton
              size="large"
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              pristine={pristine}
              saving={saving}
              disabled={pristine || saving}
            />
          </Box>
        </Box>
      )}
    />
  )
}

export default VehicleEdit
