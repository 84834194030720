import * as React from 'react'
import { cloneElement, useMemo, FC, ReactElement } from 'react'
import {
  sanitizeListRestProps,
  Identifier,
  SortPayload,
  Exporter,
  useListContext,
  useResourceContext,
  useResourceDefinition,
} from 'ra-core'
import { makeStyles, ToolbarProps } from '@material-ui/core'
import CreateButton from '../button/CreateButton'
import Box from '@material-ui/core/Box'
import ExportButton from '../button/ExportButtont'
import clsx from 'clsx'

export type ListActionsProps = ToolbarProps & {
  currentSort?: SortPayload
  className?: string
  resource?: string
  filters?: ReactElement<any>
  displayedFilters?: any
  exporter?: Exporter | boolean
  filterValues?: any
  permanentFilter?: any
  hasCreate?: boolean
  basePath?: string
  selectedIds?: Identifier[]
  onUnselectItems?: () => void
  showFilter?: (filterName: string, defaultValue: any) => void
  total?: number
}

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      [theme.breakpoints.down('md')]: {
        padding: '0 !important',
      },
    },
  }),
  { name: 'RaListActions' }
)

const ListActions: FC<ListActionsProps> = (props) => {
  const { className, exporter, filters, ...rest } = props
  const { currentSort, displayedFilters, filterValues, basePath, selectedIds, showFilter, total } = useListContext(
    props
  )
  const classes = useStyles()

  const resource = useResourceContext(rest)
  const { hasCreate } = useResourceDefinition(rest)
  return useMemo(
    () => (
      <Box display="flex" className={clsx(className, classes.wrapper)} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        {hasCreate && <CreateButton basePath={basePath} />}
        {exporter !== false && (
          <Box pl={2}>
            <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} />
          </Box>
        )}
      </Box>
    ),
    [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
  )
}

export default ListActions
