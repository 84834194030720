import { fade, makeStyles } from '@material-ui/core/styles'

export const useCalendarStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // paddingLeft: theme.spacing(2),
  },
  draggableListContainer: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  container: {
    height: 'calc(100vh - 64px)',
  },
  calendarSectionContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    transition: theme.transitions.create('width'),
  },
  calendarFastEventTemplatesContainer: (props: any): any => ({
    // height: '100%',
    overflow: 'auto',
    // width: !props.fastEventsOpen ? 0 : undefined,
    flexBasis: !props.fastEventsOpen ? 0 : undefined,
    opacity: !props.fastEventsOpen ? 0 : undefined,
    marginLeft: !props.fastEventsOpen ? '-10px' : undefined,
    // marginRight: !props.fastEventsOpen ? '-8px' : undefined,
    transition: theme.transitions.create('flex-basis', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  eventsListContainer: (props: any): any => ({
    height: '100%',
    overflow: 'auto',
    flexBasis: !props.eventsListOpen ? 0 : undefined,
    opacity: !props.eventsListOpen ? 0 : undefined,
    marginRight: !props.eventsListOpen ? '-10px' : undefined,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  calendarContainer: {
    height: '100%',
    paddingRight: theme.spacing(1),
    position: 'relative',
  },
  eventBadge: {
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    display: 'block',
    width: 9,
    height: 9,
    minWidth: 9,
    marginTop: 2,
    // marginRight: theme.spacing(1),
    marginBottom: 1,
    borderRadius: '50%',
    content: '""',
    border: '1px solid white',
  },
  fcResource: {
    overflow: 'hidden',
  },
  fcCalendarDayHeader: {
    borderBottom: `1px solid ${theme.palette.divider} !important`,
  },
  resourceTeamMemberLabelContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resourceCustomerAvatar: {
    // paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  resourceCustomerLabel: {
    textAlign: 'center',
    padding: '0px 4px',
    textOverflow: 'ellipsis',
  },
  resourceVehicleLabelContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dayHeaderContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  dayOfWeekText: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.caption.fontSize,
  },
  dayIconButton: {
    width: 36,
    height: 36,
    marginTop: theme.spacing(0.5),
  },
  dayIconText: {
    fontSize: 18,
  },
  activeDayLabel: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  hourSlotLabelContent: {
    position: 'relative',
    width: theme.spacing(2) + 25,
    height: '1.5rem',
  },
  hourSlotLabel: {
    position: 'absolute',
    top: '-0.75rem',
    fontSize: '14px',
    lineHeight: '1.5rem',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  fcSlotLabel: {
    borderBottom: '1px solid transparent !important',
  },
  fcSlotLane: {
    // borderBottom: '1px solid transparent !important',
  },
  fcBgEvent: {
    overflow: 'hidden',
    opacity: '0.6 !important',
    borderRadius: theme.shape.borderRadius,
  },
  fcEvent: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    cursor: 'pointer',
    boxShadow: theme.shadows[4],
  },
  backgroundEventContent: {
    fontSize: '0.765rem',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
  eventContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  eventTopContent: {
    display: 'flex',
    alignItems: 'center',
  },
  eventTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    // fontSize: '0.730rem',
  },
  eventTimeText: {
    fontSize: '0.650rem',
    lineHeight: '1rem',
    marginLeft: theme.spacing(0.5),
  },
  eventTopContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  '@media print': {
    calendarFastEventTemplatesContainer: {
      display: 'none',
    },
    resourceCustomerAvatar: {
      display: 'none',
    },
    eventBadge: {
      display: 'none',
    },
  },
  backdrop: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.2)',
    color: '#fff',
  },
}))
