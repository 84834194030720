import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useTranslate } from 'ra-core'

export enum CalendarViewModes {
  WEEK = 'timeGridWeek',
  DAY = 'timeGrid',
}

export type CalendarViewModeMenuProps = {
  onViewModeChange: (mode: CalendarViewModes) => void
  viewMode: string
  calendarRef?: any // maybe useless
}

export const isValidCalendarViewMode = (value: string): boolean => {
  return Object.values(CalendarViewModes).includes(value as any)
}

const CalendarViewModeMenu: React.FC<CalendarViewModeMenuProps> = ({ onViewModeChange, viewMode }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [open, setOpen] = React.useState<boolean>(false)

  const handleChange = (event: any): void => {
    onViewModeChange(event.target.value)
  }

  const handleClose = (): void => setOpen(false)

  const handleOpen = (): void => setOpen(true)

  return (
    <>
      <FormControl className={classes.formControl}>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={handleChange}
          variant="outlined"
          value={viewMode}
          classes={{ root: classes.select }}
        >
          {Object.values(CalendarViewModes).map((value: string) => (
            <MenuItem value={value}>
              {translate(`Calendar.viewModes.${value}`)}
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  select: {
    padding: theme.spacing(2),
  },
}))

export default CalendarViewModeMenu
