import React, { FC } from 'react'
import { useFormState } from 'react-final-form'
import { ReferenceInput, ArrayInput, SimpleFormIterator, AutocompleteInput, NumberInput, required } from 'react-admin'
import { useGetOne, useNotify, useRedirect, useTranslate } from 'ra-core'
import RichTextInput from 'ra-input-rich-text'
import Edit from '../../components/details/Edit'
import FormSection from '../../components/forms/FormSection'
import WizardSectionedForm, { WizardStep } from '../../components/forms/WizardSectionedForm'
import { CircularProgress, makeStyles, useTheme } from '@material-ui/core'
import WizardRightControls from '../../components/forms/WizardSectionedForm/WizardRightControls'
import IconButton from '@material-ui/core/IconButton'
import SaveButton from '../../components/button/SaveButton'
import FormSelectInput from '../../components/input/FormSelectInput'
import FilteredPersonAutocompleteInput from '../../components/input/FilteredPersonAutocompleteInput'
import RoutesSummary from './FormRoutesSummary'
import OptionsSummary from './OptionsSummary'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import FormAutoCompleteInput from '../../components/FormAutoCompleteInput'
import AddOptionButtonDrawer from './AddOptionButtonDrawer'
import AddRouteButtons from './AddRouteButtons'
import { useMutation } from '@apollo/client'
import { MUTATION_UPDATE_CIELO_QUOTE } from '../../queries'
import NoTimezoneDateTimeInput from '../../components/input/NoTimezoneDateTimeInput'

type Props = {
  [x: string]: any
}

const filterToQueryQ = (q: string): Record<string, string> => ({ q })
const airportOptionText = (record: any): string =>
  `${record.iataCode ? `${record.iataCode} (${record.icaoCode})` : record.icaoCode} - ${record.name}`

const SaveQuote: FC<any> = (props) => {
  const { values } = useFormState()
  const notify = useNotify()
  const redirect = useRedirect()

  const [updateCieloQuote, { loading, data, error }] = useMutation(MUTATION_UPDATE_CIELO_QUOTE)
  // const dataProvider = useDataProvider()

  const handleSave = async (): Promise<void> => {
    try {
      const printableNotes = values.printableNotes || { _html: null }
      const result = await updateCieloQuote({
        variables: {
          id: values.id,
          data: {
            organizationId: values.organizationId,
            customerId: values.customerId,
            personId: values.personId,
            teamMemberId: values.teamMemberId,
            languageId: values.languageId,
            notes: values.notes,
            printTemplateId: values.printTemplateId,
            currencyId: values.currencyId,
            printableNotes,
            routes: values.routes.map((item: any, index: number) => {
              return {
                id: item.id,
                departureAirportId: item.departureAirportId,
                arrivalAirportId: item.arrivalAirportId,
                flightDate: item.flightDate,
                arrivalDate: item.arrivalDate,
                flightDuration: item.flightDuration,
                passengersQuantity: item.passengersQuantity,
              }
            }),
            options: values.options.map((item: any, index: number) => {
              return {
                id: item.id && typeof item.id === 'string' ? item.id : undefined,
                vehicleId: item.vehicleId,
                purchasePrice: item.purchasePrice,
                transferCost: item.transferCost,
                sellingPrice: item.sellingPrice,
                commissions: item.commissions,
                description: item.description || null,
                showYomYor: item.showYomYor,
                taxId: item.taxId,
                excludeVatFromFinalPrice: item.excludeVatFromFinalPrice,
                sellingPriceWithoutTaxes: item.sellingPriceWithoutTaxes,
                passengerTax: item.passengerTax,
              }
            }),
          },
        },
      })

      if (result) {
        notify('ra.notify.updated')
        redirect(`/Quote/${values.id}/show`)
      } else {
        throw new Error('Quote id not found')
      }
    } catch (error) {
      console.log('create error: ', error)
      notify('Error updating Quote', 'error')
    }
  }

  return (
    <SaveButton
      {...props}
      variant="contained"
      saving={loading}
      disabled={loading}
      handleSubmitWithRedirect={handleSave}
    />
  )
}

const QuoteEdit: FC<Props> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const theme = useTheme()
  // const isSmMediaQuery = useMediaQuery(theme.breakpoints.up('sm'))

  const { loading, data } = useGetOne('Quote', props.id)

  return (
    <Edit {...props} component="div">
      <WizardSectionedForm variant="outlined" redirect="list" title={translate('resources.Quote.titles.edit')}>
        <WizardStep>
          <FormSection title={translate('resources.Quote.titles.accountManager')}>
            <ReferenceInput source="organizationId" reference="Organization">
              <FormSelectInput optionText="name" fullWidth />
            </ReferenceInput>
            <ReferenceInput source="teamMemberId" reference="TeamMember">
              <FormSelectInput optionText="fullName" fullWidth />
            </ReferenceInput>
          </FormSection>
          <FormSection title={translate('resources.Quote.titles.routing')}>
            <ArrayInput source="routes" label={false} validate={required()}>
              <SimpleFormIterator
                classes={{ form: classes.form, line: classes.line }}
                removeButton={
                  <IconButton classes={{ root: classes.removeButton }}>
                    <DeleteForeverIcon />
                  </IconButton>
                }
                addButton={<AddRouteButtons />}
              >
                <ReferenceInput
                  reference="Airport"
                  source="departureAirportId"
                  filterToQuery={filterToQueryQ}
                  label={translate('resources.Route.fields.departure')}
                  validate={required()}
                  className={classes.airportInput}
                >
                  <AutocompleteInput optionText={airportOptionText} />
                </ReferenceInput>
                <ReferenceInput
                  reference="Airport"
                  source="arrivalAirportId"
                  filterToQuery={filterToQueryQ}
                  label={translate('resources.Route.fields.arrival')}
                  validate={required()}
                  className={classes.airportInput}
                >
                  <AutocompleteInput optionText={airportOptionText} />
                </ReferenceInput>
                {/* <DateTimeInput
                  source="flightDate"
                  format={formatDateTime}
                  parse={parseDateTime}
                  classes={{ root: classes.dateTimeInput }}
                  label={translate('resources.Route.fields.flightDate')}
                /> */}
                <NoTimezoneDateTimeInput
                  source="flightDate"
                  classes={{ root: classes.dateTimeInput }}
                  label={translate('resources.Route.fields.flightDate')}
                />
                <NumberInput
                  source="flightDuration"
                  label={translate('resources.Route.fields.flightDuration')}
                  classes={{ root: classes.passengersInput }}
                />
                <NumberInput
                  source="passengersQuantity"
                  label={translate('resources.Route.fields.passengers')}
                  initialValue={1}
                  classes={{ root: classes.passengersInput }}
                />
                {/* <DateTimeInput
                  source="arrivalDate"
                  classes={{ root: classes.dateTimeInput }}
                  label={translate('resources.Route.fields.arrivalDate')}
                /> */}
              </SimpleFormIterator>
            </ArrayInput>
          </FormSection>
          <FormSection title={translate('resources.Quote.titles.clientAndLanguage')}>
            <ReferenceInput source="customerId" reference="Customer" validate={required()}>
              <FormAutoCompleteInput optionText="fullName" fullWidth />
            </ReferenceInput>
            <ReferenceInput source="languageId" reference="Language">
              <FormSelectInput optionText="name" fullWidth />
            </ReferenceInput>
            <ReferenceInput source="currencyId" reference="Currency" filters={{ active: true }}>
              <FormSelectInput optionText="name" fullWidth />
            </ReferenceInput>
            {loading ? (
              <CircularProgress />
            ) : (
              <FilteredPersonAutocompleteInput isEdit initialCustomerId={data?.customerId} />
            )}
          </FormSection>
          <FormSection title="Printable notes">
            <RichTextInput fullWidth label={false} source="printableNotes._html" />
          </FormSection>
        </WizardStep>
        <WizardStep
          // {...props}
          formRightControls={
            <WizardRightControls
              saveButtonComponent={<SaveQuote />}
              asideLowerContent={<AddOptionButtonDrawer {...props} />}
            />
          }
        >
          <RoutesSummary />
          <OptionsSummary isEdit />
        </WizardStep>
      </WizardSectionedForm>
    </Edit>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexWrap: 'wrap',
    marginTop: 0,
    marginRight: theme.spacing(5),
    '@media (max-width: 960px)': {
      marginRight: 0,
    },
    '@media (max-width: 600px)': {
      alignItems: 'flex-start',
      alignContent: 'flex-start',
    },
  },
  passengersInput: {
    maxWidth: 100,
  },
  dateTimeInput: {
    width: 'auto',
    display: 'flex',
  },
  line: {
    display: 'flex',
    alignItems: 'stretch',
    alignContent: 'center',
    borderBottom: 0,
    '@media (max-width: 960px)': {
      borderBottom: '1px solid gray',
      marginBottom: theme.spacing(4),
    },
    '@media (max-width: 600px)': {
      alignContent: 'stretch',
    },
  },
  removeButton: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
    padding: 7,
    marginLeft: theme.spacing(5),
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  airportInput: {
    [theme.breakpoints.down('md')]: {
      minWidth: 250,
    },
  },
}))

export default QuoteEdit
