import React, { FC, useCallback, useState } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslate } from 'ra-core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { Box, makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  iconMore: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: 29,
    height: 33,
    borderRadius: theme.shape.borderRadius,
    left: '-6px',
  },
}))

type Props = {
  logout: any
  classes?: { button?: string; menu?: string }
}

const AccountMoreMenu: FC<Props> = (props) => {
  const { logout } = props
  const ovverideClasses = props.classes
  const classes = useStyles()
  const translate = useTranslate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  // console.log("sono logout", logout)

  return (
    <>
      <IconButton className={classNames(classes.iconMore, ovverideClasses?.button)} edge="start" onClick={onMoreClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={'more-account'}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMoreClose}
        className={classNames(ovverideClasses?.menu)}
      >
        {logout}
        {/* <MenuItem button onClick={logout}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          
          Logout
        </MenuItem> */}
      </Menu>
    </>
  )
}

export default AccountMoreMenu
