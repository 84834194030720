import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/details/Create'
import StateForm from './StateForm'

type Props = {}

const StateCreate: FC<Props> = (props) => (
  <Create {...props} component="div">
    <SectionedForm redirect="list">
      <StateForm {...props} />
    </SectionedForm>
  </Create>
)

export default StateCreate
