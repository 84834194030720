import React, { FC } from 'react'
import { EditButtonProps } from 'ra-ui-materialui/lib/button/EditButton'
import { EditButton as RAEditButton } from 'ra-ui-materialui'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  button: {
    minHeight: 45,
    textTransform: 'none',
    fontSize: 17,
    minWidth: 116,
  },
  label: {
    paddingLeft: 0,
  },
}))

type Props = EditButtonProps

const EditButton: FC<Props> = (props) => {
  const classes = useStyles(props)

  return <RAEditButton icon={<React.Fragment />} classes={classes} color="default" variant="outlined" {...props} />
}

export default EditButton
