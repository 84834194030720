import { EventContentArg } from '@fullcalendar/react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { ReferenceField, TextField } from 'react-admin'
import React, { FC } from 'react'
import { FaPlane, FaUserTie } from 'react-icons/fa'
import { format, Locale } from 'date-fns'
import { makeStyles } from '@material-ui/core'
import { useGetOne } from 'ra-core'
import ClientNameField from '../field/ClientNameField'

type Props = {
  calendarEvent: any
  locale: Locale
}

const FlightEvent: FC<Props> = ({ calendarEvent, locale }: Props) => {
  const classes = useStyles()

  const { extendedProps, start, end } = calendarEvent.event

  const { loading, error, data: orderData } = useGetOne('Order', extendedProps?.orderId, {
    enabled: extendedProps?.orderId,
  })

  return (
    <>
      <Box display="flex" alignItems="center" px={1} py={0}>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <ReferenceField
            record={extendedProps}
            reference="Airport"
            source="departureAirportId"
            basePath="/Calendar"
            link={false}
          >
            <TextField source="iataCode" variant="h5" className={classes.airportCode} />
          </ReferenceField>
          {start && (
            <Typography variant="body2" className={classes.time}>
              {format(start as Date, 'p', { locale })}
            </Typography>
          )}
        </Box>
        <FaPlane size={20} className={classes.icon} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <ReferenceField
            record={extendedProps}
            reference="Airport"
            source="arrivalAirportId"
            basePath="/Calendar"
            link={false}
          >
            <TextField source="iataCode" variant="h5" className={classes.airportCode} />
          </ReferenceField>
          {end && (
            <Typography variant="body2" className={classes.time}>
              {format(end as Date, 'p', { locale })}
            </Typography>
          )}
        </Box>
      </Box>
      {orderData && (
        <Box display="flex" alignItems="center">
          <FaUserTie size={10} className={classes.userIcon} />
          <ReferenceField record={orderData} source="customerId" reference="Customer" link={false} basePath="/Customer">
            <ClientNameField className={classes.time} />
          </ReferenceField>
        </Box>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: 'black',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  userIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  airportCode: {
    fontWeight: 'bold',
    color: 'black',
    lineHeight: 1.1,
  },
  time: {
    fontSize: '10px',
    textAlign: 'center',
    color: 'black',
  },
}))

export default FlightEvent
