import React, { FC, useCallback } from 'react'
import { InputProps, useInput } from 'ra-core'
import { useFormState } from 'react-final-form'
import MuiPercentageInput from '../MuiPercentageInput'

type Props = {
  //
} & InputProps

export const PercentageFinalFormInput: FC<Props> = (props) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput({ ...props })

  const handleChange = useCallback(
    (event: any, rawValue: number) => {
      onChange(rawValue)
    },
    [onChange]
  )

  return <MuiPercentageInput {...rest} {...props} name={name} onChange={onChange} />
}
