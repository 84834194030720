import React from 'react'
import { useInput, InputProps } from 'ra-core'
import Select from 'react-select'

type MultiSelectField = InputProps & {
  placeholder: string
  options: Array<{ id: string; name: string }>
}

export function MultiSelectField(props: any) {
  const {
    input: { name, onChange, value },
  } = useInput({
    ...props,
  })

  return (
    <Select
      isMulti
      name={name}
      options={props.options}
      components={{
        IndicatorSeparator: () => null,
      }}
      placeholder={props.placeholder}
      getOptionLabel={(option) => option.name || ''}
      getOptionValue={(option) => option.id || ''}
      value={value}
      onChange={(ev) => onChange(ev)}
      isClearable={false}
    />
  )
}
