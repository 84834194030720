import InputAdornment from '@material-ui/core/InputAdornment'
import React, { FC } from 'react'
import { NumberInput } from 'react-admin'
import FilterTextInput from '../../components/FilterTextInput'
import Filter from '../../components/list/filter/Filter'

type Props = {
  [x: string]: any
}

const TaxFilters: FC<Props> = (props) => (
  <Filter {...props}>
    <FilterTextInput source="name" alwaysOn />
    <NumberInput source="rate" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
    {/* <NullableBooleanInput source="active" alwaysOn /> */}
  </Filter>
)
export default TaxFilters
