import React, { FC, useState, useCallback, useEffect } from 'react'
import { useDelete, useRefresh, useNotify } from 'ra-core'
import { Confirm } from 'react-admin'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DeleteIcon from '@material-ui/icons/Delete'

type Props = {
  id: string
  onMoreClose: (event: any) => any
}

const DeleteOrderRoute: FC<Props> = ({ id, onMoreClose }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const [deleteRoute, { loading: loadingDelete, error: errorDelete, data: deleteData }] = useDelete('OrderRoute', id)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const handleDialogClose = useCallback((): void => setIsDialogOpen(false), [])
  const handleDialogOpen = useCallback((event: any): void => {
    onMoreClose(event)
    setIsDialogOpen(true)
  }, [])

  useEffect(() => {
    if (deleteData && isDialogOpen) {
      refresh()
      notify('ra.notify.updated')
    }
  }, [deleteData, isDialogOpen])

  return (
    <>
      <MenuItem onClick={handleDialogOpen}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        {'Delete route'}
      </MenuItem>
      <Confirm
        isOpen={isDialogOpen}
        loading={loadingDelete}
        title="Delete Route"
        content="Are you sure you want to delete the specified route?"
        onConfirm={deleteRoute}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default DeleteOrderRoute
