import CircularProgress from '@material-ui/core/CircularProgress'
import { useGetList } from 'ra-core'
import React, { FC, useMemo } from 'react'
import { NumberField } from 'react-admin'
import ErrorIcon from '@material-ui/icons/Error'
import { ORDER_STATUS } from './OrderStatusDropDown'

type Props = {
  customerId: number | string
  statuses?: ORDER_STATUS[]
  fields?: string[]
}

const ClientTotalPrices: FC<Props> = ({ customerId, statuses, fields }) => {
  const { loading, error, data } = useGetList(
    'Order',
    { page: 1, perPage: 10000 },
    { field: 'id', order: 'DESC' },
    { customerId, statuses },
    { enabled: !!customerId }
  )

  const record: Record<string, number | null> = { totals: null }

  record.totals = useMemo((): number | null => {
    if (data && fields) {
      const ordersArray = Object.values(data)
      return ordersArray.reduce((acc: number, current: any) => {
        let sum = acc
        fields.forEach((fld: string) => {
          if (!isNaN(current[fld])) return (sum += current[fld])
        })
        return sum
      }, 0)
    } else return null
  }, [data])

  if (loading) return <CircularProgress size={20} />
  if (error) return <ErrorIcon color="error" />
  if (record.totals || record.totals === 0)
    return (
      <NumberField
        source="totals"
        record={record}
        // locales={defaultLocale}
        options={{ style: 'currency', currency: 'EUR' }}
      />
    )
  return null
}

export default ClientTotalPrices
