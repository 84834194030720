import React, { FC, useCallback, useEffect, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import { useMutation } from '@apollo/client'
import { useTranslate, useShowController, useNotify, useRedirect, useRefresh } from 'ra-core'
import { ReferenceField, TextField, DateField, Confirm, ChipField } from 'react-admin'
import { InfoCard, InfoCardItem, InfoCardWrapper, InfoCardTypography, InfoCardAvatar } from '../../components/InfoCard'
import ShowView from '../../components/details/ShowView'
import { FaClock, FaLeaf, FaPhone, FaRegBuilding, FaRegEnvelope, FaUserTie } from 'react-icons/fa'
import FullQuoteField from '../../components/field/FullRouteField'
import OptionField from './OptionField'
import { GrStatusInfo } from 'react-icons/gr'
import { CgNotes } from 'react-icons/cg'
import QuoteStatusDropDown, { QUOTE_STATUS } from '../../components/QuoteStatusDropDown'
import ClientNameField from '../../components/field/ClientNameField'
import {
  MUTATION_CONFIRM_QUOTE_OPTION,
  MUTATION_GENERATE_CIELO_QUOTE_PRINT,
  MUTATION_UPDATE_QUOTE_STATUS,
} from '../../queries'
import TabPanel from '../../components/TabPanel'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { RichTextField } from 'ra-ui-materialui'
import { DownloadServerGeneratedFileButton } from '../../components/button/DownloadServerGeneratedFileButton'
import ToggleStatusButton from '../../components/button/ToggleStatusButton'
import { CreateMailDrawerButton } from '../../components/gmail/CreateMailDrawerButton'
import { QuoteActivities } from '../../components/Activities/QuoteActivities'
import { Center, Circle, Text, Heading, HStack } from '@chakra-ui/react'

const LeftSide = (props: any): React.ReactElement => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Box pr={3}>
      <InfoCardWrapper>
        <InfoCard>
          <InfoCardItem direction="row" alignItems="center">
            <InfoCardAvatar>
              <FaRegBuilding size={20} />
            </InfoCardAvatar>
            <Box display="flex" flexDirection="column" justifySelf="flex-start">
              <InfoCardTypography variant="label">{`${translate('resources.Customer.name', {
                smart_count: 1,
              })}:`}</InfoCardTypography>
              <InfoCardTypography variant="value">
                <ReferenceField {...props} label={false} source="customerId" reference="Customer" link="show">
                  <ClientNameField />
                </ReferenceField>
              </InfoCardTypography>
              <ReferenceField {...props} label={false} source="customerId" reference="Customer" link={false}>
                <ChipField size="small" color="primary" source="customerStatus" />
              </ReferenceField>
            </Box>
          </InfoCardItem>
          <InfoCardItem direction="row" alignItems="flex-start">
            <InfoCardAvatar>
              <FaClock size={20} />
            </InfoCardAvatar>
            <Box display="flex" flexDirection="column" justifySelf="flex-start">
              <InfoCardTypography variant="label">{`${translate(
                'resources.Quote.fields.createdAt'
              )}:`}</InfoCardTypography>
              <InfoCardTypography variant="value">
                <DateField {...props} source="createdAt" />
              </InfoCardTypography>
            </Box>
          </InfoCardItem>
          <InfoCardItem direction="row" alignItems="flex-start">
            <InfoCardAvatar>
              <GrStatusInfo size={20} />
            </InfoCardAvatar>
            <Box display="flex" flexDirection="column" justifySelf="flex-start">
              <InfoCardTypography variant="value">{`${translate(
                'resources.Quote.fields.status'
              )}:`}</InfoCardTypography>
              <InfoCardTypography variant="value">
                <QuoteStatusDropDown currentStatus={props.record.status} id={props.record.id} />
              </InfoCardTypography>
            </Box>
          </InfoCardItem>
          <InfoCardItem direction="row" alignItems="center">
            <InfoCardAvatar>
              <FaLeaf size={20} />
            </InfoCardAvatar>
            <Box display="flex" justifySelf="flex-start" alignItems="center">
              <InfoCardTypography variant="value">{'Carbon offset'}</InfoCardTypography>
              <Box mr={2} />
              <ToggleStatusButton source="showCarbonOffset" record={props.record} resource="Quote" noRefresh />
            </Box>
          </InfoCardItem>
          <InfoCardItem direction="row" alignItems="center">
            <InfoCardAvatar>
              <CgNotes size={20} />
            </InfoCardAvatar>
            <Box display="flex" justifySelf="flex-start" alignItems="center">
              <InfoCardTypography variant="value">{'Printable notes'}</InfoCardTypography>
              <Box mr={2} />
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={
                  props.record.printableNotes?._html ? (
                    <RichTextField record={props.record.printableNotes} source="_html" />
                  ) : (
                    <Typography variant="body2">No notes found</Typography>
                  )
                }
              >
                <VisibilityIcon color={props.record.printableNotes?._html ? 'primary' : 'secondary'} />
              </Tooltip>
            </Box>
          </InfoCardItem>
        </InfoCard>
        <Box mt={4} />
        {props.record.personId && (
          <InfoCard>
            <InfoCardItem direction="column" alignItems="flex-start">
              <Box display="flex">
                <InfoCardAvatar>
                  <FaUserTie size={20} />
                </InfoCardAvatar>
                <div>
                  <InfoCardTypography variant="title">
                    <ReferenceField {...props} label={false} source="personId" reference="Person" link={false}>
                      <TextField source="fullName" variant="h3" className={classes.bold} />
                    </ReferenceField>
                  </InfoCardTypography>
                  <InfoCardTypography variant="label">
                    <ReferenceField {...props} label={false} source="personId" reference="Person" link={false}>
                      <TextField source="position" />
                    </ReferenceField>
                  </InfoCardTypography>
                </div>
              </Box>
            </InfoCardItem>
            <InfoCardItem direction="row" alignItems="flex-start">
              <InfoCardAvatar>
                <FaRegEnvelope size={20} />
              </InfoCardAvatar>
              <Box display="flex" flexDirection="column" justifySelf="flex-start">
                <InfoCardTypography variant="label">{`${translate(
                  'resources.Quote.fields.personsEmail'
                )}:`}</InfoCardTypography>
                <InfoCardTypography variant="value">
                  <ReferenceField {...props} label={false} source="personId" reference="Person" link={false}>
                    <TextField source="email" />
                  </ReferenceField>
                </InfoCardTypography>
              </Box>
            </InfoCardItem>
            <InfoCardItem direction="row" alignItems="flex-start">
              <InfoCardAvatar>
                <FaPhone size={20} />
              </InfoCardAvatar>
              <Box display="flex" flexDirection="column" justifySelf="flex-start">
                <InfoCardTypography variant="label">{`${translate(
                  'resources.Quote.fields.personsPhone'
                )}:`}</InfoCardTypography>
                <InfoCardTypography variant="value">
                  <ReferenceField {...props} label={false} source="personId" reference="Person" link={false}>
                    <TextField source="phone" />
                  </ReferenceField>
                </InfoCardTypography>
              </Box>
            </InfoCardItem>
          </InfoCard>
        )}
        <Box mb={2} />
      </InfoCardWrapper>
    </Box>
  )
}

const RightSide = ({ onConfirmClick, ...props }: any): React.ReactElement => {
  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" flexGrow={1}>
      {props.record.routes &&
        props.record.routes.map((route: any, index: number) => {
          return <FullQuoteField {...route} key={`route-${route.id}`} index={++index} />
        })}
      <Box mt={4} />
      {props.record.options
        .filter((option: any) => !!option.confirmedOption)
        .map((option: any, index: number) => (
          <OptionField
            {...option}
            key={`option-${option.id}`}
            index={index}
            onConfirm={onConfirmClick}
            isQuoteConfirmed={props.record.status === QUOTE_STATUS.confirmed}
            currencyId={props.record.currencyId}
            fullQuote={props.record}
          />
        ))}
      {props.record.options
        .filter((option: any) => !option.confirmedOption)
        .sort((a: any, b: any) => a?.sellingPrice - b?.sellingPrice)
        .map((option: any, index: number) => (
          <OptionField
            {...option}
            key={`option-${option.id}`}
            index={index}
            onConfirm={onConfirmClick}
            isQuoteConfirmed={props.record.status === QUOTE_STATUS.confirmed}
            currencyId={props.record.currencyId}
            fullQuote={props.record}
          />
        ))}
    </Box>
  )
}

const QuoteShowComp = (props: any): any => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const [selectedOptionId, setSelectedOptionId] = useState<number | undefined>(undefined)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)

  const [confirmQuoteOption, { loading, error, data }] = useMutation(MUTATION_CONFIRM_QUOTE_OPTION)

  const theme = useTheme()
  const [currentTab, setCurrentTab] = useState<number>(0)
  // isDesktop will be true if width > 1280px - real-time updated
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const handleTabChange = useCallback((tabIndex: number) => (): void => setCurrentTab(tabIndex), [])

  const onConfirm = useCallback(
    (): Promise<any> => confirmQuoteOption({ variables: { quoteOptionId: selectedOptionId } }),
    [selectedOptionId]
  )

  const onConfirmClick = useCallback(
    (id: number) => (): void => {
      if (props.record.status !== QUOTE_STATUS.confirmed) {
        setSelectedOptionId(id)
        setIsConfirmDialogOpen(true)
      }
    },
    [props.record.status]
  )

  const handleConfirmDialogClose = useCallback((): void => setIsConfirmDialogOpen(false), [])

  useEffect(() => {
    if (data && data.confirmQuoteOption && data.confirmQuoteOption.id) {
      handleConfirmDialogClose()
      redirect(`/Order/${data.confirmQuoteOption.id}/show`)
      // refresh()
      // notify(translate('resources.Quote.messages.confirmQuoteOptionSuccess'), 'info')
    }
  }, [data])

  useEffect(() => {
    if (error) {
      handleConfirmDialogClose()
      notify(error.message, 'error')
    }
  }, [error])

  return (
    <>
      {isDesktop ? (
        <Box display="flex" position="relative">
          <LeftSide {...props} />
          <Box display="flex" flexDirection="column" alignItems="stretch" flexGrow={1}>
            <RightSide {...props} onConfirmClick={onConfirmClick} />
            <QuoteActivities record={props.record} />
          </Box>
        </Box>
      ) : (
        <>
          <Box mb={2} display="flex" position="sticky" top={10}>
            <Button
              onClick={handleTabChange(0)}
              variant="contained"
              color={currentTab === 0 ? 'primary' : undefined}
              classes={{ label: classes.fontNormal }}
            >
              Info
            </Button>
            <Box ml={2} />
            <Button
              onClick={handleTabChange(1)}
              variant="contained"
              color={currentTab === 1 ? 'primary' : undefined}
              classes={{ label: classes.fontNormal }}
            >
              Routes
            </Button>
            <Button
              onClick={handleTabChange(2)}
              variant="contained"
              color={currentTab === 2 ? 'primary' : undefined}
              classes={{ label: classes.fontNormal }}
            >
              Activities
            </Button>
          </Box>
          <TabPanel value={currentTab} index={0}>
            <LeftSide {...props} />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <RightSide {...props} onConfirmClick={onConfirmClick} />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <QuoteActivities record={props.record} />
          </TabPanel>
        </>
      )}
      <Confirm
        isOpen={isConfirmDialogOpen}
        title={translate('resources.Quote.titles.confirmOption')}
        content={`${translate('resources.Quote.titles.confirmOptionContent')} (id: ${selectedOptionId})`}
        onConfirm={onConfirm}
        onClose={handleConfirmDialogClose}
        loading={loading}
      />
    </>
  )
}

const QuoteShow: FC<any> = (props) => {
  const classes = useStyles()
  const showProps = useShowController(props)
  const translate = useTranslate()
  const redirect = useRedirect()
  const refresh = useRefresh()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isChangeQuoteStatusDialogOpen, setChangeQuoteStatusDialogOpen] = useState<boolean>(false)
  const handleChangeQuoteStatusDialogClose = useCallback((): void => setChangeQuoteStatusDialogOpen(false), [])
  const [updateQuoteStatus, { loading, error, data }] = useMutation(MUTATION_UPDATE_QUOTE_STATUS, {
    variables: {
      id: props.id,
      status: 'sent',
    },
    onCompleted: () => {
      setChangeQuoteStatusDialogOpen(false)
      refresh()
    },
  })

  const handleMailSent = useCallback(() => {
    if (showProps.loading || !showProps.record) {
      return
    }

    const currentStatus = showProps.record?.status

    if (currentStatus === 'pending') {
      return updateQuoteStatus()
    }
  }, [showProps?.loading, showProps?.record])

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])
  const onMoreClose = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const goToOrder = useCallback(
    (event: any) => {
      onMoreClose(event)
      redirect(`/Order/${showProps?.record?.orderId}/show`)
    },
    [showProps?.record?.orderId]
  )

  const goToEdit = useCallback(
    (event: any) => {
      onMoreClose(event)
      redirect(`/Quote/${showProps?.record?.id}/1`)
    },
    [showProps?.record]
  )

  const handleDownloadCompleted = useCallback(() => {
    if (showProps.loading || !showProps.record) {
      return
    }

    const currentStatus = showProps.record?.status

    if (currentStatus === 'pending') {
      setChangeQuoteStatusDialogOpen(true)
    }
  }, [showProps])

  return !showProps.loading && showProps.record ? (
    <>
      <ShowView
        {...showProps}
        {...props}
        title={
          showProps.record
            ? `Quote #${showProps.record.reference.substring(showProps.record.reference.length - 5)}`
            : undefined
        }
        hideEdit
        additionalActions={
          // showProps.record.orderId ? (
          //   <BaseButton color="primary" variant="contained" style={{ marginRight: 20 }} onClick={goToOrder}>
          //     {translate('resources.Quote.cta.goToOrder')}
          //   </BaseButton>
          // ) : undefined
          <Box display="flex">
            <DownloadServerGeneratedFileButton
              generateMutation={MUTATION_GENERATE_CIELO_QUOTE_PRINT}
              getFileName={(): string => `cielo-quote-${showProps?.record?.reference.slice(-5)}.pdf`}
              onDownloadComplete={handleDownloadCompleted}
              id={showProps.record.id}
            />
            <CreateMailDrawerButton
              type="quote"
              resourceId={showProps.record.id as any}
              customerId={showProps.record.customerId}
              reference={showProps.record?.reference?.substring(showProps.record.reference.length - 5)}
              onMailSent={handleMailSent}
            />
            <Avatar onClick={onMoreClick as any} variant="rounded" className={classes.avatar}>
              <MoreVertIcon />
            </Avatar>
            <Menu
              id={`more-order-${showProps.record.id}`}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={onMoreClose}
            >
              <MenuItem onClick={goToEdit}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                Edit quote
              </MenuItem>
              <MenuItem onClick={goToOrder} disabled={!showProps.record.orderId}>
                <ListItemIcon>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                Go to order
              </MenuItem>
            </Menu>
          </Box>
        }
      >
        <QuoteShowComp {...props} />
      </ShowView>

      <Confirm
        isOpen={isChangeQuoteStatusDialogOpen}
        content={<ChangeQuoteStatusContent />}
        onConfirm={updateQuoteStatus}
        onClose={handleChangeQuoteStatusDialogClose}
        loading={loading}
      />
    </>
  ) : null
}

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  appBar: {
    marginBottom: theme.spacing(4),
  },
  avatar: {
    backgroundColor: 'white',
    color: theme.palette.secondary.dark,
    width: '45px',
    height: '45px',
    marginLeft: theme.spacing(2),
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  fontNormal: {
    fontWeight: 'normal',
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    maxWidth: 600,
    borderRadius: 4,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[4],
  },
}))

const ChangeQuoteStatusContent: FC<any> = (props) => {
  return (
    <Center flexDir="column">
      <Heading as="h3" size="md" mb={4}>
        Quote status update
      </Heading>

      <Text mb={4}>
        The quote status will be updated to{' '}
        <HStack
          cursor="pointer"
          _hover={{ bgColor: 'gray.100' }}
          px={2}
          display="inline-flex"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
        >
          <Circle bgColor="rgb(30, 118, 234)" size="10px" />
          <Text fontWeight="bold">Sent</Text>
        </HStack>{' '}
        .
      </Text>
    </Center>
  )
}

export default QuoteShow
