import React, { FC } from 'react'
import { ReferenceInput, SelectInput } from 'react-admin'
import FormSelectInput from './FormSelectInput'

type Props = {
  reference?: string
  source?: string
  sort?: {
    field: string
    orderd: 'ASC' | 'DESC'
  }
  optionText?: string | ((choice: any) => string)
  inDrawer?: boolean
  [x: string]: any
}

const sortByRate = { field: 'rate', order: 'ASC' }

const renderText = (choice: any): string => `${choice.name} (${choice.rate}%)`

const Select: FC<any> = ({ inDrawer, optionText, ...props }) => {
  return inDrawer ? (
    <FormSelectInput {...props} optionText={optionText} variant="outlined" />
  ) : (
    <SelectInput {...props} optionText={optionText} variant="outlined" fullWidth />
  )
}

const TaxReferenceInput: FC<Props> = ({
  reference = 'Tax',
  source = 'taxId',
  sort = sortByRate,
  optionText = renderText,
  inDrawer = false,
  classes,
  ...props
}) => {
  return (
    <ReferenceInput {...props} reference={reference} source={source} sort={sort} allowEmpty emptyText="">
      <Select optionText={optionText} inDrawer={inDrawer} classes={classes} />
    </ReferenceInput>
  )
}

export default TaxReferenceInput
