import React, { FC, useMemo } from 'react'
import { TextField, SimpleShowLayout, NumberField, FunctionField } from 'react-admin'
import { useTranslate } from 'ra-core'
import { Card, CardActions, CardContent, makeStyles, Paper, Typography } from '@material-ui/core'
// internal components
import Show from '../../components/details/Show'
// import moment from 'moment'
import { ReferenceField } from 'react-admin'
import { FaCalendarAlt, FaPlaneArrival, FaPlaneDeparture, FaUsers } from 'react-icons/fa'
import { Box, Divider, Heading, Text } from '@chakra-ui/react'

export const euroFormatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
})

const useStyles = makeStyles((theme) => ({
  root: { padding: 0 },
  routesContainer: {
    marginTop: theme.spacing(4),
  },
  routeContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    borderBottom: '1px solid black',
  },
  routeDestinationsContainer: {
    display: 'flex',
  },
  card: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(2),
    '&:last-child': { marginBottom: 0 },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  flight: {
    width: '50%',
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:first-child': { marginRight: theme.spacing(1) },
    '&:last-child': { marginLeft: theme.spacing(1) },
  },
  icon: {
    paddingTop: 5,
    '&:first-child': { marginRight: theme.spacing(1) },
    '&:last-child': { marginLeft: theme.spacing(1) },
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 0,
  },
}))

type Props = {
  [x: string]: any
}

const LeadShow: FC<Props> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <Show {...props} inDrawer title={`${translate('resources.Lead.name', { smart_count: 1 })} #${props.id}`}>
      <SimpleShowLayout className={classes.root}>
        <TextField source="leadType" />
        <NumberField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phone" />
        <ReferenceField reference="VehicleCategory" source="vehicleCategoryId" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="additionalInfo" />
        <CategoryField />
        <RoutesField />
      </SimpleShowLayout>
    </Show>
  )
}

const CategoryField: FC<{ record?: any }> = ({ record }) => {
  const category = useMemo<any>(() => {
    if (record?.category) {
      try {
        return JSON.parse(record.category)
      } catch (e) {
        //
      }
    }

    return null
  }, [])

  if (!category) {
    return null
  }

  return (
    <>
      <Divider mt={4} />
      <Heading mt={4} mb={3} size="md">
        Categoria richiesta:
      </Heading>
      <Box
        d="flex"
        flexDir="column"
        borderRadius="lg"
        bgColor="white"
        alignItems="center"
        border="1px solid"
        overflow="hidden"
      >
        <Box
          w="100%"
          h="140px"
          borderRadius="lg"
          bgPos="center center"
          bgRepeat="no-repeat"
          backgroundSize="cover"
          bgImage={category?.picture}
          pos="relative"
          borderTopRadius="none"
        >
          <Box
            w="100%"
            h="100%"
            borderRadius="lg"
            bgImage="linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.15) 100%)"
            d="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text align="center" as="span" color="white" fontSize="lg" fontWeight="bold">
              {category?.name}
            </Text>
          </Box>
        </Box>

        <Box py={4} d="flex" flexDir="column" alignItems="center" justifyContent="center" px={4} h="100%" flex="1">
          <Text fontSize="sm" opacity="0.8">
            Prezzo mostrato:
          </Text>
          <Heading fontSize="2xl">{euroFormatter.format(category?.price || 0)}</Heading>
        </Box>
      </Box>
    </>
  )
}

const RoutesField: FC<{ record?: any }> = ({ record }) => {
  const classes = useStyles()
  const parsedRoutes = useMemo(() => {
    let parsedValue: any = []
    if (record.routes) {
      try {
        parsedValue = JSON.parse(record.routes)
      } catch (error) {
        parsedValue = []
      }
    }
    return parsedValue
  }, [])

  if (parsedRoutes.length === 0) {
    return null
  }

  return (
    <div className={classes.routesContainer}>
      <Typography variant="h3">Routes</Typography>
      {/* <pre>{JSON.stringify(parsedRoutes, null, 2)}</pre> */}
      {parsedRoutes.map((item: any, index: any) => {
        // return (
        //   <div key={`lead-route-${index}`} className={classes.routeContainer}>
        //     <div className={classes.routeDestinationsContainer}>
        //       <div>
        //         <div>{item.departure.iataCode}</div>
        //         <div>{item.departure.name}</div>
        //       </div>
        //       <div>
        //         <div>{item.arrival.iataCode}</div>
        //         <div>{item.arrival.name}</div>
        //       </div>
        //     </div>
        //     <div>Passengers: {item.passengers}</div>
        //     <div>{moment(item.routeDate).format('L LT')}</div>
        //   </div>
        // )
        return <Route route={item} />
      })}
    </div>
  )
}

const Route: FC<{ route?: any }> = ({ route }) => {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classes.flight}>
          <Typography variant="h2" align="center" display="inline">
            <FaPlaneDeparture className={classes.icon} />
            <span>{route.departure?.iataCode || '-'}</span>
          </Typography>
          <Typography variant="h5" align="center">
            {route.departure?.name || '-'}
          </Typography>
        </div>
        <div className={classes.flight}>
          <Typography variant="h2" align="center">
            <span>{route.arrival?.iataCode || '-'}</span>
            <FaPlaneArrival className={classes.icon} />
          </Typography>
          <Typography variant="h5" align="center">
            {route.arrival?.name || '-'}
          </Typography>
        </div>
      </CardContent>
      <CardActions className={classes.cardFooter}>
        <span>
          <Typography variant="h5">
            <FaUsers className={classes.icon} />
            <span>{route.passengers}</span>
          </Typography>
        </span>
        <span>
          <Typography variant="h5">
            <span>{new Date(route.routeDate).toLocaleString().slice(0, -3)}</span>
            <FaCalendarAlt className={classes.icon} />
          </Typography>
        </span>
      </CardActions>
    </Card>
  )
}

export default LeadShow
