import getValue from 'get-value'
import setValue from 'set-value'
import cloneDeep from 'lodash.clonedeep'
import { nextUntil } from './nextUntil'

// QUOTE

/**
   * {
    "language": "ITA",
    "updatedAt": "29 giugno 2022",
    "organizationEmail": null,
    "organizationPhone": null,
    "organizationName": "Cielo",
    "organizationAddress": {
        "id": 2,
        "alias": null,
        "firstName": null,
        "lastName": null,
        "company": null,
        "stateId": null,
        "street": null,
        "postCode": null,
        "phone": null,
        "city": "Bologna",
        "customerId": null,
        "teamMemberId": null,
        "createdAt": "2022-06-28T14:46:51.092Z",
        "updatedAt": "2022-06-28T14:46:51.092Z",
        "country": "Italy"
    },
    "clientName": "Marco Nalon",
    "isCompany": false,
    "companyName": null,
    "organization": {
        "id": 1,
        "name": "Cielo",
        "vat": null,
        "email": null,
        "phone": null,
        "addressId": 2,
        "createdAt": "2022-06-28T14:46:51.182Z",
        "updatedAt": "2022-06-28T14:46:51.182Z"
    },
    "quoteId": 1,
    "notes": "",
    "quoteReference": "D9D1D",
    "showCarbonOffset": false,
    "routes": [
        {
            "departureAirportId": "BLQ",
            "departureAirportCity": "Bologna",
            "departureAirportName": "Bologna Guglielmo Marconi Airport",
            "arrivalAirportId": "MXP",
            "arrivalAirportCity": "Milano",
            "arrivalAirportName": "Malpensa International Airport",
            "flightDate": "\t28/06/2022",
            "flightTime": "19:00",
            "flightDuration": "30m",
            "passengersQuantity": 1
        },
        {
            "departureAirportId": "MXP",
            "departureAirportCity": "Milano",
            "departureAirportName": "Malpensa International Airport",
            "arrivalAirportId": "BLQ",
            "arrivalAirportCity": "Bologna",
            "arrivalAirportName": "Bologna Guglielmo Marconi Airport",
            "flightDate": "\t28/06/2022",
            "flightTime": "22:00",
            "flightDuration": "",
            "passengersQuantity": 1
        }
    ],
    "options": [
        {
            "id": 1,
            "optionIndex": 1,
            "showYomYor": true,
            "showTaxes": true,
            "showCarbonOffset": false,
            "hasPassengerTax": true,
            "price": "€ 210,00",
            "priceWithoutTaxes": "€ 110,00",
            "taxRate": "0.00",
            "language": "ITA",
            "description": null,
            "vehicle": {
                "id": 1,
                "description": null,
                "licensePlate": "G-ZNTH",
                "registrationYear": null,
                "yearOfManufacture": 2010,
                "yearOfRestyling": 2020,
                "numberOfSeats": 10,
                "color": null,
                "vehicleModelId": 1,
                "vehicleFuelId": null,
                "vehicleCategoryId": 1,
                "homeBaseId": null,
                "pictureId": null,
                "organizationId": null,
                "vehicleSafetyTypeId": 1,
                "homeBaseIcao": "AYGA",
                "galleryId": 4,
                "vehicleManufacturerId": 1,
                "vehicleCarbonOffsetId": 3,
                "createdAt": "2022-06-28T14:54:32.684Z",
                "updatedAt": "2022-06-28T14:54:32.684Z",
                "VehicleModel": {
                    "id": 1,
                    "name": "737",
                    "vehicleManufacturerId": null,
                    "vehicleTypeId": null,
                    "modelYear": null,
                    "vehicleFuelId": null,
                    "createdAt": "2022-06-28T14:49:12.519Z",
                    "updatedAt": "2022-06-28T14:49:12.519Z"
                },
                "VehicleManufacturer": {
                    "id": 1,
                    "name": "test operatore",
                    "createdAt": "2022-06-28T14:47:25.839Z",
                    "updatedAt": "2022-06-28T14:47:25.839Z"
                },
                "showYomYor": true,
                "yom": 2010,
                "yor": 2020
            },
            "carbonOffsetValue": "13.50"
        }
    ]
}
   */

export const GmailQuoteRouteVariables = {
  departureAirportId: 'departureAirportId',
  departureAirportCity: 'departureAirportCity',
  departureAirportName: 'departureAirportName',
  arrivalAirportId: 'arrivalAirportId',
  arrivalAirportCity: 'arrivalAirportCity',
  arrivalAirportName: 'arrivalAirportName',
  flightDate: 'flightDate',
  flightTime: 'flightTime',
  flightDuration: 'flightDuration',
  passengersQuantity: 'passengersQuantity',
}

export const GmailQuoteOptionsVariables = {
  id: 'id',
  optionIndex: 'optionIndex',
  showYomYor: 'showYomYor',
  showTaxes: 'showTaxes',
  showCarbonOffset: 'showCarbonOffset',
  hasPassengerTax: 'hasPassengerTax',
  price: 'price',
  priceWithoutTaxes: 'priceWithoutTaxes',
  taxRate: 'taxRate',
  language: 'language',
  description: 'description',
  carbonOffsetValue: 'carbonOffsetValue',
  vehicleId: 'vehicle.id',
  vehicleName: 'vehicle.name',
  vehicleLicenseCode: 'vehicle.licensePlate',
  vehicleRegistrationYear: 'vehicle.registrationYear',
  vehicleYOM: 'vehicle.yearOfManufacture',
  vehicleYOR: 'vehicle.yearOfRestyling',
  vehichleNumberOfSeats: 'vehicle.numberOfSeats',
  vehicleColor: 'vehicle.color',
  vehicleTypeName: 'vehicle.vehicleModel.name',
  vehicleTypeYear: 'vehicle.vehicleModel.year',
  vehicleOperatorName: 'vehicle.vehicleManufacturer.name',
}

export const GmailQuoteVariables = {
  language: 'languagee',
  updatedAt: 'updatedAt',
  orgEmail: 'organizationEmail',
  orgPhone: 'organizationPhone',
  orgnName: 'organizationName',
  clientName: 'clientName',
  companyName: 'companyName',
  quoteId: 'quoteId',
  quoteReference: 'quoteReference',
  reference: 'reference',
  fullname: 'fullName',
  email: 'email',
  phone: 'phone',
  ...Object.keys(GmailQuoteRouteVariables).reduce((acc, key) => {
    return {
      ...acc,
      [`routes.${key}`]: `routes.${(GmailQuoteRouteVariables as any)[key]}`,
    }
  }, {}),
  ...Object.keys(GmailQuoteOptionsVariables).reduce((acc, key) => {
    return {
      ...acc,
      [`options.${key}`]: `options.${(GmailQuoteOptionsVariables as any)[key]}`,
    }
  }, {}),
}

export const GmailQuoteVariablesList = Object.keys(GmailQuoteVariables).map((key) => ({
  id: key,
  value: key,
}))

export const GmailQuoteLoops = {
  routes: 'routes',
  options: 'quote options',
}

export const GmailQuoteLoopsList = Object.keys(GmailQuoteLoops).map((key) => ({
  id: key,
  value: (GmailQuoteLoops as any)[key],
}))

// ORDER
/**
 * {
  "language": "ITA",
  "organizationEmail": null,
  "organizationPhone": null,
  "organizationName": "Cielo",
  "organizationAddress": {
    "id": 2,
    "alias": null,
    "firstName": null,
    "lastName": null,
    "company": null,
    "stateId": null,
    "street": null,
    "postCode": null,
    "phone": null,
    "city": "Bologna",
    "customerId": null,
    "teamMemberId": null,
    "createdAt": "2022-06-28T14:46:51.092Z",
    "updatedAt": "2022-06-28T14:46:51.092Z",
    "country": "Italy"
  },
  "clientName": "Marco Nalon",
  "clientVat": "",
  "isCompany": false,
  "companyName": "",
  "bankAccount": null,
  "organization": {
    "id": 1,
    "name": "Cielo",
    "vat": null,
    "email": null,
    "phone": null,
    "addressId": 2,
    "createdAt": "2022-06-28T14:46:51.182Z",
    "updatedAt": "2022-06-28T14:46:51.182Z"
  },
  "orderId": 2,
  "orderReference": "D9D1D",
  "order": {
    "id": 2,
    "organizationId": 1,
    "bankAccountId": null,
    "reference": "046D33D9D1D",
    "teamMemberId": 1,
    "personId": null,
    "customerId": 1,
    "languageId": 1,
    "currencyId": 1,
    "status": "confirmed",
    "currency": null,
    "totalAmount": null,
    "productsSubtotal": null,
    "productsTotal": null,
    "purchasePrice": "100.000000",
    "sellingPrice": "210.000000",
    "commissions": "10.000000",
    "penaltyPrice": null,
    "transferCost": null,
    "isoCode": null,
    "notes": null,
    "closed": false,
    "closeDate": null,
    "expiringDate": "2022-06-30T00:16:52.404Z",
    "orderDate": null,
    "showCarbonOffset": false,
    "sellingPriceWithoutTaxes": "110.000000",
    "passengerTax": "100.000000",
    "taxId": null,
    "excludeVatFromFinalPrice": false,
    "printableNotes": null,
    "createdAt": "2022-06-30T00:16:52.415Z",
    "updatedAt": "2022-06-30T00:16:52.415Z"
  },
  "expiringDate": "30 giugno 2022",
  "orderPrice": "€ 210,00",
  "orderPriceWithCarbonOffset": "€ 223,50",
  "orderPriceWithoutTaxes": "€ 110,00",
  "orderTaxRate": "0.00",
  "showTaxes": true,
  "hasPassengerTax": true,
  "routes": [
    {
      "language": "ITA",
      "notes": null,
      "departureAirportId": "BLQ",
      "departureAirportCity": "Bologna",
      "departureAirportName": "Bologna Guglielmo Marconi Airport",
      "arrivalAirportId": "MXP",
      "arrivalAirportCity": "Milano",
      "arrivalAirportName": "Malpensa International Airport",
      "flightDuration": "30m",
      "departureDate": "28/06/2022",
      "departureTime": "19:00",
      "arrivalDate": "",
      "arrivalTime": "",
      "passengers": [],
      "passengersQuantity": 1,
      "vehicle": {
        "id": 1,
        "description": null,
        "licensePlate": "G-ZNTH",
        "registrationYear": null,
        "yearOfManufacture": 2010,
        "yearOfRestyling": 2020,
        "numberOfSeats": 10,
        "color": null,
        "vehicleModelId": 1,
        "vehicleFuelId": null,
        "vehicleCategoryId": 1,
        "homeBaseId": null,
        "pictureId": null,
        "organizationId": null,
        "vehicleSafetyTypeId": 1,
        "homeBaseIcao": "AYGA",
        "galleryId": 4,
        "vehicleManufacturerId": 1,
        "vehicleCarbonOffsetId": 3,
        "createdAt": "2022-06-28T14:54:32.684Z",
        "updatedAt": "2022-06-28T14:54:32.684Z",
        "VehicleModel": {
          "id": 1,
          "name": "737",
          "vehicleManufacturerId": null,
          "vehicleTypeId": null,
          "modelYear": null,
          "vehicleFuelId": null,
          "createdAt": "2022-06-28T14:49:12.519Z",
          "updatedAt": "2022-06-28T14:49:12.519Z"
        },
        "VehicleManufacturer": {
          "id": 1,
          "name": "test operatore",
          "createdAt": "2022-06-28T14:47:25.839Z",
          "updatedAt": "2022-06-28T14:47:25.839Z"
        }
      },
      "carbonOffsetValue": "13.50"
    },
    {
      "language": "ITA",
      "notes": null,
      "departureAirportId": "MXP",
      "departureAirportCity": "Milano",
      "departureAirportName": "Malpensa International Airport",
      "arrivalAirportId": "BLQ",
      "arrivalAirportCity": "Bologna",
      "arrivalAirportName": "Bologna Guglielmo Marconi Airport",
      "flightDuration": "",
      "departureDate": "28/06/2022",
      "departureTime": "22:00",
      "arrivalDate": "",
      "arrivalTime": "",
      "passengers": [],
      "passengersQuantity": 1,
      "vehicle": {
        "id": 1,
        "description": null,
        "licensePlate": "G-ZNTH",
        "registrationYear": null,
        "yearOfManufacture": 2010,
        "yearOfRestyling": 2020,
        "numberOfSeats": 10,
        "color": null,
        "vehicleModelId": 1,
        "vehicleFuelId": null,
        "vehicleCategoryId": 1,
        "homeBaseId": null,
        "pictureId": null,
        "organizationId": null,
        "vehicleSafetyTypeId": 1,
        "homeBaseIcao": "AYGA",
        "galleryId": 4,
        "vehicleManufacturerId": 1,
        "vehicleCarbonOffsetId": 3,
        "createdAt": "2022-06-28T14:54:32.684Z",
        "updatedAt": "2022-06-28T14:54:32.684Z",
        "VehicleModel": {
          "id": 1,
          "name": "737",
          "vehicleManufacturerId": null,
          "vehicleTypeId": null,
          "modelYear": null,
          "vehicleFuelId": null,
          "createdAt": "2022-06-28T14:49:12.519Z",
          "updatedAt": "2022-06-28T14:49:12.519Z"
        },
        "VehicleManufacturer": {
          "id": 1,
          "name": "test operatore",
          "createdAt": "2022-06-28T14:47:25.839Z",
          "updatedAt": "2022-06-28T14:47:25.839Z"
        }
      },
      "carbonOffsetValue": ""
    }
  ],
  "aircrafts": [
    {
      "id": 1,
      "description": null,
      "licensePlate": "G-ZNTH",
      "registrationYear": null,
      "yearOfManufacture": 2010,
      "yearOfRestyling": 2020,
      "numberOfSeats": 10,
      "color": null,
      "vehicleModelId": 1,
      "vehicleFuelId": null,
      "vehicleCategoryId": 1,
      "homeBaseId": null,
      "pictureId": null,
      "organizationId": null,
      "vehicleSafetyTypeId": 1,
      "homeBaseIcao": "AYGA",
      "galleryId": 4,
      "vehicleManufacturerId": 1,
      "vehicleCarbonOffsetId": 3,
      "createdAt": "2022-06-28T14:54:32.684Z",
      "updatedAt": "2022-06-28T14:54:32.684Z",
      "VehicleModel": {
        "id": 1,
        "name": "737",
        "vehicleManufacturerId": null,
        "vehicleTypeId": null,
        "modelYear": null,
        "vehicleFuelId": null,
        "createdAt": "2022-06-28T14:49:12.519Z",
        "updatedAt": "2022-06-28T14:49:12.519Z"
      },
      "VehicleManufacturer": {
        "id": 1,
        "name": "test operatore",
        "createdAt": "2022-06-28T14:47:25.839Z",
        "updatedAt": "2022-06-28T14:47:25.839Z"
      },
      "language": "ITA"
    }
  ],
  "showCarbonOffset": false,
  "carbonOffset": "13.50"
}
 */

export const GmailOrderRoutePassengerVariables = {
  fullName: 'fullName',
}

export const GmailOrderRouteVariables = {
  departureAirportId: 'departureAirportId',
  departureAirportCity: 'departureAirportCity',
  departureAirportName: 'departureAirportName',
  arrivalAirportId: 'arrivalAirportId',
  arrivalAirportCity: 'arrivalAirportCity',
  arrivalAirportName: 'arrivalAirportName',
  flightDuration: 'flightDuration',
  departureDate: 'departureDate',
  departureTime: 'departureTime',
  arrivalDate: 'arrivalDate',
  arrivalTime: 'arrivalTime',
  flightTime: 'flightTime',
  passengersQuantity: 'passengersQuantity',
  vehicleId: 'vehicle.id',
  vehicleName: 'vehicle.vehicleModel.name',
  licenseCode: 'vehicle.licensePlate',
  registrationYear: 'vehicle.registrationYear',
  YOM: 'vehicle.yearOfManufacture',
  YOR: 'vehicle.yearOfRestyling',
  vehichleNumberOfSeats: 'vehicle.numberOfSeats',
  vehicleColor: 'vehicle.color',
  vehicleTypeName: 'vehicle.vehicleModel.name',
  vehicleTypeYear: 'vehicle.vehicleModel.year',
  vehicleOperatorName: 'vehicle.vehicleManufacturer.name',
  ...Object.keys(GmailOrderRoutePassengerVariables).reduce((acc, key) => {
    return {
      ...acc,
      [`passengers.${key}`]: `passengers.${(GmailOrderRoutePassengerVariables as any)[key]}`,
    }
  }, {}),
}

export const GmailOrderVariables = {
  language: 'languagee',
  updatedAt: 'updatedAt',
  orgEmail: 'organizationEmail',
  orgPhone: 'organizationPhone',
  orgnName: 'organizationName',
  clientName: 'clientName',
  clientVat: 'clientVat',
  companyName: 'companyName',
  orderId: 'orderId',
  orderReference: 'orderReference',
  expiringDate: 'expiringDate',
  orderPrice: 'orderPrice',
  orderPriceWithCarbonOffset: 'orderPriceWithCarbonOffset',
  orderPriceWithoutTaxes: 'orderPriceWithoutTaxes',
  orderTaxRate: 'orderTaxRate',
  showTaxes: 'showTaxes',
  email: 'email',
  phone: 'phone',
  ...Object.keys(GmailOrderRouteVariables).reduce((acc, key) => {
    return {
      ...acc,
      [`routes.${key}`]: `routes.${(GmailOrderRouteVariables as any)[key]}`,
    }
  }, {}),
}

export const GmailOrderVariablesList = Object.keys(GmailOrderVariables).map((key) => ({
  id: key,
  value: key,
}))

export const GmailOrderLoops = {
  routes: 'routes',
  'routes.passengers': 'routes.passengers',
}

export const GmailOrderLoopsList = Object.keys(GmailOrderLoops).map((key) => ({
  id: key,
  value: (GmailOrderLoops as any)[key],
}))

/**
 * 
 * {
    "language": "ITA",
    "organizationEmail": null,
    "organizationPhone": null,
    "organizationName": "Cielo",
    "organizationAddress": {
        "id": 2,
        "alias": null,
        "firstName": null,
        "lastName": null,
        "company": null,
        "stateId": null,
        "street": null,
        "postCode": null,
        "phone": null,
        "city": "Bologna",
        "customerId": null,
        "teamMemberId": null,
        "createdAt": "2022-06-28T14:46:51.092Z",
        "updatedAt": "2022-06-28T14:46:51.092Z",
        "country": "Italy"
    },
    "clientName": "Marco Nalon",
    "isCompany": false,
    "companyName": "",
    "organization": {
        "id": 1,
        "name": "Cielo",
        "vat": null,
        "email": null,
        "phone": null,
        "addressId": 2,
        "createdAt": "2022-06-28T14:46:51.182Z",
        "updatedAt": "2022-06-28T14:46:51.182Z"
    },
    "updatedAt": "30 giugno 2022",
    "orderId": 2,
    "orderReference": "D9D1D",
    "order": {
        "id": 2,
        "organizationId": 1,
        "bankAccountId": null,
        "reference": "046D33D9D1D",
        "teamMemberId": 1,
        "personId": null,
        "customerId": 1,
        "languageId": 1,
        "currencyId": 1,
        "status": "confirmed",
        "currency": null,
        "totalAmount": null,
        "productsSubtotal": null,
        "productsTotal": null,
        "purchasePrice": "100.000000",
        "sellingPrice": "210.000000",
        "commissions": "10.000000",
        "penaltyPrice": null,
        "transferCost": null,
        "isoCode": null,
        "notes": null,
        "closed": false,
        "closeDate": null,
        "expiringDate": "2022-06-30T00:16:52.404Z",
        "orderDate": null,
        "showCarbonOffset": false,
        "sellingPriceWithoutTaxes": "110.000000",
        "passengerTax": "100.000000",
        "taxId": null,
        "excludeVatFromFinalPrice": false,
        "printableNotes": null,
        "createdAt": "2022-06-30T00:16:52.415Z",
        "updatedAt": "2022-06-30T00:16:52.415Z"
    },
    "routes": [
        {
            "language": "ITA",
            "departureAirportId": "BLQ",
            "departureAirportCity": "Bologna",
            "departureAirportName": "Bologna Guglielmo Marconi Airport",
            "arrivalAirportId": "MXP",
            "arrivalAirportCity": "Milano",
            "arrivalAirportName": "Malpensa International Airport",
            "departureDate": "28/06/2022",
            "departureTime": "19:00",
            "arrivalDate": "",
            "arrivalTime": "",
            "passengers": [
                {
                    "fullName": "Tizio Caio"
                },
                {
                    "fullName": "Tizio 2 Sempronio"
                }
            ],
            "flightDuration": "30m",
            "passengersQuantity": 1,
            "vehicle": {
                "id": 1,
                "description": null,
                "licensePlate": "G-ZNTH",
                "registrationYear": null,
                "yearOfManufacture": 2010,
                "yearOfRestyling": 2020,
                "numberOfSeats": 10,
                "color": null,
                "vehicleModelId": 1,
                "vehicleFuelId": null,
                "vehicleCategoryId": 1,
                "homeBaseId": null,
                "pictureId": null,
                "organizationId": null,
                "vehicleSafetyTypeId": 1,
                "homeBaseIcao": "AYGA",
                "galleryId": 4,
                "vehicleManufacturerId": 1,
                "vehicleCarbonOffsetId": 3,
                "createdAt": "2022-06-28T14:54:32.684Z",
                "updatedAt": "2022-06-28T14:54:32.684Z",
                "VehicleModel": {
                    "id": 1,
                    "name": "737",
                    "vehicleManufacturerId": null,
                    "vehicleTypeId": null,
                    "modelYear": null,
                    "vehicleFuelId": null,
                    "createdAt": "2022-06-28T14:49:12.519Z",
                    "updatedAt": "2022-06-28T14:49:12.519Z"
                },
                "VehicleManufacturer": {
                    "id": 1,
                    "name": "test operatore",
                    "createdAt": "2022-06-28T14:47:25.839Z",
                    "updatedAt": "2022-06-28T14:47:25.839Z"
                }
            },
            "flightNumber": null,
            "captainName": null,
            "captainNumber": null,
            "firstOfficerName": null,
            "firstOfficerNumber": null,
            "flightAttendantName": null,
            "flightAttendantNumber": null,
            "departureHandlingName": null,
            "departureHandlingNumber": null,
            "arrivalHandlingName": null,
            "arrivalHandlingNumber": null,
            "boardingTime": 20,
            "luggages": "1 per person",
            "catering": null
        },
        {
            "language": "ITA",
            "departureAirportId": "MXP",
            "departureAirportCity": "Milano",
            "departureAirportName": "Malpensa International Airport",
            "arrivalAirportId": "BLQ",
            "arrivalAirportCity": "Bologna",
            "arrivalAirportName": "Bologna Guglielmo Marconi Airport",
            "departureDate": "28/06/2022",
            "departureTime": "22:00",
            "arrivalDate": "",
            "arrivalTime": "",
            "passengers": [
                {
                    "fullName": "Tizio 2 Sempronio"
                }
            ],
            "flightDuration": "",
            "passengersQuantity": 1,
            "vehicle": {
                "id": 1,
                "description": null,
                "licensePlate": "G-ZNTH",
                "registrationYear": null,
                "yearOfManufacture": 2010,
                "yearOfRestyling": 2020,
                "numberOfSeats": 10,
                "color": null,
                "vehicleModelId": 1,
                "vehicleFuelId": null,
                "vehicleCategoryId": 1,
                "homeBaseId": null,
                "pictureId": null,
                "organizationId": null,
                "vehicleSafetyTypeId": 1,
                "homeBaseIcao": "AYGA",
                "galleryId": 4,
                "vehicleManufacturerId": 1,
                "vehicleCarbonOffsetId": 3,
                "createdAt": "2022-06-28T14:54:32.684Z",
                "updatedAt": "2022-06-28T14:54:32.684Z",
                "VehicleModel": {
                    "id": 1,
                    "name": "737",
                    "vehicleManufacturerId": null,
                    "vehicleTypeId": null,
                    "modelYear": null,
                    "vehicleFuelId": null,
                    "createdAt": "2022-06-28T14:49:12.519Z",
                    "updatedAt": "2022-06-28T14:49:12.519Z"
                },
                "VehicleManufacturer": {
                    "id": 1,
                    "name": "test operatore",
                    "createdAt": "2022-06-28T14:47:25.839Z",
                    "updatedAt": "2022-06-28T14:47:25.839Z"
                }
            },
            "flightNumber": null,
            "captainName": null,
            "captainNumber": null,
            "firstOfficerName": null,
            "firstOfficerNumber": null,
            "flightAttendantName": null,
            "flightAttendantNumber": null,
            "departureHandlingName": null,
            "departureHandlingNumber": null,
            "arrivalHandlingName": null,
            "arrivalHandlingNumber": null,
            "boardingTime": 20,
            "luggages": "1 per person",
            "catering": null
        }
    ]
}
 */

export const GmailBriefRoutePassengerVariables = {
  fullName: 'fullName',
}

export const GmailBriefRouteVariables = {
  departureAirportId: 'departureAirportId',
  departureAirportCity: 'departureAirportCity',
  departureAirportName: 'departureAirportName',
  arrivalAirportId: 'arrivalAirportId',
  arrivalAirportCity: 'arrivalAirportCity',
  arrivalAirportName: 'arrivalAirportName',
  flightDuration: 'flightDuration',
  departureDate: 'departureDate',
  departureTime: 'departureTime',
  arrivalDate: 'arrivalDate',
  arrivalTime: 'arrivalTime',
  flightTime: 'flightTime',
  passengersQuantity: 'passengersQuantity',
  vehicleId: 'vehicle.id',
  vehicleName: 'vehicle.vehicleModel.name',
  licenseCode: 'vehicle.licensePlate',
  registrationYear: 'vehicle.registrationYear',
  YOM: 'vehicle.yearOfManufacture',
  YOR: 'vehicle.yearOfRestyling',
  vehichleNumberOfSeats: 'vehicle.numberOfSeats',
  vehicleColor: 'vehicle.color',
  vehicleTypeName: 'vehicle.vehicleModel.name',
  vehicleTypeYear: 'vehicle.vehicleModel.year',
  vehicleOperatorName: 'vehicle.vehicleManufacturer.name',
  flightNumber: 'flightNumber',
  captainName: 'captainName',
  captainNumber: 'captainNumber',
  firstOfficerName: 'firstOfficerName',
  firstOfficerNumber: 'firstOfficerNumber',
  flightAttendantName: 'flightAttendantName',
  flightAttendantNumber: 'flightAttendantNumber',
  departureHandlingName: 'departureHandlingName',
  departureHandlingNumber: 'departureHandlingNumber',
  arrivalHandlingName: 'arrivalHandlingName',
  arrivalHandlingNumber: 'arrivalHandlingNumber',
  boardingTime: 'boardingTime',
  luggages: 'luggages',
  catering: 'catering',
  ...Object.keys(GmailBriefRoutePassengerVariables).reduce((acc, key) => {
    return {
      ...acc,
      [`passengers.${key}`]: `passengers.${(GmailBriefRoutePassengerVariables as any)[key]}`,
    }
  }, {}),
}

export const GmailBriefVariables = {
  language: 'languagee',
  updatedAt: 'updatedAt',
  orgEmail: 'organizationEmail',
  orgPhone: 'organizationPhone',
  orgnName: 'organizationName',
  clientName: 'clientName',
  clientVat: 'clientVat',
  companyName: 'companyName',
  orderId: 'orderId',
  orderReference: 'orderReference',
  expiringDate: 'expiringDate',
  ...Object.keys(GmailBriefRouteVariables).reduce((acc, key) => {
    return {
      ...acc,
      [`routes.${key}`]: `routes.${(GmailBriefRouteVariables as any)[key]}`,
    }
  }, {}),
}

export const GmailBriefVariablesList = Object.keys(GmailBriefVariables).map((key) => ({
  id: key,
  value: key,
}))

export const GmailBriefLoops = {
  routes: 'routes',
  'routes.passengers': 'routes.passengers',
}

export const GmailBriefLoopsList = Object.keys(GmailBriefLoops).map((key) => ({
  id: key,
  value: (GmailBriefLoops as any)[key],
}))

export const renderTemplate = (
  dom: Document | HTMLElement,
  replacements: any,
  templateType: 'quote' | 'contract' | 'brief'
): void => {
  const _collection = dom.getElementsByClassName('mention')
  while (_collection.length > 0) {
    const foundedReplacement = _collection.item(0) as HTMLElement

    const type: 'var' | 'flow' = foundedReplacement.getAttribute('data-denotation-char') === '$$' ? 'var' : 'flow'
    console.log('trovati - sono a', _collection.length, _collection, ' con type: ', type)

    if (type === 'var') {
      const variablesMap =
        templateType === 'quote'
          ? GmailQuoteVariables
          : templateType === 'contract'
          ? GmailOrderVariables
          : GmailBriefVariables

      const varName = foundedReplacement.getAttribute('data-value')
      const valuePath = (variablesMap as any)[varName as any]
      const newElement = document.createElement('span')
      console.log('trovati - var ', variablesMap, varName, (variablesMap as any)[varName as any])

      if (valuePath) {
        const varValue = getValue(replacements, (variablesMap as any)[varName as any])

        if (varValue) {
          newElement.innerHTML = `<span>${varValue}</span>`
        }
      }

      foundedReplacement.replaceWith(newElement)
    } else {
      const flowName = foundedReplacement.getAttribute('data-id')

      let numberOfOpenedFlows = 0
      let numberOfClosedFlows = 0
      let foundedCloseFlowPoint = null
      for (let i = 0; i < _collection.length; i++) {
        const foundedReplacement = _collection[i] as HTMLElement
        if (foundedReplacement.getAttribute('data-denotation-char') === '@@') {
          console.log('cerco @@', foundedReplacement.getAttribute('data-id'))
          if (foundedReplacement.getAttribute('data-id') === flowName) {
            numberOfOpenedFlows++
          } else if (foundedReplacement.getAttribute('data-id') === 'end-' + flowName) {
            if (numberOfClosedFlows === 0) {
              foundedCloseFlowPoint = foundedReplacement
            }
            numberOfClosedFlows++
          }
        }
      }

      console.log('trovati - flow ', flowName, foundedCloseFlowPoint, numberOfOpenedFlows, numberOfClosedFlows)
      if (numberOfOpenedFlows !== numberOfClosedFlows || !foundedCloseFlowPoint) {
        const newElement = document.createElement('strong')
        newElement.innerText = `-- Template Error: missing @@end ${flowName} --`
        foundedReplacement.replaceWith(newElement)
        break
      }

      const elementsBetweenStartAndEnd = nextUntil(
        foundedReplacement.parentElement!,
        foundedCloseFlowPoint.parentElement!
      )

      console.log('da renderizzare', flowName, replacements, elementsBetweenStartAndEnd)
      const loopReplacementsArray = getValue(replacements, flowName as any)
      if (loopReplacementsArray) {
        for (let i = 0; i < loopReplacementsArray.length; i++) {
          // const newReplacements = { ...replacements, [flowName as any]: loopReplacementsArray[i] }
          const newReplacements = cloneDeep(replacements)
          setValue(newReplacements, flowName as any, loopReplacementsArray[i], { merge: true })
          console.log('rendering with', loopReplacementsArray[i], newReplacements)

          const container = document.createElement('div')
          for (let j = 0; j < elementsBetweenStartAndEnd.length; j++) {
            const newElement = elementsBetweenStartAndEnd[j].cloneNode(true)
            // renderTemplate(newElement as HTMLElement, newReplacements, templateType)
            // foundedReplacement.parentElement!.append(newElement)
            container.append(newElement)
          }

          renderTemplate(container, newReplacements, templateType)

          foundedReplacement.parentElement!.append(container)
        }
      }

      for (let i = 0; i < elementsBetweenStartAndEnd.length; i++) {
        elementsBetweenStartAndEnd[i].remove()
      }

      foundedReplacement.remove()
      foundedCloseFlowPoint.remove()
    }

    console.log('---------------------------')
  }
}
