import React, { FC, useCallback, useState } from 'react'
import { Button, ButtonProps, CircularProgress } from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print'
import { DocumentNode, OperationVariables, TypedDocumentNode, useApolloClient } from '@apollo/client'
import { Identifier, useNotify } from 'ra-core'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const fileDownload = require('js-file-download')

type Props = Omit<ButtonProps, 'id'> & {
  /**
   * mutation that generate the print
   */
  generateMutation: DocumentNode | TypedDocumentNode<any, OperationVariables>
  /**
   * id used as input variable in `generateMutation`, if `getPayload` is provided this prop will be ignored
   */
  id?: Identifier
  /**
   * input payload used in `generateMutation` (it overrides `id` prop)
   */
  getPayload?: (params: Record<string, any>) => Record<string, any>
  /**
   * name of the file when downloaded
   */
  getFileName: (params: Record<string, any>) => string

  /**
   * callback called when the file has been downloaded
   */
  onDownloadComplete?: () => void
}

export function useDownloadServerGeneratedButton({
  id,
  getPayload,
  generateMutation,
  getFileName,
  onDownloadComplete,
}: Props): {
  loading: boolean
  download: (params: Record<string, any>) => Promise<void>
} {
  if (!id && !getPayload) {
    throw new Error('Mandatory props missing, add id or getPayload props')
  }

  const client = useApolloClient()
  const notify = useNotify()
  const [loading, setLoading] = useState<boolean>(false)

  const handleDownload = useCallback(async (params: Record<string, any>) => {
    try {
      if (loading) {
        return
      }

      setLoading(true)

      const { data, errors } = await client.mutate({
        mutation: generateMutation,
        variables: getPayload ? getPayload(params) : { id },
      })

      if (errors && errors.length > 0) {
        throw new Error(errors.map((item) => item.message).join('; '))
      }

      if (!data || Object.keys(data).length === 0) {
        throw new Error('Unexpected error')
      }

      const downloadResult = await fetch(data[Object.keys(data)[0]])
      const fileBlob = await downloadResult.blob()
      fileDownload(fileBlob, getFileName(params))

      if (onDownloadComplete) {
        onDownloadComplete()
      }
    } catch (error) {
      notify((error as any).message, 'error')
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    download: handleDownload,
  }
}

/**
 *
 * Utility Component that generate and download server generated prints
 */
export const DownloadServerGeneratedFileButton: FC<Props> = ({
  id,
  generateMutation,
  getFileName,
  getPayload,
  onDownloadComplete,
  ...rest
}) => {
  const { loading, download } = useDownloadServerGeneratedButton({
    id,
    generateMutation,
    getFileName,
    getPayload,
    onDownloadComplete,
  })

  return (
    <Button variant="contained" {...rest} disabled={loading} onClick={download}>
      {loading ? <CircularProgress size={24} /> : rest.children ? rest.children : <PrintIcon />}
    </Button>
  )
}
