import React, { FC, useCallback, useEffect, useState } from 'react'
import { useNotify, useRedirect, useTranslate } from 'ra-core'
import { ArrayInput, FormDataConsumer, ReferenceInput, SimpleFormIterator, TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
// internal components
import Edit from '../../components/details/Edit'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import FormSelectInput from '../../components/input/FormSelectInput'
import BaseButton from './../../components/button/BaseButton'
// icons
import ContentAdd from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

type Props = {
  [x: string]: any
}

const BankDetailsInputSection: FC<Props> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const form = useForm()
  const { values } = useFormState()
  // console.log('inside consumer, values: ', values)

  useEffect(() => {
    // console.log('inside useEffect, values: ', values)
    // const newDetails = JSON.parse(values.details)
    // form.change('details', newDetails)
    if (values.organizationId) {
      props.setOrganizationId(values.organizationId)
    }
  }, [])

  return (
    <FormSection title={translate('resources.BankAccount.titles.details')}>
      <ArrayInput source="details" label={false}>
        <SimpleFormIterator
          classes={{ line: classes.line }}
          addButton={
            <BaseButton startIcon={<ContentAdd />} color="primary" variant="contained">
              {translate('ra.action.add')}
            </BaseButton>
          }
          removeButton={
            <BaseButton startIcon={<DeleteOutlineIcon />} color="primary" variant="contained">
              {translate('ra.action.remove')}
            </BaseButton>
          }
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData }: any): React.ReactElement => {
              return (
                <>
                  <TextInput
                    variant="outlined"
                    source={getSource('key')}
                    record={scopedFormData}
                    helperText={false}
                    className={classes.input}
                    label="resources.BankAccount.fields.customField"
                  />
                  <TextInput
                    variant="outlined"
                    source={getSource('value')}
                    record={scopedFormData}
                    helperText={false}
                    label="resources.BankAccount.fields.customValue"
                  />
                </>
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </FormSection>
  )
}

// TODO: FIXME: update not working, '__typename' field added for every BankAccountDetail
// strange behaviour with the 'onSuccess' prop too
const BankAccountEdit: FC<Props> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const redirect = useRedirect()
  const notify = useNotify()
  const [organizationId, setOrganizationId] = useState<number | null>(null)

  // const transform = useCallback((data: any): any => {
  //   console.log('data before strigify; ', data)
  //   const details = JSON.parse(data.details)
  //   delete data.details
  //   data.details = [...details]
  //   console.log('data before submsision: ', data)
  //   return data
  // }, [])

  // useEffect(() => console.log('ESISTO??? ', organizationId), [organizationId])

  const onSuccess = useCallback(() => {
    notify('ra.notification.updated')
    redirect(`/Organization/${organizationId}/show`)
  }, [organizationId])

  // console.log('BankAccountEdit props: ', props)
  // const transform = useCallback((data: any): any => {
  //   console.log('xxx sta cazzo di data:: ', data)
  //   setOrganizationId(data.organizationId)
  //   return data
  // }, [])

  return (
    <Edit {...props} component="div" onSuccess={onSuccess}>
      <SectionedForm variant="outlined" title={translate('resources.BankAccount.titles.edit')}>
        <FormSection title={translate('resources.BankAccount.titles.details')}>
          <FormTextInput source="name" required />
          <FormTextInput source="bankName" />
          <ReferenceInput source="currencyId" reference="Currency">
            <FormSelectInput optionText="name" fullWidth />
          </ReferenceInput>
          <FormTextInput source="address" />
        </FormSection>
        <BankDetailsInputSection setOrganizationId={setOrganizationId} />
        {/* <FormSection title={translate('resources.BankAccount.titles.details')}>
          <ArrayInput source="details" label={false}>
            <SimpleFormIterator
              classes={{ line: classes.line }}
              addButton={
                <BaseButton startIcon={<ContentAdd />} color="primary" variant="contained">
                  {translate('ra.action.add')}
                </BaseButton>
              }
              removeButton={
                <BaseButton startIcon={<DeleteOutlineIcon />} color="primary" variant="contained">
                  {translate('ra.action.remove')}
                </BaseButton>
              }
            >
              <FormDataConsumer>
                {({ getSource, scopedFormData }: any): React.ReactElement => {
                  return (
                    <>
                      <TextInput
                        variant="outlined"
                        source={getSource('key')}
                        record={scopedFormData}
                        helperText={false}
                        className={classes.input}
                        label="resources.BankAccount.fields.customField"
                      />
                      <TextInput
                        variant="outlined"
                        source={getSource('value')}
                        record={scopedFormData}
                        helperText={false}
                        label="resources.BankAccount.fields.customValue"
                      />
                    </>
                  )
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormSection> */}
      </SectionedForm>
    </Edit>
  )
}

const useStyles = makeStyles((theme) => ({
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  line: {
    display: 'flex',
    alignItems: 'stretch',
    borderBottom: 0,
  },
  input: {
    marginRight: '3rem',
  },
}))

export default BankAccountEdit
