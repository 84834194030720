import React, { FC } from 'react'
import { useTranslate } from 'ra-core'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Container from '@material-ui/core/Container'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import SaveButton from '../button/SaveButton'

type Props = {
  saveButtonComponent?: React.ReactNode
  undoButtonComponent?: React.ReactNode
  asideUpperContent?: React.ReactNode
  asideLowerContent?: React.ReactNode
  [x: string]: any
}

const useStyles = makeStyles((theme) => ({
  aside: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(6),
    padding: 0,
    maxWidth: 220,
    height: '100%',
    minHeight: '100%',
    position: 'sticky',
    top: theme.spacing(3),
    '@media (max-width: 960px)': {
      maxWidth: '100%',
      marginLeft: 0,
      marginTop: theme.spacing(3),
      backgroundColor: 'white',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      minHeight: 0,
      height: 'auto',
      top: 'auto',
      boxShadow: theme.shadows[2],
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      flexDirection: 'row',
      width: '100%',
    },
  },
  createButton: {
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  sideWrapper: {
    flexBasis: 1,
    flexGrow: 1,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
  },
  sideWrapperCancel: {
    flexBasis: 1,
    flexGrow: 1,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      backgroundColor: 'white',
      marginBottom: theme.spacing(3),
    },
  },
  button: {
    width: 'inherit',
    backgroundColor: theme.palette.background.default,
  },
  saveButton: {
    width: '100%',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    '@media (max-width: 960px)': {
      marginTop: 0,
      padding: 0,
      width: 'auto',
    },
  },
  spaceDivider: {
    // height: theme.spacing(7), //WHY?
  },
}))

const FormRightControls: FC<Props> = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const translate = useTranslate()

  const theme = useTheme()
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container className={classes.aside}>
      <div className={classes.sideWrapper}>
        {props.undoButtonComponent && React.isValidElement(props.undoButtonComponent) ? (
          React.cloneElement(props.undoButtonComponent, props)
        ) : (
          <Button
            className={classes.button}
            size={mediaQuery ? undefined : 'large'}
            variant="outlined"
            onClick={history.goBack}
          >
            {translate('ra.action.cancel')}
          </Button>
        )}
        {props.asideUpperContent}
        {props.asideLowerContent}
        {props.asideLowerContent && <div className={classes.spaceDivider} />}
        {props.saveButtonComponent && React.isValidElement(props.saveButtonComponent) ? (
          React.cloneElement(props.saveButtonComponent, props)
        ) : (
          <SaveButton {...props} className={classes.saveButton} mt={mediaQuery ? 1 : undefined} />
        )}
      </div>
    </Container>
  )
}

export default FormRightControls
