import React, { FC, useCallback } from 'react'
import { useGetList, useCreate, useRefresh, useNotify, useDelete, useGetOne } from 'ra-core'
import { formatDistanceToNow } from 'date-fns'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import LinearProgress from '@material-ui/core/LinearProgress'
import DownloadDocumentButton from '../../components/DownloadDocumentButton'
import DeleteDocumentButton from '../../components/DeleteDocumentButton'
import UploadMediaButton from '../../components/UploadMediaButton'
import { FaCloudUploadAlt } from 'react-icons/fa'

type Props = {
  record: Record<string, any>
}

type FileItemProps = {
  documentId: string
  orderDocumentId: string
}

const UploadButton: FC<any> = (props) => {
  return (
    <Button color="primary" variant="contained" startIcon={<FaCloudUploadAlt />} onClick={props.onClick}>
      Upload new file
    </Button>
  )
}

const FileItem: FC<FileItemProps> = ({ documentId, orderDocumentId }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const { loading, error, data } = useGetOne('Document', documentId)
  const [deleteOrderDocument] = useDelete('OrderDocument', orderDocumentId, orderDocumentId)

  const onDocumentDeleted = useCallback(async () => {
    try {
      const result = await deleteOrderDocument()
      // console.log('delete result ', result)
      notify('Document deleted')
      refresh()
    } catch (err) {
      console.log('error')
      notify(err, 'error')
    }
  }, [orderDocumentId])

  return (
    <Box mb={3}>
      <Paper>
        <Box p={3} display="flex" justifyContent="space-between" alignItems="center">
          {loading ? (
            <LinearProgress />
          ) : data ? (
            <>
              <Box display="flex" flexDirection="column">
                <Typography variant="caption">
                  {data.createdAt
                    ? `Uploaded ${formatDistanceToNow(new Date(data.createdAt), { addSuffix: true })}`
                    : ''}
                </Typography>
                <Box mt={1} />
                <Typography variant="h4">{data.description}</Typography>
              </Box>
              <Box display="flex">
                <DownloadDocumentButton id={documentId} />
                <DeleteDocumentButton id={documentId} onDocumentDeleted={onDocumentDeleted} />
              </Box>
            </>
          ) : null}
        </Box>
      </Paper>
    </Box>
  )
}

const GenericFilesSection: FC<Props> = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const { loading, error, data, total } = useGetList(
    'OrderDocument',
    { page: 1, perPage: 100 },
    { field: 'createdAt', order: 'DESC' },
    { orderId: props.record.id }
  )

  const [createOrderDocument, { loading: createLoading, error: createError }] = useCreate('OrderDocument')

  const onDocumentUploaded = useCallback(async (data: any) => {
    try {
      const result = await createOrderDocument({ payload: { data: { orderId: props.record.id, documentId: data.id } } })
      notify('ra.notification.created')
      refresh()
    } catch (err) {
      // console.log('error::: ', err)
      notify(err, 'error')
    }
  }, [])

  return (
    <>
      <Box display="flex">
        <Typography variant="h3">Uploads</Typography>
        <Box ml={2} />
        <Chip size="small" label={total} color="primary" />
      </Box>
      <Box mt={3} />
      {loading && <LinearProgress />}
      {data &&
        Object.values(data).map((document: any) => {
          return <FileItem documentId={document.documentId} orderDocumentId={document.id} />
        })}
      {
        <Box alignSelf="flex-end">
          <UploadMediaButton button={<UploadButton />} onSuccess={onDocumentUploaded} />
        </Box>
      }
    </>
  )
}

export default GenericFilesSection
