import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { GrStatusUnknown } from 'react-icons/gr'
import Flag from 'react-world-flags'

type Props = {
  source: string
  record?: Record<string, any>
  label?: string
}

const FlagFromISOField: FC<Props> = ({ record, source }) => {
  const classes = useStyles()
  return record && source ? (
    <Flag className={classes.flag} code={record[source]} fallback={<GrStatusUnknown />} />
  ) : null
}

const useStyles = makeStyles((theme: any) => ({
  flag: {
    maxHeight: '1rem',
    maxWidth: '1.5rem',
  },
}))

export default FlagFromISOField
