import React, { FC, useState, useCallback } from 'react'
import { usePermissions } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import logo from './../../logo.svg'
import Drawer from '../../components/Drawer'
import MoreVertIcon from '@material-ui/icons/MoreVert'

type Props = {
  logout?: any
}

const MobileUserMenu: FC<Props> = ({ logout }) => {
  const classes = useStyles()
  const { loaded, permissions } = usePermissions()
  const [open, setOpen] = useState<boolean>(false)

  const handleDrawerOpen = useCallback(() => setOpen(true), [])
  const handleDrawerClose = useCallback(() => setOpen(false), [])

  return (
    <>
      <Box className={classes.userContainer} alignItems="center" display="flex" p={2} onClick={handleDrawerOpen}>
        <MoreVertIcon color="secondary" />
        <img src={logo} className={classes.userAvatar} />
        <Box display="flex" flexDirection="column" ml={2} width="fit-content">
          <Typography className={clsx(classes.name, classes.truncatedText)} color="primary" variant="h5">
            {permissions && permissions.user && `${permissions.user.firstName}`}
          </Typography>
          <Typography color="textSecondary" variant="body2" className={classes.role}>
            {permissions &&
              permissions.user &&
              permissions.user.roles &&
              permissions.user.roles.length > 0 &&
              `${permissions.user.roles[0].name}`}
          </Typography>
        </Box>
      </Box>
      <Drawer open={open} onClose={handleDrawerClose}>
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
          <Typography variant="h2">User settings</Typography>
          {logout}
        </Box>
      </Drawer>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    width: 53,
    height: 53,
  },
  name: {
    fontWeight: 'bold',
  },
  role: {
    fontWeight: 'lighter',
  },
  userContainer: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuTitle: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(5),
  },
  truncatedText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '125px',
  },
}))

export default MobileUserMenu
