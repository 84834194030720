import React, { FC, useMemo } from 'react'
import { SimpleMail } from '../../types'
import { useQuery } from '@apollo/client'
import { MailItemSkeleton } from '../skeletons/MailSkeletons'
import moment from 'moment'
import { MailHeadInfoPreview } from './MailHeadInfoPreview'
import { MailPart } from './MailPart'
import { GET_MAIL_BY_ID } from '../../queries'
import { Box, Flex, Circle, Tooltip, Text, Collapse } from '@chakra-ui/react'
import { EmailIcon, ArrowDownIcon, AttachmentIcon } from '@chakra-ui/icons'

function getHeaderValue(mail: any, name: string): string | undefined {
  if (mail?.payload?.headers) {
    const foundedHeader = (mail.payload.headers as any[]).find((item) => item.name === name)
    if (foundedHeader) {
      return foundedHeader.value
    }
  }

  return undefined
}

export type CustomerMailItemProps = {
  customerEmail?: string
  peopleEmails?: string[]
  simpleMail: SimpleMail
  loggedUserMail?: string
}

export const CustomerMailItem: FC<CustomerMailItemProps> = (props) => {
  const { customerEmail, peopleEmails, loggedUserMail, simpleMail } = props

  const { loading, data } = useQuery(GET_MAIL_BY_ID, {
    skip: !simpleMail?.id,
    variables: {
      id: simpleMail.id,
    },
  })

  const [show, setShow] = React.useState(false)
  const handleToggle = () => setShow(!show)

  const mailSubject = useMemo<string | undefined>(() => {
    if (!loading && data?.getGmailById?.payload?.headers) {
      return getHeaderValue(data?.getGmailById, 'Subject')
    }

    return undefined
  }, [data?.getGmailById])

  const from = useMemo<string | undefined>(() => {
    if (!loading && data?.getGmailById?.payload?.headers) {
      return getHeaderValue(data?.getGmailById, 'From')
    }

    return undefined
  }, [data?.getGmailById])

  const fromName = useMemo<string | undefined>(() => {
    if (from) {
      const splitted = from.split('<')
      if (splitted.length === 2) {
        let name = splitted[0].trimEnd()
        if (name.startsWith('"') && name.endsWith('"')) {
          name = name.slice(1, -1)
        }
        return name
      }
      return ''
    }
    return undefined
  }, [from])

  const fromEmail = useMemo<string | undefined>(() => {
    if (from) {
      const splitted = from.split('<')
      if (splitted.length === 2) {
        return splitted[1].replace('>', '')
      }
      return ''
    }
    return undefined
  }, [from])

  const to = useMemo<string | undefined>(() => {
    if (!loading && data?.getGmailById?.payload?.headers) {
      return getHeaderValue(data?.getGmailById, 'To')
    }

    return undefined
  }, [data?.getGmailById])

  const toName = useMemo<string | undefined>(() => {
    if (to) {
      const splitted = to.split('<')
      if (splitted.length === 2) {
        let name = splitted[0].trimEnd()
        if (name.startsWith('"') && name.endsWith('"')) {
          name = name.slice(1, -1)
        }
        return name
      }
      return ''
    }
    return undefined
  }, [to])

  const toEmail = useMemo<string | undefined>(() => {
    if (to) {
      const splitted = to.split('<')
      if (splitted.length === 2) {
        return splitted[1].replace('>', '')
      }
      return ''
    }
    return undefined
  }, [to])

  const mailDate = useMemo(() => {
    if (!loading && data?.getGmailById.internalDate) {
      return moment(new Date(parseInt(data.getGmailById.internalDate, 10))).format('L LT')
    }
    return undefined
  }, [data?.getGmailById])

  const hasAttachments = useMemo(
    () => data?.getGmailById?.parts?.filter((item: any) => !!item.attachmentId).length > 0,
    [data?.getGmailById]
  )

  if (loading) {
    return <MailItemSkeleton />
  }
  return (
    <>
      <Flex
        flex={1}
        alignItems="center"
        maxW="100%"
        py={!show ? '8px' : '20px'}
        transition="all 0.2s linear"
        textAlign="left"
        px="16px"
        cursor="pointer"
        onClick={handleToggle}
      >
        {!show ? (
          <>
            <Tooltip
              fontSize="10px"
              rounded="sm"
              bgColor="rgba(23,25,35,.7)"
              // eslint-disable-next-line quotes
              label={fromEmail === loggedUserMail ? "You've sent this mail" : 'You received this mail'}
              // eslint-disable-next-line quotes
              aria-label={fromEmail === loggedUserMail ? "You've sent this mail" : 'You received this mail'}
            >
              <Circle size="40px" bg="#bdbdbd" color="white">
                <EmailIcon w="24px" h="24px" />
              </Circle>
            </Tooltip>

            <Box w="10px" />

            <Flex flexDir="column">
              <Flex
                flexDir="row"
                alignItems="center"
                fontSize="16px"
                fontFamily="Avenir,-apple-system,sans-serif"
                fontWeight="400"
                lineHeight="1.5"
              >
                <ArrowDownIcon mr="3px" w="16px" h="16px" />

                {mailSubject || data?.getGmailById?.snippet}
                {hasAttachments && <AttachmentIcon mr="15px" ml="8px" opacity="0.6" />}

                {/* <div className={`hideOnExpanded ${classes.dateContainer}`}>
             {hasAttachments && <AttachmentIcon fontSize="small" className={classes.attachmentIcon} />}
             <span>{mailDate}</span>
           </div> */}
              </Flex>
              <Text fontSize="14px" fontFamily="Avenir,-apple-system,sans-serif " fontWeight="unset" lineHeight="1.43">
                {mailDate}
              </Text>
            </Flex>
          </>
        ) : (
          <MailHeadInfoPreview
            peopleEmails={peopleEmails}
            loggedUserMail={loggedUserMail!}
            customerEmail={customerEmail}
            mailHeader={data?.getGmailById?.payload?.headers || []}
            fromEmail={fromEmail}
            fromName={fromName}
            toEmail={toEmail}
            toName={toName}
            mailDate={mailDate}
            hasAttachments={hasAttachments}
            messageId={data.getGmailById.id}
          />
        )}
      </Flex>

      <Collapse in={show} animateOpacity unmountOnExit>
        <Box display="flex" flexDirection="column" overflowWrap="anywhere" h="100%">
          {!loading && data?.getGmailById?.parts && (
            <MailPart
              messageId={data.getGmailById.id}
              part={
                data.getGmailById.parts.length === 1
                  ? { ...data.getGmailById.parts[0] }
                  : { ...data.getGmailById, mimeType: data.getGmailById.payload?.mimeType }
              }
            />
          )}
        </Box>
      </Collapse>
    </>
  )
}
