import React, { FC, useCallback, useState } from 'react'
import { FormWithRedirect, useDataProvider, useNotify, useRedirect, useRefresh } from 'ra-core'
import { useForm, useFormState } from 'react-final-form'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
// internal components
import SaveButton from '../../components/button/SaveButton'
import { ReferenceInput } from 'react-admin'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import { useApolloClient } from '@apollo/client'
import { CREATE_PASSENGER_ROUTE } from '../../queries'
import PassengerForm from '../../components/forms/PassengerForm'
import BaseButton from '../../components/button/BaseButton'
import RedoIcon from '@material-ui/icons/Redo'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
  orderId: string
  orderRouteId: string
  [x: string]: any
}

const ExistingUserAlert: FC<any> = ({ userList, values, orderRouteId, shouldGoToOrder = false, orderId }) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const apolloClient = useApolloClient()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const notify = useNotify()

  const handleClose = (): void => setIsOpen(false)

  const onConfirm = useCallback(async () => {
    try {
      // console.log('vaaalues?? ', values)
      setSaving(true)
      const result = await dataProvider.create('Passenger', { data: { ...values } })
      if (result) {
        const res = await apolloClient.mutate({
          mutation: CREATE_PASSENGER_ROUTE,
          variables: {
            data: { passengerId: result.data.id, orderRouteId },
          },
        })

        refresh()
        if (shouldGoToOrder) {
          redirect(`/Order/${orderId}/show`)
        }
        notify('ra.notify.updated')
      }
    } catch (error) {
      console.log('update error: ', error)
      notify(error?.message, 'error')
    } finally {
      setSaving(false)
    }
  }, [values, orderRouteId])

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Warning! Existing passengers detected'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Found ${userList.length} user${
            userList.length > 1 ? 's' : ''
          } that could be the same person of you new entry:`}
          {userList.map((user: any) => (
            <Box display="flex">
              {`${user.firstName} ${user.lastName} ${user.birthDate ? `(born: ${user.birthDate.split('T')[0]})` : ''}`}
            </Box>
          ))}
          {'Do you still want to continue?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={saving}>
          No
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          autoFocus
          disabled={saving}
          startIcon={saving ? <CircularProgress size={18} thickness={2} className={classes.icon} /> : <SaveIcon />}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const SaveAndResetButton: FC<Props> = ({
  apolloClient,
  dataProvider,
  notify,
  saving,
  setSaving,
  orderRouteId,
  pristine,
  orderId,
}) => {
  // const { change } = useForm()
  const { values } = useFormState()
  const classes = useStyles()
  const refresh = useRefresh()
  const [existingUsers, setExistingUsers] = useState<any[]>([])

  const onSubmit = useCallback(async () => {
    try {
      // console.log('vaaalues?? ', values)

      // searching for existing passenger
      if (!values.passengerId) {
        const checkres = await dataProvider.getList('Passenger', {
          filter: {
            firstName: values.firstName,
            lastName: values.lastName,
          },
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: {
            field: 'id',
            order: 'ASC',
          },
        })
        if (checkres?.total > 0) {
          setExistingUsers(checkres.data)
          return
        }
      }

      setSaving(true)
      const result = values.passengerId
        ? await dataProvider.update('Passenger', {
            id: values.passengerId,
            data: { ...values },
            previousData: { id: values.passengerId },
          })
        : await dataProvider.create('Passenger', { data: { ...values } })

      if (result) {
        const res = await apolloClient.mutate({
          mutation: CREATE_PASSENGER_ROUTE,
          variables: {
            data: {
              passengerId: values.passengerId ? values.passengerId : result.data.id,
              orderRouteId: orderRouteId,
            },
          },
        })
        // console.log('associate passenger result: ', res)
        notify('ra.notify.updated')
        refresh()
        // Object.keys(values).forEach((key) => change(`${key}`, undefined))
      }
    } catch (error) {
      console.log('update error: ', error)
      notify(error, 'error')
    } finally {
      setSaving(false)
    }
  }, [values])

  return (
    <>
      <BaseButton
        fullWidth
        size="xl"
        onClick={onSubmit}
        disabled={saving || pristine}
        startIcon={
          saving ? (
            <CircularProgress size={18} thickness={2} className={classes.icon} />
          ) : (
            <RedoIcon className={classes.icon} />
          )
        }
      >
        Save and add new
      </BaseButton>
      {!values.passengerId && existingUsers.length > 0 && (
        <ExistingUserAlert userList={existingUsers} values={values} orderId={orderId} orderRouteId={orderRouteId} />
      )}
    </>
  )
}

const InDrawerAddPassenger: FC<Props> = (props) => {
  const apolloClient = useApolloClient()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [saving, setSaving] = useState<boolean>(false)
  const [existingUsers, setExistingUsers] = useState<any[]>([])
  const [valuesToSave, setValuesToSave] = useState<Record<string, any>>({})
  // const { orderRouteId } = parse(props.location.search)

  // const { loading, error, data } = useGetOne('OrderRoute', id, { enabled: id !== 0 })

  const onSubmit = useCallback(async (values) => {
    try {
      // console.log('vaaalues?? ', values)
      setSaving(true)
      if (!values.passengerId) {
        const checkres = await dataProvider.getList('Passenger', {
          filter: {
            firstName: values.firstName,
            lastName: values.lastName,
          },
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: {
            field: 'id',
            order: 'ASC',
          },
        })
        if (checkres?.total > 0) {
          setValuesToSave({ ...values })
          setExistingUsers(checkres.data)
          return
        }
      }
      const result = values.passengerId
        ? await dataProvider.update('Passenger', {
            id: values.passengerId,
            data: { ...values },
            previousData: { id: values.passengerId },
          })
        : await dataProvider.create('Passenger', { data: { ...values } })

      if (result) {
        const res = await apolloClient.mutate({
          mutation: CREATE_PASSENGER_ROUTE,
          variables: {
            data: {
              passengerId: values.passengerId ? values.passengerId : result.data.id,
              orderRouteId: props.orderRouteId,
            },
          },
        })
        // console.log('associate passenger result: ', res)

        refresh()
        redirect(`/Order/${props.orderId}/show`)
        notify('ra.notify.updated')
      }
    } catch (error) {
      console.log('update error: ', error)
      notify(error?.message, 'error')
    } finally {
      setSaving(false)
    }
  }, [])

  return (
    <FormWithRedirect
      {...props}
      // record={data}
      resource="Passenger"
      save={onSubmit}
      // "saving" boolean coming out from render not working ://
      render={({ handleSubmitWithRedirect, pristine }: any): any => (
        <Box display="flex" flexDirection="column">
          <Typography variant="h4">Search passenger</Typography>
          <Box mt={1} />
          <ReferenceInput
            reference="Passenger"
            source="passengerId"
            // TODO  change filterToQuery prop
            // filterToQuery={filterToQueryQ}
            // filterToQuery={filterToQueryFirstName}
            label="Passenger"
            variant="outlined"
          >
            <AutocompleteInputInDrawer optionText="fullName" fullWidth />
          </ReferenceInput>
          <Box mt={3} />
          <Typography variant="h4">or create new passenger</Typography>
          <PassengerForm />
          <Box mt={3}>
            <SaveAndResetButton
              {...props}
              saving={saving}
              setSaving={setSaving}
              apolloClient={apolloClient}
              dataProvider={dataProvider}
              notify={notify}
              pristine={pristine}
            />
          </Box>
          <Box mt={3}>
            <SaveButton
              size="large"
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              pristine={pristine}
              saving={saving}
              disabled={pristine || saving}
            />
          </Box>
          {existingUsers.length > 0 && (
            <ExistingUserAlert
              userList={existingUsers}
              values={valuesToSave}
              shouldGoToOrder
              orderId={props.orderId}
              orderRouteId={props.orderRouteId}
            />
          )}
        </Box>
      )}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
  },
}))

export default InDrawerAddPassenger
