import italianMessages from 'ra-language-italian'

export default {
  ...italianMessages,
  create: 'Crea',
  ra: {
    ...italianMessages.ra,
    page: {
      ...italianMessages.ra.page,
      empty: 'Nessun elemento presente',
      invite: 'Aggiungi un nuovo elemento alla lista vuota',
    },
    boolean: {
      ...italianMessages.ra.boolean,
      null: 'Tutti',
      isPublic: {
        F: {
          true: 'Pubblica',
          false: 'Privata',
        },
        M: {
          true: 'Pubblico',
          false: 'Privato',
        },
      },
    },
    action: {
      ...italianMessages.ra.action,
      unselect: 'Deseleziona',
      sort: 'Ordina',
      filters: 'Filtri',
      removeFilters: 'Rimuovi Filtri',
    },
    message: {
      ...italianMessages.ra.message,
      delete_content: 'Sei sicuro di voler eliminare questo elemento?',
    },
  },
  SendInviteButton: {
    label: 'Invia Invito',
  },

  CalendarPlanning: {
    title: 'Pianificazione Eventi',
    viewMode: {
      twoWeeks: '2 Settimane',
      week: 'Settimana',
      day: 'Giorno',
    },
    resourceMode: {
      vehicle: 'Per Veicolo',
      teamMember: 'Per Istruttore',
    },
  },
  CalendarToolbar: {
    today: 'Oggi',
    previousDay: 'Precedente',
    nextDay: 'Successivo',
    closeCalendarSidebar: 'Chiudi barra laterale',
    openCalendarSidebar: 'Apri barra laterale',
  },
  PlanningResourceConfiguration: {
    filterResources: 'Filtra Veicoli/Istruttori',
    changeCalendarResourceViewMode: 'Visualizza calendario per',
    groupByTeamMembers: 'Per dipendenti',
    groupByVehicles: 'Per veicoli',
    selectVisibleVehicles: 'Seleziona Veicoli',
    selectVisibleTeamMembers: 'Seleziona Dipendenti',
  },
  PlanningCustomersList: {
    searchCustomers: 'Cerca clienti',
  },
  PlanningEventTemplatesList: {
    searchEventTemplates: 'Cerca Eventi Rapidi',
  },
  dayOfWeek: {
    MONDAY: 'Lunedì',
    TUESDAY: 'Martedì',
    WEDNESDAY: 'Mercoledì',
    THURSDAY: 'Giovedì',
    FRIDAY: 'Venerdì',
    SATURDAY: 'Sabato',
    SUNDAY: 'Domenica',
  },
  contactTypes: {
    sms: 'Cellulare',
    email: 'E-mail',
    telegram: 'Telegram',
  },
  CustomerForm: {
    mainInfo: 'Informazioni Principali',
    otherInfo: 'Altre Informazioni',
  },
  uploadPictureButton: {
    label: 'Carica Immagine',
  },
  uploadDocumentButton: {
    label: 'Carica Documento',
  },
  QuickCreateCustomerCoursePlanningButton: {
    label: 'Aggiungi Studente al corso',
  },
  QuickEditCourseScheduleButton: {
    label: 'Gestisci Pianificazione Corso',
  },
  QuickCreateCustomerExamPlanningButton: {
    label: "Aggiungi Studente all' esame",
  },
  QuickCreateCustomerContactButton: {
    label: 'Aggiungi contatto',
  },
  QuickCreateCustomerDrivingLicenseButton: {
    label: 'Aggiungi patente',
  },
  QuickCreateCourseLessonButton: {
    label: 'Registra nuova lezione',
  },
  CustomerContactsCard: {
    notificationEnabled: 'Notifiche Abilitate',
    notificationDisabled: 'Notifiche non attive',
  },
  custom: {
    noData: 'N.D.',
  },
  menu: {
    categories: {
      organizations: 'Organizzazioni',
      planning: 'Pianificazioni',
      vehicles: 'Veicoli',
      users: 'Users',
      localization: 'Localizzazione',
      catalog: 'Catalogo',
      order: 'Preventivi e Ordini',
      customers: 'Clienti',
      coursesAndExams: 'Corsi ed Esami',
    },
  },
  resources: {
    Organization: {
      name: 'Organizzazione |||| Organizzazioni',
      fields: {
        name: 'Nome',
        vat: 'Partita IVA',
        email: 'E-mail',
        phone: 'Telefono',
      },
    },
    Office: {
      name: 'Sede |||| Sedi',
      fields: {
        name: 'Nome',
        email: 'E-mail',
        phone: 'Telefono',
        organizationId: 'Organizzazione',
        countryId: 'Nazione',
        stateId: 'Provincia',
        city: 'Città',
        street: 'Via',
        postCode: 'CAP',
      },
    },
    TeamMember: {
      name: 'Dipendente |||| Dipendenti',
      fields: {
        firstName: 'Nome',
        lastName: 'Cognome',
        birthday: 'Data di nascita',
        calendarColor: 'Colore nel calendario',
        fiscalCode: 'Codice fiscale',
      },
    },
    Customer: {
      name: 'Cliente |||| Clienti',
      fields: {
        firstName: 'Nome',
        lastName: 'Cognome',
        fullName: 'Nome',
        fiscalCode: 'Codice Fiscale',
        profilePictureId: 'Immagine profilo',
        birthday: 'Data di Nascita',
        note: 'Note',
        email: 'E-mail',
        phone: 'Numero di telefono',
        countryId: 'Nazione di Nascita',
        mainReferenceTeamMemberId: 'Istruttore di riferimento',
        subscriptionOfficeId: 'Ufficio di riferimento',
        address: 'Indirizzo di residenza',
        ean: 'EAN Cliente',
        createdAt: 'Creato il',
      },
    },
    CustomerAddress: {
      name: 'Contatto |||| Contatti',
      fields: {
        contactType: 'Tipologia/Mezzo di comunicazione',
        contact: 'Contatto',
        notificationEnabled: 'Notifiche Abilitate',
        description: 'Descrizione',
      },
    },
    Address: {
      name: 'Indirizzo |||| Indirizzi',
      fields: {
        firstName: 'Nome',
        lastName: 'Cognome',
        street: 'Indirizzo',
        postCode: 'C.A.P.',
        city: 'Città',
        phone: 'Telefono',
        customer: 'Cliente',
        country: 'Nazione',
      },
      sections: {
        associatedCustomer: 'Cliente associato',
        addressInfo: 'Informazioni indirizzo',
      },
    },
    Product: {
      name: 'Prodotto |||| Prodotti',
      fields: {
        name: 'Nome del Prodotto',
        sku: 'SKU',
        productQuantityType: 'Tipo di Prodotto',
        netPrice: 'Prezzo Netto',
        retailPrice: 'Prezzo di Vendita',
        taxId: 'Tassazione',
        description: 'Descrizione',
        active: 'Pubblicato',
        drivingSchoolAttribute: {
          hasLearnerPermits: 'Contiene Foglio Rosa',
          isDrivingTraining: 'È una lezione di guida',
          courseIds: 'Corsi Associati',
          examIds: 'Esami Associati',
        },
        ean8: 'EAN-8',
        numPoints: 'Meccanica EAN-8',
        ean13: 'EAN-13',
        stickersAndAmount: 'Meccanica EAN-13',
        mainCategoryId: 'Short Collection',
        categories: 'Short Collection',
      },
    },
    ProductCategory: {
      name: 'Categoria |||| Categorie',
    },
    ProductAttribute: {
      name: 'Attributo |||| Attributi',
    },
    ProductFeature: {
      name: 'Funzionalità |||| Funzionalità',
    },
    Vehicle: {
      name: 'Veicolo |||| Veicoli',
    },
    VehicleFuel: {
      name: 'Alimentazione Veicolo |||| Alimentazione Veicoli',
    },
    VehicleManufacturer: {
      name: 'Produttore Veicoli |||| Produttori Veicoli',
    },
    VehicleType: {
      name: 'Tipologia Veicolo |||| Tipologie Veicoli',
    },
    VehicleModel: {
      name: 'Modello Veicolo |||| Modelli Veicoli',
    },
    OpeningHour: {
      name: 'Opening Hours',
    },
    EventTemplate: {
      name: 'Evento Rapido |||| Eventi Rapidi',
    },
    EventStatus: {
      name: 'Stato |||| Stati',
      fields: {
        id: 'id',
        name: 'Nome',
      },
    },
    PlannedEvent: {
      name: 'Evento Pianificato |||| Eventi Pianificati',
      invites: {
        success: 'Inviti inviati con successo',
        failure: 'Non tutti gli inviti sono stati inviati',
        error: "Errore nell'invio degli inviti",
      },
      fields: {
        customerIds: 'Studenti',
        teamMemberIds: 'Istruttori',
        customerEventStatus: 'Stato',
        title: 'Titolo',
        startDate: 'Data di inizio',
        endDate: 'Data di fine',
        vehicleId: 'Veicolo',
        description: 'Descrizione',
      },
    },
    Country: {
      name: 'Nazione |||| Nazioni',
    },
    State: {
      name: 'Provincia |||| Province',
    },
    Currency: {
      name: 'Valuta |||| Valute',
    },
    Tax: {
      name: 'Tassazione |||| Tassazioni',
    },
    Estimate: {
      name: 'Preventivo |||| Preventivi',
      fields: {
        product: 'Prodotto',
        quantity: 'Quantità',
        price: 'Prezzo netto',
        tax: 'Tassa (%)',
        createdAt: 'Creato il',
        customer: 'Studente',
        approved: 'Approvato',
        totalAmount: 'Totale',
      },
    },
    Order: {
      name: 'Ordine |||| Ordini',
      fields: {
        createdAt: 'Creato il',
        expiringDate: 'Data scadenza',
        closed: 'Chiuso?',
        amountToPay: 'Da pagare',
        amountPayed: 'Pagato',
        totalAmount: 'Totale',
        customer: 'Cliente',
        product: 'Prodotto',
        quantity: 'Quantità',
        price: 'Prezzo netto',
        tax: 'Tassa (%)',
        notes: 'Note',
      },
      sections: {
        customer: 'Cliente',
        list: 'Lista prodotti',
        summary: 'Resoconto',
        info: 'Informazioni aggiuntive',
      },
    },
    Document: {
      name: 'Documento |||| Documenti',
      fields: {
        id: 'id',
        description: 'Descrizione',
        url: 'Url di download',
      },
      sections: {
        general: 'Generali',
      },
    },
    Course: {
      name: 'Corso |||| Corsi',
      fields: {
        name: 'Nome',
      },
    },
    Exam: {
      name: 'Esame |||| Esami',
      fields: {
        name: 'Nome',
      },
    },
    ExamPlanning: {
      name: 'Pianificazione Esame |||| Pianificazioni Esame',
      fields: {
        teamMemberIds: 'Istruttori',
        date: 'Data',
        startDate: 'Data Inizio Esame',
        endDate: 'Data Fine Esame',
        totalPartecipants: 'Numero di Partecipanti',
      },
    },
    DrivingLicense: {
      name: 'Patente |||| Patenti',
      fields: {
        licenceNumber: 'Numero Patente',
        licenceCode: 'Codice Patente',
        releaseDate: 'Data di rilascio',
        expiringDate: 'Data di scadenza',
        // eslint-disable-next-line quotes
        releasedFrom: "Rilasciato dall'ente",
        drivingLicenceCategoryId: 'Tipologie patenti',
      },
    },
    CoursePlanning: {
      name: 'Pianificazione Corso |||| Pianificazioni Corso',
      fields: {
        startDate: 'Data Inizio Corso',
        endDate: 'Data Fine Corso',
        totalPartecipants: 'Numero di Partecipanti',
        profilePictureId: 'Foto',
        teamMemberId: 'Referente',
      },
    },
    CourseSchedule: {
      name: 'Schedulazione Corso |||| Schedulazioni Corso',
    },
    SmsTemplate: {
      name: 'Template SMS |||| Template SMS',
    },
    EmailTemplate: {
      name: 'Template Email |||| Template Email',
    },
    TelegramTemplate: {
      name: 'Template Telegram |||| Template Telegram',
    },
    CustomerContact: {
      name: 'Contatti',
      fields: {
        description: 'Descrizione',
        contactType: 'Tipo di contatto',
        notificationEnabled: 'Notifiche Abilitate',
        contact: 'Contatto',
      },
    },
    ProductStock: {
      fields: {
        officeId: 'Punto Vendita',
        productId: 'Prodotto',
      },
    },
    User: {
      name: 'Utente |||| Utenti',
      fields: {
        fullName: 'Cognome e Nome',
        firstName: 'Nome',
        lastName: 'Cognome',
        createdAt: 'Creato il',
        active: 'Attivo',
      },
    },
    ShortCollection: {
      name: 'Short Collection',
      new: 'Nuova Short Collection',
      sections: {
        shortCollection: 'Short Collection',
        availability: 'Validità',
        brand: 'Brand',
      },
      fields: {
        name: 'Nome',
        startDate: 'Data di inizio',
        endDate: 'Data di fine',
        cleanUpDate: 'Data di clean-up',
        active: 'Pubblica',
        activeExtended: 'Vuoi pubblicare subito la collection?',
        productsNumber: 'Num. Prodotti',
        description: 'Descrizione',
        brandName: 'Nome del Brand',
        brandImage: 'Immagine del Brand',
        ean8: 'EAN-8',
        ean13: 'EAN-13',
        numPoints: 'Meccanica EAN-8',
        stickersAndAmount: 'Meccanica EAN-13',
        dateRange: {
          startDate: 'Data di inizio (range)',
          endDate: 'Data di fine (range',
        },
      },
      actions: {
        addProduct: 'Aggiungi prodotto',
      },
    },
    Reservation: {
      name: 'Prenotazione |||| Prenotazioni',
      fields: {
        createdAt: 'Creata il',
        customerFullName: 'Nome Cliente',
        customerEan: 'EAN Cliente',
        dateRange: {
          startDate: 'Data di Inizio (range)',
          endDate: 'Data di Fine (range)',
        },
        officeId: 'Punto Vendita',
        productCategoryId: 'Nome Short Collection',
        reservationCustomerId: 'Cognome e Nome Cliente',
      },
    },
  },
  customFields: {
    galleryInput: {
      title: 'Catalog Pictures',
      mobileTitle: 'Mobile Catalog Pictures',
      galleryTitle: 'Product Details Gallery',
      modal: {
        error: 'Error uploading picture',
        success: 'Picture uploaded correctly',
        title: {
          addToGallery: 'Add new to Gallery',
          setCatalog: 'Set Primary Picture',
          setSecondaryCatalog: 'Set Secondary Catalog Picture',
        },
      },
    },
    galleryGrid: {
      setFeaturedImage: 'Set Featured Picture',
      catalogImage: 'Primary',
      catalogSecondaryImage: 'Secondary',
      featured: 'Featured',
      featuredImage: 'Featured',
      addToGallery: 'Add to gallery',
      setCatalogImage: 'Set Catalog Image',
      setCatalogSecondaryImage: 'Set Secondary Image',
    },
    orderStatus: {
      confirmed: 'Confirmed',
      sent: 'Sent',
      confirmedAndPaid: 'Paid',
      canceled: 'Canceled',
      canceledWithPenalty: 'Canceled with penalty',
      canceledWithPenaltyAndPaid: 'Canceled with penalty and paid',
    },
    priceSection: {
      title: 'Price',
    },
    discountSection: {
      title: 'Sale',
    },
    orderProductTableField: {
      subtotal: 'Subtotal',
      shippingCost: 'Shipping',
      tax: 'Tax',
      total: 'Total',
    },
    orderCustomerField: {
      // eslint-disable-next-line quotes
      noRaffleParticipations: "User hasn't participated to any raffle yet",
      nbPartecipations: '%{total} raffle partecipation |||| %{total} raffle partecipations',
      noPurchases: 'No purchases',
      nbPurchases: '%{total} purchase |||| %{total} purchases',
      customerAgreeNewsletter: 'Accept newsletter e-mails',
    },
    countryState: {
      country: 'Nazione',
      state: 'Provincia',
    },
  },
  products: {
    edit: {
      tabs: {
        basicSettings: 'Impostazioni Generali',
        options: 'Opzioni',
        pricing: 'Gestione Prezzo',
        shipping: 'Spedizione',
        combinations: 'Combinazioni',
        // drivingSchoolAttributes: 'Impostazioni',
      },
    },
  },
}
