import { useApolloClient } from '@apollo/client'
import React, { FC, useEffect, useState } from 'react'
import { QUERY_GET_ACTIVITY_TYPES } from '../../queries'
import { useForm } from 'react-final-form'
import { useNotify } from 'react-admin'
import { SelectInput } from 'ra-ui-materialui'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { SelectInputProps } from '@material-ui/core/Select/SelectInput'
import { ActivityTypeItem } from './ActivityTypeItem'

type Props = {
  source?: string
} & Partial<SelectInputProps>

export const ActivityTypeSelectInput: FC<Props> = ({ source = 'type', ...rest }) => {
  const classes = useStyles()
  const client = useApolloClient()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [activityTypes, setActivityTypes] = useState<{ id: string; label?: string; icon?: string }[] | undefined>(
    undefined
  )
  const { change, getState } = useForm()
  const notify = useNotify()

  useEffect(() => {
    const fetchActivityTypes = async (): Promise<void> => {
      try {
        const { data, error } = await client.query({
          query: QUERY_GET_ACTIVITY_TYPES,
          variables: {
            pagination: {
              disabled: true,
            },
            sort: {
              label: 'ASC',
            },
          },
        })

        if (error) {
          throw new Error(error.message)
        }

        if (!data || !data.activityTypes || !data.activityTypes.data) {
          throw new Error('Error finding activity types')
        }

        const activityTypes = data.activityTypes.data
          .filter((item: any) => !!item && !item.isNote)
          .map((item: any) => ({ id: item.id, label: item.label, icon: item.icon }))
        setActivityTypes(activityTypes)

        if (!getState().values[source]) {
          if (activityTypes?.length > 0) {
            const foundedActivity = activityTypes.find((item: any) => item.id === '2')
            if (foundedActivity) {
              change(source, foundedActivity.id)
            }
          }
        }
      } catch (error) {
        console.error('Error fetching activity types', error)
        notify('Error fetching activity types', 'error')
      }
    }

    setInitialized(false)

    fetchActivityTypes().finally(() => {
      setInitialized(true)
    })
  }, [change])

  if (!initialized) {
    return <Skeleton variant="rect" className={classes.skeleton} />
  }

  return (
    <SelectInput
      className={classes.input}
      {...({ variant: 'standard', label: '' } as any)}
      source={source}
      optionText={<ActivityTypeItem />}
      choices={activityTypes}
      {...rest}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  skeleton: {
    width: '100%',
    height: '40px',
    marginBottom: '37px',
  },
  input: {
    minWidth: 200,
    '& .MuiSelect-root': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}))
