// import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react'

type Props = {
  children?: React.ReactNode
  index: any
  value: any
}

const TabPanel: FC<Props> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {
        value === index && children // </Box> //   <Typography>{children}</Typography> // <Box p={3}>
      }
    </div>
  )
}

export default TabPanel
