import React, { FC } from 'react'
import SectionedForm from '../../../components/forms/SectionedForm'
import Create from '../../../components/details/Create'
import TaxForm from './VehicleCarbonOffsetForm'

type Props = {}

const VehicleCarbonOffsetCreate: FC<Props> = (props) => (
  <Create {...props} component="div">
    <SectionedForm variant="outlined" redirect="list">
      <TaxForm {...props} />
    </SectionedForm>
  </Create>
)

export default VehicleCarbonOffsetCreate
