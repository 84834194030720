import { useGetOne } from 'ra-core'
import { useMemo } from 'react'

export type CurrencyMaskType = {
  prefix: string
  suffix: string
  includeThousandsSeparator: boolean
  thousandsSeparatorSymbol: string
  allowDecimal: boolean
  decimalSymbol: string
  decimalLimit: number
  integerLimit: number | null
  allowNegative: boolean
  allowLeadingZeroes: boolean
}

const currencyToMaskConverter = (currency: Record<string, any>): CurrencyMaskType => {
  const mask = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true, // this is always true
    thousandsSeparatorSymbol: '',
    allowDecimal: true, // always true
    decimalSymbol: '',
    decimalLimit: 2, // always 2
    integerLimit: null, // always null
    allowNegative: true, // always true
    allowLeadingZeroes: false,
  }
  // setting sign on prefix/suffix with optional space
  const indexOfSign = currency.format.indexOf('x')
  if (indexOfSign === 0) {
    mask.prefix = `${currency.sign}${currency.showSpace ? ' ' : ''}`
  }
  if (indexOfSign > 0) {
    mask.suffix = `${currency.showSpace ? ' ' : ''}${currency.sign}`
  }
  // searching for thousand/decimal separator
  const formatWithoutSign = (currency.format as string).replace('x', '')
  mask.thousandsSeparatorSymbol = formatWithoutSign.charAt(1)
  mask.decimalSymbol = formatWithoutSign.charAt(5)

  return mask
}

const useCurrencySettings = (currencyId: any): Record<string, any> => {
  const { loading, error, data } = useGetOne('Currency', currencyId)

  const mask = useMemo((): CurrencyMaskType | null => {
    if (!data) return null
    else return currencyToMaskConverter(data)
  }, [data, currencyId])

  return { loading, error, data, mask }
}

export default useCurrencySettings
