import React, { FC, useCallback, useState } from 'react'
import MailIcon from '@material-ui/icons/Mail'
import { Button, Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CreateMailForm, CreateMailFormProps } from './CreateMailForm'
import DrawerHeader from '../DrawerHeader'

type Props = {
  //
} & CreateMailFormProps

export const CreateMailDrawerButton: FC<Props> = ({ onMailSent, ...props }) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleButtonClick = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleMailSent = useCallback(async () => {
    if (onMailSent) {
      console.log('onMailSent')
      await onMailSent()
    }
    setIsOpen(false)
  }, [onMailSent])

  return (
    <>
      <Button className={classes.mailDrawerButton} variant="contained" onClick={handleButtonClick}>
        <MailIcon />
        {props.children}
      </Button>

      <Drawer classes={{ paper: classes.paper }} anchor="right" open={isOpen} onClose={handleMailSent}>
        <DrawerHeader buttonOnClick={handleMailSent} title={`Send ${props.type}`} />
        <CreateMailForm onMailSent={handleMailSent} {...props} />
      </Drawer>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  mailDrawerButton: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  paper: {
    padding: '20px',
    minWidth: '350px',
    maxWidth: '100%',
    'media(max-width: 375px)': {
      width: '100%',
    },
  },
}))
