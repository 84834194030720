import React from 'react'
import {
  Center,
  Heading,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Paper } from '@material-ui/core'
import { TableContent } from './components/TableContent'

export type MostFrequentRoutes = {
  departureAirport: {
    id: string
    name: string
    city: string
  }
  arrivalAirport: {
    id: string
    name: string
    city: string
  }
  count: number
}

interface MostFrequentRoutesCardProps {
  data?: MostFrequentRoutes[]
  loading?: boolean
  error?: boolean
}

export const MostFrequentRoutesCard = ({ data, loading, error }: MostFrequentRoutesCardProps) => {
  return (
    <Paper style={{ display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
      <Heading as="h4" size="sm" m="2">
        Most frequent routers
      </Heading>
      <TableContainer overflowY="auto" overflowX="auto" maxHeight="10rem">
        <Table size="sm" variant="simple">
          <Thead position="sticky" top={0} bgColor="gray.50">
            <Tr>
              <Th>Departure</Th>
              <Th>Arrival</Th>
              <Th isNumeric w="1%">
                Flights
              </Th>
            </Tr>
          </Thead>
          <TableContent loading={loading} error={error}>
            <Tbody overflow="auto">
              {data?.map((route) => (
                <Tr key={`${route.departureAirport.id}-${route.arrivalAirport.id}`}>
                  <Td>
                    <Stack isInline alignItems="center">
                      <Tag size="sm" colorScheme="gray">
                        {route.departureAirport.id}
                      </Tag>
                      <Text fontSize="sm">{route.departureAirport.city}</Text>
                    </Stack>
                  </Td>
                  <Td>
                    <Stack isInline alignItems="center">
                      <Tag size="sm" colorScheme="gray">
                        {route.arrivalAirport.id}
                      </Tag>
                      <Text fontSize="sm">{route.arrivalAirport.city}</Text>
                    </Stack>
                  </Td>
                  <Td isNumeric w="1%">
                    {route.count}
                  </Td>
                </Tr>
              ))}
              {data === undefined && (
                <Center>
                  <Spinner size="lg" m="4" />
                </Center>
              )}
            </Tbody>
          </TableContent>
        </Table>
      </TableContainer>
    </Paper>
  )
}
