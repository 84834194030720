import React, { FC, MouseEvent } from 'react'
import { ImageField, ImageInput } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { FaPlus } from 'react-icons/fa'

type Props = {
  [x: string]: any
}

const onPlaceholderClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>): void => event.preventDefault()
// TODO: make a stand-alone component (self responsible of api calls)
const ImageGalleryInput: FC<Props> = ({ label, ...props }) => {
  const classes = useStyles()

  return (
    <>
      {label && <Typography>{label}</Typography>}
      <ImageInput
        source={props.source}
        label=""
        accept="image/*"
        multiple
        classes={{
          root: classes.imgRoot,
          dropZone: classes.imgDropzone,
          preview: classes.imgPreviews,
          removeButton: classes.imgRemoveButton,
        }}
        placeholder={
          <button className={classes.imgDropzone} onClick={onPlaceholderClick}>
            <FaPlus color="white" />
          </button>
        }
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  imgRoot: {
    display: 'flex',
    flexDirection: 'row',
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .previews': {
      display: 'flex',
      overflow: 'scroll',
    },
  },
  imgDropzone: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    display: 'inline-block',
    border: 'none',
    width: '70px',
    height: '70px',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0),
    marginRight: theme.spacing(2),
    '& > button': {
      marginRight: theme.spacing(0),
      outline: 'none',
    },
  },
  imgPreviews: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '70px',
    width: '70px',
    padding: theme.spacing(0),
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],
    marginRight: theme.spacing(2),
    '& img': {
      width: '70px',
      margin: theme.spacing(0),
    },
  },
  imgRemoveButton: {
    position: 'relative',
    '& button': {
      position: 'absolute',
      left: '5px',
      top: '5px',
      padding: 0,
      backgroundColor: theme.palette.background.paper,
    },
    '& button svg': {
      width: '0.8em',
      height: '0.8em',
    },
  },
}))

export default ImageGalleryInput
