import React, { FC, useCallback } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField, FunctionField } from 'react-admin'
import TaxFilters from './TaxFilters'
import GenericMoreMenu from '../../components/GenericMoreMenu'
import Datagrid from '../../components/Datagrid'
import { FaPercentage } from 'react-icons/fa'

const sort = { field: 'rate', order: 'ASC' }
// const filterDefaultValues = { active: true }

type Props = {}

const TaxList: FC<Props> = (props) => {
  const render = useCallback(({ rate }) => `${rate}%`, [])
  return (
    <RAList {...props} sort={sort} filters={<TaxFilters variant="outlined" />} titleIcon={<FaPercentage />}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="name" />
        <FunctionField source="rate" render={render} />
        {/* <BooleanField source="active" /> */}
        <GenericMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default TaxList
