import React, { FC } from 'react'
import { TextInput, NumberInput } from 'react-admin'
import FormSection from '../../components/forms/FormSection'
import InputColor from './../../components/InputColor'

type Props = {
  //
}

const EventStatusForm: FC<Props> = (props) => (
  <FormSection title="Event Template" {...props}>
    <TextInput source="name" />
    <NumberInput source="order" />
    <InputColor source="color" />
  </FormSection>
)

export default EventStatusForm
