import React, { FC } from 'react'
import { useTranslate } from 'ra-core'
import SectionedForm from '../SectionedForm'
import FormSection from '../FormSection'
import Create from '../../details/Create'
import FormTextInput from '../../FormTextInput'

type Props = {
  [x: string]: any
}

const NameOnlyCreate: FC<Props> = (props) => {
  const translate = useTranslate()

  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="list" title={translate(`resources.${props.resource}.titles.create`)}>
        <FormSection title="">
          <FormTextInput source="name" />
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default NameOnlyCreate
