import React, { FC } from 'react'
import { TextField } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/details/Edit'
import EmailTemplateForm from './EmailTemplateForm'

type Props = {
  //
}

const EmailTemplateEdit: FC<Props> = (props) => (
  <Edit {...props} component="div">
    <SectionedForm variant="outlined">
      <FormSection title="ID">
        <TextField source="id" addLabel={false} />
      </FormSection>
      <EmailTemplateForm />
    </SectionedForm>
  </Edit>
)

export default EmailTemplateEdit
