import React, { FC } from 'react'
import { useGetOne } from 'ra-core'
import LinearProgress from '@material-ui/core/LinearProgress'

const RouteCarbonOffsetField: FC<any> = ({ carbonOffsetId, totalDuration }) => {
  const { loading, data } = useGetOne('VehicleCarbonOffset', carbonOffsetId as string, { enabled: !!carbonOffsetId })

  if (loading) return <LinearProgress />
  if (data) return <span>{((data.value * totalDuration) / 60).toFixed(2)}</span>
  return null
}

export default RouteCarbonOffsetField
