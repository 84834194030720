import React, { FC } from 'react'
import { TextInput, BooleanInput } from 'react-admin'
import FormSection from './../../components/forms/FormSection'

type Props = {}

const CountryForm: FC<Props> = props => (
  <FormSection title="Country Info" {...props}>
    <TextInput source="name" />
    <TextInput source="isoAlpha2" />
    <TextInput source="isoAlpha3" />
    <TextInput source="isoNumeric" />
    <TextInput source="phonePrefix" />
    {/* <ReferenceInput label="Zone" source="zoneId" reference="Zone">
      <SelectInput optionText="name" />
    </ReferenceInput> */}
    <BooleanInput source="idNumberMandatory" />
    <BooleanInput source="taxIncluded" />
    <BooleanInput source="active" defaultValue={true} />
  </FormSection>
)

export default CountryForm
