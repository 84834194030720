import React, { FC, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextInput } from 'react-admin'
import { FormWithRedirect, useNotify, useRefresh } from 'ra-core'
import Create from '../../components/details/Create'
import PlannedEventDatesInput from '../../components/PlannedEventDatesInput'
import { useHistory, useLocation } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { SaveButton } from 'react-admin'
import { BooleanInput } from 'react-admin'
import { AutocompleteArrayInput, AutocompleteInput, ReferenceArrayInput, ReferenceInput } from 'ra-ui-materialui'
import { EventPersonInput } from './EventPersonInput'

type Props = any

const vehicleFilterToQuery = (licensePlate: string): any => ({
  licensePlate,
})

const useStyles = makeStyles((theme) => ({
  suggestionsContainer: {
    zIndex: 1300,
  },
}))

const EventCreateForm: FC<any> = (props) => {
  const classes = useStyles()

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any): any => (
        // here starts the custom form layout
        <form>
          <Box d="flex" flexDir="column">
            <PlannedEventDatesInput {...props} />
            <TextInput source="description" fullWidth {...props} variant="outlined" />
            <BooleanInput source="allDay" />
            <ReferenceArrayInput
              label="Team members"
              source="teamMemberIds"
              reference="TeamMember"
              variant="outlined"
              fullWidth
              {...props}
            >
              <AutocompleteArrayInput
                options={{
                  suggestionsContainerProps: {
                    className: classes.suggestionsContainer,
                  },
                }}
                optionText="fullName"
                optionValue="id"
              />
            </ReferenceArrayInput>
            <ReferenceInput source="customerId" reference="Customer" variant="outlined" fullWidth allowEmpty>
              <AutocompleteInput
                options={{
                  suggestionsContainerProps: {
                    className: classes.suggestionsContainer,
                  },
                }}
                optionText="fullName"
                optionValue="id"
              />
            </ReferenceInput>
            <EventPersonInput />
            <ReferenceInput source="quoteId" reference="Quote" variant="outlined" fullWidth allowEmpty>
              <AutocompleteInput
                options={{
                  suggestionsContainerProps: {
                    className: classes.suggestionsContainer,
                  },
                }}
                optionText={(choice: any): string =>
                  choice?.reference ? `#${choice?.reference?.substring(choice?.reference?.length - 5)}` : ''
                }
                optionValue="id"
              />
            </ReferenceInput>
            <ReferenceInput source="orderId" reference="Order" variant="outlined" fullWidth allowEmpty>
              <AutocompleteInput
                options={{
                  suggestionsContainerProps: {
                    className: classes.suggestionsContainer,
                  },
                }}
                optionText={(choice: any): string =>
                  choice?.reference ? `#${choice?.reference?.substring(choice?.reference?.length - 5)}` : ''
                }
                optionValue="id"
              />
            </ReferenceInput>
          </Box>
          <SaveButton
            size="large"
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            // pristine={formProps.pristine}
            saving={formProps.saving}
            disabled={formProps.pristine || formProps.saving}
            fullWidth
          />
        </form>
      )}
    />
  )
}

const PlannedEventCreate: FC<Props> = (props) => {
  const classes = useStyles(props)
  const notify = useNotify()
  const history = useHistory()
  const { search } = useLocation()
  const refresh = useRefresh()

  const onSuccess = useCallback(({ data }) => {
    notify('Event created successfully')
    history.push({
      pathname: '/Event',
      search: search,
    })
    refresh()
  }, [])

  return (
    <Create {...props} onSuccess={onSuccess} component="div">
      <EventCreateForm />
    </Create>
  )
}

export default PlannedEventCreate
