import React, { FC } from 'react'
import { TextInput, NumberInput } from 'react-admin'
import FormSection from '../../components/forms/FormSection'
import InputColor from '../../components/InputColor'
import RichTextInput from 'ra-input-rich-text'

type Props = {
  //
}

const EmailTemplateForm: FC<Props> = (props) => (
  <FormSection title="Email Template" {...props}>
    <TextInput source="name" />
    <TextInput fullWidth multiline source="content" />
    <RichTextInput variant="outlined" source="html" />
  </FormSection>
)

export default EmailTemplateForm
