import React, { FC, useCallback } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField, Datagrid, EditButton } from 'react-admin'
import EventTemplateFilters from './EventTemplateFilters'
import { ColorField } from 'react-admin-color-input'

const sort = { field: 'title', order: 'ASC' }
// const filterDefaultValues = { active: true }

type Props = {}

const EventTemplate: FC<Props> = (props) => {
  const render = useCallback(({ rate }) => `${rate}%`, [])
  return (
    <RAList {...{ sort }} {...props} filters={<EventTemplateFilters />}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="title" />
        <NumberField source="duration" />
        <ColorField source="color" />
        <TextField source="description" />
        <EditButton />
      </Datagrid>
    </RAList>
  )
}

export default EventTemplate
