import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiDrawer, { DrawerProps } from '@material-ui/core/Drawer'

type Props = DrawerProps

const Drawer: FC<Props> = (props) => {
  const classes = useStyles(props)
  return <MuiDrawer anchor="right" {...props} classes={classes} />
}

const useStyles = makeStyles((theme) => ({
  paper: {
    // width: '30vw',
    width: '26vw',
    minWidth: 260,
    // minWidth: 600,
    // maxWidth: 700,
    padding: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      width: '100vw',
    },
  },
}))

export default Drawer
