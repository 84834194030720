import React, { FC } from 'react'
import { required as requiredValidator, TextInput } from 'react-admin'
import Flag from 'react-world-flags'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useTranslate } from 'ra-core'

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    width: '50%',
    alignSelf: 'stretch',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  helper: {
    width: '50%',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(8),
  },
  input: {
    width: '100%',
  },
  flag: {
    maxHeight: '1rem',
    maxWidth: '1.5rem',
  },
}))

type Props = {
  source?: string
  [x: string]: any
}

const FormTextInput: FC<Props> = ({ required, source = '', ...props }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { values } = useFormState()

  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputWrapper}>
        <TextInput
          {...props}
          source={source}
          className={classes.input}
          helperText={undefined}
          validate={required ? requiredValidator() : undefined}
        />
      </div>
      <Typography className={classes.helper} variant="body2">
        {values && values[source] ? (
          <Flag
            code={values[source]}
            fallback={<span>{translate('customFields.language.inputUnknown')}</span>}
            className={classes.flag}
          />
        ) : (
          translate('customFields.language.inputHelper')
        )}
      </Typography>
    </div>
  )
}

export default FormTextInput
