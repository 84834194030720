import React, { FC, useCallback, useState } from 'react'
import { FormWithRedirect, useGetOne, useTranslate, useDataProvider, useNotify, useRedirect, useRefresh } from 'ra-core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
// internal components
import Loading from '../../components/Loading'
import SaveButton from '../../components/button/SaveButton'
import AddressFormSection from '../../components/AddressFormSection'

type Props = {
  id?: string | number
  customerId?: string | number
  [x: string]: any
}

const InDrawerAddressEdit: FC<Props> = ({ id = 0, customerId, ...props }) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [saving, setSaving] = useState<boolean>(false)
  const { loading, error, data } = useGetOne('Address', id, { enabled: id !== 0 })

  const onSubmit = useCallback(
    async (values) => {
      try {
        setSaving(true)
        if (id > 0) {
          const result = await dataProvider.update('Address', {
            id,
            data: { ...values },
            previousData: { id },
          })
        } else {
          const result = await dataProvider.create('Address', {
            data: { ...values, alias: 'NoAlias', customerId },
          })
        }
        // console.log('update?? ', result)
        refresh()
        redirect(`/Customer/${customerId}/show`)
        // UNABLE TO USE ra.notification.updated (smart count not working :/)
        notify('ra.notify.updated')
      } catch (error) {
        console.log('update error: ', error)
        notify('ra.message.error', 'error')
      } finally {
        setSaving(false)
      }
    },
    [data, id]
  )

  if (loading) {
    return <Loading />
  }

  if (error && id !== 0) {
    return <Typography color="error">{translate('ra.page.error')}</Typography>
  }

  return (
    <FormWithRedirect
      {...props}
      record={data}
      resource="Address"
      save={onSubmit}
      // "saving" boolean coming out from render not working ://
      render={({ handleSubmitWithRedirect, pristine, record }: any): any => (
        <Box display="flex" flexDirection="column">
          <AddressFormSection record={record} referenceField="id" inDrawer />
          <Box mt={3}>
            <SaveButton
              size="large"
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              pristine={pristine}
              saving={saving}
              disabled={pristine || saving}
            />
          </Box>
        </Box>
      )}
    />
  )
}

export default InDrawerAddressEdit
