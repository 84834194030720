import React, { FC } from 'react'
import TextField from '@material-ui/core/TextField'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'

const defaultMaskOptions = {
  prefix: '',
  suffix: ' %',
  includeThousandsSeparator: false,
  // thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 6, // how many digits allowed after the decimal
  integerLimit: null, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: true,
}

type Props = {
  variant?: string
  label?: string
  name?: string
  value: string
  onChange: (event: any) => void
  classes?: Record<string, any>
  [x: string]: any
}

const MuiPercentageInput: FC<Props> = ({ variant = 'outlined', label, name, value, onChange, classes, ...rest }) => {
  return (
    <TextField
      {...rest}
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      InputProps={{
        classes: { ...classes },
        inputComponent: TextMaskCustom as any,
      }}
    />
  )
}

const TextMaskCustom: FC<any> = (props: any) => {
  const { inputRef, ...other } = props
  const currencyMask = createNumberMask(defaultMaskOptions)

  return <MaskedInput {...other} ref={(ref: any): any => inputRef(ref ? ref.inputElement : null)} mask={currencyMask} />
}

export default MuiPercentageInput

// export const formatCurrencyInput = (value: string): number | string =>
//   value ? parseFloat(value.replace('€ ', '').replaceAll('.', '').replace(',', '.').trim()) : value
