import React, { FC, useCallback } from 'react'
import { Edit } from 'react-admin'
import { Record, useTranslate, useDataProvider, useNotify } from 'ra-core'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
// TODO: FIXME: UNABLE TO USE OUR EDIT COMPONENT (transform prop incompatibility)
// import Edit from '../../components/details/Edit'
import FormTextInput from '../../components/FormTextInput'
import AddressFormSection from '../../components/AddressFormSection'

type Props = {
  [x: string]: any
}

const OrganizationCreate: FC<Props> = (props) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const transform = useCallback(async (data: Record): Promise<Record | undefined> => {
    try {
      const address = {
        alias: data.name,
        phone: data.phone,
        countryId: data.countryId,
        city: data.city,
        street: data.street,
        postCode: data.postCode,
      }
      delete data.countryId
      delete data.city
      delete data.street
      delete data.postCode

      if (data.addressId) {
        const addressResult = await dataProvider.update('Address', {
          id: data.addressId,
          data: address,
          previousData: { id: data.addressId },
        })
      } else {
        if (!!data.phone || !!data.countryId || !!data.city || !!data.street || !!data.postCode) {
          const addressResult = await dataProvider.create('Address', { data: address })
          // console.log('addressResult: ', addressResult)
          if (!addressResult.data.id) {
            throw new Error('error creating new address')
          }
          data.addressId = addressResult.data.id
        }
      }

      // console.log('addressResult: ', addressResult)
      return data
    } catch (error) {
      console.error('update/create address error (organization): ', error)
      notify(error, 'error')
    }
  }, [])

  return (
    <Edit {...props} component="div" transform={transform} actions={false}>
      <SectionedForm variant="outlined" redirect="show" title={translate('resources.Organization.titles.edit')}>
        <FormSection title={translate('resources.Organization.titles.company')}>
          <FormTextInput source="name" />
          <FormTextInput source="email" />
          <FormTextInput source="vat" />
          <FormTextInput source="phone" />
        </FormSection>
        <AddressFormSection sectionTitle={translate('resources.Organization.titles.info')} />
      </SectionedForm>
    </Edit>
  )
}

export default OrganizationCreate
