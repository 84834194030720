import React, { FC, useCallback } from 'react'
import { Create } from 'react-admin'
import { Record, useDataProvider, useTranslate, useNotify } from 'ra-core'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
// import Create from '../../components/details/Create'
import FormTextInput from '../../components/FormTextInput'
import AddressFormSection from '../../components/AddressFormSection'

type Props = {
  [x: string]: any
}

const OrganizationCreate: FC<Props> = (props) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const transform = useCallback(async (data: Record): Promise<Record | undefined> => {
    try {
      if (!!data.phone || !!data.countryId || !!data.city || !!data.street || !!data.postCode) {
        const address = {
          // alias: data.name,
          phone: data.phone,
          countryId: data.countryId,
          city: data.city,
          street: data.street,
          postCode: data.postCode,
        }
        delete data.countryId
        delete data.city
        delete data.street
        delete data.postCode

        const addressResult = await dataProvider.create('Address', { data: address })
        // console.log('addressResult: ', addressResult)
        if (!addressResult.data.id) {
          throw new Error('error creating new address')
        }
        data.addressId = addressResult.data.id
      }
      return data
    } catch (error) {
      console.error('createAddress error (organization): ', error)
      notify(error, 'error')
    }
  }, [])

  return (
    <Create {...props} component="div" transform={transform}>
      <SectionedForm variant="outlined" redirect="list" title={translate('resources.Organization.titles.create')}>
        <FormSection title={translate('resources.Organization.titles.company')}>
          <FormTextInput source="name" required />
          <FormTextInput source="email" />
          <FormTextInput source="vat" />
          <FormTextInput source="phone" />
        </FormSection>
        <AddressFormSection sectionTitle={translate('resources.Organization.titles.info')} />
      </SectionedForm>
    </Create>
  )
}

export default OrganizationCreate
