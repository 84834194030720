import { Avatar, Skeleton } from '@chakra-ui/react'
import { useQuery, useQueryWithStore } from 'ra-core'
import React, { useCallback } from 'react'
import { FC } from 'react'
import { AvatarFilter } from '../../chakra-components/avatar-group-filter/AvatarFilter'
import {
  AvatarGroupFilter,
  AvatarGroupFilterProps,
} from '../../chakra-components/avatar-group-filter/AvatarGroupFilter'

type Props = {
  selectedIds?: string[]
  onSelectedIdsChange?: (selectedIds: string[]) => void
  source?: string
  additionalFilters?: any
} & Omit<AvatarGroupFilterProps, 'children'>

export const TeamMembersFilter: FC<Props> = ({
  selectedIds = [],
  onSelectedIdsChange,
  onAvatarClick,
  source = 'accountId',
  additionalFilters,
  ...props
}) => {
  const { loading, data, error } = useQuery({
    resource: 'TeamMember',
    type: 'getList',
    payload: {
      filter: {
        approved: true,
        ...(additionalFilters || {}),
      },
      sort: {},
      pagination: { page: 1, perPage: 100 },
    },
  })

  const handleAvatarClick = useCallback(
    (id: string | number) => {
      if (!onSelectedIdsChange) {
        return
      }

      if (selectedIds.includes(id)) {
        // if the avatar is already selected, remove it
        onSelectedIdsChange(selectedIds.filter((selectedId) => selectedId !== id))
      } else {
        onSelectedIdsChange([...(selectedIds as string[]), id as string])
      }
    },
    [selectedIds, onSelectedIdsChange]
  )

  if (loading) {
    return (
      <AvatarGroupFilter {...props}>
        <Avatar size="md" as={Skeleton} />
        <Avatar size="md" as={Skeleton} />
        <Avatar size="md" as={Skeleton} />
      </AvatarGroupFilter>
    )
  }

  if (error) {
    return <pre>Unexpected Error</pre>
  }

  return (
    <AvatarGroupFilter selectedIds={selectedIds} onAvatarClick={handleAvatarClick} {...props}>
      {data?.map((teamMember: any) => (
        <AvatarFilter key={`team-member-filter-${teamMember.id}`} id={teamMember[source]} name={teamMember.fullName} />
      ))}
    </AvatarGroupFilter>
  )
}
