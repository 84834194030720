import PermissionRoleList from './PermissionRoleList'
import PermissionRoleCreate from './PermissionRoleCreate'
import PermissionRoleEdit from './PermissionRoleEdit'

export default {
  name: 'PermissionRole',
  list: PermissionRoleList,
  create: PermissionRoleCreate,
  edit: PermissionRoleEdit
}
