import englishMessages from 'ra-language-english'

export default {
  ...englishMessages,
  create: 'Create',
  uploadDocumentButton: {
    label: 'Upload File',
  },
  ra: {
    ...englishMessages.ra,
    boolean: {
      ...englishMessages.ra.boolean,
      null: 'All',
    },
    action: {
      ...englishMessages.ra.action,
      filters: 'Filters',
      removeFilters: 'Remove all filters',
      applyFilters: 'Apply filters',
      nextStep: 'Next step',
      duplicate: 'Duplicate',
    },
    navigation: {
      ...englishMessages.navigation,
      viewing: 'Viewing',
      of: 'of',
      page_rows_per_page: 'Page rows per page',
    },
    notify: {
      updated: 'Element updated',
    },
  },
  menu: {
    categories: {
      vehicles: 'Aircrafts',
      organizations: 'Companies',
      settings: 'Settings',
      clients: 'Clients',
      offers: 'Trip Board',
      customers: 'Customers',
    },
  },
  status: {
    name: 'Status',
    enabled: 'Enabled',
    disabled: 'Disabled',
    null: 'No status',
  },
  statuses: {
    pending: 'Pending',
    sent: 'Sent',
    confirmed: 'Confirmed',
    abandoned: 'Abandoned',
    lost: 'Lost',
  },
  resources: {
    Vehicle: {
      name: 'Aircrafts',
      fields: {
        numberOfSeats: 'Pax',
        yearOfManufacture: 'YOM',
        yearOfRestyling: 'YOR',
        licensePlate: 'Registration',
        vehicleModelId: 'Type',
        vehicleManufacturerId: 'Operator',
        vehicleTypeId: 'Category',
        images: 'Image Gallery',
        homeBaseIcao: 'Homebase',
        vehicleSafetyTypeId: 'Safety',
      },
      actions: {
        create: 'New Aircraft',
      },
      titles: {
        create: 'New Aircraft',
      },
    },
    VehicleModel: {
      name: 'Type',
      fields: {
        name: 'Name',
      },
      titles: {
        create: 'New Type',
      },
    },
    VehicleManufacturer: {
      name: 'Operator',
      fields: {
        name: 'Name',
      },
      titles: {
        create: 'New Operator',
      },
    },
    VehicleCategory: {
      name: 'Category',
      fields: {
        name: 'Name',
      },
      titles: {
        create: 'New Category',
      },
    },
    VehicleSafetyType: {
      name: 'Safety type',
      fields: {
        name: 'Name',
      },
      titles: {
        create: 'New Safety type',
      },
    },
    Organization: {
      name: 'Organisation',
      fields: {
        name: 'Name',
        vat: 'Vat',
        phone: 'Phone',
        email: 'Email',
        addressId: 'Country',
        address: 'Address',
        'bankAccounts.length': 'Bank Accounts',
      },
      titles: {
        create: 'New Organisation',
        edit: 'Edit Organisation',
        company: 'Organisation',
        info: 'Info',
      },
      actions: {
        addAccount: 'Add Bank Account',
      },
    },
    TeamMember: {
      name: 'Team Member',
      fields: {
        firstName: 'First name',
        lastName: 'Last name',
        birthday: 'Birth date',
        calendarColor: 'Calendar color',
        fiscalCode: 'Fiscal code',
        q: 'Search',
      },
      titles: {
        create: 'New Team Member',
        edit: 'Edit Team Member',
      },
      actions: {
        create: 'New Team Member',
      },
    },
    BankAccount: {
      name: 'Bank Account',
      fields: {
        name: 'Account Name',
        customField: 'Field label',
        customValue: 'Value',
        details: 'Account Details',
      },
      titles: {
        create: 'New Bank Account',
        details: 'Bank Details',
        accountDetails: 'Account Details',
        edit: 'Edit Bank Account',
      },
    },
    User: {
      name: 'User |||| Users',
      helpers: {
        roleId: 'Select a role for permissions',
        organizationId: 'Select the main organization for the current user',
        byEmail: 'Search by email',
      },
      titles: {
        create: 'New User',
        details: 'User Details',
      },
      fields: {
        active: 'Status',
        organizationId: 'Organization',
        roleId: 'Role',
        email: 'Email',
        emptyRole: 'No assigned Role',
      },
      actions: {
        create: 'New User',
      },
    },
    ServiceLog: {
      name: 'Service Log |||| Service Logs',
      fields: {
        action: 'Action',
        createdAt: 'Date',
      },
    },
    Lead: {
      name: 'Website Request |||| Website Requests',
      fields: {
        leadType: 'Lead Origin',
      },
    },
    Airport: {
      name: 'Airport |||| Airports',
    },
    Language: {
      name: 'Language |||| Languages',
      titles: {
        create: 'Create language',
        formSection: 'Language info',
      },
      missingLanguageTooltip: 'Please add languages in Settings/Languages',
    },
    Customer: {
      name: 'Customer |||| Customers',
      all: 'All',
      fields: {
        name: 'Name',
        vat: 'VAT',
        pec: 'PEC',
        fiscalCode: 'Fiscal Code',
        email: 'Email',
        mainReferenceTeamMemberId: 'Account Manager',
      },
      isCompany: {
        true: 'Company',
        false: 'Private',
      },
      helpers: {
        language: "Choose client's language",
        noAddress: 'Address does not exist',
      },
      titles: {
        create: 'New Client',
        createSub1: 'Client Info',
        createSub2: 'Client Address',
        addPerson: 'Add person',
        details: 'Details',
      },
      cta: {
        clickOnAddPerson: 'Click on "Add person"',
        editAddress: 'Edit Address',
        createAddress: 'Create Address',
      },
    },
    Person: {
      name: 'Person |||| People',
      fields: {
        firstName: 'First name',
        lastName: 'Last name',
        position: 'Position',
        email: 'Email',
        phone: 'Phone',
      },
      helpers: {
        choosePersonNoCustomer: 'Search person (choose client first)',
        choosePerson: 'Search person',
        personNotFound: 'Person not found. ',
        quickCreatePersonLink: 'Click here to create it',
      },
    },
    Quote: {
      name: 'Quote |||| Quotes',
      fields: {
        status: 'Status',
        customerId: 'Client',
        teamMemberId: 'Account manager',
        personId: "Person's email",
        createdAt: 'Created at',
        personsEmail: "Person's email",
        personsPhone: "Person's phone",
        routes: 'Routes',
      },
      titles: {
        create: 'New Quote',
        edit: 'Update Quote',
        accountManager: 'Account Manager',
        routing: 'Routing',
        clientAndLanguage: 'Client and Language',
        confirmOption: 'Confirm option',
        confirmOptionContent:
          'Remember that confirming one of the options will result in the creation of an order related to it. Are you sure do you want to confirm the option?',
      },
      cta: {
        addRoute: 'Add route',
        addReturn: 'Add return',
        goToOrder: 'Go to order',
      },
      messages: {
        confirmQuoteOptionSuccess: 'Option confirmed successfully.',
      },
    },
    Order: {
      name: 'Contract |||| Contracts',
      fields: {
        status: 'Status',
        customerId: 'Client',
        teamMemberId: 'Account manager',
      },
    },
    Route: {
      name: 'Route |||| Routes',
      fields: {
        departure: 'Departure',
        arrival: 'Arrival',
        flightDate: 'Flight Date',
        passengers: 'Passengers',
        arrivalDate: 'Arrival Date',
        flightDuration: 'Duration',
      },
    },
    Currency: {
      name: 'Currency |||| Currencies',
    },
    OrderAdditionalInfoTemplate: {
      name: 'Contract Condition |||| Contract Cond...',
    },
    OrderRoute: {
      name: 'Flight |||| Flights',
    },
    Passenger: {
      name: 'Passenger |||| Passengers',
      actions: {
        create: 'Create Passenger',
      },
      fields: {
        total: 'N. of Flights',
      },
    },
    VehicleCarbonOffset: {
      name: 'Carbon Offset |||| Carbon Offsets',
    },
    Tax: {
      name: 'Tax |||| Taxes',
    },
    ProspectClient: {
      name: 'Prospect |||| Prospects',
      fields: {
        mainReferenceTeamMemberId: 'Account Manager',
      },
    },
    LeadClient: {
      name: 'Lead |||| Leads',
      fields: {
        mainReferenceTeamMemberId: 'Account Manager',
      },
    },
    Client: {
      name: 'Client |||| Clients',
      fields: {
        mainReferenceTeamMemberId: 'Account Manager',
      },
    },
    GmailSettings: {
      name: 'Gmail settings',
    },
    GmailTemplate: {
      name: 'Gmail template |||| Gmail templates',
    },
  },
  customFields: {
    galleryInput: {
      title: 'Catalog Pictures',
      mobileTitle: 'Mobile Catalog Pictures',
      galleryTitle: 'Product Details Gallery',
      modal: {
        error: 'Error uploading picture',
        success: 'Picture uploaded correctly',
        title: {
          addToGallery: 'Add new to Gallery',
          setCatalog: 'Set Primary Picture',
          setSecondaryCatalog: 'Set Secondary Catalog Picture',
        },
      },
    },
    galleryGrid: {
      setFeaturedImage: 'Set Featured Picture',
      catalogImage: 'Primary',
      catalogSecondaryImage: 'Secondary',
      featured: 'Featured',
      featuredImage: 'Featured',
      addToGallery: 'Add to gallery',
      setCatalogImage: 'Set Catalog Image',
      setCatalogSecondaryImage: 'Set Secondary Image',
    },
    priceSection: {
      title: 'Price',
    },
    discountSection: {
      title: 'Sale',
    },
    orderProductTableField: {
      subtotal: 'Subtotal',
      shippingCost: 'Shipping',
      tax: 'Tax',
      total: 'Total',
    },
    orderCustomerField: {
      // eslint-disable-next-line quotes
      noRaffleParticipations: "User hasn't participated to any raffle yet",
      nbPartecipations: '%{total} raffle partecipation |||| %{total} raffle partecipations',
      noPurchases: 'No purchases',
      nbPurchases: '%{total} purchase |||| %{total} purchases',
      customerAgreeNewsletter: 'Accept newsletter e-mails',
    },
    language: {
      inputHelper: 'Two letter, three letter or three digit country code',
      inputUnknown: 'Unknown (Two letter, three letter or three digit country code)',
    },
    orderStatus: {
      confirmed: 'Confirmed',
      sent: 'Sent',
      confirmedAndPaid: 'Paid',
      canceled: 'Canceled',
      canceledWithPenalty: 'Canceled with penalty',
      canceledWithPenaltyAndPaid: 'Canceled with penalty and paid',
    },
    routeStatus: {
      waitingForFlightBrief: 'Waiting for FB',
      flightBriefIsReady: 'FB ready',
      flightBriefSent: 'FB sent',
    },
    prices: {
      sellingPrice: 'Selling price',
      purchasePrice: 'Purchase price',
      transferCost: 'Transfer cost',
      commissions: 'Commissions',
      sellingPriceWithoutTaxes: 'Total (no taxes)',
      passengerTax: 'Passenger tax',
      vat: 'VAT',
    },
  },
  products: {
    edit: {
      tabs: {
        basicSettings: 'Basic Settings',
        options: 'Options',
        pricing: 'Pricing',
        shipping: 'Shipping',
        combinations: 'Combinations',
      },
    },
  },
  Calendar: {
    viewModes: {
      month: 'Month',
      twoWeeks: 'Two Weeks',
      week: 'Week',
      day: 'Day',
    },
  },
}
