import ProductList from './ProductList'
import ProductCreate from './ProductCreate'
import ProductEdit from './ProductEdit'

export default {
  name: 'Product',
  list: ProductList,
  create: ProductCreate,
  // edit: ProductEdit,
}
