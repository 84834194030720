import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import FormSwitchInput from './../../components/forms/FormSwitchInput'
import HiddenOrganizationField from '../../components/forms/HiddenOrganizationField'
import OfficeAreaInput from '../../components/OfficeAreaInput'

type Props = {
  [x: string]: any
}

const OfficeCreate: FC<Props> = (props) => {
  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="list" title="Nuovo Punto Vendita">
        <FormSection title="Punto Vendita">
          <HiddenOrganizationField />
          <FormTextInput {...props} source="name" helperText="" />
        </FormSection>
        <FormSection title="Area geografica di appartenenza">
          <OfficeAreaInput label={false} />
        </FormSection>
        <FormSection title="Pubblica">
          <FormSwitchInput {...props} source="active" helperText="" />
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default OfficeCreate
