import React, { FC, useCallback, useState } from 'react'
import { useDelete, useRefresh } from 'ra-core'
import { ReferenceField, RichTextField, Confirm, TextField, FunctionField } from 'react-admin'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import FlagFromISOField from '../../components/field/FlagFromISOField'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'

type Props = {
  id: number | string
  languageId: number | string
  value: Record<string, any>
  subject: string
  createdAt: string
  updatedAt: string
  onEditClick: (valueId: any) => (event: any) => {}
  [x: string]: any
}

const TemplateValueField: FC<Props> = (props) => {
  const refresh = useRefresh()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [deleteValue, { loading, error, data }] = useDelete('GmailTemplateValue', props.id, props.id)

  const handleDialogClose = useCallback((): void => setIsDialogOpen(false), [])
  const handleDialogOpen = useCallback((): void => setIsDialogOpen(true), [])

  const handleDelete = useCallback(async () => {
    await deleteValue()
    refresh()
  }, [deleteValue])

  return (
    <>
      <Box
        display="flex"
        width="70%"
        alignSelf="stretch"
        color="primary"
        mb={4}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column" width="100%">
          {/* <TextField record={props} source="subject" /> */}
          <FunctionField record={props} render={(record: any) => `Subject: ${record.subject}`} />
          {/* <Box flexGrow={4}>
            <Paper>
              <Box p={4}>
                <RichTextField label={false} record={props.value} source="_html" />
              </Box>
            </Paper>
          </Box> */}
          {/* <Box flexGrow={4}> */}
          <Paper>
            <Box flexGrow={4} p={4}>
              <RichTextField label={false} record={props.value} source="_html" />
            </Box>
          </Paper>
          {/* </Box> */}
        </Box>
        <Box display="flex" flexDirection="column">
          <Box ml={4} display="flex" flexDirection="column" justifyContent="space-between">
            <ReferenceField basePath="/GmailTemplate" record={props} source="languageId" reference="Language">
              <FlagFromISOField source="isoCode" />
            </ReferenceField>
            <Typography variant="h6">
              edited on:
              <Typography>{props.updatedAt ? new Date(props.updatedAt).toLocaleDateString() : ''}</Typography>
            </Typography>
          </Box>
          <Box ml={4} display="flex" justifyContent="space-between">
            <IconButton onClick={handleDialogOpen} size="small">
              <DeleteForeverIcon />
            </IconButton>
            <IconButton color="primary" onClick={props.onEditClick(props.id)} size="small">
              <EditIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Confirm
        isOpen={isDialogOpen}
        loading={loading}
        title="Delete Value"
        content="Are you sure you want to delete the specified value?"
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default TemplateValueField
