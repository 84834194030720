import React, { FC } from 'react'
import { DateTimeInput } from 'react-admin'

const leftPad = (nb = 2) => (value: any) => ('0'.repeat(nb) + value).slice(-nb)
const leftPad4 = leftPad(4)
const leftPad2 = leftPad(2)

const convertDateToString = (value: Date) => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return ''
  // const yyyy = leftPad4(value.getFullYear())
  // const MM = leftPad2(value.getMonth() + 1)
  // const dd = leftPad2(value.getDate())
  // const hh = leftPad2(value.getHours())
  // const mm = leftPad2(value.getMinutes())
  const yyyy = leftPad4(value.getUTCFullYear())
  const MM = leftPad2(value.getUTCMonth() + 1)
  const dd = leftPad2(value.getUTCDate())
  const hh = leftPad2(value.getUTCHours())
  const mm = leftPad2(value.getUTCMinutes())
  return `${yyyy}-${MM}-${dd}T${hh}:${mm}`
}

// yyyy-MM-ddThh:mm
const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/

const formatDateTime = (value: string | Date) => {
  // null, undefined and empty string values should not go through convertDateToString
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === '') {
    return ''
  }

  if (value instanceof Date) {
    return convertDateToString(value)
  }
  // valid dates should not be converted
  if (dateTimeRegex.test(value)) {
    return value
  }

  return convertDateToString(new Date(value))
}

const parseDateTime = (value: string): Date => {
  return new Date(`${value}Z`)
}

type Props = {
  [x: string]: any
}

const NoTimezoneDateTimeInput: FC<Props> = (props) => {
  return <DateTimeInput format={formatDateTime} parse={parseDateTime} {...props} />
}

export default NoTimezoneDateTimeInput
