import React from 'react'
import { Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { Paper } from '@material-ui/core'
import { TableContent } from './components/TableContent'

export type MostFrequentPassengers = {
  id: number
  passenger: {
    id: number
    fullName: string
  }
  count: number
  SUM_flightDuration: number
}

interface MostFrequentPassengersCardProps {
  data?: MostFrequentPassengers[]
  loading?: boolean
  error?: boolean
}

export const MostFrequentPassengersCard = ({ data, loading, error }: MostFrequentPassengersCardProps) => {
  return (
    <Paper>
      <Heading as="h4" size="sm" m="2">
        Most frequent passengers
      </Heading>
      <TableContainer overflowY="auto" maxHeight="10rem">
        <Table size="sm" variant="simple">
          <Thead position="sticky" top={0} bgColor="gray.50">
            <Tr>
              <Th>Passenger</Th>
              <Th isNumeric> Flights</Th>
              <Th isNumeric>Hours</Th>
            </Tr>
          </Thead>
          <TableContent loading={loading} error={error}>
            <Tbody overflow="auto">
              {data?.map(({ passenger, count, SUM_flightDuration }) => (
                <Tr key={passenger.id}>
                  <Td>{passenger.fullName}</Td>
                  <Td isNumeric>{count}</Td>
                  <Td isNumeric>{Math.round(SUM_flightDuration / 6) / 10}</Td>
                </Tr>
              ))}
            </Tbody>
          </TableContent>
        </Table>
      </TableContainer>
    </Paper>
  )
}
