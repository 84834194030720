import React, { FC } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField, Filter } from 'react-admin'

import Datagrid from '../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'
import { FaStore } from 'react-icons/fa'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import FilterTextInput from '../../components/FilterTextInput'

type Props = {}

const Filters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput label="Cerca" source="name" alwaysOn />
    </Filter>
  )
}

const RoleParamList: FC<Props> = (props) => {
  return (
    <RAList
      {...props}
      title="Parametri (Ruoli)"
      sort={{ field: 'name', order: 'ASC' }}
      titleIcon={<FaStore />}
      actions={<CreateButton label="Nuovo Parametro" />}
      filters={<Filters variant="outlined" />}
    >
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="name" />
        <TextField source="type" />
        <GenericMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default RoleParamList
