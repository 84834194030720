import React, { FC } from 'react'
import Show from '../../components/details/Show'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Avatar, Box, Button, Divider, makeStyles, Typography } from '@material-ui/core'
import { FaStore, FaUsers } from 'react-icons/fa'
import BooleanStatusField from '../../components/BooleanStatusField'
import ToggleStatusButton from '../../components/button/ToggleStatusButton'
import { ReferenceManyField, FunctionField, TextField } from 'react-admin'
import Datagrid from '../../components/Datagrid'
import { Pagination, PaginationLimit } from '../../components/list/pagination'
import Empty from '../../components/list/Empty'
import { InfoCard, InfoCardItem, InfoCardWrapper, InfoCardTypography, InfoCardAvatar } from '../../components/InfoCard'
import { OfficeAreaEnum } from '../../customEnums'

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    backgroundColor: '#eeeeee',
    color: 'rgba(51,51,51,0.5)',
    width: '60px',
    height: '60px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    marginRight: theme.spacing(3),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emailValue: {
    fontWeight: 'bold',
  },
  contactButton: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    backgroundColor: '#eeeeee',
  },
  card: {
    flexBasis: '50%',
    maxWidth: 375,
    position: 'sticky',
    top: theme.spacing(3),
  },
  userLogsContainer: {
    flex: 1,
    marginLeft: theme.spacing(3),
    // overflow: 'scroll',
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-start',
  },
  cardValue: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}))

const OfficeShowCard = (props: any): any => {
  const classes = useStyles()
  console.log('CIAO ', props)

  return props.record ? (
    <InfoCardWrapper>
      <InfoCard>
        <InfoCardItem direction="row">
          <InfoCardAvatar>
            <FaStore size={20} />
          </InfoCardAvatar>
          <InfoCardTypography variant="title">{props.record.name}</InfoCardTypography>
        </InfoCardItem>

        <InfoCardItem>
          <InfoCardTypography variant="label">Status:</InfoCardTypography>
          <BooleanStatusField className={classes.cardValue} source="active" record={props.record} />
        </InfoCardItem>

        <InfoCardItem>
          <InfoCardTypography variant="label">Area:</InfoCardTypography>
          <InfoCardTypography variant="value">{OfficeAreaEnum[props.record.areaId] || ''}</InfoCardTypography>
        </InfoCardItem>
      </InfoCard>
    </InfoCardWrapper>
  ) : null
}

const OfficeTeamMemberTable: FC<any> = (props) => {
  const classes = useStyles()

  return props.record ? (
    <Box className={classes.userLogsContainer}>
      <ReferenceManyField
        pagination={<Pagination />}
        record={props.record}
        reference="TeamMember"
        target="officeId"
        basePath="/TeamMember"
      >
        <Datagrid emptyComponent={<Empty buttonComponent={<></>} />}>
          <TextField source="id" label="ID" />
          <TextField source="fullName" />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  ) : (
    <p></p>
  )
}

const Wrapper: FC<any> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.contentContainer}>
      <OfficeShowCard {...props} />
      <OfficeTeamMemberTable {...props} />
    </div>
  )
}

const OfficeShow: FC<any> = (props) => {
  return (
    <Show {...props}>
      <Wrapper {...props} />
    </Show>
  )
}

export default OfficeShow
