import React, { FC, useCallback } from 'react'
import { TextField, NumberField, TextInput } from 'react-admin'
import { Route, useHistory } from 'react-router-dom'
import { useTranslate } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
// internal components
import NameOnlyEdit from './NameOnlyEdit'
import RAList from './../../list/List'
import Datagrid from './../../Datagrid'
import GenericMoreMenu from './../../GenericMoreMenu'
import Drawer from './../../Drawer'
import DrawerHeader from './../../DrawerHeader'
import Filter from './../../Filter'
import FilterTextInput from '../../FilterTextInput'
// icons
import { VscListSelection } from 'react-icons/vsc'

type Props = {
  [x: string]: any
}

const NameOnlyList: FC<Props> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const history = useHistory()

  const handleDrawerClose = useCallback(() => history.push(`/${props.resource}`), [])

  return (
    <>
      <RAList
        {...props}
        titleIcon={<VscListSelection />}
        bulkActionButtons={false}
        filters={
          <Filter {...props}>
            <FilterTextInput source="name" alwaysOn />
          </Filter>
        }
      >
        <Datagrid>
          <NumberField source="id" />
          <TextField source="name" />
          <GenericMoreMenu />
        </Datagrid>
      </RAList>
      <Route path={`/${props.resource}/:id`}>
        {({ match }): React.ReactNode => {
          const isMatch = match && match.params && match.params.id !== 'create'

          return (
            <Drawer open={isMatch} onClose={handleDrawerClose}>
              {isMatch ? (
                <>
                  <DrawerHeader
                    buttonOnClick={handleDrawerClose}
                    title={`${translate('ra.action.edit')} #${match?.params?.id}`}
                  />
                  <NameOnlyEdit id={match!.params!.id} onCancel={handleDrawerClose} {...props} />
                </>
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          )
        }}
      </Route>
    </>
  )
}

const useStyles = makeStyles((theme: any) => ({
  export: {
    marginLeft: theme.spacing(3),
  },
  drawerContent: {
    width: '26vw',
    minWidth: 260,
    content: "''",
  },
}))

export default NameOnlyList
