import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Button,
  chakra,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuOptionGroupProps,
  Portal,
  Text,
} from '@chakra-ui/react'
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { QUERY_GET_ACTIVITY_TYPES } from '../../queries'
import { useQuery } from '@apollo/client'

type Props = {
  showAdditionalTypes?: boolean
} & Pick<MenuOptionGroupProps, 'onChange' | 'value'>

export const ActivityTypeFilter: FC<Props> = ({ onChange, value, showAdditionalTypes = false }) => {
  const [initialized, setInitialized] = useState<boolean>(false)
  const { loading, data } = useQuery(QUERY_GET_ACTIVITY_TYPES, {
    variables: {
      pagination: {
        disabled: true,
      },
      sort: {
        label: 'ASC',
      },
    },
  })

  const handleToggleAllActivityTypes = useCallback(() => {
    if (onChange) {
      if (value && value.length === (data?.activityTypes?.data?.length || 0) + (showAdditionalTypes ? 1 : 0)) {
        onChange([])
      } else {
        onChange([
          ...(data?.activityTypes?.data?.map((item: any) => item.id) || []),
          ...(showAdditionalTypes ? ['email'] : []),
        ])
      }
    }
  }, [value, onChange, data, showAdditionalTypes])

  useEffect(() => {
    if (data?.activityTypes.data && !initialized && onChange) {
      onChange([...data.activityTypes.data.map((item: any) => item.id), ...(showAdditionalTypes ? ['email'] : [])])
      setInitialized(true)
    }
  }, [data])

  return (
    <Menu closeOnSelect={false}>
      <MenuButton disabled={loading} ml={4} as={Button} leftIcon={<HamburgerIcon />} rightIcon={<ChevronDownIcon />}>
        Filters
      </MenuButton>
      <Portal>
        <MenuList minWidth="240px">
          <MenuOptionGroup
            type="checkbox"
            value={
              value && value.length === (data?.activityTypes?.data?.length || 0) + (showAdditionalTypes ? 1 : 0)
                ? ['all']
                : []
            }
            onChange={handleToggleAllActivityTypes}
          >
            <MenuItemOption value="all">
              <Flex flex="1" alignItems="center">
                <Icon mr={2} w="20px" h="20px" as={AiOutlineUnorderedList} />
                <Text>All Activities</Text>
              </Flex>
            </MenuItemOption>
          </MenuOptionGroup>
          <MenuOptionGroup title="Quick Filters" type="checkbox" onChange={onChange} value={value}>
            {showAdditionalTypes && (
              <MenuItemOption value="email">
                <Flex flex="1" alignItems="center">
                  <chakra.span color="blackAlpha.700" mr={2} mt={1} className={'material-icons'}>
                    mail
                  </chakra.span>
                  <Text>Email</Text>
                </Flex>
              </MenuItemOption>
            )}
            {data?.activityTypes.data.map((item: any) => (
              <MenuItemOption key={`activity-type-${item.id}`} value={item.id}>
                <Flex flex="1" alignItems="center">
                  <chakra.span color="blackAlpha.700" mr={2} mt={1} className={'material-icons'}>
                    {item.icon}
                  </chakra.span>
                  <Text>{item.label}</Text>
                </Flex>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
}
