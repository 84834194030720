import React, { FC } from 'react'
import useCurrencySettings, { CurrencyMaskType } from '../../hooks/useCurrencySettings'
import LinearProgress from '@material-ui/core/LinearProgress'
import ErrorIcon from '@material-ui/icons/Error'
import { Variant } from '@material-ui/core/styles/createTypography'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

type Props = {
  record?: Record<string, any>
  source: string
  label?: string
  currencyId?: string | number
  variant?: Variant
  inline?: boolean
  className?: any
  textAlign?: 'left' | 'right' | 'center'
}

export const numberToFormattedCurrencyConverter = (value: number, mask: CurrencyMaskType): string => {
  let stringDecimals = (value % 1).toString().replace('0', '').replace('.', mask.decimalSymbol).slice(0, 3)

  if (stringDecimals.length < 3) {
    if (stringDecimals.length === 0) stringDecimals = `${mask.decimalSymbol}00`
    if (stringDecimals.length === 2) stringDecimals = `${stringDecimals}0`
  }

  const stringInt = Math.floor(value).toString()
  const s2 = stringInt.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, `$1${mask.thousandsSeparatorSymbol}`)
  const final = `${mask.prefix}${s2}${stringDecimals}${mask.suffix}`
  return final
}

const CurrencyField: FC<Props> = (props) => {
  const { record = {}, source, label, currencyId, variant = 'body2', textAlign = 'right' } = props
  const retrievedCurrencyId = currencyId || record.currencyId || 1
  if (!currencyId && !record.currencyId) {
    console.warn('WARNING: No currency given, using default currency for field ', source)
  }
  const { loading, error, mask } = useCurrencySettings(retrievedCurrencyId)
  const classes = useStyles(props)

  if (loading) return <LinearProgress />
  if (error) return <ErrorIcon />
  if (!!mask && typeof record[source] === 'number') {
    return (
      <Typography variant={variant} className={clsx(classes.price, props.className)} align={textAlign}>
        {numberToFormattedCurrencyConverter(record[source], mask)}
      </Typography>
    )
  }
  return null
}

const useStyles = makeStyles({
  price: {
    display: (props: any): string | undefined => (props.inline ? 'inline' : undefined),
    justifyContent: (props: any): string | undefined => (props.tableField ? 'flex-end' : undefined),
    color: (props: any): string | undefined => (props.color ? props.color : undefined),
  },
})

export default CurrencyField
