import React, { FC } from 'react'
import { useTranslate } from 'ra-core'
import RAList from '../../components/list/List'
import { TextField, NumberField, ReferenceField, DateField, ReferenceInput, TextInput, SelectInput } from 'react-admin'
import Datagrid from './../../components/Datagrid'
import SearchAutoCompleteInput from '../../components/SearchAutoCompleteInput'
import { FaListOl } from 'react-icons/fa'
import ClientNameField from '../../components/field/ClientNameField'
import Filter from '../../components/list/filter/Filter'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import FirstRouteField from '../../components/field/FirstRouteField'
import QuoteStatusField from '../../components/field/QuoteStatusField'
import QuoteMoreMenu from './QuoteMoreMenu'

type Props = {
  //
}

const sort = { field: 'createdAt', order: 'DESC' }

const filterToQuery = (q: string): any => ({ q })

const customerOptionText = (record: any): string => {
  return !record || !record.id
    ? ''
    : record.isCompany
    ? `${record.companyName}`
    : `${record.firstName} ${record.lastName}`
}

const QuoteFilters: FC<any> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <ReferenceInput filterToQuery={filterToQuery} source="customerId" reference="Customer" label="Client" alwaysOn>
        <SearchAutoCompleteInput optionText={customerOptionText} />
      </ReferenceInput>
      <ReferenceInput source="personId" reference="Person" filterToQuery={filterToQuery}>
        <AutocompleteInputInDrawer optionText="fullName" />
      </ReferenceInput>
      <TextInput source="reference" />
      <SelectInput
        source="status"
        choices={[
          { id: 'pending', name: 'Pending' },
          { id: 'sent', name: 'Sent' },
          { id: 'confirmed', name: 'Confirmed' },
          { id: 'abandoned', name: 'Abandoned' },
          { id: 'lost', name: 'Lost' },
        ]}
      />
    </Filter>
  )
}

const QuoteList: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <RAList {...props} filters={<QuoteFilters />} titleIcon={<FaListOl />} sort={sort}>
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <TextField source="reference" />
        <DateField source="createdAt" showTime options={{ dateStyle: 'short', timeStyle: 'short' }} />
        <DateField source="updatedAt" showTime options={{ dateStyle: 'short', timeStyle: 'short' }} />
        <ReferenceField source="customerId" link={false} reference="Customer">
          <ClientNameField />
        </ReferenceField>
        <FirstRouteField label={translate('resources.Quote.fields.routes')} />
        <DateField source="flightDate" showTime options={{ dateStyle: 'short', timeStyle: 'short' }} />
        <ReferenceField source="teamMemberId" link={false} reference="TeamMember">
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField source="personId" reference="Person" link={false}>
          <TextField source="email" />
        </ReferenceField>
        <QuoteStatusField label={translate('resources.Quote.fields.status')} />
        {/* <GenericMoreMenu /> */}
        <QuoteMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default QuoteList
