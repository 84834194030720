import { makeStyles } from '@material-ui/core'
import { AutocompleteInput, ReferenceInput } from 'ra-ui-materialui'
import React, { FC } from 'react'
import { useFormState } from 'react-final-form'

type Props = {
  //
}

const useStyles = makeStyles((theme) => ({
  suggestionsContainer: {
    zIndex: 1300,
  },
}))

export const EventPersonInput: FC<Props> = (props) => {
  const classes = useStyles()
  const { values } = useFormState()

  return (
    <ReferenceInput
      source="personId"
      reference="Person"
      variant="outlined"
      fullWidth
      filter={{ referencedCustomerId: values.customerId }}
      allowEmpty
      {...props}
    >
      <AutocompleteInput
        options={{
          suggestionsContainerProps: {
            className: classes.suggestionsContainer,
          },
        }}
        optionText="fullName"
        optionValue="id"
        disabled={!values.customerId}
      />
    </ReferenceInput>
  )
}
