import { useGetList, useShowController } from 'ra-core'
import React, { FC, useCallback } from 'react'
import { Route, useHistory } from 'react-router-dom'
import ShowView from '../../components/details/ShowView'
import TemplateValueForm from './GmailTemplateValueForm'
import TemplateValueField from './GmailTemplateValueField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Drawer from '../../components/Drawer'
import DrawerHeader from '../../components/DrawerHeader'
import TemplateValueEditForm from './GmailTemplateValueEditForm'

type Props = {
  [x: string]: any
}

const ShowComp: FC<any> = (props) => {
  const history = useHistory()

  const { loading, data, total } = useGetList(
    'GmailTemplateValue',
    {
      page: 1,
      perPage: 100,
    },
    { field: 'id', order: 'DESC' },
    { gmailTemplateId: props.record.id }
  )

  const handleDrawerClose = useCallback(() => history.goBack(), [])

  const onEditValueClick = useCallback(
    (valueId: number) => (event: any): void => {
      history.push(`/GmailTemplate/${props.record.id}/show/GmailTemplateValue/${valueId}`)
    },
    [props.record]
  )

  return (
    <>
      <TemplateValueForm resourceType={props.record.category as any} gmailTemplateId={props.record.id} />
      {data && (
        <Box mt={8}>
          <Typography variant="h3">Variants saved for this template: </Typography>
          <Box mt={4} />
          {Object.values(data).length > 0 &&
            Object.values(data).map((value: any) => (
              <TemplateValueField key={`template-value-${value.id}`} {...value} onEditClick={onEditValueClick} />
            ))}
        </Box>
      )}
      <Route exact path="/GmailTemplate/:id/show/GmailTemplateValue/:valueId">
        {({ match }): React.ReactNode => {
          const isMatch = match && match.params
          const valueId = match?.params.valueId

          return (
            <Drawer open={isMatch} onClose={handleDrawerClose}>
              {valueId ? (
                <>
                  <DrawerHeader buttonOnClick={handleDrawerClose} title={'Edit value'} />
                  <TemplateValueEditForm resourceType={props.record.category as any} id={valueId} />
                </>
              ) : null}
            </Drawer>
          )
        }}
      </Route>
    </>
  )
}

const GmailTemplateShow: FC<Props> = (props) => {
  const showProps = useShowController(props)

  return !showProps.loading && showProps.record ? (
    <ShowView {...showProps} {...props} title={showProps.record ? showProps.record.name : undefined}>
      <ShowComp {...props} />
    </ShowView>
  ) : null
}

export default GmailTemplateShow
