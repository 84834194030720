import React, { FC, useEffect, useState } from 'react'
import { Admin, Resource } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import buildGraphQLProvider from 'ra-hyperting-graphql'
import { ApolloProvider, gql } from '@apollo/client'
import { client } from './apolloClient'
import authProvider from './authProvider'
import customRoutes from './customRoutes'
import LoginPage from './routes/LoginPage'
import createHistory from 'history/createBrowserHistory'
import englishMessages from './i18n/en'
import Loading from './components/Loading'
import Layout from './components/layout/Layout'
import Organization from './resources/Organization'
import Vehicle from './resources/Vehicle'
import Office from './resources/Office'
import VehicleManufacturer from './resources/Vehicle/VehicleManufacturer'
import VehicleCategory from './resources/Vehicle/VehicleCategory'
import VehicleModel from './resources/Vehicle/VehicleModel'
import VehicleSafetyType from './resources/Vehicle/VehicleSafetyType'
import TeamMember from './resources/TeamMember'
import JssProvider from 'react-jss/lib/JssProvider'
// import Customer from './resources/Customer'
import Address from './resources/Address'
import Country from './resources/Country'
import Document from './resources/Document'
import State from './resources/State'
import Currency from './resources/Currency'
import Tax from './resources/Tax'
import Product from './resources/Product'
import ProductCategory from './resources/ProductCategory'
import ProductAttribute from './components/ProductAttribute'
import ProductFeature from './components/ProductFeature'
import ProductFeatureValue from './components/ProductFeatureValue'
import ProductAttributeValue from './components/ProductAttributeValue'
import Event from './resources/Event'
import Order from './resources/Order'
import EventTemplate from './resources/EventTemplate'
import addUploadFeature from './addUploadFeature'
import theme from './theme'
import EventStatus from './resources/EventStatus'
import SMSTemplate from './resources/SMSTemplate'
import EmailTemplate from './resources/EmailTemplate'
import TelegramTemplate from './resources/TelegramTemplate'
import Role from './resources/Role'
import PermissionRole from './resources/PermissionRole'
import User from './resources/User'
import logo from './logo.svg'
import PageNotFound from './components/PageNotFound'
import { hasPermission } from './utils/hasPermission'
import BankAccount from './resources/BankAccount'
import Lead from './resources/Lead'
import Airport from './resources/Airport'
import Language from './resources/Language'
import Customer from './resources/Customer'
import Quote from './resources/Quote'
import OrderAdditionalInfoTemplate from './resources/OrderAdditionalInfoTemplate'
import OrderRoute from './resources/OrderRoute'
import Passenger from './resources/Passenger'
import VehicleCarbonOffset from './resources/Vehicle/VehicleCarbonOffset'
import Dashboard from './components/Dashboard'
import RoleParam from './resources/RoleParam'
import ProspectClient from './resources/ProspectClient'
import LeadClient from './resources/LeadClient'
import Client from './resources/Client'
import GmailTemplate from './resources/GmailTemplate'
import { generateClassName } from './generateClassName'
import { HNSProvider } from 'hns-react'

const SERVICE_WORKER_PATH = process.env.NODE_ENV === 'production' ? '/service-worker.js' : '/hns-webpush-sw.js'

const history = createHistory()

const i18nProvider = polyglotI18nProvider((locale) => {
  // FIXME: italian language temporary disabled
  // if (locale === 'it') {
  //   return italianMessages
  // }

  // Always fallback on english
  return englishMessages
}, 'en')

const App: FC = () => {
  const [dataProvider, setDataProvider] = useState(null)

  const getUserToken = React.useCallback(async () => {
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation createNotificationToken {
            createNotificationToken
          }
        `,
      })

      return data?.createNotificationToken
    } catch {
      //
    }
  }, [])

  useEffect(() => {
    const buildProvider = async (): Promise<any> => {
      const dP = await buildGraphQLProvider({
        client,
      })
      setDataProvider(() => dP)
    }

    buildProvider()
  }, [])

  if (!dataProvider) {
    return <Loading theme={theme} logo={logo} />
  }

  const uploadCapableDataProvider = addUploadFeature(dataProvider)

  return (
    <HNSProvider
      serviceWorkerPath="/service-worker.js"
      publicKey={process.env.REACT_APP_HNS_VAPID_KEY}
      getUserToken={getUserToken}
    >
      <ApolloProvider client={client}>
        <JssProvider generateClassName={generateClassName}>
          <Admin
            history={history}
            layout={Layout}
            dataProvider={uploadCapableDataProvider}
            i18nProvider={i18nProvider}
            authProvider={authProvider}
            theme={theme}
            loginPage={LoginPage}
            customRoutes={customRoutes}
            catchAll={PageNotFound}
            dashboard={Dashboard}
          >
            {(permissions: any): any => {
              console.log(permissions, 'ciao sono <Admin />')
              return [
                // <Resource {...Reservation} />,
                <Resource {...Vehicle} />,
                <Resource {...VehicleManufacturer} />,
                <Resource {...VehicleCategory} />,
                <Resource {...VehicleModel} />,
                // <Resource name="Airport" />,
                <Resource {...VehicleSafetyType} />,
                <Resource {...PermissionRole} />,
                <Resource {...User} />,
                <Resource {...Organization} />,
                <Resource
                  name="Role"
                  list={hasPermission(permissions) ? Role.list : undefined}
                  create={hasPermission(permissions) ? Role.create : undefined}
                  edit={hasPermission(permissions) ? Role.edit : undefined}
                  show={hasPermission(permissions) ? Role.show : undefined}
                />,
                <Resource
                  name="Office"
                  list={hasPermission(permissions) ? Office.list : undefined}
                  create={hasPermission(permissions) ? Office.create : undefined}
                  edit={hasPermission(permissions) ? Office.edit : undefined}
                  show={hasPermission(permissions) ? Office.show : undefined}
                />,
                <Resource name="OpeningHour" />,
                <Resource name="MarketingOrigin" />,
                <Resource {...TeamMember} />,
                <Resource {...Customer} />,
                // <Resource name="Customer" />,
                <Resource {...Address} />,
                <Resource {...Country} />,
                <Resource {...State} />,
                <Resource {...Currency} />,
                <Resource {...Tax} />,
                <Resource
                  name="Product"
                  list={hasPermission(permissions) ? Product.list : undefined}
                  // create={hasPermission(permissions) ? Product.create : undefined}
                />,
                <Resource {...ProductCategory} />,
                <Resource {...ProductAttribute} />,
                <Resource {...ProductFeature} />,
                <Resource {...ProductFeatureValue} />,
                <Resource {...ProductAttributeValue} />,
                <Resource {...Event} />,
                <Resource name="Brand" />,
                <Resource name="EventTeamMember" />,
                <Resource name="Event" />,
                <Resource name="WorkingHour" />,
                <Resource {...Order} />,
                <Resource {...EventTemplate} />,
                <Resource {...EventStatus} />,
                <Resource {...Document} />,
                <Resource name="CustomerCoursePlanning" />,
                <Resource name="CourseSchedule" />,
                <Resource name="OrderItem" />,
                <Resource name="OrderMessage" />,
                <Resource name="Picture" />,
                <Resource name="CustomerContact" />,
                <Resource name="CourseLesson" />,
                <Resource name="ProductDrivingSchoolAttribute" />,
                <Resource name="ServiceLog" />,
                <Resource {...SMSTemplate} />,
                <Resource {...EmailTemplate} />,
                <Resource {...TelegramTemplate} />,
                <Resource name="CoopCustomer" />,
                <Resource {...BankAccount} />,
                <Resource {...Lead} />,
                <Resource {...Airport} />,
                <Resource {...Language} />,
                <Resource name="Person" />,
                <Resource {...Quote} />,
                <Resource {...OrderRoute} />,
                <Resource {...OrderAdditionalInfoTemplate} />,
                <Resource name="OrderAdditionalInfoTemplateValue" />,
                <Resource {...Passenger} />,
                <Resource name="PassengerRoute" />,
                <Resource name="OrderDocument" />,
                <Resource {...VehicleCarbonOffset} />,
                <Resource {...RoleParam} />,
                <Resource {...ProspectClient} />,
                <Resource {...LeadClient} />,
                <Resource {...Client} />,
                <Resource {...GmailTemplate} />,
                <Resource name="GmailTemplateValue" />,
                <Resource name="CustomerActivity" />,
                <Resource name="ActivityType" />,
              ]
            }}
          </Admin>
        </JssProvider>
      </ApolloProvider>
    </HNSProvider>
  )
}

export default App
