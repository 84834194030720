import React, { FC } from 'react'
import { useTranslate } from 'ra-core'
// internal components
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'

type Props = {
  [x: string]: any
}

const TeamMemberCreate: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="show" title={translate('resources.TeamMember.titles.create')}>
        <FormSection title="">
          <FormTextInput source="firstName" />
          <FormTextInput source="lastName" helperText="" />
          <FormTextInput source="email" />
          {/* <FormTextInput {...props} source="password" /> */}
        </FormSection>
        {/* <FormSection title="Punto Vendita">
          <ReferenceInput label="Seleziona punto vendita" source="officeId" reference="Office">
            <FormSelectInput optionText="name" fullWidth helperText="Seleziona punto vendita di appartenenza" />
          </ReferenceInput>
        </FormSection> */}
      </SectionedForm>
    </Create>
  )
}

export default TeamMemberCreate
