import React, { FC, useCallback, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button } from '@material-ui/core'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import classNames from 'classnames'
import { Rating } from '@material-ui/lab'
import { formatDistanceToNow } from 'date-fns'
import { QUERY_GET_ORDER_FEEDBACK, MUTATION_SEND_FEEDBACK_REQUEST } from '../../queries'
import Drawer from '../../components/Drawer'
import DrawerHeader from '../../components/DrawerHeader'
import { RecipientsForm } from './RecipientsForm'

type Props = {
  record: Record<string, any>
  name?: string
  id?: any
}

enum FeedbackStatus {
  ANSWERED = 'ANSWERED',
  EXPIRED = 'EXPIRED',
  SENT = 'SENT',
  OUT = 'OUT',
  READY = 'READY',
}

const OrderFeedbackNotification: FC<Props> = ({ record }) => {
  const classes = useStyles()
  const { data, loading, error, refetch } = useQuery(QUERY_GET_ORDER_FEEDBACK, {
    variables: {
      orderId: record.id,
    },
    fetchPolicy: 'cache-and-network',
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [send, sendResult] = useMutation(MUTATION_SEND_FEEDBACK_REQUEST)

  const sendFeedbackRequest = useCallback(
    (values: {
      to: {
        id: string
      }[]
    }) => {
      send({
        variables: {
          orderId: record.id,
          recipients: values.to.map((recipient) => recipient.id),
        },
        update: () => {
          refetch()
        },
      })
    },
    [send, record.id, refetch]
  )

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  if (loading || error || !data?.orderFeedback) {
    return null
  }

  const { status, orderFeedback } = data?.orderFeedback

  switch (status) {
    case FeedbackStatus.ANSWERED:
      return (
        <Paper className={classes.card}>
          <Box className={classes.inline}>
            <Typography variant="h4">Feedback received.</Typography>
            <Rating size="large" max={10} value={orderFeedback?.rating} readOnly />
          </Box>
          <Typography variant="caption">
            {formatDistanceToNow(new Date(orderFeedback?.answeredAt), { addSuffix: true })}
          </Typography>
          {orderFeedback?.comment && <Typography variant="body1">{data?.orderFeedback?.comment}</Typography>}
        </Paper>
      )

    case FeedbackStatus.READY:
      return (
        <>
          <Paper className={classNames(classes.card, classes.alert, classes.inline)}>
            <Typography variant="body1">
              Would you like to send the <strong>Feedback E-Mail</strong> to the customer?
            </Typography>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => setIsOpen(true)}
              disabled={sendResult.loading}
            >
              Send
            </Button>
          </Paper>
          <Drawer classes={{ paper: classes.paper }} anchor="right" open={isOpen} onClose={onClose}>
            <DrawerHeader buttonOnClick={onClose} title="Feedback request" />
            <RecipientsForm customerId={record.customerId} loading={sendResult.loading} onSubmit={sendFeedbackRequest} />
            {/* <CreateMailForm onMailSent={onClose} {...props} /> */}
          </Drawer>
        </>
      )

    case FeedbackStatus.SENT: {
      return (
        <Paper className={classNames(classes.card, classes.waiting, classes.inline)}>
          <Typography variant="body1">Feedback request sent, but not answered yet.</Typography>
        </Paper>
      )
    }

    default:
      return null
  }
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  waiting: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.error.contrastText,
  },
  alert: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  button: {
    backgroundColor: 'white',
  },
  paper: {
    padding: '20px',
    minWidth: '394px',
    maxWidth: '100%',
    'media(max-width: 375px)': {
      width: '100%',
    },
  },
}))

export default OrderFeedbackNotification
