import React, { isValidElement, Children, cloneElement, useCallback, FC } from 'react'
import PropTypes from 'prop-types'
import {
  sanitizeListRestProps,
  useMutation,
  useUpdateMany,
  useUpdate,
  useRefresh,
  useDataProvider,
  useNotify,
} from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import classnames from 'classnames'
import ReactDragListView from 'react-drag-listview'

import { DatagridHeaderCell, DatagridLoading, DatagridBody, PureDatagridBody } from 'ra-ui-materialui'

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'auto',
  },
  thead: {},
  tbody: {},
  headerRow: {},
  headerCell: {},
  checkbox: {},
  row: {},
  clickableRow: {
    cursor: 'pointer',
  },
  rowEven: {},
  rowOdd: {},
  rowCell: {},
  expandHeader: {
    padding: 0,
    width: theme.spacing(6),
  },
  expandIconCell: {
    width: theme.spacing(6),
  },
  expandIcon: {
    padding: theme.spacing(1),
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expanded: {
    transform: 'rotate(0deg)',
  },
}))

/**
 * The Datagrid component renders a list of records as a table.
 * It is usually used as a child of the <List> and <ReferenceManyField> components.
 *
 * Props:
 *  - rowStyle
 *
 * @example Display all posts as a datagrid
 * const postRowStyle = (record, index) => ({
 *     backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
 * });
 * export const PostList = (props) => (
 *     <List {...props}>
 *         <Datagrid rowStyle={postRowStyle}>
 *             <TextField source="id" />
 *             <TextField source="title" />
 *             <TextField source="body" />
 *             <EditButton />
 *         </Datagrid>
 *     </List>
 * );
 *
 * @example Display all the comments of the current post as a datagrid
 * <ReferenceManyField reference="comments" target="post_id">
 *     <Datagrid>
 *         <TextField source="id" />
 *         <TextField source="body" />
 *         <DateField source="created_at" />
 *         <EditButton />
 *     </Datagrid>
 * </ReferenceManyField>
 */
type Props = {
  basePath?: string
  body?: any
  // eslint-disable-next-line @typescript-eslint/ban-types
  classes?: object
  className?: string
  currentSort?: {
    field: string
    order: string
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  data?: object
  expand?: any
  hasBulkActions?: boolean
  hover?: boolean
  ids?: any[]
  // eslint-disable-next-line @typescript-eslint/ban-types
  loading?: Function
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSelect?: Function
  // eslint-disable-next-line @typescript-eslint/ban-types
  onToggleItem?: Function
  resource?: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  rowClick?: Function | string
  // eslint-disable-next-line @typescript-eslint/ban-types
  rowStyle?: Function
  selectedIds?: any[]
  // eslint-disable-next-line @typescript-eslint/ban-types
  setSort?: Function
  total?: number
  version?: number
  optimized?: boolean
  loaded?: boolean
  size?: 'medium' | 'small' | undefined
  sortableSource: string
  fullWidth?: boolean
  options?: any
}

const SortableDatagrid: FC<Props> = ({ classes: classesOverride, ...props }) => {
  const classes = useStyles({ classes: classesOverride })
  const {
    basePath,
    optimized = false,
    body = optimized ? <PureDatagridBody /> : <DatagridBody />,
    children,
    className,
    currentSort = { field: 'id', order: 'ASC' },
    data,
    expand,
    hasBulkActions,
    hover,
    ids,
    loading,
    loaded,
    onSelect,
    onToggleItem,
    resource,
    rowClick,
    rowStyle,
    selectedIds,
    setSort,
    size = 'small',
    total,
    version,
    sortableSource,
    ...rest
  } = props

  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  const updateSort = useCallback(
    (event) => {
      event.stopPropagation()
      setSort!(event.currentTarget.dataset.sort)
    },
    [setSort]
  )

  const handleSelectAll = useCallback(
    (event) => {
      if (event.target.checked) {
        onSelect!(ids!.concat(selectedIds!.filter((id) => !ids!.includes(id))))
      } else {
        onSelect!([])
      }
    },
    [ids, onSelect, selectedIds]
  )

  const onDragEnd = async (fromIndex: number, toIndex: number): Promise<void> => {
    try {
      // console.log(fromIndex, toIndex, resource, data, ids, selectedIds)
      let copy = ids!.map((item: any): any => {
        return (data as any)[item]
      })
      const item = copy[fromIndex]
      copy.splice(fromIndex, 1)
      copy.splice(toIndex, 0, item)
      copy = copy.map((item, index) => ({
        ...item,
        [sortableSource]: index + 1,
      }))

      const promises = copy.map((item, index) => {
        return dataProvider.update(resource as string, {
          id: item.id,
          data: {
            // ...item,
            id: item.id,
            [sortableSource]: item[sortableSource],
          },
          previousData: { ...item },
        })
      })
      await Promise.all(promises)
      refresh()
    } catch (error) {
      notify('unexcpeted error', 'warning')
      refresh()
    }
  }

  /**
   * if loaded is false, the list displays for the first time, and the dataProvider hasn't answered yet
   * if loaded is true, the data for the list has at least been returned once by the dataProvider
   * if loaded is undefined, the Datagrid parent doesn't track loading state (e.g. ReferenceArrayField)
   */
  if (loaded === false) {
    return (
      <DatagridLoading
        classes={classes}
        className={className}
        expand={expand}
        hasBulkActions={hasBulkActions}
        nbChildren={React.Children.count(children)}
        size={size}
      />
    )
  }

  /**
   * Once loaded, the data for the list may be empty. Instead of
   * displaying the table header with zero data rows,
   * the datagrid displays nothing in this case.
   */
  if (loaded && (ids!.length === 0 || total === 0)) {
    return null
  }

  /**
   * After the initial load, if the data for the list isn't empty,
   * and even if the data is refreshing (e.g. after a filter change),
   * the datagrid displays the current data.
   */
  return (
    <ReactDragListView ignoreSelector=".ignored-drag" onDragEnd={onDragEnd}>
      <Table className={classnames(classes.table, className)} size={size} {...sanitizeListRestProps(rest)}>
        <TableHead className={classes.thead}>
          <TableRow className={classnames(classes.row, classes.headerRow)}>
            {expand && <TableCell padding="none" className={classes.expandHeader} />}
            {hasBulkActions && (
              <TableCell padding="checkbox">
                <Checkbox
                  className="select-all"
                  color="primary"
                  checked={selectedIds!.length > 0 && ids!.length > 0 && ids!.every((id) => selectedIds!.includes(id))}
                  onChange={handleSelectAll}
                />
              </TableCell>
            )}
            {Children.map(children, (field, index) =>
              isValidElement(field) ? (
                <DatagridHeaderCell
                  className={classes.headerCell}
                  // currentSort={currentSort}
                  currentSort={currentSort}
                  field={field}
                  isSorting={currentSort!.field === (field.props.sortBy || field.props.source)}
                  key={field.props.source || index}
                  resource={resource || ''}
                  updateSort={updateSort}
                />
              ) : null
            )}
          </TableRow>
        </TableHead>
        {cloneElement(
          body,
          {
            basePath,
            className: classes.tbody,
            classes,
            expand,
            rowClick,
            data,
            hasBulkActions,
            hover,
            ids,
            onToggleItem,
            resource,
            rowStyle,
            selectedIds,
            version,
          },
          children
        )}
      </Table>
    </ReactDragListView>
  )
}

SortableDatagrid.defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [],
  selectedIds: [],
}

export default SortableDatagrid
