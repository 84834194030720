import React, { FC, useMemo } from 'react'
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core'
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive'
import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { QUERY_GET_PICTURES } from '../../queries'
import { Skeleton } from '@material-ui/lab'

type Props = {
  onClick?: (orderRoute: any) => void
  item: any
  disabled?: boolean
}
export const OrderRouteListItem: FC<Props> = ({ onClick, item, disabled }) => {
  const classes = useStyles()

  const { data: picturesData, loading: picturesLoading } = useQuery(QUERY_GET_PICTURES, {
    variables: {
      filters: {
        galleryId: item?.vehicle?.galleryId,
      },
    },
    skip: !item || !item.vehicle || !item.vehicle.galleryId,
  })
  const imageUrl = useMemo(
    () =>
      picturesData?.pictures?.data && picturesData.pictures.data.length > 0
        ? picturesData.pictures.data[0].urlMedium
        : undefined,
    [picturesData, item]
  )

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(item)
    }
  }, [onClick])

  return (
    <ListItem disabled={disabled} button onClick={handleClick}>
      <ListItemAvatar>
        {picturesLoading ? (
          <Skeleton variant="circle">
            <Avatar />
          </Skeleton>
        ) : (
          <Avatar src={imageUrl}>{!imageUrl && <AirplanemodeActiveIcon />}</Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        primary={`${item.departureAirport.iataCode} → ${item.arrivalAirport.iataCode}`}
        // secondary={new Date(item.flightDate.replace('Z', '')).toLocaleDateString()}
        secondary={
          <Box display="flex">
            <Typography variant="body2">{new Date(item.flightDate.replace('Z', '')).toLocaleDateString()}</Typography>
            <Typography variant="body2" className={classes.timeLabel}>
              {/* {new Date(props.flightDate).toLocaleTimeString()} */}
              {new Date(item.flightDate.replace('Z', '')).toLocaleTimeString().slice(0, -3)}
            </Typography>
          </Box>
        }
      />
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  timeLabel: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
}))
