import React, { FC } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { SelectInput } from 'ra-ui-materialui'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    width: (props: any) => (props.fillWidth ? '100%' : '50%'),
    alignSelf: 'stretch',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection:(props: any) => (props.fillWidth ? 'column' : 'row'),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  helper: {
    width: (props: any) => (props.fillWidth ? '100%' : '50%'),
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  input: {
    width: '100%',
  },
}))

type Props = {
  helperText?: string
  source?: string
  fullWidth?: boolean
  [x: string]: any
}

const FormSelectInput: FC<Props> = (props) => {
  const classes = useStyles({ fillWidth: props.fullWidth })
  const theme = useTheme()
  const isMdMediaquery = useMediaQuery(theme.breakpoints.up('md'))
  // console.log('select props: ', props)
  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputWrapper}>
        <SelectInput
          {...props}
          className={classes.input}
          helperText={isMdMediaquery ? undefined : typeof props.helperText === 'string' ? props.helperText : undefined}
        />
      </div>
      {(isMdMediaquery || (props.helperText && typeof props.helperText !== 'string')) && (
        <Typography className={classes.helper} variant="body2">
          {props.helperText}
        </Typography>
      )}
    </div>
  )
}

export default FormSelectInput
