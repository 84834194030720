import React, { FC } from 'react'
import { AutocompleteInput, required } from 'react-admin'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useFormState, useForm } from 'react-final-form'
import { useEffect } from 'react'
import { useDataProvider } from 'ra-core'

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    width: '50%',
    alignSelf: 'stretch',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  helper: {
    width: '50%',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: -20,
      marginLeft: theme.spacing(2),
    },
  },
  input: {
    width: '100%',
  },
}))

type Props = {
  [x: string]: any
}

const QuoteCustomerAutoCompleteInput: FC<Props> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMdMediaquery = useMediaQuery(theme.breakpoints.up('md'))

  const dataProvider = useDataProvider()
  const { values } = useFormState()
  const { change } = useForm()

  useEffect(() => {
    const updateClientInfo = async (): Promise<void> => {
      try {
        const foundedClient = await dataProvider.getOne('Customer', { id: values.customerId })

        if (foundedClient.data?.id) {
          change('languageId', foundedClient.data.languageId)
          console.log('adesso setto tutto', foundedClient.data)
        }
      } catch (error) {
        //
      }
    }

    if (values.customerId) {
      updateClientInfo()
    }
  }, [values.customerId])

  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputWrapper}>
        <AutocompleteInput
          {...props}
          className={classes.input}
          helperText={isMdMediaquery ? undefined : props.helperText}
          validate={props.required ? required() : undefined}
        />
      </div>
      {(isMdMediaquery || (props.helperText && typeof props.helperText !== 'string')) && (
        <Typography className={classes.helper} variant="body2">
          {props.helperText}
        </Typography>
      )}
    </div>
  )
}

export default QuoteCustomerAutoCompleteInput
