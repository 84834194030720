import * as React from 'react'
import { useCallback, FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import DownloadIcon from '@material-ui/icons/GetApp'
import {
  fetchRelatedRecords,
  useDataProvider,
  useNotify,
  useListContext,
  SortPayload,
  Exporter,
  FilterPayload,
  useResourceContext,
} from 'ra-core'
import BaseButton from './BaseButton'
import { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

type Props = {
  basePath?: string
  exporter?: Exporter
  filterValues?: FilterPayload
  icon?: JSX.Element
  label?: string
  maxResults?: number
  onClick?: (e: Event) => void
  resource?: string
  sort?: SortPayload
  loading?: boolean
}

const ExportButton: FunctionComponent<ExportButtonProps> = (props) => {
  const {
    maxResults = 1000,
    onClick,
    label = 'ra.action.export',
    icon = defaultIcon,
    exporter: customExporter,
    sort, // deprecated, to be removed in v4
    loading = false,
    ...rest
  } = props
  const { filterValues, currentSort, exporter: exporterFromContext, total } = useListContext(props)
  const resource = useResourceContext(props)
  const exporter = customExporter || exporterFromContext
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const handleClick = useCallback(
    (event) => {
      dataProvider
        .getList(resource, {
          sort: currentSort || sort,
          filter: filterValues,
          pagination: { page: 1, perPage: maxResults },
        })
        .then(
          ({ data }: any): any => exporter && exporter(data, fetchRelatedRecords(dataProvider), dataProvider, resource)
        )
        .catch((error) => {
          console.error(error)
          notify('ra.notification.http_error', 'warning')
        })
      if (typeof onClick === 'function') {
        onClick(event)
      }
    },
    [currentSort, dataProvider, exporter, filterValues, maxResults, notify, onClick, resource, sort]
  )

  return (
    <BaseButton
      onClick={props.onClick || handleClick}
      label={label}
      disabled={total === 0 || loading}
      {...sanitizeRestProps(rest)}
    >
      {loading ? <CircularProgress size={20} color="inherit" /> : icon}
    </BaseButton>
  )
}

const defaultIcon = <DownloadIcon />

const sanitizeRestProps = ({
  basePath,
  filterValues,
  resource,
  ...rest
}: Omit<ExportButtonProps, 'sort' | 'maxResults' | 'label' | 'exporter'>): any => rest

export type ExportButtonProps = Props & ButtonProps

export default ExportButton
