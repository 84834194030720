import React, { FC, useCallback, useMemo } from 'react'
import { Avatar, makeStyles, Typography } from '@material-ui/core'
import AttachmentIcon from '@material-ui/icons/AttachmentOutlined'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

type Props = {
  messageId: string
  loggedUserMail: string
  customerEmail?: string
  peopleEmails?: string[]
  mailHeader?: any[]
  additionalClassName?: string
  toName?: string
  toEmail?: string
  fromName?: string
  fromEmail?: string
  mailDate?: string
  hasAttachments?: boolean
}

export const MailHeadInfoPreview: FC<Props> = ({
  messageId,
  additionalClassName = '',
  customerEmail,
  peopleEmails,
  loggedUserMail,
  mailHeader,
  toName,
  toEmail,
  fromName,
  fromEmail,
  mailDate,
  hasAttachments,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handleMenuClose = useCallback((event: React.MouseEvent<any>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const handleOpenInGmail = useCallback(
    (event: React.MouseEvent<any>) => {
      event.preventDefault()
      event.stopPropagation()
      setAnchorEl(null)
      ;(window as any)
        .open(`https://mail.google.com/mail?authuser=${loggedUserMail}#all/${messageId}`, '_blank')
        .focus()
    },
    [loggedUserMail, messageId]
  )

  const fromInitials = useMemo(() => {
    if (fromName) {
      const splittedName = fromName.split(' ')
      if (splittedName.length === 1) {
        return `${splittedName[0][0].toUpperCase()}${splittedName[0][1].toUpperCase()}`
      } else if (splittedName.length > 1) {
        return `${splittedName[0][0].toUpperCase()}${splittedName[1][0].toUpperCase()}`
      }
    }

    return (fromEmail || 'user')[0].toUpperCase() + (fromEmail || 'user')[1].toUpperCase()
  }, [fromName, fromEmail])

  return (
    <div className={`${additionalClassName} ${classes.root}`}>
      <div className={classes.fromInfoContainer}>
        <Avatar className={classes.avatar}>{fromInitials}</Avatar>
        <div className={classes.infoContainer}>
          <Typography className={classes.fromName}>{fromName}</Typography>
          <Typography className={classes.fromEmail}>{fromEmail}</Typography>
        </div>
      </div>

      <div className={classes.rightContainer}>
        {hasAttachments && <AttachmentIcon fontSize="small" className={classes.attachmentIcon} />}
        <span>{mailDate}</span>

        <IconButton
          size="small"
          aria-label="more"
          aria-controls={`menu-${messageId}`}
          aria-haspopup="true"
          onClick={handleMenuOpen}
          className={classes.moreMenu}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>

        <Menu id={`menu-${messageId}`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleOpenInGmail}>Open in GMail</MenuItem>
        </Menu>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  fromInfoContainer: {
    display: 'flex',
  },
  attachmentIcon: {
    marginRight: '15px',
  },
  rightContainer: {
    opacity: 0.6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexBasis: '200px',
    flexGrow: 0,
    minWidth: '200px',
    marginLeft: '20px',
    // marginRight: '25px',
  },
  moreMenu: {
    marginLeft: '10px',
    marginBottom: '5px',
  },
  avatar: {},
  infoContainer: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  fromName: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  fromEmail: {
    opacity: 0.6,
  },
}))
