import React, { FC, useCallback } from 'react'
import { useForm, useFormState } from 'react-final-form'
import Box from '@material-ui/core/Box'
import OptionField from './OptionField'

type Props = {
  [x: string]: any
}

const OptionsSummary: FC<Props> = ({ isEdit = false }) => {
  const { values } = useFormState()
  const form = useForm()

  const onRemove = useCallback(
    (id: string): any => (): any => {
      const options = [...values.options]
      const index = options.findIndex((elem) => elem.id === id)
      if (index > -1) {
        options.splice(index, 1)
        form.change('options', options)
      }
    },
    [values?.options, form]
  )

  if (values && values.options && Array.isArray(values.options)) {
    return (
      <Box mt={6}>
        {values.options
          .sort((a, b) => a?.sellingPrice - b?.sellingPrice)
          .map((option: any, index: number) => {
            return (
              <OptionField
                {...option}
                key={`option-${option.id}`}
                index={index}
                onRemove={onRemove}
                currencyId={values.currencyId}
                savedRecord={isEdit ? option : undefined}
                fullQuote={values}
              />
            )
          })}
      </Box>
    )
  }
  return null
}

export default OptionsSummary
