import React, { FC, useCallback, useEffect, useState } from 'react'
import { Confirm } from 'react-admin'
import { useDelete } from 'ra-core'
import IconButton from '@material-ui/core/IconButton'
import { TiDelete } from 'react-icons/ti'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
  id: number | string
  onDocumentDeleted: (id: Record<string, any>) => void
}

const DeleteDocumentButton: FC<Props> = ({ id, onDocumentDeleted }) => {
  const classes = useStyles()
  const [deleteDocument, { loading, error, data }] = useDelete('Document', id)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const onDownloadClick = useCallback(() => {
    if (data && data.url) {
      window.open(data.url, '_blank')
    }
  }, [data])

  const handleDialogClose = useCallback((): void => setIsDialogOpen(false), [])
  const handleDialogOpen = useCallback((): void => setIsDialogOpen(true), [])

  useEffect(() => {
    if (!loading && data) onDocumentDeleted({ id: null })
  }, [loading, data])

  return (
    <>
      <IconButton onClick={handleDialogOpen} className={classes.iconButton}>
        <TiDelete color="darkred" />
      </IconButton>
      <Confirm
        isOpen={isDialogOpen}
        loading={loading}
        title="Delete Document"
        content="Are you sure you want to delete the specified document?"
        onConfirm={deleteDocument}
        onClose={handleDialogClose}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 0,
  },
}))

export default DeleteDocumentButton
