// import { useListContext } from 'ra-core'
// import React, { cloneElement, FC, isValidElement } from 'react'
// import Card from '@material-ui/core/Card'
// import {
//   List as ReactAdminList,
//   Pagination,
//   ListBase,
//   Title,
//   ListToolbar,
//   ListActions as DefaultActions,
// } from 'react-admin'

// const DefaultPagination: FC = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200]} {...props} />

// const ListView: FC<any> = ({
//   children,
//   filters,
//   pagination = <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200]} />,
//   actions = <DefaultActions />,
//   ...rest
// }) => {
//   const listContext = useListContext(rest)
//   // console.log('sono le props', listContext, rest)
//   return (
//     <>
//       <Title title={rest.title} defaultTitle={listContext.defaultTitle} />
//       <Card>
//         {(filters || actions) && (
//           <ListToolbar
//             filters={filters}
//             {...listContext} // deprecated, use ListContext instead, to be removed in v4
//             actions={actions}
//             exporter={listContext.exporter} // deprecated, use ListContext instead, to be removed in v4
//           />
//         )}
//       </Card>
//       {children}
//       {pagination && isValidElement(pagination) && cloneElement(pagination, listContext)}
//     </>
//   )
// }

// const List: FC<any> = ({ children, ...props }) => {
//   return (
//     <ListBase {...props}>
//       <ListView {...props}>{children}</ListView>
//     </ListBase>
//   )
// }

// const sort = { field: 'id', order: 'DESC' }

// List.defaultProps = {
//   pagination: <DefaultPagination />,
//   perPage: 10,
//   sort,
// }

// export default List

import React, { FC } from 'react'
import { useCheckMinimumRequiredProps, useListController, ListContextProvider } from 'ra-core'
import { Pagination } from 'react-admin'

import ListView from './ListView'
import { ListProps } from '../../types'
import { Box } from '@material-ui/core'

const DefaultPagination: FC = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200]} {...props} />

/**
 * List page component
 *
 * The <List> component renders the list layout (title, buttons, filters, pagination),
 * and fetches the list of records from the REST API.
 * It then delegates the rendering of the list of records to its child component.
 * Usually, it's a <Datagrid>, responsible for displaying a table with one row for each post.
 *
 * In Redux terms, <List> is a connected component, and <Datagrid> is a dumb component.
 *
 * The <List> component accepts the following props:
 *
 * - actions
 * - aside
 * - component
 * - filter (the permanent filter to apply to the query)
 * - filters (a React component used to display the filter form)
 * - pagination
 * - perPage
 * - sort
 * - title
 *
 * @example
 *
 * const PostFilter = (props) => (
 *     <Filter {...props}>
 *         <TextInput label="Search" source="q" alwaysOn />
 *         <TextInput label="Title" source="title" />
 *     </Filter>
 * );
 * export const PostList = (props) => (
 *     <List {...props}
 *         title="List of posts"
 *         sort={{ field: 'published_at' }}
 *         filter={{ is_published: true }}
 *         filters={PostFilter}
 *     >
 *         <Datagrid>
 *             <TextField source="id" />
 *             <TextField source="title" />
 *             <EditButton />
 *         </Datagrid>
 *     </List>
 * );
 */
type Props = ListProps & {
  titleIcon?: React.ReactNode
}

const List: FC<Props> = (props) => {
  useCheckMinimumRequiredProps('List', ['children'], props)
  const controllerProps = useListController(props)
  return (
    <ListContextProvider value={controllerProps}>
      <ListView {...props} {...controllerProps} component={Box} />
    </ListContextProvider>
  )
}

export default List
