import React, { FC } from 'react'
import { TextField, ReferenceField } from 'react-admin'

const Text: FC<any> = (props) => {
  const record = { ...props.record, fullValue: `${props.record.name} (${props.record.value})` }
  return <TextField record={record} source="fullValue" />
}

const CarbonOffsetReferenceField: FC<any> = ({
  source = 'vehicleCarbonOffsetId',
  reference = 'VehicleCarbonOffset',
  ...props
}) => {
  return (
    <ReferenceField {...props} source={source} reference={reference} link={false}>
      <Text />
    </ReferenceField>
  )
}

export default CarbonOffsetReferenceField
