import React, { FC } from 'react'
import RAList from '../../components/list/List'
import {
  TextField,
  NumberField,
  Filter,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
} from 'react-admin'

import Datagrid from '../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'
import { FaStore } from 'react-icons/fa'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import FilterTextInput from '../../components/FilterTextInput'
import BooleanStatusField from '../../components/BooleanStatusField'
import { ReferenceField } from 'ra-ui-materialui'

type Props = {}

const PermissionRoleFilters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput label="Cerca" source="name" alwaysOn />
    </Filter>
  )
}

const PermissionRoleList: FC<Props> = (props) => {
  return (
    <RAList
      {...props}
      title="Ruoli"
      sort={{ field: 'permissionName', order: 'ASC' }}
      titleIcon={<FaStore />}
      actions={<CreateButton label="Nuovo Ruolo" />}
      filters={<PermissionRoleFilters variant="outlined" />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="permissionName" />
        <ReferenceArrayField sort={{ field: 'name', order: 'ASC' }} reference="Role" source="roles.id">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <GenericMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default PermissionRoleList
