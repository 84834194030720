import React, { FC, useEffect } from 'react'
import { ReferenceInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/details/Edit'
import FormTextInput from '../../components/FormTextInput'
import FormSelectInput from '../../components/input/FormSelectInput'
import SaveUserButton from './SaveUserButton'
import { useDataProvider, useTranslate } from 'ra-core'
import { BooleanInput } from 'ra-ui-materialui'

type Props = {
  //
}

const autocompleteQuery = (name: string): Record<string, any> => ({ name })
const sort = { field: 'name', order: 'ASC' }

const RoleSelector: FC<any> = (props) => {
  const form = useForm()
  const { values, touched } = useFormState()
  const translate = useTranslate()

  useEffect(() => {
    if (props.record.rolesIds && props.record.rolesIds.length > 0 && !touched!['roleId'] && !values.roleId) {
      form.change('roleId', props.record.rolesIds[0])
    }
  }, [])

  return (
    <ReferenceInput {...props} source="roleId" reference="Role">
      <FormSelectInput optionText="name" fullWidth helperText={translate('resources.User.helpers.roleId')} />
    </ReferenceInput>
  )
}

const OrganizationSelector: FC<any> = (props) => {
  const form = useForm()
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const translate = useTranslate()

  useEffect(() => {
    const fetchTeamMember = async (): Promise<void> => {
      try {
        const result = await dataProvider.getList('TeamMember', {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            accountId: props.record.id,
          },
        })

        if (result.data && result.data.length > 0 && !values.officeId) {
          form.change('organizationId', result.data[0].organizationId)
          form.change('approved', result.data[0].approved)
        }
      } catch (e) {
        console.log('error fetching teamMember', e)
      }
    }

    fetchTeamMember()
  }, [])

  return (
    <ReferenceInput
      {...props}
      source="organizationId"
      reference="Organization"
      filterToQuery={autocompleteQuery}
      sort={sort}
    >
      <FormSelectInput optionText="name" helperText={translate('resources.User.helpers.organizationId')} />
    </ReferenceInput>
  )
}

const UserEdit: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <Edit {...props} component="div">
      <SectionedForm
        variant="outlined"
        redirect="show"
        title={translate('resources.User.titles.create')}
        saveButtonComponent={<SaveUserButton />}
      >
        <FormSection title={translate('resources.User.titles.details')}>
          <FormTextInput {...props} source="firstName" />
          <FormTextInput {...props} source="lastName" helperText="" />
          <FormTextInput {...props} source="email" />
          <RoleSelector />
          <OrganizationSelector />
          <BooleanInput {...props} source="approved" label="Visible in filters" />
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default UserEdit
