import React, { FC } from 'react'
import { NumberInput, TextInput, ReferenceInput } from 'react-admin'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import Filter from '../../components/list/filter/Filter'
import SearchAutoCompleteInput from '../../components/SearchAutoCompleteInput'

type Props = {
  [x: string]: any
}

const autocompleteQuery = (name: string): Record<string, any> => ({ name })
const sort = { field: 'name', order: 'ASC' }

const VehicleFilters: FC<Props> = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        source="vehicleManufacturerId"
        reference="VehicleManufacturer"
        alwaysOn
        filterToQuery={autocompleteQuery}
        sort={sort}
      >
        <SearchAutoCompleteInput />
      </ReferenceInput>
      <TextInput source="licensePlate" />
      <NumberInput source="numberOfSeats" />
      <ReferenceInput source="vehicleModelId" reference="VehicleModel" filterToQuery={autocompleteQuery} sort={sort}>
        <AutocompleteInputInDrawer optionText="name" />
      </ReferenceInput>
    </Filter>
  )
}

export default VehicleFilters
