import React, { FC, useCallback } from 'react'
import { TextInput, ReferenceInput, AutocompleteInput, BooleanInput } from 'react-admin'
import FormSection from './../../components/forms/FormSection'

type Props = {}

const StateForm: FC<Props> = props => {
  const filterToQuery = useCallback(() => (name: string): Record<string, string> => ({ name }), [])
  return (
    <FormSection title="State Info" {...props}>
      <TextInput source="name" />
      <TextInput source="iso" />
      <ReferenceInput source="countryId" reference="Country" {...{ filterToQuery }}>
        <AutocompleteInput optionText="name" emptyText="ra.boolean.null" />
      </ReferenceInput>
      <BooleanInput source="active" defaultValue={true} />
    </FormSection>
  )
}

export default StateForm
