import { useGetOne } from 'ra-core'
import React, { FC } from 'react'
import { DateField } from 'react-admin'
import ErrorIcon from '@material-ui/icons/Error'
import LinearProgress from '@material-ui/core/LinearProgress'

type Props = {
  record?: Record<string, any>
  source?: string
  label?: string
}

const FirstOrderRouteDateField: FC<Props> = ({ record = {}, source = 'routes', ...props }) => {
  const { loading, error, data } = useGetOne(
    'OrderRoute',
    record && record[source] && record[source][0] && record[source][0].id ? record[source][0].id : ''
  )

  if (loading) return <LinearProgress />
  if (error) return <ErrorIcon color="error" />
  if (data) return <DateField {...props} record={data} source="flightDate" />
  return null
}

export default FirstOrderRouteDateField
