import React, { FC, useCallback, useState } from 'react'
import { useTranslate, useNotify, useRefresh, useDataProvider } from 'ra-core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { makeStyles } from '@material-ui/core'

export enum ORDER_STATUS {
  confirmed = 'confirmed',
  sent = 'sent',
  confirmedAndPaid = 'confirmedAndPaid',
  canceled = 'canceled',
  canceledWithPenalty = 'canceledWithPenalty',
  canceledWithPenaltyAndPaid = 'canceledWithPenaltyAndPaid',
}

type Statuses = {
  name: ORDER_STATUS
  color: string
}[]

export const ORDER_STATUS_OPTIONS: Statuses = [
  { name: ORDER_STATUS.confirmed, color: '#62ed38' },
  { name: ORDER_STATUS.sent, color: '#1e76ea' },
  { name: ORDER_STATUS.confirmedAndPaid, color: '#62ed38' },
  { name: ORDER_STATUS.canceled, color: '#ed3b38' },
  { name: ORDER_STATUS.canceledWithPenalty, color: '#ed3b38' },
  { name: ORDER_STATUS.canceledWithPenaltyAndPaid, color: '#ffb516' },
]

type Props = {
  currentStatus: ORDER_STATUS
  id: number
}

const OrderStatusDropDown: FC<Props> = ({ currentStatus, id }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // const [updateQuoteStatus, { loading, error, data }] = useMutation(MUTATION_UPDATE_QUOTE_STATUS)
  // const [updateOrder, { loading, error, data }] = useUpdate('Order', id)
  const [loading, setLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const onClose = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const onStatusClick = useCallback(
    (status: ORDER_STATUS) => async (event: React.MouseEvent<HTMLLIElement>): Promise<void> => {
      event.preventDefault()
      event.stopPropagation()
      setAnchorEl(null)
      // updateOrder({ variables: { }})
      // updateQuoteStatus({ variables: { status, id } })
      try {
        setLoading(true)
        const result = await dataProvider.update('Order', { id, data: { status }, previousData: { id } })
        if (result && result.data) {
          notify(translate('ra.notification.updated', { smart_count: 1 }))
          refresh()
        }
      } catch (error) {
        notify((error as any).message, 'error')
      } finally {
        setLoading(false)
      }
    },
    []
  )

  // useEffect(() => {
  //   if (!loading && !error && data) {
  //     notify(translate('ra.notification.updated', { smart_count: 1 }))
  //     refresh()
  //   }
  // }, [data, error, loading])

  // useEffect(() => {
  //   if (error) {
  //     notify(error.message, 'error')
  //   }
  // })

  return (
    <>
      <IconButton className={classes.iconMore} edge="start" onClick={onClick}>
        <FiberManualRecordIcon
          fontSize="small"
          style={{
            marginLeft: -4,
            marginRight: 6,
            color: ORDER_STATUS_OPTIONS.find((status: any) => status.name === currentStatus)?.color,
          }}
        />
        <Typography variant="body1" className={classes.textAlignLeft}>
          {translate(`customFields.orderStatus.${currentStatus}`)}
        </Typography>
        <ExpandMoreIcon />
      </IconButton>
      <Menu id={'statuses-drop-down'} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
        {loading ? (
          <MenuItem key="status-drop-down-loading" disabled className={classes.itemLoading}>
            <CircularProgress />
          </MenuItem>
        ) : (
          ORDER_STATUS_OPTIONS.map((status) => {
            return (
              <MenuItem
                key={`status-drop-down-${status.name}`}
                onClick={onStatusClick(status.name)}
                disabled={status.name === currentStatus}
              >
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" style={{ color: status.color }} />
                </ListItemIcon>
                {translate(`customFields.orderStatus.${status.name}`)}
              </MenuItem>
            )
          })
        )}
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  iconMore: {
    margin: 0,
    padding: 0,
  },
  itemLoading: {
    minWidth: 150,
    display: 'flex',
    justifyContent: 'center',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
}))

export default OrderStatusDropDown
