import React, { FC, useEffect } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IOSSwitch from './IOSSwitch'
import { useFormState, useForm } from 'react-final-form'
import BaseTypography from './BaseTypography'
import { Box } from '@material-ui/core'

type Props = {
  source: string
  label: string
  // startingValue?: boolean
  [x: string]: any
}

const useStyles = makeStyles((theme) => ({
  switchCard: {
    marginTop: theme.spacing(5),
  },
  switchCardContent: {
    textAlign: 'center',
    padding: `${theme.spacing(5)}px ${theme.spacing(3)}px`,
  },
}))

/**
 *
 * @param startingValue this boolean will be ignored if field is alread filled (example from local storage)
 */
const SwitchCardInput: FC<Props> = ({ label, source, startingValue = false, ...rest }) => {
  const classes = useStyles()
  const { values } = useFormState()
  const form = useForm()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    form.change(source, checked)
  }

  useEffect(() => {
    if (values[source] === undefined) {
      setTimeout(() => form.change(source, startingValue), 500)
    }
  }, [])

  return (
    <Card className={classes.switchCard}>
      <Box className={classes.switchCardContent}>
        <BaseTypography variant="h4" weight="bold">
          {label}
        </BaseTypography>
        <Box mb={2} />
        <IOSSwitch {...rest} onChange={handleChange} checked={values[source]} startingValue={startingValue} />
      </Box>
    </Card>
  )
}

export default SwitchCardInput
