import { gql, useMutation, useQuery } from '@apollo/client'
import React from 'react'
import { useInput } from 'ra-core'
import CreatableSelect from 'react-select/creatable'

const QUERY_marketingOrigins = gql`
  query marketingOrigins {
    marketingOrigins {
      data {
        id
        name
      }
    }
  }
`

const MUTATION_createMarketingOrigin = gql`
  mutation createMarketingOrigin($data: MarketingOriginCreateDataInput!) {
    createMarketingOrigin(data: $data) {
      id
      name
    }
  }
`

interface MarketingOrigin {
  id: string
  name: string
}

export function MarketingOriginField(props: any) {
  const { data, refetch } = useQuery(QUERY_marketingOrigins)
  const [createMarketingOrigin] = useMutation(MUTATION_createMarketingOrigin)

  const {
    input: { name, onChange, value },
  } = useInput({
    defaultValue: null,
    ...props,
  })

  const handleCreate = async (inputValue: string) => {
    const { data } = await createMarketingOrigin({
      variables: {
        data: {
          name: inputValue,
        },
      },
    })

    await refetch()

    onChange(data.createMarketingOrigin.id)
  }

  const options = (data?.marketingOrigins?.data || []) as MarketingOrigin[]

  return (
    <CreatableSelect
      value={options.find((mo) => mo.id === value)}
      onChange={(ev) => onChange(ev?.id)}
      onCreateOption={handleCreate}
      placeholder="Marketing Origin"
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      // styles={{
      //   control: (provided, state) => ({
      //     ...provided,
      //     borderWidth: state.isFocused ? '0px' : '1px',
      //     borderStyle: 'solid',
      //     borderColor: 'gray.100',
      //     borderRadius: '8px',
      //     height: '40px',
      //   }),
      //   container: (provided, state) => ({
      //     ...provided,
      //     width: '100%',
      //   }),
      // }}
      components={{
        IndicatorSeparator: () => null,
      }}
      options={options}
    />
  )
}
