import React, { FC, useCallback } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField, SelectInput } from 'react-admin'

import Datagrid from '../../components/Datagrid'
import { FaUsers } from 'react-icons/fa'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import FilterTextInput from '../../components/FilterTextInput'
import Filter from '../../components/list/filter/Filter'
import ClientNameField from '../../components/field/ClientNameField'
import { useTranslate } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
import { Route, useHistory } from 'react-router-dom'
import DrawerHeader from '../../components/DrawerHeader'
// import ClientEdit from './ClientEdit'
import Drawer from '../../components/Drawer'
import LangWithFlagSelectInput from '../../components/input/LangWithFlagSelectInput'
import { ReferenceField, ReferenceInput } from 'ra-ui-materialui'

type Props = {}

const ClientFilters: FC<any> = (props) => {
  const classes = useStyles()
  return (
    <Filter {...props} variant="outlined">
      <FilterTextInput label="Search" source="q" alwaysOn />
      {/* <NullableBooleanInput source="isCompany" fullWidth className={classes.isCompanyInput} />
      <LangWithFlagSelectInput allowEmpty /> */}
      <ReferenceInput
        source="mainReferenceTeamMemberId"
        reference="TeamMember"
        filterToQuery={(q: string): any => ({ q })}
      >
        <SelectInput optionText="fullName" />
      </ReferenceInput>
      <ReferenceInput
        source="marketingOriginId"
        reference="MarketingOrigin"
        filterToQuery={(q: string): any => ({ q })}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  )
}

const onRowClick = (id: number, basePath: string, record: Record<string, any>): string => `/Customer/${id}/show`

const LeadClientList: FC<Props> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const handleDrawerClose = useCallback(() => history.push('/LeadClient'), [])
  return (
    <>
      <RAList
        {...props}
        titleIcon={<FaUsers />}
        filters={<ClientFilters />}
        filter={{ customerStatus: 'LEAD' }}
        bulkActionButtons={false}
      >
        <Datagrid rowClick={onRowClick}>
          <NumberField source="id" />
          <ClientNameField label={translate('resources.Customer.fields.name')} />
          <TextField source="email" />
          <ReferenceField source="mainReferenceTeamMemberId" link={false} reference="TeamMember">
            <TextField source="fullName" />
          </ReferenceField>
          {/* <GenericMoreMenu /> */}
        </Datagrid>
      </RAList>
      {/* <Route path="/Customer/:id">
        {({ match }): React.ReactNode => {
          const isMatch = match && match.params && match.params.id !== 'create'

          return (
            <Drawer open={isMatch} onClose={handleDrawerClose}>
              {isMatch ? (
                <>
                  <DrawerHeader
                    buttonOnClick={handleDrawerClose}
                    title={`${translate('ra.action.edit')} #${match?.params?.id}`}
                  />
                  <ClientEdit
                    id={isMatch && match && match.params ? match.params.id : undefined}
                    onCancel={handleDrawerClose}
                    {...props}
                  />
                </>
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          )
        }}
      </Route> */}
    </>
  )
}

const useStyles = makeStyles((theme: any) => ({
  export: {
    marginLeft: theme.spacing(3),
  },
  drawerContent: {
    width: '26vw',
    minWidth: 260,
    content: "''",
  },
  isCompanyInput: {
    width: '100%',
  },
}))

export default LeadClientList
