import React, { FC } from 'react'
import { TextField, BooleanField } from 'react-admin'
import RAList from '../../components/list/List'
import Datagrid from '../../components/Datagrid'
import { FaLanguage } from 'react-icons/fa'
import FlagFromISOField from '../../components/field/FlagFromISOField'

type Props = {
  [x: string]: any
}

const LanguageList: FC<Props> = (props) => {
  return (
    <RAList {...props} titleIcon={<FaLanguage />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <FlagFromISOField source="isoCode" label="Flag" />
        <TextField source="isoCode" />
        <TextField source="nativeName" />
        <BooleanField source="active" />
      </Datagrid>
    </RAList>
  )
}

export default LanguageList
