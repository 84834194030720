import React, { FC } from 'react'
import { Create, ReferenceInput } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
// unable to use TRANSFORM prop from our Create component
// import Create from '../../components/details/Create'
import { useDataProvider, useNotify, useTranslate } from 'ra-core'
import FormSelectInput from '../../components/input/FormSelectInput'
import FormTextInput from '../../components/FormTextInput'
import FormNumberInput from '../../components/input/FormNumberInput'
import ImageGalleryInput from '../../components/input/ImageGalleryInput'
import FormAutoCompleteInput from '../../components/FormAutoCompleteInput'
import CarbonOffsetReferenceInput from '../../components/input/CarbonOffsetReferenceInput'

const sort = { field: 'name', order: 'ASC' }
const filterToQueryHomeBase = (icao: string): any => ({ icao })
const filterToQuery = (name: string): any => ({ name })
const sortHomeBase = { field: 'icaoCode', order: 'ASC' }

type Props = {}

const VehicleCreate: FC<Props> = (props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const transform = async (data: any): Promise<any> => {
    try {
      // creating image gallery
      let galleryId = null
      const galleryRes = await dataProvider.create('Gallery', {
        data: { name: `aircraft-${data?.licensePlate}` },
      })
      if (galleryRes.data.id === undefined) throw new Error('Error creating gallery')
      galleryId = galleryRes.data.id
      if (data && data.images && data.images.length > 0) {
        // adding images to gallery
        for (let i = 0; i < data.images.length; i++) {
          const picResult = await dataProvider.create('Picture', {
            data: {
              description: 'test',
              galleryId,
              file: { ...data.images[i] },
            },
          })
          // console.log('picResult: ', picResult)
        }
      }
      // preparing data for vehicleCreate
      if (galleryId !== null) {
        data.galleryId = galleryId
        delete data.images
      }

      return data
    } catch (error) {
      console.error('Error while creating vehicle: ', error)
      notify(error, 'error')
    }
  }

  return (
    <Create {...props} component="div" transform={transform}>
      <SectionedForm variant="outlined" redirect="list" title={translate('resources.Vehicle.titles.create')}>
        <FormSection title="Info">
          <ReferenceInput
            reference="VehicleManufacturer"
            source="vehicleManufacturerId"
            sort={sort}
            filterToQuery={filterToQuery}
          >
            <FormAutoCompleteInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput reference="VehicleModel" source="vehicleModelId" sort={sort} filterToQuery={filterToQuery}>
            <FormAutoCompleteInput optionText="name" />
          </ReferenceInput>
          <FormTextInput source="licensePlate" />
          <ReferenceInput
            reference="Airport"
            source="homeBaseIcao"
            filterToQuery={filterToQueryHomeBase}
            sort={sortHomeBase}
          >
            <FormAutoCompleteInput optionText="id" />
          </ReferenceInput>
          <FormNumberInput source="numberOfSeats" />
          <FormNumberInput source="yearOfManufacture" />
          <FormNumberInput source="yearOfRestyling" />
          <ReferenceInput reference="VehicleCategory" source="vehicleCategoryId" sort={sort}>
            <FormSelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput reference="VehicleSafetyType" source="vehicleSafetyTypeId" sort={sort}>
            <FormSelectInput optionText="name" />
          </ReferenceInput>
          <CarbonOffsetReferenceInput inDrawer />
          <ImageGalleryInput source="images" label={translate('resources.Vehicle.fields.images')} />
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default VehicleCreate
