import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import {
  MUTATION_CREATE_ORDER_ACTIVITY,
  MUTATION_DELETE_ORDER_ACTIVITY,
  QUERY_GET_ORDER_ACTIVITIES,
  QUERY_GET_SERVICE_LOGS,
} from '../../queries'
import { ActivityForm } from './ActivityForm'
import { ActivitiesList } from './ActivitiesList'
import { useCombinedPagination } from 'use-combined-pagination'
import { useNotify } from 'ra-core'
import { Box, chakra } from '@chakra-ui/react'
import { TeamMembersFilter } from './TeamMembersFilter'
import { ActivityTypeFilter } from './ActivityTypeFilter'
import InfiniteScroll from 'react-infinite-scroll-component'

type Props = {
  record: any
}

// TODO: CHANGE THIS
const LIMIT_PER_PAGE = 10

export const OrderActivities: FC<Props> = (props) => {
  const client = useApolloClient()
  const notify = useNotify()

  const [filteredTeamMemberIds, setFilteredTeamMemberIds] = useState<string[]>([])
  const [filteredActivityTypes, setFilteredActivityTypes] = useState<string[] | undefined>(undefined)

  const fetchLogs = useCallback(
    async (page: number): Promise<any[]> => {
      try {
        const filters: any = {
          _Op_or: [
            {
              service: 'cielo-aviation',
              domain: 'CieloQuote',
              action: 'confirmQuoteOption',
              data: {
                _Op_contains: {
                  id: parseInt(props.record.id, 10),
                },
              },
            },
            {
              service: 'cielo-aviation',
              data: {
                _Op_or: [
                  {
                    _Op_contains: {
                      orderId: parseInt(props.record.id, 10),
                    },
                  },
                  {
                    _Op_contains: {
                      orderId: props.record.id,
                    },
                  },
                ],
              },
            },
            {
              domain: 'Order',
              data: {
                _Op_contains: {
                  id: parseInt(props.record.id, 10),
                },
              },
            },
          ],
        }

        if (filteredTeamMemberIds.length > 0) {
          filters['userId'] = { _Op_in: filteredTeamMemberIds }
        }

        const logsResult = await client.query({
          query: QUERY_GET_SERVICE_LOGS,
          fetchPolicy: 'network-only',
          variables: {
            filters,
            pagination: {
              limit: LIMIT_PER_PAGE,
              offset: page,
            },
            sort: {
              createdAt: 'DESC',
            },
          },
        })

        if (!logsResult?.data?.serviceLogs?.data?.length) {
          throw new Error('Error fetching data')
        }

        return logsResult?.data.serviceLogs.data
      } catch (error) {
        console.error('Error fetching customer logs: ', error)
      }

      return []
    },
    [filteredTeamMemberIds]
  )

  const fetchActivities = useCallback(
    async (page: number): Promise<any[]> => {
      console.log('fetchActivities', page, filteredActivityTypes)
      try {
        const activitiesResult = await client.query({
          query: QUERY_GET_ORDER_ACTIVITIES,
          fetchPolicy: 'no-cache',
          variables: {
            filters: {
              orderId: props.record.id,
              createdUserIds: filteredTeamMemberIds.length > 0 ? filteredTeamMemberIds : undefined,
              typeIds: filteredActivityTypes && filteredActivityTypes?.length > 0 ? filteredActivityTypes : [],
            },
            pagination: {
              limit: LIMIT_PER_PAGE,
              offset: page,
            },
            sort: {
              date: 'DESC',
            },
          },
        })

        if (!activitiesResult?.data?.cieloOrderActivities?.data?.length) {
          throw new Error('Error fetching data')
        }

        return activitiesResult.data.cieloOrderActivities.data
      } catch (error) {
        console.error('Error fetching order activities: ', error)
      }

      return []
    },
    [filteredTeamMemberIds, filteredActivityTypes]
  )

  const getters = useMemo(() => {
    if (!filteredActivityTypes) {
      return []
    }

    const newGetters = [fetchLogs, fetchActivities]

    return newGetters
  }, [filteredActivityTypes, fetchLogs])

  const { loading, data, getNext, refetch, state, hasNext, setState } = useCombinedPagination({
    getters: getters,
    sortKey: 'date',
  })

  const handleTeamMembersFilterChange = useCallback((selectedIds: string[]) => {
    setFilteredTeamMemberIds(selectedIds)
  }, [])

  const handleActivityTypesFilterChange = useCallback((items: string | string[]) => {
    console.log('sono activity types', items)
    setFilteredActivityTypes(typeof items === 'string' ? [items] : items)
  }, [])

  const handleActivityCreated = useCallback(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    if (!loading) {
      refetch()
    }
  }, [filteredTeamMemberIds, filteredActivityTypes])

  return (
    <div>
      <ActivityForm
        {...props}
        mutation={MUTATION_CREATE_ORDER_ACTIVITY}
        source="orderId"
        id={props.record.id}
        onCreated={handleActivityCreated}
      />

      <Box d="flex" alignItems="center" justifyContent="flex-end" pt={4} pb={2}>
        <TeamMembersFilter selectedIds={filteredTeamMemberIds} onSelectedIdsChange={handleTeamMembersFilterChange} />
        <ActivityTypeFilter
          value={filteredActivityTypes}
          onChange={handleActivityTypesFilterChange}
          showAdditionalTypes={false}
        />
      </Box>

      <InfiniteScroll
        dataLength={data?.length || 0}
        next={getNext}
        hasMore={hasNext}
        loader={<chakra.div minH="180px">Loading...</chakra.div>}
        hasChildren={(data?.length || 0) > 0}
        scrollThreshold={0.8}
      >
        <ActivitiesList
          onDeleteComplete={(): void => {
            //
          }}
          data={data}
          loading={loading}
          deleteMutation={MUTATION_DELETE_ORDER_ACTIVITY}
        />
      </InfiniteScroll>
      {/* {loading && <chakra.div minH="400px">Loading...</chakra.div>} */}
      {/* {hasNext && <Button label="Load More" onClick={getNext} />} */}
    </div>
  )
}
