import * as React from 'react'
import PropTypes from 'prop-types'

import { Record, useEditContext, useResourceDefinition } from 'ra-core'
import ShowButton from '../button/ShowButton'
import { FC } from 'react'
// import TopToolbar from '../layout/TopToolbar'

/**
 * Action Toolbar for the Edit view
 *
 * Internal component. If you want to add or remove actions for a Edit view,
 * write your own EditActions Component. Then, in the <Edit> component,
 * use it in the `actions` prop to pass a custom component.
 *
 * @example
 *     import Button from '@material-ui/core/Button';
 *     import { TopToolbar, ShowButton, Edit } from 'react-admin';
 *
 *     const PostEditActions = ({ basePath, record, resource }) => (
 *         <TopToolbar>
 *             <ShowButton basePath={basePath} record={record} />
 *             // Add your custom actions here
 *             <Button color="primary" onClick={customAction}>Custom Action</Button>
 *         </TopToolbar>
 *     );
 *
 *     export const PostEdit = (props) => (
 *         <Edit actions={<PostEditActions />} {...props}>
 *             ...
 *         </Edit>
 *     );
 */
const EditActions: FC<EditActionsProps> = ({ className, ...rest }) => {
  const { basePath, record } = useEditContext(rest)
  const { hasShow } = useResourceDefinition(rest)
  return <React.Fragment />

  //   return (
  //     <TopToolbar className={className} {...sanitizeRestProps(rest)}>
  //       {hasShow && <ShowButton basePath={basePath} record={record} />}
  //     </TopToolbar>
  //   )
}

const sanitizeRestProps = ({
  basePath = null,
  hasCreate = null,
  hasEdit = null,
  hasShow = null,
  hasList = null,
  ...rest
}): any => rest

export interface EditActionsProps {
  basePath?: string
  className?: string
  data?: Record
  hasShow?: boolean
  hasList?: boolean
  resource?: string
}

export default EditActions
