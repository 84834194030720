import { Typography, TypographyProps } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import BaseTypography, { BaseTypographyProps } from '../BaseTypography'
import { useInfoCardStyles } from './InfoCard.styles'

type Overrided = Omit<TypographyProps, 'variant'> & {
  variant?: 'label' | 'value' | 'title' | 'caption'
}

interface Props extends Overrided {
  children: any
  weight?: 'light' | 'bold'
  [x: string]: any
}

const InfoCardTypography: FC<Props> = ({ variant, ...rest }) => {
  const classes = useInfoCardStyles()

  const typoClass = useMemo(() => {
    if (variant == 'value') return classes.typoValue
    else if (variant == 'title') return classes.typoTitle
    else if (variant == 'caption') return classes.typoCaption
    return classes.typoLabel
  }, [variant])

  return <BaseTypography className={typoClass} {...rest} />
}

export default InfoCardTypography
