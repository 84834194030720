import React, { FC, useCallback } from 'react'
import { Link, Route, useHistory } from 'react-router-dom'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import { useTranslate, useShowController, usePermissions } from 'ra-core'
import { ReferenceManyField, TextField, FunctionField } from 'react-admin'
import { Pagination } from '../../components/list/pagination'
import Datagrid from '../../components/Datagrid'
import Empty from '../../components/list/Empty'
import { InfoCard, InfoCardItem, InfoCardWrapper, InfoCardTypography, InfoCardAvatar } from '../../components/InfoCard'
import ClientNameField from '../../components/field/ClientNameField'
import AddressDetailsField from '../../components/AddressDetailsField'
import Drawer from '../../components/Drawer'
import DrawerHeader from '../../components/DrawerHeader'
import GenericMoreMenu from '../../components/GenericMoreMenu'
import InDrawerAddressEdit from './InDrawerAddressEdit'
import InDrawerPersonForm from './InDrawerPersonForm'
import ShowView from '../../components/details/ShowView'
import CreateButton from '../../components/button/CreateButton'
import ClientEdit from './CustomerEdit'
import { FaBan, FaListOl } from 'react-icons/fa'
import ClientTotalQuotesField from '../../components/field/ClientTotalQuotesField'
import ClientTotalContracts from '../../components/ClientTotalContracts'
import { GoTasklist } from 'react-icons/go'
import { ORDER_STATUS } from '../../components/OrderStatusDropDown'
import ClientTotalPrices from '../../components/ClientTotalPrices'
import { GiMoneyStack, GiReceiveMoney } from 'react-icons/gi'
import { canSeeClientStats } from '../../utils/hasPermission'
import BaseButton from '../../components/button/BaseButton'
import { CustomerActivities } from '../../components/Activities/CustomerActivities'

const ClientShowComp = (props: any): any => {
  const { loading, permissions } = usePermissions()
  const classes = useStyles()
  const translate = useTranslate()

  const history = useHistory()

  const handleDrawerClose = useCallback(() => history.goBack(), [])

  return (
    <>
      <Box display="flex" position="relative">
        <Box pr={3}>
          <InfoCardWrapper>
            <Typography variant="h1" gutterBottom>
              {translate('resources.Customer.titles.details')}
            </Typography>
            <InfoCard>
              <InfoCardItem direction="column" alignItems="flex-start">
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                  <ClientNameField {...props} variant="h3" />
                  <FunctionField
                    {...props}
                    render={(record: any): string =>
                      record.isCompany
                        ? `${translate('resources.Customer.fields.vat')}: ${record.vat || 'N.D.'}`
                        : `${translate('resources.Customer.fields.fiscalCode')}: ${record.fiscalCode || 'N.D.'}`
                    }
                    className={classes.field}
                  />
                  {props.record.isCompany && props.record.pec && (
                    <FunctionField
                      {...props}
                      render={(record: any): string => `${translate('resources.Customer.fields.pec')}: ${record.pec}`}
                      className={classes.field}
                    />
                  )}

                  <Box p="10">
                    {/* <Box display="flex" flexDirection="column" justifySelf="flex-start">
      
              <ReferenceField {...props} label={false} source="customerId" reference="Customer" link={false}>
                <ChipField size="small" color="primary" source="customerStatus" />
              </ReferenceField>
            </Box> */}
                    {/* <QuoteShowComp {...props} /> */}
                  </Box>
                </Box>
              </InfoCardItem>
              <InfoCardItem>
                <InfoCardTypography variant="label">{translate('resources.Customer.fields.email')}</InfoCardTypography>
                <TextField {...props} source="email" className={classes.cardValue} emptyText={'N.D.'} />
              </InfoCardItem>
            </InfoCard>
            <Box mb={2} />
            <InfoCard>
              <InfoCardItem direction="column" pb={0}>
                <AddressDetailsField {...props} />
              </InfoCardItem>
              <Button
                component={Link}
                to={`/Customer/${props.record.id}/show/address/${
                  props.record.addressesIds.length > 0 ? props.record.addressesIds[0] : 0
                }`}
                fullWidth
                color="primary"
                variant="text"
              >
                {translate(
                  props.record.addressesIds.length > 0
                    ? 'resources.Customer.cta.editAddress'
                    : 'resources.Customer.cta.createAddress'
                )}
              </Button>
            </InfoCard>
            <Box mb={2} />
            <InfoCard>
              <InfoCardItem direction="row" alignItems="center">
                <InfoCardAvatar>
                  <FaListOl size={20} />
                </InfoCardAvatar>
                <Box
                  display="flex"
                  justifySelf="flex-start"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <InfoCardTypography variant="label">{'Quotes: '}</InfoCardTypography>
                  <Box mr={1} />
                  <InfoCardTypography variant="value">
                    <ClientTotalQuotesField customerId={props.record.id} />
                  </InfoCardTypography>
                </Box>
              </InfoCardItem>
              <InfoCardItem direction="row" alignItems="center">
                <InfoCardAvatar>
                  <GoTasklist size={20} />
                </InfoCardAvatar>
                <Box
                  display="flex"
                  justifySelf="flex-start"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <InfoCardTypography variant="label">{'Contracts (confirmed): '}</InfoCardTypography>
                  <Box mr={1} />
                  <InfoCardTypography variant="value">
                    <ClientTotalContracts
                      customerId={props.record.id}
                      statuses={[ORDER_STATUS.confirmed, ORDER_STATUS.confirmedAndPaid, ORDER_STATUS.sent]}
                    />
                  </InfoCardTypography>
                </Box>
              </InfoCardItem>
              <InfoCardItem direction="row" alignItems="center">
                <InfoCardAvatar>
                  <FaBan size={20} />
                </InfoCardAvatar>
                <Box
                  display="flex"
                  justifySelf="flex-start"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <InfoCardTypography variant="label">{'Contracts (canceled): '}</InfoCardTypography>
                  <Box mr={1} />
                  <InfoCardTypography variant="value">
                    <ClientTotalContracts
                      customerId={props.record.id}
                      statuses={[
                        ORDER_STATUS.canceled,
                        ORDER_STATUS.canceledWithPenalty,
                        ORDER_STATUS.canceledWithPenaltyAndPaid,
                      ]}
                    />
                  </InfoCardTypography>
                </Box>
              </InfoCardItem>
              {!loading && permissions && canSeeClientStats(permissions) && (
                <InfoCardItem direction="row" alignItems="center">
                  <InfoCardAvatar>
                    <GiReceiveMoney size={20} />
                  </InfoCardAvatar>
                  <Box
                    display="flex"
                    justifySelf="flex-start"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <InfoCardTypography variant="label">{'Profit:'}</InfoCardTypography>
                    <Box mr={1} />
                    <InfoCardTypography variant="value">
                      <ClientTotalPrices
                        customerId={props.record.id}
                        fields={['commissions', 'passengerTax']}
                        // statuses={[ORDER_STATUS.confirmed, ORDER_STATUS.confirmedAndPaid]}
                      />
                    </InfoCardTypography>
                  </Box>
                </InfoCardItem>
              )}
              {!loading && permissions && canSeeClientStats(permissions) && (
                <InfoCardItem direction="row" alignItems="center">
                  <InfoCardAvatar>
                    <GiMoneyStack size={20} />
                  </InfoCardAvatar>
                  <Box
                    display="flex"
                    justifySelf="flex-start"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <InfoCardTypography variant="label">{'Income:'}</InfoCardTypography>
                    <Box mr={1} />
                    <InfoCardTypography variant="value">
                      <ClientTotalPrices
                        customerId={props.record.id}
                        fields={['purchasePrice', 'commissions', 'passengerTax']}
                        // statuses={[ORDER_STATUS.confirmed, ORDER_STATUS.confirmedAndPaid]}
                      />
                    </InfoCardTypography>
                  </Box>
                </InfoCardItem>
              )}
            </InfoCard>
          </InfoCardWrapper>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="stretch" flexGrow={1} flexShrink={1} minWidth={0}>
          <Typography variant="h1" gutterBottom>
            {translate('resources.Person.name', { smart_count: 2 })}
          </Typography>
          <PeopleTable {...props} />

          <CustomerActivities {...props} />

          {/* <CustomerMailList {...props} /> */}
        </Box>
      </Box>
      {/* <Switch> */}
      <Route exact path="/Customer/:id/show/Person/:personId">
        {({ match }: any): React.ReactNode => {
          const isMatch = match && match.params
          const isCreate = match && match.params && match.params.personId === 'create'
          // console.log('MATCHME! ', match)
          return (
            <Drawer open={isMatch} onClose={handleDrawerClose}>
              {isMatch ? (
                <>
                  <DrawerHeader
                    buttonOnClick={handleDrawerClose}
                    title={`${
                      isCreate ? translate('ra.action.create') : translate('ra.action.edit')
                    } ${translate('resources.Person.name', { smart_count: 1 })}`}
                  />
                  <InDrawerPersonForm
                    id={isCreate ? undefined : match?.params.personId}
                    customerId={match?.params.id}
                  />
                </>
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          )
        }}
      </Route>
      <Route path="/Customer/:id/show/address/:addrId">
        {({ match }: any): React.ReactNode => {
          const isMatch = match && match.params && match.params.id !== 'create'
          // console.log('MATCHME! ', match, isMatch)
          return (
            <Drawer open={isMatch} onClose={handleDrawerClose}>
              {isMatch ? (
                <>
                  <DrawerHeader
                    buttonOnClick={handleDrawerClose}
                    title={translate(
                      props.record.addressesIds.length > 0
                        ? 'resources.Customer.cta.editAddress'
                        : 'resources.Customer.cta.createAddress'
                    )}
                  />
                  <InDrawerAddressEdit
                    id={props.record.addressesIds.length > 0 ? match?.params.addrId : 0}
                    // id={match && match.params && match.params.addrId ? match.params.addrId : 0}
                    customerId={match?.params.id}
                  />
                </>
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          )
        }}
      </Route>
      {/* </Switch> */}
    </>
  )
}

const PeopleTable: FC<any> = ({ record }) => {
  const classes = useStyles()
  // console.log('table record:: ', record)

  return (
    <Box className={classes.peopleTable}>
      <ReferenceManyField
        pagination={<Pagination />}
        record={record}
        reference="Person"
        target="referencedCustomerId"
        basePath="/Person"
      >
        <Datagrid emptyComponent={<Empty buttonComponent={<></>} />}>
          <TextField source="id" label="ID" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="position" />
          <TextField source="email" />
          <TextField source="phone" />
          <GenericMoreMenu basePath={`/Customer/${record.id}/show/Person`} />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  )
}

const ClientShow: FC<any> = (props) => {
  const showProps = useShowController(props)
  const translate = useTranslate()
  const history = useHistory()
  const classes = useStyles()

  const handleDrawerClose = useCallback(() => history.goBack(), [])
  const handleDrawerOpen = useCallback(
    () => (showProps && showProps.record ? history.push(`/Customer/${showProps.record.id}/show/edit`) : undefined),
    [showProps]
  )

  return !showProps.loading && showProps.record ? (
    <>
      <ShowView
        {...showProps}
        {...props}
        // title={showProps.record ? showProps.record.name : undefined}
        title={`${showProps.record.customerStatus !== 'NOT_DEFINED' ? showProps.record.customerStatus : ''} #${
          showProps.record.id
        }`}
        hideEdit
        additionalActions={
          <Box component="span" mr={4}>
            <CreateButton
              basePath={`/Customer/${showProps.record.id}/show/Person`}
              label="resources.Customer.titles.addPerson"
              queryParams={showProps.record ? { organizationId: showProps.record.id } : undefined}
            />
            <Box component="span" mr={4} />
            <BaseButton onClick={handleDrawerOpen} variant="outlined">
              Edit client
            </BaseButton>
          </Box>
        }
      >
        <ClientShowComp {...props} />
      </ShowView>
      <Route path="/Customer/:id/show/edit">
        {({ match }: any): React.ReactNode => {
          const isMatch = match && match.params && match.params.id !== 'create'

          return (
            <Drawer open={isMatch} onClose={handleDrawerClose}>
              {isMatch ? (
                <>
                  <DrawerHeader
                    buttonOnClick={handleDrawerClose}
                    title={`${translate('ra.action.edit')} #${match?.params?.id}`}
                  />
                  <ClientEdit
                    id={isMatch && match && match.params ? match.params.id : undefined}
                    onCancel={handleDrawerClose}
                    {...props}
                  />
                </>
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          )
        }}
      </Route>
    </>
  ) : null
}

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    backgroundColor: '#eeeeee',
    color: 'rgba(51,51,51,0.5)',
    width: '60px',
    height: '60px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    marginRight: theme.spacing(3),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardValue: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  bold: {
    fontWeight: 'bold',
  },
  activeButton: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    backgroundColor: '#56c85f',
  },
  activeLabel: {
    color: 'white',
  },
  card: {
    position: 'sticky',
    top: theme.spacing(3),
  },
  statCard: {
    flex: 1,
  },
  peopleTable: {
    // backgroundColor: theme.palette.background.paper,
    // overflow: 'scroll',
    alignSelf: 'flex-start',
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  brandTitle: {
    color: 'black',
    marginLeft: theme.spacing(3),
  },
  field: {
    marginTop: theme.spacing(3),
  },
  drawerContent: {
    width: '26vw',
    minWidth: 260,
    // eslint-disable-next-line quotes
    content: "''",
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    maxWidth: 600,
    borderRadius: 4,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[4],
  },
}))

export default ClientShow
