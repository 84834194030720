import React, { FC, useCallback, useEffect } from 'react'
import { ReferenceInput } from 'react-admin'
import { useDataProvider } from 'ra-core'
import { useForm } from 'react-final-form'
import FormAutoCompleteInput from './FormAutoCompleteInput'
import FormSection from './forms/FormSection'
import FormTextInput from './FormTextInput'
// import { AutocompleteInput } from 'react-admin'
import { TextInput } from 'react-admin'
import AutocompleteInputInDrawer from './AutocompleteInDrawer'

const sort = { field: 'name', order: 'ASC' }
const filterToQuery = (name: string): any => ({ name })

type Props = {
  referenceField?: string
  inDrawer?: boolean
  [x: string]: any
}

/**
 * @description component used inside Organization / Customer CRUDs,
 * responsible to handle existing address and changing the uppper form
 */
const AddressFormSection: FC<Props> = ({ record, referenceField = 'addressId', inDrawer = false, ...props }) => {
  const dataProvider = useDataProvider()
  const form = useForm()
  // console.log('form sect props: ', record, referenceField, props)
  useEffect(() => {
    if (record && record[referenceField]) getAddress(record[referenceField])
  }, [])

  const getAddress = useCallback(async (id: number): Promise<void> => {
    try {
      const addressResult = await dataProvider.getOne('Address', { id })
      if (addressResult && !addressResult.data) throw new Error('Address data is empty!')
      setAddress(addressResult.data)
    } catch (error) {
      console.error('address error: ', error)
    }
  }, [])

  const setAddress = useCallback((address: Record<string, string | number>): void => {
    form.change('city', address.city)
    form.change('countryId', address.countryId)
    form.change('street', address.street)
    form.change('postCode', address.postCode)
  }, [])

  return inDrawer ? (
    <>
      <ReferenceInput reference="Country" source="countryId" sort={sort} filterToQuery={filterToQuery}>
        <AutocompleteInputInDrawer optionText="name" fullWidth variant="outlined" />
      </ReferenceInput>
      <TextInput source="city" variant="outlined" />
      <TextInput source="street" variant="outlined" />
      <TextInput source="postCode" variant="outlined" />
      {/* <TextInput source="phone" variant="outlined" /> */}
    </>
  ) : (
    <FormSection title={props.sectionTitle} {...props} record={record}>
      <ReferenceInput reference="Country" source="countryId" sort={sort} filterToQuery={filterToQuery}>
        <FormAutoCompleteInput optionText="name" />
      </ReferenceInput>
      <FormTextInput source="city" />
      <FormTextInput source="street" />
      <FormTextInput source="postCode" />
      {/* <FormTextInput source="phone" /> */}
    </FormSection>
  )
}

export default AddressFormSection
