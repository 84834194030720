import React, { FC } from 'react'
import { TextInput, ReferenceInput, DateInput } from 'react-admin'
import Filter from '../../components/list/filter/Filter'
import SearchAutoCompleteInput from '../../components/SearchAutoCompleteInput'

type Props = {
  [x: string]: any
}

const autocompleteQuery = (name: string): Record<string, any> => ({ name })
const sort = { field: 'name', order: 'ASC' }

const PassengerFilters: FC<Props> = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="nationality" reference="Country" alwaysOn filterToQuery={autocompleteQuery} sort={sort}>
        <SearchAutoCompleteInput />
      </ReferenceInput>
      <TextInput source="lastName" />
      <TextInput source="firstName" />
      <DateInput source="birthDate" />
      <TextInput source="documentNumber" />
    </Filter>
  )
}

export default PassengerFilters
