import React, { cloneElement, FC, isValidElement, useCallback } from 'react'
import { Datagrid, ListToolbar, BulkActionsToolbar, Pagination, TextField } from 'react-admin'
import { useListContext, ListBase } from 'ra-core'
import Card from '@material-ui/core/Card'
import { Route, useLocation, useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import PlannedEventEdit from './EventEdit'
import PlannedEventCreate from './EventCreate'
import { makeStyles } from '@material-ui/core/styles'
import { CalendarPlanning } from '../../components/CalendarPlanning/CalendarPlanning'
import DrawerHeader from '../../components/DrawerHeader'
import InDrawerOrderRouteForm from '../Order/InDrawerOrderRouteForm'
import Drawer from '../../components/Drawer'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '30vw',
    minWidth: 300,
  },
}))

const PlannedEventList: FC = (props) => {
  const classes = useStyles(props)
  const history = useHistory()
  const { search } = useLocation()
  const handleClose = useCallback(() => {
    history.push({
      pathname: '/Event',
      search: search,
    })
  }, [search])

  return (
    <>
      <CalendarPlanning {...props} />
      <Route exact path="/Event/OrderRoute/:orderId/:orderRouteId">
        {({ match, location: { search } }): React.ReactNode => {
          const isMatch = match && match.params
          const orderRouteId = match?.params.orderRouteId
          const orderId = match?.params.orderId

          return (
            <Drawer open={isMatch} onClose={handleClose}>
              {orderRouteId ? (
                <>
                  <DrawerHeader buttonOnClick={handleClose} title="Edit route" />
                  <InDrawerOrderRouteForm onRedirect={handleClose} id={orderRouteId} orderId={orderId} />
                </>
              ) : null}
            </Drawer>
          )
        }}
      </Route>
      <Route exact path="/Event/:id">
        {({ match }): React.ReactNode => {
          const isEdit = match && match.params && match.params.id !== 'create'
          const isCreate = match && match.params && match.params.id === 'create'

          return (
            <Drawer open={isEdit || isCreate} anchor="right" onClose={handleClose} classes={{ paper: classes.drawer }}>
              <DrawerHeader buttonOnClick={handleClose} title={`${isEdit ? 'Edit' : 'Create'} event`} />
              {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
              {isEdit && match?.params?.id && (
                <PlannedEventEdit id={match.params.id} onCancel={handleClose} {...props} />
              )}
              {isCreate && <PlannedEventCreate onCancel={handleClose} {...props} />}
            </Drawer>
          )
        }}
      </Route>
    </>
  )
}

export default PlannedEventList
