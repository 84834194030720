import React, { FC, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextInput, SaveButton, DeleteButton } from 'react-admin'
import { useNotify, useRefresh, FormWithRedirect, useGetOne } from 'ra-core'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { MUTATION_UPDATE_PLANNED_EVENT } from '../../queries'
import { DateTimeInput } from 'react-admin'
import { Box } from '@chakra-ui/react'
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  ReferenceArrayInput,
  ReferenceInput,
} from 'ra-ui-materialui'
import { EventPersonInput } from './EventPersonInput'

type Props = any

const parseDate = (v: any): any => {
  return new Date(v).toISOString()
}

const useStyles = makeStyles((theme) => ({
  suggestionsContainer: {
    zIndex: 1300,
  },
}))

const EventEdit: FC<Props> = (props) => {
  const classes = useStyles(props)
  const notify = useNotify()
  const history = useHistory()
  const { search } = useLocation()
  const refresh = useRefresh()
  const [updateEvent, { loading: submitting }] = useMutation(MUTATION_UPDATE_PLANNED_EVENT)
  const { loading, data } = useGetOne('Event', props.id, { enabled: true })

  const onSubmit = useCallback(async (values) => {
    const { errors } = await updateEvent({
      variables: {
        id: props.id,
        data: {
          title: values.title,
          description: values.description,
          startDate: values.startDate,
          endDate: values.endDate,
          allDay: values.allDay,
          teamMemberIds: values.teamMemberIds,
          customerId: values.customerId,
          personId: values.personId,
          quoteId: values.quoteId,
          orderId: values.orderId,
        },
      },
    })

    if (errors) {
      notify(errors[0].message, 'warning')
    } else {
      notify('Event Updated successfully')
      history.replace({
        pathname: '/Event',
        search: search,
      })
      refresh()
    }
  }, [])

  if (loading) {
    return <div></div>
  }

  return (
    <FormWithRedirect
      {...props}
      save={onSubmit}
      record={data}
      render={(formProps: any): any => (
        <form>
          <Box d="flex" flexDir="column">
            <TextInput source="title" fullWidth {...props} variant="outlined" />
            <DateTimeInput variant="outlined" parse={parseDate} source="startDate" resource="Event" fullWidth />
            <DateTimeInput variant="outlined" parse={parseDate} source="endDate" resource="Event" fullWidth />
            <TextInput source="description" fullWidth {...props} variant="outlined" />
            <BooleanInput source="allDay" />
            <ReferenceArrayInput
              label="Team members"
              source="teamMemberIds"
              reference="TeamMember"
              variant="outlined"
              fullWidth
              {...props}
            >
              <AutocompleteArrayInput
                options={{
                  suggestionsContainerProps: {
                    className: classes.suggestionsContainer,
                  },
                }}
                optionText="fullName"
                optionValue="id"
              />
            </ReferenceArrayInput>
            <ReferenceInput source="customerId" reference="Customer" variant="outlined" fullWidth allowEmpty>
              <AutocompleteInput
                options={{
                  suggestionsContainerProps: {
                    className: classes.suggestionsContainer,
                  },
                }}
                optionText="fullName"
                optionValue="id"
              />
            </ReferenceInput>
            <EventPersonInput />
            <ReferenceInput source="quoteId" reference="Quote" variant="outlined" fullWidth allowEmpty>
              <AutocompleteInput
                options={{
                  suggestionsContainerProps: {
                    className: classes.suggestionsContainer,
                  },
                }}
                optionText={(choice: any): string =>
                  choice?.reference ? `#${choice?.reference?.substring(choice?.reference?.length - 5)}` : ''
                }
                optionValue="id"
              />
            </ReferenceInput>
            <ReferenceInput source="orderId" reference="Order" variant="outlined" fullWidth allowEmpty>
              <AutocompleteInput
                options={{
                  suggestionsContainerProps: {
                    className: classes.suggestionsContainer,
                  },
                }}
                optionText={(choice: any): string =>
                  choice?.reference ? `#${choice?.reference?.substring(choice?.reference?.length - 5)}` : ''
                }
                optionValue="id"
              />
            </ReferenceInput>
          </Box>
          <SaveButton
            size="large"
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            saving={submitting}
            disabled={formProps.pristine || submitting}
            fullWidth
          />
          <Box mt={2}>
            <DeleteButton
              size="large"
              fullWidth
              basePath={props.basePath}
              record={data}
              resource="Event"
              undoable={false}
              redirect={`/Event${search}`}
            />
          </Box>
        </form>
      )}
    />
  )
}

export default EventEdit
