import React from 'react'
import { Route } from 'react-router-dom'
import CustomCalendar from './components/Calendar'
import Dashboard from './components/Dashboard'
// import PageNotFound from './components/PageNotFound'
import Settings from './components/Settings'
import AuthCallback from './routes/AuthCallback'
import GoogleLogin from './routes/GoogleLogin'
import GmailCallback from './routes/GmailCallback'
import { LogsConsole } from './routes/LogsConsole/LogsConsole'

export default [
  <Route exact path="/callback" component={AuthCallback} />,
  <Route exact path="/dashboard" component={Dashboard} />,
  <Route exact path="/settings" component={Settings} />,
  <Route exact path="/gmailcb" component={GmailCallback} />,
  <Route exact path="/gmail" component={GoogleLogin} />,
  <Route exact path="/logs-console" component={LogsConsole} />,
  <Route exact path="/calendar" component={CustomCalendar} />,
]
