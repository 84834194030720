import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import { useFormState } from 'react-final-form'
import FullQuoteField from '../../components/field/FullRouteField'

type Props = {
  //
}

const RoutesSummary: FC<Props> = () => {
  const { values } = useFormState()

  if (values && values.routes && Array.isArray(values.routes)) {
    return (
      <Box display="flex" flexDirection="column">
        {values.routes.map((route: any, index: number) => {
          return <FullQuoteField {...route} key={`route-${index}`} index={++index} />
        })}
      </Box>
    )
  }
  return null
}

export default RoutesSummary
