import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  Badge,
  Box,
  BoxProps,
  Flex,
  forwardRef,
  Grid,
  Icon,
  Link,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { format, Locale } from 'date-fns'
import { useDataProvider, useTranslate } from 'ra-core'
import { HiOfficeBuilding } from 'react-icons/hi'
import { FaUserTie } from 'react-icons/fa'
import { MdAttachMoney } from 'react-icons/md'
import { IoIosPaper } from 'react-icons/io'
import FirstOrderRouteField from '../field/FirstOrderRouteField'
import { QUOTE_STATUS_OPTIONS } from '../QuoteStatusDropDown'
import { TeamMembersFilter } from '../Activities/TeamMembersFilter'

type EventContentProps = {
  timeText?: string
  title?: string
  loading?: boolean
  customer?: any
  quote?: any
  order?: any
}
const EventContent: FC<EventContentProps> = forwardRef<EventContentProps & BoxProps, 'div'>(
  ({ timeText, title, loading, customer, quote, order, ...rest }, ref) => {
    return (
      <Box d="flex" w="100%" h="100%" flexWrap="wrap" ref={ref} {...rest}>
        <Box px={1} w="100%" h="100%" color="blackAlpha.700">
          <Text noOfLines={1} fontSize="xs" textTransform="uppercase" fontWeight="bold" color="white">
            {title}
          </Text>
          <Text noOfLines={1} fontSize="x-small" color="white">
            {timeText}
          </Text>
          <Flex alignItems="center" color="white">
            {loading ? (
              <Skeleton w="10px" h="10px" mt="1px" mr="0.07rem" />
            ) : customer ? (
              <>
                {customer?.isCompany ? (
                  <Icon as={HiOfficeBuilding} mr="0.07rem" w="10px" h="10px" />
                ) : (
                  <Icon as={FaUserTie} mr="0.07rem" w="10px" h="10px" />
                )}
              </>
            ) : null}
            {loading ? (
              <Skeleton ml="3px" w="55px" mt="1px" h="10px" />
            ) : customer ? (
              <Text noOfLines={1} fontSize="x-small">
                {customer?.clientName}
              </Text>
            ) : null}
          </Flex>
          <Flex alignItems="center" color="white">
            {loading ? (
              <Skeleton w="10px" h="10px" mt="1px" mr="0.07rem" />
            ) : quote?.reference ? (
              <Icon as={MdAttachMoney} mr="0.07rem" w="10px" h="10px" />
            ) : null}
            {loading ? (
              <Skeleton ml="3px" w="55px" mt="1px" h="10px" />
            ) : quote?.reference ? (
              <Text noOfLines={1} fontSize="x-small">
                {quote.reference.substring(quote.reference.length - 5)}
              </Text>
            ) : null}
          </Flex>
          <Flex alignItems="center" color="white">
            {loading ? (
              <Skeleton w="10px" h="10px" mt="1px" mr="0.07rem" />
            ) : order?.reference ? (
              <Icon as={IoIosPaper} mr="0.07rem" w="10px" h="10px" />
            ) : null}
            {loading ? (
              <Skeleton ml="3px" w="55px" mt="1px" h="10px" />
            ) : order?.reference ? (
              <Text noOfLines={1} fontSize="x-small">
                {order.reference.substring(order.reference.length - 5)}
              </Text>
            ) : null}
          </Flex>
        </Box>
      </Box>
    )
  }
)

type Props = {
  calendarEvent: any
  timeText?: string
  locale: Locale
}

export const Event: FC<Props> = ({ calendarEvent, timeText, locale, ...props }) => {
  const [customer, setCustomer] = useState<any>(undefined)
  const [person, setPerson] = useState<any>(undefined)
  const [quote, setQuote] = useState<any>(undefined)
  const [order, setOrder] = useState<any>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const provider = useDataProvider()
  const translate = useTranslate()

  const subtitleDay = useMemo(
    () => (!calendarEvent ? '' : format(new Date(calendarEvent?.start), 'EEEE L LLLL ', { locale })),
    [locale, calendarEvent]
  )

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true)

        const [customer, person, quote, order] = await Promise.all([
          (async (): Promise<any> => {
            if (!calendarEvent?.extendedProps?.customerId) {
              return null
            }

            const { data: customer } = await provider.getOne('Customer', {
              id: calendarEvent?.extendedProps?.customerId,
            })

            return customer
          })(),
          (async (): Promise<any> => {
            if (!calendarEvent?.extendedProps?.personId) {
              return null
            }

            const { data: person } = await provider.getOne('Person', {
              id: calendarEvent?.extendedProps?.personId,
            })

            return person
          })(),
          (async (): Promise<any> => {
            if (!calendarEvent?.extendedProps?.quoteId) {
              return null
            }

            const { data: quote } = await provider.getOne('Quote', {
              id: calendarEvent?.extendedProps?.quoteId,
            })

            return quote
          })(),
          (async (): Promise<any> => {
            if (!calendarEvent?.extendedProps?.orderId) {
              return null
            }

            const { data: order } = await provider.getOne('Order', {
              id: calendarEvent?.extendedProps?.orderId,
            })

            return order
          })(),
        ])

        if (customer) {
          setCustomer({
            ...customer,
            clientName: customer?.isCompany ? customer?.companyName : `${customer.firstName} ${customer.lastName}`,
          })
        }

        if (person) {
          setPerson({
            ...person,
          })
        }

        if (quote) {
          setQuote({
            ...quote,
            statusColor: QUOTE_STATUS_OPTIONS.find(
              (status: any) => quote?.status?.toLowerCase() === status.name.toLowerCase()
            )?.color,
          })
        }

        if (order) {
          setOrder({
            ...order,
            statusColor: QUOTE_STATUS_OPTIONS.find(
              (status: any) => quote?.status?.toLowerCase() === status.name.toLowerCase()
            )?.color,
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <EventContent
          timeText={timeText}
          title={calendarEvent?.title}
          loading={loading}
          customer={customer}
          quote={quote}
          order={order}
        />
      </PopoverTrigger>

      <Portal>
        <PopoverContent>
          <PopoverCloseButton />
          <PopoverBody d="flex" flexDir="column">
            <Flex
              flexDir="column"
              alignItems="flex-start"
              borderBottom="1px solid"
              borderBottomColor="blackAlpha.200"
              pb={2}
              mb={2}
            >
              <Text as="span" fontWeight="bold" fontSize="sm" noOfLines={1} textTransform="uppercase">
                {calendarEvent?.title}
              </Text>
              <Text as="span" fontSize="xs" noOfLines={1}>
                {subtitleDay} • {timeText}
              </Text>
            </Flex>

            {calendarEvent?.extendedProps?.teamMemberIds && (
              <Flex
                flexDir="column"
                alignItems="flex-start"
                pb={2}
                mb={customer ? 2 : 0}
                borderBottom={customer ? '1px solid' : undefined}
                borderBottomColor="blackAlpha.200"
              >
                <Text mb={1} fontSize="x-small" color="blackAlpha.700" textTransform="capitalize">
                  Team members
                </Text>
                <TeamMembersFilter
                  size="xs"
                  groupedAvatarsSpacing={-2}
                  expandedAvatarsSpacing={-2}
                  additionalFilters={{ ids: calendarEvent?.extendedProps?.teamMemberIds }}
                />
              </Flex>
            )}

            {customer && (
              <Flex
                flex="1"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={quote ? '1px solid' : undefined}
                borderBottomColor="blackAlpha.200"
                pb={2}
                mb={quote ? 2 : 0}
              >
                <Flex flexDir="column">
                  <Text fontSize="x-small" color="blackAlpha.700" textTransform="capitalize">
                    {customer?.customerStatus?.toLowerCase() || 'Client'}
                  </Text>
                  {/* <Box>
                  <Badge fontSize="x-small">{customer?.customerStatus}</Badge>
                </Box> */}
                  <Link
                    as={RouterLink}
                    to={`/Customer/${customer?.id}/show`}
                    fontSize="sm"
                    noOfLines={1}
                    fontWeight="bold"
                    color="blue.700"
                  >
                    {customer?.clientName}
                  </Link>
                </Flex>

                {person && (
                  <Flex flexDir="column">
                    <Text textAlign="right" fontSize="x-small" color="blackAlpha.700">
                      Person
                    </Text>
                    <Text textAlign="right" fontSize="sm" fontWeight="bold">
                      {person?.fullName || 'N.D.'}
                    </Text>
                  </Flex>
                )}
              </Flex>
            )}
            {quote && (
              <Grid
                flex="1"
                templateColumns="1fr 1fr 1fr"
                columnGap={4}
                borderBottom={order ? '1px solid' : undefined}
                borderBottomColor="blackAlpha.200"
                pb={2}
                mb={order ? 2 : 0}
              >
                <Flex flexDir="column" justifyContent="space-between">
                  <Text fontSize="x-small" color="blackAlpha.700" textTransform="capitalize">
                    Quote
                  </Text>
                  <Link
                    as={RouterLink}
                    to={`/Quote/${quote?.id}/show`}
                    fontSize="sm"
                    noOfLines={1}
                    fontWeight="bold"
                    color="blue.700"
                  >
                    #{quote?.reference.substring(quote?.reference.length - 5)}
                  </Link>
                </Flex>

                <Flex flexDir="column">
                  <Text fontSize="x-small" color="blackAlpha.700">
                    Routes
                  </Text>
                  <Box
                    sx={{
                      '.MuiChip-labelSmall': {
                        fontSize: 'x-small',
                      },
                      '.MuiTypography-body2': {
                        fontSize: 'x-small',
                      },
                      '.MuiChip-sizeSmall': {
                        height: '18px',
                      },
                    }}
                  >
                    <FirstOrderRouteField record={quote} />
                  </Box>
                </Flex>
                <Flex flexDir="column" justifyContent="space-between">
                  <Text fontSize="x-small" color="blackAlpha.700" textTransform="capitalize">
                    Status
                  </Text>
                  <Text d="flex" alignItems="center" fontSize="sm">
                    <Box
                      as="span"
                      d="inline-block"
                      borderRadius="full"
                      w="8px"
                      h="8px"
                      bgColor={quote?.statusColor}
                      mr={2}
                    />
                    {translate(`statuses.${order?.status}`)}
                  </Text>
                </Flex>
              </Grid>
            )}

            {order && (
              <Grid flex="1" templateColumns="1fr 1fr 1fr" columnGap={4} pb={2}>
                <Flex flexDir="column" justifyContent="space-between">
                  <Text fontSize="x-small" color="blackAlpha.700" textTransform="capitalize">
                    Order
                  </Text>
                  <Link
                    as={RouterLink}
                    to={`/Order/${order?.id}/show`}
                    fontSize="sm"
                    noOfLines={1}
                    fontWeight="bold"
                    color="blue.700"
                  >
                    #{order?.reference.substring(order?.reference.length - 5)}
                  </Link>
                </Flex>

                <Flex flexDir="column">
                  <Text fontSize="x-small" color="blackAlpha.700">
                    Routes
                  </Text>
                  <Box
                    sx={{
                      '.MuiChip-labelSmall': {
                        fontSize: 'x-small',
                      },
                      '.MuiTypography-body2': {
                        fontSize: 'x-small',
                      },
                      '.MuiChip-sizeSmall': {
                        height: '18px',
                      },
                    }}
                  >
                    <FirstOrderRouteField record={order} />
                  </Box>
                </Flex>
                <Flex flexDir="column" justifyContent="space-between">
                  <Text fontSize="x-small" color="blackAlpha.700" textTransform="capitalize">
                    Status
                  </Text>
                  <Text d="flex" alignItems="center" fontSize="sm">
                    <Box
                      as="span"
                      d="inline-block"
                      borderRadius="full"
                      w="8px"
                      h="8px"
                      bgColor={order?.statusColor}
                      mr={2}
                    />
                    {translate(`statuses.${order?.status}`)}
                  </Text>
                </Flex>
              </Grid>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
