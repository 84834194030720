import React, { FC } from 'react'
import { useTranslate } from 'ra-core'
// internal components
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/details/Edit'
import FormTextInput from '../../components/FormTextInput'

type Props = {}

const TeamMemberEdit: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <Edit {...props} component="div">
      <SectionedForm variant="outlined" redirect="show" title={translate('resources.TeamMember.titles.edit')}>
        <FormSection title="">
          <FormTextInput source="firstName" />
          <FormTextInput source="lastName" />
          <FormTextInput source="email" />
          {/* <FormTextInput {...props} source="password" /> */}
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default TeamMemberEdit
