import React, { FC, useCallback } from 'react'
import { useNotify, useRedirect, useTranslate } from 'ra-core'
import { ArrayInput, FormDataConsumer, ReferenceInput, SimpleFormIterator, TextInput } from 'react-admin'
import { parse } from 'query-string'
import { makeStyles } from '@material-ui/core/styles'
// internal components
import Create from '../../components/details/Create'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import FormSelectInput from '../../components/input/FormSelectInput'
import BaseButton from './../../components/button/BaseButton'
// icons
import ContentAdd from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

type Props = {
  [x: string]: any
}

// TODO: handle redirect on this page without organizationId
const BankAccountCreate: FC<Props> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const redirect = useRedirect()
  const notify = useNotify()

  const transform = useCallback(
    (data: any): any => {
      data.organizationId = parse(props.location.search).organizationId
      return data
    },
    [props]
  )

  const onSuccess = useCallback(() => {
    redirect(`/Organization/${parse(props.location.search).organizationId}/show`)
    notify('ra.notification.created')
  }, [props])

  return (
    <Create {...props} component="div" transform={transform} onSuccess={onSuccess}>
      <SectionedForm variant="outlined" redirect="list" title={translate('resources.BankAccount.titles.create')}>
        <FormSection title={translate('resources.BankAccount.titles.details')}>
          <FormTextInput source="name" required />
          <FormTextInput source="bankName" />
          <ReferenceInput source="currencyId" reference="Currency">
            <FormSelectInput optionText="name" fullWidth />
          </ReferenceInput>
          <FormTextInput source="address" />
        </FormSection>
        <FormSection title={translate('resources.BankAccount.titles.details')}>
          <ArrayInput source="details" label={false}>
            <SimpleFormIterator
              classes={{ line: classes.line }}
              addButton={
                <BaseButton startIcon={<ContentAdd />} color="primary" variant="contained">
                  {translate('ra.action.add')}
                </BaseButton>
              }
              removeButton={
                <BaseButton startIcon={<DeleteOutlineIcon />} color="primary" variant="contained">
                  {translate('ra.action.remove')}
                </BaseButton>
              }
            >
              <FormDataConsumer>
                {({ getSource, scopedFormData }: any): React.ReactElement => (
                  <>
                    <TextInput
                      variant="outlined"
                      source={getSource('key')}
                      record={scopedFormData}
                      helperText={false}
                      className={classes.input}
                      label="resources.BankAccount.fields.customField"
                    />
                    <TextInput
                      variant="outlined"
                      source={getSource('value')}
                      record={scopedFormData}
                      helperText={false}
                      label="resources.BankAccount.fields.customValue"
                    />
                  </>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

const useStyles = makeStyles((theme) => ({
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  line: {
    display: 'flex',
    alignItems: 'stretch',
    borderBottom: 0,
  },
  input: {
    marginRight: '3rem',
  },
}))

export default BankAccountCreate
