/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, Children, isValidElement, useMemo, useCallback, useState, useEffect } from 'react'
import { FormWithRedirect } from 'react-admin'
import AppBarFormToolbar from '../AppBarFormToolbar'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import WizardFormLayout from './WizardFormLayout'
import { useFormState } from 'react-final-form'
import Loading from '../../Loading'

export type WizardSectionedProps = {
  children?: React.ReactNode
  initialValues?: any
  resource?: string
  basePath?: string
  record?: any
  redirect?: string | boolean | (() => string)
  save?: any
  saving?: any | boolean
  submitOnEnter?: boolean
  undoable?: boolean
  validate?: () => void
  version?: string
  variant?: 'outlined' | 'standard' | 'filled'
  handleSubmitWithRedirect?: any
  handleSubmit?: any
  invalid?: boolean
  pristine?: boolean
  toolbar?: React.ReactElement
  defaultTitle?: string
  title?: string
  formRightControls?: React.ReactNode
  titleBar?: React.ReactNode
}

const WizardSectionedForm: FC<WizardSectionedProps> = ({
  variant = 'outlined',
  toolbar = <AppBarFormToolbar />,
  ...props
}) => {
  const [initialized, setInitialized] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({})

  useEffect(() => {
    if (!props.record || !props.record.id) {
      let initialFormValues = {}
      try {
        initialFormValues = JSON.parse(localStorage.getItem(`_wizard_${props.resource}_create`) || '{}')
      } catch (e) {}
      setInitialValues(initialFormValues)
      setInitialized(true)
    } else {
      setInitialValues(props.record)
      setInitialized(true)
    }
  }, [])

  if (!initialized) {
    return <Loading />
  }

  return (
    <FormWithRedirect
      initialValues={initialValues}
      {...props}
      variant={variant}
      toolbar={toolbar}
      render={(formProps: any): React.ReactNode => <WizardSectionedFormView {...formProps} />}
    />
  )
}

const WizardSectionedFormView: FC<WizardSectionedProps> = ({
  children,
  resource,
  record,
  basePath,
  variant,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  pristine,
  saving,
  submitOnEnter,
  undoable,
  toolbar,
  redirect: defaultRedirect,
  defaultTitle,
  title,
  formRightControls,
  titleBar,
}) => {
  const wizardBasePath = useMemo(() => `${basePath}/${record.id ? record.id : 'create'}`, [])
  const history = useHistory()
  const match = useRouteMatch(`${basePath}/:detail/:step`)
  const childrenCount = useMemo(() => Children.count(children), [children])
  const currentStep = useMemo(
    () => (match && match.params && (match?.params as any).step ? (match?.params as any).step : undefined),
    [match]
  )
  const hasNextStep = useMemo(() => (currentStep ? currentStep < childrenCount - 1 : false), [
    currentStep,
    childrenCount,
  ])
  const hasPreviousStep = useMemo(() => (currentStep ? currentStep > 0 : false), [currentStep, childrenCount])

  const goToStep = useCallback((step: number, historyReplace = false) => {
    if (step < 1) {
      console.error(`step ${step} does not exists`)
      return
    }
    if (step > childrenCount) {
      console.error(`step ${step} does not exists`)
      return
    }

    if (historyReplace) {
      history.replace(`${wizardBasePath}/${step}`)
    } else {
      history.push(`${wizardBasePath}/${step}`)
    }
  }, [])

  return (
    <WizardFormLayout
      title={title || defaultTitle}
      wizardBasePath={wizardBasePath}
      handleSubmit={handleSubmit}
      handleSubmitWithRedirect={handleSubmitWithRedirect}
      currentStep={currentStep}
      hasNextStep={hasNextStep}
      hasPreviousStep={hasPreviousStep}
      record={record}
      resource={resource}
      formRightControls={formRightControls}
      childrenCount={childrenCount}
      wizardChildren={children}
      goToStep={goToStep}
      titleBar={titleBar}
      invalid={invalid}
    >
      <Switch>
        {Children.map(children, (wizardStep, index) => {
          if (wizardStep && isValidElement(wizardStep)) {
            const hasNextStep = index < childrenCount - 1
            const hasPreviousStep = index > 0
            return React.createElement(Route, {
              path: `${wizardBasePath}/${index + 1}`,
              children: React.cloneElement(wizardStep, {
                resource,
                record,
                wizardBasePath,
                basePath,
                variant: wizardStep.props.variant || variant,
                currentStep: index + 1,
                hasNextStep,
                hasPreviousStep,
                nextStepTo: hasNextStep ? `${wizardBasePath}/${index + 2}` : undefined,
                previousStepTo: hasPreviousStep ? `${wizardBasePath}/${index}` : undefined,
                handleSubmitWithRedirect,
                invalid,
                pristine,
                saving,
                submitOnEnter,
                undoable,
                toolbar,
                goToStep,
              }),
            })
          }
        })}
        {childrenCount > 0 && <Redirect to={`${wizardBasePath}/1`} />}
      </Switch>
    </WizardFormLayout>
  )
}

export default WizardSectionedForm
