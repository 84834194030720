import React, { FC, useState, useCallback, useEffect } from 'react'
import { useDelete, useNotify, useRefresh } from 'ra-core'
import { useApolloClient } from '@apollo/client'
import { Confirm } from 'react-admin'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import { MUTATION_DELETE_PAX_ROUTE } from '../queries'

type Props = {
  id: string
  firstName: string
  lastName: string
  orderRouteId: string
}

const RoutePassengerChip: FC<Props> = ({ id, firstName, lastName, orderRouteId }) => {
  const apolloClient = useApolloClient()
  const notify = useNotify()
  const refresh = useRefresh()
  // const [deletePax, { loading, error, data }] = useDelete('PassengerRoute', id)
  const [loading, setLoading] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const handleDialogClose = useCallback((): void => setIsDialogOpen(false), [])
  const handleDialogOpen = useCallback((): void => setIsDialogOpen(true), [])

  const deletePax = useCallback(async () => {
    try {
      setLoading(true)
      const result = await apolloClient.mutate({
        mutation: MUTATION_DELETE_PAX_ROUTE,
        variables: { orderRouteId, passengerId: id },
      })
      if (result) {
        setIsDialogOpen(false)
        refresh()
        notify('ra.notify.updated')
      }
    } catch (err) {
      console.error('Error deleting PassengerRoute:: ', err.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <>
      <Box mr={3}>
        <Chip size="small" color="primary" label={`${firstName} ${lastName}`} onDelete={handleDialogOpen} />
      </Box>
      <Confirm
        isOpen={isDialogOpen}
        loading={loading}
        title="Remove passenger"
        content="Are you sure you want to remove the selected passenger from the route?"
        onConfirm={deletePax}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default RoutePassengerChip
