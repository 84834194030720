import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import { TypographyVariant } from '@material-ui/core'

type Props = {
  record?: Record<string, any>
  label?: string
  variant?: TypographyVariant
  className?: any
}

const ClientNameField: FC<Props> = ({ record = {}, variant = 'body2', className }) => {
  return (
    <Typography variant={variant} className={className}>
      {record.isCompany ? record.companyName : `${record.firstName} ${record.lastName}`}
    </Typography>
  )
}

export default ClientNameField
