import React, { FC } from 'react'
import { Dialog, DialogTitle, ListItemIcon, makeStyles, MenuItem } from '@material-ui/core'
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import { useState } from 'react'
import { useCallback } from 'react'
import { ChooseOrderRoute } from './ChooseOrderRoute'
import { ChooseOrderRouteDataToimport, SectionsToImport } from './ChooseOrderRouteDataToimport'
import { useDataProvider, useNotify, useRefresh } from 'ra-core'
import { useApolloClient } from '@apollo/client'
import { CREATE_PASSENGER_ROUTE } from '../../queries'

type Props = {
  orderId: string | number
  orderRouteId: string | number
}

export const CopyOrderRouteModal: FC<Props> = (props) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [currentState, setCurrentState] = useState<'chooseRoute' | 'dataSelector'>('chooseRoute')
  const [selectedOrderRoute, setSelectedOrderRoute] = useState<any | undefined>()
  const [saving, setSaving] = useState<boolean>(false)
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const apolloClient = useApolloClient()
  const refresh = useRefresh()

  const handleSelectedOrderRoute = useCallback((orderRoute: any) => {
    setSelectedOrderRoute(orderRoute)
    setCurrentState('dataSelector')
  }, [])

  const handleMenuItemClick = useCallback<React.MouseEventHandler<HTMLLIElement>>((e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    if (saving) {
      return
    }

    setSelectedOrderRoute(undefined)
    setCurrentState('chooseRoute')
    setIsModalOpen(false)
  }, [])

  const handleSave = useCallback(
    async (sectionsToSave: SectionsToImport) => {
      try {
        if (saving) {
          return
        }

        setSaving(true)

        const newCopiedData: Record<string, any> = {}

        if (sectionsToSave.flightNumber) {
          newCopiedData.flightNumber = selectedOrderRoute.flightNumber
        }

        if (sectionsToSave.crewInfo) {
          newCopiedData.captainName = selectedOrderRoute.captainName
          newCopiedData.captainNumber = selectedOrderRoute.captainNumber
          newCopiedData.firstOfficerName = selectedOrderRoute.firstOfficerName
          newCopiedData.firstOfficerNumber = selectedOrderRoute.firstOfficerNumber
          newCopiedData.flightAttendantName = selectedOrderRoute.flightAttendantName
          newCopiedData.flightAttendantNumber = selectedOrderRoute.flightAttendantNumber
        }

        if (sectionsToSave.handlingInfo) {
          newCopiedData.departureHandlingName = selectedOrderRoute.arrivalHandlingName
          newCopiedData.departureHandlingNumber = selectedOrderRoute.arrivalHandlingNumber
          newCopiedData.arrivalHandlingName = selectedOrderRoute.departureHandlingName
          newCopiedData.arrivalHandlingNumber = selectedOrderRoute.departureHandlingNumber
        }

        if (sectionsToSave.additionalInfo) {
          newCopiedData.notes = selectedOrderRoute.notes
        }

        if (sectionsToSave.passengers) {
          newCopiedData.passengersQuantity = selectedOrderRoute.passengersQuantity

          const foundedOldPassengers = await dataProvider.getList('Passenger', {
            filter: { orderRouteId: props.orderRouteId },
            sort: { field: 'id', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
          })

          const foundedOldPassengerIds = foundedOldPassengers.data.map((item) => item.id)

          const foundedNewPassengers = await dataProvider.getList('Passenger', {
            filter: { orderRouteId: selectedOrderRoute.id },
            sort: { field: 'id', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
          })

          const passengersResult = await Promise.all(
            foundedNewPassengers.data
              .filter((item) => foundedOldPassengerIds.indexOf(item.id) === -1)
              .map((item) => {
                return apolloClient.mutate({
                  mutation: CREATE_PASSENGER_ROUTE,
                  variables: {
                    data: {
                      passengerId: item.id,
                      orderRouteId: props.orderRouteId,
                    },
                  },
                })
              })
          )
        }

        const result = await dataProvider.update('OrderRoute', {
          id: props.orderRouteId,
          data: { ...newCopiedData },
          previousData: {
            id: props.orderRouteId,
          },
        })

        if (!result.data || !result.data.id) {
          throw new Error('Error copying data')
        }

        notify('Route data copied successfully')
        setSelectedOrderRoute(undefined)
        setCurrentState('chooseRoute')
        setIsModalOpen(false)
        refresh()
      } catch (error) {
        notify(error, 'error')
        console.error('error saving', error)
      } finally {
        setSaving(false)
      }
    },
    [selectedOrderRoute, saving]
  )

  const handleDialogClick = useCallback<React.MouseEventHandler<HTMLDivElement>>((event) => {
    event.stopPropagation()
  }, [])

  return (
    <>
      <MenuItem onClick={handleMenuItemClick}>
        <ListItemIcon>
          <CallReceivedIcon fontSize="small" />
        </ListItemIcon>
        copy from route
      </MenuItem>

      <Dialog
        open={isModalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={handleModalClose}
        onClick={handleDialogClick}
      >
        <DialogTitle id="simple-dialog-title">Copy from route</DialogTitle>
        {isModalOpen && currentState === 'chooseRoute' && (
          <ChooseOrderRoute onItemClick={handleSelectedOrderRoute} {...props} />
        )}
        {isModalOpen && currentState === 'dataSelector' && selectedOrderRoute && (
          <ChooseOrderRouteDataToimport
            orderRoute={selectedOrderRoute}
            onCancel={handleModalClose}
            saving={saving}
            onSave={handleSave}
          />
        )}
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
}))
