import React, { FC, useEffect, useMemo } from 'react'
import { useTranslate } from 'ra-core'
import { useFormState, useForm } from 'react-final-form'
import { ReferenceInput } from 'react-admin'
import { Link } from 'react-router-dom'
import FormAutoCompleteInput from '../FormAutoCompleteInput'

type Props = {
  customerIdSource?: string
  source?: string
  record?: Record<string, any>
  customerId?: string
  isEdit?: boolean
  initialCustomerId?: string
  inDrawer?: boolean
  [x: string]: any
}

const Input: FC<any> = ({ disabled, choices, customerId, ...props }) => {
  const translate = useTranslate()
  return (
    <FormAutoCompleteInput
      {...props}
      disabled={disabled}
      choices={choices}
      optionText="fullName"
      helperText={
        disabled ? (
          translate('resources.Person.helpers.choosePersonNoCustomer')
        ) : choices && choices.length > 0 ? (
          translate('resources.Person.helpers.choosePerson')
        ) : (
          <>
            {translate('resources.Person.helpers.personNotFound')}
            <Link to={`/Customer/${customerId}/show/Person/create?organizationId=${customerId}`}>
              {translate('resources.Person.helpers.quickCreatePersonLink')}
            </Link>
          </>
        )
      }
    />
  )
}

const FilteredPersonAutocompleteInput: FC<Props> = ({
  customerIdSource = 'customerId',
  source = 'personId',
  isEdit = false,
  initialCustomerId,
  ...rest
}) => {
  const { values } = useFormState()
  const form = useForm()

  const filter = useMemo((): Record<string, string> => ({ referencedCustomerId: values[`${customerIdSource}`] }), [
    values,
    customerIdSource,
  ])

  const disabled = useMemo((): boolean => !values[`${customerIdSource}`], [values, customerIdSource])

  useEffect(() => {
    if (!isEdit || (isEdit && initialCustomerId !== values?.[customerIdSource])) {
      form.change(source, null)
    }
  }, [values?.[customerIdSource]])

  return (
    <ReferenceInput {...rest} disabled={disabled} source={source} reference="Person" filter={filter} variant="outlined">
      <Input allowEmpty disabled={disabled} customerId={values[`${customerIdSource}`]} />
    </ReferenceInput>
  )
}

export default FilteredPersonAutocompleteInput
