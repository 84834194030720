import React, { Children, FC, isValidElement } from 'react'
import { WizardSectionedProps } from './WizardSectionedForm'

export type WizardStepProps = WizardSectionedProps & {
  currentStep?: string | number
  hasNextStep?: boolean
  hasPreviousStep?: boolean
  nextStepTo?: string
  previousStepTo?: string
  goToStep?: (step: number, historyReplace?: boolean) => void
}

const WizardStep: FC<WizardStepProps> = ({
  children,
  resource,
  record,
  basePath,
  variant,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  pristine,
  saving,
  submitOnEnter,
  undoable,
  toolbar,
  redirect: defaultRedirect,
  defaultTitle,
  title,
  currentStep,
  hasNextStep,
  hasPreviousStep,
  nextStepTo,
  previousStepTo,
  goToStep,
  ...rest
}) => {
  return (
    <div>
      {Children.map(children, (form) => {
        if (form && isValidElement(form)) {
          return React.cloneElement(form, {
            resource,
            record,
            basePath,
            variant: form.props.variant || variant,
            currentStep,
            hasNextStep,
            hasPreviousStep,
            nextStepTo,
            previousStepTo,
            goToStep,
            handleSubmit,
            handleSubmitWithRedirect,
            invalid,
            pristine,
            saving,
            submitOnEnter,
            undoable,
            toolbar,
            redirect: defaultRedirect,
            defaultTitle,
            // title,
          })
        }
      })}
    </div>
  )
}

export default WizardStep
