import React, { FC } from 'react'
import { RadioButtonGroupInput } from 'react-admin'
import { OfficeAreaEnum } from '../customEnums'

type Props = {
  record?: Record<string, any>
  source?: string
  label?: string | false
}

const OfficeAreaInput: FC<Props> = ({ source = 'areaId', label }) => {
  return (
    <RadioButtonGroupInput
      label={label}
      source={source}
      choices={Object.entries(OfficeAreaEnum).map((area: Record<number, string>) => ({
        id: area[0],
        name: area[1],
      }))}
    />
  )
}

export default OfficeAreaInput
