import React, { FC } from 'react'
import RAList from '../../../components/list/List'
import { TextField, NumberField } from 'react-admin'
import VehicleCarbonOffsetFilters from './VehicleCarbonOffsetFilters'
import GenericMoreMenu from '../../../components/GenericMoreMenu'
import Datagrid from '../../../components/Datagrid'
import { FaLeaf } from 'react-icons/fa'

const sort = { field: 'value', order: 'DESC' }

type Props = {}

const List: FC<Props> = (props) => {
  return (
    <RAList {...props} sort={sort} filters={<VehicleCarbonOffsetFilters variant="outlined" />} titleIcon={<FaLeaf />}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="name" />
        <NumberField source="value" />
        <GenericMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default List
