import React, { FC } from 'react'
import { ReferenceInput, SelectInput } from 'react-admin'
import FormSelectInput from './FormSelectInput'

type Props = {
  reference?: string
  source?: string
  sort?: {
    field: string
    orderd: 'ASC' | 'DESC'
  }
  optionText?: string | ((choice: any) => string)
  inDrawer?: boolean
  [x: string]: any
}

const sortByName = { field: 'name', order: 'ASC' }

const renderText = (choice: any): string => `${choice.name} (${choice.value})`

const Select: FC<any> = ({ inDrawer, optionText, ...props }) => {
  return inDrawer ? (
    <FormSelectInput {...props} optionText={optionText} />
  ) : (
    <SelectInput {...props} optionText={optionText} variant="outlined" />
  )
}

const CarbonOffsetReferenceInput: FC<Props> = ({
  reference = 'VehicleCarbonOffset',
  source = 'vehicleCarbonOffsetId',
  sort = sortByName,
  optionText = renderText,
  inDrawer = false,
  ...props
}) => {
  return (
    <ReferenceInput {...props} reference={reference} source={source} sort={sort}>
      <Select optionText={optionText} inDrawer={inDrawer} />
    </ReferenceInput>
  )
}

export default CarbonOffsetReferenceInput
