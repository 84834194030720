import { useQuery } from '@apollo/client'
import { Button, IconButton, makeStyles, Menu, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { FC, useCallback, useEffect } from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { QUERY_GET_PICTURES } from '../../queries'
import { GalleryEditableFieldItem } from './GalleryEditableFieldItem'
import { useUpdate, useVersion } from 'ra-core'
import UploadMediaButton from '../UploadMediaButton'

type Props = {
  source: string
  record?: Record<string, any>
}
export const GalleryEditableField: FC<Props> = ({ source, record, ...rest }) => {
  const classes = useStyles()
  const version = useVersion()
  const [update, { loading: submitting, error: updateGalleryError }] = useUpdate('Gallery', record![source])
  const { loading, data, error, refetch } = useQuery(QUERY_GET_PICTURES, {
    variables: {
      filters: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        [source]: record![source],
      },
    },
    skip: !record || !record[source],
  })

  const getPayload = useCallback(() => {
    return {
      [source]: record![source],
    }
  }, [])

  useEffect(() => {
    refetch()
  }, [version])

  if (loading) {
    return (
      <div className={classes.root}>
        <Typography variant="h5">Pictures</Typography>
        <div className={classes.imagesContainer}>
          <Skeleton className={classes.image} variant="rect" />
          <Skeleton className={classes.image} variant="rect" />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5">Pictures</Typography>
      <div className={classes.imagesContainer}>
        {data?.pictures?.data.map((item: any, index: number) => {
          return <GalleryEditableFieldItem key={`picture-${item.id}`} picture={item} />
        })}
      </div>

      <div>
        <UploadMediaButton
          getPayload={getPayload}
          button={
            <Button color="primary" variant="contained" size="small" className={classes.uploadButton}>
              Add picture
            </Button>
          }
          resource="Picture"
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
  },
  imagesContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  imageContainer: {
    display: 'flex',
  },
  image: {
    height: 100,
    width: 100,
    // eslint-disable-next-line quotes
    content: "''",
    marginRight: theme.spacing(3),
    borderRadius: '4px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  uploadButton: {
    marginTop: theme.spacing(3),
  },
}))
