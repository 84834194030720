import React, { FC, useCallback } from 'react'
import { TextField, NumberField, ReferenceField, DateField } from 'react-admin'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useTranslate } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
// internal components
import RAList from '../../components/list/List'
import Datagrid from '../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'
import ExportButton from '../../components/button/ExportButtont'
import GenericMoreMenu from '../../components/GenericMoreMenu'
import Drawer from '../../components/Drawer'
import DrawerHeader from '../../components/DrawerHeader'
// icons
import { FaUsers } from 'react-icons/fa'
import PassengerTotalFlightsField from '../../components/field/PassengerTotalFlightsField'
import PassengerFilters from './PassengerFilters'
import InDrawerPassengerCreate from './InDrawerPassengerCreateEdit'
import InDrawerPassengerShow from './InDrawerPassengerShow'

type Props = {
  [x: string]: any
}

const List: FC<Props> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const history = useHistory()

  const handleDrawerClose = useCallback(() => history.push('/Passenger'), [])

  return (
    <>
      <RAList
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<PassengerFilters variant="outlined" />}
        titleIcon={<FaUsers />}
        bulkActionButtons={false}
        actions={
          <div>
            <CreateButton basePath={props.basePath} label="resources.Passenger.actions.create" />
            <ExportButton
              {...props}
              className={classes.export}
              loading={false}
              onClick={(): void => console.log('TODO: implement me!!')}
            />
          </div>
        }
      >
        <Datagrid rowClick="show">
          <NumberField source="id" />
          <TextField source="lastName" />
          <TextField source="firstName" />
          <DateField source="birthDate" />
          <ReferenceField source="nationality" reference="Country" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <PassengerTotalFlightsField label="resources.Passenger.fields.total" />
          <GenericMoreMenu />
        </Datagrid>
      </RAList>
      <Switch>
        <Route exact path="/Passenger/:id/show">
          {({ match }): React.ReactNode => (
            <Drawer open={true} onClose={handleDrawerClose}>
              <InDrawerPassengerShow
                id={match && match.params ? match.params.id : undefined}
                onCancel={handleDrawerClose}
                {...props}
              />
            </Drawer>
          )}
        </Route>
        <Route exact path="/Passenger/:id">
          {({ match }): React.ReactNode => {
            const isOpen = match?.params?.id
            const isCreate = match && match.params && match.params.id === 'create'
            const passengerId = isCreate ? undefined : match?.params.id

            return (
              <Drawer open={isOpen} onClose={handleDrawerClose}>
                <DrawerHeader
                  buttonOnClick={handleDrawerClose}
                  title={isCreate ? translate('ra.action.create') : `${translate('ra.action.edit')} #${passengerId}`}
                />
                <InDrawerPassengerCreate passengerId={passengerId} />
              </Drawer>
            )
          }}
        </Route>
      </Switch>
    </>
  )
}

const useStyles = makeStyles((theme: any) => ({
  export: {
    marginLeft: theme.spacing(3),
  },
  drawerContent: {
    width: '26vw',
    minWidth: 260,
    // eslint-disable-next-line quotes
    content: "''",
  },
}))

export default List
