import React, { FC, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Form, FormRenderProps, FormSpy } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { mergeInitialValuesWithDefaultValues } from 'ra-ui-materialui/lib/list/filter/FilterForm'
import clsx from 'clsx'
import BaseButton from '../../button/BaseButton'
import { MdFilter } from 'react-icons/md'
import { FaFilter, FaMinusCircle } from 'react-icons/fa'
import { useTranslate } from 'ra-core'
import { Badge, Box } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    form: {
      display: 'flex',
      alignItems: 'center',
    },
    clearFix: { clear: 'right' },
    drawerButton: {
      marginLeft: theme.spacing(4),
      alignSelf: 'flex-end',
    },
    buttonBadge: {
      '& > *': { top: '-20px' },
    },
    filterIcon: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        marginrRight: 0,
        marginLeft: 0,
        padding: 0,
      },
    },
    filterTitle: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  }),
  { name: 'HyInlineFilterForm' }
)

const InlineFilterForm: FC<{ [x: string]: any }> = ({
  classes = {},
  className,
  resource,
  margin,
  variant,
  filters,
  displayedFilters = {},
  hideFilter,
  initialValues,
  handleOpenDrawer,
  setFilters,
  ...rest
}) => {
  const translate = useTranslate()
  const alwaysOnFilters = useMemo(() => {
    return filters.filter((item: any) => item.props.alwaysOn)
  }, [filters])

  const activeFiltersCount = useMemo(
    () => Object.keys(initialValues).filter((val: any) => val !== 'organizationId').length,
    [filters]
  )

  const deleteAllFilters = (): void => {
    setFilters({}, {})
  }

  return (
    <form className={clsx(className, classes.form)} {...sanitizeRestProps(rest)} onSubmit={handleSubmit}>
      {alwaysOnFilters.map((filterElement: any) => {
        return React.cloneElement(filterElement, {
          allowEmpty: filterElement.props.allowEmpty === undefined ? true : filterElement.props.allowEmpty,
          resource,
          record: emptyRecord,
          variant,
          margin,
          helperText: false,
          // ignore defaultValue in Field because it was already set in Form (via mergedInitialValuesWithDefaultValues)
          defaultValue: undefined,
        })
      })}
      <BaseButton
        className={classes.drawerButton}
        onClick={handleOpenDrawer}
        disabled={filters.length - alwaysOnFilters.length === 0}
      >
        <FaFilter size="1rem" className={classes.filterIcon} />
        <span className={classes.filterTitle}>{translate('ra.action.filters')}</span>
      </BaseButton>
      {activeFiltersCount ? (
        <Badge
          className={classes.buttonBadge}
          color="primary"
          badgeContent={activeFiltersCount}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      ) : (
        ''
      )}

      {activeFiltersCount ? (
        <BaseButton size="small" variant="text" color="primary" onClick={deleteAllFilters}>
          {translate('ra.action.removeFilters')}
        </BaseButton>
      ) : (
        ''
      )}
    </form>
  )
}

const EnhancedInlineFilterForm: FC<{ [x: string]: any }> = (props) => {
  const classes = useStyles(props)
  const mergedInitialValuesWithDefaultValues = mergeInitialValuesWithDefaultValues(props as any)

  return (
    <Form
      onSubmit={handleFinalFormSubmit}
      initialValues={mergedInitialValuesWithDefaultValues}
      mutators={{ ...arrayMutators }}
      render={(formProps: FormRenderProps): React.ReactNode => (
        <>
          <FormSpy
            subscription={FormSpySubscription}
            onChange={({ pristine, values }: any): void => {
              if (pristine) {
                return
              }
              props.setFilters && props.setFilters(values, null)
            }}
          />
          <InlineFilterForm classes={classes} {...formProps} {...props} />
        </>
      )}
    />
  )
}

const emptyRecord = {}

// Options to instruct the FormSpy that it should only listen to the values and pristine changes
const FormSpySubscription = { values: true, pristine: true }

const handleSubmit = (event: any): boolean => {
  event.preventDefault()
  return false
}

const handleFinalFormSubmit = (): void => {
  //
}

const sanitizeRestProps = ({
  active,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  error,
  errors,
  filterValues,
  form,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  invalid,
  modified,
  modifiedSinceLastSubmit,
  pristine,
  setFilters,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  touched,
  valid,
  validating,
  values,
  visited,
  ...props
}: any): any => props

export default EnhancedInlineFilterForm
