import { EventContentArg } from '@fullcalendar/react'
import Typography from '@material-ui/core/Typography'
import { ReferenceField, TextField } from 'react-admin'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { FaLeaf, FaPlane, FaShieldAlt, FaUsers, FaUserTie } from 'react-icons/fa'
import { format, Locale } from 'date-fns'
import { BoxProps, makeStyles } from '@material-ui/core'
import { useDataProvider, useGetOne, useTranslate } from 'ra-core'
import ClientNameField from '../field/ClientNameField'
import {
  Box,
  chakra,
  Circle,
  Flex,
  forwardRef,
  Grid,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Skeleton,
  SkeletonProps,
  Text,
  Image,
  Link,
} from '@chakra-ui/react'
import { IoIosAirplane, IoIosJet, IoIosPaper, IoIosBarcode } from 'react-icons/io'
import { AiOutlineBarcode, AiOutlineNumber } from 'react-icons/ai'
import { Link as RouterLink } from 'react-router-dom'
import { HiOfficeBuilding } from 'react-icons/hi'
import { useApolloClient, useQuery } from '@apollo/client'
import { ROUTE_STATUS_OPTIONS } from '../field/OrderRouteStatusField'
import { formatMinutesDuration } from '../field/FlightDurationField'
import { QUERY_GET_PICTURES, QUERY_GET_VEHICLE_BY_ID } from '../../queries'
import { BsDot } from 'react-icons/bs'

type Props = {
  calendarEvent: any
  locale: Locale
}

const IATASkeleton: FC<SkeletonProps> = (props) => <Skeleton w="27px" h="14px" my="2px" {...props} />

type FlightEventContentProps = {
  order?: any
  customer?: any
  departureAirport?: any
  arrivalAirport?: any
  loading?: boolean
  startStr?: string
  endStr?: string
  status?: {
    name: string
    color: string
  }
}

export const FlightEventContent: FC<FlightEventContentProps> = forwardRef<FlightEventContentProps & BoxProps, 'div'>(
  ({ order, customer, departureAirport, arrivalAirport, startStr, endStr, loading, status, ...rest }, ref) => {
    return (
      <Box
        d="flex"
        w="100%"
        h="100%"
        flexWrap="wrap"
        borderLeftWidth="4px"
        borderLeftStyle="solid"
        borderLeftColor={status?.color || 'gray.200'}
        ref={ref}
        {...rest}
      >
        <Box px={1} w="100%" h="100%" color="blackAlpha.700">
          {/* <pre>{JSON.stringify(orderData, null, 2)}</pre> */}
          <Flex alignItems="center">
            <Flex flexDir="column">
              {loading ? (
                <IATASkeleton />
              ) : (
                <Text fontSize="xs" fontWeight="bold">
                  {departureAirport?.iataCode}
                </Text>
              )}
              <Text fontSize="x-small">{startStr}</Text>
            </Flex>
            <Icon mx={1} w="15px" h="15px" as={IoIosAirplane} />
            <Flex flexDir="column">
              {loading ? (
                <IATASkeleton />
              ) : (
                <Text fontSize="xs" fontWeight="bold">
                  {arrivalAirport?.iataCode}
                </Text>
              )}
              <Text fontSize="x-small">{endStr}</Text>
            </Flex>
          </Flex>
          <Flex mt={1} flexWrap="wrap">
            <Flex alignItems="center" pr={2}>
              <Icon as={IoIosPaper} mr="0.07rem" w="10px" h="10px" />
              {loading ? (
                <Skeleton w="55px" h="10px" mt="2px" mb="3px" />
              ) : (
                <Text fontSize="x-small">{order?.shortRef}</Text>
              )}
            </Flex>
            <Flex alignItems="center">
              {loading ? (
                <Skeleton w="10px" h="10px" mt="1px" mr="0.07rem" />
              ) : (
                <>
                  {customer?.isCompany ? (
                    <Icon as={HiOfficeBuilding} mr="0.07rem" w="10px" h="10px" />
                  ) : (
                    <Icon as={FaUserTie} mr="0.07rem" w="10px" h="10px" />
                  )}
                </>
              )}
              {loading ? (
                <Skeleton ml="3px" w="55px" mt="1px" h="10px" />
              ) : (
                <Text noOfLines={1} fontSize="x-small">
                  {customer?.clientName}
                </Text>
              )}
            </Flex>
          </Flex>
        </Box>
      </Box>
    )
  }
)

export const FlightEvent: FC<Props> = ({ calendarEvent, locale }: Props) => {
  const { extendedProps, startStr: start, endStr: end } = calendarEvent.event
  const translate = useTranslate()
  const provider = useDataProvider()
  const [order, setOrder] = useState<any>(undefined)
  const [customer, setCustomer] = useState<any>(undefined)
  const [departureAirport, setDepartureAirport] = useState<any>(undefined)
  const [arrivalAirport, setArrivalAirport] = useState<any>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const { data: vehicleData, loading: vehicleLoading } = useQuery(QUERY_GET_VEHICLE_BY_ID, {
    variables: {
      id: extendedProps.vehicleId,
    },
    skip: !extendedProps || !extendedProps.vehicleId,
  })

  const imageUrl = useMemo(
    () =>
      vehicleData?.vehicle?.gallery?.pictures && vehicleData.vehicle.gallery.pictures.length > 0
        ? vehicleData.vehicle.gallery.pictures[0].urlMedium
        : undefined,
    [vehicleData]
  )

  const status = useMemo(() => ROUTE_STATUS_OPTIONS.find((s) => s.name === extendedProps?.status), [
    extendedProps?.status,
  ])

  const statusIndex = useMemo(() => ROUTE_STATUS_OPTIONS.findIndex((s) => s.name === extendedProps?.status), [
    extendedProps?.status,
  ])
  const startStr = useMemo(() => (start ? format(new Date(start), 'p', { locale }) : undefined), [start, locale])
  const endStr = useMemo(() => (end ? format(new Date(end), 'p', { locale }) : 'N.D.'), [end, locale])
  const durationStr = useMemo(() => {
    if (extendedProps?.flightDuration) {
      return formatMinutesDuration(extendedProps.flightDuration)
    }

    return 'N.D.'
  }, [extendedProps?.flightDuration])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (!extendedProps?.orderId) {
        return
      }

      try {
        setLoading(true)
        const [[order, customer], airports] = await Promise.all([
          (async (): Promise<any[]> => {
            const { data: order } = await provider.getOne('Order', { id: extendedProps?.orderId })
            const { data: customer } = await provider.getOne('Customer', { id: order.customerId })
            return [order, customer]
          })(),
          (async (): Promise<any[]> => {
            const { data } = await provider.getMany('Airport', {
              ids: [extendedProps.departureAirportId, extendedProps.arrivalAirportId],
            })
            return data
          })(),
        ])

        setOrder({
          ...order,
          shortRef: order.reference.substring(order.reference.length - 5),
        })
        setCustomer({
          ...customer,
          clientName: customer.isCompany ? customer.companyName : `${customer.firstName} ${customer.lastName}`,
        })
        setDepartureAirport(airports.find((item) => item.id === extendedProps.departureAirportId))
        setArrivalAirport(airports.find((item) => item.id === extendedProps.arrivalAirportId))
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <FlightEventContent
          order={order}
          customer={customer}
          departureAirport={departureAirport}
          arrivalAirport={arrivalAirport}
          loading={loading}
          startStr={startStr}
          endStr={endStr}
          status={status}
        />
      </PopoverTrigger>

      <Portal>
        <PopoverContent boxShadow="lg" width="400px">
          <PopoverCloseButton />
          {/* <PopoverHeader>
            
          </PopoverHeader> */}
          <PopoverBody d="flex" flexDir="column">
            <Flex
              flex="1"
              alignItems="center"
              borderBottom="1px solid"
              borderBottomColor="blackAlpha.200"
              pb={2}
              mb={2}
            >
              {vehicleLoading ? (
                <Skeleton boxSize="55px" borderRadius="md" />
              ) : imageUrl ? (
                <Image
                  borderRadius="md"
                  src={imageUrl}
                  alt={vehicleData?.name || 'loading image'}
                  boxSize="55px"
                  fit="cover"
                  objectPosition="left center"
                />
              ) : (
                <Box boxSize="55px" borderRadius="md" border="1px solid" borderColor="blackAlpha.200" />
              )}
              <Flex ml={2} flexDir="column" alignItems="flex-start">
                <Text as="span" fontWeight="bold" fontSize="sm" noOfLines={1}>
                  {vehicleData?.vehicle?.vehicleModel?.name || 'N.D.'}
                </Text>
                <Text as="span" fontSize="xs" noOfLines={1}>
                  Operated by{' '}
                  <Text as="span" fontWeight="bold">
                    {vehicleData?.vehicle?.vehicleManufacturer?.name || 'N.D.'}
                  </Text>
                </Text>
                <Flex alignItems="center" color="blackAlpha.700">
                  <Icon as={AiOutlineNumber} mr="0.07rem" w="12px" h="12px" />
                  <Text fontSize="xs" as="span">
                    {vehicleData?.vehicle?.licensePlate || 'N.D.'}
                  </Text>

                  {/* {vehicleData?.vehicle?.vehicleSafetyType?.id && (
                    <>
                      <Icon as={BsDot} w="12px" h="12px" />
                      <Icon as={FaShieldAlt} mr={1} w="10px" h="10px" />
                      <Text fontSize="xs" fontWeight="bold" as="span">
                        {vehicleData?.vehicle?.vehicleSafetyType?.name || 'N.D.'}
                      </Text>
                    </>
                  )} */}
                  <Icon as={BsDot} w="12px" h="12px" />
                  <Icon as={FaUsers} mr={1} w="10px" h="10px" />
                  <Text fontSize="xs" as="span">
                    {vehicleData?.vehicle?.numberOfSeats || 'N.D.'}
                  </Text>

                  {extendedProps?.flightDuration && vehicleData?.vehicle?.vehicleCarbonOffset?.value && (
                    <>
                      <Icon as={BsDot} w="12px" h="12px" />
                      <Icon as={FaLeaf} mr={1} w="10px" h="10px" />
                      <Text fontSize="xs" as="span">
                        {(
                          (vehicleData?.vehicle?.vehicleCarbonOffset?.value * extendedProps.flightDuration) /
                          60
                        ).toFixed(2)}
                      </Text>
                    </>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Flex
              flex="1"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid"
              borderBottomColor="blackAlpha.200"
              pb={2}
              mb={2}
            >
              <Flex flexDir="column">
                <Text fontSize="x-small" color="blackAlpha.700">
                  Client
                </Text>
                <Link
                  as={RouterLink}
                  to={`/Customer/${customer?.id}/show`}
                  fontSize="sm"
                  noOfLines={1}
                  fontWeight="bold"
                  color="blue.700"
                >
                  {customer?.clientName}
                </Link>
              </Flex>

              <HStack spacing={6}>
                <Flex flexDir="column">
                  <Text fontSize="x-small" color="blackAlpha.700">
                    Order ref.
                  </Text>
                  <Link
                    as={RouterLink}
                    to={`/Order/${order?.id}/show?selectedRoute=${extendedProps?.id}`}
                    fontSize="sm"
                    noOfLines={1}
                    fontWeight="bold"
                    color="blue.700"
                  >
                    #{order?.shortRef}
                  </Link>
                </Flex>
                <Flex flexDir="column">
                  <Text textAlign="right" fontSize="x-small" color="blackAlpha.700">
                    Flight No.
                  </Text>
                  <Text textAlign="right" fontSize="sm" fontWeight="bold">
                    {extendedProps?.flightNumber || 'N.D.'}
                  </Text>
                </Flex>
              </HStack>
            </Flex>

            <Grid templateColumns="68px 1fr 68px">
              <Flex alignItems="flex-start" flexDir="column">
                <Text fontSize="x-small" color="blackAlpha.600">
                  {startStr}
                </Text>
                <Flex flex="1" w="100%" alignItems="center" justifyContent="flex-start">
                  <Text fontWeight="black" mr={3}>
                    {departureAirport?.iataCode}
                  </Text>
                  {/** Departure Circle */}
                  <Box
                    d="flex"
                    w="18px"
                    h="18px"
                    alignItems="center"
                    justifyContent="center"
                    borderWidth="4px"
                    borderRadius="full"
                    borderColor="blackAlpha.200"
                  >
                    <Box borderRadius="full" w="10px" h="10px" bgColor="gray.400" />
                  </Box>
                </Flex>
              </Flex>
              <Flex alignItems="center" pos="relative">
                <Box flex="1" mt="15px">
                  <Icon
                    pos="absolute"
                    left="calc(50% - 14px)"
                    top="calc(50% - 7px)"
                    w="28px"
                    h="28px"
                    as={IoIosAirplane}
                    color="gray.300"
                  />
                  <chakra.hr border="0 none" bg="none" height="0" borderTop="2px dashed" borderColor="blackAlpha.200" />
                </Box>
              </Flex>
              <Flex alignItems="flex-end" flexDir="column" justifyContent="flex-end">
                <Text fontSize="x-small" textAlign="right" color="blackAlpha.600">
                  {endStr}
                </Text>
                <Flex flex="1" w="100%" alignItems="center" justifyContent="flex-end">
                  {/** Arrival Circle */}
                  <Box
                    d="flex"
                    w="18px"
                    h="18px"
                    alignItems="center"
                    justifyContent="center"
                    borderWidth="4px"
                    borderRadius="full"
                    borderColor="blackAlpha.200"
                  >
                    <Box borderRadius="full" w="10px" h="10px" borderWidth="2px" borderColor="gray.400" />
                  </Box>
                  <Text ml={3} fontWeight="black">
                    {arrivalAirport?.iataCode}
                  </Text>
                </Flex>
              </Flex>
            </Grid>
            <Grid
              borderBottom="1px solid"
              borderBottomColor="blackAlpha.200"
              pb={2}
              mb={2}
              templateColumns="1.15fr 0.7fr 1.15fr"
              pt={1}
            >
              <Text fontSize="x-small" noOfLines={1}>
                {departureAirport?.name || 'N.D.'}
              </Text>
              <Text textAlign="center" color="blackAlpha.600" fontSize="x-small">
                {durationStr}
              </Text>
              <Text fontSize="x-small" noOfLines={1} textAlign="right">
                {arrivalAirport?.name || 'N.D.'}
              </Text>
            </Grid>

            <Box pb={2} mb={2} borderBottom="1px solid" borderBottomColor="blackAlpha.200">
              <Box py={2} px={3}>
                <Grid templateColumns="1fr 1fr 1fr" templateRows="10px" borderRadius="full" overflow="hidden">
                  <Box borderLeftRadius="full" w="100%" bgColor="red.500" />
                  <Box
                    w="100%"
                    bgColor="orange.400"
                    opacity={statusIndex >= 1 ? 1 : 0.4}
                    borderLeft="1px solid white"
                    borderRight="1px solid white"
                  />
                  <Box borderRightRadius="full" w="100%" opacity={statusIndex >= 2 ? 1 : 0.4} bgColor="green.500" />
                </Grid>
                <Grid mt={1} templateColumns="1fr 1fr 1fr">
                  <Text noOfLines={1} color="red.500" fontSize="xx-small">
                    {translate(`customFields.routeStatus.${ROUTE_STATUS_OPTIONS[0].name}`)}
                  </Text>
                  <Text noOfLines={1} color="orange.400" opacity={statusIndex >= 1 ? 1 : 0.4} fontSize="xx-small">
                    {translate(`customFields.routeStatus.${ROUTE_STATUS_OPTIONS[1].name}`)}
                  </Text>
                  <Text noOfLines={1} color="green.500" opacity={statusIndex >= 2 ? 1 : 0.4} fontSize="xx-small">
                    {translate(`customFields.routeStatus.${ROUTE_STATUS_OPTIONS[2].name}`)}
                  </Text>
                </Grid>
              </Box>
            </Box>

            <Grid
              flex="1"
              templateColumns="1fr 1fr 1fr"
              columnGap={4}
              borderBottom="1px solid"
              borderBottomColor="blackAlpha.200"
              pb={2}
              mb={2}
            >
              <Flex flexDir="column">
                <Text fontSize="x-small" color="blackAlpha.700">
                  Captain
                </Text>
                <Text fontSize="xs" noOfLines={1} fontWeight="bold">
                  {extendedProps?.captainName || 'N.D.'}
                </Text>
                <Text fontSize="x-small" noOfLines={1}>
                  {extendedProps?.captainNumber || 'N.D.'}
                </Text>
              </Flex>

              <Flex flexDir="column">
                <Text fontSize="x-small" color="blackAlpha.700">
                  First Officer
                </Text>
                <Text fontSize="xs" noOfLines={1} fontWeight="bold">
                  {extendedProps?.firstOfficerName || 'N.D.'}
                </Text>
                <Text fontSize="x-small" noOfLines={1}>
                  {extendedProps?.firstOfficerNumber || 'N.D.'}
                </Text>
              </Flex>

              <Flex flexDir="column" alignItems="flex-end">
                <Text textAlign="right" fontSize="x-small" color="blackAlpha.700">
                  Flight Attendant
                </Text>
                <Text textAlign="right" fontSize="xs" noOfLines={1} fontWeight="bold">
                  {extendedProps?.flightAttendantName || 'N.D.'}
                </Text>
                <Text textAlign="right" fontSize="x-small" noOfLines={1}>
                  {extendedProps?.flightAttendantNumber || 'N.D.'}
                </Text>
              </Flex>
            </Grid>
            <Flex alignItems="center" justifyContent="space-between" pb={2} mb={2}>
              <Flex flexDir="column">
                <Text fontSize="x-small" color="blackAlpha.700">
                  Departure Handling
                </Text>
                <Text fontSize="xs" noOfLines={1} fontWeight="bold">
                  {extendedProps?.departureHandlingName || 'N.D.'}
                </Text>
                <Text fontSize="x-small" noOfLines={1}>
                  {extendedProps?.departureHandlingNumber || 'N.D.'}
                </Text>
              </Flex>

              <Flex flexDir="column" alignItems="flex-end">
                <Text textAlign="right" fontSize="x-small" color="blackAlpha.700">
                  Destination Handling
                </Text>
                <Text textAlign="right" fontSize="xs" noOfLines={1} fontWeight="bold">
                  {extendedProps?.arrivalHandlingName || 'N.D.'}
                </Text>
                <Text textAlign="right" fontSize="x-small" noOfLines={1}>
                  {extendedProps?.arrivalHandlingNumber || 'N.D.'}
                </Text>
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
