import list from './OfficeList'
import create from './OfficeCreate'
import edit from './OfficeEdit'
import show from './OfficeShow'

export default {
  name: 'Office',
  list,
  create,
  edit,
  show,
}
