import { Avatar, Box, BoxProps, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { useInfoCardStyles } from './InfoCard.styles'
import classNames from 'classnames'

type Props = BoxProps & {
  children: any
}

const InfoCardAvatar: FC<Props> = ({ children, ...rest }) => {
  const classes = useInfoCardStyles()
  return (
    <Box className={classNames(classes.avatar)} {...rest}>
      <Avatar variant="rounded" className={classes.avatar}>
        {children}
      </Avatar>
    </Box>
  )
}

export default InfoCardAvatar
