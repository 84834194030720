import React, { FC } from 'react'
import { Datagrid, TextField, FunctionField } from 'react-admin'
import ProductCategoryFilters from './ProductCategoryFilters'
import { Link } from '@material-ui/core'
import List from '../../components/list/List'

const ProductCategoryList: FC = (props: any): any => {
  return (
    <List {...props} filters={<ProductCategoryFilters />} sort={{ field: 'name', order: 'ASC' }}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        {/* <FunctionField
          source="slug"
          render={(record: any): React.ReactNode => (
            <Link
              target="_blank"
              href={`https://localhost:3005/${record['slug']}`}
            >{`http://localhost:3005/${record['slug']}`}</Link>
          )}
        /> */}
        <FunctionField label="Sub categories" render={(record: any): any => record.subCategories.length} />
      </Datagrid>
    </List>
  )
}

export default ProductCategoryList
