import React, { FC, useCallback } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState, useForm } from 'react-final-form'
import { useGetList, useTranslate } from 'ra-core'
import MUISelect from '@material-ui/core/Select'
import MUIMenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import LinearProgress from '@material-ui/core/LinearProgress'
import FlagFromISOField from '../../components/field/FlagFromISOField'
import ErrorIcon from '@material-ui/icons/Error'
import { useEffect } from 'react'

type Props = {
  source?: string
  allowEmpty?: boolean
  [x: string]: any
}

const LangWithFlagSelectInput: FC<Props> = ({ source = 'languageId', allowEmpty = false }) => {
  const { loading, data } = useGetList(
    'Language',
    { page: 1, perPage: 20 },
    { field: 'name', order: 'ASC' },
    { active: true }
  )
  const classes = useStyles()
  const { values } = useFormState()
  const form = useForm()
  const translate = useTranslate()

  const handleLang = useCallback(
    (e: any) => {
      form.change(source, e.target.value === 0 ? null : e.target.value)
    },
    [form]
  )

  useEffect(() => {
    if (data && !loading && !values[source]) {
      form.change(source, Object.values(data)[0].id)
    }
  }, [data])

  return !loading && data ? (
    Object.values(data).length > 0 ? (
      <MUISelect
        id="languageId"
        value={values[source] ? values[source] : allowEmpty ? 0 : Object.values(data)[0].id}
        variant="outlined"
        autoWidth
        className={classes.select}
        classes={{ root: classes.root }}
        onChange={handleLang}
      >
        {allowEmpty && (
          <MUIMenuItem key="language-select-null" value={0} className={classes.menuitem}>
            <div className={classes.wrapper}>
              <Typography className={classes.nullValue}>
                <span>{'Choose language'}</span>
              </Typography>
            </div>
          </MUIMenuItem>
        )}
        {Object.values(data).map((language: any) => (
          <MUIMenuItem key={`language-select-${language.id}`} value={language.id} className={classes.menuitem}>
            <div className={classes.wrapper}>
              <Typography className={classes.isoCode}>
                <span>{language.isoCode}</span>
              </Typography>
              <FlagFromISOField source="isoCode" record={language} />
            </div>
          </MUIMenuItem>
        ))}
      </MUISelect>
    ) : (
      <Tooltip title={translate('resources.Language.missingLanguageTooltip')} placement="top">
        <ErrorIcon color="error" />
      </Tooltip>
    )
  ) : (
    <LinearProgress />
  )
}

const useStyles = makeStyles((theme) => ({
  menuitem: {
    witdh: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  isoCode: {
    marginRight: theme.spacing(2),
  },
  select: {
    minWidth: '7rem',
  },
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  nullValue: {
    marginRight: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.4)',
  },
}))

export default LangWithFlagSelectInput
