import React, { FC, useState } from 'react'
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
} from '@material-ui/core'
import { useCallback } from 'react'

export type SectionsToImport = {
  flightNumber: boolean
  crewInfo: boolean
  handlingInfo: boolean
  passengers: boolean
  additionalInfo: boolean
}

type Props = {
  orderRoute: any
  onCancel: () => void
  saving: boolean
  onSave: (sectionsToImport: SectionsToImport) => void
}

export const ChooseOrderRouteDataToimport: FC<Props> = ({ orderRoute, onCancel, saving, onSave }) => {
  const classes = useStyles()

  const [sectionsToImport, setSectionsToImport] = useState<SectionsToImport>({
    flightNumber: true,
    crewInfo: true,
    handlingInfo: true,
    passengers: true,
    additionalInfo: true,
  })

  const handleOnSave = useCallback(() => {
    if (onSave) {
      onSave(sectionsToImport)
    }
  }, [sectionsToImport])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSectionsToImport({
        ...sectionsToImport,
        [event.target.name]: event.target.checked,
      })
    },
    [sectionsToImport]
  )

  return (
    <>
      <DialogContent>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sectionsToImport.flightNumber}
                  onChange={handleChange}
                  name="flightNumber"
                  color="primary"
                />
              }
              label="Flight Number"
            />
            <FormControlLabel
              control={
                <Checkbox checked={sectionsToImport.crewInfo} onChange={handleChange} name="crewInfo" color="primary" />
              }
              label="Crew Info"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sectionsToImport.handlingInfo}
                  onChange={handleChange}
                  name="handlingInfo"
                  color="primary"
                />
              }
              label="Handling Info"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sectionsToImport.passengers}
                  onChange={handleChange}
                  name="passengers"
                  color="primary"
                />
              }
              label="Passengers"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sectionsToImport.additionalInfo}
                  onChange={handleChange}
                  name="additionalInfo"
                  color="primary"
                />
              }
              label="Additional Information"
            />
          </FormGroup>
          {/* <FormHelperText>Be careful</FormHelperText> */}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button disabled={saving} onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button disabled={saving} onClick={handleOnSave} color="primary" autoFocus>
          Copy data
        </Button>
      </DialogActions>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
}))
