import React, { FC, useCallback, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useTranslate } from 'ra-core'
import { addHours, setMinutes } from 'date-fns'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'

type Props = {}

const AddRouteButtons: FC<Props> = () => {
  const { values } = useFormState()
  const form = useForm()
  const translate = useTranslate()

  const onClickAddRoute = useCallback(() => {
    const routeToPush = {
      key: new Date().getTime(),
      passengersQuantity: 1,
      departureAirportId: '',
      arrivalAirportId: '',
      flightDate: setMinutes(addHours(new Date(), 3), 0),
    }
    if (values && values.routes && Array.isArray(values.routes) && values.routes.length > 0) {
      const lastRoute = { ...values.routes[values.routes.length - 1] }
      routeToPush.passengersQuantity = lastRoute.passengersQuantity
      routeToPush.departureAirportId = lastRoute.arrivalAirportId
      routeToPush.flightDate = addHours(new Date(lastRoute.flightDate), 3)
    }
    form.mutators.push('routes', routeToPush)
  }, [values?.routes])

  const onClickAddReturn = useCallback(() => {
    if (values && values.routes && Array.isArray(values.routes)) {
      const lastRoute = { ...values.routes[values.routes.length - 1] }
      form.mutators.push('routes', {
        key: new Date().getTime(),
        passengersQuantity: lastRoute.passengersQuantity,
        departureAirportId: lastRoute.arrivalAirportId,
        arrivalAirportId: lastRoute.departureAirportId,
        flightDate: addHours(new Date(lastRoute.flightDate), 3),
      })
    }
  }, [values?.routes])

  const disabled = useMemo((): boolean => {
    if (!values || !values.routes) return false
    else {
      if (Array.isArray(values.routes) && values.routes.length === 0) return false
      else {
        const lastRoute = { ...values.routes[values.routes.length - 1] }
        if (lastRoute.departureAirportId === '' || lastRoute.arrivalAirportId === '') return true
        else return false
      }
    }
  }, [values?.routes])

  return (
    <>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={onClickAddRoute} disabled={disabled}>
        {translate('resources.Quote.cta.addRoute')}
      </Button>
      {values && values.routes && Array.isArray(values.routes) && values.routes.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<KeyboardReturnIcon />}
          onClick={onClickAddReturn}
          disabled={disabled}
          style={{ marginLeft: 10 }}
        >
          {translate('resources.Quote.cta.addReturn')}
        </Button>
      )}
    </>
  )
}

export default AddRouteButtons
