export const sortByDate = (a: string, b: string): number => new Date(b).valueOf() - new Date(a).valueOf()
export const sortOrderEventByDate = (a: any, b: any): number =>
  new Date(b.date || '').valueOf() - new Date(a.date || '').valueOf()

export const getDayForEvent = (event: any): string => {
  if (!event.date) {
    throw new Error('miss date prop from OrderEvent')
  }

  const date = new Date(event.date)
  date.setMilliseconds(0)
  date.setSeconds(0)
  date.setMinutes(0)
  date.setHours(0)
  return date.toISOString()
}

const groupByDay = (events: any[]): { days: string[]; eventsByDay: { [x: string]: any[] } } => {
  const groups = events.reduce((days: { [x: string]: any[] }, event) => {
    const day = getDayForEvent(event)
    if (!days[day]) {
      days[day] = []
    }
    days[day] = days[day].concat(event)
    return days
  }, {})
  return {
    days: Object.keys(groups).sort(sortByDate),
    eventsByDay: groups,
  }
}

export default groupByDay
