import * as React from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase'
import { makeStyles, fade } from '@material-ui/core/styles'
import { ExtendMui } from '@material-ui/pickers/typings/extendMui'
import { useCanAutoFocus } from './useCanAutofocus'
import { useDefaultProps } from './withDefaultProps'
import { onSpaceOrEnter } from './onSpaceOrEnter'
import { format, isMonday, isSunday } from 'date-fns'

const muiComponentConfig = { name: 'MuiPickersDay' }

const DAY_SIZE = 36

const DAY_MARGIN = 2

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      ...theme.typography.caption,
      // width: DAY_SIZE,
      minWidth: DAY_SIZE + 2 * DAY_MARGIN,
      maxWidth: DAY_SIZE + 2 * DAY_MARGIN,
      height: DAY_SIZE,
      // borderRadius: '20%',
      // margin: `0 ${DAY_MARGIN}px`,
      margin: 0,
      padding: `${DAY_MARGIN}px 0`,
      display: 'flex',
      // background required here to prevent collides with the other days when animating with transition group
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: fade(theme.palette.action.active, theme.palette.action.hoverOpacity),
      },
      '&:focus': {
        backgroundColor: fade(theme.palette.action.active, theme.palette.action.hoverOpacity),
        '&$selected': {
          willChange: 'background-color',
          backgroundColor: theme.palette.primary.dark,
        },
      },
      '&$selected': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.short,
        }),
        '&:hover': {
          willChange: 'background-color',
          backgroundColor: theme.palette.primary.dark,
        },
      },
      '&$disabled': {
        color: theme.palette.text.secondary,
      },
    },
    dayWithMargin: {
      margin: `0 ${DAY_MARGIN}px`,
    },
    dayOutsideMonth: {
      color: theme.palette.text.disabled,
    },
    dayInSameWeekSelected: {
      backgroundColor: 'rgba(0, 61, 96, 0.1)',
      // color: theme.palette.primary.contrastText,
    },
    hiddenDaySpacingFiller: {
      visibility: 'hidden',
    },
    today: {
      '&:not($selected)': {
        // border: `1px solid ${theme.palette.text.secondary}`,
        border: '1px solid rgba(0,0,0, 0.2)',
      },
    },
    dayLabel: {
      // need for overrides
    },
    selected: {},
    disabled: {},
    dot: {
      width: 6,
      height: 6,
      borderRadius: '50%',
      backgroundColor: 'orange',
      // position: 'absolute',
      // bottom: '6px',
      position: 'relative',
    },
    dot2: {
      width: 6,
      height: 6,
      borderRadius: '50%',
      backgroundColor: 'green',
      // position: 'absolute',
      // bottom: '6px',
      position: 'relative',
    },
    firstDayOfWeek: {
      borderTopLeftRadius: '20%',
      borderBottomLeftRadius: '20%',
    },
    lastDayOfWeek: {
      borderTopRightRadius: '20%',
      borderBottomRightRadius: '20%',
    },
    fullBorderRadius: {
      borderRadius: '20%',
      margin: `0 ${DAY_MARGIN}px`,
      padding: 0,
      maxWidth: `${DAY_SIZE}px`,
      minWidth: `${DAY_SIZE}px`,
    },
    dotContainer: {
      position: 'absolute',
      bottom: '4px',
      display: 'flex',
      justifyContent: 'space-around',
      minWidth: '16px',
    },
  }),
  muiComponentConfig
)

export interface DayProps<TDate> extends ExtendMui<ButtonBaseProps> {
  /**
   * The date to show.
   */
  day: TDate
  /**
   * Is focused by keyboard navigation.
   */
  focused?: boolean
  /**
   * Can be focused by tabbing in.
   */
  focusable?: boolean
  /**
   * Is day in current month.
   */
  inCurrentMonth: boolean
  /**
   * Is switching month animation going on right now.
   */
  isAnimating?: boolean
  /**
   * Is today?
   */
  today?: boolean
  /**
   * Disabled?.
   */
  disabled?: boolean
  /**
   * Selected?
   */
  selected?: boolean
  /**
   * Is keyboard control and focus management enabled.
   */
  allowKeyboardControl?: boolean
  /**
   * Disable margin between days, useful for displaying range of days.
   */
  disableMargin?: boolean
  /**
   * Display disabled dates outside the current month.
   *
   * @default false
   */
  showDaysOutsideCurrentMonth?: boolean
  /**
   * Disable highlighting today date with a circle.
   *
   * @default false
   */
  disableHighlightToday?: boolean
  /**
   * Allow selecting the same date (fire onChange even if same date is selected).
   *
   * @default false
   */
  allowSameDateSelection?: boolean

  sameSelectedWeek?: boolean

  hasPrimaryEvents?: boolean
  hasSecondaryEvents?: boolean

  fullBorderRadius?: boolean

  onDayFocus?: (day: TDate) => void
  onDaySelect: (day: TDate, isFinish: any) => void
}

function PureDay<TDate>(props: DayProps<TDate>): any {
  const {
    allowKeyboardControl,
    allowSameDateSelection = false,
    className,
    day,
    disabled = false,
    disableHighlightToday = false,
    disableMargin = false,
    focusable = false,
    focused = false,
    hidden,
    inCurrentMonth: isInCurrentMonth,
    isAnimating,
    onClick,
    onDayFocus,
    onDaySelect,
    onFocus,
    onKeyDown,
    selected = false,
    showDaysOutsideCurrentMonth = false,
    today: isToday = false,
    sameSelectedWeek,
    hasPrimaryEvents,
    hasSecondaryEvents,
    fullBorderRadius,
    today,
    ...other
  } = useDefaultProps(props, muiComponentConfig)

  // const utils = new DateFnsUtils()
  const classes = useStyles()
  const canAutoFocus = useCanAutoFocus()
  const ref = React.useRef<HTMLButtonElement>(null)

  React.useEffect(() => {
    if (
      focused &&
      !disabled &&
      !isAnimating &&
      isInCurrentMonth &&
      ref.current &&
      allowKeyboardControl &&
      canAutoFocus
    ) {
      ref.current.focus()
    }
  }, [allowKeyboardControl, canAutoFocus, disabled, focused, isAnimating, isInCurrentMonth])

  const handleFocus = (event: React.FocusEvent<HTMLButtonElement>) => {
    if (!focused && onDayFocus) {
      onDayFocus(day)
    }

    if (onFocus) {
      onFocus(event)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!allowSameDateSelection && selected) return

    if (!disabled) {
      onDaySelect(day, 'finish')
    }

    if (onClick) {
      onClick(event)
    }
  }

  const handleKeyDown = onSpaceOrEnter(() => {
    if (!disabled) {
      onDaySelect(day, 'finish')
    }
  }, onKeyDown)

  const dayClassName = clsx(
    classes.root,
    {
      [classes.selected]: selected,
      // [classes.dayWithMargin]: !disableMargin,
      [classes.today]: !disableHighlightToday && isToday,
      [classes.dayOutsideMonth]: !isInCurrentMonth && showDaysOutsideCurrentMonth,
      [classes.dayInSameWeekSelected]: sameSelectedWeek,
      [classes.firstDayOfWeek]: isMonday(day as any),
      [classes.lastDayOfWeek]: isSunday(day as any),
      [classes.fullBorderRadius]: fullBorderRadius || (today && !selected && !sameSelectedWeek),
    },
    className
  )

  if (!isInCurrentMonth && !showDaysOutsideCurrentMonth) {
    // Do not render button and not attach any listeners for empty days
    return <div aria-hidden className={clsx(dayClassName, classes.hiddenDaySpacingFiller)} />
  }

  return (
    <ButtonBase
      ref={ref}
      centerRipple
      data-mui-test="day"
      disabled={disabled}
      // aria-label={utils.format(day as any, 'fullDate')}
      tabIndex={focused || focusable ? 0 : -1}
      className={dayClassName}
      {...other}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
    >
      {/* <span className={classes.dayLabel}>{utils.format(day as any, 'dayOfMonth')}</span> */}
      <span className={classes.dayLabel}>{format(day as any, 'd')}</span>
      {/* {hasPrimaryEvents && <span className={classes.dot} />} */}
      {(hasPrimaryEvents || hasSecondaryEvents) && (
        <div className={classes.dotContainer}>
          {hasPrimaryEvents && <span className={classes.dot} />}
          {hasSecondaryEvents && <span className={classes.dot2} />}
        </div>
      )}
    </ButtonBase>
  )
}

export const areDayPropsEqual = (prevProps: DayProps<any>, nextProps: DayProps<any>) => {
  return (
    prevProps.focused === nextProps.focused &&
    prevProps.focusable === nextProps.focusable &&
    prevProps.isAnimating === nextProps.isAnimating &&
    prevProps.today === nextProps.today &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.selected === nextProps.selected &&
    prevProps.allowKeyboardControl === nextProps.allowKeyboardControl &&
    prevProps.disableMargin === nextProps.disableMargin &&
    prevProps.showDaysOutsideCurrentMonth === nextProps.showDaysOutsideCurrentMonth &&
    prevProps.disableHighlightToday === nextProps.disableHighlightToday &&
    prevProps.className === nextProps.className &&
    prevProps.inCurrentMonth === nextProps.inCurrentMonth &&
    prevProps.onDayFocus === nextProps.onDayFocus &&
    prevProps.onDaySelect === nextProps.onDaySelect &&
    prevProps.sameSelectedWeek === nextProps.sameSelectedWeek &&
    prevProps.hasPrimaryEvents === nextProps.hasPrimaryEvents &&
    prevProps.fullBorderRadius === nextProps.fullBorderRadius
  )
}

PureDay.displayName = 'PickersDay'

PureDay.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  today: PropTypes.bool,
}

// keep typings of original component and not loose generic
export const CustomDay = (React.memo(PureDay, areDayPropsEqual) as unknown) as typeof PureDay
