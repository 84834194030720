import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDataProvider, useNotify } from 'ra-core'
import { isSameDay } from 'date-fns'
import { ReferenceField, TextField } from 'react-admin'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
  userId: string
}

const perPage = 10

const UserActionsTimeline: FC<Props> = ({ userId }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [groupedData, setGroupedData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const [retrieved, setRetrieved] = useState<number>(0)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [dialogContent, setDialogContent] = useState<Record<string, any>>({})

  const getData = useCallback(async (): Promise<any> => {
    try {
      setLoading(true)
      const groupedLogs: any[] = [...groupedData]
      const { data, total: totalResults } = await dataProvider.getList('ServiceLog', {
        pagination: { perPage, page },
        sort: { field: 'id', order: 'DESC' },
        filter: { userId },
      })
      data.forEach((log: any) => {
        if (groupedLogs.length === 0) groupedLogs.push({ date: new Date(log.createdAt), logs: [log] })
        else {
          const lastElem = groupedLogs.pop()
          if (isSameDay(lastElem.date, new Date(log.createdAt))) {
            lastElem.logs = [...lastElem.logs, log]
            groupedLogs.push(lastElem)
          } else groupedLogs.push(lastElem, { date: new Date(log.createdAt), logs: [log] })
        }
      })
      setTotal(totalResults)
      setGroupedData(groupedLogs)
      setPage((prevPage) => prevPage + 1)
      setRetrieved((prevRetrieved) => prevRetrieved + data.length)
    } catch (err) {
      console.error('logs error: ', err)
      notify(err, 'error')
    } finally {
      setLoading(false)
    }
  }, [groupedData, page, retrieved])

  const onMoreClick = useCallback(() => {
    getData()
  }, [groupedData, page, retrieved])

  const onViewClick = useCallback(
    (logData: any) => (): void => {
      setDialogContent(logData)
      setIsDialogOpen(true)
    },
    []
  )

  const onDialogClose = useCallback(() => {
    setIsDialogOpen(false)
    setDialogContent({})
  }, [])

  useEffect(() => {
    getData()
  }, [])

  return (
    <Box ml={4} width="100%">
      <Timeline align="left" className={classes.timeline}>
        {groupedData.map((logGroup: any) => (
          <TimelineItem key={`${(logGroup.date as Date).toLocaleDateString()}-group`}>
            <TimelineOppositeContent className={classes.leftContent}>
              <Typography color="textSecondary" variant="h6">
                {(logGroup.date as Date).toLocaleDateString()}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.rightContent}>
              {logGroup.logs.map((log: any) => (
                <Paper key={log.createdAt} className={classes.log}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography variant="caption" className={classes.w10}>
                      {new Date(log.createdAt).toLocaleTimeString()}
                    </Typography>
                    {log.userId ? (
                      <ReferenceField
                        basePath="/User"
                        record={log}
                        label={false}
                        source="userId"
                        reference="User"
                        link={false}
                      >
                        <TextField source="fullName" className={classes.w30} />
                      </ReferenceField>
                    ) : (
                      <div className={classes.w30} />
                    )}
                    <Typography variant="body2" className={classes.w25}>
                      {log.domain}
                    </Typography>
                    <Typography variant="body2" className={classes.w25}>
                      {log.action}
                    </Typography>
                    <Button color="primary" variant="text" onClick={onViewClick(log.data)} className={classes.w10}>
                      View
                    </Button>
                  </Box>
                </Paper>
              ))}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      {total > retrieved && (
        <Box alignSelf="center" mt={4}>
          <Button color="primary" variant="contained" onClick={onMoreClick} disabled={loading}>
            {loading ? 'Loading...' : `Load more (${total - retrieved})`}
          </Button>
        </Box>
      )}
      <Dialog open={isDialogOpen} onClose={onDialogClose}>
        <DialogTitle>
          <Typography variant="h3">Data</Typography>
        </DialogTitle>
        <List className={classes.dialog}>
          {Object.entries(dialogContent).map((elem: string[], idx: number) => (
            <ListItem key={`dialog-${idx}`}>
              <ListItemText primary={elem[1]} secondary={elem[0]} secondaryTypographyProps={{ display: 'inline' }} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </Box>
  )
}

const useStyles = makeStyles((theme: any) => ({
  timeline: {
    padding: 0,
  },
  leftContent: {
    flex: 0,
    paddingLeft: 0,
  },
  rightContent: {
    paddingRight: 0,
  },
  log: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  w10: {
    minWidth: '10%',
    maxWidth: '10%',
    '&:first-child': {
      paddingLeft: theme.spacing(3),
    },
  },
  w25: {
    minWidth: '25%',
    maxWidth: '25%',
  },
  w30: {
    minWidth: '30%',
    maxWidth: '30%',
  },
  dialog: {
    minWidth: 400,
  },
}))

export default UserActionsTimeline
