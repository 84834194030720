import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useQuery } from 'ra-core'
import { LinearProgress, DateTimeInput, TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

type Props = {
  id?: string
  record?: any
}

const parseDate = (v: any): any => {
  return new Date(v).toISOString()
}

const PlannedEventDatesInput: FC<Props> = (props) => {
  const form = useForm()
  const formState = useFormState()
  const { loading, data } = useQuery({
    type: 'getOne',
    resource: 'Event',
    payload: {
      id: props.record.eventId,
    },
  })

  const newRecord = useMemo(() => {
    return {
      ...(props.record ? props.record : {}),
      ...(data ? data : {}),
    }
  }, [data, props.record])

  useEffect(() => {
    if (newRecord.endDate && !props.record.endDate) {
      form.change('endDate', newRecord.endDate)
    }
  }, [newRecord])

  useEffect(() => {
    if (newRecord.startDate && !props.record.startDate) {
      form.change('startDate', newRecord.startDate)
    }
  }, [newRecord])

  useEffect(() => {
    if (newRecord.title && !props.record.title) {
      form.change('title', newRecord.title)
    }
  }, [newRecord])

  if (loading) {
    return <LinearProgress />
  }
  // return <pre>{JSON.stringify(data, null, 2)}</pre>
  return (
    <>
      <TextInput variant="outlined" source="title" resource="Event" fullWidth />
      <DateTimeInput variant="outlined" parse={parseDate} source="startDate" resource="Event" fullWidth />
      <DateTimeInput variant="outlined" parse={parseDate} source="endDate" resource="Event" fullWidth />
    </>
  )
}

export default PlannedEventDatesInput
