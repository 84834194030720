import React, { FC } from 'react'
import { TextInput } from 'react-admin'
import FilterSelectInput from '../../components/FilterSelectInput'
import FilterTextInput from '../../components/FilterTextInput'
import Filter from '../../components/list/filter/Filter'

type Props = {
  [x: string]: any
}

const LeadFilters: FC<Props> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <FilterSelectInput
        source="leadType"
        alwaysOn
        choices={[
          { id: 'booking-form', name: 'Booking Form' },
          { id: 'newsletter', name: 'Newsletter' },
          { id: 'contacts-form', name: 'Contacts Page' },
        ]}
      />
      <TextInput source="email" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
    </Filter>
  )
}

export default LeadFilters
