import { Box, Fade, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { useInfoCardStyles } from './InfoCard.styles'

type Props = {
  show?: boolean
  children: any
}

const InfoCardWrapper: FC<Props> = ({ show = true, ...rest }) => {
  const classes = useInfoCardStyles()
  return (
    <Fade in={show}>
      <Box className={classes.wrapper} {...rest}></Box>
    </Fade>
  )
}

export default InfoCardWrapper
