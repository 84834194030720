import React, { FC, useState } from 'react'
import { useFormState } from 'react-final-form'
import { ReferenceInput, ArrayInput, SimpleFormIterator, AutocompleteInput, NumberInput, required } from 'react-admin'
import { useMutation } from '@apollo/client'
import { useAuthProvider, useDataProvider, useNotify, useQuery, useRedirect, useTranslate } from 'ra-core'
import RichTextInput from 'ra-input-rich-text'
import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import WizardSectionedForm, { WizardStep } from '../../components/forms/WizardSectionedForm'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import WizardRightControls from '../../components/forms/WizardSectionedForm/WizardRightControls'
import IconButton from '@material-ui/core/IconButton'
import SaveButton from '../../components/button/SaveButton'
import FormSelectInput from '../../components/input/FormSelectInput'
import FilteredPersonAutocompleteInput from '../../components/input/FilteredPersonAutocompleteInput'
import RoutesSummary from './FormRoutesSummary'
import OptionsSummary from './OptionsSummary'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import FormAutoCompleteInput from '../../components/FormAutoCompleteInput'
import { MUTATION_CREATE_CIELO_QUOTE } from '../../queries'
import AddOptionButtonDrawer from './AddOptionButtonDrawer'
import AddRouteButtons from './AddRouteButtons'
import NoTimezoneDateTimeInput from '../../components/input/NoTimezoneDateTimeInput'
import { useEffect } from 'react'
import { Loading } from 'react-admin'
import QuoteCustomerAutoCompleteInput from '../../components/QuoteCustomerAutoCompleteInput'

type Props = {
  [x: string]: any
}

const filterToQueryQ = (q: string): Record<string, string> => ({ q })
const airportOptionText = (record: any): string =>
  `${record.iataCode ? `${record.iataCode} (${record.icaoCode})` : record.icaoCode} - ${record.name}`

const SaveQuote: FC<any> = (props) => {
  const { values } = useFormState()
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const [createCieloQuote, { loading, data, error }] = useMutation(MUTATION_CREATE_CIELO_QUOTE)

  const handleSave = async (): Promise<any> => {
    const routes = [...values.routes]
    routes.forEach((route: any) => delete route.key)
    const options = [...values.options]
    options.forEach((option: any) => delete option.id)

    try {
      const result = await createCieloQuote({
        variables: { data: { ...values, routes, options } },
      })

      if (result && result.data && result.data.createCieloQuote && result.data.createCieloQuote.id) {
        localStorage.removeItem('_wizard_Quote_create')
        notify('ra.success')
        redirect(`/Quote/${result.data.createCieloQuote.id}/show`)
      } else {
        throw new Error('Quote id not found')
      }
    } catch (error) {
      console.log('create error: ', error)
    }
  }

  return (
    <SaveButton
      {...props}
      variant="contained"
      saving={loading}
      disabled={loading || !values.options || values.options.length === 0}
      handleSubmitWithRedirect={handleSave}
      className={loading || !values.options || values.options.length === 0 ? classes.saveButtonDisabled : undefined}
    />
  )
}

const QuoteCreate: FC<Props> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const theme = useTheme()
  const { getIdentity } = useAuthProvider()
  const dataProvider = useDataProvider()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any | undefined>(undefined)
  // console.log('SM???? ', isSmMediaQuery)

  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        setInitialized(false)
        const authUser = await getIdentity!()

        const foundedTeamMebers = await dataProvider.getList('TeamMember', {
          pagination: {
            page: 1,
            perPage: 1,
          },
          filter: {
            accountId: authUser.id,
          },
          sort: {
            field: 'id',
            order: 'ASC',
          },
        })

        console.log(authUser, foundedTeamMebers, 'authUser')

        if ((foundedTeamMebers as any).data.length > 0) {
          const foundedOrganization = await dataProvider.getOne('Organization', {
            id: foundedTeamMebers.data[0].organizationId,
          })

          console.log('organizzazione trovata', foundedOrganization)
          let currencyId = undefined
          if (foundedOrganization.data?.bankAccountsIds?.length > 0) {
            const firstBankAccount = await dataProvider.getOne('BankAccount', {
              id: foundedOrganization.data.bankAccountsIds[0],
            })

            if (firstBankAccount.data?.id) {
              currencyId = firstBankAccount.data.currencyId
            }
          }

          setInitialValues({
            organizationId: (foundedTeamMebers as any).data[0].organizationId,
            teamMemberId: (foundedTeamMebers as any).data[0].id,
            currencyId,
          })
        }
      } catch (error) {
        console.error('error initializing Quote', error)
        //
      } finally {
        setInitialized(true)
      }
    }

    init()
  }, [])

  if (!initialized) {
    return <Loading />
  }

  return (
    <Create {...props} component="div">
      <WizardSectionedForm
        variant="outlined"
        redirect="list"
        title={translate('resources.Quote.titles.create')}
        initialValues={initialValues}
      >
        <WizardStep>
          <FormSection title={translate('resources.Quote.titles.accountManager')}>
            <ReferenceInput source="organizationId" reference="Organization">
              <FormSelectInput optionText="name" fullWidth />
            </ReferenceInput>
            <ReferenceInput source="teamMemberId" reference="TeamMember">
              <FormSelectInput optionText="fullName" fullWidth />
            </ReferenceInput>
          </FormSection>
          <FormSection title={translate('resources.Quote.titles.routing')}>
            <ArrayInput source="routes" label={false} validate={required()}>
              <SimpleFormIterator
                classes={{ form: classes.form, line: classes.line }}
                removeButton={
                  <IconButton classes={{ root: classes.removeButton }}>
                    <DeleteForeverIcon />
                  </IconButton>
                }
                addButton={<AddRouteButtons />}
              >
                <ReferenceInput
                  reference="Airport"
                  source="departureAirportId"
                  filterToQuery={filterToQueryQ}
                  label={translate('resources.Route.fields.departure')}
                  validate={required()}
                  className={classes.airportInput}
                >
                  <AutocompleteInput optionText={airportOptionText} />
                </ReferenceInput>
                <ReferenceInput
                  reference="Airport"
                  source="arrivalAirportId"
                  filterToQuery={filterToQueryQ}
                  label={translate('resources.Route.fields.arrival')}
                  validate={required()}
                  className={classes.airportInput}
                >
                  <AutocompleteInput optionText={airportOptionText} />
                </ReferenceInput>
                {/* <DateTimeInput
                  source="flightDate"
                  classes={{ root: classes.dateTimeInput }}
                  label={translate('resources.Route.fields.flightDate')}
                /> */}
                <NoTimezoneDateTimeInput
                  source="flightDate"
                  classes={{ root: classes.dateTimeInput }}
                  label={translate('resources.Route.fields.flightDate')}
                />
                <NumberInput
                  source="flightDuration"
                  label={translate('resources.Route.fields.flightDuration')}
                  classes={{ root: classes.passengersInput }}
                />
                <NumberInput
                  source="passengersQuantity"
                  label={translate('resources.Route.fields.passengers')}
                  initialValue={1}
                  classes={{ root: classes.passengersInput }}
                />
                {/* <DateTimeInput
                  source="arrivalDate"
                  classes={{ root: classes.dateTimeInput }}
                  label={translate('resources.Route.fields.arrivalDate')}
                /> */}
              </SimpleFormIterator>
            </ArrayInput>
          </FormSection>
          <FormSection title={translate('resources.Quote.titles.clientAndLanguage')}>
            <ReferenceInput source="customerId" reference="Customer" validate={required()}>
              <QuoteCustomerAutoCompleteInput optionText="fullName" fullWidth />
            </ReferenceInput>
            <ReferenceInput source="languageId" reference="Language">
              <FormSelectInput optionText="name" fullWidth />
            </ReferenceInput>
            <ReferenceInput source="currencyId" reference="Currency" filters={{ active: true }}>
              <FormSelectInput optionText="name" fullWidth />
            </ReferenceInput>
            <FilteredPersonAutocompleteInput />
          </FormSection>
          <FormSection title="Printable notes">
            <RichTextInput label={false} source="printableNotes._html" />
          </FormSection>
        </WizardStep>
        <WizardStep
          // {...props}
          formRightControls={
            <WizardRightControls
              saveButtonComponent={<SaveQuote />}
              asideLowerContent={<AddOptionButtonDrawer {...props} />}
            />
          }
        >
          <RoutesSummary />
          <OptionsSummary isEdit />
        </WizardStep>
      </WizardSectionedForm>
    </Create>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexWrap: 'wrap',
    marginTop: 0,
    marginRight: theme.spacing(5),
    '@media (max-width: 960px)': {
      marginRight: 0,
    },
    '@media (max-width: 600px)': {
      alignItems: 'flex-start',
      alignContent: 'flex-start',
    },
  },
  passengersInput: {
    maxWidth: 100,
  },
  dateTimeInput: {
    width: 'auto',
    display: 'flex',
  },
  airportInput: {
    [theme.breakpoints.down('md')]: {
      minWidth: 250,
    },
  },
  line: {
    display: 'flex',
    alignItems: 'stretch',
    alignContent: 'center',
    borderBottom: 0,
    '@media (max-width: 960px)': {
      borderBottom: '1px solid gray',
      marginBottom: theme.spacing(4),
    },
    '@media (max-width: 600px)': {
      alignContent: 'stretch',
    },
  },
  removeButton: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
    padding: 7,
    marginLeft: theme.spacing(5),
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  saveButtonDisabled: {
    backgroundColor: '#e5e5e5',
  },
}))

export default QuoteCreate
