import React, { FC, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useFormState } from 'react-final-form'
import FormRightControls from '../FormRightControls'
import SaveButton from '../../button/SaveButton'
import BaseButton from '../../button/BaseButton'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { useTranslate } from 'ra-core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

type WizardProps = {
  resource?: string
  record?: Record<string, any>
  basePath?: string
  wizardBasePath?: string
  currentStep?: number
  hasNextStep?: boolean
  hasPreviousStep?: boolean
  nextStepTo?: string
  children?: React.ReactNode
  asideUpperContent?: React.ReactNode
  asideLowerContent?: React.ReactNode
  saveButtonComponent?: React.ReactNode
  undoButtonComponent?: React.ReactNode
  invalid?: boolean
  [x: string]: any
}

const WizardRightControls: FC<WizardProps> = ({
  resource,
  record = {},
  basePath,
  currentStep,
  hasNextStep,
  hasPreviousStep,
  nextStepTo,
  children,
  asideUpperContent,
  asideLowerContent,
  saveButtonComponent,
  undoButtonComponent,
  invalid,
  ...rest
}) => {
  const classes = useStyles()
  const history = useHistory()
  const translate = useTranslate()
  const { values, valid } = useFormState()

  const handleGoForwardClick = useCallback(() => {
    if (hasNextStep && nextStepTo && valid) {
      if (!record.id) {
        localStorage.setItem(`_wizard_${resource}_create`, JSON.stringify(values))
      }
      history.push(nextStepTo)
    }
  }, [nextStepTo, resource, record, values, valid])

  const handleSuccess = useCallback(() => {
    if (!record.id && localStorage.getItem(`_wizard_${resource}_create`)) {
      localStorage.removeItem(`_wizard_${resource}_create`)
    }
  }, [])

  const theme = useTheme()
  const mediaquery = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <FormRightControls
      undoButtonComponent={
        undoButtonComponent ? (
          undoButtonComponent
        ) : hasPreviousStep ? (
          <Button className={classes.button} size="large" variant="outlined" onClick={history.goBack}>
            {translate('ra.action.back')}
          </Button>
        ) : undefined
      }
      saveButtonComponent={
        saveButtonComponent ? (
          saveButtonComponent
        ) : hasNextStep ? (
          <BaseButton
            color="primary"
            size={mediaquery ? undefined : 'xl'}
            variant="contained"
            fullWidth={mediaquery ? false : true}
            className={classes.createButton}
            onClick={handleGoForwardClick}
            endIcon={<HiArrowNarrowRight />}
            // component={NavLink}
            // to={hasNextStep && nextStepTo ? nextStepTo : ''}
            // disabled={!valid}
            disabled={invalid}
          >
            {translate('ra.action.nextStep')}
          </BaseButton>
        ) : (
          <SaveButton
            resource={resource}
            record={record}
            basePath={basePath}
            {...rest}
            variant="contained"
            onSuccess={handleSuccess}
            disabled={invalid}
          />
        )
      }
      asideUpperContent={asideUpperContent}
      asideLowerContent={asideLowerContent}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  aside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: theme.spacing(13),
    marginLeft: theme.spacing(6),
    padding: 0,
    maxWidth: 220,
  },
  createButton: {
    marginTop: theme.spacing(3),
    '@media (max-width: 960px)': {
      marginTop: 0,
    },
  },
  sideWrapper: {
    width: '100%',
  },
  button: {
    width: 'inherit',
    backgroundColor: theme.palette.background.default,
  },
  saveButton: {
    marginTop: theme.spacing(3),
  },
  spaceDivider: {
    height: theme.spacing(7),
  },
}))

export default WizardRightControls
