import React, { FC, useCallback, useState } from 'react'
import { IconButton, ListItemIcon, makeStyles, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'
import { useNotify, useRefresh, useTranslate } from 'ra-core'
import { Confirm } from 'ra-ui-materialui'
import inflection from 'inflection'
import { useMutation } from '@apollo/client'

type Props = {
  record?: any
  resource?: string
  onDeleteComplete: () => void
  deleteMutation: any
}
export const ActivityMoreMenu: FC<Props> = ({ deleteMutation, record = {}, resource = '', onDeleteComplete }) => {
  const classes = useStyles()

  const translate = useTranslate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()

  const [deleteActivity, { loading, error, data }] = useMutation(deleteMutation)

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const onItemDeleteClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      onMoreClose(event)
      setConfirmOpen(true)
    },
    [onMoreClose, setConfirmOpen]
  )

  const onConfirmDelete = useCallback(async () => {
    try {
      await deleteActivity({
        variables: {
          id: record.id,
        },
      })
      setConfirmOpen(false)
      // onDeleteComplete()
      refresh()
    } catch (e) {
      console.error('error deleting log', e)
      notify('error deleting log', 'error')
    }
  }, [refresh, deleteActivity, record, onDeleteComplete])

  const handleOpenConfirm = useCallback(() => {
    setConfirmOpen(true)
  }, [])

  const handleCloseConfirm = useCallback(() => {
    setConfirmOpen(false)
  }, [])

  return (
    <>
      <IconButton className={classes.iconMore} edge="start" onClick={onMoreClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`activity-more-menu-${record.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMoreClose}
      >
        <MenuItem onClick={onItemDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          {translate('ra.action.delete')}
        </MenuItem>
      </Menu>
      <Confirm
        isOpen={confirmOpen}
        loading={loading}
        title="ra.message.delete_title"
        content="ra.message.delete_content"
        ConfirmIcon={({ props, context }): string => {
          return ''
        }}
        CancelIcon={({ props, context }): string => {
          return ''
        }}
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1,
            _: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: inflection.singularize(`${resource}`),
              }),
              true
            ),
          }),
          id: record.id,
        }}
        onConfirm={onConfirmDelete}
        onClose={handleCloseConfirm}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  iconMore: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: 29,
    height: 33,
    borderRadius: theme.shape.borderRadius,
  },
}))
