import { useTranslate } from 'ra-core'
import React, { FC, useCallback } from 'react'
import { TextInput, ReferenceInput, AutocompleteInput, BooleanInput, FileInput, FileField } from 'react-admin'
import FormSection from '../../components/forms/FormSection'

type Props = {}

const DocumentForm: FC<Props> = (props) => {
  const translate = useTranslate()
  const filterToQuery = useCallback(() => (name: string): Record<string, string> => ({ name }), [])
  return (
    <FormSection title={translate('resources.Document.sections.general')} {...props}>
      <TextInput source="description" />

      <FileInput multiple={false} source="file" label="Related files">
        <FileField source="src" title="title" />
      </FileInput>
    </FormSection>
  )
}

export default DocumentForm
