/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Fade } from '@material-ui/core'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import SubMenu from './SubMenu'
import MenuItemLink from './MenuItemLink'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { IoIosAirplane, IoMdSettings, IoMdAirplane } from 'react-icons/io'
import AccountMoreMenu from '../AccountMoreMenu'
import { usePermissions, useRedirect, useTranslate } from 'ra-core'
import { GiPlaneWing, GiHelicopter, GiAirplane } from 'react-icons/gi'
import { BiCode } from 'react-icons/bi'

import {
  FaBuilding,
  FaLanguage,
  FaPlaneDeparture,
  FaUsers,
  FaUserTie,
  FaListOl,
  FaBusinessTime,
  FaEuroSign,
  FaLeaf,
  FaPercentage,
  FaChartLine,
  FaUserTag,
  FaUser,
  FaCalendar,
  FaCalendarAlt,
  FaBell,
} from 'react-icons/fa'
import { BsBuilding } from 'react-icons/bs'
import { AiOutlineSafety } from 'react-icons/ai'
import { GoTasklist } from 'react-icons/go'
import { HiTemplate } from 'react-icons/hi'
import { SiGmail } from 'react-icons/si'
import { canSeeDashboard } from '../../utils/hasPermission'
import { NotificationsPopover } from 'hns-react'
import { NotificationsTriggerButton } from './NotificationsTriggerButton'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 45,
    height: 45,
    transition: theme.transitions.create(['width', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderRadius: '4px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.secondary.dark,
  },
  closedAvatar: {
    width: 32,
    height: 32,
  },
  name: {
    transition: theme.transitions.create(['opacity', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  role: {
    transition: theme.transitions.create(['opacity', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuContainer: {
    overflowX: 'hidden',
    marginLeft: theme.spacing(3),
  },
  userContainer: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  closedSidebarUserInfo: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
  },
  menuTitle: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(5),
  },
  truncatedText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '125px',
  },
}))

interface Props {
  dense: boolean
  logout: () => void
  onMenuClick: () => void
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout, ...rest }) => {
  const classes = useStyles()
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen)
  const translate = useTranslate()
  const redirect = useRedirect()
  useSelector((state: any) => state.theme) // force rerender on theme change
  const avatarClassName = useMemo(() => clsx(classes.avatar, { [classes.closedAvatar]: !open }), [open])
  const usernameClassName = useMemo(() => clsx(classes.name, { [classes.closedSidebarUserInfo]: !open }), [open])
  const userRoleClassName = useMemo(() => clsx(classes.role, { [classes.closedSidebarUserInfo]: !open }), [open])
  const { pathname } = useLocation()
  const { loaded, permissions } = usePermissions()

  const handleNotificationClick = React.useCallback(
    (id: string, clickUrl?: string) => {
      if (clickUrl) {
        redirect(clickUrl.replace(window.location.origin, ''))
      }
    },
    [redirect]
  )

  // console.log('permissions?? ', hasPermission(permissions))

  return (
    <Fade in={loaded}>
      <Box height="100%" display="flex" flexDirection="column" displayPrint="none" mr={10}>
        <Hidden mdDown>
          <Box className={classes.userContainer} alignItems="center" display="flex" p={2}>
            <AccountMoreMenu logout={logout} />
            <Avatar
              variant="square"
              className={avatarClassName}
              src={'http://localhost:3000/static/images/avatars/avatar_6.png'}
            />
            <Box display="flex" flexDirection="column" ml={3} width="100%">
              <Typography className={clsx(usernameClassName, classes.truncatedText)} color="primary" variant="h5">
                {permissions && permissions.user && `${permissions.user.firstName} ${permissions.user.lastName}`}
              </Typography>

              <Typography color="textSecondary" variant="body2" className={userRoleClassName}>
                {permissions &&
                  permissions.user &&
                  permissions.user.roles &&
                  permissions.user.roles.length > 0 &&
                  `${permissions.user.roles[0].name}`}
              </Typography>
            </Box>
          </Box>
        </Hidden>
        {/* <Divider /> */}
        <Typography variant="h5" className={classes.menuTitle}>
          Menu
        </Typography>
        <Box className={classes.menuContainer}>
          <List>
            <NotificationsPopover
              onNotificationClick={handleNotificationClick}
              buttonComponent={<NotificationsTriggerButton leftIcon={FaBell} primaryText="Notifications" />}
            />
            {loaded && permissions && canSeeDashboard(permissions) && (
              <MenuItemLink
                to="/"
                primaryText="Dashboard"
                leftIcon={FaChartLine}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                isExactMatch
              />
            )}
            <MenuItemLink
              to="/Event"
              primaryText="Calendar"
              leftIcon={FaCalendarAlt}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
              isExactMatch
            />
            <MenuItemLink
              to={'/Lead'}
              primaryText={translate('resources.Lead.name', { smart_count: 2 })}
              leftIcon={IoMdAirplane}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            {/* <SubMenu sidebarIsOpen={open} name="menu.categories.clients" icon={FaUsers} dense={dense}> */}
            {/* </SubMenu> */}
            <SubMenu sidebarIsOpen={open} name="menu.categories.customers" icon={FaUsers} dense={dense}>
              <MenuItemLink
                to={'/Customer'}
                primaryText={translate('resources.Customer.all')}
                leftIcon={FaUsers}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/LeadClient'}
                primaryText={translate('resources.LeadClient.name', { smart_count: 2 })}
                leftIcon={FaUser}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/ProspectClient'}
                primaryText={translate('resources.ProspectClient.name', { smart_count: 2 })}
                leftIcon={FaUserTag}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/Client'}
                primaryText={translate('resources.Client.name', { smart_count: 2 })}
                leftIcon={FaUserTie}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            </SubMenu>
            <SubMenu sidebarIsOpen={open} name="menu.categories.offers" icon={FaBusinessTime} dense={dense}>
              <MenuItemLink
                to={'/Quote'}
                primaryText={translate('resources.Quote.name', { smart_count: 2 })}
                leftIcon={FaListOl}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/Order'}
                primaryText={translate('resources.Order.name', { smart_count: 2 })}
                leftIcon={GoTasklist}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/OrderRoute'}
                primaryText={translate('resources.OrderRoute.name', { smart_count: 2 })}
                leftIcon={GoTasklist}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/Passenger'}
                primaryText={translate('resources.Passenger.name', { smart_count: 2 })}
                leftIcon={FaUsers}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            </SubMenu>
            {/* <SubMenu sidebarIsOpen={open} name="menu.categories.organizations" icon={FaBuilding} dense={dense}>
            </SubMenu> */}
            <SubMenu sidebarIsOpen={open} name="menu.categories.vehicles" icon={IoIosAirplane} dense={dense}>
              <MenuItemLink
                to={'/Vehicle'}
                primaryText={translate('resources.Vehicle.name', { smart_count: 2 })}
                leftIcon={IoIosAirplane}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/VehicleModel'}
                primaryText={translate('resources.VehicleModel.name', { smart_count: 2 })}
                leftIcon={GiAirplane}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/VehicleManufacturer'}
                primaryText={translate('resources.VehicleManufacturer.name', { smart_count: 2 })}
                leftIcon={GiPlaneWing}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/VehicleCategory'}
                primaryText={translate('resources.VehicleCategory.name', { smart_count: 2 })}
                leftIcon={GiHelicopter}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/VehicleSafetyType'}
                primaryText={translate('resources.VehicleSafetyType.name', { smart_count: 2 })}
                leftIcon={AiOutlineSafety}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/VehicleCarbonOffset'}
                primaryText={translate('resources.VehicleCarbonOffset.name', { smart_count: 2 })}
                leftIcon={FaLeaf}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            </SubMenu>
            <MenuItemLink
              to={'/Airport'}
              primaryText={translate('resources.Airport.name', { smart_count: 2 })}
              leftIcon={FaPlaneDeparture}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <SubMenu sidebarIsOpen={open} name="menu.categories.settings" icon={IoMdSettings} dense={dense}>
              <MenuItemLink
                to={'/Organization'}
                primaryText={translate('resources.Organization.name', { smart_count: 2 })}
                leftIcon={BsBuilding}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to="/User"
                primaryText={translate('resources.User.name', { smart_count: 2 })}
                leftIcon={FaUserTie}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              {/* <MenuItemLink
                to={'/BankAccount'}
                primaryText={translate('resources.BankAccount.name', { smart_count: 2 })}
                leftIcon={GiMoneyStack}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              /> */}
              <MenuItemLink
                to={'/Language'}
                primaryText={translate('resources.Language.name', { smart_count: 2 })}
                leftIcon={FaLanguage}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/Currency'}
                primaryText={translate('resources.Currency.name', { smart_count: 2 })}
                leftIcon={FaEuroSign}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/OrderAdditionalInfoTemplate'}
                primaryText={translate('resources.OrderAdditionalInfoTemplate.name', { smart_count: 2 })}
                leftIcon={HiTemplate}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/Tax'}
                primaryText={translate('resources.Tax.name', { smart_count: 2 })}
                leftIcon={FaPercentage}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/gmail'}
                primaryText={translate('resources.GmailSettings.name', { smart_count: 2 })}
                leftIcon={SiGmail}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/GmailTemplate'}
                primaryText={translate('resources.GmailTemplate.name', { smart_count: 2 })}
                leftIcon={SiGmail}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={'/logs-console'}
                primaryText={'Logs Console'}
                leftIcon={BiCode}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            </SubMenu>
            {/* {hasPermission(permissions) && (
              <MenuItemLink
                to={'/Office'}
                primaryText="Punti Vendita"
                // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                leftIcon={FaStore}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {hasPermission(permissions) && (
              <MenuItemLink
                to={'/dashboard'}
                // primaryText={translate('menu.Dashboard', { smart_count: 2 })}
                primaryText="Dashboard"
                leftIcon={FaChartLine}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                isExactMatch
                // selected={pathname === '/Organization'}
              />
            )}
            <MenuItemLink
              to={'/Reservation'}
              // primaryText={translate('resources..name', { smart_count: 2 })}
              primaryText="Prenotazioni"
              leftIcon={FaFileAlt}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            {hasPermission(permissions) ? (
              <MenuItemLink
                to={'/ProductStockGrouped'}
                primaryText="Giacenze"
                // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                leftIcon={FaBox}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            ) : (
              <MenuItemLink
                to={'/OperatorProductStockGrouped'}
                primaryText="Giacenze"
                // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                leftIcon={FaBox}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {/* {hasPermission(permissions) && ( */}
            {/* <MenuItemLink
              to={'/ShortCollection'}
              primaryText="Short Collections"
              // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
              leftIcon={FaGifts}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            {/* )} */}
            {/* {hasPermission(permissions) && (
              <MenuItemLink
                to={'/Product'}
                primaryText="Prodotti"
                // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                leftIcon={FaShoppingBasket}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )} */}
            {/* {hasPermission(permissions) && (
              <MenuItemLink
                to={'/User'}
                primaryText="Utenti"
                // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                leftIcon={FaUsers}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )} */}
            {/* <MenuItemLink
              to={'/Customer'}
              primaryText="Clienti"
              // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
              leftIcon={FaUser}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            /> */}
            {/* REMOVED MENU SETTINGS  
            
              <MenuItemLink
              to={'/settings'}
              primaryText="Impostazioni"
              // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
              leftIcon={FaCogs}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
          /> */}
          </List>
        </Box>
      </Box>
    </Fade>
  )
}

const MenuAuthWrapper: FC<Props> = (props) => {
  const location = useLocation()

  if (location.pathname === '/callback') {
    return null
  }

  return <Menu {...props} />
}

export default MenuAuthWrapper
