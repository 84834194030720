import React, { FC, useCallback } from 'react'
import { FormDataConsumer } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { RAColorInput } from './RAColorInput'
import { useFormState } from 'react-final-form'

type InputColorProps = { source: string; [x: string]: any }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
  },
  colorBox: (props: any): any => ({
    width: '25px',
    height: '20px',
    backgroundColor:
      props.values && props.source && props.values[props.source] ? props.values[props.source] : undefined,
    marginRight: '5px',
    display: 'inline-block',
    content: '" "',
  }),
}))

const InputColor: FC<InputColorProps> = ({ source, ...rest }: InputColorProps) => {
  const { values } = useFormState()
  const classes = useStyles({ values, source })

  return (
    <RAColorInput
      source={source}
      record={values}
      picker="Sketch"
      inputProps={{
        variant: 'outlined',
        autoComplete: 'off',
        InputProps: {
          startAdornment: <div className={classes.colorBox} />,
          autoComplete: 'off',
        },
      }}
      {...rest}
    />
  )
}

export default InputColor
