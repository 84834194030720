import React, { FC } from 'react'
import { FieldProps, InjectedFieldProps } from '../utils/ra-types'
// import { makeStyles } from '@material-ui/core/styles'
import { ReferenceFieldController, useTranslate, useGetList } from 'ra-core'
import get from 'lodash/get'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Loading } from 'react-admin'
// import { Link } from 'react-router-dom'

type Props = FieldProps & InjectedFieldProps & {}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(0, 0.5, 3),
//   },
// }))

const AddressDetailsField: FC<Props> = ({ record }) => {
  // const classes = useStyles()
  const translate = useTranslate()

  const { loading, data, total } = useGetList(
    'Address',
    {
      page: 1,
      perPage: 25,
    },
    { field: 'id', order: 'DESC' },
    { customerId: record!.id }
  )

  if (loading || !data) {
    return <Loading />
  }

  if (total === 0) {
    return (
      <Typography variant="body2">
        {/* Nessun indirizzo presente, <Link to="/Address/create">clicca qui</Link> per crearlo */}
        {translate('resources.Customer.helpers.noAddress')}
      </Typography>
    )
  }

  const address = Object.values(data)[0]

  return (
    <Grid container spacing={1} direction="column">
      {/* <Typography variant="body2">{`${get(address, 'firstName')} ${get(address, 'lastName')}`}</Typography> */}
      <Typography variant="body2">{`${get(address, 'postCode')}, ${get(address, 'street')}`}</Typography>
      <Typography variant="body2">{get(address, 'city')}</Typography>

      <ReferenceFieldController
        resource="Address"
        basePath="/Address"
        record={address}
        link={false}
        reference="State"
        source="stateId"
      >
        {({ referenceRecord, ...props }: any): any =>
          referenceRecord ? <Typography variant="body2">{get(referenceRecord, 'name')}</Typography> : null
        }
      </ReferenceFieldController>

      <ReferenceFieldController
        resource="Address"
        basePath="/Address"
        record={address}
        link={false}
        reference="Country"
        source="countryId"
      >
        {({ referenceRecord, ...props }: any): any =>
          referenceRecord ? <Typography variant="body2">{get(referenceRecord, 'name')}</Typography> : null
        }
      </ReferenceFieldController>
    </Grid>
  )
}

export default AddressDetailsField
