import React, { FC } from 'react'
import Show from '../../components/details/Show'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Avatar, Button, Divider, makeStyles, Typography } from '@material-ui/core'
import { FaUsers } from 'react-icons/fa'
import BooleanStatusField from '../../components/BooleanStatusField'
import ToggleStatusButton from '../../components/button/ToggleStatusButton'

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    backgroundColor: '#eeeeee',
    color: 'rgba(51,51,51,0.5)',
    width: '60px',
    height: '60px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    marginRight: theme.spacing(3),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emailValue: {
    fontWeight: 'bold',
  },
  contactButton: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    backgroundColor: '#eeeeee',
  },
  card: {
    width: '40%', // TODO: DELETE ME
  },
}))

const TeamMemberShowCard = (props: any): any => {
  const classes = useStyles()

  return props.record ? (
    <Card className={classes.card}>
      <CardContent className={classes.row}>
        <Avatar variant="rounded" className={classes.userAvatar}>
          <FaUsers size={30} />
        </Avatar>
        <Typography variant="h4">{`${props.record.firstName} ${props.record.lastName}`}</Typography>
      </CardContent>
      <Divider />
      <CardContent className={classes.rowSpaceBetween}>
        <div>
          <Typography variant="body1">Email:</Typography>
          <Typography variant="body1" className={classes.emailValue}>
            {props.record.email}
          </Typography>
        </div>
        <Button className={classes.contactButton}>CONTATTA</Button>
      </CardContent>
      <Divider />
      <CardContent className={classes.row}>
        <div>
          <Typography variant="body1">Ruolo:</Typography>
          <Typography variant="body1" className={classes.emailValue}>
            // dato mancante
          </Typography>
        </div>
      </CardContent>
      <Divider />
      <CardContent className={classes.rowSpaceBetween}>
        <div>
          <Typography variant="body1">Status:</Typography>
          <BooleanStatusField source="approved" record={props.record} />
        </div>
        <ToggleStatusButton record={props.record} resource={props.resource} source="approved" />
      </CardContent>
    </Card>
  ) : null
}

const TeamMemberShow: FC<any> = (props) => {
  return (
    <Show {...props}>
      <TeamMemberShowCard {...props} />
    </Show>
  )
}

export default TeamMemberShow
