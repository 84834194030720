import React, { FC } from 'react'
import { AutocompleteInput, required } from 'react-admin'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AutocompleteInputInDrawer from './AutocompleteInDrawer'

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    width: '50%',
    alignSelf: 'stretch',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  helper: {
    width: '50%',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: -20,
      marginLeft: theme.spacing(2),
    },
  },
  input: {
    width: '100%',
  },
}))

type Props = {
  [x: string]: any
}

const FormAutoCompleteInput: FC<Props> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMdMediaquery = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputWrapper}>
        {props.inDrawer ? (
          <AutocompleteInputInDrawer
            {...props}
            className={classes.input}
            helperText={isMdMediaquery ? undefined : props.helperText}
            validate={props.required ? required() : undefined}
          />
        ) : (
          <AutocompleteInput
            {...props}
            className={classes.input}
            helperText={isMdMediaquery ? undefined : props.helperText}
            validate={props.required ? required() : undefined}
          />
        )}
      </div>
      {(isMdMediaquery || (props.helperText && typeof props.helperText !== 'string')) && (
        <Typography className={classes.helper} variant="body2">
          {props.helperText}
        </Typography>
      )}
    </div>
  )
}

export default FormAutoCompleteInput
