import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import SwitchCardInput from '../../components/SwitchCardInput'
import { RadioButtonGroupInput } from 'react-admin'
import FormSwitchInput from '../../components/forms/FormSwitchInput'
import HiddenOrganizationField from '../../components/forms/HiddenOrganizationField'
import FormSelectInput from '../../components/input/FormSelectInput'
import { ROLEPARAMTYPECHOICES } from './RoleParamTypeEnum'

type Props = {
  [x: string]: any
}

const RoleCreate: FC<Props> = (props) => {
  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="list" title="Nuovo Ruolo">
        <FormSection title="Parametro">
          <FormTextInput {...props} source="name" helperText="Nome del parametro" />
          <FormSelectInput {...props} source="type" helperText="Tipologia" choices={ROLEPARAMTYPECHOICES} />
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default RoleCreate
