import { makeStyles } from '@material-ui/core/styles'

export const useCalendarPlanningStyles = makeStyles((theme) => ({
  '@global': {
    '.fc-event-main': {
      padding: '0px !important',
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 'calc(100% + 40px)',
    // paddingLeft: theme.spacing(2),
  },
  container: {
    height: 'calc(100vh - 64px)',
  },
  calendarFastEventTemplatesContainer: (props: any): any => ({
    height: '100%',
    overflow: 'auto',
    // width: !props.fastEventsOpen ? 0 : undefined,
    flexBasis: !props.fastEventsOpen ? 0 : undefined,
    opacity: !props.fastEventsOpen ? 0 : undefined,
    marginLeft: !props.fastEventsOpen ? '-10px' : undefined,
    // marginRight: !props.fastEventsOpen ? '-8px' : undefined,
    transition: theme.transitions.create('flex-basis', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  calendarSectionContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    transition: theme.transitions.create('width'),
  },
  calendarContainer: {
    height: '100%',
    paddingRight: theme.spacing(1),
  },
  oneDayDate: {
    display: 'none',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  fcEvent: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    cursor: 'pointer',
    boxShadow: theme.shadows[4],
  },
  dayHeaderContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  activeDayLabel: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  dayOfWeekText: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.caption.fontSize,
  },
  dayIconButton: {
    width: 36,
    height: 36,
    marginTop: theme.spacing(0.5),
  },
  dayIconText: {
    fontSize: 18,
  },
  fcSlotLabel: {
    borderBottom: '1px solid transparent !important',
  },
  fcSlotLane: {
    // borderBottom: '1px solid transparent !important',
  },
  fcCalendarDayHeader: {
    borderBottom: `1px solid ${theme.palette.divider} !important`,
  },
  hourSlotLabelContent: {
    position: 'relative',
    width: theme.spacing(2) + 25,
    height: '1.25rem',
    // height: 20,
  },
  hourSlotLabel: {
    position: 'absolute',
    top: '-0.75rem',
    fontSize: '14px',
    lineHeight: '1.5rem',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  eventContainer: {
    display: 'flex',
    // flexDirection: 'column',
    height: '100%',
    width: '100%',
    flexWrap: 'wrap',
  },
  eventTopContainer: {
    marginRight: theme.spacing(1),
  },
  eventTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(0.5),
    fontSize: '0.730rem',
    textTransform: 'uppercase',
  },
  eventTimeText: {
    fontSize: '0.650rem',
    lineHeight: '1rem',
    marginLeft: theme.spacing(0.5),
    letterSpacing: 'normal',
  },
}))
