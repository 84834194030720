import React, { FC } from 'react'
import { TextField } from 'react-admin'
import { Record as RARecord } from 'ra-core'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/details/Edit'
import TaxForm from './TaxForm'

type Props = {}

const TaxEdit: FC<Props> = (props) => {
  const transform = (data: RARecord): RARecord => ({ ...data, isActive: true })

  return (
    <Edit {...props} component="div" transform={transform}>
      <SectionedForm variant="outlined">
        <FormSection title="ID">
          <TextField source="id" addLabel={false} />
        </FormSection>
        <TaxForm />
      </SectionedForm>
    </Edit>
  )
}

export default TaxEdit
