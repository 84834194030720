import React, { FC, useCallback } from 'react'
import { InputProps, useInput } from 'ra-core'
import { useFormState } from 'react-final-form'
import MuiCurrencyInput from '../MuiCurrencyInput'

type Props = {
  //
} & InputProps

export const CurrencyFinalFormInput: FC<Props> = (props) => {
  const {
    input: { name, onChange, value, ...rest },
    meta: { active },
  } = useInput({ ...props })

  const { values } = useFormState()

  const handleChange = useCallback(
    (event: any, rawValue: number, maskedValue: string) => {
      // onChange(rawValue)
      onChange(maskedValue)
    },
    [onChange]
  )

  return (
    <MuiCurrencyInput
      currencyId={values.currencyId}
      {...rest}
      {...props}
      name={name}
      onChange={handleChange}
      value={value}
    />
  )
}
