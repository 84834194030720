import React, { FC, useCallback } from 'react'
import { TextField, NumberField, DateField } from 'react-admin'
import RAList from '../../components/list/List'
import Datagrid from '../../components/Datagrid'
import GenericMoreMenu from '../../components/GenericMoreMenu'
import { IoMdAirplane } from 'react-icons/io'
import { Route, Switch, useHistory } from 'react-router-dom'
import LeadShow from './LeadShow'
import { makeStyles } from '@material-ui/core'
import Drawer from '../../components/Drawer'
import LeadFilters from './LeadFilters'

const useStyles = makeStyles((theme: any) => ({
  export: {
    marginLeft: theme.spacing(3),
  },
  drawerContent: {
    width: '50vw',
    minWidth: 300,
    // eslint-disable-next-line quotes
    content: "''",
  },
}))

type Props = {
  [x: string]: any
}

const LeadList: FC<Props> = (props) => {
  const history = useHistory()
  const handleDrawerClose = useCallback(() => history.push('/Lead'), [])

  return (
    <>
      <RAList
        {...props}
        titleIcon={<IoMdAirplane />}
        filters={<LeadFilters />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
      >
        <Datagrid rowClick="show">
          <NumberField source="id" />
          <DateField source="createdAt" showTime options={{ dateStyle: 'short', timeStyle: 'short' }} />
          <TextField source="leadType" />
          <TextField source="email" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="phone" />
          <GenericMoreMenu disableEdit />
        </Datagrid>
      </RAList>

      <Switch>
        <Route exact path="/Lead/:id/show">
          {({ match }): React.ReactNode => (
            <Drawer open={true} onClose={handleDrawerClose}>
              <LeadShow
                id={match && match.params ? match.params.id : undefined}
                onCancel={handleDrawerClose}
                {...props}
              />
            </Drawer>
          )}
        </Route>
      </Switch>
    </>
  )
}

export default LeadList
