import React, { FC, useCallback } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField, Datagrid, EditButton, BooleanField, FunctionField } from 'react-admin'
import EmailTemplateFilters from './EmailTemplateFilters'
import { ColorField } from 'react-admin-color-input'

const sort = { field: 'id', order: 'DESC' }
// const filterDefaultValues = { active: true }

type Props = {
  //
}

const EmailTemplateList: FC<Props> = (props) => {
  return (
    <RAList {...{ sort }} {...props} filters={<EmailTemplateFilters />}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="name" />
        <EditButton />
      </Datagrid>
    </RAList>
  )
}

export default EmailTemplateList
