import React, { FC } from 'react'
import { TextInput, NumberInput } from 'react-admin'
import FormSection from '../../../components/forms/FormSection'

type Props = {}

const VehicleCarbonOffsetForm: FC<Props> = (props) => (
  <FormSection title="Carbon Offset category" {...props}>
    <TextInput source="name" />
    <NumberInput source="value" />
  </FormSection>
)

export default VehicleCarbonOffsetForm
