import { makeStyles, Paper, Typography } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import groupByDay, { sortOrderEventByDate } from '../../utils/groupByDays'
import { ActivityItem } from './ActivityItem'
import clsx from 'clsx'
import { CustomerMailItemProps } from '../gmail/CustomerMailItem'

const getDayString = (date: string): string =>
  new Date(date).toLocaleDateString(undefined, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

type Props = {
  data: any
  loading: boolean
  onDeleteComplete: () => void
  deleteMutation: any
} & Partial<Omit<CustomerMailItemProps, 'simpleMail'>>

export const ActivitiesList: FC<Props> = ({ data, loading, onDeleteComplete, deleteMutation, ...rest }) => {
  const classes = useStyles()
  const { days, eventsByDay } = useMemo(() => {
    if (!loading && data.length > 0) {
      return groupByDay(data)
    }

    return { days: [], eventsByDay: {} }
  }, [data])

  return (
    <div className={classes.root}>
      {days.map((day, dayIndex) => (
        <React.Fragment key={`events-day-${dayIndex}`}>
          <Typography variant="subtitle1" className={classes.timelineDate}>
            {getDayString(day)}
          </Typography>
          <Paper className={clsx(classes.timelineItems)}>
            {eventsByDay[day].sort(sortOrderEventByDate).map((activity, eventIndex) => (
              <ActivityItem
                onDeleteComplete={onDeleteComplete}
                key={`event-${dayIndex}-${eventIndex}`}
                activity={activity}
                deleteMutation={deleteMutation}
                {...rest}
              />
            ))}
          </Paper>
        </React.Fragment>
      ))}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    minHeight: '350px',
  },
  timelineItems: {
    margin: theme.spacing(0, 3, 2),
    padding: theme.spacing(1, 1),
  },
  timelineDate: {
    margin: theme.spacing(2, 3),
  },
}))
