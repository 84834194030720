import React, { FC, useCallback, useEffect, useState } from 'react'
import { TextField, ReferenceField } from 'react-admin'
import { useQuery, useMutation } from '@apollo/client'
import { MUTATION_UPDATE_ORDER_CHECKLIST_ITEM, QUERY_ORDER_CHECKLIST_GROUPS } from '../../queries'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { InfoCard, InfoCardItem, InfoCardTypography } from '../../components/InfoCard'
import { makeStyles } from '@material-ui/core/styles'
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri'
import { useNotify } from 'ra-core'
import UploadMediaButton from '../../components/UploadMediaButton'
import DownloadDocumentButton from '../../components/DownloadDocumentButton'
import DeleteDocumentButton from '../../components/DeleteDocumentButton'

function items_sort_asc(e1: any, e2: any): any {
  if (e1.itemPosition > e2.itemPosition) return 1
  if (e1.itemPosition < e2.itemPosition) return -1
  return 0
}

type GroupProps = {
  orderId: number
}

type ItemProps = {
  id: number
  name: string
  done: boolean
  itemPosition: number
  hasDocument: boolean
  documentId: number
  refetch: () => any
}

const OrderChecklistItem: FC<ItemProps> = (props) => {
  const classes = useItemStyles(props)
  const notify = useNotify()
  const [updateItem] = useMutation(MUTATION_UPDATE_ORDER_CHECKLIST_ITEM)

  const onCheckClick = useCallback(async () => {
    try {
      const updateResult = await updateItem({
        variables: {
          id: props.id,
          data: { done: !props.done },
        },
      })
      if (updateResult && updateResult.data && !updateResult.errors) {
        notify('ra.notify.updated')
        props.refetch()
      }
    } catch (err) {
      console.error('Error updating element, ', err.message)
      notify(err.message, 'error')
    }
  }, [props])

  const onDocumentChanged = useCallback(
    async (data: any) => {
      try {
        const updateResult = await updateItem({
          variables: {
            id: props.id,
            data: { documentId: data.id },
          },
        })
        if (updateResult && updateResult.data && !updateResult.errors) {
          notify('ra.notify.updated')
          props.refetch()
        }
      } catch (err) {
        console.error('Error updating element, ', err.message)
        notify(err.message, 'error')
      }
    },
    [props]
  )

  return (
    <div className={classes.item}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onCheckClick}>
          {props.done ? <RiCheckboxCircleFill color="green" /> : <RiCheckboxBlankCircleLine />}
        </IconButton>
        <Box>
          <Typography>{props.name}</Typography>
          {props.documentId && (
            <Box display="flex" alignItems="center">
              <ReferenceField
                record={props}
                source="documentId"
                resource="Document"
                basePath="/Order"
                link={false}
                reference="Document"
              >
                <TextField source="description" variant="caption" color="primary" />
              </ReferenceField>
              <DeleteDocumentButton id={props.documentId} onDocumentDeleted={onDocumentChanged} />
            </Box>
          )}
        </Box>
      </span>
      {props.hasDocument &&
        (props.documentId ? (
          <DownloadDocumentButton id={props.documentId} />
        ) : (
          <UploadMediaButton onSuccess={onDocumentChanged} />
        ))}
    </div>
  )
}

const OrderChecklistGroups: FC<GroupProps> = ({ orderId }) => {
  const classes = useStyles()
  const { loading, error, data, refetch } = useQuery(QUERY_ORDER_CHECKLIST_GROUPS, {
    variables: { filters: { orderId } },
  })

  const [sortedData, setSortedData] = useState<any[]>([])
  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    if (
      !loading &&
      !error &&
      data &&
      data.orderChecklistGroups &&
      data.orderChecklistGroups.data &&
      Array.isArray(data.orderChecklistGroups.data)
    ) {
      const newData = [...data.orderChecklistGroups.data]
      newData.sort(items_sort_asc)
      setSortedData(newData)
      setReady(true)
    }
  }, [loading, error, data])

  return (
    <InfoCard>
      {ready ? (
        sortedData.map((group: any) => (
          <Accordion
            elevation={0}
            key={`accordion-checklist-${group.id}`}
            classes={{ expanded: classes.infoCardAccordionSummaryContent }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id={`accordion-checklist-summary-${group.id}`}
              classes={{
                root: classes.infoCardAccordionSummaryRoot,
                content: classes.infoCardAccordionSummaryContent,
              }}
            >
              <InfoCardItem>
                <InfoCardTypography variant="value">{group.name}</InfoCardTypography>
              </InfoCardItem>
            </AccordionSummary>
            <AccordionDetails className={classes.infoCardAccordionDetail}>
              {group.items &&
                Array.isArray(group.items) &&
                group.items.map((item: any) => (
                  <OrderChecklistItem key={`accordion-checklist-item-${item.id}`} {...item} refetch={refetch} />
                ))}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <LinearProgress />
      )}
    </InfoCard>
  )
}

const useStyles = makeStyles((theme) => ({
  infoCardAccordionSummaryRoot: {
    paddingLeft: 0,
  },
  infoCardAccordionSummaryContent: {
    margin: '0 !important',
  },
  infoCardAccordionDetail: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const useItemStyles = makeStyles((theme) => ({
  item: {
    order: (props: ItemProps): number => props.itemPosition,
    display: 'flex',
    justifyContent: (props: ItemProps): string => (props.hasDocument ? 'space-between' : 'flex-start'),
    alignItems: 'center',

    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

export default OrderChecklistGroups
