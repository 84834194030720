import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core'
import gql from 'graphql-tag'
import { useQueryWithStore } from 'ra-core'
import React, { FC, useCallback, useMemo } from 'react'
import { LinearProgress } from 'react-admin'

const QUERY_GET_GALLERY_PICTURES = gql`
  query GetGalleryPictures($id: ID!) {
    gallery(id: $id) {
      id
      name
      pictures {
        id
        description
        urlSmall
        urlMedium
        urlLarge
        order
      }
    }
  }
`

type Props = {
  record?: Record<string, any>
  source: string
}

const GalleryImagesField: FC<Props> = ({ record = {}, source }) => {
  const classes = useStyles()
  const { loading, data, error } = useQuery(QUERY_GET_GALLERY_PICTURES, {
    skip: !record || !source || !record[source],
    variables: {
      id: record[source],
    },
  })

  const toShowImages = useMemo(() => {
    if (data && data.gallery && data.gallery.pictures) {
      return data.gallery.pictures.slice(0, 2)
    }

    return []
  }, [data])

  if (loading) {
    return <LinearProgress />
  }

  return (
    <div className={classes.root}>
      {toShowImages.map(
        (item: any, index: number): React.ReactNode => {
          return (
            <img
              className={classes.img}
              key={`gallery-image-${item.id}`}
              alt={item.description || item.name}
              src={item.urlMedium}
            />
          )
        }
      )}
    </div>
  )
}

export default GalleryImagesField

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  img: {
    height: 40,
    maxWidth: 50,
    marginLeft: theme.spacing(2),
    borderRadius: '4px',
  },
}))
