import React, { FC, useCallback } from 'react'
import { motion, Transition } from 'framer-motion'
import { Avatar, AvatarProps, Tooltip } from '@chakra-ui/react'

const MotionAvatar = motion(Avatar)

const animationTransition: Transition = {
  type: 'spring',
  stiffness: 400,
  damping: 30,
}

export type AvatarFilterProps = {
  id: string | number
  isSelected?: boolean
  data?: Record<string, any>
} & AvatarProps

export const AvatarFilter: FC<AvatarFilterProps> = ({ isSelected, id, data, ...props }) => {
  const handleKeyUp = useCallback(
    (e) => {
      if (e.keyCode === 32 || e.keyCode === 13) {
        e.preventDefault()
        if (props.onClick) {
          props.onClick(e)
        }
      }
    },
    [props.onClick, id]
  )

  return (
    <Tooltip label={props.name}>
      <MotionAvatar
        _hover={{
          borderColor: 'blue.500',
          borderWidth: '3px',
        }}
        borderWidth={isSelected ? '3px' : undefined}
        borderColor={isSelected ? 'blue.500' : undefined}
        role="button"
        tabIndex={0}
        layout
        transition={animationTransition}
        onKeyUp={handleKeyUp}
        {...props}
      />
    </Tooltip>
  )
}
