import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { FaUsers } from 'react-icons/fa'
import BooleanStatusField from '../../components/BooleanStatusField'
import ToggleStatusButton from '../../components/button/ToggleStatusButton'
import { ReferenceField, TextField, ReferenceManyField } from 'react-admin'
import { useDataProvider, useNotify, useTranslate, useVersion, useLocale } from 'ra-core'
import ShowView from '../../components/details/ShowView'
import Datagrid from '../../components/Datagrid'
import { FunctionField } from 'ra-ui-materialui'
import Pagination from '../../components/list/pagination/Pagination'
import Loading from '../../components/Loading'
import Empty from '../../components/list/Empty'
import { InfoCard, InfoCardItem, InfoCardWrapper, InfoCardTypography, InfoCardAvatar } from '../../components/InfoCard'
import { formatDistanceToNow } from 'date-fns'
import { it } from 'date-fns/locale'
import UserActionsTimeline from '../../components/UserActionsTimeline'

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    backgroundColor: '#eeeeee',
    color: 'rgba(51,51,51,0.5)',
    width: '60px',
    height: '60px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    marginRight: theme.spacing(3),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emailValue: {
    fontWeight: 'bold',
  },
  userCard: {
    flexBasis: '50%',
    maxWidth: 375,
    position: 'sticky',
    top: theme.spacing(3),
  },
  cardValue: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  contentContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-start',
  },
  userLogsContainer: {
    flex: 1,
    marginLeft: theme.spacing(3),
    //    overflow: 'scroll',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    alignSelf: 'flex-start',
  },
}))

const UserShow: FC<any> = (props): any => {
  const locale = useLocale()
  const translate = useTranslate()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const layoutRecord = useMemo(() => ({ id: props.id }), [props.id])
  const [loading, setLoading] = useState<boolean>(true)
  const [loaded, setLoaded] = useState<boolean>(true)
  const [user, setUser] = useState<any>(undefined)
  const [teamMember, setTeamMember] = useState<any>(undefined)
  const layoutTitle = useMemo(() => (user && user.fullName ? user.fullName : 'Utente'), [user])
  const notify = useNotify()
  const version = useVersion()

  console.log('locale??? ', locale)

  const fetchData = useCallback(async () => {
    if (!loaded) {
      setLoading(true)
    }
    try {
      const userResult = await dataProvider.getOne('User', {
        id: props.id,
      })

      if (!userResult.data || !userResult.data.id) {
        throw new Error('User not found')
      }
      setUser(userResult.data)

      const teamMemberResults = await dataProvider.getList('TeamMember', {
        sort: {
          field: 'id',
          order: 'ASC',
        },
        pagination: {
          perPage: 1,
          page: 1,
        },
        filter: {
          accountId: userResult.data.id,
        },
      })

      if (teamMemberResults.data && teamMemberResults.data.length > 0) {
        setTeamMember(teamMemberResults.data[0])
      }
      setLoaded(true)
    } catch (e) {
      notify('ra.message.error')
    } finally {
      setLoading(false)
    }
  }, [props.id, loaded])

  useEffect(() => {
    fetchData()
  }, [version])

  return (
    <ShowView {...props} record={layoutRecord} title={layoutTitle} loaded={!loading && user}>
      {loading || !user ? (
        <Loading />
      ) : (
        <div className={classes.contentContainer}>
          <InfoCardWrapper>
            <InfoCard>
              <InfoCardItem direction="row">
                <InfoCardAvatar>
                  <FaUsers size={20} />
                </InfoCardAvatar>
                <InfoCardTypography variant="title">{`${user.fullName}`}</InfoCardTypography>
              </InfoCardItem>
              <InfoCardItem>
                <InfoCardTypography variant="label">
                  {translate('resources.User.fields.organizationId')}
                </InfoCardTypography>
                <ReferenceField
                  {...props}
                  record={teamMember}
                  source="organizationId"
                  reference="Organization"
                  link={false}
                >
                  <TextField source="name" variant="body1" />
                </ReferenceField>
              </InfoCardItem>
              <InfoCardItem>
                <InfoCardTypography variant="label">{translate('resources.User.fields.email')}</InfoCardTypography>
                <InfoCardTypography variant="value">{user.email}</InfoCardTypography>
              </InfoCardItem>
              <InfoCardItem>
                <InfoCardTypography variant="label">{translate('resources.User.fields.roleId')}</InfoCardTypography>
                {user && user.roles && user.roles.length > 0 ? (
                  <ReferenceField {...props} link={false} reference="Role" record={user.roles[0]} source="id">
                    <TextField className={classes.cardValue} variant="body1" source="name" />
                  </ReferenceField>
                ) : (
                  <InfoCardTypography variant="value" color="error">
                    {translate('resources.User.fields.emptyRole')}
                  </InfoCardTypography>
                )}
              </InfoCardItem>
              <InfoCardItem direction="row" justifyContent="space-between">
                <div>
                  <InfoCardTypography variant="label">{translate('status.name')}</InfoCardTypography>
                  <InfoCardTypography variant="value">
                    <BooleanStatusField className={classes.cardValue} variant="body1" source="active" record={user} />
                  </InfoCardTypography>
                </div>
                <ToggleStatusButton record={user} resource={props.resource} source="active" />
              </InfoCardItem>
            </InfoCard>
          </InfoCardWrapper>

          {/* <Box className={classes.userLogsContainer}>
            <ReferenceManyField
              {...props}
              pagination={<Pagination />}
              record={user}
              reference="ServiceLog"
              target="userId"
              sort={{ field: 'createdAt', order: 'DESC' }}
            >
              <Datagrid emptyComponent={<Empty buttonComponent={<></>} />}>
                <TextField source="id" />
                <TextField source="action" />
                <FunctionField
                  source="createdAt"
                  render={(record: any): React.ReactNode =>
                    formatDistanceToNow(new Date(record.createdAt), {
                      addSuffix: true,
                      locale: locale === 'it' ? it : undefined,
                    })
                  }
                />
              </Datagrid>
            </ReferenceManyField>
          </Box> */}
          <UserActionsTimeline userId={props.id} />
        </div>
      )}
    </ShowView>
  )
}

export default UserShow
