import React, { FC, useCallback } from 'react'
import { useTranslate } from 'ra-core'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/details/Edit'
import FormTextInput from '../../components/FormTextInput'
import FormLangWithFlagInput from '../../components/input/FormLangWithFlagInput'
// import FormSwitchInput from '../../components/forms/FormSwitchInput'

type Props = {
  [x: string]: any
}

const LanguageEdit: FC<Props> = (props) => {
  const translate = useTranslate()

  const transform = useCallback((data) => ({ ...data, active: true }), [])

  return (
    <Edit {...props} component="div" transform={transform}>
      <SectionedForm variant="outlined" redirect="list" title={translate('resources.Language.titles.create')}>
        <FormSection title={translate('resources.Language.titles.formSection')}>
          <FormTextInput source="name" required />
          <FormLangWithFlagInput source="isoCode" required />
          <FormTextInput source="nativeName" />
          {/* <FormSwitchInput source="active" /> */}
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default LanguageEdit
