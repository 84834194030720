import * as React from 'react'
import { EditContextProvider, useCheckMinimumRequiredProps, useEditController } from 'ra-core'
import { EditView } from './EditView'
import { EditProps } from 'ra-ui-materialui'
import { FC } from 'react'

/**
 * Page component for the Edit view
 *
 * The `<Edit>` component renders the page title and actions,
 * fetches the record from the data provider.
 * It is not responsible for rendering the actual form -
 * that's the job of its child component (usually `<SimpleForm>`),
 * to which it passes pass the `record` as prop.
 *
 * The <Edit> component accepts the following props:
 *
 * - actions
 * - aside
 * - component
 * - successMessage
 * - title
 * - undoable
 *
 * @example
 *
 * // in src/posts.js
 * import * as React from "react";
 * import { Edit, SimpleForm, TextInput } from 'react-admin';
 *
 * export const PostEdit = (props) => (
 *     <Edit {...props}>
 *         <SimpleForm>
 *             <TextInput source="title" />
 *         </SimpleForm>
 *     </Edit>
 * );
 *
 * // in src/App.js
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 *
 * import { PostEdit } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={...}>
 *         <Resource name="posts" edit={PostEdit} />
 *     </Admin>
 * );
 * export default App;
 */
const Edit: FC<EditProps> = (props): JSX.Element => {
  useCheckMinimumRequiredProps('Edit', ['children'], props)
  const controllerProps = useEditController(props)
  return (
    <EditContextProvider value={controllerProps}>
      <EditView {...props} {...controllerProps} />
    </EditContextProvider>
  )
}

export default Edit
