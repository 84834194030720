import { makeStyles } from '@material-ui/core'

export const useInfoCardStyles = makeStyles((theme) => ({
  wrapper: {
    // width: 300,
    maxWidth: 290,
    minWidth: 250,
    position: 'sticky',
    // top: theme.spacing(6),
    top: '54px',
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
      position: 'relative',
      top: '0',
      // marginTop: theme.spacing(6),
    },
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    '& $cardItem:not(:first-child)': {
      borderTop: '1px solid',
      borderTopColor: theme.palette.grey[200],
    },
  },
  cardItem: {
    flex: 1,
    display: 'flex',
    '&.row': {},
    '&.column': {
      alignItems: 'flex-start',
    },
  },
  avatar: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.secondary.dark,
    width: '45px',
    height: '45px',
    boxShadow: theme.shadows[1],
    marginRight: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
  },
  typoLabel: { ...theme.typography.body2, fontWeight: 'lighter' },
  typoValue: { ...theme.typography.body1, fontWeight: 'bold' },
  typoTitle: { ...theme.typography.h4, fontWeight: 'bold' },
  typoCaption: { ...theme.typography.caption, fontWeight: 'normal' },
}))
