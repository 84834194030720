import React, { FC } from 'react'
import { useGetList } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

const ClientTotalQuotesField: FC<any> = ({ customerId }) => {
  const classes = useStyles()
  const { loading, error, total } = useGetList(
    'Quote',
    { page: 1, perPage: 10000 },
    { field: 'id', order: 'DESC' },
    { customerId },
    { enabled: !!customerId }
  )

  if (loading) return <CircularProgress size={20} />
  if (error) return <ErrorIcon color="error" />
  if (total || total === 0) {
    return (
      <Button
        disabled={total === 0}
        color="primary"
        variant="text"
        component={Link}
        to={{
          pathname: '/Quote',
          search: `filter=${JSON.stringify({ customerId })}`,
        }}
        classes={{ root: classes.button, text: total > 0 ? classes.text : undefined, disabled: classes.disabled }}
      >
        <h3>{total}</h3>
      </Button>
    )
  }
  return null
}

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 0,
  },
  text: {
    textDecoration: 'underline',
  },
  disabled: {
    color: 'black !important',
  },
}))

export default ClientTotalQuotesField
