import React, { FC } from 'react'
import { useGetMany } from 'ra-core'
import { ReferenceField } from 'react-admin'
import LinearProgress from '@material-ui/core/LinearProgress'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'
import ClientNameField from './ClientNameField'

type Props = {
  record?: Record<string, any>
  departureSource?: string
  arrivalSource?: string
  clientSource?: string
  label?: string
}

const SimpleOrderRouteWithClientField: FC<Props> = ({
  record = {},
  departureSource = 'departureAirportId',
  arrivalSource = 'arrivalAirportId',
  clientSource = 'customerId',
  ...props
}) => {
  const { loading, error, data } = useGetMany('Airport', [record[departureSource], record[arrivalSource]])

  if (error || !record || !record[departureSource] || !record[arrivalSource] || data.length !== 2) {
    return <ErrorIcon htmlColor="darkred" />
  }

  if (loading) {
    return <LinearProgress />
  }

  if (data && Array.isArray(data) && data.length === 2) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Chip
          color="primary"
          size="small"
          label={`${data[0].iataCode || data[0].icaoCode} - ${data[1].iataCode || data[1].icaoCode}`}
        />
        <span>&nbsp;</span>
        <span style={{ fontSize: '1rem' }}>{'with'}</span>
        <span>&nbsp;</span>
        <ReferenceField {...props} record={record} source="orderId" reference="Order" link={false}>
          <ReferenceField source={`${clientSource}`} reference="Customer" link={false}>
            <ClientNameField />
          </ReferenceField>
        </ReferenceField>
      </div>
    )
  }
  return null
}

export default SimpleOrderRouteWithClientField
