import React, { FC, useCallback } from 'react'
import { Create } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import FormSelectInput from '../../components/input/FormSelectInput'

type Props = {}

export enum GMAIL_TEMPLATE_CATEGORIES {
  quote = 'quote',
  contract = 'contract',
  brief = 'brief',
  // bankAccountDetails = 'Bank account details',
}

export const GMAILTEMPLATESELECTCATEGORIES = [
  { id: GMAIL_TEMPLATE_CATEGORIES.quote, name: GMAIL_TEMPLATE_CATEGORIES.quote },
  { id: GMAIL_TEMPLATE_CATEGORIES.contract, name: GMAIL_TEMPLATE_CATEGORIES.contract },
  { id: GMAIL_TEMPLATE_CATEGORIES.brief, name: GMAIL_TEMPLATE_CATEGORIES.brief },
]

const GmailTemplateCreate: FC<Props> = (props) => {
  const transform = useCallback((data) => ({ ...data, values: [] }), [])

  return (
    <Create {...props} component="div" transform={transform}>
      <SectionedForm variant="outlined" redirect="show" title="Create template">
        <FormSection title="Template info">
          <FormTextInput source="name" required />
          <FormSelectInput source="category" choices={GMAILTEMPLATESELECTCATEGORIES} />
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default GmailTemplateCreate
