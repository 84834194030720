import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import MuiAppBar, { AppBarProps } from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import Hidden from '@material-ui/core/Hidden'
import { toggleSidebar } from 'ra-core'
import { UserMenu as DefaultUserMenu } from 'react-admin'
import clsx from 'clsx'

import Avatar from '@material-ui/core/Avatar'
import MobileUserMenu from './MobileUserMenu'

// const TRANSACTIONS_REQUEST_SUBSCRIPTION = gql`
//   subscription TransactionRequest {
//     incomingPayment {
//       id
//       rejectMessage
//     }
//   }
// `

export const APPBAR_HEIGHT = 77

type Props = AppBarProps & {
  children?: React.ReactNode
  logout?: React.ReactElement
  open?: boolean
  userMenu?: React.ReactElement
}

const AppBar: FC<Props> = (props) => {
  const { children, classes: classesOverride, className, logout, open, userMenu = <DefaultUserMenu />, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()

  // const { loading, data } = useSubscription(TRANSACTIONS_REQUEST_SUBSCRIPTION)

  const handleToggleSidebar = useCallback(() => {
    dispatch(toggleSidebar())
  }, [])

  return (
    <Hidden lgUp>
      <MuiAppBar elevation={0} color="inherit" className={clsx(classes.root, className)} {...rest}>
        <MobileUserMenu logout={logout} />
        <Avatar className={classes.menuButton} variant="rounded" onClick={handleToggleSidebar}>
          <MenuIcon />
        </Avatar>
      </MuiAppBar>
      <div id="react-admin-sub-app-bar" />
    </Hidden>
  )

  // return (
  //   <>
  //     <MuiAppBar elevation={0} color="transparent" className={clsx(classes.root, className)} {...rest}>
  //       <Toolbar>
  //         <Hidden lgUp>
  //           <Avatar className={classes.menuButton} variant="rounded" onClick={handleToggleSidebar}>
  //             <MenuIcon />
  //           </Avatar>
  //           {/* <IconButton
  //             color="inherit"
  //             aria-label="open drawer"
  //             onClick={handleToggleSidebar}
  //             className={classes.menuButton}
  //           >
  //             <MenuIcon
  //               classes={{
  //                 root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
  //               }}
  //             />
  //           </IconButton> */}
  //         </Hidden>
  //         {Children.count(children) === 0 ? (
  //           <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
  //         ) : (
  //           children
  //         )}
  //         <LoadingIndicator />
  //         <CustomUserMenu logout={logout} />
  //         {/* {cloneElement(userMenu, { logout })} */}
  //       </Toolbar>
  //     </MuiAppBar>
  //     <div id="react-admin-sub-app-bar" />
  //   </>
  // )
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: APPBAR_HEIGHT,
      maxHeight: APPBAR_HEIGHT,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 0,
      paddingRight: theme.spacing(5),
      boxShadow: theme.shadows[4],
    },
    avatar: {
      width: 60,
      height: 60,
    },
    menuButton: {
      backgroundColor: 'white',
      color: theme.palette.secondary.dark,
      width: '45px',
      height: '45px',
      boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    // menuButton: {
    //   marginLeft: '0.5em',
    //   marginRight: '0.5em',
    // },
    // menuButtonIconClosed: {
    //   transition: theme.transitions.create(['transform'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    //   }),
    //   transform: 'rotate(0deg)',
    // },
    // menuButtonIconOpen: {
    //   transition: theme.transitions.create(['transform'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    //   }),
    //   transform: 'rotate(180deg)',
    // },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '@media print': {
      root: {
        display: 'none',
      },
    },
  }),
  { name: 'RaAppBar' }
)

export default AppBar
