import React, { FC, useMemo } from 'react'
// import { TextField } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
// import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/details/Edit'
// import AddressForm from './AddressForm'
import AddressFormSection from '../../components/AddressFormSection'
import { useTranslate } from 'ra-core'
import { parse } from 'query-string'

type Props = {
  [x: string]: any
}

const AddressEdit: FC<Props> = (props) => {
  const translate = useTranslate()

  const redirect = useMemo((): string | false => {
    const parsedUrl = parse(props.location.search)
    if (parsedUrl && parsedUrl.customerId) return `/Customer/${parsedUrl.customerId}/show`
    else return false
  }, [props.location])

  return (
    <Edit {...props} component="div">
      <SectionedForm variant="outlined" redirect={redirect}>
        <AddressFormSection title={translate('resources.Customer.titles.createSub2')} />
      </SectionedForm>
    </Edit>
  )
}

export default AddressEdit
