import React, { FC, useCallback, useState } from 'react'
import { required, TextInput } from 'react-admin'
import { Form } from 'react-final-form'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import LangWithFlagSelectInput from '../../components/input/LangWithFlagSelectInput'
import { useDataProvider, useGetOne, useNotify, useRedirect, useRefresh } from 'ra-core'
import { GmailRichTextEditor } from '../../components/gmail/GmailRichTextEditor'

type Props = {
  id: number | string
  resourceType?: 'quote' | 'contract' | 'brief'
}

const TemplateValueEditForm: FC<Props> = ({ id, resourceType }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const [saving, setSaving] = useState<boolean>(false)
  const { loading, error, data } = useGetOne('GmailTemplateValue', id)

  const onSubmit = useCallback(async (values) => {
    try {
      setSaving(true)
      const result = await dataProvider.update('GmailTemplateValue', {
        id,
        data: { ...values },
        previousData: { id },
      })
      notify('ra.notify.updated')
      redirect(`/GmailTemplate/${values.gmailTemplateId}/show/`)
      refresh()
    } catch (err) {
      console.error(err as any)
      notify((err as any).message, 'error')
    } finally {
      setSaving(false)
    }
  }, [])

  // if (data) console.log('languageeees', data)

  return !loading && data ? (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...data }}
      render={({ handleSubmit, invalid }): React.ReactElement => (
        <form onSubmit={handleSubmit}>
          <Box>
            <TextInput source="subject" variant="outlined" fullWidth />
            <Paper>
              <GmailRichTextEditor
                resourceType={resourceType}
                label={false}
                source="value._html"
                validate={required()}
              />
            </Paper>
            <Box mt={4} display="flex" justifyContent="space-between">
              <LangWithFlagSelectInput source="languageId" />
              <Button color="primary" variant="contained" onClick={handleSubmit} disabled={saving || invalid}>
                Update
              </Button>
            </Box>
          </Box>
        </form>
      )}
    />
  ) : (
    <CircularProgress />
  )
}

export default TemplateValueEditForm
