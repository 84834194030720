import React, { FC, useMemo, useState, useCallback, useEffect } from 'react'
import { useDelete, useQueryWithStore, useRefresh } from 'ra-core'
import { Confirm } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  author: {
    fontWeight: 'bold',
    display: 'inline',
  },
  inline: {
    display: 'inline',
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

type Props = {
  record?: any
}

type DeleteOrderMessageProps = {
  id: string
}

const DeleteOrderMessage: FC<DeleteOrderMessageProps> = ({ id }) => {
  const refresh = useRefresh()
  const [deleteMessage, { loading, error, data }] = useDelete('OrderMessage', id)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleDialogClose = useCallback((): void => setIsDialogOpen(false), [])
  const handleDialogOpen = useCallback((): void => setIsDialogOpen(true), [])

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const onDeleteClick = useCallback((event: any) => {
    onMoreClose(event)
    handleDialogOpen()
  }, [])

  useEffect(() => {
    if (data && isDialogOpen) {
      refresh()
    }
  }, [isDialogOpen, data])

  return (
    <>
      <IconButton onClick={onMoreClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`more-ordermessage-${id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMoreClose}
      >
        <MenuItem onClick={onDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          {'Delete note'}
        </MenuItem>
      </Menu>
      <Confirm
        isOpen={isDialogOpen}
        loading={loading}
        title="Delete Route"
        content="Are you sure you want to delete this note?"
        onConfirm={deleteMessage}
        onClose={handleDialogClose}
      />
    </>
  )
}

const OrderMessageItem: FC<Props> = ({ record }) => {
  const classes = useStyles()
  const { data, loading, error } = useQueryWithStore({
    type: 'getOne',
    resource: 'User',
    payload: {
      id: record.accountId,
    },
  })

  const messageDate = useMemo(() => (record && record.createdAt ? record.createdAt : undefined), [record])

  return (
    <Paper elevation={2} className={classes.paper}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          {!loading && data && (
            <Typography variant="h6" className={classes.author}>{`${data.firstName} ${data.lastName}   `}</Typography>
          )}
          <Typography variant="caption" className={classes.inline}>
            {messageDate ? new Date(messageDate).toLocaleString() : ''}
          </Typography>
          <Typography>{record.message}</Typography>
        </Box>
        <DeleteOrderMessage id={record.id} />
      </Box>
    </Paper>
  )
}

export default OrderMessageItem
