import React, { FC } from 'react'
import {
  Avatar,
  Box,
  CircularProgress,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useState } from 'react'
import { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { QUERY_GET_ORDER_ROUTES } from '../../queries'
import { useDataProvider } from 'ra-core'
import { OrderRouteListItem } from './OrderRouteListItem'
import { Skeleton } from '@material-ui/lab'

type Props = {
  orderId: string | number
  orderRouteId: string | number
  onItemClick: (orderRoute: any) => void
}

export const ChooseOrderRoute: FC<Props> = (props) => {
  const classes = useStyles()
  const [orderRoutes, setOrderRoutes] = useState<Record<string, any>[] | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const apolloClient = useApolloClient()
  const dataProvider = useDataProvider()

  useEffect(() => {
    const fetchOrderRoutes = async (): Promise<void> => {
      try {
        setLoading(true)
        const result = await apolloClient.query({
          query: QUERY_GET_ORDER_ROUTES,
          variables: {
            sort: {
              flightDate: 'ASC',
            },
            filters: {
              orderId: props.orderId,
            },
            pagination: {
              disabled: true,
            },
          },
        })

        if (!result?.data?.orderRoutes?.data || result.data.orderRoutes.data.length === 0) {
          throw new Error('error downloading OrderRoutes')
        }

        const departureAirports = await Promise.all(
          result.data.orderRoutes.data.map((item: any, index: number) =>
            dataProvider.getOne('Airport', { id: item.departureAirportId })
          )
        )
        const departureAirportsMap = departureAirports.reduce<any>((acc: any, item: any) => {
          if (!item?.data || !item?.data?.id) {
            return acc
          }

          return {
            ...acc,
            [item.data.id]: item.data,
          }
        }, {})

        const arrivalAirports = await Promise.all(
          result.data.orderRoutes.data.map((item: any, index: number) =>
            dataProvider.getOne('Airport', { id: item.arrivalAirportId })
          )
        )
        const arrivalAirportsMap = arrivalAirports.reduce<any>((acc: any, item: any) => {
          if (!item?.data || !item?.data?.id) {
            return acc
          }

          return {
            ...acc,
            [item.data.id]: item.data,
          }
        }, {})

        const vehicles = await Promise.all(
          result.data.orderRoutes.data.map((item: any, index: number) =>
            dataProvider.getOne('Vehicle', { id: item.vehicleId })
          )
        )

        const vehiclesMap = vehicles.reduce<any>((acc: any, item: any) => {
          if (!item?.data || !item?.data?.id) {
            return acc
          }

          return {
            ...acc,
            [item.data.id]: item.data,
          }
        }, {})

        const finalOrderRoutes = result.data.orderRoutes.data.map((item: any) => {
          return {
            ...item,
            departureAirport: departureAirportsMap[item.departureAirportId],
            arrivalAirport: arrivalAirportsMap[item.arrivalAirportId],
            vehicle: vehiclesMap[item.vehicleId],
          }
        })

        setOrderRoutes(finalOrderRoutes)

        console.log('prova', finalOrderRoutes)
      } catch (e) {
        console.error('Error fetching OrderRoutes')
      } finally {
        setLoading(false)
      }
    }

    fetchOrderRoutes()
  }, [])

  if (loading) {
    return (
      <DialogContent className={classes.root}>
        <ListItem>
          <Skeleton variant="circle">
            <Avatar />
          </Skeleton>
          <ListItemText className={classes.skeletonListItemText} primary={<Skeleton />} secondary={<Skeleton />} />
        </ListItem>
        <ListItem>
          <Skeleton variant="circle">
            <Avatar />
          </Skeleton>
          <ListItemText className={classes.skeletonListItemText} primary={<Skeleton />} secondary={<Skeleton />} />
        </ListItem>
      </DialogContent>
    )
  }

  return (
    <DialogContent className={classes.root}>
      {orderRoutes?.map((item) => {
        return <OrderRouteListItem disabled={item.id === props.orderRouteId} item={item} onClick={props.onItemClick} />
      })}
    </DialogContent>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '300px',
    marginBottom: theme.spacing(2),
  },
  timeLabel: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  skeletonListItemText: {
    marginLeft: theme.spacing(3),
  },
}))
