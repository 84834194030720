import React, { FC, useMemo } from 'react'
import List from '../../components/list/List'
import { TextField, ReferenceField, ImageField, BooleanField, NumberField, FunctionField, Filter } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import FilterTextInput from '../../components/FilterTextInput'
import { FaShoppingBasket } from 'react-icons/fa'
import CreateButton from '../../components/button/CreateButton'
import Datagrid from '../../components/Datagrid'
import BooleanStatusField from '../../components/BooleanStatusField'

const useImageFieldStyles = makeStyles({
  image: {
    height: 80,
    margin: 0,
  },
})

type Props = {}

const ProductFilters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput label="Cerca" source="name" alwaysOn />
    </Filter>
  )
}

const ProductList: FC = (props) => {
  const imageFieldClasses = useImageFieldStyles()

  const defaultCurrency = 'EUR'
  const defaultLocale = 'it-IT'

  return (
    <List
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      filters={<ProductFilters variant="outlined" />}
      titleIcon={<FaShoppingBasket />}
      actions={<></>}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        {/* <FunctionField
          label="Picture"
          render={(record: any): React.ReactNode => {
            if (!record.catalogPictureId) {
              return <div className={imageFieldClasses.image} />
            } else {
              return (
                <ReferenceField
                  basePath="/Product"
                  resource="Product"
                  record={record}
                  label="Picture"
                  source="catalogPictureId"
                  reference="Picture"
                  sortable={false}
                  link={false}
                >
                  <ImageField classes={imageFieldClasses} source="urlSmall" />
                </ReferenceField>
              )
            }
          }}
        /> */}

        {/* <ReferenceField label="Picture" source="catalogPictureId" reference="Picture" sortable={false}>
          <ImageField source="urlSmall" height={50} />
        </ReferenceField> */}
        <TextField source="sku" />
        <TextField source="name" />
        <NumberField
          source="retailPrice"
          locales={defaultLocale}
          options={{ style: 'currency', currency: defaultCurrency }}
          sortable={false}
        />
        <ReferenceField source="mainCategoryId" reference="ProductCategory" linkType={false}>
          <TextField source="name" />
        </ReferenceField>
        {/* <ReferenceField label="Brand" source="brandId" reference="Brand" sortable={false}>
          <TextField source="name" />
        </ReferenceField> */}
        <BooleanStatusField source="active" label="Status" trueValue="Pubblico" falseValue="Privato" />

        {/* <BooleanField source="featured" /> */}
      </Datagrid>
    </List>
  )
}

export default ProductList
