import React, { FC, useCallback } from 'react'
import { Create } from 'react-admin'
import { useTranslate } from 'ra-core'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
// import Create from '../../components/details/Create'
import FormTextInput from '../../components/FormTextInput'
import FormLangWithFlagInput from '../../components/input/FormLangWithFlagInput'

type Props = {
  [x: string]: any
}

const LanguageCreate: FC<Props> = (props) => {
  const translate = useTranslate()

  const transform = useCallback((data) => ({ ...data, active: true }), [])

  return (
    <Create {...props} component="div" transform={transform}>
      <SectionedForm variant="outlined" redirect="list" title={translate('resources.Language.titles.create')}>
        <FormSection title={translate('resources.Language.titles.formSection')}>
          <FormTextInput source="name" required />
          <FormLangWithFlagInput source="isoCode" required />
          <FormTextInput source="nativeName" />
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default LanguageCreate
