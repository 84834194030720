import React, { useEffect } from 'react'
import { parse } from 'query-string'
import { useApolloClient } from '@apollo/client'
import { MUTATION_VERIFY_GOOGLE_CODE } from '../queries'
import { useRedirect } from 'ra-core'

const GmailCallback = (props: any): any => {
  const client = useApolloClient()
  const redirect = useRedirect()

  useEffect(() => {
    const verify = async (): Promise<void> => {
      try {
        const queryString = parse(props.location.search)
        if (!queryString || !queryString.code) {
          throw new Error('No code passed to callback')
        }
        const res = await client.mutate({
          mutation: MUTATION_VERIFY_GOOGLE_CODE,
          variables: { code: queryString.code },
        })

        if (res?.data?.verifyGoogleCode?.success) {
          redirect('/gmail')
        } else {
          throw new Error(res.data.verifyGoogleCode.error || 'Unknown error')
        }
      } catch (error) {
        console.error('error verifying code:: ', error)
      }
    }

    verify()
  }, [])

  return <div></div>
}

export default GmailCallback
