import React, { FC } from 'react'
import { TextInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import FormTextInput from '../../components/FormTextInput'

type Props = {
  [x: string]: any
}

// props.id === undefined means we are in create
const CustomerDynamicFormPart: FC<Props> = (props) => {
  const { values } = useFormState()
  // console.log('whaaat? ', values, props)

  return values.id ? (
    values.isCompany ? (
      <>
        <TextInput variant="outlined" {...props} source="companyName" />
        <TextInput variant="outlined" {...props} source="vat" />
        <TextInput variant="outlined" {...props} source="pec" />
        <TextInput variant="outlined" {...props} source="phone" />
        <TextInput variant="outlined" {...props} source="billingDigitalId" />
      </>
    ) : (
      <>
        <TextInput variant="outlined" {...props} source="firstName" />
        <TextInput variant="outlined" {...props} source="lastName" />
        <TextInput variant="outlined" {...props} source="fiscalCode" />
        <TextInput variant="outlined" {...props} source="phone" />
      </>
    )
  ) : values.isCompany ? (
    <>
      <FormTextInput {...props} source="companyName" />
      <FormTextInput {...props} source="vat" />
      <FormTextInput {...props} source="pec" />
      <FormTextInput {...props} source="phone" />
      <FormTextInput {...props} source="billingDigitalId" />
    </>
  ) : (
    <>
      <FormTextInput {...props} source="firstName" />
      <FormTextInput {...props} source="lastName" />
      <FormTextInput variant="outlined" {...props} source="fiscalCode" />
      <FormTextInput {...props} source="phone" />
    </>
  )
}

export default CustomerDynamicFormPart
