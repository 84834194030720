import React, { FC, useCallback, useState, useEffect } from 'react'
import { FormWithRedirect, useDataProvider, useNotify, useRedirect, useRefresh } from 'ra-core'
import { useForm } from 'react-final-form'
import Box from '@material-ui/core/Box'
import PassengerForm from '../../components/forms/PassengerForm'
import SaveButton from '../../components/button/SaveButton'

const InjectExistingPassenger: FC<any> = ({ passengerId }) => {
  const form = useForm()

  useEffect(() => {
    if (passengerId) {
      form.change('passengerId', passengerId)
    }
  }, [passengerId, form])

  return null
}

const InDrawerPassengerCreateEdit: FC<any> = (props: any) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [saving, setSaving] = useState<boolean>(false)

  const onSubmit = useCallback(async (values) => {
    try {
      const result = values.passengerId
        ? await dataProvider.update('Passenger', {
            id: values.passengerId,
            data: { ...values },
            previousData: { id: values.passengerId },
          })
        : await dataProvider.create('Passenger', { data: { ...values } })

      refresh()
      redirect('/Passenger')
      notify('ra.notify.updated')
    } catch (error) {
      console.log('update error: ', error)
      notify(error, 'error')
    } finally {
      setSaving(false)
    }
  }, [])

  return (
    <FormWithRedirect
      {...props}
      // record={data}
      resource="Passenger"
      save={onSubmit}
      // "saving" boolean coming out from render not working ://
      render={({ handleSubmitWithRedirect, pristine, record }: any): any => (
        <Box display="flex" flexDirection="column">
          {props.passengerId && <InjectExistingPassenger passengerId={props.passengerId} />}
          <PassengerForm />
          <Box mt={3}>
            <SaveButton
              size="large"
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              pristine={pristine}
              saving={saving}
              disabled={pristine || saving}
            />
          </Box>
        </Box>
      )}
    />
  )
}

export default InDrawerPassengerCreateEdit
