import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { gql, useApolloClient, useQuery } from '@apollo/client'
import Typography from '@material-ui/core/Typography'
import PermissionItem from './PermissionItem'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useDataProvider, useGetList, useNotify } from 'ra-core'
import Loading from '../../components/Loading'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core'
import SectionedForm from '../../components/forms/SectionedForm'
import { FaUser, FaUsers } from 'react-icons/fa'
import { MdSave } from 'react-icons/md'
import { SelectInput } from 'ra-ui-materialui'
import { ROLEPARAMTYPEENUM } from '../RoleParam/RoleParamTypeEnum'
import SendIcon from '@material-ui/icons/Send'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const MUTATION_UPDATE_PERMISSION_ROLE = gql`
  mutation UpdatePermissionRole($id: ID!, $data: PermissionRoleUpdateInput!) {
    updatePermissionRole(data: $data, id: $id) {
      id
    }
  }
`

const QUERY_GET_PERMISSION_ROLES = gql`
  query GetRoleDetails {
    allPermissionRoles: permissionRoles(pagination: { disabled: true }, sort: { permissionName: "ASC" }) {
      total
      data {
        id
        permissionName
        categories {
          id
          name
        }
        roles {
          id
          name
        }
      }
    }

    permissionCategories(pagination: { disabled: true }, sort: { name: "ASC" }) {
      data {
        id
        name
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  saveButton: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  userAvatar: {
    backgroundColor: '#eeeeee',
    color: 'rgba(51,51,51,0.5)',
    width: '60px',
    height: '60px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    marginRight: theme.spacing(3),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stickyColumn: {
    // width: '40%', // TODO: DELETE ME
    flexBasis: '50%',
    maxWidth: 375,
    position: 'sticky',
    top: 0,
  },
  rolesWrapper: {
    flex: '50%',
    marginLeft: theme.spacing(3),
    height: '100%',
  },
}))

type Props = {
  record?: any
}

const AddParam: FC<any> = ({ record, ...props }) => {
  const dataProvider = useDataProvider()
  const { loading, error, data } = useGetList(
    'RoleParam',
    { page: 1, perPage: 10000 },
    { field: 'name', order: 'ASC' },
    {}
  )

  const [selected, setSelected] = useState(0)
  const [value, setValue] = useState(null)
  const [saving, setSaving] = useState<boolean>(false)

  const handleChange = (event: any) => {
    setSelected(event.target.value)
  }

  const onValueChange = (event: any) => setValue(event.target.value)

  const onSubmit = async (event: any) => {
    if (!data) return
    setSaving(true)

    try {
      const res = await dataProvider.update('Role', {
        id: record.id,
        data: { ...record, params: { ...record.params, [data[selected].name]: value } },
        previousData: { id: record.id },
      })

      setSelected(0)
      setValue(null)
    } catch (err) {
      console.log('error adding roleParam: ', err)
    } finally {
      setSaving(false)
    }
  }

  return data ? (
    <Box display="flex">
      <FormControl fullWidth>
        <InputLabel id="param-select-label">Parameter</InputLabel>
        <Select
          labelId="param-select-label"
          id="param-select"
          value={selected}
          label="Parameter"
          onChange={handleChange}
          variant="outlined"
        >
          {Object.values(data).map((elem: any) => (
            <MenuItem value={elem.id}>{elem.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {selected !== 0 && (
        <FormControl fullWidth>
          {data[selected].type === ROLEPARAMTYPEENUM.boolean && (
            <>
              <InputLabel id="param-value-label">Param Value</InputLabel>
              <Select
                labelId="param-value-label"
                id="param-value"
                value={value}
                label="Param"
                onChange={onValueChange}
                variant="outlined"
              >
                <MenuItem value={true as any}>Yes</MenuItem>
                <MenuItem value={false as any}>No</MenuItem>
              </Select>
            </>
          )}
          {data[selected].type === ROLEPARAMTYPEENUM.string && (
            <TextField
              label="Param Value"
              name="param-value"
              onChange={onValueChange}
              id="param-value"
              variant="outlined"
            />
          )}
        </FormControl>
      )}
      {selected !== 0 && value !== null && (
        <IconButton onClick={onSubmit} color="primary">
          {saving ? <CircularProgress size={10} /> : <SendIcon />}
        </IconButton>
      )}
    </Box>
  ) : null
}

const ParamsList: FC<any> = ({ record, ...props }) => {
  const dataProvider = useDataProvider()
  const [saving, setSaving] = useState<boolean>(false)

  const onUpdate = (keyToDelete: string) => async () => {
    try {
      setSaving(true)
      const params = { ...record.params }
      delete params[keyToDelete]

      const result = await dataProvider.update('Role', {
        id: record.id,
        data: { ...record, params },
        previousData: { id: record.id },
      })
    } catch (err) {
      console.log('error deleting key::: ', err)
    } finally {
      setSaving(false)
    }
  }

  if (record && record.params) {
    return (
      <Card>
        {Object.entries(record.params).map((elem: any[], idx: number) => (
          <Box
            display="flex"
            justifyContent="space-between"
            key={`role-param-${idx}`}
            alignItems="center"
            my={2}
            px={2}
          >
            <Typography variant="h5">{elem[0]}</Typography>
            <Typography variant="body1">{`${elem[1]}`}</Typography>
            <IconButton onClick={onUpdate(elem[0])} size="small" disabled={saving}>
              <DeleteForeverIcon color="error" />
            </IconButton>
          </Box>
        ))}
      </Card>
    )
  }
  return null
}

const RoleDetails: FC<Props> = (props) => {
  const classes = useStyles(props)
  const { loading, data, error, refetch } = useQuery(QUERY_GET_PERMISSION_ROLES, {
    fetchPolicy: 'network-only',
  })
  const client = useApolloClient()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const notify = useNotify()
  const [activePermissions, setActivePermissions] = useState<Record<string, boolean> | undefined>(undefined)

  const permissionHashTable = useMemo(() => {
    if (!loading && data && data.allPermissionRoles && data.allPermissionRoles.data) {
      return data.allPermissionRoles.data.reduce((acc: any, item: any) => {
        return {
          ...acc,
          [item.id]: item,
        }
      }, {})
    }

    return {}
  }, [data, loading])

  const categoriesPermissions = useMemo(() => {
    if (
      !loading &&
      data &&
      data.permissionCategories &&
      data.permissionCategories.data &&
      data.allPermissionRoles &&
      data.allPermissionRoles.data
    ) {
      const orphanedPermissions = data.allPermissionRoles.data.filter(
        (permission: any) => !permission.categories || permission.categories.length === 0
      )
      return [
        ...data.permissionCategories.data.map((permissionCategory: any) => {
          return {
            ...permissionCategory,
            permissions: data.allPermissionRoles.data.filter((permission: any) => {
              return !!permission.categories.find((pC: any) => pC.id === permissionCategory.id)
            }),
          }
        }),
        {
          id: 'NO_CATEGORY',
          name: 'Senza Categoria',
          permissions: orphanedPermissions,
        },
      ]
    }
    return []
  }, [data, loading])

  const handlePermissionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setActivePermissions({
        ...activePermissions,
        [event.target.name]: event.target.checked,
      })
    },
    [activePermissions]
  )

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true)
      if (!activePermissions) {
        throw new Error('Active permissions not set')
      }

      await Promise.all(
        Object.keys(activePermissions).map((item: any) => {
          const rolesIds = permissionHashTable[item].roles
            .map((r: any) => r.id)
            .filter((r: any) => r !== props.record.id)

          if (activePermissions[item]) {
            rolesIds.push(props.record.id)
            console.log('trovato', item, rolesIds)
          } else {
            console.log('dovrebbe togliere', item, rolesIds)
          }

          return client.mutate({
            mutation: MUTATION_UPDATE_PERMISSION_ROLE,
            variables: {
              id: item,
              data: {
                rolesIds,
              },
            },
          })
        })
      )
      refetch()
    } catch (e) {
      notify('ra.message.error', 'warning')
      console.error(e)
    } finally {
      setSubmitting(false)
    }
  }, [permissionHashTable, activePermissions, props.record])

  useEffect(() => {
    if (!loading && data.allPermissionRoles && data.allPermissionRoles.data && !activePermissions && props.record) {
      const activePermissions = data.allPermissionRoles.data.filter((permission: any) => {
        return permission.roles && permission.roles.find((pR: any) => pR.id === props.record.id)
      })
      setActivePermissions(
        activePermissions.reduce((acc: any, item: any) => {
          return {
            ...acc,
            [item.id]: true,
          }
        }, {})
      )
    }
  }, [loading, data, activePermissions, props.record])

  if (loading || !activePermissions) {
    return <Loading />
  }

  return (
    <Box display="flex" height="100%" alignItems="flex-start">
      <div className={classes.stickyColumn}>
        <RoleDetailCard record={props.record} />
        <Box display="flex" justifyContent="flex-end" my={3}>
          <Button
            disabled={submitting}
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            size="large"
            className={classes.saveButton}
            startIcon={<MdSave />}
          >
            Salva
          </Button>
        </Box>
        <Typography variant="h4">Associa parametro</Typography>
        <AddParam {...props} />
        <Box mt={3} />
        <Typography variant="h4">Parametri associati</Typography>
        <ParamsList {...props} />
      </div>

      <div className={classes.rolesWrapper}>
        {categoriesPermissions.map((categoryPermission: any, index) => {
          return (
            <Box boxShadow={3} mb={3} p={8} bgcolor="background.paper" borderRadius={4}>
              <div key={`category-permission-${categoryPermission.id}`}>
                <Typography variant="h2">{categoryPermission.name}</Typography>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    {categoryPermission.permissions.map((permission: any, index: any) => {
                      return (
                        <Grid item xs={12} md={4}>
                          <PermissionItem
                            disabled={submitting}
                            key={`permission-item-${permission.id}`}
                            permission={permission}
                            name={permission.id}
                            checked={activePermissions && activePermissions[permission.id]}
                            onChange={handlePermissionChange}
                            variant="body2"
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              </div>
            </Box>
          )
        })}
      </div>
    </Box>
  )
}

const RoleDetailCard: FC<Props> = (props) => {
  const classes = useStyles(props)

  return (
    <Card>
      <CardContent className={classes.row}>
        <Avatar variant="rounded" className={classes.userAvatar}>
          <FaUsers size={30} />
        </Avatar>
        <div>
          <Typography variant="body1">Role:</Typography>
          <Typography variant="h4">{`${props.record.name}`}</Typography>
        </div>
      </CardContent>
      <Divider />
      <CardContent className={classes.rowSpaceBetween}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Is admin:</Typography>
          <Typography variant="body2">{props.record.isAdmin ? 'Yes' : 'No'}</Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardContent className={classes.row}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Is blocked</Typography>
          <Typography variant="body2">{props.record.isBlocked ? 'Yes' : 'No'}</Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardContent className={classes.row}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Is Public</Typography>
          <Typography variant="body2">{props.record.isPublic ? 'Yes' : 'No'}</Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default RoleDetails
