import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Tab, Tabs } from '@material-ui/core'
import TabPanel from '../TabPanel'
import { CreateActivityForm } from './CreateActivityForm'
import { CreateCustomerNoteForm } from './CreateCustomerNoteForm'

type Props = {
  mutation: any
  source: string
  id: any
  onCreated: () => void
} & Record<string, any>

function a11yProps(index: any): any {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const ActivityForm: FC<Props> = (props) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<any>, newValue: number): void => {
    setValue(newValue)
  }

  return (
    <Paper className={classes.root}>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Log Activity" {...a11yProps(0)} />
        <Tab label="Create Note" {...a11yProps(1)} />
      </Tabs>
      <div className={classes.tabContents}>
        <TabPanel value={value} index={0}>
          <CreateActivityForm {...props} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CreateActivityForm showActivityTypes={false} {...props} />
        </TabPanel>
      </div>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  tabContents: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}))
