import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import RichTextInput from 'ra-input-rich-text'
import { Form } from 'react-final-form'
import {
  MUTATION_GENERATE_CIELO_FLIGHT_BRIEF,
  MUTATION_GENERATE_CIELO_ORDER,
  MUTATION_GENERATE_CIELO_QUOTE_PRINT,
  MUTATION_SEND_MAIL,
} from '../../queries'
import { useApolloClient, useMutation } from '@apollo/client'
import { useNotify } from 'ra-core'
import { TextInput } from 'ra-ui-materialui'
import Skeleton from '@material-ui/lab/Skeleton'
import { SelectTemplateModalButton } from './SelectTemplateModalButton'
import { Alert } from '@material-ui/lab'
import { MailAttachment } from './MailAttachment'
import { MultiSelectField } from './MultiSelectField'
import { useCustomerPeople } from './useCustomerPeople'

export type CreateMailFormProps = {
  type: 'quote' | 'contract' | 'brief'
  resourceId: string
  reference: string
  customerId: string
  onMailSent?: () => void
}

export const CreateMailForm: FC<CreateMailFormProps> = ({ type, resourceId, customerId, onMailSent, reference }) => {
  const classes = useStyles()
  const notify = useNotify()
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>()

  const { initialized, options: customerPeople } = useCustomerPeople(customerId)

  const client = useApolloClient()

  const [sendGmail, { data, loading, error }] = useMutation(MUTATION_SEND_MAIL)

  useEffect(() => {
    const generateCieloPrint = async (): Promise<void> => {
      try {
        let mutation = MUTATION_GENERATE_CIELO_FLIGHT_BRIEF

        switch (type) {
          case 'brief':
            mutation = MUTATION_GENERATE_CIELO_FLIGHT_BRIEF
            break
          case 'contract':
            mutation = MUTATION_GENERATE_CIELO_ORDER
            break
          case 'quote':
            mutation = MUTATION_GENERATE_CIELO_QUOTE_PRINT
        }
        const result = await client.mutate({
          mutation,
          variables: {
            [type === 'quote' ? 'id' : 'orderId']: resourceId,
          },
        })
        const resultKeys = Object.keys(result.data || {})
        if (result.data && resultKeys.length > 0) {
          setDownloadUrl(result.data[resultKeys[0]])
        }
      } catch (error) {
        console.error('error creating attachment', error)
        notify('error creating attachment', 'error')
      }
    }

    generateCieloPrint()
  }, [])

  const handleOnSubmit = useCallback(
    async (values: any) => {
      const { data, errors } = await sendGmail({
        variables: {
          attachmentUrl: downloadUrl,
          attachmentName: `cielo-${type}-${reference}.pdf`,
          content: values.html,
          subject: values.subject,
          recipients: values.to?.map((item: any) => item.id),
          cc: values.cc?.map((item: any) => item.id),
          senderName: 'Cielo Aviation - Customer Care',
        },
      })

      if (errors) {
        notify('error sending email', 'error')
        console.error('error sending email', errors)
      } else {
        notify('Mail sent successfully', 'info')
        if (onMailSent) {
          onMailSent()
        }
      }
    },
    [downloadUrl]
  )

  if (!initialized) {
    return (
      <div className={classes.root}>
        <Skeleton variant="rect" className={classes.inputSkeleton} />
        <Skeleton variant="rect" width={132} height={30} className={classes.selectTemplateButtonContainer} />
        <Skeleton variant="rect" className={classes.inputSkeleton} />
        <Skeleton variant="rect" width="100%" height={113} />
      </div>
    )
  }

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={{ to: customerPeople && customerPeople.length > 0 ? [customerPeople[0]] : undefined }}
      render={({ handleSubmit, invalid }): React.ReactElement => (
        <form className={classes.root} onSubmit={handleSubmit}>
          {!customerPeople ||
            (customerPeople.length === 0 && (
              <Alert severity="warning">Error! No e-mail available for the client</Alert>
            ))}
          <div className={classes.inputContainer}>
            <MultiSelectField source="to" placeholder="To" options={customerPeople} />
          </div>
          <div className={classes.inputContainer}>
            <MultiSelectField source="cc" placeholder="Cc" options={customerPeople} />
          </div>
          <div className={classes.selectTemplateButtonContainer}>
            <SelectTemplateModalButton resourceId={resourceId} customerId={customerId} type={type} />
          </div>
          <TextInput source="subject" variant="outlined" />
          <RichTextInput label="Body" source="html" variant="outlined" />

          <div className={classes.attachmentsContainer}>
            <MailAttachment
              isLoading={!downloadUrl}
              attachmentUrl={downloadUrl}
              resourceType={type}
              reference={reference}
            />
          </div>

          <Button disabled={loading || !downloadUrl} onClick={handleSubmit}>
            {loading && <CircularProgress size={20} />}
            Send
          </Button>
        </form>
      )}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: '20px',
  },
  inputSkeleton: {
    width: '100%',
    height: '40px',
    marginBottom: '37px',
  },
  selectTemplateButtonContainer: {
    marginBottom: '20px',
  },
  attachmentsContainer: {
    marginBottom: '20px',
  },
  inputContainer: {
    marginBottom: '20px',
    width: '354px',
  },
}))
