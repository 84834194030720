import React, { FC, useMemo } from 'react'
import { useGetMany } from 'ra-core'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import FlightDurationField from './FlightDurationField'

type Props = {
  departureAirportId: string
  arrivalAirportId: string
  passengersQuantity?: number
  flightDate: string
  arrivalDate?: string
  flightDuration?: number
  index?: number
}

const FullRouteField: FC<Props> = ({
  departureAirportId,
  arrivalAirportId,
  passengersQuantity,
  flightDate,
  index,
  arrivalDate,
  flightDuration,
}) => {
  const { loading, data, error } = useGetMany('Airport', [departureAirportId, arrivalAirportId])
  const classes = useStyles()
  const theme = useTheme()
  const mediaquery = useMediaQuery(theme.breakpoints.down('xs'))

  const departure = useMemo(
    () => (data && Array.isArray(data) ? data.find(({ id }: any = {}) => id === departureAirportId) : undefined),
    [data]
  )
  const arrival = useMemo(
    () => (data && Array.isArray(data) ? data.find(({ id }: any = {}) => id === arrivalAirportId) : undefined),
    [data]
  )
  const routeDate = useMemo(
    () =>
      flightDate && typeof flightDate === 'string'
        ? new Date(flightDate.replaceAll('Z', ''))
        : typeof flightDate === 'object' && (flightDate as any) instanceof Date
        ? new Date((flightDate as Date).toISOString().replace('Z', ''))
        : undefined,
    [flightDate]
  )
  const routeDay = useMemo(
    (): string => (routeDate && routeDate instanceof Date ? routeDate.toLocaleDateString() : ''),
    [routeDate]
  )
  const routeTime = useMemo(
    () => (routeDate && routeDate instanceof Date ? routeDate.toLocaleTimeString().slice(0, -3) : ''),
    [routeDate]
  )

  if (mediaquery) {
    return (
      <Paper className={classes.paper}>
        <Box m={4} display="flex" flexDirection="column" width="100%">
          <Box width="100%" display="flex" justifyContent="space-between" flexWrap="wrap">
            <Typography variant="h6" className={classes.indexText}>{`Route ${index}`}</Typography>
          </Box>
          <Timeline align="left" classes={{ root: classes.timeline }}>
            <TimelineItem classes={{ root: classes.timelineItem }}>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineOppositeContent classes={{ root: classes.oppositeContent }}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Box display="flex" alignItems="center">
                    <Typography variant="h3" className={classes.airportCode}>
                      {departure?.iataCode || departure?.icaoCode}
                    </Typography>
                    <Typography variant="caption" noWrap className={classes.airportName}>
                      {departure?.name}
                    </Typography>
                  </Box>
                )}
                <Box display="flex" alignItems="baseline">
                  <Typography variant="body2">{routeDay}</Typography>
                  <Box ml={2} />
                  <Typography variant="h6" className={classes.bold}>
                    {routeTime}
                  </Typography>
                </Box>
              </TimelineOppositeContent>
            </TimelineItem>
            <TimelineItem classes={{ root: classes.timelineItem }}>
              <TimelineSeparator>
                <TimelineDot color="primary" />
              </TimelineSeparator>
              <TimelineOppositeContent classes={{ root: classes.oppositeContent }}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Box display="flex" alignItems="center">
                    <Typography variant="h3" className={classes.airportCode}>
                      {arrival?.iataCode || arrival?.icaoCode}
                    </Typography>
                    <Typography variant="caption" noWrap className={classes.airportName}>
                      {arrival?.name}
                    </Typography>
                  </Box>
                )}

                <Box display="flex" alignItems="baseline">
                  <Typography variant="body2">
                    {arrivalDate ? new Date(arrivalDate).toLocaleDateString() : ''}
                  </Typography>
                  <Box ml={2} />
                  <Typography variant="h6" className={classes.bold}>
                    {arrivalDate ? new Date(arrivalDate).toLocaleTimeString() : ''}
                  </Typography>
                </Box>
              </TimelineOppositeContent>
            </TimelineItem>
          </Timeline>
          <Box display="flex" justifyContent="space-between">
            <Box mr={4}>
              <Typography variant="body2">{'Duration'}</Typography>
              <Typography variant="h6" className={classes.bold}>
                <FlightDurationField record={{ flightDuration }} />
              </Typography>
            </Box>
            <Box mr={4}>
              <Typography variant="body2">{'Passengers'}</Typography>
              <Typography variant="h6" className={classes.bold}>
                {passengersQuantity}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    )
  } else {
    return (
      <Paper className={classes.paper}>
        <Box m={4} display="flex" flexDirection="column" width="100%">
          <Box width="100%" display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box display="flex" flexWrap="wrap">
              <Box className={classes.indexSquare} display="flex" justifyContent="center" alignItems="center">
                {index}
              </Box>
              {loading ? (
                <CircularProgress />
              ) : (
                <Box mr={4}>
                  <Typography variant="h3" className={classes.airportCode}>
                    {departure?.iataCode || departure?.icaoCode}
                  </Typography>
                  <Box className={classes.boxAirportName}>
                    <Typography variant="caption" className={classes.airportName}>
                      {departure?.name}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box mr={4}>
                <Typography variant="body2">{routeDay}</Typography>
                <Typography variant="h6" className={classes.bold}>
                  {routeTime}
                </Typography>
              </Box>
              <Box mr={4}>
                <svg width="20px" height="46px" fill="gray">
                  <path stroke="gray" d="M20 23 L0 0 M0 46 L20 23" />
                </svg>
              </Box>
              {loading ? (
                <CircularProgress />
              ) : (
                <Box mr={4}>
                  <Typography variant="h3" className={classes.airportCode}>
                    {arrival?.iataCode || arrival?.icaoCode}
                  </Typography>
                  <Box className={classes.boxAirportName}>
                    <Typography variant="caption" className={classes.airportName}>
                      {arrival?.name}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box mr={4}>
                <Typography variant="body2">{arrivalDate ? new Date(arrivalDate).toLocaleDateString() : ''}</Typography>
                <Typography variant="h6" className={classes.bold}>
                  {arrivalDate ? new Date(arrivalDate).toLocaleTimeString() : ''}
                </Typography>
              </Box>
              <Box mr={4}>
                <Typography variant="body2">{'Duration'}</Typography>
                <Typography variant="h6" className={classes.bold}>
                  <FlightDurationField record={{ flightDuration }} />
                </Typography>
              </Box>
              <Box mr={4}>
                <Typography variant="body2">{'Passengers'}</Typography>
                <Typography variant="h6" className={classes.bold}>
                  {passengersQuantity}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    )
  }
  // return (
  //   <Paper className={classes.container}>
  //     <Card className={classes.itemAirort}>
  //       <CardContent className={classes.itemContent}>
  //         <Typography variant="h4">
  //           <FaPlaneDeparture className={classes.titleIcon} />
  //           Departure
  //         </Typography>
  //         {loading || !departure ? (
  //           <CircularProgress />
  //         ) : (
  //           <>
  //             <Typography variant="h1">{departure.iataCode || departure.icaoCode}</Typography>
  //             <Typography variant="h5">{departure.name}</Typography>
  //           </>
  //         )}
  //       </CardContent>
  //     </Card>
  //     <Card className={classes.itemAirort}>
  //       <CardContent className={classes.itemContent}>
  //         <Typography variant="h4">
  //           <FaPlaneArrival className={classes.titleIcon} />
  //           Arrival
  //         </Typography>
  //         {loading || !arrival ? (
  //           <CircularProgress />
  //         ) : (
  //           <>
  //             <Typography variant="h1">{arrival.iataCode || arrival.icaoCode}</Typography>
  //             <Typography variant="h5">{arrival.name}</Typography>
  //           </>
  //         )}
  //       </CardContent>
  //     </Card>
  //     <Card className={classes.itemGeneric}>
  //       <CardContent className={classes.itemContent}>
  //         <Typography variant="h4">
  //           <FaCalendarDay className={classes.titleIcon} />
  //           Flying date
  //         </Typography>
  //         <Typography variant="h1">{routeDay}</Typography>
  //         <Typography variant="h5">{routeTime}</Typography>
  //       </CardContent>
  //     </Card>
  //     <Card className={classes.itemGeneric}>
  //       <CardContent className={classes.itemContent}>
  //         <Typography variant="h4">
  //           <FaUsers className={classes.titleIcon} />
  //           Passengers
  //         </Typography>
  //         <Typography variant="h1">{passengersQuantity}</Typography>
  //       </CardContent>
  //     </Card>
  //   </Paper>
  // )
}

const useStyles = makeStyles((theme) => ({
  //// new styles
  indexSquare: {
    width: '37px',
    height: '37px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '4px',
    marginRight: theme.spacing(4),
  },
  moreSquare: {
    width: '37px',
    height: '37px',
    backgroundColor: '#eeeeee',
    borderRadius: '4px',
    [theme.breakpoints.down('xs')]: {
      width: '20px',
      height: '20px',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  boxAirportName: {
    width: '280px',
    maxWidth: '280px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      width: '170px',
      maxWidth: '170px',
    },
  },
  airportName: {
    lineHeight: 1,
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
    },
  },
  airportCode: {
    lineHeight: 1,
  },
  vehicleImage: {
    width: '37px',
    height: '37px',
    borderRadius: '4px',
  },
  inlineValue: {
    display: 'inline',
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  smallIcon: {
    fontWeight: 'lighter',
    marginLeft: theme.spacing(3),
    '&:first-child': {
      marginLeft: 0,
    },
  },
  indexText: {
    fontWeight: 'bold',
    color: 'gray',
  },
  timeline: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  oppositeContent: {
    textAlign: 'left',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
  },
  timelineItem: {
    minHeight: '60px',
  },
  paper: {
    marginBottom: theme.spacing(4),
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

export default FullRouteField
