import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import SwitchCardInput from '../../components/SwitchCardInput'
import { RadioButtonGroupInput } from 'react-admin'
import FormSwitchInput from '../../components/forms/FormSwitchInput'
import HiddenOrganizationField from '../../components/forms/HiddenOrganizationField'

type Props = {
  [x: string]: any
}

const PermissionRoleCreate: FC<Props> = (props) => {
  return (
    <Create {...props} component="div">
      <SectionedForm
        variant="outlined"
        redirect="show"
        title="Nuovo Punto Vendita"
        asideUpperContent={
          <SwitchCardInput source="myBoolean" label="Vuoi notificare il punto vendita?" startingValue={true} />
        }
      >
        <FormSection title="Utente">
          <HiddenOrganizationField />
          <FormTextInput {...props} source="name" helperText="" />
        </FormSection>
        <FormSection title="Area geografica di appartenenza">
          <RadioButtonGroupInput
            label={false}
            source="areaId"
            choices={[
              { id: '1', name: 'Nord' },
              { id: '2', name: 'Sud' },
            ]}
          />
        </FormSection>
        <FormSection title="Pubblica">
          <FormSwitchInput {...props} source="active" helperText="" />
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default PermissionRoleCreate
