import React, { FC, useMemo } from 'react'
import { Button, ButtonProps, makeStyles, PropTypes } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  baseButton: {
    backgroundColor: '#eeeeee',
    borderWidth: 0,
  },
  base: {},
  xs: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: `${theme.typography.caption}`,
  },
  small: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  medium: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '44px',
  },
  large: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  xl: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    height: '76px',
  },
  iconXs: {
    padding: theme.spacing(1),
    width: '28px',
    minWidth: '28px',
  },
  iconSmall: {
    padding: theme.spacing(2),
    width: '34px',
    minWidth: '34px',
  },
  iconMedium: {
    padding: theme.spacing(3),
    width: '44px',
    minWidth: '44px',
  },
  iconLarge: {
    padding: theme.spacing(4),
    width: '60px',
    minWidth: '60px',
  },
  iconXl: {
    padding: theme.spacing(5),
    width: '75px',
    minWidth: '75px',
  },
  outlined: {},
}))

interface Props {
  className?: string
  icon?: any
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl'
  children?: React.ReactNode
  color?: PropTypes.Color
  disabled?: boolean
  endIcon?: React.ReactNode
  fullWidth?: boolean
  startIcon?: React.ReactNode
  variant?: 'text' | 'outlined' | 'contained'
  onClick?: () => void
  component?: React.ReactElement
  [x: string]: any
}

const BaseButton: FC<Props> = ({ className, size: baseSize, icon, ...rest }) => {
  const { color, children } = rest
  const classes = useStyles()

  const mdSize = useMemo(() => {
    if (baseSize == 'xs') return 'small'
    if (baseSize == 'xl') return 'large'
    return baseSize
  }, [baseSize])

  const sizeClass = useMemo(() => {
    if (baseSize == 'xs') return classes.xs
    if (baseSize == 'small') return classes.small
    if (baseSize == 'large') return classes.large
    if (baseSize == 'xl') return classes.xl
    return classes.medium
  }, [baseSize])

  const iconClass = useMemo(() => {
    if (baseSize == 'xs') return classes.iconXs
    if (baseSize == 'small') return classes.iconSmall
    if (baseSize == 'large') return classes.iconLarge
    if (baseSize == 'xl') return classes.iconXl
    return classes.iconMedium
  }, [baseSize])

  return (
    <Button
      className={clsx({ [classes.baseButton]: !color }, sizeClass, { [iconClass]: icon }, className)}
      {...rest}
      size={mdSize}
    >
      {icon ? icon : children}
    </Button>
  )
}

export default BaseButton
