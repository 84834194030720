import React, { FC, useCallback, useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import Button from '@material-ui/core/Button'
import LinkIcon from '@material-ui/icons/Link'
import { QUERY_GET_GOOGLE_AUTH_URL } from '../queries'

const GmailCodeRedirectButton: FC = () => {
  const client = useApolloClient()
  const [authUrl, setAuthUrl] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const onClick = useCallback((): void => {
    if (typeof authUrl === 'string') {
      window.location.replace(authUrl)
    }
  }, [authUrl])

  useEffect(() => {
    const getUrl = async (): Promise<any> => {
      try {
        const res = await client.query({ query: QUERY_GET_GOOGLE_AUTH_URL })
        if (res?.data?.getGoogleAuthUrl.authUrl) {
          setAuthUrl(res?.data?.getGoogleAuthUrl.authUrl)
        }
      } catch (error) {
        console.error('xxx Error getting gmail auth url: ', error)
      } finally {
        setLoading(false)
      }
    }

    getUrl()
  }, [])

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={onClick}
      endIcon={loading || !authUrl ? undefined : <LinkIcon />}
    >
      {loading ? 'Loading...' : 'Connect with Gmail'}
    </Button>
  )
}

export default GmailCodeRedirectButton
