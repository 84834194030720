import React, { FC } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField, Datagrid, EditButton, ReferenceField, UrlField } from 'react-admin'
import StateFilters from './DocumentFilters'

const sort = { field: 'name', order: 'ASC' }
const filterDefaultValues = { active: true }

type Props = {}

const DocumentList: FC<Props> = (props) => (
  <RAList {...props} filters={<StateFilters />}>
    <Datagrid>
      <NumberField source="id" />
      <TextField source="description" />
      <UrlField source="url" />
      <EditButton />
    </Datagrid>
  </RAList>
)

export default DocumentList
