import React, { FC, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import IOSSwitch from '../../components/IOSSwitch'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState, useForm } from 'react-final-form'
import LangWithFlagSelectInput from '../../components/input/LangWithFlagSelectInput'
import { useTranslate } from 'ra-core'

type Props = {
  source?: string
  [x: string]: any
}

const IsCompanyAndLanguageFormPart: FC<Props> = ({ source = 'isCompany', ...props }) => {
  const classes = useStyles()
  const { values } = useFormState()
  const form = useForm()
  const translate = useTranslate()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    form.change(source, checked)
  }

  useEffect(
    () =>
      form.registerField(
        source,
        (fieldState: any) => {
          const { blur, change, focus, ...rest } = fieldState
        },
        { active: false, dirty: true, touched: true, valid: false, value: true }
      ),
    []
  )

  return (
    <div className={classes.container}>
      <div className={classes.upperWrapper}>
        <div className={classes.switchWrapper}>
          <IOSSwitch {...props} onChange={handleChange} checked={values[source]} />
          <Typography variant="h5" className={classes.switchHelperText}>
            {values[source]
              ? translate('resources.Customer.isCompany.true')
              : translate('resources.Customer.isCompany.false')}
          </Typography>
        </div>
        <LangWithFlagSelectInput />
      </div>
      {!values.id && (
        <Typography className={classes.languageHelper} variant="body2">
          {translate('resources.Customer.helpers.language')}
        </Typography>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  upperWrapper: {
    display: 'flex',
    width: '50%',
    maxWidth: '50%',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    '&:last-child': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  languageHelper: {
    width: '50%',
    marginLeft: theme.spacing(8),
  },
  switchHelperText: {
    marginLeft: theme.spacing(5),
  },
}))

export default IsCompanyAndLanguageFormPart
