import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/details/Create'
import TaxForm from './TaxForm'
import { Record as RARecord } from 'ra-core'

type Props = {}

const TaxCreate: FC<Props> = (props) => {
  const transform = (data: RARecord): RARecord => ({ ...data, isActive: true })

  return (
    <Create {...props} component="div" transform={transform}>
      <SectionedForm variant="outlined" redirect="list">
        <TaxForm {...props} />
      </SectionedForm>
    </Create>
  )
}

export default TaxCreate
