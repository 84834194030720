import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Edit from '../../components/details/Edit'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import SwitchCardInput from '../../components/SwitchCardInput'
import FormSwitchInput from './../../components/forms/FormSwitchInput'
import HiddenOrganizationField from '../../components/forms/HiddenOrganizationField'
import OfficeAreaInput from '../../components/OfficeAreaInput'

type Props = {
  [x: string]: any
}

const OfficeEdit: FC<Props> = (props) => {
  return (
    <Edit {...props} component="div">
      <SectionedForm
        variant="outlined"
        redirect="show"
        title="Nuovo Punto Vendita"
        asideUpperContent={
          <SwitchCardInput source="myBoolean" label="Vuoi notificare il punto vendita?" startingValue={true} />
        }
      >
        <FormSection title="Punto Vendita">
          <HiddenOrganizationField />
          <FormTextInput {...props} source="name" helperText="" />
        </FormSection>
        <FormSection title="Area geografica di appartenenza">
          <OfficeAreaInput label={false} />
        </FormSection>
        <FormSection title="Pubblica">
          <FormSwitchInput {...props} source="active" helperText="" />
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default OfficeEdit
