import { CurrencyMaskType } from '../hooks/useCurrencySettings'

export const calculateTax = (price: any, taxRate: any): number => {
  const p = parseFloat(price)
  const t = parseFloat(taxRate)

  return (p / 100) * t
}

export const EURformat = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
})

export const numToEUR = (value: number): string => EURformat.format(value)

export const formatToMaskedString = (mask: CurrencyMaskType, rawValue: number): string => {
  if (!mask || !rawValue) return ''
  const { prefix, suffix, decimalSymbol, thousandsSeparatorSymbol } = mask

  const [integerPart, decimalPart] = rawValue.toString().split('.')

  const reversedInteger = integerPart.split('').slice().reverse()

  let reverseSeparated = []

  if (thousandsSeparatorSymbol !== '') {
    for (let i = 0; i < reversedInteger.length; i++) {
      const element = reversedInteger[i]

      if (i !== 0 && i % 3 === 0) {
        reverseSeparated.push(thousandsSeparatorSymbol)
      }

      reverseSeparated.push(element)
    }
  } else {
    reverseSeparated = [...reversedInteger]
  }

  const ordered = reverseSeparated.slice().reverse().join('')

  const final = `${prefix || ''}${ordered}${decimalPart ? decimalSymbol : ''}${decimalPart || ''}${suffix || ''}`

  return final
}

export const parseFromMaskedString = (mask: CurrencyMaskType, formattedValue: string): number => {
  if (!mask || !formattedValue) return 0
  const { prefix, suffix, decimalSymbol, thousandsSeparatorSymbol } = mask

  let noPrefix = ''
  if (prefix !== '') noPrefix = formattedValue.split(prefix)[1]

  let noSuffix = noPrefix
  if (suffix !== '') noSuffix = noPrefix.split(suffix)[0]

  const [integerPart, decimalPart] = noSuffix.split(decimalSymbol)

  const noThousands = integerPart.replaceAll(thousandsSeparatorSymbol, '')

  const noSymbols = `${noThousands}${decimalPart ? `.${decimalPart}` : ''}`

  const final = parseFloat(noSymbols)

  return final
}
