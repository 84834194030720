import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import { ReferenceInput } from 'react-admin'
import FormSelectInput from '../../components/input/FormSelectInput'
import SaveUserButton from './SaveUserButton'
import { useTranslate } from 'ra-core'
import { BooleanInput } from 'ra-ui-materialui'

type Props = {
  [x: string]: any
}

const autocompleteQuery = (name: string): Record<string, any> => ({ name })
const sort = { field: 'name', order: 'ASC' }

const UserCreate: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <Create {...props} component="div">
      <SectionedForm
        variant="outlined"
        redirect="show"
        title={translate('resources.User.titles.create')}
        saveButtonComponent={<SaveUserButton />}
      >
        <FormSection title={translate('resources.User.titles.details')}>
          <FormTextInput {...props} source="firstName" />
          <FormTextInput {...props} source="lastName" />
          <FormTextInput {...props} source="email" />
          <FormTextInput {...props} type="password" source="password" />
          <ReferenceInput source="roleId" reference="Role">
            <FormSelectInput optionText="name" fullWidth helperText={translate('resources.User.helpers.roleId')} />
          </ReferenceInput>
          <ReferenceInput
            source="organizationId"
            reference="Organization"
            filterToQuery={autocompleteQuery}
            sort={sort}
          >
            <FormSelectInput
              optionText="name"
              fullWidth
              helperText={translate('resources.User.helpers.organizationId')}
            />
          </ReferenceInput>
          <BooleanInput {...props} source="approved" label="Visible in filters" />
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default UserCreate
