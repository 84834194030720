import React, { FC, useCallback } from 'react'
import { TextField, Box, Button, CircularProgress } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { MUTATION_UPLDATE_GMAIL_SETTINGS } from '../queries'

export const GmailSettingsCC: FC<{ value: string }> = ({ value: _value, ...rest }) => {
  const [value, setValue] = React.useState(_value)
  const [saveSettings, { loading }] = useMutation(MUTATION_UPLDATE_GMAIL_SETTINGS)

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value
    setValue(newValue)
  }, [])

  const handleConfirm = useCallback(async (): Promise<void> => {
    await saveSettings({
      variables: {
        data: {
          defaultCC: value,
        },
      },
    })
  }, [saveSettings, value])

  return (
    <Box display="flex">
      <TextField placeholder="Default CC" value={value} onChange={handleChange} />
      <Button disabled={loading} onClick={handleConfirm}>
        {loading ? <CircularProgress size={24} /> : 'Save'}
      </Button>
    </Box>
  )
}
