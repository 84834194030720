import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  FormWithRedirect,
  useTranslate,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
  useGetOne,
  useDelete,
} from 'ra-core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
// internal components
import Loading from '../../components/Loading'
import SaveButton from '../../components/button/SaveButton'
import { ReferenceInput, DateTimeInput, NumberInput, TextInput, Confirm } from 'react-admin'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import BaseButton from '../../components/button/BaseButton'
import NoTimezoneDateTimeInput from '../../components/input/NoTimezoneDateTimeInput'
import RichTextInput from 'ra-input-rich-text'

type Props = {
  id: number
  orderId: number
  onRedirect?: () => void
  [x: string]: any
}

const filterToQueryQ = (q: string): Record<string, string> => ({ q })
const filterToQueryLicensePlate = (licensePlate: string): Record<string, string> => ({ licensePlate })
const airportOptionText = (record: any): string =>
  `${record.iataCode ? `${record.iataCode} (${record.icaoCode})` : record.icaoCode} - ${record.name}`

const InDrawerOrderRouteForm: FC<Props> = ({ id = 0, orderId, onRedirect, ...props }) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [saving, setSaving] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const { loading, error, data } = useGetOne('OrderRoute', id, { enabled: id !== 0 })
  const [deleteRoute, { loading: loadingDelete, error: errorDelete, data: deleteData }] = useDelete('OrderRoute', id)

  const handleDialogClose = useCallback((): void => setIsDialogOpen(false), [])
  const handleDialogOpen = useCallback((): void => setIsDialogOpen(true), [])

  const onSubmit = useCallback(
    async (values) => {
      try {
        setSaving(true)
        const result =
          id === 0
            ? await dataProvider.create('OrderRoute', {
                data: {
                  ...values,
                  orderId,
                  flightDate: values.flightDate.toString(),
                  arrivalDate: values.arrivalDate ? values.arrivalDate.toString() : null,
                },
              })
            : await dataProvider.update('OrderRoute', {
                id,
                data: {
                  ...values,
                  flightDate: values.flightDate.toString(),
                  arrivalDate: values.arrivalDate ? values.arrivalDate.toString() : null,
                },
                previousData: { id },
              })
        refresh()
        if (onRedirect) {
          onRedirect()
        } else {
          redirect(`/Order/${orderId}/show`)
        }
        // UNABLE TO USE ra.notification.updated (smart count not working :/)
        notify('ra.notify.updated')
      } catch (error) {
        console.log('update error: ', error)
        notify(error, 'error')
      } finally {
        setSaving(false)
      }
    },
    [data, onRedirect]
  )

  useEffect(() => {
    if (deleteData && isDialogOpen) {
      refresh()
      notify('ra.notify.updated')
      if (onRedirect) {
        onRedirect()
      } else {
        redirect(`/Order/${orderId}/show`)
      }
    }
  }, [deleteData, isDialogOpen])

  if (loading) {
    return <Loading />
  }

  if (error && id !== 0) {
    return <Typography color="error">{translate('ra.page.error')}</Typography>
  }

  return (
    <>
      <FormWithRedirect
        {...props}
        record={data}
        resource="Person"
        save={onSubmit}
        // "saving" boolean coming out from render not working ://
        render={({ handleSubmitWithRedirect, pristine, record }: any): any => (
          <Box display="flex" flexDirection="column">
            <Typography variant="h4">Route</Typography>
            <Box mt={1} />
            <ReferenceInput
              reference="Airport"
              source="departureAirportId"
              filterToQuery={filterToQueryQ}
              label={translate('resources.Route.fields.departure')}
              variant="outlined"
            >
              <AutocompleteInputInDrawer optionText={airportOptionText} fullWidth />
            </ReferenceInput>
            <ReferenceInput
              reference="Airport"
              source="arrivalAirportId"
              filterToQuery={filterToQueryQ}
              label={translate('resources.Route.fields.arrival')}
              variant="outlined"
            >
              <AutocompleteInputInDrawer optionText={airportOptionText} fullWidth />
            </ReferenceInput>
            <Typography variant="h4">Aircraft</Typography>
            <Box mt={1} />
            <ReferenceInput
              source="vehicleId"
              reference="Vehicle"
              filterToQuery={filterToQueryLicensePlate}
              variant="outlined"
            >
              <AutocompleteInputInDrawer optionText="licensePlate" fullWidth />
            </ReferenceInput>
            <Typography variant="h4">Info</Typography>
            <Box mt={1} />
            <TextInput source="flightNumber" fullWidth variant="outlined" />
            <NumberInput source="passengersQuantity" fullWidth variant="outlined" />
            {/* <DateTimeInput source="flightDate" fullWidth variant="outlined" /> */}
            <NoTimezoneDateTimeInput source="flightDate" fullWidth variant="outlined" />
            <NumberInput source="flightDuration" fullWidth variant="outlined" />
            {/* <DateTimeInput source="arrivalDate" fullWidth variant="outlined" /> */}
            <NoTimezoneDateTimeInput source="arrivalDate" fullWidth variant="outlined" />
            {/* <TextInput source="notes" multiline fullWidth variant="outlined" /> */}
            <RichTextInput source="notes" multiline fullWidth />
            <Box mt={1}>
              <Typography variant="h4">Crew Info</Typography>
              <TextInput source="captainName" fullWidth variant="outlined" />
              <TextInput source="captainNumber" fullWidth variant="outlined" />
              <TextInput source="firstOfficerName" fullWidth variant="outlined" />
              <TextInput source="firstOfficerNumber" fullWidth variant="outlined" />
              <TextInput source="flightAttendantName" fullWidth variant="outlined" />
              <TextInput source="flightAttendantNumber" fullWidth variant="outlined" />
            </Box>
            <Box mt={1}>
              <Typography variant="h4">Handling Info</Typography>
              <TextInput source="departureHandlingName" fullWidth variant="outlined" />
              <TextInput source="departureHandlingNumber" fullWidth variant="outlined" />
              <TextInput source="arrivalHandlingName" fullWidth variant="outlined" />
              <TextInput source="arrivalHandlingNumber" fullWidth variant="outlined" />
            </Box>
            <Box mt={1}>
              <Typography variant="h4">Other</Typography>
              <NumberInput source="boardingTime" fullWidth variant="outlined" />
              <TextInput source="luggages" fullWidth variant="outlined" label="Luggage" />
              <TextInput source="catering" fullWidth variant="outlined" />
            </Box>
            <Box mt={3}>
              {id !== 0 && (
                <BaseButton fullWidth onClick={handleDialogOpen} style={{ backgroundColor: 'darkred', color: 'white' }}>
                  Delete route
                </BaseButton>
              )}
              <SaveButton
                size="large"
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                saving={saving}
                disabled={pristine || saving}
              />
            </Box>
          </Box>
        )}
      />
      <Confirm
        isOpen={isDialogOpen}
        loading={loadingDelete}
        title="Delete Route"
        content="Are you sure you want to delete the specified route?"
        onConfirm={deleteRoute}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default InDrawerOrderRouteForm
