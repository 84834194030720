import { Spinner, TableCaption } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import { Typography } from '@material-ui/core'

interface TableContentProps {
  loading?: boolean
  error?: boolean
  children: ReactNode
}

export const TableContent = ({ children, loading, error }: TableContentProps) => {
  if (loading) {
    return (
      <TableCaption>
        <Spinner size="lg" m="4" />
      </TableCaption>
    )
  }

  if (error) {
    return (
      <TableCaption>
        <ErrorIcon color="error" />
        <Typography variant="body2" color="textPrimary">
          (Aggiorna la pagina per riprovare)
        </Typography>
      </TableCaption>
    )
  }

  return <>{children}</>
}
