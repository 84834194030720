import { useGetOne } from 'ra-core'
import React, { FC, useMemo } from 'react'
import FirstRouteField from './FirstRouteField'
import ErrorIcon from '@material-ui/icons/Error'
import LinearProgress from '@material-ui/core/LinearProgress'

type Props = {
  record?: Record<string, any>
  source?: string
  label?: string
}

const FirstOrderRouteField: FC<Props> = ({ record = {}, source = 'routes', ...props }) => {
  const { loading, error, data } = useGetOne(
    'OrderRoute',
    record && record[source] && record[source][0] && record[source][0].id ? record[source][0].id : ''
  )

  const newRecord = useMemo(() => {
    const routes = [...record[source]]
    routes[0] = data
    return { ...record, [source]: [...routes] }
  }, [data, record[source]])

  if (loading) return <LinearProgress />
  if (error) return <ErrorIcon color="error" />
  if (data) return <FirstRouteField {...props} record={newRecord} source={source} />
  return null
}

export default FirstOrderRouteField
