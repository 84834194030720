import React, { FC, useMemo, useCallback, useRef, useState, useEffect } from 'react'
import FullCalendar, { EventSourceFunc, DatesSetArg, EventClickArg, EventContentArg } from '@fullcalendar/react'
import adaptivePlugin from '@fullcalendar/adaptive'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import listPlugin from '@fullcalendar/list'
import { useApolloClient, useQuery } from '@apollo/client'
import { useDataProvider, useVersion } from 'ra-core'
import { Loading, fetchStart, fetchEnd, useRedirect } from 'react-admin'
import interactionPlugin from '@fullcalendar/interaction'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
// import PlanningCustomersList from './PlanningCustomersList'
// import PlanningEventTemplatesList from './PlanningEventTemplatesList'
import { QUERY_GET_EVENT_STATUSES_CONFIGURATION } from '../../queries'
import Tooltip from '@material-ui/core/Tooltip'
import { useCalendarStyles } from './Calendar.styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import MonthlyPreviewPicker from './MonthlyPreviewPicker'
import CalendarToolbar from './CalendarToolbar'
import { useDispatch } from 'react-redux'

import {
  subHours,
  addHours,
  getWeekOfMonth,
  isWithinInterval,
  startOfMonth,
  endOfMonth,
  startOfISOWeek,
  endOfISOWeek,
  getMonth,
} from 'date-fns'
import enLocale from '@fullcalendar/core/locales/en-gb'
import dateFnsEnLocale from 'date-fns/locale/en-GB'
import { CalendarViewModes, isValidCalendarViewMode } from './CalendarViewModeMenu'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import FlightEvent from './FlightEvent'

const DEFAULT_TIME_FORMAT: any = {
  hour: 'numeric',
  minute: '2-digit',
  meridiem: false,
  hour12: false,
}

const GRIDTOLISTVIEWS = {
  [CalendarViewModes.WEEK]: 'listWeek',
  [CalendarViewModes.DAY]: 'listDay',
}

type Props = {
  basePath?: string
}

const isEventEditable = (eventStatusConfiguration: any, item: any): boolean => {
  return eventStatusConfiguration.getConfiguration && eventStatusConfiguration.getConfiguration.value
    ? item.customerEvents.length > 0 && item.customerEvents[0].status
      ? item.customerEvents[0].status.id === eventStatusConfiguration.getConfiguration.value.created
      : true
    : false
}

const EventTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: '#f5f5f9',
    // color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    minWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    // border: '1px solid #dadde9',
  },
}))(Tooltip)

const CustomCalendar: FC<Props> = (props) => {
  const redirect = useRedirect()
  const client = useApolloClient()
  const dataProvider = useDataProvider()
  // const [data, setData] = useState<any>(undefined)
  const [flightEvents, setFlightEvents] = useState<any[]>([])

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)
  const [fetchInterval, setFetchInterval] = useState<Record<string, Date | number | null>>({
    start: null,
    end: null,
    currentMonth: null,
  })

  const dispatch = useDispatch()
  const { search } = useLocation()
  const fullCalendarRef = useRef<FullCalendar>()
  const listCalendarRef = useRef<FullCalendar>()
  const calendarContainerRef = useRef<any>()
  const [calTitle, setCalTitle] = useState<string>('')
  const history = useHistory()
  const viewVersion = useVersion()
  const [fastEventsOpen, setFastEventsOpen] = useState<boolean>(
    localStorage.getItem('fastEventsOpen') === 'false' ? false : true
  )
  const [eventsListOpen, setEventsListOpen] = useState<boolean>(
    localStorage.getItem('eventsListOpen') === 'true' ? true : false
  )
  const classes = useCalendarStyles({ ...props, eventsListOpen, fastEventsOpen })
  const { loading: configurationLoading, data: eventStatusConfiguration } = useQuery(
    QUERY_GET_EVENT_STATUSES_CONFIGURATION
  )

  const parsedQueryString = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const viewMode = useMemo<CalendarViewModes>(() => {
    let newViewMode = CalendarViewModes.WEEK
    if (parsedQueryString.viewMode) {
      if (typeof parsedQueryString.viewMode === 'string') {
        if (isValidCalendarViewMode(parsedQueryString.viewMode as any)) {
          newViewMode = parsedQueryString.viewMode as any
        }
      } // maybe check if it is an array??
    }
    return newViewMode
  }, [parsedQueryString])

  const selectedDateString = useMemo<string>(() => {
    if (parsedQueryString.date) {
      return typeof parsedQueryString.date === 'string' ? parsedQueryString.date : parsedQueryString.date[0]
    }
    return new Date().toISOString()
  }, [parsedQueryString])

  const handleToggleFastEvents = useCallback(() => {
    setFastEventsOpen(!fastEventsOpen)
    localStorage.setItem('fastEventsOpen', JSON.stringify(!fastEventsOpen))
  }, [fastEventsOpen])

  const onEventClick = useCallback(
    (e: EventClickArg) => {
      if (e.event.id && e.event.id.indexOf('course-planning-') === -1 && e.event.id.indexOf('exam-planning-') === -1) {
        history.push({
          pathname: `/PlannedEvent/${e.event.id}`,
          search: search,
        })
      }
    },
    [props.basePath, search]
  )

  const onCalendarViewChange = (arg: DatesSetArg): void => {
    console.log('onCalendarViewChange()', arg)
    // console.log('getEvents()', arg.view.calendar.getEvents())
    setCalTitle(arg.view.title)
  }

  const onCalPrev = useCallback(() => {
    fullCalendarRef.current!.getApi().prev()
    listCalendarRef.current!.getApi().prev()
    history.push({
      pathname: props.basePath,
      search: queryString.stringify({
        ...parsedQueryString,
        date: fullCalendarRef.current!.getApi().view.currentStart.toISOString(),
      }),
    })
  }, [fullCalendarRef.current, history, parsedQueryString])

  const onCalNext = useCallback(() => {
    fullCalendarRef.current!.getApi().next()
    listCalendarRef.current!.getApi().next()
    history.push({
      pathname: props.basePath,
      search: queryString.stringify({
        ...parsedQueryString,
        date: fullCalendarRef.current!.getApi().view.currentStart.toISOString(),
      }),
    })
  }, [fullCalendarRef.current, history, parsedQueryString])

  const onCalToday = useCallback(() => {
    fullCalendarRef.current!.getApi().today()
    listCalendarRef.current!.getApi().today()
    history.push({
      pathname: props.basePath,
      search: queryString.stringify({
        ...parsedQueryString,
        // date: fullCalendarRef.current!.getApi().view.currentStart.toISOString(),
        date: fullCalendarRef.current!.getApi().getDate().toISOString(),
      }),
    })
  }, [fullCalendarRef.current, history, parsedQueryString])

  const onViewModeChange = useCallback(
    (viewMode: string): void => {
      history.push({
        pathname: props.basePath,
        search: queryString.stringify({ ...parsedQueryString, viewMode }),
      })
    },
    [fullCalendarRef.current, parsedQueryString]
  )

  const onFullCalendarRef = useCallback((ref: FullCalendar) => {
    fullCalendarRef.current = ref
    // fullCalendarRef.current.getApi().
  }, [])
  const onListCalendarRef = useCallback((ref: FullCalendar) => {
    listCalendarRef.current = ref
    // fullCalendarRef.current.getApi().
  }, [])

  const selectedDate = useMemo<Date>(() => {
    console.log(new Date(selectedDateString), 'selectedDate')
    return new Date(selectedDateString)
  }, [selectedDateString])

  const onCalGoToDate = useCallback(
    (date: any) => {
      // fullCalendarRef.current!.getApi().gotoDate(date)
      history.push({
        pathname: props.basePath,
        search: queryString.stringify({
          ...parsedQueryString,
          date: date.toISOString(),
        }),
      })
    },
    [history, parsedQueryString]
  )

  const onFlightEvents = useCallback<EventSourceFunc>(
    (info, success, error) => {
      const fetchEvents = async (): Promise<void> => {
        try {
          const start = startOfISOWeek(startOfMonth(selectedDate))
          const end = endOfISOWeek(endOfMonth(selectedDate))
          const currentMonth = getMonth(selectedDate)

          if (
            currentMonth === fetchInterval.currentMonth &&
            isWithinInterval(selectedDate, { start: fetchInterval.start as Date, end: fetchInterval.end as Date })
          ) {
            console.log('XXX NO NEED TO FETCH')
            return success(flightEvents)
          }

          dispatch(fetchStart())
          console.log('XXX FEEETCHING: ', info)
          const reslt = await dataProvider.getList('OrderRoute', {
            pagination: { page: 1, perPage: 1000 },
            filter: {
              // startDate: info.startStr,
              // endDate: info.endStr,
              startDate: start.toUTCString(),
              endDate: end.toUTCString(),
            },
            sort: {
              field: 'flightDate',
              order: 'ASC',
            },
          })

          console.log('XXX result', reslt)
          let finalData = []
          if (reslt) {
            finalData = reslt.data.map((item: any) => ({
              ...item,
              title: `Flight from ${item.departureAirportId} to ${item.arrivalAirportId}`,
              start: item.flightDate,
              end: item.arrivalDate,
              extendedProps: {
                isFlightEvent: true,
                orderId: item.orderId,
                departureAirportId: item.departureAirportId,
                arrivalAirportId: item.arrivalAirportId,
              },
            }))
          }
          setFlightEvents(finalData)
          success(finalData)
          setFetchInterval({ start, end, currentMonth })
        } catch (e) {
          console.error(e)
          error({ message: 'Error fetching Planned Event' })
        } finally {
          dispatch(fetchEnd())
        }
      }

      fetchEvents()
    },
    [eventStatusConfiguration, selectedDate, fetchInterval, flightEvents]
  )

  const eventContent = useCallback((arg: EventContentArg) => {
    if (arg.event.extendedProps.isFlightEvent) {
      return <FlightEvent calendarEvent={arg} locale={dateFnsEnLocale} />
    } else {
      return null
    }
  }, [])

  const onLoading = (isLoading: any): void => setIsDataLoading(isLoading)

  useEffect(() => {
    if (fullCalendarRef.current) {
      fullCalendarRef.current!.getApi().changeView(viewMode)
    }
  }, [viewMode, fullCalendarRef])

  useEffect(() => {
    if (listCalendarRef.current) {
      listCalendarRef.current!.getApi().changeView(GRIDTOLISTVIEWS[viewMode])
    }
  }, [viewMode, listCalendarRef])

  useEffect(() => {
    if (fullCalendarRef.current && selectedDateString) {
      const date = new Date(selectedDateString)
      if (fullCalendarRef.current.getApi().view.activeStart.getTime() !== date.getTime()) {
        fullCalendarRef.current.getApi().gotoDate(date)
        if (listCalendarRef?.current) {
          listCalendarRef.current.getApi().gotoDate(date)
        }
      }
    }
  }, [selectedDateString, fullCalendarRef, listCalendarRef])

  if (configurationLoading) {
    return <Loading />
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsEnLocale}>
      <CalendarToolbar
        onCalToday={onCalToday}
        onCalPrev={onCalPrev}
        onCalNext={onCalNext}
        onToggleCalendarSidebarOpen={handleToggleFastEvents}
        calendarSidebarOpen={fastEventsOpen}
        dateTitle={calTitle}
        viewMode={viewMode}
        onViewModeChange={onViewModeChange}
        // onTwoWeeksClick={onSwitchCalView('resourceTimeGridTwoWeeksDay')}
        // onWeekClick={onSwitchCalView('resourceTimeGridWeek')}
        // onDayClick={onSwitchCalView('resourceTimeGridDay')}
        calendarRef={fullCalendarRef}
        // resourceMode={selectedResourceMode}
        // onResourceModeChange={handleResourceModeChange}
        // selectedResourceIds={selectedResourceIds}
        // onResourcesSelected={onResourcesSelected}
        // vehicleHashTable={vehiclesHashTable}
        // vehicles={vehicles}
        // vehiclesLoading={vehiclesLoading}
        // teamMembersHashTable={teamMembersHashTable}
        // teamMembers={teamMembers}
        // teamMembersLoading={teamMembersLoading}
        calendarContainerRef={calendarContainerRef}
      />

      <div className={classes.root}>
        <Box display="flex" flexDirection="row" className={classes.container}>
          <Box pr={2} ml={1} py={1} className={classes.calendarFastEventTemplatesContainer} width={300}>
            <MonthlyPreviewPicker
              value={selectedDate}
              onChange={onCalGoToDate}
              viewMode={viewMode}
              primaryEvents={flightEvents}
            />

            <FullCalendar
              ref={onListCalendarRef}
              locale={enLocale}
              allDaySlot={true}
              schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
              headerToolbar={false}
              plugins={[listPlugin]}
              initialDate={selectedDateString}
              nowIndicator={true}
              height="100%"
              datesSet={onCalendarViewChange}
              initialView={GRIDTOLISTVIEWS[viewMode]}
              firstDay={1}
              eventTimeFormat={DEFAULT_TIME_FORMAT}
              slotLabelFormat={DEFAULT_TIME_FORMAT}
              eventSources={[
                {
                  events: onFlightEvents,
                  backgroundColor: '#ffca68',
                  borderColor: '#ffca68',
                  textColor: 'black',
                  editable: false,
                },
              ]}
            />
          </Box>
          <Box flex="1" className={classes.calendarSectionContainer}>
            <div className={classes.calendarContainer} ref={calendarContainerRef}>
              <Backdrop className={classes.backdrop} open={isDataLoading}>
                <CircularProgress color="primary" size={60} />
              </Backdrop>
              <FullCalendar
                ref={onFullCalendarRef}
                locale={enLocale}
                allDaySlot={true}
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                headerToolbar={false}
                plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, resourceTimeGridPlugin, adaptivePlugin]}
                selectable={true}
                droppable={true}
                editable={true}
                initialDate={selectedDateString}
                // eventResize={onEventResize}
                // eventReceive={onFastEventDrop}
                // eventDrop={onEventDrop}
                // select={onDateSelected}
                // eventClick={onEventClick}
                // eventContent={eventContent}
                // resourceLabelContent={resourceLabelContent}
                // dayHeaderContent={dayHeaderContent}
                // slotLabelContent={slotLabelContent}
                // dayHeaders={true}
                nowIndicator={true}
                height="100%"
                datesSet={onCalendarViewChange}
                initialView={viewMode}
                firstDay={1}
                eventTimeFormat={DEFAULT_TIME_FORMAT}
                slotLabelFormat={DEFAULT_TIME_FORMAT}
                // events={onEvents}
                eventSources={[
                  {
                    events: onFlightEvents,
                    backgroundColor: '#ffca68',
                    borderColor: '#ffca68',
                    textColor: 'black',
                    editable: false,
                  },
                ]}
                loading={onLoading}
                eventContent={eventContent}
                eventClassNames={classes.fcEvent}
              />
            </div>
          </Box>
        </Box>
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default CustomCalendar
