import React, { FC, useCallback, useState } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslate, useRedirect, useDeleteWithConfirmController } from 'ra-core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core'
import { Confirm } from 'ra-ui-materialui'
import inflection from 'inflection'

const useStyles = makeStyles((theme) => ({
  iconMore: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: 29,
    height: 33,
    borderRadius: theme.shape.borderRadius,
  },
}))

type Props = {
  record?: any
  basePath?: string
  resource?: string
  disableEdit?: boolean
  hideEdit?: boolean
}

const TeamMemberMoreMenu: FC<Props> = ({
  record = {},
  basePath = '',
  resource = '',
  disableEdit = false,
  hideEdit = false,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const redirect = useRedirect()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const {
    open,
    loading,
    handleDialogOpen: handleDeleteDialogOpen,
    handleDialogClose: handleDeleteModalClose,
    handleDelete,
  } = useDeleteWithConfirmController({
    resource,
    record,
    redirect: false,
    basePath,
    onClick: undefined,
  })

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const onItemEditClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      setAnchorEl(null)
      redirect(`${basePath}/${record.id}`)
    },
    [record, basePath]
  )

  const onItemDeleteClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      onMoreClose(event)
      handleDeleteDialogOpen(event)
    },
    [onMoreClose, handleDeleteDialogOpen]
  )

  return (
    <>
      <IconButton className={classes.iconMore} edge="start" onClick={onMoreClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`more-${resource}-${record.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMoreClose}
      >
        {!hideEdit && (
          <MenuItem onClick={onItemEditClick} disabled={disableEdit}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            {translate('ra.action.edit')}
          </MenuItem>
        )}
        <MenuItem onClick={onItemDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          {translate('ra.action.delete')}
        </MenuItem>
      </Menu>
      <Confirm
        isOpen={open}
        loading={loading}
        title="ra.message.delete_title"
        content="ra.message.delete_content"
        ConfirmIcon={({ props, context }) => {
          return ''
        }}
        CancelIcon={({ props, context }) => {
          return ''
        }}
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1,
            _: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: inflection.singularize(`${resource}`),
              }),
              true
            ),
          }),
          id: record.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDeleteModalClose}
      />
    </>
  )
}

export default TeamMemberMoreMenu
