import React, { FC, useCallback } from 'react'
import { useGetOne } from 'ra-core'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FaDownload } from 'react-icons/fa'
import ErrorIcon from '@material-ui/icons/Error'

type Props = {
  id: number | string
}

const DownloadDocumentButton: FC<Props> = ({ id }) => {
  const { loading, error, data } = useGetOne('Document', id)

  const onDownloadClick = useCallback(() => {
    if (data && data.url) {
      window.open(data.url, '_blank')
    }
  }, [data])

  if (loading) return <CircularProgress size={10} />
  if (error) return <ErrorIcon htmlColor="darkred" />
  if (data)
    return (
      <IconButton onClick={onDownloadClick}>
        <FaDownload color="#003d60" />
      </IconButton>
    )
  return null
}

export default DownloadDocumentButton
