import React, { FC, useMemo } from 'react'
import { useGetMany } from 'ra-core'
import Chip from '@material-ui/core/Chip'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'

type Props = {
  record?: Record<string, any>
  source?: string
  label?: string
}

const FirstRouteField: FC<Props> = ({ record = {}, source = 'routes', ...props }) => {
  const { loading, data, error } = useGetMany(
    'Airport',
    record &&
      record[source] &&
      record[source][0] &&
      record[source][0].departureAirportId &&
      record[source][0].arrivalAirportId
      ? [record[source][0].departureAirportId, record[source][0].arrivalAirportId]
      : []
  )

  const departure = useMemo(
    () =>
      data && Array.isArray(data)
        ? data.find(
            ({ id }: any = {}) =>
              record &&
              record[source] &&
              record[source][0] &&
              record[source][0].departureAirportId &&
              record[source][0].departureAirportId
          )
        : undefined,
    [data]
  )
  const arrival = useMemo(
    () =>
      data && Array.isArray(data)
        ? data.find(
            ({ id }: any = {}) =>
              record &&
              record[source] &&
              record[source][0] &&
              record[source][0].arrivalAirportId &&
              id === record[source][0].arrivalAirportId
          )
        : undefined,
    [data]
  )

  if (loading) return <LinearProgress />
  if (error || !departure || !arrival) return <ErrorIcon color="error" />

  return (
    <>
      <Chip
        color="primary"
        size="small"
        label={`${departure.iataCode || departure.icaoCode} - ${arrival.iataCode || arrival.icaoCode}`}
      />
      {record[source] && record[source].length > 1 && (
        <Typography variant="body2" display="inline">{` + ${record[source].length - 1}`}</Typography>
      )}
    </>
  )
}

export default FirstRouteField
