import React, { FC, useCallback } from 'react'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import { Form } from 'react-final-form'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useNotify, useRefresh } from 'ra-core'
import { ActivityTypeSelectInput } from './ActivityTypeSelectInput'
import { ActivityDateTimeInput } from './ActivityDateTimeInput'
import { ActivityTextValueInput } from './ActivityTextValueInput'
import { QUERY_GET_NOTE_ACTIVITY_TYPE } from '../../queries'

export type CreateActivityFormProps = {
  onCreated: () => void
  mutation: any
  source: string
  id: any
  showActivityTypes?: boolean
}

export const CreateActivityForm: FC<CreateActivityFormProps> = ({
  mutation,
  source,
  id,
  onCreated,
  showActivityTypes = true,
}) => {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()

  const { loading, data } = useQuery(QUERY_GET_NOTE_ACTIVITY_TYPE, {
    skip: showActivityTypes,
  })

  const [logActivity, { loading: submitting }] = useMutation(mutation)

  const handleOnSubmit = useCallback(
    async (values: any, form: any) => {
      if (loading || submitting) {
        return
      }

      if (!showActivityTypes && !data?.activityTypeNote?.id) {
        notify('error creating activity, Note type not found', 'error')
        console.error('error creating activity, Note type not found')
        return
      }

      const { data: result, errors } = await logActivity({
        variables: {
          data: {
            [source]: id,
            textValue: values?.textValue,
            date: values?.activityDateTime,
            typeId: showActivityTypes ? values?.type : data.activityTypeNote.id,
          },
        },
      })

      if (errors) {
        notify('error creating activity', 'error')
        console.error('error creating activity', errors)
      } else {
        notify('Log created', 'info')
        onCreated()
        setTimeout(() => form.change('textValue', ''))
      }
    },
    [refresh, logActivity, source, id, onCreated, notify, showActivityTypes, data, loading, submitting]
  )

  return (
    <Form
      onSubmit={handleOnSubmit}
      render={({ handleSubmit, invalid, values, form }): React.ReactElement => (
        <form className={classes.root} onSubmit={handleSubmit}>
          {showActivityTypes && <ActivityTypeSelectInput />}
          <ActivityTextValueInput />

          <div hidden={!values?.textValue}>
            <ActivityDateTimeInput />
            <div className={classes.actionsContainer}>
              <Button onClick={(): void => form.change('textValue', '')}>Cancel</Button>
              <Button disabled={submitting || loading} onClick={handleSubmit}>
                {submitting && <CircularProgress size={20} />}
                Save
              </Button>
            </div>
          </div>
        </form>
      )}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  actionsContainer: {
    paddingBottom: theme.spacing(2),
  },
  datesContainer: {},
  title: {
    marginBottom: '20px',
  },
  inputSkeleton: {
    width: '100%',
    height: '40px',
    marginBottom: '37px',
  },
  selectTemplateButtonContainer: {
    marginBottom: '20px',
  },
  attachmentsContainer: {
    marginBottom: '20px',
  },
}))
