import React, { FC, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@material-ui/core'
import { ActivityMoreMenu } from './ActivityMoreMenu'
import { ReferenceField, TextField } from 'react-admin'
import { CustomerMailItem, CustomerMailItemProps } from '../gmail/CustomerMailItem'

type Props = {
  activity: any
  onDeleteComplete: () => void
  deleteMutation: any
} & Partial<Omit<CustomerMailItemProps, 'simpleMail'>>

const ServiceLogValue: FC<any> = ({ activity }) => {
  const action = useMemo((): string => {
    if (typeof activity?.action === 'string') {
      const str = activity.action.split(activity.domain)[0]

      switch (str) {
        case 'create':
          return 'created a new'
        case 'update':
          return 'updated an existing'
        case 'delete':
          return 'deleted an existing'
        default:
          if (activity.action.includes('confirmQuoteOption')) {
            return 'confirmed a quote option'
          } else if (activity.action.includes('addPassenger')) {
            return 'added a'
          } else if (activity.action.includes('removePassenger')) {
            return 'removed a'
          } else {
            return 'performed an action with a'
          }
      }
    }
    return '  '
  }, [activity])

  return (
    <>
      <Typography variant="body2">
        <ReferenceField basePath="/User" record={activity} label={false} source="userId" reference="User" link={false}>
          <TextField source="firstName" />
        </ReferenceField>
        {` ${action} ${activity?.domain} `}
      </Typography>
    </>
  )
}

const CustomerActivityValue: FC<any> = ({ activity }) => {
  return (
    <Typography variant="body2">
      {`${activity.textValue} - by `}
      <ReferenceField
        basePath="/User"
        record={activity}
        label={false}
        source="createdUserId"
        reference="User"
        link={false}
      >
        <TextField source="fullName" />
      </ReferenceField>
    </Typography>
  )
}

export const ActivityItem: FC<Props> = ({
  activity,
  onDeleteComplete,
  customerEmail,
  loggedUserMail,
  peopleEmails,
  deleteMutation,
}) => {
  const classes = useStyles()

  const iconString = useMemo((): string => {
    if (activity.type && activity.type.icon) {
      return `${activity.type.icon}`
    } else if (activity.domain) {
      switch (activity.domain) {
        case 'Passenger':
          return 'person'
        case 'Person':
          return 'account_circle'
        case 'CieloQuote':
          return 'format_list_numbered'
        case 'Order':
          return 'playlist_add_check'
        case 'OrderRoute':
          return 'flight'
        case 'Address':
          return 'business'
        case 'Customer':
          return 'contact_mail'
        default:
          return 'note'
      }
    } else {
      return 'note'
    }
  }, [activity])

  if (activity.__typename === 'FullEmailObject') {
    return (
      <CustomerMailItem
        simpleMail={activity}
        loggedUserMail={loggedUserMail}
        customerEmail={customerEmail}
        peopleEmails={peopleEmails}
      />
    )
  }

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Tooltip
          title={activity.type ? activity.type.label : activity.domain}
          aria-label={activity.type ? activity.type.label : activity.domain}
        >
          <Avatar className={classes.avatar}>
            <span className="material-icons">{iconString}</span>
          </Avatar>
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        primary={
          activity.textValue ? <CustomerActivityValue activity={activity} /> : <ServiceLogValue activity={activity} />
        }
        secondary={new Date(activity.date || '').toLocaleString()}
      />
      {(activity.__typename === 'CustomerActivity' ||
        activity.__typename === 'CieloQuoteActivity' ||
        activity.__typename === 'CieloOrderActivity') && (
        <ActivityMoreMenu
          deleteMutation={deleteMutation}
          onDeleteComplete={onDeleteComplete}
          resource="CustomerActivity"
          record={activity}
        />
      )}
    </ListItem>
  )
  // return (
  //   <div className={classes.root}>
  //     <ActivityMoreMenu onDeleteComplete={onDeleteComplete} resource="CustomerActivity" record={activity} />
  //     {activity.type ? (
  //       <>
  //         type: <span className="material-icons">{activity.type.icon}</span> {activity.type.label}
  //       </>
  //     ) : (
  //       <>NOTA</>
  //     )}
  //     <br />
  //     date: {new Date(activity.date).toLocaleString()}
  //     <br />
  //     value: {activity.textValue}
  //     <br />
  //   </div>
  // )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  avatar: {},
  seeMoreButton: {
    display: 'inline',
  },
}))
