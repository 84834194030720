import React, { FC, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DateInput, TimeInput } from 'react-admin-date-inputs'
import { useFormState, useForm } from 'react-final-form'

type Props = {
  //
}

export const ActivityDateTimeInput: FC<Props> = () => {
  const classes = useStyles()
  const { values } = useFormState()
  const { change } = useForm()

  useEffect(() => {
    if (values?.activityDate && values?.activityTime) {
      const date = new Date(
        values.activityDate.getFullYear(),
        values.activityDate.getMonth(),
        values.activityDate.getDate(),
        values.activityTime.getHours(),
        values.activityTime.getMinutes(),
        values.activityTime.getSeconds()
      )

      console.log(date, 'data trovata')
      change('activityDateTime', date)
    } else {
      change('activityDateTime', null)
    }
  }, [values?.activityDate, values?.activityTime])

  return (
    <div className={classes.root}>
      <DateInput variant="standard" source="activityDate" label="Start date" options={{ format: 'dd/MM/yyyy' }} />
      <TimeInput
        ampm={false}
        className={classes.timeInput}
        variant="standard"
        source="activityTime"
        label="Start time"
        options={{ format: 'HH:mm' }}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  timeInput: {
    marginLeft: theme.spacing(3),
  },
}))
