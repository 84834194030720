import React, { FC } from 'react'
import { TextField } from 'react-admin'
import RAList from '../../components/list/List'
import Datagrid from '../../components/Datagrid'
import { FaPlaneDeparture } from 'react-icons/fa'
import AirportFilters from './AirportFilters'

type Props = {
  [x: string]: any
}

const AirportList: FC<Props> = (props) => {
  return (
    <RAList
      {...props}
      titleIcon={<FaPlaneDeparture />}
      bulkActionButtons={false}
      filters={<AirportFilters />}
      sort={{ field: 'icaoCode', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="icaoCode" />
        <TextField source="name" />
        <TextField source="iataCode" />
        <TextField source="city" />
        <TextField source="country" />
      </Datagrid>
    </RAList>
  )
}

export default AirportList
