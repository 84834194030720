import React, { FC, useCallback, useState } from 'react'
import { useTranslate, useDataProvider, useNotify, useRedirect, useRefresh, useGetOne, FormWithRedirect } from 'ra-core'
import { TextInput } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
// internal components
import Loading from './../../Loading'
import SaveButton from './../../button/SaveButton'

type Props = {
  [x: string]: any
}

const NameOnlyEdit: FC<Props> = ({ id, ...props }) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [saving, setSaving] = useState<boolean>(false)
  const { loading, error, data } = useGetOne(props.resource, id)

  const onSubmit = useCallback(
    async (values) => {
      try {
        setSaving(true)
        const result = await dataProvider.update(props.resource, {
          id,
          data: { ...values },
          previousData: { id },
        })
        console.log('update?? ', result)
        refresh()
        redirect(`/${props.resource}`)
        // UNABLE TO USE ra.notification.updated (smart count not working :/)
        notify('ra.notify.updated')
      } catch (error) {
        console.log('update error: ', error)
        notify('ra.message.error', 'error')
      } finally {
        setSaving(false)
      }
    },
    [data, id, props?.resource]
  )

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Typography color="error">{translate('ra.page.error')}</Typography>
  }

  return (
    <FormWithRedirect
      {...props}
      record={data}
      resource={props.resource}
      save={onSubmit}
      // "saving" boolean coming out from render not working ://
      render={({ handleSubmitWithRedirect, pristine }: any): any => (
        <Box display="flex" flexDirection="column">
          <TextInput source="name" variant="outlined" />
          <Box mt={3}>
            <SaveButton
              size="large"
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              pristine={pristine}
              saving={saving}
              disabled={pristine || saving}
            />
          </Box>
        </Box>
      )}
    />
  )
}

export default NameOnlyEdit
