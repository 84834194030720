import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/details/Create'
import CurrencyForm from './CurrencyForm'

type Props = {}

const CurrencyCreate: FC<Props> = (props) => (
  <Create {...props} component="div">
    <SectionedForm variant="outlined" redirect="list">
      <CurrencyForm {...props} />
    </SectionedForm>
  </Create>
)

export default CurrencyCreate
