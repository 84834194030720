import { Typography } from '@material-ui/core'
import React, { FC } from 'react'
import BaseTypography from '../BaseTypography'
import { OfficeAreaEnum } from './../../customEnums'

type Props = {
  label?: string
  source: string
  record?: Record<string, any>
}

const OfficeAreaField: FC<Props> = ({ label, source, record = {} }) => {
  return (
    <BaseTypography variant="body2" weight="bold">
      {OfficeAreaEnum[record[source]] ? OfficeAreaEnum[record[source]] : 'N.D.'}
    </BaseTypography>
  )
}

export default OfficeAreaField
