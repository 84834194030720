/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback } from 'react'
import { useTranslate, FormWithRedirect, useDataProvider, useNotify } from 'ra-core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Link, useRouteMatch, useLocation, Route, Redirect } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'
import ProductGalleryInput from '../../components/ProductGalleryInput'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Toolbar } from '@material-ui/core'
import {
  TextInput,
  RadioButtonGroupInput,
  NumberInput,
  SaveButton,
  DeleteButton,
  AutocompleteInput,
  ReferenceInput,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import ProductPriceInput from '../../components/ProductPriceInput'
import ProductCategoryInput from '../../components/ProductCategoryInput'
import ProductCombinationsInput from '../../components/ProductCombinationsInput'
import ProductFeatureValueInput from '../../components/ProductFeatureValueInput'
// import ProductCarriersInput from '../../components/ProductCarriersInput'
import ProductDiscountInput from '../../components/ProductDiscountInput'
import HiddenOrganizationField from './../../components/forms/HiddenOrganizationField'

const getTabFullPath = (tab: any, baseUrl: string): string => `${baseUrl}/${tab.props.path}`

const useStyles = makeStyles((theme) => ({
  errorTabButton: { color: theme.palette.error.main },
  content: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

const ProductForm: FC<any> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const match = useRouteMatch()
  const location = useLocation()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const baseUrl = match ? match.url : location.pathname

  const hiddenStyle = { display: 'none' }

  const handleSubmitWithRedirect = useCallback(
    (formProps: any) => async (...args: any[]): Promise<any> => {
      // change the average_note field value
      const { values } = formProps.form.getState()

      if (values.productFeatureValues) {
        formProps.form.change(
          'productFeatureValues',
          values.productFeatureValues.filter((value: any): boolean => value.id && value.id !== '')
        )
      }
      if (values.combinations) {
        formProps.form.change('combinations', undefined)
      }
      formProps.handleSubmitWithRedirect(...args)
    },
    [props.record]
  )

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any): React.ReactNode => (
        <form className="tabbed-form">
          <TextInput source="name" resource="Product" fullWidth variant="filled" required />
          <FormDataConsumer>
            {({ formData }: any): any => (
              <Tabs value={location.pathname.split('/').length > 3 ? location.pathname.split('/')[3] : 'basicSettings'}>
                <Tab
                  label={translate('products.edit.tabs.basicSettings')}
                  value={'basicSettings'}
                  className="form-tab"
                  component={Link}
                  to={{ pathname: baseUrl + '/basicSettings' }}
                />
                <Tab
                  label={translate('products.edit.tabs.pricing')}
                  value={'pricing'}
                  className="form-tab"
                  component={Link}
                  to={{ pathname: baseUrl + '/pricing' }}
                />
                {/* <Tab
                  label={translate('products.edit.tabs.shipping')}
                  value={'shipping'}
                  className="form-tab"
                  component={Link}
                  to={{ pathname: baseUrl + '/shipping' }}
                /> */}
                {props.record.id && formData.productQuantityType === 'WITH_COMBINATIONS' && (
                  <Tab
                    label={translate('products.edit.tabs.combinations')}
                    value={'combinations'}
                    className="form-tab"
                    component={Link}
                    to={{ pathname: baseUrl + '/combinations' }}
                  />
                )}
              </Tabs>
            )}
          </FormDataConsumer>

          <Divider />

          <div className={classes.content}>
            <Route exact path={`${baseUrl}/basicSettings`}>
              {(routeProps: any): any => (
                <span style={!routeProps.match ? hiddenStyle : {}}>
                  <Box display="flex">
                    <Box flex={2} p="1rem">
                      <ProductGalleryInput {...formProps} resource="Product" source="galleryId" />
                      <HiddenOrganizationField />
                      <RichTextInput resource="Product" source="description" />
                      {/* <TextInput fullWidth resource="Product" source="slug" />
                      <TextInput multiline fullWidth resource="Product" source="seoDescription" /> */}
                      <ProductFeatureValueInput source="productFeatureValues" mainSource="mainFeatureId" />
                      {/* <ReferenceInput
                        source="brandId"
                        reference="Brand"
                        filterToQuery={(name: string): any => ({ name })}
                      >
                        <AutocompleteInput optionText="name" optionValue="id" />
                      </ReferenceInput> */}
                      <BooleanInput resource="Product" source="active" />
                    </Box>
                    <Box flex={1} p="1rem">
                      {/* <TextInput fullWidth resource="Product" source="barcode" /> */}
                      <TextInput fullWidth resource="Product" source="sku" />
                      <RadioButtonGroupInput
                        row={false}
                        fullWidth
                        resource="Product"
                        source="productQuantityType"
                        defaultValue="SIMPLE"
                        choices={[
                          { id: 'SIMPLE', name: 'Simple' },
                          { id: 'WITH_COMBINATIONS', name: 'With combinations' },
                        ]}
                      />
                      {/* <FormDataConsumer>
                        {({ formData }: any): any =>
                          formData.productQuantityType !== 'WITH_COMBINATIONS' && (
                            <NumberInput fullWidth source="quantity" step={1} />
                          )
                        }
                      </FormDataConsumer> */}
                      <ProductPriceInput />
                      <ProductCategoryInput
                        source="categories"
                        label={translate('resources.Product.fields.categories')}
                        multiSelect
                      />
                    </Box>
                  </Box>
                </span>
              )}
            </Route>

            <Route exact path={`${baseUrl}/pricing`}>
              {(routeProps: any): any => (
                <span style={!routeProps.match ? hiddenStyle : {}}>
                  <Box display="flex" flexDirection="row">
                    <Box flex="1">
                      <ProductPriceInput />
                    </Box>
                    <Box flex="1">
                      <ProductDiscountInput />
                    </Box>
                  </Box>
                </span>
              )}
            </Route>

            <Route exact path={`${baseUrl}/options`}>
              {(routeProps: any): any => <span style={!routeProps.match ? hiddenStyle : {}}></span>}
            </Route>

            {/* <Route exact path={`${baseUrl}/shipping`}>
              {(routeProps: any): any => (
                <span style={!routeProps.match ? hiddenStyle : {}}>
                  <Box display="flex">
                    <ProductCarriersInput source="carriers" multiSelect />
                  </Box>
                </span>
              )}
            </Route> */}

            <FormDataConsumer>
              {({ formData }: any): any => (
                <Route exact path={`${baseUrl}/combinations`}>
                  {(routeProps: any): any => (
                    <div
                      style={
                        !routeProps.match || formData.productQuantityType !== 'WITH_COMBINATIONS' ? hiddenStyle : {}
                      }
                    >
                      <ProductCombinationsInput source="combinations" label="Manage product combinations" />
                    </div>
                  )}
                </Route>
              )}
            </FormDataConsumer>

            <Route exact path={baseUrl}>
              <Redirect exact to={`${baseUrl}/basicSettings`} />
            </Route>
          </div>

          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <SaveButton saving={formProps.saving} handleSubmitWithRedirect={handleSubmitWithRedirect(formProps)} />
              <DeleteButton record={formProps.record} />
            </Box>
          </Toolbar>
        </form>
      )}
    />
  )
}

export default ProductForm
