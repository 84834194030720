import React, { FC } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField, BooleanField } from 'react-admin'
import CurrencyFilters from './CurrencyFilters'
import Datagrid from '../../components/Datagrid'
import { FaEuroSign } from 'react-icons/fa'
import GenericMoreMenu from '../../components/GenericMoreMenu'

const sort = { field: 'name', order: 'ASC' }
const filterDefaultValues = { active: true }

type Props = {}

const CurrencyList: FC<Props> = (props) => (
  <RAList
    {...props}
    sort={sort}
    filterDefaultValues={filterDefaultValues}
    filters={<CurrencyFilters variant="outlined" />}
    titleIcon={<FaEuroSign />}
  >
    <Datagrid>
      <NumberField source="id" />
      <TextField source="name" />
      <TextField source="isoCode" />
      <NumberField source="isoCodeNumber" />
      <TextField source="sign" sortable={false} />
      <TextField source="format" sortable={false} />
      <NumberField source="conversionRate" sortable={false} />
      <BooleanField source="showDecimals" sortable={false} />
      <BooleanField source="showSpace" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <GenericMoreMenu />
    </Datagrid>
  </RAList>
)

export default CurrencyList
