import React, { FC } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField, DateField, TextInput, RadioButtonGroupInput } from 'react-admin'

import Datagrid from '../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'
import { FaUsers } from 'react-icons/fa'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import FilterTextInput from '../../components/FilterTextInput'
import BooleanStatusField from '../../components/BooleanStatusField'
import Filter from '../../components/list/filter/Filter'

type Props = {}

const UserFilters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput label="resources.User.helpers.byEmail" source="email" alwaysOn />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="email" />
      <RadioButtonGroupInput
        label="status.name"
        source="active"
        choices={[
          { id: true, name: 'status.enabled' },
          { id: false, name: 'status.disabled' },
        ]}
      />
    </Filter>
  )
}

const UserList: FC<Props> = (props) => {
  return (
    <RAList
      {...props}
      sort={{ field: 'lastName', order: 'ASC' }}
      titleIcon={<FaUsers />}
      actions={<CreateButton label="resources.User.actions.create" />}
      filters={<UserFilters variant="outlined" />}
    >
      <Datagrid rowClick="show">
        <NumberField source="id" textAlign="left" />
        <TextField source="fullName" />
        <DateField source="createdAt" />
        <TextField source="email" />
        <BooleanStatusField source="active" />
        <GenericMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default UserList
