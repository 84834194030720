import React, { FC, useCallback, useState } from 'react'
import { Confirm } from 'react-admin'
import { useNotify, useRefresh } from 'ra-core'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useApolloClient } from '@apollo/client'
import { DELETE_CIELO_ORDER } from '../../queries'

type Props = {
  record?: any
}

const OrderMoreMenu: FC<Props> = ({ record = { id: 0 } }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const apolloClient = useApolloClient()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleDialogClose = useCallback((): void => setIsDialogOpen(false), [])
  const handleDialogOpen = useCallback((): void => setIsDialogOpen(true), [])

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const onDeleteClick = useCallback((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    onMoreClose(event)
    handleDialogOpen()
  }, [])

  const deleteOrder = useCallback(async () => {
    try {
      setLoading(true)
      const result = await apolloClient.mutate({
        mutation: DELETE_CIELO_ORDER,
        variables: { id: record.id },
      })

      if (result && result.data && !result.data.deleteCieloOrder) {
        throw new Error('error deleting order ')
      }
      notify('ra.notification.deleted')
    } catch (err) {
      console.error('Delete order error: ', err.message)
      notify(err.message, 'error')
    } finally {
      setLoading(false)
      refresh()
    }
  }, [record])

  return (
    <>
      <IconButton onClick={onMoreClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`more-order-${record.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMoreClose}
      >
        <MenuItem onClick={onDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          {'Delete order'}
        </MenuItem>
      </Menu>
      <Confirm
        isOpen={isDialogOpen}
        loading={loading}
        title="Delete Order"
        content="Are you sure you want to delete this order?"
        onConfirm={deleteOrder}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default OrderMoreMenu
