import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from 'apollo-link-error'
import userManager from './userManager'
import { createUploadLink } from 'apollo-upload-client'
import { AnyARecord } from 'dns'

const { REACT_APP_API_GATEWAY_URI } = process.env

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token')
  const user = await userManager.getUser()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: user && user.access_token ? user.access_token : '',
    },
  }
})

export const client = new ApolloClient({
  link: ApolloLink.from([
    // httpLink,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    authLink,
    createUploadLink({
      uri: REACT_APP_API_GATEWAY_URI,
      credentials: 'same-origin',
    }),
  ]),
  // link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
