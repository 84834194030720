import React, { FC } from 'react'
import { NumberInput, TextInput, NullableBooleanInput } from 'react-admin'
import FilterTextInput from '../../components/FilterTextInput'
import Filter from '../../components/list/filter/Filter'

type Props = {
  [x: string]: any
}

const CurrencyFilters: FC<Props> = (props) => (
  <Filter {...props}>
    <NumberInput source="id" />
    <FilterTextInput source="name" alwaysOn />
    <TextInput source="isoCode" />
    <NumberInput source="isoCodeNumber" />
    <TextInput source="sign" />
    <NullableBooleanInput source="active" />
  </Filter>
)

export default CurrencyFilters
