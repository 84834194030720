import { ORDER_STATUS } from '../OrderStatusDropDown'

export type QuoteTotalByStatus = {
  status: string
  count: number
}

export type OrderTotalByStatus = {
  status: string
  count: number
  SUM_purchasePrice: number
  SUM_commissions: number
  SUM_passengerTax: number
}

export type FlightTotalByOrderStatus = {
  status: string
  count: number
  SUM_flightDuration: number
}

export type MostFrequentRoutes = {
  departureAirportId: string
  arrivalAirportId: string
  count: number
}

type GenericByStatus = { status: string; count: number }

export function groupByStatus<T extends GenericByStatus[]>(
  statuses: 'all' | string[],
  totalsByStatus?: T
): Omit<T[number], 'status'> {
  if (!totalsByStatus || totalsByStatus.length === 0) {
    return {} as Omit<T[number], 'status'>
  }

  const first = totalsByStatus[0] as Omit<T[number], 'status'>

  const defaultResult = Object.entries(first).reduce((acc, [key, value]) => {
    if (typeof value === 'number') {
      Object.assign(acc, { [key]: 0 })
    }

    return acc
  }, {} as Omit<T[number], 'status'>)

  totalsByStatus.forEach((metric) => {
    if (statuses !== 'all' && !statuses.includes(metric.status)) {
      return
    }

    Object.keys(defaultResult).forEach((key) => {
      const _key = key as keyof typeof metric

      if (typeof metric[_key] === 'number') {
        // @ts-ignore
        defaultResult[key] += metric[_key]
      }
    })
  })

  return defaultResult
}
