import React, { FC, useCallback, useState } from 'react'
import { required } from 'react-admin'
import { Form } from 'react-final-form'
import RichTextInput from 'ra-input-rich-text'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import LangWithFlagSelectInput from '../../components/input/LangWithFlagSelectInput'
import { useDataProvider, useGetOne, useNotify, useRedirect, useRefresh } from 'ra-core'

type Props = {
  id: number | string
}

const TemplateValueEditForm: FC<Props> = ({ id }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const [saving, setSaving] = useState<boolean>(false)
  const { loading, error, data } = useGetOne('OrderAdditionalInfoTemplateValue', id)

  const onSubmit = useCallback(async (values) => {
    try {
      setSaving(true)
      const result = await dataProvider.update('OrderAdditionalInfoTemplateValue', {
        id,
        data: { ...values },
        previousData: { id },
      })
      console.log('updateddd:: ', result)
      notify('ra.notify.updated')
      redirect(`/OrderAdditionalInfoTemplate/${values.orderAdditionalInfoTemplateId}/show/`)
      refresh()
    } catch (err) {
      console.error(err)
      notify(err.message, 'error')
    } finally {
      setSaving(false)
    }
  }, [])

  // if (data) console.log('languageeees', data)

  return !loading && data ? (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...data }}
      render={({ handleSubmit, invalid }): React.ReactElement => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Paper>
              <RichTextInput label={false} source="value._html" validate={required()} />
            </Paper>
            <Box mt={4} display="flex" justifyContent="space-between">
              <LangWithFlagSelectInput source="languageId" />
              <Button color="primary" variant="contained" onClick={handleSubmit} disabled={saving || invalid}>
                Update
              </Button>
            </Box>
          </Box>
        </form>
      )}
    />
  ) : (
    <CircularProgress />
  )
}

export default TemplateValueEditForm
