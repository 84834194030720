// TODO: FIXME: UNABLE TO USE TRANSFORM PROP
import React, { FC } from 'react'
import PropTypes from 'prop-types'
import { CreateContextProvider, useCheckMinimumRequiredProps, useCreateController } from 'ra-core'
import { CreateView } from './CreateView'
import { CreateProps } from 'ra-ui-materialui'

/**
 * Page component for the Create view
 *
 * The `<Create>` component renders the page title and actions.
 * It is not responsible for rendering the actual form -
 * that's the job of its child component (usually `<SimpleForm>`),
 * to which it passes pass the `record` as prop.
 *
 * The <Create> component accepts the following props:
 *
 * - actions
 * - aside
 * - component
 * - successMessage
 * - title
 *
 * @example
 *
 * // in src/posts.js
 * import * as React from "react";
 * import { Create, SimpleForm, TextInput } from 'react-admin';
 *
 * export const PostCreate = (props) => (
 *     <Create {...props}>
 *         <SimpleForm>
 *             <TextInput source="title" />
 *         </SimpleForm>
 *     </Create>
 * );
 *
 * // in src/App.js
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 *
 * import { PostCreate } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={...}>
 *         <Resource name="posts" create={PostCreate} />
 *     </Admin>
 * );
 * export default App;
 */
export const Create: FC<CreateProps> = (props) => {
  useCheckMinimumRequiredProps('Create', ['children'], props)
  const controllerProps = useCreateController(props)
  return (
    <CreateContextProvider value={controllerProps}>
      <CreateView {...props} {...controllerProps} />
    </CreateContextProvider>
  )
}

export default Create
