import React, { FC, useCallback, useMemo, useState } from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { IconButton, ListItemIcon, makeStyles, Menu, MenuItem } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { Confirm } from 'react-admin'
import { useDelete, useRefresh } from 'ra-core'

type Props = {
  picture: any
}

export const GalleryEditableFieldItem: FC<Props> = ({ picture }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const menuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [deletePicture, { loading, error, data }] = useDelete('Picture', picture.id)
  const refresh = useRefresh()

  const handleMenuClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleOpenConfirmDialog = useCallback(() => {
    setAnchorEl(null)
    setDialogOpen(true)
  }, [])

  const handleCloseConfirmDialog = useCallback(() => {
    setDialogOpen(false)
  }, [])

  const handleDelete = useCallback(() => {
    deletePicture(undefined, {
      onSuccess: () => {
        setDialogOpen(false)
        refresh()
      },
    })
  }, [])

  return (
    <div className={classes.imageContainer}>
      <div className={classes.image} style={{ backgroundImage: `url(${picture.urlMedium})` }}>
        {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      </div>

      <IconButton
        size="small"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem onClick={handleOpenConfirmDialog}>
          <ListItemIcon>
            <DeleteForeverIcon color="error" fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
      <Confirm
        isOpen={dialogOpen}
        loading={loading}
        title="Delete Picture"
        content="Are you sure you want to delete this picture?"
        onConfirm={handleDelete}
        onClose={handleCloseConfirmDialog}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: 'flex',
    marginRight: theme.spacing(3),
    alignItems: 'flex-start',
  },
  image: {
    height: 100,
    width: 100,
    // eslint-disable-next-line quotes
    content: "''",
    borderRadius: '4px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}))
