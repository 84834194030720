import React, { FC, useCallback, useMemo, useState } from 'react'
import { useGetMany, useGetOne, useGetList } from 'ra-core'
import { ReferenceField, TextField } from 'react-admin'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ErrorIcon from '@material-ui/icons/Error'
import EditIcon from '@material-ui/icons/Edit'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { FaPlaneDeparture, FaShieldAlt, FaPlaneArrival, FaUsers, FaLeaf } from 'react-icons/fa'
import FlightDurationField from '../../components/field/FlightDurationField'
// import GenericMoreMenu from '../../components/GenericMoreMenu'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { QUERY_GET_PICTURES } from '../../queries'
import RoutePassengerChip from '../../components/RoutePassengerChip'
import OrderRouteStatusField, { ROUTE_STATUS } from '../../components/field/OrderRouteStatusField'
import OrderRouteStatusDropdown from '../../components/OrderRouteStatusDropdown'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PrintIcon from '@material-ui/icons/Print'
import DeleteIcon from '@material-ui/icons/Delete'
import DeleteOrderRoute from './DeleteOrderRoute'
import { CopyOrderRouteModal } from '../../components/CopyOrderRouteModal'
import { RichTextField } from 'ra-ui-materialui'
import RouteCarbonOffsetField from '../../components/field/RouteCarbonOffsetField'

type OrderRouteProps = {
  id: number
  index: number
  onEditClick: (orderRouteId: string) => (event: any) => any
  onPrintClick: (orderRouteId: string) => (event: any) => any
  defaultExpanded: boolean
}

type OrderRouteCompProps = {
  id: string
  index: number
  orderId: number
  vehicleId: number
  departureAirportId: string
  arrivalAirportId: string
  flightDate: string
  passengersQuantity: number
  notes: string
  flightDuration: number
  arrivalDate?: string
  flightNumber?: string
  createdAt: Date
  updatedAt: Date
  status: ROUTE_STATUS
  boardingTime?: number
  luggages?: string
  catering?: string
  onEditClick: (orderRouteId: string) => (event: any) => any
  onPrintClick: (orderRouteId: string) => (event: any) => any
  defaultExpanded: boolean
  [x: string]: any
}

const OrderRouteComp: FC<OrderRouteCompProps> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const mediaquery = useMediaQuery(theme.breakpoints.down('xs'))

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const { loading: loadingVehicle, error: errorVehicle, data: dataVehicle } = useGetOne('Vehicle', props.vehicleId)
  const { loading: airportsLoading, error: airportsError, data: airportsData } = useGetMany('Airport', [
    props.departureAirportId,
    props.arrivalAirportId,
  ])
  const { loading: loadingPax, error: errorPax, data: dataPax } = useGetList(
    'Passenger',
    { page: 1, perPage: 100 },
    { field: 'id', order: 'DESC' },
    { orderRouteId: props.id }
  )

  // if (dataPax) console.log('sono paax: ', dataPax)

  const passengers = useMemo((): any[] => {
    if (!loadingPax && dataPax) {
      return Object.values(dataPax)
    } else {
      return []
    }
  }, [loadingPax, dataPax])

  const { data: picturesData, loading: picturesLoading } = useQuery(QUERY_GET_PICTURES, {
    variables: {
      filters: {
        galleryId: dataVehicle?.galleryId,
      },
    },
    skip: !dataVehicle || !dataVehicle.galleryId,
  })
  const imageUrl = useMemo(
    () =>
      picturesData?.pictures?.data && picturesData.pictures.data.length > 0
        ? picturesData.pictures.data[0].urlMedium
        : undefined,
    [picturesData]
  )

  const departure = useMemo(
    () =>
      airportsData && Array.isArray(airportsData)
        ? airportsData.find(({ id }: any = {}) => id === props.departureAirportId)
        : undefined,
    [airportsData]
  )
  const arrival = useMemo(
    () =>
      airportsData && Array.isArray(airportsData)
        ? airportsData.find(({ id }: any = {}) => id === props.arrivalAirportId)
        : undefined,
    [airportsData]
  )

  const renderAccordionDetails = useCallback(
    (): React.ReactElement => (
      <AccordionDetails className={classes.infoCardAccordionDetail}>
        <Divider />
        <Box py={6} px={4}>
          <Typography variant="h3" color="primary">
            Jet Info
          </Typography>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap" my={6}>
            {loadingVehicle && !dataVehicle ? (
              <CircularProgress />
            ) : (
              <>
                <Box display="flex" flexDirection="column" fontWeight="normal">
                  <Typography variant="body2">Aircraft</Typography>
                  <ReferenceField
                    basePath="/Order"
                    record={dataVehicle}
                    source="vehicleModelId"
                    reference="VehicleModel"
                    link={false}
                  >
                    <TextField source="name" variant="h4" className={classes.inlineValue} />
                  </ReferenceField>
                </Box>
                <Box display="flex" flexDirection="column" fontWeight="normal">
                  <Typography variant="body2">Registration</Typography>
                  <Typography variant="h4">{dataVehicle?.licensePlate}</Typography>
                </Box>
                <Box display="flex" flexDirection="column" fontWeight="normal">
                  <Typography variant="body2">Flight Number</Typography>
                  <Typography variant="h4">{props.flightNumber}</Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Divider />
        <Box py={6} px={4}>
          <Typography variant="h3" color="primary">
            Crew Info
          </Typography>
          <Box display="flex" fontWeight="normal" justifyContent="space-between">
            <Box display="flex" flexDirection="column" fontWeight="normal">
              <Typography variant="body2">Captain</Typography>
              <Typography variant="h4">{props.captainName || 'N.D.'}</Typography>
              <Typography variant="body2">{props.captainNumber || 'N.D.'}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" fontWeight="normal">
              <Typography variant="body2">First officer</Typography>
              <Typography variant="h4">{props.firstOfficerName || 'N.D.'}</Typography>
              <Typography variant="body2">{props.firstOfficerNumber || 'N.D.'}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" fontWeight="normal">
              <Typography variant="body2">Flight attendant</Typography>
              <Typography variant="h4">{props.flightAttendantName || 'N.D.'}</Typography>
              <Typography variant="body2">{props.flightAttendantNumber || 'N.D.'}</Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box py={6} px={4}>
          <Typography variant="h3" color="primary">
            Handling Info
          </Typography>
          <Box display="flex" fontWeight="normal" justifyContent="space-between">
            <Box display="flex" flexDirection="column" fontWeight="normal">
              <Typography variant="body2">Departure Handling</Typography>
              <Typography variant="h4">{props.departureHandlingName || 'N.D.'}</Typography>
              <Typography variant="body2">{props.departureHandlingNumber || 'N.D.'}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" fontWeight="normal">
              <Typography variant="body2">Arrival Handling</Typography>
              <Typography variant="h4">{props.arrivalHandlingName || 'N.D.'}</Typography>
              <Typography variant="body2">{props.arrivalHandlingNumber || 'N.D.'}</Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box py={6} px={4}>
          <Typography variant="h3" color="primary">
            Passengers Info
          </Typography>
          <Box display="flex" flexWrap="wrap" my={6}>
            {passengers.map((pax: any) => (
              <RoutePassengerChip
                id={pax.id}
                firstName={pax.firstName}
                lastName={pax.lastName}
                orderRouteId={props.id}
                key={`route-${props.id}-pax-${pax.id}`}
              />
            ))}
          </Box>
          <Button
            color="primary"
            variant="contained"
            size="small"
            component={Link}
            to={`/Order/${props.orderId}/show/OrderRoute/${props.id}/addpassenger?selectedRoute=${props.id}`}
          >
            Add passenger
          </Button>
        </Box>
        <Divider />
        <Box py={6} px={4}>
          <Typography variant="h3" color="primary">
            Additional info:
          </Typography>
          <Box mt={2}>
            {/* <Typography variant="h5">{props.notes || 'N.D.'}</Typography> */}
            <RichTextField record={props} source="notes" />
          </Box>
        </Box>
        <Divider />
        <Box py={6} px={4}>
          <Typography variant="h3" color="primary">
            Boarding Info
          </Typography>
          <Box display="flex" fontWeight="normal" justifyContent="space-between">
            <Box display="flex" flexDirection="column" fontWeight="normal">
              <Typography variant="body2">Boarding Time</Typography>
              <Typography variant="body2">{`${props.boardingTime} minutes before departure`}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" fontWeight="normal">
              <Typography variant="body2">Luggage</Typography>
              <Typography variant="body2">{props.luggages || 'N.D.'}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" fontWeight="normal">
              <Typography variant="body2">Catering</Typography>
              <Typography variant="body2">{props.catering || 'N.D.'}</Typography>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    ),
    [props, passengers]
  )

  const renderMoreMenu = useCallback((): React.ReactElement => {
    return (
      <Menu
        id={`more-orderroute-${props.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMoreClose}
      >
        <MenuItem onClick={props.onEditClick(props.id)}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          {'Edit route'}
        </MenuItem>
        <MenuItem onClick={props.onPrintClick(props.id)}>
          <ListItemIcon>
            <PrintIcon fontSize="small" />
          </ListItemIcon>
          {'Print FB'}
        </MenuItem>
        <CopyOrderRouteModal orderRouteId={props.id} orderId={props.orderId} />
        <DeleteOrderRoute id={props.id} onMoreClose={onMoreClose} />
      </Menu>
    )
  }, [anchorEl, onMoreClose, props])

  if (mediaquery) {
    return (
      <Accordion
        elevation={1}
        classes={{ root: classes.mainAccordion, expanded: classes.mainAccordionExpanded }}
        defaultExpanded={props.defaultExpanded}
        // expanded={props.defaultExpanded}
        // expanded={expanded}
        // onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="prices-panel-header"
          classes={{
            root: classes.infoCardAccordionSummaryRoot,
            content: classes.infoCardAccordionSummaryContent,
          }}
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Box width="100%" display="flex" justifyContent="space-between" flexWrap="wrap">
              <Typography variant="h6" className={classes.indexText}>{`Route ${props.index}`}</Typography>
              <OrderRouteStatusDropdown id={props.id} currentStatus={props.status} />
              <IconButton onClick={onMoreClick} className={classes.moreSquare}>
                <MoreVertIcon htmlColor="#1b1b1b" />
              </IconButton>
              {renderMoreMenu()}
            </Box>
            <Timeline align="left" classes={{ root: classes.timeline }}>
              <TimelineItem classes={{ root: classes.timelineItem }}>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineOppositeContent classes={{ root: classes.oppositeContent }}>
                  <Box display="flex" alignItems="baseline">
                    <Typography variant="h3" className={classes.airportCode}>
                      {departure?.iataCode || departure?.icaoCode}
                    </Typography>
                    <Typography variant="caption" noWrap className={classes.airportName}>
                      {departure?.name}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="baseline">
                    {/* <Typography variant="body2">{new Date(props.flightDate).toLocaleDateString()}</Typography> */}
                    <Typography variant="body2">
                      {new Date(props.flightDate.replace('Z', '')).toLocaleDateString()}
                    </Typography>
                    <Box ml={2} />
                    <Typography variant="h6" className={classes.bold}>
                      {/* {new Date(props.flightDate).toLocaleTimeString()} */}
                      {new Date(props.flightDate.replace('Z', '')).toLocaleTimeString().slice(0, -3)}
                    </Typography>
                  </Box>
                </TimelineOppositeContent>
              </TimelineItem>
              <TimelineItem classes={{ root: classes.timelineItem }}>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                </TimelineSeparator>
                <TimelineOppositeContent classes={{ root: classes.oppositeContent }}>
                  <Box display="flex" alignItems="baseline">
                    <Typography variant="h3" className={classes.airportCode}>
                      {arrival?.iataCode || arrival?.icaoCode}
                    </Typography>
                    <Typography variant="caption" noWrap className={classes.airportName}>
                      {arrival?.name}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="baseline">
                    <Typography variant="body2">
                      {/* {props.arrivalDate ? new Date(props.arrivalDate).toLocaleDateString() : ''} */}
                      {props.arrivalDate && typeof props.arrivalDate === 'string'
                        ? new Date(props.arrivalDate.replace('Z', '')).toLocaleDateString()
                        : ''}
                    </Typography>
                    <Box ml={2} />
                    <Typography variant="h6" className={classes.bold}>
                      {/* {props.arrivalDate ? new Date(props.arrivalDate).toLocaleTimeString() : ''} */}
                      {props.arrivalDate && typeof props.arrivalDate === 'string'
                        ? new Date(props.arrivalDate.replace('Z', '')).toLocaleTimeString().slice(0, -3)
                        : ''}
                    </Typography>
                  </Box>
                </TimelineOppositeContent>
              </TimelineItem>
            </Timeline>
            <Box display="flex" justifyContent="space-between">
              <Box mr={4}>
                <Typography variant="body2">{'Duration'}</Typography>
                <Typography variant="h6" className={classes.bold}>
                  <FlightDurationField record={props} />
                </Typography>
              </Box>
              <Box mr={4}>
                <Typography variant="body2">{'Passengers'}</Typography>
                <Typography variant="h6" className={classes.bold}>
                  {props.passengersQuantity}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" mt={2} alignItems="center">
              <img src={imageUrl} className={classes.vehicleImage} />
              <Box display="flex" flexDirection="column" ml={2}>
                {loadingVehicle ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Box display="flex">
                      <ReferenceField
                        basePath="/Order"
                        record={dataVehicle}
                        source="vehicleManufacturerId"
                        reference="VehicleManufacturer"
                        link={false}
                      >
                        <TextField source="name" variant="body2" />
                      </ReferenceField>
                      <ReferenceField
                        basePath="/Order"
                        record={dataVehicle}
                        source="vehicleModelId"
                        reference="VehicleModel"
                        link={false}
                      >
                        <TextField source="name" variant="h6" className={classes.inlineValue} />
                      </ReferenceField>
                    </Box>
                    <Box display="flex">
                      <FaShieldAlt className={classes.smallIcon} />
                      <ReferenceField
                        basePath="/Order"
                        record={dataVehicle}
                        source="vehicleSafetyTypeId"
                        reference="VehicleSafetyType"
                        link={false}
                      >
                        <TextField source="name" variant="h6" className={classes.inlineValue} />
                      </ReferenceField>
                      <FaUsers className={classes.smallIcon} />
                      <Typography variant="h6" className={classes.inlineValue}>
                        {dataVehicle?.numberOfSeats}
                      </Typography>
                      {dataVehicle && props.flightDuration && (
                        <>
                          <FaLeaf className={classes.smallIcon} />
                          <Typography variant="h6" className={classes.inlineValue}>
                            <RouteCarbonOffsetField
                              totalDuration={props.flightDuration}
                              carbonOffsetId={dataVehicle.vehicleCarbonOffsetId}
                            />
                          </Typography>
                        </>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        {renderAccordionDetails()}
      </Accordion>
    )
  } else {
    return (
      <Accordion
        elevation={1}
        classes={{ root: classes.mainAccordion, expanded: classes.mainAccordionExpanded }}
        defaultExpanded={props.defaultExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="prices-panel-header"
          classes={{
            root: classes.infoCardAccordionSummaryRoot,
            content: classes.infoCardAccordionSummaryContent,
          }}
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Box width="100%" display="flex" justifyContent="space-between" flexWrap="wrap">
              <Box display="flex" flexWrap="wrap">
                <Box className={classes.indexSquare} display="flex" justifyContent="center" alignItems="center">
                  {props.index}
                </Box>
                <Box mr={4}>
                  <Typography variant="h3" className={classes.airportCode}>
                    {departure?.iataCode || departure?.icaoCode}
                  </Typography>
                  <Box className={classes.boxAirportName}>
                    <Typography variant="caption" noWrap className={classes.airportName}>
                      {departure?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box mr={4}>
                  {/* <Typography variant="body2">{new Date(props.flightDate).toLocaleDateString()}</Typography> */}
                  <Typography variant="body2">
                    {new Date(props.flightDate.replace('Z', '')).toLocaleDateString()}
                  </Typography>
                  <Typography variant="h6" className={classes.bold}>
                    {/* {new Date(props.flightDate).toLocaleTimeString()} */}
                    {new Date(props.flightDate.replace('Z', '')).toLocaleTimeString().slice(0, -3)}
                  </Typography>
                </Box>
                <Box mr={4}>
                  <svg width="20px" height="46px" fill="gray">
                    <path stroke="gray" d="M20 23 L0 0 M0 46 L20 23" />
                  </svg>
                </Box>
                <Box mr={4}>
                  <Typography variant="h3" className={classes.airportCode}>
                    {arrival?.iataCode || arrival?.icaoCode}
                  </Typography>
                  <Box className={classes.boxAirportName}>
                    <Typography variant="caption" noWrap className={classes.airportName}>
                      {arrival?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box mr={4}>
                  <Typography variant="body2">
                    {/* {props.arrivalDate ? new Date(props.arrivalDate).toLocaleDateString() : ''} */}
                    {props.arrivalDate && typeof props.arrivalDate === 'string'
                      ? new Date(props.arrivalDate.replace('Z', '')).toLocaleDateString()
                      : ''}
                  </Typography>
                  <Typography variant="h6" className={classes.bold}>
                    {/* {props.arrivalDate ? new Date(props.arrivalDate).toLocaleTimeString() : ''} */}
                    {props.arrivalDate && typeof props.arrivalDate === 'string'
                      ? new Date(props.arrivalDate.replace('Z', '')).toLocaleTimeString().slice(0, -3)
                      : ''}
                  </Typography>
                </Box>
              </Box>
              <div>
                <OrderRouteStatusDropdown id={props.id} currentStatus={props.status} />
                <IconButton onClick={onMoreClick} className={classes.moreSquare}>
                  <MoreVertIcon htmlColor="#1b1b1b" />
                </IconButton>
                {renderMoreMenu()}
              </div>
            </Box>
            <Box width="100%" display="flex" justifyContent="space-between" mt={4} flexWrap="wrap">
              <Box display="flex">
                <Box mr={4}>
                  <Typography variant="body2">{'Duration'}</Typography>
                  <Typography variant="h6" className={classes.bold}>
                    <FlightDurationField record={props} />
                  </Typography>
                </Box>
                <Box mr={4}>
                  <Typography variant="body2">{'Passengers'}</Typography>
                  <Typography variant="h6" className={classes.bold}>
                    {props.passengersQuantity}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex">
                <Box mr={4} display="flex" flexDirection="column" alignItems="flex-end">
                  {loadingVehicle ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Box display="flex">
                        <ReferenceField
                          basePath="/Order"
                          record={dataVehicle}
                          source="vehicleManufacturerId"
                          reference="VehicleManufacturer"
                          link={false}
                        >
                          <TextField source="name" variant="body2" />
                        </ReferenceField>
                        <ReferenceField
                          basePath="/Order"
                          record={dataVehicle}
                          source="vehicleModelId"
                          reference="VehicleModel"
                          link={false}
                        >
                          <TextField source="name" variant="h6" className={classes.inlineValue} />
                        </ReferenceField>
                      </Box>
                      <Box display="flex">
                        <FaShieldAlt className={classes.smallIcon} />
                        <ReferenceField
                          basePath="/Order"
                          record={dataVehicle}
                          source="vehicleSafetyTypeId"
                          reference="VehicleSafetyType"
                          link={false}
                        >
                          <TextField source="name" variant="h6" className={classes.inlineValue} />
                        </ReferenceField>
                        <FaUsers className={classes.smallIcon} />
                        <Typography variant="h6" className={classes.inlineValue}>
                          {dataVehicle?.numberOfSeats}
                        </Typography>
                        {dataVehicle && props.flightDuration && (
                          <>
                            <FaLeaf className={classes.smallIcon} />
                            <Typography variant="h6" className={classes.inlineValue}>
                              <RouteCarbonOffsetField
                                totalDuration={props.flightDuration}
                                carbonOffsetId={dataVehicle.vehicleCarbonOffsetId}
                              />
                            </Typography>
                          </>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
                <img src={imageUrl} className={classes.vehicleImage} />
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        {renderAccordionDetails()}
      </Accordion>
      // <Accordion elevation={1} classes={{ root: classes.mainAccordion, expanded: classes.mainAccordionExpanded }}>
      //   <AccordionSummary
      //     expandIcon={<ExpandMoreIcon htmlColor="white" />}
      //     id="prices-panel-header"
      //     classes={{
      //       root: classes.infoCardAccordionSummaryRoot,
      //       content: classes.infoCardAccordionSummaryContent,
      //     }}
      //   >
      //     <Paper className={classes.container}>
      //       <div className={classes.allInfoWrapper}>
      //         <div className={classes.vehicleHalf}>
      //           <img src={imageUrl} className={classes.image} />
      //           <div className={classes.vehicleInfoWrapper}>
      //             {loadingVehicle ? (
      //               <LinearProgress />
      //             ) : errorVehicle || !dataVehicle ? (
      //               <ErrorIcon color="error" />
      //             ) : (
      //               <>
      //                 <span>
      //                   <ReferenceField
      //                     basePath="/Order"
      //                     record={dataVehicle}
      //                     source="vehicleManufacturerId"
      //                     reference="VehicleManufacturer"
      //                     link={false}
      //                   >
      //                     <TextField source="name" variant="h2" />
      //                   </ReferenceField>
      //                   <ReferenceField
      //                     basePath="/Order"
      //                     record={dataVehicle}
      //                     source="vehicleModelId"
      //                     reference="VehicleModel"
      //                     link={false}
      //                   >
      //                     <TextField source="name" variant="h2" className={classes.inlineField} />
      //                   </ReferenceField>
      //                 </span>
      //                 <span className={classes.fieldWithIcon}>
      //                   <FaShieldAlt className={classes.icon} />
      //                   <ReferenceField
      //                     basePath="/Order"
      //                     record={dataVehicle}
      //                     source="vehicleSafetyTypeId"
      //                     reference="VehicleSafetyType"
      //                     link={false}
      //                   >
      //                     <TextField source="name" variant="h4" />
      //                   </ReferenceField>
      //                 </span>
      //                 <Typography variant="h4" className={classes.fieldWithIcon}>
      //                   <FaUsers className={classes.icon} />
      //                   {`${dataVehicle.numberOfSeats} seats`}
      //                 </Typography>
      //               </>
      //             )}
      //           </div>
      //         </div>
      //         <div className={classes.airportsHalf}>
      //           <div className={classes.airportInfoWrapper}>
      //             <Typography variant="h5" align="center">
      //               <FaPlaneDeparture className={classes.titleIcon} />
      //               Departure
      //             </Typography>
      //             {airportsLoading || !departure ? (
      //               <CircularProgress />
      //             ) : (
      //               <>
      //                 <Typography variant="h1">{departure.iataCode || departure.icaoCode}</Typography>
      //                 <Typography variant="h5" align="center">
      //                   {departure.name}
      //                 </Typography>
      //               </>
      //             )}
      //           </div>
      //           <div className={classes.airportInfoWrapper}>
      //             <Typography variant="h5" align="center">
      //               <FaPlaneArrival className={classes.titleIcon} />
      //               Arrival
      //             </Typography>
      //             {airportsLoading || !arrival ? (
      //               <CircularProgress />
      //             ) : (
      //               <>
      //                 <Typography variant="h1">{arrival.iataCode || arrival.icaoCode}</Typography>
      //                 <Typography variant="h5" align="center">
      //                   {arrival.name}
      //                 </Typography>
      //               </>
      //             )}
      //           </div>
      //         </div>
      //       </div>
      //       <div className={classes.editIconWrapper}>
      //         <IconButton onClick={props.onEditClick(props.id)} className={classes.editIconButton}>
      //           <EditIcon htmlColor="white" />
      //         </IconButton>
      //       </div>
      //     </Paper>
      //   </AccordionSummary>
      //   <AccordionDetails className={classes.infoCardAccordionDetail}>
      //     <div className={classes.flightInfoWrapper}>
      //       <div className={classes.flightInfo}>
      //         <Typography variant="h5">Departure date:</Typography>
      //         <Typography variant="h1">{new Date(props.flightDate).toLocaleDateString()}</Typography>
      //       </div>
      //       <div className={classes.flightInfo}>
      //         <Typography variant="h5">Departure time:</Typography>
      //         <Typography variant="h1">{new Date(props.flightDate).toLocaleTimeString().slice(0, -3)}</Typography>
      //       </div>
      //       <div className={classes.flightInfo}>
      //         <Typography variant="h5">Flight duration:</Typography>
      //         <Typography variant="h1">{props.flightDuration}</Typography>
      //       </div>
      //       <div className={classes.flightInfo}>
      //         <Typography variant="h5">Passengers:</Typography>
      //         <Typography variant="h1">{props.passengersQuantity}</Typography>
      //       </div>
      //     </div>
      //     {props.notes && (
      //       <Box mt={3}>
      //         <Typography variant="h5">Additional info:</Typography>
      //         <Typography variant="h5">{props.notes}</Typography>
      //       </Box>
      //     )}
      //   </AccordionDetails>
      // </Accordion>
    )
  }
}

const OrderRoute: FC<OrderRouteProps> = ({ id, onEditClick, index, defaultExpanded, onPrintClick }) => {
  const { loading, error, data } = useGetOne('OrderRoute', id)

  // if (data) console.log('dataaaaaa ', data)

  if (loading) return <LinearProgress />
  if (error) return <ErrorIcon color="error" />
  if (data)
    return (
      <OrderRouteComp
        {...(data as OrderRouteCompProps)}
        onEditClick={onEditClick}
        onPrintClick={onPrintClick}
        index={++index}
        defaultExpanded={defaultExpanded}
      />
    )

  return null
}

const useStyles = makeStyles((theme) => ({
  mainAccordion: {
    marginBottom: theme.spacing(4),
    borderRadius: 6,
    '&:before': {
      height: 0,
    },
  },
  mainAccordionExpanded: {
    marginTop: '0 !important',
    marginBottom: theme.spacing(6),
    backgroundColor: 'rgba(191, 191, 191, 0.15)',
  },
  infoCardAccordionSummaryRoot: {
    paddingLeft: 0,
  },
  infoCardAccordionSummaryContent: {
    margin: '0 !important',
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: 0,
  },
  infoCardAccordionDetail: {
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper,
  },
  flightInfoWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  flightInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  //// new styles
  indexSquare: {
    width: '37px',
    height: '37px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '4px',
    marginRight: theme.spacing(4),
  },
  moreSquare: {
    width: '37px',
    height: '37px',
    backgroundColor: '#eeeeee',
    borderRadius: '4px',
    position: 'absolute',
    right: '45px',
    top: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      width: '20px',
      height: '20px',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  boxAirportName: {
    width: '100px',
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  airportName: {
    lineHeight: 1,
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
  airportCode: {
    lineHeight: 1,
  },
  vehicleImage: {
    width: '37px',
    height: '37px',
    borderRadius: '4px',
  },
  inlineValue: {
    display: 'inline',
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  smallIcon: {
    fontWeight: 'lighter',
    marginLeft: theme.spacing(3),
    '&:first-child': {
      marginLeft: 0,
    },
  },
  indexText: {
    fontWeight: 'bold',
    color: 'gray',
  },
  timeline: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  oppositeContent: {
    textAlign: 'left',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
  },
  timelineItem: {
    minHeight: '60px',
  },
}))

export default OrderRoute
