import React, { FC } from 'react'
import { FunctionField, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

type FieldProps = {
  source: string
  record?: Record<string, any>
}

type DetailProps = {
  record: Record<string, string>
  isLast: boolean
}

const BankAccountDetail: FC<DetailProps> = ({ record, isLast }) => {
  const classes = useStyles()
  return (
    <div className={isLast ? classes.lastDetail : classes.detail}>
      <Typography variant="h6" color="primary">
        {`${record.key}`.toUpperCase()}
      </Typography>
      <TextField record={record} source="value" label={`${record.key}`} addLabel />
    </div>
  )
}

const BankAccountDetailsField: FC<FieldProps> = (props) => {
  const classes = useStyles()
  return props && props.source && props.record ? (
    <FunctionField
      source={props.source}
      record={props.record[props.source]}
      render={(record: Record<string, string>[]): React.ReactElement => (
        <div className={classes.wrapper}>
          {record.map((detail: Record<string, string>, index: number) => (
            <BankAccountDetail key={index} record={detail} isLast={record.length === index + 1} />
          ))}
        </div>
      )}
    />
  ) : null
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
  },
  lastDetail: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default BankAccountDetailsField
