import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import {
  GET_CUSTOMER_MAILS,
  GET_CUSTOMER_PEOPLE,
  GET_MAIL_BY_ID,
  MUTATION_CREATE_CUSTOMER_ACTIVITY,
  MUTATION_DELETE_CUSTOMER_ACTIVITY,
  QUERY_CHECK_GOOGLE_TOKEN,
  QUERY_GET_CUSTOMER_ACTIVITIES,
  QUERY_GET_SERVICE_LOGS,
} from '../../queries'
import { ActivityForm } from './ActivityForm'
import { ActivitiesList } from './ActivitiesList'
import { useCombinedPagination } from 'use-combined-pagination'
import { useNotify } from 'ra-core'
import { Box, chakra } from '@chakra-ui/react'
import { TeamMembersFilter } from './TeamMembersFilter'
import { ActivityTypeFilter } from './ActivityTypeFilter'
import InfiniteScroll from 'react-infinite-scroll-component'

type Props = {
  record: any
}

// TODO: CHANGE THIS
const LIMIT_PER_PAGE = 10

export const CustomerActivities: FC<Props> = (props) => {
  const client = useApolloClient()
  const notify = useNotify()
  const [gmailNextPageToken, setGmailNextPageToken] = useState<string | undefined>(undefined)
  const [gmailInitialized, setGmailInitialized] = useState<boolean>(false)
  const [customerPeople, setCustomerPeople] = useState<
    { id: string; email: string; firstName?: string; lastName?: string }[] | undefined
  >(undefined)
  const [loggedUserMail, setLoggedUserEmail] = useState<string | undefined>(undefined)

  const customerEmailAddress = useMemo(() => props.record.email, [props.record])
  const peopleEmails = useMemo(() => {
    if (gmailInitialized && customerPeople) {
      return customerPeople.map((item) => item.email)
    }

    return []
  }, [gmailInitialized, customerPeople])

  const fromAndToFilters = useMemo(() => {
    const mails = []
    if (props.record?.email) {
      mails.push(props.record.email)
    }

    mails.push(...peopleEmails.filter((item) => !!item))
    return mails
  }, [props.record?.email, peopleEmails])

  const [filteredTeamMemberIds, setFilteredTeamMemberIds] = useState<string[]>([])
  const [filteredActivityTypes, setFilteredActivityTypes] = useState<string[] | undefined>(undefined)
  const [initialized, setInitialized] = useState(false)

  const fetchLogs = useCallback(
    async (page: number): Promise<any[]> => {
      try {
        const filters: any = {
          _Op_or: [
            {
              data: {
                _Op_or: [
                  {
                    _Op_contains: {
                      customerId: props.record.id + '',
                    },
                  },
                  {
                    _Op_contains: {
                      customerId: parseInt(props.record.id, 10),
                    },
                  },
                ],
              },
            },
            {
              service: 'ms-organization',
              domain: 'Customer',
              data: {
                _Op_or: [
                  {
                    _Op_contains: {
                      id: parseInt(props.record.id, 10),
                    },
                  },
                  {
                    _Op_contains: {
                      id: props.record.id + '',
                    },
                  },
                ],
              },
            },
            {
              data: {
                _Op_contains: {
                  referencedCustomerId: props.record.id + '',
                },
              },
            },
          ],
        }

        if (filteredTeamMemberIds.length > 0) {
          filters['userId'] = { _Op_in: filteredTeamMemberIds }
        }

        const logsResult = await client.query({
          query: QUERY_GET_SERVICE_LOGS,
          fetchPolicy: 'network-only',
          variables: {
            filters,
            pagination: {
              limit: LIMIT_PER_PAGE,
              offset: page,
            },
            sort: {
              createdAt: 'DESC',
            },
          },
        })

        if (!logsResult?.data?.serviceLogs?.data?.length) {
          throw new Error('Error fetching data')
        }

        return logsResult?.data.serviceLogs.data
      } catch (error) {
        console.error('Error fetching customer logs: ', error)
      }

      return []
    },
    [filteredTeamMemberIds]
  )

  const fetchActivities = useCallback(
    async (page: number): Promise<any[]> => {
      try {
        const typeIds = (filteredActivityTypes || []).filter((item) => item !== 'email')
        const activitiesResult = await client.query({
          query: QUERY_GET_CUSTOMER_ACTIVITIES,
          fetchPolicy: 'no-cache',
          variables: {
            filters: {
              customerId: props.record.id,
              createdUserIds: filteredTeamMemberIds.length > 0 ? filteredTeamMemberIds : undefined,
              typeIds: typeIds.length > 0 ? typeIds : [],
            },
            pagination: {
              limit: LIMIT_PER_PAGE,
              offset: page,
            },
            sort: {
              date: 'DESC',
            },
          },
        })

        if (!activitiesResult?.data?.customerActivities?.data?.length) {
          throw new Error('Error fetching data')
        }

        return activitiesResult.data.customerActivities.data
      } catch (error) {
        console.error('Error fetching customer activities: ', error)
      }

      return []
    },
    [filteredTeamMemberIds, filteredActivityTypes]
  )

  const fetchEmails = useCallback(
    async (page: number) => {
      try {
        if (fromAndToFilters.length === 0) {
          return []
        }

        // const { data } = await client.query({
        //   query: QUERY_GET_GMAIL_SETTINGS,
        // })

        // if (data?.gmailSettings?.defaultCC) {
        //   fromAndToFilters.push(data.gmailSettings.defaultCC)
        // }

        const emailsResult = await client.query({
          query: GET_CUSTOMER_MAILS,
          fetchPolicy: 'no-cache',
          variables: {
            maxResults: LIMIT_PER_PAGE,
            filters: {
              fromORto: fromAndToFilters,
            },
            pageToken: page > 0 ? gmailNextPageToken : undefined,
          },
        })

        if (!emailsResult?.data?.getGmailList?.messages?.length) {
          throw new Error('Error fetching data')
        }

        setGmailNextPageToken(emailsResult.data?.getGmailList?.nextPageToken)
        const resolvedMails = await Promise.all(
          (emailsResult.data?.getGmailList?.messages || []).map(async (item: any) => {
            return await client
              .query({
                query: GET_MAIL_BY_ID,
                variables: {
                  id: item.id,
                },
              })
              .then((data) => ({
                ...data.data.getGmailById,
                date: new Date(parseInt(data.data.getGmailById.internalDate, 10)).toISOString(),
              }))
          })
        )

        return resolvedMails
      } catch (error) {
        console.error('Error fetching customer emails: ', error)
        return []
      }
    },
    [gmailNextPageToken, fromAndToFilters]
  )

  const getters = useMemo(() => {
    if (!filteredActivityTypes) {
      return []
    }

    const newGetters = [fetchLogs, fetchActivities]

    if (filteredActivityTypes.includes('email')) {
      newGetters.push(fetchEmails)
    }

    return newGetters
  }, [filteredActivityTypes, fetchEmails, fetchLogs])

  const { loading, data, getNext, refetch, state, hasNext, setState } = useCombinedPagination({
    getters: getters,
    sortKey: 'date',
  })

  const handleTeamMembersFilterChange = useCallback(
    (selectedIds: string[]) => {
      setFilteredTeamMemberIds(selectedIds)
    },
    [setState, fetchLogs, fetchActivities, fetchEmails, getNext]
  )

  const handleActivityTypesFilterChange = useCallback((items: string | string[]) => {
    setFilteredActivityTypes(typeof items === 'string' ? [items] : items)
  }, [])

  const handleActivityCreated = useCallback(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    const fetchLoggedUserMail = async (): Promise<void> => {
      try {
        const { data, error } = await client.query({
          query: QUERY_CHECK_GOOGLE_TOKEN,
        })

        if (error) {
          throw new Error(error.message)
        }

        if (!data || !data.checkGmailToken || !data.checkGmailToken.user) {
          return
        }

        setLoggedUserEmail(data.checkGmailToken.user)
      } catch (error) {
        notify('Error fetching gmail user', 'error')
      }
    }

    const fetchCustomerPeople = async (): Promise<void> => {
      try {
        const { data, error } = await client.query({
          query: GET_CUSTOMER_PEOPLE,
          variables: {
            customerId: props.record?.id,
          },
        })

        if (error) {
          throw new Error(error.message)
        }

        if (!data || !data.people || !data.people.data) {
          throw new Error('Error parsing client people')
        }

        setCustomerPeople(data.people.data)
      } catch (error) {
        notify('Error fetching client people', 'error')
      }
    }

    setGmailInitialized(false)

    Promise.all([fetchCustomerPeople(), fetchLoggedUserMail()]).finally(() => {
      setGmailInitialized(true)
    })
  }, [])

  useEffect(() => {
    if (gmailInitialized && getters && filteredActivityTypes && data?.length === 0 && !loading && !initialized) {
      refetch()
      setInitialized(true)
      console.log('fetching first page')
    }
  }, [getters, gmailInitialized])

  useEffect(() => {
    if (!loading && gmailInitialized) {
      refetch()
    }
  }, [filteredTeamMemberIds, filteredActivityTypes])

  return (
    <div>
      <ActivityForm
        {...props}
        mutation={MUTATION_CREATE_CUSTOMER_ACTIVITY}
        source="customerId"
        id={props.record.id}
        onCreated={handleActivityCreated}
      />

      <Box d="flex" alignItems="center" justifyContent="flex-end" pt={4} pb={2}>
        <TeamMembersFilter selectedIds={filteredTeamMemberIds} onSelectedIdsChange={handleTeamMembersFilterChange} />
        <ActivityTypeFilter
          value={filteredActivityTypes}
          onChange={handleActivityTypesFilterChange}
          showAdditionalTypes
        />
      </Box>

      <InfiniteScroll
        dataLength={data?.length || 0}
        next={getNext}
        hasMore={hasNext}
        loader={<chakra.div minH="180px">Loading...</chakra.div>}
        hasChildren={(data?.length || 0) > 0}
        scrollThreshold={0.8}
      >
        <ActivitiesList
          onDeleteComplete={(): void => {
            //
          }}
          data={data}
          loading={loading}
          loggedUserMail={loggedUserMail}
          customerEmail={customerEmailAddress}
          peopleEmails={peopleEmails}
          deleteMutation={MUTATION_DELETE_CUSTOMER_ACTIVITY}
        />
      </InfiniteScroll>
      {/* {loading && <chakra.div minH="400px">Loading...</chakra.div>} */}
      {/* {hasNext && <Button label="Load More" onClick={getNext} />} */}
    </div>
  )
}
