import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Resizable } from 're-resizable'
import { makeStyles } from '@material-ui/core/styles'

export type ResizableIFrameProps = React.DetailedHTMLProps<
  React.IframeHTMLAttributes<HTMLIFrameElement>,
  HTMLIFrameElement
> & {
  content?: any
}

const MIN_HEIGHT = 222

export const ResizableIFrame: FC<ResizableIFrameProps> = ({ content, ...props }) => {
  const classes = useStyles()
  const ref = useRef<HTMLIFrameElement>(null)

  const [height, setHeight] = useState<number | undefined>(undefined)

  const syncHeight = useCallback(() => {
    const frameHeight = ref.current?.contentWindow?.document.body.offsetHeight
    setHeight(frameHeight)
  }, [])

  const getHeight = useCallback(() => (height !== undefined && height >= MIN_HEIGHT ? height + 30 : MIN_HEIGHT), [
    height,
  ])

  useEffect(() => {
    const doc = ref.current?.contentWindow?.document
    if (doc) {
      doc.open()
      doc.write(content)
      doc.close()

      syncHeight()
    }
  }, [])

  return (
    <Resizable bounds={'parent'} minWidth={250} minHeight={getHeight()} maxHeight={getHeight()}>
      <iframe
        className={classes.root}
        loading={'lazy'}
        width={'100%'}
        height={getHeight()}
        onLoad={syncHeight}
        ref={ref}
        {...props}
      />
    </Resizable>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    outline: 'none',
  },
}))
